import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Auth, Hub, Logger } from "aws-amplify";
import noop from "lodash/noop";
import { useLocation, useNavigate } from "react-router-dom";

import { updateUser as updateUserApi } from "../api/index";
import { useAnalytics } from "../analytics/AnalyticsContext";
import { pubsub } from "./pubsub";

Logger.prototype._ts = () => "";

const AuthContext = createContext({
  authState: null,
  authStateData: {},
  user: null,
  userAttributes: null,
  fetchUserAttributes: noop,
  setUser: noop,
  signIn: noop,
  federatedSignIn: noop,
  signUp: noop,
  signOut: noop,
  toSignIn: noop,
  toSignUp: noop,
  toForgotPassword: noop,
  confirmSignUp: noop,
  resendSignUp: noop,
  forgotPassword: noop,
  forgotPasswordSubmit: noop,
  confirmMFA: noop,
  updateUser: noop,
  enableMFA: noop,
  disableMFA: noop,
  sessionToken: null,
});

const getInitials = ({ given_name: firstName, family_name: lastName }) =>
  `${(firstName === "-" ? "" : firstName).charAt(0)}${(lastName === "-"
    ? ""
    : lastName
  ).charAt(0)}`;

const tryParse = (string, defaultValue) => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return defaultValue;
  }
};

const getUser = async () => {
  try {
    const cognitoUser = await Auth.currentUserInfo();
    if (!cognitoUser) {
      // false is a special value that we use to understand
      // that we need to redirect to the login page
      return false;
    }

    const currentUser = await Auth.currentAuthenticatedUser();
    currentUser.getCachedDeviceKeyAndPassword();

    const [preferredMFA, session] = await Promise.all([
      Auth.getPreferredMFA(currentUser, {
        bypassCache: true,
      }),
      Auth.currentSession(),
    ]);

    const { intercomHash } = session.idToken.payload;

    return {
      ...cognitoUser.attributes,
      id: cognitoUser.attributes.sub,
      username: cognitoUser.username,
      firstName: cognitoUser.attributes.given_name,
      lastName: cognitoUser.attributes.family_name,
      initials: getInitials(cognitoUser.attributes),
      timeZone: cognitoUser.attributes.zoneinfo,
      preferredMFA,
      intercomHash,
      identities: tryParse(cognitoUser.attributes.identities, []),
      deviceKey: currentUser.deviceKey,
    };
  } catch (e) {
    console.error("AuthContext cannot get user", e);
    return false;
  }
};

export const AuthProvider = ({ children }) => {
  const [authStateData, setAuthStateData] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const { trackEvent } = useAnalytics();

  const [user, setUser] = useState(null);

  const [sessionToken, setSessionToken] = useState(null);

  useEffect(() => {
    if (!user) {
      return;
    }

    const updateToken = async () => {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        setSessionToken(token);
      } catch (e) {
        console.error("AuthContext cannot get session token", e);
        setSessionToken(null);
      }
    };
    const stopListener = Hub.listen("auth", async ({ payload }) => {
      // https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/
      if (payload.event !== "tokenRefresh") {
        return;
      }

      await updateToken();
    });

    return () => stopListener();
  }, [user?.sub]);

  useEffect(() => {
    // This effect fetches the current Amplify user
    // and decides if we can continue showing the page
    // or we need to login.

    const loadCurrentUser = async () => {
      if (user) {
        // no need to run this effect many times
        return;
      }

      const nextUser = await getUser();
      setUser(nextUser);

      if (!nextUser) {
        return;
      }

      try {
        if (nextUser.deviceKey) {
          await Auth.rememberDevice();
        }
      } catch (error) {
        console.error("Error remembering device", error);
      }

      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      const locale = navigator.language || navigator.languages[0];

      if (timeZone === nextUser.zoneinfo && locale === nextUser.locale) {
        return;
      }

      const updates = {
        timeZone,
        locale,
      };

      await updateUserApi(updates);

      setUser((user) => ({
        ...user,
        ...updates,
      }));
    };

    loadCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const signIn = useCallback(
    async (...args) => {
      trackEvent("Sign In");

      try {
        let signinResult = await Auth.signIn(...args);

        if (!signinResult) {
          console.warn(
            "User is not authenticated, no user in response to signIn"
          );
          return;
        }

        if (signinResult.challengeName === "SOFTWARE_TOKEN_MFA") {
          trackEvent("MFA Prompt");

          setAuthStateData({ email: args[0], user: signinResult });
          navigate("/signin/mfa");
          return;
        }

        if (signinResult.challengeName === "NEW_PASSWORD_REQUIRED") {
          setAuthStateData({ email: args[0], user: signinResult });
          navigate("/signin/new-password");
          return;
        }

        setUser(await getUser());

        navigate("/?signin=1");
      } catch (e) {
        if (e.code === "UserNotConfirmedException") {
          trackEvent("User re-confirm required");

          setAuthStateData({
            email: args[0],
          });
          navigate("/signup/confirm");
          return;
        }

        throw e;
      }
    },
    [navigate, setAuthStateData, setUser, trackEvent]
  );

  const signUp = useCallback(
    async ({ username, password, attributes, ...args }) => {
      trackEvent("Signup", {
        email: username,
      });

      try {
        const response = await Auth.signUp({ username, password, attributes });

        if (!response.userConfirmed) {
          setAuthStateData({ email: username, password, ...args });
          navigate("/signup/confirm");
          return;
        }

        navigate("/?signup=1");
      } catch (e) {
        if (e.code === "UsernameExistsException") {
          await signIn(username, password);
          return;
        }

        throw e;
      }
    },
    [navigate, setAuthStateData, trackEvent, signIn]
  );

  const signOut = useCallback(
    async (...args) => {
      trackEvent("Sign Out");

      setAuthStateData({});
      setUser(null);

      // Don't delete Cognito related keys from localStorage to proper Cognito sign out to call user pool logout endpoint
      // https://github.com/aws-amplify/amplify-js/blob/main/packages/auth/src/Auth.ts#L2164-L2170
      // Otherwise, Cognito will not call user pool logout endpoint and the user will not be logged out from the user pool.
      const keysToDelete = Object.keys(window.localStorage).filter(
        (key) =>
          !key.startsWith("CognitoIdentityServiceProvider.") &&
          !key.startsWith("amplify-")
      );

      console.log(keysToDelete);

      keysToDelete.forEach((key) => {
        window.localStorage.removeItem(key);
      });

      window.localStorage.setItem(
        "hasPreviouslySignedIn",
        Date.now().toString()
      );

      await Auth.signOut(...args);
    },
    [trackEvent]
  );

  const attemptAutoSignIn = useCallback(
    async (email, password) => {
      // this callback can automatically sign in a user if
      // email and password are available in authStateData.
      // If the signin fails, the user is redirected to the signin page.
      const signInOk = await Auth.signIn(email, password);

      if (!signInOk) {
        console.warn(
          "Cannot automatically signin user because no user was returned"
        );
        navigate("/signin" + location.search);
        return;
      }

      setAuthStateData({});
      setUser(await getUser());

      // forces a page refresh, because yes
      window.location.href = "/?autosignin=1";
    },
    [navigate, setAuthStateData, setUser, location.search]
  );

  const confirmSignUp = useCallback(
    async (email, code, ...args) => {
      trackEvent("Signup Confirm", {
        email,
      });

      await Auth.confirmSignUp(email, code, ...args);

      if (!authStateData.password) {
        console.warn(
          "Cannot automatically signin user because no password was provided"
        );
        navigate("/signin" + location.search);
        return;
      }

      attemptAutoSignIn(email, authStateData.password);
    },
    [
      trackEvent,
      authStateData.password,
      attemptAutoSignIn,
      navigate,
      location.search,
    ]
  );

  const confirmMFA = useCallback(
    async (user, code) => {
      trackEvent("Signin Confirm MFA");

      await Auth.confirmSignIn(user, code, "SOFTWARE_TOKEN_MFA");

      setUser(await getUser());
      navigate("/?confirmmfa=1");
    },
    [navigate, trackEvent]
  );

  const resendSignUp = useCallback(
    async (...args) => Auth.resendSignUp(...args),
    []
  );

  const forgotPassword = useCallback(
    async ({ email, code }) => {
      await Auth.forgotPassword(email, code);

      setAuthStateData({ email });
      navigate("/signin/reset-password/confirm");
    },
    [navigate]
  );

  const forgotPasswordSubmit = useCallback(
    async ({ email, code, password }) => {
      await Auth.forgotPasswordSubmit(email, code, password);
      attemptAutoSignIn(email, password);
    },
    [attemptAutoSignIn]
  );

  const completeNewPassword = useCallback(
    async ({ user, email, password }) => {
      await Auth.completeNewPassword(user, password);
      attemptAutoSignIn(email, password);
    },
    [attemptAutoSignIn]
  );

  const federatedSignIn = useCallback(
    async (provider) => {
      trackEvent("Signin Federated", {});
      await Auth.federatedSignIn({ provider });
    },
    [trackEvent]
  );

  const updateUser = useCallback(
    async (updateParams) => {
      await updateUserApi(updateParams);
      const user = await getUser();

      setUser(user);

      pubsub.publish("user:updated", user);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const enableMFA = useCallback(async ({ code }) => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    await Auth.verifyTotpToken(cognitoUser, code);
    await Auth.setPreferredMFA(cognitoUser, "TOTP");
    setUser((user) => ({
      ...user,
      preferredMFA: "SOFTWARE_TOKEN_MFA",
    }));
  }, []);

  const disableMFA = useCallback(async ({ code }) => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    await Auth.verifyTotpToken(cognitoUser, code);
    await Auth.setPreferredMFA(cognitoUser, "NOMFA");
    setUser((user) => ({
      ...user,
      preferredMFA: "NOMFA",
    }));
  }, []);

  const [userAttributes, setUserAttributes] = useState(null);

  const asyncGetAttributes = async () => {
    const amplifyUser = await Auth.currentAuthenticatedUser();
    const attrs = await Auth.userAttributes(amplifyUser);
    return attrs;
  };

  useEffect(() => {
    asyncGetAttributes().then(setUserAttributes);
  }, [user]);

  const fetchUserAttributes = useCallback(async () => {
    const attrs = await asyncGetAttributes();
    setUserAttributes(attrs);
  }, [setUserAttributes]);

  return (
    <AuthContext.Provider
      value={{
        authStateData,
        user,
        userAttributes,
        fetchUserAttributes,
        setUser,
        signIn,
        federatedSignIn,
        signUp,
        signOut,
        confirmSignUp,
        resendSignUp,
        forgotPassword,
        forgotPasswordSubmit,
        confirmMFA,
        updateUser,
        enableMFA,
        disableMFA,
        completeNewPassword,
        sessionToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

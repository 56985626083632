import React, { useCallback, useMemo } from "react";
import { truncate } from "lodash";
import ms from "ms";
import { withErrorBoundary } from "react-error-boundary";

import {
  CircleOutlined as CircleIcon,
  OpenInNewOutlined as OpenInNewIcon,
  PlayCircleOutlineOutlined as PlayCircleOutlineIcon,
} from "@mui/icons-material";

import { Report } from "../../../pages/BugTrackerPage/types";
import { Box, Stack, Tooltip, Typography, IconButton } from "@mui/material";
import { AutopilotError } from "./types";
import { useAppContext } from "../../../contexts/AppContext.js";
import useLoading from "../../../hooks/useLoading.js";
import Button from "../../Button.js";

const errorTypeNameMap = {
  "error-click": "Error Click",
  "rage-click": "Rage Click",
  "fetch-error": "Network Error",
  "load-error": "Load Error",
};

const getErrorTooltip = (error: AutopilotError) => {
  const { type, clickEvent, jsErrors, url, method, reason, statusCode } = error;

  if (type === "error-click") {
    let message = truncate(jsErrors[0].message || "No error message", {
      length: 100,
      omission: "...",
    });

    if (jsErrors.length > 1) {
      message += ` (+${jsErrors.length - 1} more)`;
    }

    return message;
    // old
    // return jsErrors.map(({ message }) => message).join(", ");
  }

  if (type === "rage-click") {
    return truncate(String(clickEvent.serializableTarget?.html), {
      length: 100,
      omission: "...",
    });
  }

  if (type === "fetch-error") {
    const humanizeURL = new URL(url);
    return `[${method}] ${humanizeURL.pathname}`;
  }

  if (type === "load-error") {
    return `Load error${
      reason === "page-empty"
        ? ". Page is empty"
        : reason === "page-response-status-code"
        ? `. Page loaded with status ${statusCode}`
        : ""
    }`;
  }
};

const humanizeErrorType = (type: string, count: number) => {
  const name = errorTypeNameMap[type];

  if (!name) {
    return type;
  }

  return `${name}${count > 1 ? "s" : ""}`;
};

type AutopilotErrorBag = {
  type: string;
  list: AutopilotError[];
  count: number;
};

const AutoPilotError = ({ companyId, index, error, switchTab, errorType }) => {
  const { muteError } = useAppContext();

  const { isLoading: isMuting, handler: muteErrors } = useLoading((errors) =>
    Promise.all(
      errors.map(({ type, ...data }) =>
        muteError({
          type,
          companyId,
          data,
        })
      )
    )
  );

  const timestamp = useMemo(() => {
    if (!error.clickEvent) return null;

    return error.clickEvent.timestamp;
  }, [error]);

  return (
    <ClickArea timestamp={timestamp} switchTab={switchTab}>
      <Stack spacing={0.5}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <CircleIcon
            sx={{
              color: "error.main",
              width: 8,
              height: 8,
            }}
            fontSize="small"
          />

          <Typography
            variant="body2"
            color="tertiary.main"
            sx={{
              fontSize: "0.6rem",
              textTransform: "uppercase",
              fontWeight: 700,
              letterSpacing: "70%",
            }}
          >
            {errorType}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
          style={{
            marginBottom: 8,
          }}
        >
          <Box flexGrow={1}>
            <Typography
              variant="body2"
              color="tertiary.main"
              sx={{
                fontSize: "0.8rem",
              }}
            >
              {getErrorTooltip(error)}
            </Typography>
          </Box>

          <Box>
            <Tooltip
              title={
                timestamp
                  ? `Start playing session from ${errorType} at ${new Intl.DateTimeFormat(
                      "en-GB",
                      {
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                      }
                    ).format(
                      typeof timestamp === "string"
                        ? new Date(timestamp).getTime()
                        : timestamp
                    )}`
                  : "Unknown time"
              }
              arrow
              placement="right"
            >
              <IconButton size={"small"} sx={{ padding: 0 }}>
                <PlayCircleOutlineIcon fontSize={"small"} />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      </Stack>
    </ClickArea>
  );
};

const AutopilotSummaryComponent = ({
  report,
  switchTab,
  reportContents,
}: {
  report: Report;
  switchTab: (tab: string) => void;
}) => {
  const autopilotErrors = useMemo(() => {
    if (!report.autopilotErrors) return [];

    return report.autopilotErrors;
  }, [report]);

  if (!autopilotErrors || autopilotErrors.length === 0) {
    return null;
  }

  const groupedErrors = autopilotErrors.reduce((acc, error) => {
    const key = error.type;
    if (acc[key]) {
      acc[key].list.push(error);
      acc[key].count++;
      return acc;
    }

    acc[key] = {
      type: error.type,
      list: [error],
      count: 1,
    };

    return acc;
  }, {} as Record<string, AutopilotErrorBag>);

  if (Object.keys(groupedErrors).length === 0) {
    return null;
  }

  const vercelRequestIds = reportContents
    .filter(
      ({ eventType, type, data }) =>
        eventType === "activities" &&
        type === "fetchResponse" &&
        data?.headers?.["x-vercel-id"] &&
        !data.ok
    )
    .map(({ data }) => data.headers["x-vercel-id"].split("::").slice(-1)[0]);

  return (
    <Stack spacing={1}>
      <Typography
        sx={{
          textTransform: "uppercase",
          fontWeight: 600,
          fontSize: 11,
          padding: 0,
          margin: 0,
        }}
        color="secondary.text"
      >
        Detected Problems
      </Typography>

      {Object.entries(groupedErrors).map(([type, bag], i) =>
        bag.list.map((error, ii) => (
          <AutoPilotError
            key={error.clickEvent?.timestamp}
            index={i + "-" + ii}
            error={error}
            companyId={report.companyId}
            switchTab={switchTab}
            errorType={humanizeErrorType(type, bag.list.length)}
          />
        ))
      )}

      {vercelRequestIds.length > 0 &&
        report.integrations?.vercel?.inspectorUrl && (
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();

              const logsUrl = `${report.integrations.vercel.inspectorUrl
                .split("/")
                .slice(0, -1)
                .join("/")}/logs`;

              vercelRequestIds.forEach((requestId) => {
                const url = new URL(logsUrl);

                url.searchParams.set("requestIds", requestId);
                url.searchParams.set("timeline", "absolute");
                url.searchParams.set(
                  "startDate",
                  (report.timestamp - ms("10m")).toString()
                );
                url.searchParams.set(
                  "endDate",
                  (report.timestamp + ms("10m")).toString()
                );

                window.open(url.toString(), "_blank");
              });
            }}
            startIcon={<OpenInNewIcon />}
          >
            Vercel Logs
          </Button>
        )}
    </Stack>
  );
};

function ClickArea({
  timestamp,
  switchTab,
  children,
}: {
  timestamp: Date | null;
  switchTab: (tab: string) => void;
  children: React.ReactNode;
}) {
  const handleClick = useCallback(() => {
    switchTab("session");

    const seek = () => {
      const iframe = document.getElementById(
        "bugpilot-player-iframe"
      ) as HTMLIFrameElement | null;

      iframe?.contentWindow?.postMessage?.(
        {
          type: "io.bugpilot.player::seek",
          timestamp,
        },
        "*"
      );
    };

    if (document.getElementById("bugpilot-player-iframe")) {
      seek();
    } else {
      setTimeout(() => seek, 2 * 1000);
    }
  }, [switchTab, timestamp]);

  return (
    <Box
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        userSelect: "none",
      }}
    >
      {children}
    </Box>
  );
}

export const AutopilotSummary = withErrorBoundary(AutopilotSummaryComponent, {
  fallbackRender: () => "An error occurred in the AutopilotSummary component",
  onError(error, info) {
    console.error("ErrorBoundary caught an error:", error, info);
  },
});

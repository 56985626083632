import React, {
  SyntheticEvent,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";

import {
  ConsoleLogArg,
  ConsoleLogData,
  RageClickItem,
  StepToReproduce,
} from "../AutopilotGroup/types";

import { useAutopilot } from "../AutopilotGroup/AutopilotContext.tsx";
import type { AutopilotData } from "../AutopilotGroup/types";
import { Report } from "../../../pages/BugTrackerPage/types";

const BugTranscriptContext = createContext<{
  text: string;
}>({
  text: "",
});

export function BugTranscriptProvider({
  report,
  children,
}: {
  report: Report;
  children: React.ReactNode;
}) {
  const { data } = useAutopilot();
  const autopilot = data as AutopilotData;

  const steps: StepToReproduce[] = useMemo(() => {
    if (data?.stepsToReproduce) {
      return data.stepsToReproduce;
    }

    return [];
  }, [data]);

  const humanSteps = useMemo(() => {
    return steps.map((step: StepToReproduce, index) => {
      const [action, tagName, name, value] = step.label;
      const timeString = new Date(step.timestamp).toLocaleTimeString();

      if (action === "click") {
        return `${
          index + 1
        }. [${timeString}] Click on <${tagName}> with content (or name) "${name}"`;
      }

      if (action === "type") {
        return `${
          index + 1
        }. [${timeString}] Type "${value}" in <${tagName}> ${name}`;
      }

      return step.label.join(" ");
    });
  }, [steps]);

  const networkErrors = useMemo(() => {
    if (!autopilot) {
      return [];
    }

    return autopilot.networkErrorsList.map((error) => {
      const timeString = new Date(error.timestamp).toLocaleTimeString();

      const data = error.data;
      const status = data.response?.status
        ? `HTTP ${data.response.status}`
        : "BLOCKED REQUEST";

      return `- [${timeString}] ${status} ${data.url}`;
    });
  }, [autopilot]);

  const rageClicks = useMemo(() => {
    if (!autopilot) {
      return [];
    }

    return autopilot.rageClicksList.map((click: RageClickItem) => {
      const label = click.item.data.target.text;
      const timestamps = click.items
        .map((item) => item.timestamp)
        .map((t) => new Date(t).toLocaleTimeString());

      return `- [${new Date(
        click.timestamp
      ).toLocaleTimeString()}] Repeated click ${
        click.count
      } times on "${label}" (at ${timestamps.join(", ")})`;
    });
  }, [autopilot]);

  const frontendErrors = useMemo(() => {
    if (!autopilot) {
      return [];
    }

    return autopilot.frontendErorrsList.map((error: ConsoleLogData) => {
      const timeString = new Date(error.timestamp).toLocaleTimeString();

      const extractValue = (arg: ConsoleLogArg) => {
        if (typeof arg === "string") {
          return arg;
        }

        if (typeof arg.value === "string") {
          return arg.value;
        }
        try {
          return JSON.stringify(arg.value);
        } catch (e) {
          return String(arg);
        }
      };

      return `- [${timeString}] ${error.data.args.map(extractValue).join(" ")}`;
    });
  }, [autopilot]);

  const text = useMemo(() => {
    return [
      `Starting url is ${report.url}`,

      "",
      "# Bug is " +
        (report.metadata?.userProvidedDescription
          ? "manually reported by the end-user"
          : "automatically detecteed via autopilot"),

      "",
      "# Report title is:",
      report.title,

      ...(report.metadata?.userProvidedDescription
        ? [
            "",
            "# User has provided this description:",
            report.metadata.userProvidedDescription,
          ]
        : []),

      "",
      "# User details (JSON):",
      JSON.stringify(report.user),

      "",
      "# User browser and system info:",
      "Browser: " +
        Object.entries(report.userAgent?.browser || {})
          .map(([_, v]) => v)
          .join(" "),
      "Screen Size: " +
        (report.screen
          ? `${report.screen.width}x${report.screen.height}`
          : "unknown"),
      "Timezone: " + report.timeZone,
      "Language: " + report.language,
      "User agent: " + report.userAgentString,

      "",
      "# Rage clicks:",
      ...(rageClicks.length ? rageClicks : ["No rage clicks"]),

      "",
      "# Network errors:",
      ...(networkErrors.length ? networkErrors : ["No network errors"]),

      "",
      "# Frontend errors:",
      ...(frontendErrors.length ? frontendErrors : ["No frontend errors"]),

      "",
      "# Actions performed by the user (steps to reproduce):",
      ...humanSteps,
    ].join("\n");
  }, [report, humanSteps, networkErrors, rageClicks, frontendErrors]);

  return (
    <BugTranscriptContext.Provider value={{ text }}>
      {children}
    </BugTranscriptContext.Provider>
  );
}

export const useBugTranscript = () => useContext(BugTranscriptContext);

const StepsAsText = () => {
  const { text } = useBugTranscript();

  const copyToClipboard = useCallback(
    (e: SyntheticEvent<HTMLTextAreaElement>) => {
      e.currentTarget?.select();
      navigator.clipboard.writeText(text);
    },
    [text]
  );

  return (
    <textarea
      style={{
        width: "100%",
        height: 400,
        lineHeight: 1.5,
        padding: 8,
      }}
      onClick={copyToClipboard}
      value={text}
      readOnly
    ></textarea>
  );
};

export function StepsAsTextContainer({ report }) {
  return (
    <BugTranscriptProvider report={report}>
      <StepsAsText />
    </BugTranscriptProvider>
  );
}

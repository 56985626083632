import { noop, groupBy } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  listWorkspaceIntegrations as listWorkspaceIntegrationsApi,
  createIntegration as createIntegrationApi,
  updateIntegration as updateIntegrationApi,
  getAtlassianIntegrationIssueTypes as getAtlassianIntegrationIssueTypesApi,
  getAtlassianIntegrationProjects as getAtlassianIntegrationProjectsApi,
  deleteIntegration as deleteIntegrationApi,
  getNotionIntegrationPages as getNotionIntegrationPagesApi,
  getLiveChatOrganizationId as getLiveChatOrganizationIdApi,
  getIntercomAppId as getIntercomAppIdApi,
  getCrispWebsiteId as getCrispWebsiteIdApi,
  getHelpScoutData as getHelpScoutDataApi,
  createHelpScoutData as createHelpScoutDataApi,
  getZendeskSubdomain as getZendeskSubdomainApi,
  getFrontTeamId as getFrontTeamIdApi,
} from "../api/index";

import { withOptimisticUpdates } from "../hooks/withOptimisticUpdates.tsx";
import { useModals } from "../hooks/useModals";
import { useAppContext } from "../contexts/AppContext";

export const INTEGRATION_REDIRECT_URIS = {
  slack: `https://dash.bugpilot.io/settings/integrations`,
  atlassian: `https://dash.bugpilot.io/settings/integrations/atlassian`,
  notion: `https://dash.bugpilot.io/settings/integrations/notion`,
  clickup: `https://dash.bugpilot.io/settings/integrations/clickup`,
  github: `https://dash.bugpilot.io/settings/integrations/github`,
  vercel: `${window.location.protocol}//${window.location.host}/settings/integrations/vercel`,
};

const SLACK_CLIENT_ID = "2518739531683.3658444255908";
const SLACK_SCOPES = ["incoming-webhook"];
const ATLASSIAN_CLIENT_ID = "96yn6NqLj1TxLbfHLeRqcGy6o3vbztdH";
const ATLASSIAN_SCOPES = [
  "write:jira-work",
  "read:jira-work",
  // For obtaining refresh_token
  // https://community.developer.atlassian.com/t/where-do-i-initially-obtain-a-refresh-token-for-jira/45470
  "offline_access",
];
const NOTION_CLIENT_ID = "d771c808-86a8-440c-bc11-56fb48ab3ec8";
const CLICKUP_CLIENT_ID = "1242FAOODDN48US7IO5V8NJ7SHCAXDCU";
const GITHUB_CLIENT_ID = "88681f570e140afb303b";
const GITHUB_SCOPES = ["user", "repo", "project", "read:org"];

const integrationsContext = createContext({
  isWorkspaceIntegrationsLoading: false,
  workspaceIntegrations: [],
  fetchAtlassianIntegrationProjects: noop,
  fetchAtlassianIntegrationIssueTypes: noop,
  atlassianIntegrationProjects: [],
  atlassianIntegrationIssueTypes: [],
  isAtlassianIntegrationProjectsLoading: false,
  isAtlassianIntegrationIssueTypesLoading: false,
  fetchNotionIntegrationPages: noop,
  notionIntegrationPages: [],
  isNotionIntegrationPagesLoading: false,
  createWorkspaceIntegration: noop,
  fetchWorkspaceIntegrations: noop,
  updateCompanyIntegration: noop,
  deleteCompanyIntegration: noop,

  setIntercomAppId: noop,
  intercomAppId: null,
  setCrispWebsiteId: noop,
  crispWebsiteId: null,
  setLiveChatOrganizationId: noop,
  liveChatOrganizationId: null,
  fetchIntercomAppId: noop,
  fetchLiveChatOrganizationId: noop,
  fetchCrispWebsiteId: noop,
  helpScoutCallbackUrl: null,
  helpScoutSecret: null,
  fetchHelpScoutData: noop,
  createHelpScoutData: noop,
  fetchZendeskSubdomain: noop,
  zendeskSubdomain: null,
  setZendeskSubdomain: noop,
  fetchFrontTeamId: noop,
  frontTeamId: null,
  setFrontTeamId: noop,

  addToFront: noop,
  addToHelpScout: noop,
  addToIntercom: noop,
  addToZendesk: noop,
  addToLivechat: noop,
  addToCrisp: noop,
  addSlack: noop,
  addJira: noop,
  addZapier: noop,
  addMake: noop,
  addGitHub: noop,
  addClickUp: noop,
  addNotion: noop,
  addVercel: noop,

  helpdeskConnected: false,
  updateHelpdeskStatus: noop,
  integrationsByType: {},
});

export const IntegrationsProvider = ({ children }) => {
  const { selectedCompanyId: selectedWorkspaceId } = useAppContext();
  const modals = useModals();

  const { selectedCompanyId } = useAppContext();

  const [workspaceIntegrations, setWorkspaceIntegrations] = useState([]);
  const [atlassianIntegrationProjects, setAtlassianIntegrationProjects] =
    useState([]);
  const [notionIntegrationPages, setNotionIntegrationPages] = useState([]);
  const [atlassianIntegrationIssueTypes, setAtlassianIntegrationIssueTypes] =
    useState([]);

  const [liveChatOrganizationId, setLiveChatOrganizationId] = useState(null);
  const [intercomAppId, setIntercomAppId] = useState(null);
  const [crispWebsiteId, setCrispWebsiteId] = useState(null);
  const [helpScoutSecret, setHelpScoutSecret] = useState(null);
  const [helpScoutCallbackUrl, setHelpScoutCallbackUrl] = useState(null);
  const [zendeskSubdomain, setZendeskSubdomain] = useState(null);
  const [frontTeamId, setFrontTeamId] = useState(null);

  const [helpdeskConnected, setHelpdeskConnected] = useState(false);

  const fetchIntercomAppId = useCallback(async ({ companyId }) => {
    if (!companyId) {
      return false;
    }

    const appId = await getIntercomAppIdApi({ companyId });
    setIntercomAppId(appId);
  }, []);

  const fetchLiveChatOrganizationId = useCallback(async ({ companyId }) => {
    const organizationId = await getLiveChatOrganizationIdApi({ companyId });

    setLiveChatOrganizationId(organizationId);
  }, []);

  const fetchCrispWebsiteId = useCallback(async ({ companyId }) => {
    const websiteId = await getCrispWebsiteIdApi({ companyId });

    setCrispWebsiteId(websiteId);
  }, []);

  const fetchHelpScoutData = useCallback(async ({ companyId }) => {
    const data = await getHelpScoutDataApi({ companyId });

    if (!data) {
      return;
    }

    const { callbackUrl, secret } = data;
    setHelpScoutCallbackUrl(callbackUrl);
    setHelpScoutSecret(secret);
  }, []);

  const createHelpScoutData = useCallback(async ({ companyId }) => {
    const data = await createHelpScoutDataApi({ companyId });

    if (!data) {
      return;
    }

    const { callbackUrl, secret } = data;
    setHelpScoutCallbackUrl(callbackUrl);
    setHelpScoutSecret(secret);

    return { callbackUrl, secret };
  }, []);

  const fetchZendeskSubdomain = useCallback(async ({ companyId }) => {
    const subdomain = await getZendeskSubdomainApi({ companyId });

    setZendeskSubdomain(subdomain);
  }, []);

  const fetchFrontTeamId = useCallback(async ({ companyId }) => {
    const teamId = await getFrontTeamIdApi({ companyId });

    setFrontTeamId(teamId);
  }, []);

  const fetchWorkspaceIntegrations = useCallback(async (companyId) => {
    const integrations = await listWorkspaceIntegrationsApi(companyId);
    setWorkspaceIntegrations(integrations.integrations);
  }, []);

  const fetchAtlassianIntegrationProjects = useCallback(async (companyId) => {
    const projects = await getAtlassianIntegrationProjectsApi(companyId);
    setAtlassianIntegrationProjects(projects);

    return projects;
  }, []);

  const fetchAtlassianIntegrationIssueTypes = useCallback(async (companyId) => {
    const issueTypes = await getAtlassianIntegrationIssueTypesApi(companyId);
    setAtlassianIntegrationIssueTypes(issueTypes);

    return issueTypes;
  }, []);

  const fetchNotionIntegrationPages = useCallback(
    async (companyId, { type = "database-item" } = {}) => {
      const pages = await getNotionIntegrationPagesApi(companyId);
      const result = pages
        .filter(
          ({ object }) => object === (type === "page" ? "page" : "database")
        )
        .map(({ id, properties, title }) => ({
          id,
          name:
            type === "page"
              ? properties.title?.title?.[0]?.plain_text
              : title?.[0]?.plain_text,
          ...(type === "database-item" && {
            columns: Object.entries(properties)
              .filter(([_, { type }]) => type === "title")
              .map(([name]) => name),
          }),
        }));

      setNotionIntegrationPages(result);

      return result;
    },
    []
  );

  const createWorkspaceIntegration = useCallback(
    async (companyId, payload) => {
      const integration = await createIntegrationApi(
        companyId || selectedCompanyId,

        payload
      );
      setWorkspaceIntegrations((integrations) => [
        ...integrations,
        integration,
      ]);
    },
    [selectedCompanyId]
  );

  const updateCompanyIntegration = useCallback(
    async ({ companyId, type, integration }) =>
      withOptimisticUpdates(
        () =>
          updateIntegrationApi({
            companyId: companyId || selectedCompanyId,
            type,
            integration,
          }),
        workspaceIntegrations,
        setWorkspaceIntegrations,
        {
          primaryColumns: ["type", "id"],
        }
      )({ id: companyId, type, ...integration }),
    [selectedCompanyId, workspaceIntegrations]
  );

  const deleteCompanyIntegration = useCallback(
    async ({ companyId, type }) => {
      const integrationsSnapshot = [...workspaceIntegrations];
      try {
        setWorkspaceIntegrations((integrations) =>
          integrations.filter(
            (integration) =>
              integration.type !== type || integration.id !== companyId
          )
        );
        await deleteIntegrationApi({
          companyId,
          type,
        });
      } catch (e) {
        setWorkspaceIntegrations(integrationsSnapshot);
        throw e;
      }
    },
    [workspaceIntegrations]
  );

  const handleAddToIntercom = useCallback(() => {
    const url = new URL(
      "https://bugpilot-intercom.adoptoapp.workers.dev/redirect"
    );
    url.searchParams.set("workspaceId", selectedCompanyId);
    url.searchParams.set("source", "dash");
    url.searchParams.set("provider", "intercom");

    window.location = url.toString();
  }, [selectedCompanyId]);

  const handleAddToLivechat = useCallback(() => {
    const url = new URL("https://api.bugpilot.io/livechat/installation");
    url.searchParams.set("workspaceId", selectedCompanyId);
    url.searchParams.set("source", "dash");

    window.location = url.toString();
  }, [selectedCompanyId]);

  const handleAddToCrisp = useCallback(() => {
    const url = new URL("https://api.bugpilot.io/crisp/installation");
    url.searchParams.set("workspaceId", selectedCompanyId);
    url.searchParams.set("source", "dash");

    window.location = url.toString();
  }, [selectedCompanyId]);

  const handleAddToZendesk = useCallback(() => {
    // const url = new URL("https://api.bugpilot.io/zendesk/installation");
    // url.searchParams.set("workspaceId", selectedCompanyId);
    // url.searchParams.set("source", "dash");
    //
    // return url.toString();

    window.location =
      "https://www.zendesk.com/marketplace/apps/support/877301/bugpilot/";
  }, []);

  const handleAddSlack = useCallback(() => {
    const uuid = crypto.randomUUID();
    const state = JSON.stringify({ uuid });
    window.localStorage.setItem("oauth-expected-state", state);

    const slackUrl = new URL("https://slack.com/oauth/v2/authorize");
    slackUrl.searchParams.append(
      "redirect_uri",
      INTEGRATION_REDIRECT_URIS.slack
    );
    slackUrl.searchParams.append("client_id", SLACK_CLIENT_ID);
    slackUrl.searchParams.append("scope", SLACK_SCOPES.join(",")); // bot scopes
    slackUrl.searchParams.append("user_scope", "");
    slackUrl.searchParams.append("state", state);

    window.location.href = slackUrl.toString();
  }, []);

  const handleAddJira = useCallback(() => {
    const uuid = crypto.randomUUID();
    const state = JSON.stringify({ uuid });
    window.localStorage.setItem("oauth-expected-state", state);

    const atlassianUrl = new URL("https://auth.atlassian.com/authorize");
    atlassianUrl.searchParams.append(
      "redirect_uri",
      INTEGRATION_REDIRECT_URIS.atlassian
    );
    atlassianUrl.searchParams.append("client_id", ATLASSIAN_CLIENT_ID);
    atlassianUrl.searchParams.append("scope", ATLASSIAN_SCOPES.join(" ")); // bot scopes
    atlassianUrl.searchParams.append("state", state);
    atlassianUrl.searchParams.append("response_type", "code");
    atlassianUrl.searchParams.append("prompt", "consent");
    atlassianUrl.searchParams.append("audience", "api.atlassian.com");

    window.location.href = atlassianUrl.toString();
  }, []);

  const handleAddNotion = useCallback(() => {
    const uuid = crypto.randomUUID();
    const state = JSON.stringify({ uuid });
    window.localStorage.setItem("oauth-expected-state", state);

    const notionUrl = new URL("https://api.notion.com/v1/oauth/authorize");
    notionUrl.searchParams.append(
      "redirect_uri",
      INTEGRATION_REDIRECT_URIS.notion
    );
    notionUrl.searchParams.append("client_id", NOTION_CLIENT_ID);
    notionUrl.searchParams.append("state", state);
    notionUrl.searchParams.append("response_type", "code");
    notionUrl.searchParams.append("owner", "user");

    window.location.href = notionUrl.toString();
  }, []);

  const handleAddZapier = useCallback(() => {
    window.open("https://zapier.com/apps/adopto/integrations", "_blank");
  }, []);

  const handleAddMake = useCallback(() => {
    window.open(
      "https://www.make.com/en/hq/app-invitation/77b7b7cfaecac1f7985b155b1bc77788",
      "_blank"
    );
  }, []);

  const handleAddClickUp = useCallback(() => {
    const uuid = crypto.randomUUID();
    const state = JSON.stringify({ uuid });
    window.localStorage.setItem("oauth-expected-state", state);

    const clickUpUrl = new URL("https://app.clickup.com/api");
    clickUpUrl.searchParams.append(
      "redirect_uri",
      INTEGRATION_REDIRECT_URIS.clickup
    );
    clickUpUrl.searchParams.append("client_id", CLICKUP_CLIENT_ID);
    clickUpUrl.searchParams.append("state", state);

    window.location.href = clickUpUrl.toString();
  }, []);

  const handleAddVercel = useCallback(() => {
    window.open("https://vercel.com/integrations/bugpilot", "_blank");
  }, []);

  const handleAddGitHub = useCallback(() => {
    const uuid = crypto.randomUUID();
    const state = JSON.stringify({ uuid });
    window.localStorage.setItem("oauth-expected-state", state);

    const url = new URL("https://github.com/login/oauth/authorize");
    url.searchParams.append("redirect_uri", INTEGRATION_REDIRECT_URIS.github);
    url.searchParams.append("client_id", GITHUB_CLIENT_ID);
    url.searchParams.append("scope", GITHUB_SCOPES.join(" "));
    url.searchParams.append("state", state);

    window.location.href = url.toString();
  }, []);

  const handleAddToHelpScout = useCallback(async () => {
    if (!helpScoutCallbackUrl) {
      const { callbackUrl, secret } = await createHelpScoutData({
        companyId: selectedCompanyId,
      });
      modals.openModal(modals.modalTypes.HelpScout, {
        callbackUrl,
        secret,
      });
      return;
    }

    modals.openModal(modals.modalTypes.HelpScout, {
      callbackUrl: helpScoutCallbackUrl,
      secret: helpScoutSecret,
    });
  }, [
    helpScoutCallbackUrl,
    modals,
    helpScoutSecret,
    createHelpScoutData,
    selectedCompanyId,
  ]);

  const handleAddToFront = useCallback(async () => {
    window.open("https://front.com/integrations/bugpilot", "_blank");
  }, [modals]);

  const integrationsByType = useMemo(
    () => ({
      ...(intercomAppId && { intercom: [{ integrationType: "intercom" }] }),
      ...(liveChatOrganizationId && {
        livechat: [{ integrationType: "livechat" }],
      }),
      ...(helpScoutCallbackUrl && {
        helpscout: [{ integrationType: "helpscout" }],
      }),
      ...(crispWebsiteId && { crisp: [{ integrationType: "crispintercom" }] }),
      ...(zendeskSubdomain && { zendesk: [{ integrationType: "zendesk" }] }),
      ...(frontTeamId && { front: [{ integrationType: "front" }] }),
      ...groupBy(workspaceIntegrations, "integrationType"),
    }),
    [
      crispWebsiteId,
      frontTeamId,
      helpScoutCallbackUrl,
      intercomAppId,
      liveChatOrganizationId,
      workspaceIntegrations,
      zendeskSubdomain,
    ]
  );

  const updateHelpdeskStatus = useCallback(async () => {
    fetchIntercomAppId({ companyId: selectedCompanyId });
    fetchLiveChatOrganizationId({ companyId: selectedCompanyId });
    fetchCrispWebsiteId({ companyId: selectedCompanyId });
    fetchHelpScoutData({ companyId: selectedCompanyId });
    fetchZendeskSubdomain({ companyId: selectedCompanyId });
    fetchFrontTeamId({ companyId: selectedCompanyId });
  }, [
    fetchCrispWebsiteId,
    fetchFrontTeamId,
    fetchHelpScoutData,
    fetchIntercomAppId,
    fetchLiveChatOrganizationId,
    fetchZendeskSubdomain,
    selectedCompanyId,
  ]);

  useEffect(() => {
    if (!selectedWorkspaceId) {
      return;
    }

    if (
      crispWebsiteId ||
      intercomAppId ||
      liveChatOrganizationId ||
      helpScoutCallbackUrl ||
      zendeskSubdomain ||
      frontTeamId
    ) {
      setHelpdeskConnected(true);
    } else {
      setHelpdeskConnected(false);
    }
  }, [
    selectedWorkspaceId,
    crispWebsiteId,
    frontTeamId,
    helpScoutCallbackUrl,
    intercomAppId,
    liveChatOrganizationId,
    zendeskSubdomain,
  ]);

  // Fetch data on load
  useEffect(() => {
    if (!selectedWorkspaceId) {
      return;
    }

    fetchWorkspaceIntegrations(selectedWorkspaceId).then(() => {
      updateHelpdeskStatus();
    });
  }, [selectedWorkspaceId, updateHelpdeskStatus, fetchWorkspaceIntegrations]);

  return (
    <integrationsContext.Provider
      value={{
        createWorkspaceIntegration,
        fetchWorkspaceIntegrations,

        workspaceIntegrations,
        integrationsByType,

        updateCompanyIntegration,
        deleteCompanyIntegration,

        fetchAtlassianIntegrationProjects,
        fetchAtlassianIntegrationIssueTypes,
        atlassianIntegrationProjects,
        atlassianIntegrationIssueTypes,

        fetchNotionIntegrationPages,
        notionIntegrationPages,

        addToFront: handleAddToFront,
        addToHelpScout: handleAddToHelpScout,
        addToIntercom: handleAddToIntercom,
        addToZendesk: handleAddToZendesk,
        addToLivechat: handleAddToLivechat,
        addToCrisp: handleAddToCrisp,
        addSlack: handleAddSlack,
        addJira: handleAddJira,
        addZapier: handleAddZapier,
        addGitHub: handleAddGitHub,
        addClickUp: handleAddClickUp,
        addNotion: handleAddNotion,
        addVercel: handleAddVercel,
        addMake: handleAddMake,

        intercomAppId,
        liveChatOrganizationId,
        crispWebsiteId,
        zendeskSubdomain,
        frontTeamId,
        helpScoutCallbackUrl,
        setIntercomAppId,
        setCrispWebsiteId,
        setLiveChatOrganizationId,
        fetchIntercomAppId,
        fetchLiveChatOrganizationId,
        fetchCrispWebsiteId,
        helpScoutSecret,
        fetchHelpScoutData,
        createHelpScoutData,
        fetchZendeskSubdomain,
        setZendeskSubdomain,
        fetchFrontTeamId,
        setFrontTeamId,

        helpdeskConnected,
        updateHelpdeskStatus,
      }}
    >
      {children}
    </integrationsContext.Provider>
  );
};

export const useIntegrations = () => {
  return useContext(integrationsContext);
};

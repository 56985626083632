import { useMemo } from "react";
import { Tooltip, Typography } from "@mui/material";

import { useAppContext } from "../../contexts/AppContext";
import MemberAvatar from "../../components/MemberAvatar.tsx";

const AssigneeBadge = ({
  report,
  tooltip = true,
  size,
  withName = false,
  memberAvatarProps = {},
  nameProps = {},
}) => {
  const { companyMembers } = useAppContext();
  const assignee = useMemo(
    () =>
      companyMembers.find((m) => [m.id, m.email].includes(report.assigneeSub)),
    [companyMembers, report.assigneeSub]
  );

  if (!assignee) {
    return null;
  }

  if (withName) {
    const label = assignee.firstName ?? assignee.email;

    return (
      <>
        <MemberAvatar member={assignee} size={size} {...memberAvatarProps} />
        <Typography
          variant="body2"
          color="tertiary.dark"
          noWrap
          maxWidth={100}
          {...nameProps}
        >
          {label}
        </Typography>
      </>
    );
  }

  return (
    <Tooltip title={tooltip && `Assigned to ${assignee.name}`} arrow>
      <MemberAvatar member={assignee} size={size} {...memberAvatarProps} />
    </Tooltip>
  );
};

export default AssigneeBadge;

import React, { useMemo } from "react";
import { Avatar, Typography } from "@mui/material";
import { withErrorBoundary } from "react-error-boundary";

const getRandomColor = (str) => {
  // get a random hex color based on the string
  const hash = str.split("").reduce((acc, char) => {
    acc = char.charCodeAt(0) + ((acc << 5) - acc);
    return acc & acc;
  }, 0);

  let color = Math.floor(Math.abs(((Math.sin(hash) * 10000) % 1) * 16777216))
    .toString(16)
    .substring(0, 6);

  // avoid bright colors by checking that the hex value in each pair
  // is greater than 0x40 (64)

  const limit = "c0";

  if (parseInt(color.substring(0, 2), 16) > parseInt(limit, 16)) {
    color = `${limit}${color.substring(2)}`;
  }
  if (parseInt(color.substring(2, 4), 16) > parseInt(limit, 16)) {
    color = `${color.substring(0, 2)}${limit}${color.substring(4)}`;
  }
  if (parseInt(color.substring(4, 6), 16) > parseInt(limit, 16)) {
    color = `${color.substring(0, 4)}${limit}`;
  }

  return `#${color}`;
};

export type CompanyMember = {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  initials: string;
  email: string;
};

type MemberAvatarProps = {
  member: CompanyMember;
  size?: "small" | "large" | number;
  typographyProps?: any;
};

export const AUTOPILOT = {
  id: "autopilot",
  name: "Bugpilot AI",
  firstName: "Bugpilot",
  lastName: "AI",
  initials: "AI",
  email: "support@bugpilot.io",
};

const MemberAvatar = ({
  member,
  size = "small",
  typographyProps = {},
  ...props
}: MemberAvatarProps) => {
  const sx = useMemo(
    () => ({
      width: typeof size === "number" ? size : size === "small" ? 24 : 32,
      height: typeof size === "number" ? size : size === "small" ? 24 : 32,
      ...props.sx,
    }),
    [size, props.sx]
  );

  if (!member) {
    return <Avatar variant="rounded" sx={sx} />;
  }

  return (
    <Avatar
      variant="rounded"
      sx={{
        ...sx,
        backgroundColor: (theme) =>
          member === AUTOPILOT
            ? theme.palette.primary.accent
            : getRandomColor(member.id || member.sub),
        textTransform: "uppercase",
      }}
      src={member.picture}
    >
      <Typography variant="body2" component="span" {...typographyProps}>
        {member.initials || member.email.charAt(0)}
      </Typography>
    </Avatar>
  );
};

export default withErrorBoundary(MemberAvatar, {
  fallbackRender(props) {
    return (
      <Typography variant="body2" component="span">
        ERR
      </Typography>
    );
  },
});

import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";

const Select = ({
  label = "",
  fetch,
  getOptionLabel,
  value,
  onChange,
  disabled = false,
  renderOption,
  groupBy,
  disableClearable = true,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      fetch()
        .then((data) => {
          setOptions(data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
      return;
    }
    setOptions([]);
  }, [isOpen]);

  const handleChange = useCallback(
    (e, data, reason) => {
      if (!["selectOption", "clear"].includes(reason)) {
        return;
      }

      onChange?.(data);
    },
    [onChange]
  );

  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableClearable={disableClearable}
      open={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      value={value}
      onClose={() => {
        setIsOpen(false);
      }}
      getOptionLabel={getOptionLabel || ((option) => option.name)}
      renderOption={renderOption}
      options={options}
      loading={isLoading}
      onChange={handleChange}
      groupBy={groupBy}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress size={25} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default Select;

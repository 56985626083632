import React, { useMemo } from "react";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import {
  Typography,
  Box,
  Stack,
  useMediaQuery,
  Theme,
  Divider,
  Chip,
} from "@mui/material";
import ChangeLogComponent from "./ChangeLog";
import { ActivitiesProps } from "./types.tsx";
import { CommentComponent } from "./CommentComponent.tsx";
import { CommentInputComponent } from "./index.tsx";
import { usePreferences } from "../../../contexts/UserPreferencesContext";
import AssigneeBadge from "../../../pages/BugTrackerPage/AssigneeBadge.js";
import { format } from "date-fns";

// eslint-disable-next-line react/display-name
export const Activities = forwardRef(
  (
    {
      items,
      onCreate,
      user,
      onUpdate,
      onDelete,
      onReply,
      companyMembers,
      reportId,
      report,
      setReport,
    }: ActivitiesProps,
    ref
  ) => {
    const { preferences, updatePreferences } = usePreferences();

    const [visibility, setVisibility] = useState<"full" | "minimized">(
      preferences?.chatVisibility || "minimized"
    );

    // check if xs breakpoint using mui breakpoints
    const isXS = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    const [fullscreen, setFullscreen] = useState(false);
    const shouldRenderFullscreen = useMemo(() => {
      return fullscreen || isXS;
    }, [fullscreen, isXS]);

    const onToggleChatVisibility = useCallback(
      (e) => {
        if (fullscreen) {
          setFullscreen(false);
        }

        let nextVisibility;
        if (visibility === "minimized") {
          nextVisibility = "full";
        }

        if (visibility === "full") {
          nextVisibility = "minimized";
        }

        setVisibility(nextVisibility);
        updatePreferences({
          chatVisibility: nextVisibility,
        });
      },
      [fullscreen, updatePreferences, visibility]
    );

    const expandTimer = useRef<any | null>(null);

    const captureKeyboardFocus = useCallback(
      (e) => {
        // listener.current = window.addEventListener("keydown", (e) => {
        //   // if the current focused element is our editor, do nothing
        //   if (e.target?.id?.includes("mui-rte")) {
        //     return;
        //   }
        //   e.stopPropagation();
        //   ref.current.focus();

        if (visibility === "minimized") {
          expandTimer.current = setTimeout(() => {
            setVisibility("full");
          }, 600);
        }
      },
      [visibility]
    );

    const releaseKeyboardFocus = useCallback(() => {
      clearTimeout(expandTimer.current);
      // window.removeEventListener("keydown", listener.current);
    }, []);

    const scrollRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = useCallback(() => {
      if (!scrollRef.current) {
        return;
      }

      scrollRef.current?.scrollTo({
        top: scrollRef.current.scrollHeight,
      });
    }, []);

    useEffect(() => {
      // scroll to bottom every time the items change,
      // for example because a new message was added
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }, [items.length, scrollToBottom, visibility]);

    const preparedItems = useMemo(
      () =>
        items.filter(
          ({ type, column }) =>
            type !== "change-log" || column !== "description"
        ),
      [items]
    );

    const reportReason = useMemo(() => {
      if (report.metadata?.triggerType === "autopilot") {
        return "Autopilot identified this issue on";
      }

      if (report.metadata?.triggerType === "sdk") {
        return "Bug report created via SDK on";
      }

      if (report.metadata?.triggerType === "wss") {
        return "Bug report created via a 3rd-party integration on";
      }

      if (report.metadata?.triggerType === "helpdesk-listener") {
        return "Bug report created via open helpdesk chat bubble on";
      }

      return "The user reported a problem on";
    }, [report.metadata?.triggerType]);

    return (
      <Stack
        id="report/activities"
        direction="column"
        sx={{
          height: "100%",
        }}
      >
        <Box
          flexGrow={1}
          sx={{
            overflowY: "auto",
            height: "100%",
          }}
          ref={scrollRef}
        >
          <Box
            sx={{
              opacity: 0.7,
            }}
          >
            {report.id?.startsWith?.("card") && report.createdBy ? (
              <Typography variant="caption" color="tertiary.main">
                <Stack direction="row" gap={0.5}>
                  <span>Created on </span>

                  <span>
                    {new Date(report.createdAt * 1000).toLocaleString()} by{" "}
                  </span>

                  <AssigneeBadge
                    report={{
                      ...report,
                      assigneeSub: report.createdBy.sub,
                    }}
                    tooltip={false}
                    size={18}
                    withName
                    nameProps={{
                      sx: {
                        fontSize: 12,
                        lineHeight: "19px",
                      },
                    }}
                  />
                </Stack>
              </Typography>
            ) : (
              <Typography variant="caption" color="tertiary.main">
                {reportReason}

                <span> {new Date(report.timestamp).toLocaleString()}</span>

                {report.metadata?.errorInfo?.lastOccurrenceOn
                  ? ". Issue last appeared on " +
                    new Date(
                      report.metadata?.errorInfo?.lastOccurrenceOn
                    ).toLocaleString()
                  : ""}
              </Typography>
            )}
          </Box>

          <Stack
            direction="column"
            spacing={1}
            flex={1}
            sx={{
              width: "100%",
              mt: 2,
            }}
          >
            {preparedItems.map((item, ii) => {
              if (item.type !== "comment") {
                return (
                  <>
                    <DateDivider
                      prevTimestamp={ii > 0 ? items[ii - 1]?.createdAt : 0}
                      currentTimestamp={item?.createdAt}
                    />

                    <ChangeLogComponent
                      key={item.id}
                      changeLog={item}
                      user={user}
                      showDateTime
                    />
                  </>
                );
              }

              return (
                <>
                  <DateDivider
                    prevTimestamp={ii > 0 ? items[ii - 1]?.createdAt : 0}
                    currentTimestamp={item?.createdAt}
                  />

                  <CommentComponent
                    key={item.id}
                    comment={item}
                    user={user}
                    withUser={
                      ii === 0 ||
                      items[ii - 1]?.author?.id !== item.author?.id ||
                      items[ii - 1]?.type !== "comment" ||
                      item.type !== "comment"
                    }
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    companyMembers={companyMembers}
                    onReply={() => onReply(item)}
                  />
                </>
              );
            })}
          </Stack>
        </Box>

        <Box flexGrow={1} />

        <Box
          flexGrow={0}
          flexShrink={0}
          flexBasis={"content"}
          sx={{
            mt: 2,
          }}
          onMouseDown={() => setVisibility("full")}
        >
          <CommentInputComponent
            onChange={onCreate}
            companyMembers={companyMembers}
            reportId={reportId}
            ref={ref}
            onReply={() => {}}
            onCancel={() => {}}
            rawContentState={null}
          />
        </Box>
      </Stack>
    );
  }
);

function DateDivider({
  prevTimestamp,
  currentTimestamp,
}: {
  prevTimestamp: number | string | Date;
  currentTimestamp: number | string | Date;
}): JSX.Element {
  const prevDate = new Date(+prevTimestamp * 1000);
  const currentDate = new Date(+currentTimestamp * 1000);

  if (!prevTimestamp || prevDate.getDate() !== currentDate.getDate()) {
    return (
      <Divider
        sx={{
          my: 1,
          "&::before, &::after": {
            borderTop: "1px solid #FAFAFA",
          },
        }}
      >
        <Chip
          label={format(currentDate, "EEEE, MMMM d")}
          size="small"
          variant="outlined"
          color="muted"
          sx={{
            border: 0,
          }}
        />
      </Divider>
    );
  }

  return null;
}

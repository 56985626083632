// dynamic content from CMS
// API URL: https://saas-cms.bugpilot.io/content?database=XXX&id=XXX

import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const cache = {};

export const CMSContent = ({
  type = "text",
  database,
  contentId,
  fallback,
  width,
  ...props
}) => {
  const [content, setContent] = useState(cache[database + contentId] || null);

  useEffect(() => {
    const fetchContent = async () => {
      const response = await fetch(
        `https://saas-cms.bugpilot.io/content?database=${database}&id=${contentId}`
      );
      const data = await response.json();

      const _content = data?.items?.[0]?.image || data?.items?.[0]?.content;
      cache[database + contentId] = _content;

      setContent(_content);
    };

    fetchContent();
  }, [database, contentId]);

  if (type === "image" && content) {
    if (content) {
      if (!content.endsWith("/")) {
        return <Box component="img" {...props} src={content} alt={contentId} />;
      }

      // we can append w=size to generate responsive sizes
      const src = `${content}w=${width},fit=crop,format=webp`;
      const src2x = `${content}w=${width * 2},fit=crop,format=webp`;
      const src3x = `${content}w=${width * 3},fit=crop,format=webp`;

      const srcSet = `${src} 1x, ${src2x} 2x, ${src3x} 3x`;

      return (
        <Box
          component="img"
          {...props}
          src={src}
          srcSet={srcSet}
          alt={contentId}
          sx={{
            width: width,
            height: "auto",
            ...(props.sx || {}),
          }}
        />
      );
    }

    if (fallback) {
      return (
        <Box component="img" {...props}>
          {fallback}
        </Box>
      );
    }

    return <Box {...props}>{fallback}</Box>;
  }

  if (content) {
    return (
      <Box
        {...props}
        sx={{
          "& p": {
            margin: 0,
            marginBottom: 2,
          },
        }}
      >
        <ReactMarkdown>{content}</ReactMarkdown>
      </Box>
    );
  }

  if (window.location.href.includes("localhost")) {
    return (
      <>
        {fallback}
        <Typography
          {...props}
          sx={{
            ...props.sx,
            border: "1px solid red",
          }}
        >
          {contentId}
        </Typography>
      </>
    );
  }

  return fallback;
};

import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Box,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { BuildOutlined as BuildIcon } from "@mui/icons-material";
import DeleteIcon from "../../../icons/DeleteIcon";

export const IntegrationCardDialog = ({
  name,
  Component,
  DialogContentComponent,
  company,
  onCompanyChange,
  isAdminOrOwner,
  isAdding,
  configuredIntegrations,
  onDeleteIntegration,
  handleCreateIntegration,
  onUpdateIntegration,
  open,
  openDetails,
  closeDetails,
  isDeletable,
}) => {
  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth onClose={closeDetails}>
        <DialogTitle sx={{ mt: 1 }}>
          <Typography variant="h3">{name}</Typography>
        </DialogTitle>

        <DialogContent>
          {DialogContentComponent && (
            <DialogContentComponent
              isDisabled={!isAdminOrOwner}
              company={company}
              onChange={onCompanyChange}
              onClose={closeDetails}
            />
          )}

          {configuredIntegrations?.length > 0 && Component && (
            <>
              <DialogContentText>
                <Typography variant="body1">
                  You have configured {configuredIntegrations.length} {name}{" "}
                  integration{configuredIntegrations.length > 1 ? "s" : ""}.
                </Typography>
              </DialogContentText>

              <Stack spacing={4} sx={{ mt: 4 }}>
                {configuredIntegrations.map((integration, i) => (
                  <Box key={integration.type}>
                    <Stack spacing={1}>
                      <Divider
                        title={`Integration ${i + 1}`}
                        key={integration.id}
                      >
                        Integration {i + 1}
                      </Divider>

                      <Box>
                        <Component
                          isDisabled={!isAdminOrOwner}
                          integration={integration}
                          isAdding={isAdding}
                          onDeleteIntegration={onDeleteIntegration}
                          onUpdateIntegration={(values) =>
                            onUpdateIntegration({
                              type: integration.type,
                              companyId: integration.id,
                              integration: values,
                            })
                          }
                          onCreateIntegration={handleCreateIntegration}
                        />
                      </Box>

                      {isDeletable && (
                        <Box>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => onDeleteIntegration(integration)}
                            disabled={!isAdminOrOwner}
                            startIcon={<DeleteIcon fontSize="small" />}
                            color="error"
                          >
                            Delete
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                ))}
              </Stack>
            </>
          )}
        </DialogContent>
      </Dialog>

      {configuredIntegrations?.length > 0 && Component && (
        <Grid item>
          <Button
            onClick={openDetails}
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<BuildIcon />}
          >
            Settings
          </Button>
        </Grid>
      )}
    </>
  );
};

import React, { useCallback, useState, Fragment, useEffect } from "react";
import uniq from "lodash/uniq";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import InputAdornment from "@mui/material/InputAdornment";
import AddIcon from "@mui/icons-material/AddOutlined";
import CloseIcon from "@mui/icons-material/CloseOutlined";

import Input from "../../components/Input";
import Chip from "@mui/material/Chip";
import DeleteIcon from "../../icons/DeleteIcon";
import { useModals } from "../../hooks/useModals";

const formControlLabelComponentProps = {
  typography: {
    variant: "body1",
  },
};

const formControlLabelRadioComponentProps = {
  typography: {
    variant: "body1",
  },
};

const FieldsSelect = ({
  title,
  placeholder,
  variant = "chip",
  hideSelect,
  onChange,
  enabled = "all",
  hideToggle,
  disabled = false,
  confirmRequired = false,
  confirmTitle = "",
  confirmText = "",
  confirmCta = "",
  inputModifier,
  valueModifier,
  validator,
  inputProps,
  size,
  sx,
  ...props
}) => {
  const [value, setValue] = useState(enabled);
  const [fields, setFields] = useState(props.fields || []);
  const [newField, setNewField] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    setFields(props.fields || []);
  }, [props.fields]);

  const handleEnabledChange = useCallback(
    (e) => {
      const value = e.target.checked ? (hideSelect ? "custom" : "all") : "off";
      setValue(value);
      onChange?.({ enabled: value, fields });
    },
    [hideSelect, onChange, fields]
  );

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      setValue(value);
      onChange?.({ enabled: value, fields });
    },
    [onChange, fields]
  );

  const handleNewFieldChange = useCallback(
    (e) => {
      setNewField(
        typeof inputModifier === "function"
          ? inputModifier(e.target.value)
          : e.target.value
      );
    },
    [inputModifier]
  );

  const handleNewFieldAdd = useCallback(() => {
    if (typeof validator === "function" && !validator(newField).isValid) {
      setError(validator(newField).message);
      return;
    }
    const newFields = uniq([
      ...fields,
      typeof valueModifier === "function" ? valueModifier(newField) : newField,
    ]);

    setNewField("");

    if (newFields.length === fields.length) {
      return;
    }

    setFields(newFields);

    try {
      onChange?.({ fields: newFields, enabled: value });
    } catch (e) {
      console.error("Error updating field:", e);
      setError(e.message);
      setFields(fields);
      setNewField(newField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, newField, onChange, value]);

  const modals = useModals();

  const handleDeleteField = useCallback(
    (value) => () => {
      const doDelete = () => {
        const newFields = fields.filter((field) => field !== value);
        setFields(newFields);
        onChange?.({ fields: newFields, enabled: value });
      };

      if (!confirmRequired) {
        doDelete();
        return;
      }

      modals.openConfirmation({
        onConfirm: doDelete,
        text: confirmText,
        confirmCTAHeader: confirmTitle,
        confirmCTATitle: confirmCta,
        confirmCTAColor: "error",
      });
    },
    [
      confirmRequired,
      fields,
      onChange,
      modals,
      confirmText,
      confirmTitle,
      confirmCta,
    ]
  );

  const handleNewFieldKeyDown = useCallback(
    (e) => {
      setError(false);

      if (e.key !== "Enter") {
        return;
      }

      if (!newField) {
        return;
      }

      handleNewFieldAdd();
    },
    [handleNewFieldAdd, newField]
  );

  return (
    <Grid container flexDirection="column" spacing={2} sx={sx}>
      {(!hideToggle || title || !hideSelect) && (
        <Grid item container flexDirection="column">
          <Grid item>
            {hideToggle ? (
              title && <Typography variant="body1">{title}</Typography>
            ) : (
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={value !== "off"}
                    onChange={handleEnabledChange}
                    disabled={disabled}
                  />
                }
                label={title}
                componentsProps={formControlLabelComponentProps}
              />
            )}
          </Grid>

          {!hideSelect && (hideToggle || value !== "off") && (
            <Grid item>
              <RadioGroup row value={value} onChange={handleChange}>
                <FormControlLabel
                  value="all"
                  control={<Radio disabled={disabled} />}
                  label="All"
                  componentsProps={formControlLabelRadioComponentProps}
                />
                <FormControlLabel
                  value="custom"
                  control={<Radio disabled={disabled} />}
                  label="Custom"
                  componentsProps={formControlLabelRadioComponentProps}
                />
                <FormControlLabel
                  value="off"
                  control={<Radio disabled={disabled} />}
                  label="None"
                  componentsProps={formControlLabelRadioComponentProps}
                />
              </RadioGroup>
            </Grid>
          )}
        </Grid>
      )}

      {value === "custom" && (
        <>
          <Grid item xs={12}>
            <Box pl={hideToggle ? 0 : 5.5}>
              <Input
                size={size}
                placeholder={placeholder}
                fullWidth
                value={newField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={!newField.trim()}
                        onClick={handleNewFieldAdd}
                      >
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={handleNewFieldChange}
                onKeyDown={handleNewFieldKeyDown}
                disabled={disabled}
                {...inputProps}
              />
              {error && (
                <Typography variant="caption" color="error" component="span">
                  {error}
                </Typography>
              )}
            </Box>
          </Grid>
          {fields.length > 0 && (
            <Grid item>
              <Box pl={hideToggle ? 0 : 5.5}>
                {variant === "chip" && (
                  <Grid container spacing={1}>
                    {fields.map((field) => (
                      <Grid item key={field}>
                        <Chip
                          label={
                            <Typography variant="body1">{field}</Typography>
                          }
                          onDelete={
                            disabled ? undefined : handleDeleteField(field)
                          }
                          deleteIcon={<CloseIcon fontSize="small" />}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
                {variant === "list" && (
                  <List>
                    {fields.map((field, i) => (
                      <Fragment key={field}>
                        <ListItem sx={{ paddingLeft: 0 }}>
                          <ListItemText
                            primary={
                              <Typography variant="body1">{field}</Typography>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              size="small"
                              onClick={handleDeleteField(field)}
                              disabled={disabled}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        {i !== fields.length - 1 && <Divider />}
                      </Fragment>
                    ))}
                  </List>
                )}
              </Box>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default FieldsSelect;

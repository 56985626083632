import React, { useMemo } from "react";
import { Alert, Skeleton, Stack, Typography } from "@mui/material";
import humanizeDuration from "humanize-duration";
import { CircleOutlined as CircleIcon } from "@mui/icons-material";
import { useAutopilot } from "./AutopilotContext.tsx";
import { withErrorBoundary } from "react-error-boundary";

const UserPostureComponent = () => {
  const { data, error, loading } = useAutopilot();

  const adblockers = useMemo(() => {
    if (!data) {
      return {
        available: false,
        name: ["", ""],
        ok: false,
      };
    }

    if (typeof data.blocked === "undefined") {
      return {
        available: false,
        name: ["", ""],
        ok: false,
      };
    }

    const ok = data.blocked === 0;
    const icon = (
      <CircleIcon
        sx={{
          color: ok ? "success.main" : "warning.main",
          width: 12,
          height: 12,
        }}
        fontSize="small"
      />
    );

    return {
      id: "adblockers",
      available: true,
      name: ["Likely ad-blocker", "No ad-blockers"],
      description: "Ad blockers detected",
      count: data.blocked,
      ok,
      icon,
      info: {},
    };
  }, [data]);

  const connectionSpeed = useMemo(() => {
    if (!data) {
      return {
        available: false,
        name: ["", ""],
        ok: false,
      };
    }

    if (!data.connectionSpeed || !data.connectionSpeed.info) {
      return {
        available: false,
        name: ["Unknown Internet speed"],
        icon: (
          <CircleIcon
            sx={{
              color: "success.main",
              width: 12,
              height: 12,
            }}
            fontSize="small"
          />
        ),
        ok: false,
      };
    }

    const ok = data.connectionSpeed.ok;
    const icon = (
      <CircleIcon
        sx={{
          color: ok ? "success.main" : "warning.main",
          width: 12,
          height: 12,
        }}
        fontSize="small"
      />
    );

    const networkLabel = data.connectionSpeed.info?.toUpperCase?.();

    return {
      id: "connectionSpeed",
      available: true,
      name: [
        `Slow Internet (${networkLabel})`,
        `${networkLabel === "4G" ? "Fast" : networkLabel} Internet`,
      ],
      description: `${data.connectionSpeed.info || "Unknown"} connection type`,
      count: 0,
      ok,
      icon,
      info: {
        connectionSpeed: data.connectionSpeed.info || "unknown",
      },
    };
  }, [data]);

  const loadTimeCheck = useMemo(() => {
    if (!data) {
      return {
        available: false,
        name: ["", ""],
        ok: false,
      };
    }

    if (!data.timing) {
      return {
        available: false,
        name: ["", ""],
        ok: false,
      };
    }

    const text = `Page load in ${humanizeDuration(
      data.timing.info?.totalDuration,
      {
        maxDecimalPoints: 1,
      }
    )}`;

    const ok = data.timing.ok;
    const icon = (
      <CircleIcon
        sx={{
          color: ok ? "success.main" : "warning.main",
          width: 12,
          height: 12,
        }}
        fontSize="small"
      />
    );

    return {
      available: true,
      name: [text, text],
      description: "",
      ok,
      icon,
      info: {
        loadTime: humanizeDuration(data.timing.info?.totalDuration, {
          maxDecimalPoints: 2,
        }),
      },
    };
  }, [data]);

  const screenResolutionCheck = useMemo(() => {
    if (!data) {
      return {
        available: false,
        name: ["", ""],
        ok: false,
      };
    }

    if (!data.screenResolutionCheck) {
      return {
        available: false,
        name: ["", ""],
        ok: false,
      };
    }

    const ok = data.screenResolutionCheck.ok;
    const icon = (
      <CircleIcon
        sx={{
          color: ok ? "success.main" : "warning.main",
          width: 12,
          height: 12,
        }}
        fontSize="small"
      />
    );

    const description = `Screen resolution: ${data.screenResolutionCheck.info.resolutionString}`;

    return {
      id: "screenResolution",
      available: true,
      name: [
        `Screen ${data.screenResolutionCheck.info.resolutionString}`,
        `Screen ${data.screenResolutionCheck.info.resolutionString}`,
      ],
      description,
      ok,
      icon,
      info: data.screenResolutionCheck.info,
    };
  }, [data]);

  const mobileDeviceCheck = useMemo(() => {
    if (!data) {
      return {
        available: false,
        name: ["", ""],
        ok: false,
      };
    }

    if (!data.mobileDeviceCheck) {
      return {
        available: false,
        name: ["", ""],
        ok: false,
      };
    }

    const ok = data.mobileDeviceCheck.ok;
    const icon = (
      <CircleIcon
        sx={{
          color: "success.main",
          width: 12,
          height: 12,
        }}
        fontSize="small"
      />
    );

    return {
      id: "mobileDevice",
      available: true,
      name: ["Touch Device", "Desktop Device"],
      description: "",
      ok,
      icon,
    };
  }, [data]);

  const browserCheck = useMemo(() => {
    if (!data) {
      return {
        available: false,
        name: ["", ""],
        ok: false,
      };
    }

    if (!data.browserVersionInfo) {
      return {
        available: false,
        name: ["", ""],
        ok: false,
      };
    }

    const ok =
      data.browserVersionInfo.detected && data.browserVersionInfo.updated;

    const icon = (
      <CircleIcon
        sx={{
          color: ok ? "success.main" : "warning.main",
          width: 12,
          height: 12,
        }}
        fontSize="small"
      />
    );

    return {
      id: "oldBrowser",
      available: true,
      name: [
        `${data.browserVersionInfo.name} ${data.browserVersionInfo.major}`,
        `${data.browserVersionInfo.name} ${data.browserVersionInfo.major}`,
      ],
      description: `Checks if the user is using an legacy or not up to date browser. Currently, the user is using the following browser: ${data.browserVersionInfo.name} ${data.browserVersionInfo.major}. Old browsers, or less common browsers, could introduce bugs that difficult to reproduce`,
      ok,
      icon,
      info: data.browserVersionInfo,
    };
  }, [data]);

  if (loading || !data) {
    return (
      <Stack spacing={2}>
        <Skeleton variant="rectangular" width="100%" height={14} />
        <Skeleton variant="rectangular" width="100%" height={14} />
        <Skeleton variant="rectangular" width="100%" height={14} />
        <Skeleton variant="rectangular" width="100%" height={14} />
        <Skeleton variant="rectangular" width="100%" height={14} />
        <Skeleton variant="rectangular" width="100%" height={14} />
      </Stack>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Stack spacing={2}>
      {[
        adblockers,
        connectionSpeed,
        loadTimeCheck,
        screenResolutionCheck,
        browserCheck,
        mobileDeviceCheck,
      ].map((item, i) => (
        <Typography
          variant="body2"
          color="text.muted"
          key={i}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            cursor: "default",
          }}
        >
          {item.icon} {item.name[Number(item.ok)]}
        </Typography>
      ))}
    </Stack>
  );
};

export const UserPosture = withErrorBoundary(UserPostureComponent, {
  fallback: <Typography color="text.muted">Unavailable</Typography>,
  onError(error, info) {
    console.error("ErrorBoundary caught an error:", error, info);
  },
});

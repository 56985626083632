import { useEffect, useState, useCallback, useMemo } from "react";
import ms from "ms";
import { noop } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Alert,
  Box,
  LinearProgress,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { withErrorBoundary } from "react-error-boundary";

import {
  fetchReportContents,
  getReport as getReportAPI,
} from "../../api/index";
import { useAuth } from "../../contexts/AuthContext";
import useLoading from "../../hooks/useLoading";
import { useAppContext } from "../../contexts/AppContext";
import { FullReportPage } from "./FullReportPage";
import { useReportsListCache } from "../../contexts/ReportsListCacheContext.js";
import { ReportProvider, useReport } from "../../contexts/ReportContext.tsx";

const ReportPage = ({
  reportId: propsId,
  isPreviewPanel = false,
  onClose = noop, // to close preview drawer
  handleNextReport: _handleNextReport,
  variant = "full", // full | lean
}) => {
  const { cachedReportsList } = useReportsListCache();

  const location = useLocation();
  const navigate = useNavigate();

  const { id: pathId /*slug*/ } = useParams();
  const searchId = new URLSearchParams(location.search).get("id");

  const initialReportId = useMemo(
    () => propsId || pathId || searchId,
    [propsId, pathId, searchId]
  );

  const optimisticReport = useMemo(() => {
    return cachedReportsList.find((r) => r.id === initialReportId) || {};
  }, [initialReportId, cachedReportsList]);

  const [report, setReport] = useState(optimisticReport);
  const [reportContents, setReportContents] = useState([]);
  const [error, setError] = useState(null);
  const [buffering, setBuffering] = useState(true);

  const { selectedCompanyId, companies, updateReport, mutedErrors } =
    useAppContext();
  const { closeReport } = useReport();

  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth(); // user can be null!

  const [reportId, setReportId] = useState(initialReportId);

  useEffect(() => {
    setReportId(initialReportId);
  }, [initialReportId]);

  const handleNextReport = useCallback(
    (direction) => {
      const callback = (nextReport) => {
        setReport(nextReport);
        setReportId(nextReport.id);
        navigate(`/report/${nextReport.id}`);
      };
      _handleNextReport(callback, direction || 1);
    },
    [_handleNextReport, navigate]
  );

  const handleUpdateReport = useCallback(
    (reportObj, fields) =>
      updateReport(
        reportObj,
        {
          ...fields,
        },
        setReport
      ),
    [setReport, updateReport]
  );

  const { handler: loadReport, isLoading: isReportLoading } = useLoading(
    async () => {
      try {
        const nextReport = await getReportAPI(
          {
            reportId,
            userId: user?.sub,
          },
          {
            withAuth: variant === "full",
          }
        );

        if (nextReport.autopilotErrors) {
          nextReport.autopilotErrors = nextReport.autopilotErrors.map(
            (error) => ({
              ...error,
              ...(error.clickEvent && {
                clickEvent: {
                  ...error.clickEvent,
                  timestamp:
                    typeof error.clickEvent.timestamp === "string"
                      ? new Date(error.clickEvent.timestamp)
                      : error.clickEvent.timestamp,
                },
              }),
            })
          );
        }
        setReport(nextReport);

        if (nextReport && nextReport.contentsUrl) {
          const nextReportContents = await fetchReportContents(
            nextReport.contentsUrl
          );
          setReportContents(nextReportContents);
        } else {
          setReportContents([]);
        }
      } catch (e) {
        setReport({});
        setError(e);
        enqueueSnackbar("Error loading session", {
          variant: "error",
        });
      }
    }
  );

  useEffect(() => {
    if (!reportId) {
      return;
    }

    loadReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  useEffect(() => {
    if (!user?.sub || !user?.email || !report?.id) {
      return;
    }

    const lastSeenOn = report.lastSeenOn || {};

    if (
      user.email.endsWith("@bugpilot.io") ||
      user.email.endsWith("@adopto.it")
    ) {
      return;
    }

    lastSeenOn[user.email] = Date.now();

    handleUpdateReport(report, { lastSeenOn });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.id, user?.sub]);

  if (error) {
    console.error("Error fetching report", error);
  }

  const reportPending = useMemo(
    () =>
      report &&
      report.timestamp &&
      report.timestamp > Date.now() - 1000 * 60 * 2.5 &&
      !location.search.includes("preview"),
    [report, location]
  );

  useEffect(() => {
    if (!reportPending) {
      return;
    }

    const timeout = setInterval(() => {
      getReportAPI({
        reportId,
        userId: user?.sub,
      })
        .then((nextReport) => {
          setReport(nextReport);
        })
        .catch((e) => {
          console.error("Error refreshing report", e);
        });
    }, 1000 * 60 * 0.5);

    return () => clearInterval(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPending, reportId, user?.sub]);

  const reportPendingBox = useMemo(() => {
    if (!report) {
      return null;
    }

    if (report.id?.startsWith("card-")) {
      return null;
    }

    if (!reportPending) {
      return null;
    }

    if (location.search.includes("force")) {
      return null;
    }

    return (
      <Box
        sx={{
          height: "calc(100vh - 120px)",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          backgroundColor: "white.main",
          opacity: 0.85,
        }}
      >
        <Paper>
          <Box
            p={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" textAlign="center">
              We are analyzing this report. <br />
              Most reports are processed within 5 minutes.
            </Typography>

            <LinearProgress
              variant="determinate"
              value={((Date.now() - report.timestamp) / ms("5minutes")) * 100}
              sx={{
                width: "100%",
                mt: 2,
              }}
            />
          </Box>
        </Paper>
      </Box>
    );
  }, [report, reportPending, location.search]);

  if (!report) {
    return (
      <Typography>
        This report does not exist. It might have been deleted or it expired
        because it was not updated for more than 30 days.
      </Typography>
    );
  }

  return (
    <Box
      style={{
        height: "calc(100vh - 120px)",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <WorkspaceMismatchBanner report={report} />

      <FullReportPage
        user={user}
        report={report}
        reportContents={reportContents}
        company={companies.find(({ id }) => id === selectedCompanyId)}
        setReport={setReport}
        isReportLoading={isReportLoading}
        mutedErrors={mutedErrors}
        handleNextReport={handleNextReport}
        isBuffering={buffering}
        refreshReport={() => {}} // TODO: remove
        error={error}
        variant={variant}
      />

      {reportPendingBox}
    </Box>
  );
};

const WorkspaceMismatchBanner = ({ report }) => {
  const { currentWorkspace } = useAppContext();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => {
      const shouldShow = currentWorkspace?.id !== report.companyId;

      if (!shouldShow) {
        return;
      }

      // show after a short delay to avoid flickering
      // and false positives (no idea why they happen)

      setShow(true);
    }, 10 * 1000);

    return () => {
      clearTimeout(t);
    };
  }, [currentWorkspace?.id, report.companyId]);

  if (!show) {
    return null;
  }

  return (
    <Box mb={2}>
      <Snackbar
        open
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error" variant="filled">
          This report belongs to a different workspace, some features might not
          be available.
        </Alert>
      </Snackbar>
    </Box>
  );
};

const ReportPageContainer = ({ ...props }) => {
  const { id: reportId } = useParams();

  return (
    <ReportProvider reportId={reportId}>
      <ReportPage {...props} />
    </ReportProvider>
  );
};

export default withErrorBoundary(ReportPageContainer, {
  fallback: <div>Sorry, something went wrong while rendering this report</div>,
  onError: (error, componentStack) => {
    console.error("ReportPage Error Boundary", error, componentStack);
  },
});

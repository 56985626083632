import { useEffect } from "react";
import { compose } from "lodash/fp";

import { pubsub } from "../contexts/pubsub";

const filterOnlyFirstReportInGroup = (reports) =>
  reports.filter((r) => {
    return (
      r.metadata?.triggerType !== "autopilot" ||
      !r.metadata?.errorInfo?.originalReportId ||
      r.id === r.metadata?.errorInfo?.originalReportId
    );
  });

const useReportsListeners = ({ setReports, label }) => {
  useEffect(() => {
    const reportUpdatingListener = (report) => {
      setReports(
        compose(filterOnlyFirstReportInGroup, (reports) =>
          reports.map((r) =>
            r.id === report.id
              ? {
                  ...r,
                  ...report,
                }
              : r
          )
        )
      );
    };

    const reportUpdatedListener = (report) => {
      setReports(
        compose(filterOnlyFirstReportInGroup, (reports) =>
          reports.some((r) => r.id === report.id)
            ? reports.map((r) => (r.id === report.id ? report : r))
            : [report, ...reports]
        )
      );
    };

    const reportDeletedListener = ({ id }) => {
      setReports((reports) => reports.filter((r) => r.id !== id));
    };

    pubsub.subscribe("report:updating", reportUpdatingListener);
    pubsub.subscribe("report:updated", reportUpdatedListener);
    pubsub.subscribe("report:deleted", reportDeletedListener);

    return () => {
      pubsub.unsubscribe("report:updating", reportUpdatingListener);
      pubsub.unsubscribe("report:updated", reportUpdatedListener);
      pubsub.unsubscribe("report:deleted", reportDeletedListener);
    };
  }, [setReports]);
};

export default useReportsListeners;

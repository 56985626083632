import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { Button, Stack, TextField } from "@mui/material";

import FieldsSelect from "./FieldsSelect";
import { SettingsSection } from "./SettingsSection.tsx";

import { useCallback, useState } from "react";

const formControlLabelComponentProps = {
  typography: {
    variant: "body1",
  },
};

const ExcludeElements = ({ company, onChange, isDisabled }) => {
  const [value, setValue] = useState(
    (company?.excludedClassNames || []).join(", ")
  );

  const handleSave = useCallback(() => {
    onChange({
      excludedClassNames: value
        .split(",")
        .map((s) => s.trim())
        .filter((s) => s),
    });
  }, [value, onChange]);

  return (
    <SettingsSection
      title="Exclude Page Elements"
      description="You can exclude HTML elements from screen recordings and activity logging by specifying their CSS classes here."
      leftButtons={[
        <Typography key={1} variant="body1" color="text.tertiary">
          Type elements CSS class names, separated by comma. You can use * for
          partial matching.
        </Typography>,
      ]}
      buttons={[
        <Button
          key="save"
          onClick={handleSave}
          disabled={isDisabled}
          variant="contained"
          color="primary"
        >
          Save
        </Button>,
      ]}
    >
      <TextField
        disabled={isDisabled}
        label="CSS classes of elements to exclude"
        fullWidth
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    </SettingsSection>
  );
};

const SecurityAndPrivacy = ({ company, onChange, isDisabled }) => (
  <Box>
    <Stack spacing={3}>
      <SettingsSection
        title="Handling of PII"
        description="To reduce the risk of you sending Personally-Identifiable Information (PII) to Bugpilot. This setting instructs Bugpilot to attempt removing PII-like info before it's sent to the server. If you disable this option, make sure you add CSS class names of elements you do not want to record. This feature is in beta."
      >
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={company?.piiRemovalMode !== "manual"}
              onChange={(e, enabled) => {
                onChange({
                  piiRemovalMode: enabled ? "auto" : "manual",
                });
              }}
            />
          }
          label="Attempt to remove user Personal Information (PII)"
          componentsProps={formControlLabelComponentProps}
        />
      </SettingsSection>

      <SettingsSection
        title="UUIDs"
        description="When this setting is enabled, Bugpilot will attempt to remove all the strings that match the UUID format, before sending the report to the server. If you need to record UUIDs, you can disable this option. This feature is in beta."
      >
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={!company?.shouldRecordUUIDs}
              onChange={(e, enabled) => {
                onChange({
                  shouldRecordUUIDs: !enabled,
                });
              }}
            />
          }
          label={"Do not record UUIDs"}
          componentsProps={formControlLabelComponentProps}
        />
      </SettingsSection>

      <SettingsSection
        title="Payment gateways"
        description="Bugpilot attempts to filter the recording of network activity to and from known payment gateways such as Stripe and PayPal, based on the list of hostnames published directly by the payment providers. The network activity regarding such services might not be available in the report. This feature is in beta."
      >
        <FormControlLabel
          control={<Switch size="small" checked disabled={isDisabled} />}
          label={
            "Do not record network activity towards known Payment Gateways"
          }
          componentsProps={formControlLabelComponentProps}
        />
      </SettingsSection>

      <ExcludeElements
        company={company}
        onChange={onChange}
        isDisabled={isDisabled}
      />

      <SettingsSection
        title="Log Cookies"
        description="You can specify if you want to store cookies as part of the session."
      >
        <FieldsSelect
          hideToggle
          placeholder="Type in a cookie name"
          onChange={({ enabled, fields }) =>
            onChange({
              ...(enabled && { recordCookies: enabled }),
              ...(fields && { recordCookiesFields: fields }),
              ...(["off", "all"].includes(enabled) && {
                recordCookiesFields: [],
              }),
            })
          }
          fields={company?.recordCookiesFields}
          enabled={company?.recordCookies}
          disabled={isDisabled}
          sx={{ marginLeft: 0 }}
        />
      </SettingsSection>

      <SettingsSection
        title="Log Local Storage"
        description="You can specify if you want to store localStorage data as part of the session."
      >
        <FieldsSelect
          hideToggle
          placeholder="Type in a local storage field name"
          onChange={({ enabled, fields }) =>
            onChange({
              ...(enabled && { recordLocalStorage: enabled }),
              ...(fields && { recordLocalStorageFields: fields }),
              ...(["off", "all"].includes(enabled) && {
                recordLocalStorageFields: [],
              }),
            })
          }
          fields={company?.recordLocalStorageFields}
          enabled={company?.recordLocalStorage}
          disabled={isDisabled}
        />
      </SettingsSection>

      <SettingsSection
        title="Log Session Storage"
        description="You can specify if you want to store sessionStorage data as part of the session. Most applications do not need this feature."
      >
        <FieldsSelect
          hideToggle
          placeholder="Type in a session storage field name"
          onChange={({ enabled, fields }) =>
            onChange({
              ...(enabled && { recordSessionStorage: enabled }),
              ...(fields && { recordSessionStorageFields: fields }),
              ...(["off", "all"].includes(enabled) && {
                recordSessionStorageFields: [],
              }),
            })
          }
          fields={company?.recordSessionStorageFields}
          enabled={company?.recordSessionStorage}
          disabled={isDisabled}
        />
      </SettingsSection>
    </Stack>
  </Box>
);

export default SecurityAndPrivacy;

import { Breadcrumbs, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { createPortal } from "react-dom";

import { useAppContext } from "../../contexts/AppContext";
import useHasRole from "../../hooks/useHasRole";
import { useAuth } from "../../contexts/AuthContext";
import { StepCode } from "./StepCode";
import { StepDone } from "./StepDone";
import { useAnalytics } from "../../analytics/AnalyticsContext";
import { useReportsListContext } from "../../contexts/ReportsListContext.tsx";

const STEPS = { code: "Add tracking code", done: "Get started" };

const GettingStartedPage = ({
  company,
  user,
  activeTab,
  isAdminOrOwner,
  onCompanyChange,
  children,
  companyId,
  reports,
}) => {
  const { scriptIsSetup } = useAppContext();
  const topBarBreadcrumbsElement = document.getElementById(
    "top-bar-breadcrumbs"
  );

  const { trackEvent } = useAnalytics();
  const { step } = useParams();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    trackEvent(`GettingStartedPage Step ${step}`);
  }, [step, trackEvent]);

  if (!step) {
    if (!scriptIsSetup) {
      return <Navigate to="/getting-started/code" />;
    }

    return <Navigate to="/getting-started/done" />;
  }

  return (
    <Box
      sx={{
        maxWidth: "md",
        width: "100%",
      }}
    >
      {topBarBreadcrumbsElement &&
        createPortal(
          <Stack direction="row" spacing={2} alignItems="center">
            <Breadcrumbs aria-label="breadcrumb">
              {Object.entries(STEPS).map(([s, label]) => (
                <Link
                  key={s}
                  to={`/getting-started/${s}`}
                  style={{
                    color: "inherit",
                    textTransform: "uppercase",
                    textDecoration: activeTab === s ? "underline" : "none",
                    textUnderlineOffset: "0.2em",
                    fontSize: "0.8rem",
                    fontWeight: "normal",
                  }}
                >
                  {label}
                </Link>
              ))}
            </Breadcrumbs>
          </Stack>,
          topBarBreadcrumbsElement
        )}

      {step === "code" && <StepCode scriptIsSetup={scriptIsSetup} />}
      {step === "done" && <StepDone />}
    </Box>
  );
};

const GettingStartedPageContainer = ({ children }) => {
  const { companies, updateCompany, selectedCompanyId } = useAppContext();

  const { reports } = useReportsListContext();

  const { user } = useAuth();
  const location = useLocation();
  const { hasRole: isAdminOrOwner } = useHasRole("admin");

  const [activeTab, setActiveTab] = useState(
    location.pathname.split("/")[2]?.toLowerCase()
  );

  const handleCompanyChange = useCallback(
    (changes) => {
      return updateCompany(selectedCompanyId, changes);
    },
    [updateCompany, selectedCompanyId]
  );

  useEffect(() => {
    setActiveTab(location.pathname.split("/")[2]?.toLowerCase());
  }, [location.pathname]);

  const selectedCompanyReports = useMemo(
    () => reports.filter((r) => r.companyId === selectedCompanyId),
    [reports, selectedCompanyId]
  );

  return (
    <GettingStartedPage
      company={companies.find(({ id }) => id === selectedCompanyId)}
      companyId={selectedCompanyId}
      user={user}
      onCompanyChange={handleCompanyChange}
      activeTab={activeTab}
      isAdminOrOwner={isAdminOrOwner}
      reports={selectedCompanyReports}
    >
      {children}
    </GettingStartedPage>
  );
};

export default GettingStartedPageContainer;

import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Grid,
  IconButton,
  Typography,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import CloseIcon from "@mui/icons-material/CloseOutlined";

import Button from "../Button";
import Input from "../Input";
import { getFieldProps } from "../../utils/getFieldProps";
import KeyIcon from "../../icons/KeyIcon";

const DialogActions = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(4)}`,
  },
}))(MuiDialogActions);

const DialogContent = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(4)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogTitle);

const validationSchema = Yup.object({
  oldPassword: Yup.string()
    .min(8, "Old password must be at least 8 characters")
    .required("Old password is a required field"),
  newPassword: Yup.string()
    .min(8, "New password must be at least 8 characters")
    .required("New password is a required field"),
  confirmPassword: Yup.string()
    .min(8, "Repeat password must be at least 8 characters")
    .oneOf([Yup.ref("newPassword")], "Passwords are not the same")
    .required("Repeat password is a required field"),
});

const ChangePasswordModal = ({ onClose, onConfirm }) => {
  const [error, setError] = useState(null);
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setError(null);
        await onConfirm(values);
        onClose();
      } catch (e) {
        setError(e.message);
      }
    },
  });

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold">
            Change password
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Input
              fullWidth
              label="Old password"
              size="small"
              required
              type="password"
              {...getFieldProps(formik, {
                name: "oldPassword",
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Input
              fullWidth
              label="New password"
              size="small"
              required
              type="password"
              {...getFieldProps(formik, {
                name: "newPassword",
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Input
              fullWidth
              label="Repeat new password"
              size="small"
              required
              type="password"
              {...getFieldProps(formik, {
                name: "confirmPassword",
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {error && (
            <Grid item>
              <FormHelperText error>{error}</FormHelperText>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          bold
          disabled={!formik.dirty || formik.isSubmitting}
          onClick={formik.handleSubmit}
          endIcon={formik.isSubmitting && <CircularProgress size={25} />}
          fullWidth
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordModal;

import React, { MouseEvent, useCallback, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Fade,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import { useAppContext } from "../../contexts/AppContext";
import { CheckOutlined as CheckIcon } from "@mui/icons-material";
import { Report } from "../../pages/BugTrackerPage/types";
import { useSnackbar } from "notistack";
import AssigneeBadge from "../../pages/BugTrackerPage/AssigneeBadge";

type CompanyMember = {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  companyId: string;
  role: string;
  invitedAt: number;
  invitedBy: any;
  email: string;
  acceptedOn: number;
};

type AssignMenuProps = {
  report: Report;
  anchorEl: HTMLElement | null;
  open: boolean;
  loading: boolean;
  onAssign: ({ member }: { member: CompanyMember }) => void;
  onClose: () => void;
};

type AssigneeItemProps = {
  report: Report;
  member: CompanyMember;
  onAssign: ({ member }: { member: CompanyMember }) => void;
};

const AssigneeItem = ({ report, member, onAssign }: AssigneeItemProps) => {
  const isAssignedToUser = [member.email, member.id].includes(
    report.assigneeSub
  );

  const handleClick = useCallback(() => {
    onAssign({ member });
  }, [member, onAssign]);

  return (
    <ListItemButton key={member.email} onClick={handleClick} dense>
      <ListItemAvatar
        sx={{ minWidth: "auto", display: "flex", alignItems: "center" }}
      >
        {isAssignedToUser ? (
          <CheckIcon fontSize="small" sx={{ mr: 1 }} />
        ) : (
          <Box sx={{ width: 20, mr: 1 }} />
        )}
      </ListItemAvatar>
      <ListItemText
        sx={{ m: 0 }}
        primary={
          <Stack spacing={2} direction="row">
            <Box>{member.name || member.email}</Box>
            {/* {member.provider !== "cognito" && (
              <Chip
                size="small"
                label="Google"
                color="primary"
                icon={<GoogleIcon fontSize="small" />}
              />
            )} */}
          </Stack>
        }
      />
    </ListItemButton>
  );
};

const AssignMenu = ({
  report,
  anchorEl,
  open,
  loading,
  onClose,
  onAssign,
}: AssignMenuProps) => {
  const { companyMembers }: { companyMembers: CompanyMember[] } =
    useAppContext();

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      popperOptions={{}}
      sx={{
        zIndex: 99999,
      }}
      transition
      placement="bottom-start"
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <Box>
                {loading ? (
                  <Box p={4}>
                    <CircularProgress
                      sx={{
                        margin: "auto",
                        display: "block",
                        color: (theme) => theme.palette.light.main,
                      }}
                      size={24}
                    />
                  </Box>
                ) : (
                  <List>
                    {companyMembers.map((member, id) => (
                      <AssigneeItem
                        report={report}
                        member={member}
                        key={id}
                        onAssign={onAssign}
                      />
                    ))}
                  </List>
                )}
              </Box>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

type AssigneeSelectProps = {
  report: Report;
  setReport: (report: Report) => void;
  addons?: React.ReactNode;
};

export const AssigneeSelect = ({
  report,
  setReport,
  addons,
}: AssigneeSelectProps) => {
  const { updateReport } = useAppContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleAssign = useCallback(
    async ({ member }) => {
      setLoading(true);

      try {
        await updateReport(
          report,
          {
            assigneeSub: report.assigneeSub === member.id ? null : member.id,
          },
          setReport
        );

        setAnchorEl(null);
        setLoading(false);
      } catch (error) {
        console.error("Failed to assign report", error);
        enqueueSnackbar("Failed to assign report", {
          variant: "error",
        });
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAnchorEl, report, setReport, enqueueSnackbar]
  );

  const isAssigned = report.assigneeSub;

  const menu = open ? (
    <AssignMenu
      key={"assign"}
      report={report}
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      loading={loading}
      onAssign={handleAssign}
    />
  ) : null;

  return (
    <Box>
      <ButtonGroup variant="outlined" color="tertiary" fullWidth>
        <Button
          onClick={handleClick}
          sx={{
            textTransform: "none",
            justifyContent: "flex-start",
            height: 36,
          }}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            {isAssigned ? (
              <AssigneeBadge
                report={report}
                tooltip={false}
                size={22}
                withName
              />
            ) : (
              <>
                <Avatar
                  variant="circular"
                  sx={{
                    width: 22,
                    height: 22,
                  }}
                />

                <Typography
                  variant="body2"
                  sx={{ ml: 0.5 }}
                  color="muted.dark"
                  noWrap
                  maxWidth={100}
                >
                  Assign to...
                </Typography>
              </>
            )}
          </Stack>
        </Button>

        {addons}
      </ButtonGroup>

      {menu}
    </Box>
  );
};

import { useMemo } from "react";
import { useAuth } from "../contexts/AuthContext";

import { Navigate, useLocation } from "react-router-dom";

export const isAccountSetup = (userAttributes) => {
  const userPersonalPrivacyPolicyAgreed = userAttributes?.find(
    (a) => a.Name === "custom:privacyPolicyAgreed"
  );

  return userPersonalPrivacyPolicyAgreed;
};

export const RequireAccountSetup = ({ children }) => {
  const { user, userAttributes } = useAuth();
  const location = useLocation();

  const loading = useMemo(
    () => user === null || userAttributes === null,
    [user, userAttributes]
  );

  const isAccountSetup = useMemo(() => {
    const userPersonalPrivacyPolicyAgreed = userAttributes?.find(
      (a) => a.Name === "custom:privacyPolicyAgreed"
    );

    return userPersonalPrivacyPolicyAgreed;
  }, [userAttributes]);

  if (location.pathname.includes("onboarding")) {
    return children;
  }

  if (loading) {
    return children;
  }

  if (!isAccountSetup) {
    return <Navigate to="/onboarding/role" replace />;
  }

  return children;
};

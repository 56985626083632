import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";

import { useAppContext } from "../contexts/AppContext";
import { useAnalytics } from "./AnalyticsContext";
import { usePreferences } from "../contexts/UserPreferencesContext";

export const AnalyticsIdentifyUser = ({ children }) => {
  const { user } = useAuth();
  const { selectedCompanyId, companies, currentWorkspace } = useAppContext();
  const { identifyCompany, identifyUser, june } = useAnalytics();
  const { preferences } = usePreferences();

  useEffect(() => {
    if (!user || !currentWorkspace) {
      return;
    }

    window.Bugpilot?.identify?.({
      id: user.sub,
      email: user.email,
      firstName: user.attributes?.given_name,
      lastName: user.attributes?.family_name,
      companies: companies
        .filter(({ isExternalCompany }) => !isExternalCompany)
        .map((c) => c.id),
      isLtd: Boolean(currentWorkspace.appSumoId),
    });

    const creationTimestamp = Math.floor(
      new Date(preferences?.itemCreatedAt || 0).valueOf() / 1000
    );

    identifyUser(user, {
      created_at: creationTimestamp, // Customer.io requires this to be snake_case
      createdAt: new Date(preferences?.itemCreatedAt || 0).toISOString(),
      isLtd: Boolean(currentWorkspace.appSumoId),
    });

    identifyCompany(currentWorkspace);

    june?.identify?.(
      user.sub,
      {
        email: user.email,
        firstName: user.attributes?.given_name,
        lastName: user.attributes?.family_name,
      },
      {}
    );

    june?.group?.(
      selectedCompanyId,
      { name: currentWorkspace.name, plan: currentWorkspace.plan },
      {}
    );

    // LogSnag
    window.ls?.("setUserId", user.email);

    window.localStorage.setItem("bugpilot.lastemail", user.email);
  }, [
    user,
    selectedCompanyId,
    currentWorkspace,
    identifyUser,
    identifyCompany,
    preferences,
    companies,
    june,
  ]);

  return null;
};

import React, { useCallback } from "react";
import {
  Typography,
  Box,
  Button,
  Stack,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Icon2023 } from "../../icons/logos/Logo2023";
import { BuildOutlined as BuildIcon } from "@mui/icons-material";

export const WidgetIntegration = ({
  company,
  onCompanyChange,
  isDisabled,
  handleOpenToggle: __handleOpenToggle_do_not_use,
}) => {
  const navigate = useNavigate();
  const handleGoToNewSection = useCallback(() => {
    navigate("/widget");
  }, [navigate]);

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2}>
          <Icon2023 height={24} width={24} />
          <Typography variant="body1" fontWeight="bold">
            Bug reporting widget
          </Typography>
        </Stack>

        <FormControlLabel
          onClick={handleGoToNewSection}
          control={
            <Switch
              disabled
              defaultChecked
              checked={company.enableUserWidgetV2 ?? false}
              color="success"
              onClick={(e) => e.stopPropagation()}
            />
          }
          label={company.enableUserWidgetV2 ? "Enabled" : "Disabled"}
        />
      </Stack>
      <Typography variant="body1">
        Add a bug reporting widget to your pages. The Bug reporting widget
        allows your users to report bugs and submit screen recordings with one
        click.
      </Typography>
      <Box>
        <Button
          onClick={handleGoToNewSection}
          variant="outlined"
          color="secondary"
          size="small"
          startIcon={<BuildIcon />}
        >
          Settings
        </Button>
      </Box>
    </Stack>
  );
};

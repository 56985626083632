import React from "react";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import OpenInNewIcon from "@mui/icons-material/OpenInNewOutlined";

const SlackIntegration = ({ integration, isDisabled }) => (
  <Typography variant="caption" component="p">
    Send a message to the channel <b>{integration.channelname}</b> in the{" "}
    <b>{integration.teamName}</b> workspace every time a new report is created
    with Bugpilot.
    <br />
    <Button
      variant="contained"
      color="secondary"
      size="small"
      endIcon={<OpenInNewIcon />}
      onClick={() => window.open(integration.settingsPageUrl, "_blank")}
    >
      Manage
    </Button>
  </Typography>
);

export default SlackIntegration;

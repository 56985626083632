import { Link as RouterLink } from "react-router-dom";
import { useMemo } from "react";
import MUILink from "@mui/material/Link";

const styles = {
  textDecoration: "underline",
  color: "unset",
  cursor: "pointer",
  textUnderlineOffset: 2,
};

const Link = ({ onClick, silent, ...props }) => {
  const handleClick = useMemo(
    () =>
      silent
        ? (e) => {
            e.preventDefault();
            onClick?.(e);
          }
        : onClick,
    [onClick, silent]
  );

  const dest = props.to || props.href || "#";
  const { style, sx, color } = props;

  if (dest.startsWith("http")) {
    return (
      <MUILink
        href={props.to || props.href}
        onClick={handleClick}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          ...styles,
          ...sx,
          ...style,
        }}
        color={color}
      >
        {props.children}
      </MUILink>
    );
  }

  return (
    <RouterLink
      {...props}
      style={{ ...styles, ...props.style }}
      onClick={handleClick}
    />
  );
};

export default Link;

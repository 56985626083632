import React, { useCallback, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Box,
  InputLabel,
  FormGroup,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Help from "./Help";
import { useSnackbar } from "notistack";
import AsyncButton from "./AsyncButton";

const WidgetConfig = ({ company, onChange, isDisabled, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [saving, setSaving] = useState(false);

  const [userWidgetPosition, setUserWidgetPosition] = useState(
    company?.userWidgetPosition || "center-right"
  );
  const [showWidgetBranding, setShowWidgetBranding] = useState(
    company?.showWidgetBranding ?? true
  );
  const [idleText, setIdleText] = useState(
    company?.widgetSettings?.idleText || "Report issue"
  );
  const [customCSS, setCustomCSS] = useState(
    company?.widgetSettings?.customCSS || ""
  );

  const [elementSelector, setElementSelector] = useState(
    company?.widgetSettings?.elementSelector || null
  );

  const handleSave = useCallback(async () => {
    setSaving(true);
    await onChange({
      userWidgetPosition,
      showWidgetBranding,
      widgetSettings: {
        idleText,
        inProgressText: "Reporting...", // unused
        successText: "",
        customCSS,
        elementSelector,
      },
    });

    setSaving(false);
    setTimeout(() => {
      onClose();
    }, 300);
  }, [
    onChange,
    userWidgetPosition,
    showWidgetBranding,
    idleText,
    customCSS,
    elementSelector,
    onClose,
  ]);

  const onPreview = useCallback(() => {
    if (!window.Bugpilot?.config?.workspaceSettings) {
      enqueueSnackbar(
        "Bugpilot is not initialized on this page. Try disabling ad-blockers.",
        { variant: "error" }
      );
      return;
    }

    const root = document.querySelector("#bugpilot-root");
    document.body.removeChild(root);

    window.Bugpilot.config.workspaceSettings.enableUserWidgetV2 = true;
    window.Bugpilot.config.workspaceSettings.widgetSettings.customCSS =
      customCSS;
    window.Bugpilot.config.workspaceSettings.widgetSettings.idleText = idleText;
    window.Bugpilot.config.workspaceSettings.userWidgetPosition =
      userWidgetPosition;
    window.Bugpilot._initializeUI();
  }, [customCSS, idleText, userWidgetPosition, enqueueSnackbar]);

  return (
    <Stack spacing={2}>
      <ToggleButtonGroup
        disabled={isDisabled}
        size="small"
        value={typeof elementSelector !== "string"}
        exclusive
        onChange={(_, shouldUseBugpilotButton) => {
          setElementSelector(shouldUseBugpilotButton ? null : "#button");
        }}
      >
        <ToggleButton value={true}>Bugpilot button</ToggleButton>
        <ToggleButton value={false}>Custom element</ToggleButton>
      </ToggleButtonGroup>
      <Stack spacing={4}>
        <Typography variant="body2">
          {typeof elementSelector === "string" ? (
            <>
              You can specify an HTML element on your website by clicking on
              which will trigger reporting
            </>
          ) : (
            <>
              You can customize widget settings using this form, and add custom
              CSS to match your brand styles. Read more in our{" "}
              <Link
                href="https://bugpilot.notion.site/Customize-the-Bug-Reporting-Widget-ca822375fc7641ad8db971ccb1b4f9b9"
                target="_blank"
              >
                documentation
              </Link>
              .
            </>
          )}
        </Typography>

        <Box component="form">
          <Stack spacing={2}>
            {typeof elementSelector === "string" ? (
              <FormControl>
                <TextField
                  label="Element selector"
                  value={elementSelector}
                  error={elementSelector === ""}
                  onChange={(e) => setElementSelector(e.target.value)}
                  disabled={isDisabled}
                  helperText={
                    elementSelector === ""
                      ? "Element selector is required"
                      : "HTML element selector on your website, e.g. #button, .button"
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            ) : (
              <>
                <FormControl>
                  <InputLabel id="widget-position-label">
                    Widget position
                  </InputLabel>
                  <Select
                    label="Widget position"
                    value={
                      userWidgetPosition === "center-right"
                        ? "right"
                        : userWidgetPosition
                    }
                    onChange={(e) => {
                      setUserWidgetPosition(e.target.value);
                    }}
                    disabled={isDisabled}
                  >
                    <MenuItem value="right">Right</MenuItem>
                    <MenuItem value="bottom">Bottom</MenuItem>
                    <MenuItem value="left">Left</MenuItem>
                    <MenuItem value="top">Top</MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <TextField
                    label="Widget label"
                    value={idleText}
                    onChange={(e) => setIdleText(e.target.value)}
                    disabled={isDisabled}
                    helperText="This text is displayed on the widget label."
                  />
                </FormControl>
                <FormControl>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox color="secondary" />}
                      label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <span>Show Bugpilot branding</span>
                          <Help>
                            Shows a small link to Bugpilot in the widget, after
                            the screen recording is finished.
                          </Help>
                        </Stack>
                      }
                      checked={showWidgetBranding}
                      onChange={(e) => setShowWidgetBranding(e.target.checked)}
                      disabled={isDisabled}
                    />
                  </FormGroup>
                </FormControl>
                <FormControl>
                  <TextField
                    label="Custom CSS styles"
                    multiline
                    value={customCSS}
                    onChange={(e) => setCustomCSS(e.target.value)}
                    disabled={isDisabled}
                    helperText={
                      <span>
                        CSS IDs of elements to style:
                        #bugpilot-widget-container, #bugpilot-widget-button,
                        #bugpilot-widget-text. Find examples{" "}
                        <Link
                          href="https://bugpilot.notion.site/Customize-the-Bug-Reporting-Widget-ca822375fc7641ad8db971ccb1b4f9b9"
                          target="_blank"
                        >
                          at the end of this page
                        </Link>
                        .
                      </span>
                    }
                    minRows={4}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        fontFamily: "monospace",
                      },
                    }}
                  />
                </FormControl>
              </>
            )}
          </Stack>
        </Box>

        <Box
          sx={{
            alignSelf: "flex-end",
          }}
        >
          <Stack direction="row" spacing={2} mt={4}>
            {typeof elementSelector !== "string" && (
              <Tooltip
                title="Applies the styles to the widget embedded on this page"
                arrow
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={onPreview}
                >
                  Preview
                </Button>
              </Tooltip>
            )}
            <AsyncButton
              variant="contained"
              color="secondary"
              size="small"
              disabled={saving || isDisabled || elementSelector === ""}
              onClick={handleSave}
            >
              Save
            </AsyncButton>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

export default WidgetConfig;

import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import Input from "../components/Input";
import AsyncButton from "../components/AsyncButton";
import CodeIcon from "../icons/CodeIcon";
import { getFieldProps } from "../utils/getFieldProps";
import { handleSubmit } from "../utils/formik";
import FormHelperText from "@mui/material/FormHelperText";
import ErrorText from "../components/ErrorText";
import OnboardingBackdrop from "../components/OnboardingBackdrop";

const validationSchema = Yup.object({
  code: Yup.string()
    .min(6, "The confirmation code must be exactly 6 digits")
    .max(6, "The confirmation code must be exactly 6 digits")
    .required("The confirmation code is mandatory"),
});

const ConfirmMFA = ({ onConfirm, onSignInClick, email, user }) => {
  const [error, setError] = useState(null);
  const formik = useFormik({
    initialValues: {
      email,
      code: "",
    },
    validationSchema,
    onSubmit: async ({ code }) => {
      try {
        setError(null);
        await onConfirm(user, code);
      } catch (e) {
        console.error(e);
        setError(e.message);
        throw e;
      }
    },
  });

  return (
    <OnboardingBackdrop>
      <Box maxWidth={700} width="100vw" boxSizing="border-box">
        <Paper>
          <Box p={5}>
            <Grid container spacing={4} flexDirection="column">
              <Grid item>
                <Typography fontWeight="bold" variant="h6">
                  Login verification
                </Typography>
                <Typography variant="body1" mt={1}>
                  This account is protected by Two-Factor Authentication. Please
                  enter a verification code that is generated from your Google
                  Authenticator app.
                </Typography>
              </Grid>
              <Grid item>
                <Input
                  label="Verification code"
                  required
                  placeholder="000000"
                  type="text"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CodeIcon />
                      </InputAdornment>
                    ),
                  }}
                  {...getFieldProps(formik, {
                    name: "code",
                    errorWhenTouchedOrSubmitted: true,
                  })}
                />
              </Grid>
              <Grid item>
                <AsyncButton
                  fullWidth
                  variant="contained"
                  size="large"
                  onClick={handleSubmit(formik)}
                  disabled={formik.isSubmitting}
                >
                  Login
                </AsyncButton>
              </Grid>

              {error && (
                <Grid item>
                  <FormHelperText error>
                    <ErrorText>{error}</ErrorText>
                  </FormHelperText>
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </OnboardingBackdrop>
  );
};

export default ConfirmMFA;

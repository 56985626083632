import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";

export const SimpleList = ({
  items,
  icon,
  dense = true,
  disablePadding = true,
  ...props
}) => (
  <Box {...props}>
    <List dense={dense}>
      {items.map((item, index) => (
        <ListItem disablePadding={disablePadding} key={item}>
          <ListItemText>
            <Typography variant="body2">
              {index + 1}. {item}
            </Typography>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  </Box>
);

import { forwardRef, useMemo } from "react";
import { get, debounce } from "lodash";

import Typography from "@mui/material/Typography";

const getFieldProps = (
  formik,
  {
    name,
    disabled,
    errorWhenTouchedOrSubmitted = false,
    errorOverride = false,
    helperText = "",
  }
) => ({
  name,
  value: get(formik.values, name) || "",
  onBlur: formik.handleBlur,
  onChange: formik.handleChange,
  onKeyDown: (e) => {
    if (
      e.key === "Enter" &&
      (e.target.tagName.toLowerCase() !== "textarea" || e.altKey)
    ) {
      formik.handleSubmit();
    }
  },
  error:
    ((!errorWhenTouchedOrSubmitted || get(formik.touched, name, false)) &&
      Boolean(get(formik.errors, name, false))) ||
    errorOverride,
  disabled: formik.isSubmitting || disabled,
  helperText:
    (!errorWhenTouchedOrSubmitted || get(formik.touched, name, false)) &&
    Boolean(get(formik.errors, name, false)) ? (
      <Typography component="span">{get(formik.errors, name)}</Typography>
    ) : (
      helperText
    ),
});

const DebounceInput = forwardRef(function DebounceInput(props, ref) {
  const onChange = useMemo(
    () =>
      debounce(props.onChange, props.debounceTimeout, {
        leading: true,
      }),
    [props.onChange, props.debounceTimeout]
  );

  return <input {...props} onChange={onChange} ref={ref} />;
});

const inputProps = {
  inputComponent: DebounceInput,
  inputProps: {
    debounceTimeout: 100,
  },
};

const multilineInputProps = {
  ...inputProps,
  inputProps: {
    ...inputProps.inputProps,
    element: "textarea",
  },
};

export { getFieldProps, inputProps, multilineInputProps };

import { noop, orderBy } from "lodash";
import { nanoid } from "nanoid";
import React, {
  useState,
  useCallback,
  useContext,
  createContext,
  useEffect,
} from "react";
import {
  fetchReportsAPI,
  createWorkspaceCard as createWorkspaceCardApi,
} from "../../api/index";
import { useAppContext } from "../../contexts/AppContext";
import { withOptimisticUpdates } from "../../hooks/withOptimisticUpdates.tsx";
import { BugTrackerContextType, Report, ReportInput } from "./types";
import useLoading from "../../hooks/useLoading.js";
import useReportsListeners from "../../hooks/useReportsListeners.js";

export const TWO_WEEKS_IN_MS = 1000 * 60 * 60 * 24 * 14;

const BugTrackerContext = createContext<BugTrackerContextType>({
  reports: [],
  selectedReports: [],
  setSelectedReports: noop,
  likelyBugs: [],
  loading: false,
  refreshReports: noop,
  createCard: noop,
});

export const BugTrackerProvider = ({ children }) => {
  const { selectedCompanyId } = useAppContext();

  const [likelyBugs, setLikelyBugs] = useState<Report[]>([]);
  const [trackerReports, setTrackerReports] = useState<Report[]>([]);
  const [selectedReports, setSelectedReports] = useState<Report[]>([]);

  useReportsListeners({ setReports: setTrackerReports, label: "bugTracker" });

  const loadReports = useCallback(async () => {
    const { reports: apiReports } = await fetchReportsAPI(selectedCompanyId, {
      target: "tracker-new",
    });

    const getTrackerOrder = (report) => {
      if (["widget", "wss"].includes(report.metadata?.triggerType)) {
        return 1000;
      }
      return 0;
    };

    const reportsWithTrackerOrder = apiReports.map((report) => {
      return {
        ...report,
        trackerOrder: getTrackerOrder(report),
      };
    });

    return orderBy(
      reportsWithTrackerOrder,
      ["trackerOrder", "priority", "timestamp"],
      ["desc", "desc", "desc"]
    );
  }, [selectedCompanyId]);

  const { isLoading, handler: refreshReports } = useLoading(async () => {
    const reports = await loadReports();
    setTrackerReports(reports);
  });

  useEffect(() => {
    refreshReports();
  }, [selectedCompanyId]);

  // periodically loads sorting
  useEffect(() => {
    const interval = setInterval(() => {
      if (isLoading) {
        return;
      }

      if (document.visibilityState !== "visible") {
        return;
      }
    }, 1000 * 10);

    return () => clearInterval(interval);
  }, [isLoading]);

  useEffect(() => {
    setLikelyBugs(
      trackerReports.filter((report) => {
        return (
          report.bugStatus === "likely_a_bug" &&
          Date.now() - report?.timestamp <= TWO_WEEKS_IN_MS
        );
      })
    );
  }, [trackerReports]);

  const createCard = useCallback(
    (payload: ReportInput) => {
      const report = {
        ...payload,
        companyId: selectedCompanyId,
        id: `card-${nanoid()}`,
        type: "card",
        v: "20230315",
      };
      return withOptimisticUpdates<Report>(
        createWorkspaceCardApi,
        trackerReports,
        setTrackerReports
      )(report);
    },
    [trackerReports, selectedCompanyId]
  );

  return (
    <BugTrackerContext.Provider
      value={{
        reports: trackerReports,
        selectedReports,
        setSelectedReports,
        likelyBugs,
        loading: isLoading,
        refreshReports,
        createCard,
      }}
    >
      {children}
    </BugTrackerContext.Provider>
  );
};

export const useBugTrackerContext = () => useContext(BugTrackerContext);

import { useCallback, useMemo } from "react";
import { debounce, noop } from "lodash";
import { useLocation } from "react-router-dom";
import { Box, Paper, AlertTitle, Alert } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { getColumns, getLoadingColumns } from "../ReportsListCells/getColumns";
import {
  DATA_GRID_INITIAL_PAGE_SIZE,
  DATA_GRID_PAGE_SIZES,
  DATA_GRID_INITIAL_STATE,
  DATA_GRID_STYLES,
  FIXED_ROW_HEIGHT,
} from "./config.tsx";
import { NoRows } from "./NoRows";
import { makeReportUrl } from "../../components/report/makeReportUrl";

const USERS_COUNT_THRESHOLD = 2;

export const isUserReported = (report) =>
  report.metadata?.triggerType === "widget";

export const isFrequent = (report) =>
  report.metadata?.errorInfo?.numAffectedUsers >= USERS_COUNT_THRESHOLD;

export const isRecommended = (report) =>
  isFrequent(report) ||
  ["widget", "sdk", "wss"].includes(report.metadata?.triggerType);

export const ReportsTable = ({
  activeTab,
  currentWorkspace,
  deletingList,
  reportsLoading,
  onCreateSampleReport,
  onLoadMore,
  onOpenReport,
  reports,
  setLatestOnly,
  currentView = "all", // when active_tab = possible_bugs: priority, user-reported, all
  setCurrentView = noop,
}) => {
  const location = useLocation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRowsScrollEnd = useCallback(
    debounce(
      () => {
        setLatestOnly(false);
        onLoadMore();
      },
      750,
      { leading: false, trailing: true }
    ),
    [setLatestOnly]
  );

  const isSessionReplays = useMemo(
    () => location.pathname.includes("/reports/all"),
    [location.pathname]
  );

  const isInbox = useMemo(
    () => location.pathname.includes("/issues"),
    [location.pathname]
  );

  const getRowClassName = useCallback(
    (item) => {
      // if less than 3 minutes ago, show as pending
      if (item.row.timestamp > Date.now() - 1000 * 60 * 3) {
        return "pending";
      }

      if (deletingList.includes(item.id)) {
        return "deleting";
      }

      const lastSeenOn = item.row.lastSeenOn || {};
      const hasSomeoneSeenThisReport = Object.keys(lastSeenOn).length > 0;

      if (!hasSomeoneSeenThisReport) {
        return "new";
      }
    },
    [deletingList]
  );

  const rows = useMemo(() => {
    if (reportsLoading && !reports?.length) {
      return [1, 2, 3].map((i) => ({ id: i }));
    }

    if (currentView === "all") {
      return reports;
    }

    if (currentView === "user-reported") {
      return reports.filter(isUserReported);
    }

    if (currentView === "priority") {
      return reports.filter(isRecommended);
    }
  }, [currentView, reports, reportsLoading]);

  const columns = useMemo(() => {
    if (reportsLoading) {
      return getLoadingColumns();
    }

    return getColumns({
      currentWorkspace,
    });
  }, [reportsLoading, currentWorkspace]);

  const handleRowClick = (row, event) => {
    event.preventDefault();
    event.stopPropagation();

    // open in new tab if:
    // - user is holding down the ctrl key
    // - user is holding down the meta key (mac)
    if (event.shiftKey) {
      window.open(makeReportUrl(row), "_blank");
      return;
    }

    onOpenReport(row);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          width: "100%",
        }}
      >
        {isSessionReplays && (
          // Deprecation notice
          <Alert severity="error" sx={{ m: 2 }}>
            <AlertTitle>Deprecation notice</AlertTitle>
            This feature is deprecated and will be removed on October, 1st. If
            you are looking for a specific session, use the Search bar at the
            top of the page.
          </Alert>
        )}

        <DataGridPro
          key={location.pathname}
          rows={rows}
          columns={columns}
          getRowClassName={getRowClassName}
          pageSize={DATA_GRID_INITIAL_PAGE_SIZE}
          rowsPerPageOptions={DATA_GRID_PAGE_SIZES}
          initialState={DATA_GRID_INITIAL_STATE}
          getRowHeight={() => FIXED_ROW_HEIGHT}
          sx={DATA_GRID_STYLES}
          headerHeight={0}
          onRowClick={handleRowClick}
          pagination
          paginationMode="server"
          rowCount={rows.length}
          components={{
            NoRowsOverlay: () => (
              <NoRows
                activeTab={activeTab}
                isInbox={isInbox}
                isReportsLoading={reportsLoading}
                onCreateSampleReport={onCreateSampleReport}
                isAll={isSessionReplays}
                reports={rows}
                currentView={currentView}
                setCurrentView={setCurrentView}
              />
            ),

            Footer: () => <Box />,
          }}
          // Selection logic
          //
          disableSelectionOnClick
          disableColumnFilter
          sortModel={DATA_GRID_INITIAL_STATE.sorting.sortModel}
        />
      </Paper>
    </Box>
  );
};

import React, {
  useCallback,
  useState,
  useRef,
  useMemo,
  useEffect,
} from "react";

import { Alert, Button, ButtonGroup, Paper } from "@mui/material";
import { Box, Stack } from "@mui/system";
import {
  ChatOutlined as ChatIcon,
  SmartDisplayOutlined as SmartDisplayIcon,
} from "@mui/icons-material";

import ActionsTable, { CONSOLE_SIZES } from "./ActionsTable";
import { UserEnvGroup } from "./UserEnvGroup";
import Activities from "./Activities/index.tsx";
import { useAutopilot } from "./AutopilotGroup/AutopilotContext.tsx";
import NotesPanel from "./NotesPanel";
import { BugTranscriptProvider } from "./StepsToReproduce/StepsAsText.tsx";
import { PlayerContainer } from "./PlayerContainer.tsx";
import { UserButton } from "./UserButton.js";
import { PriorityWizard } from "./PriorityWizard.tsx";

const ActionsPanel = ({
  company,
  report,
  reportContents,
  isReportLoading,
  user,
  setReport,
  defaultTab: currentTab,
  switchTab,
  readOnly,
  variant,
}) => {
  const { loading } = useAutopilot();
  const isCard = useMemo(() => report.id?.startsWith?.("card-"), [report.id]);

  const availableTabs = useMemo(
    () =>
      [
        {
          value: "session",
          label: "Session",
          icon: <SmartDisplayIcon sx={{ fontSize: 18 }} />,
          show: !isCard,
        },
        {
          value: "discussion",
          label: "Discussion",
          icon: <ChatIcon sx={{ fontSize: 18 }} />,
          show: !readOnly && variant === "full",
        },
      ].filter((tab) => tab.show),
    [readOnly, isCard]
  );

  // if defaultCard is not available, switch to the first available tab
  useEffect(() => {
    if (
      !availableTabs.find((tab) => tab.value === currentTab) &&
      currentTab !== "user"
    ) {
      switchTab(availableTabs[0].value);
    }
  }, [availableTabs, currentTab, switchTab]);

  const handleTabChange = useCallback(
    (_, newTab) => switchTab(newTab),
    [switchTab]
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const [actionsTableHeight, setActionsTableHeight] = useState<number>(
    CONSOLE_SIZES.medium
  );

  if (loading) {
    return null;
  }

  if (!report.activities || report.activities.length === 0) {
    if (report?.metadata?.isOverQuota) {
      return (
        <Alert severity="warning">
          Sorry, your workspace was over quota when this report was generated
          and we were unable to analyze this session.
        </Alert>
      );
    }
  }

  return (
    <Stack
      sx={{
        height: "100%",
      }}
      spacing={1}
    >
      <Stack
        direction={"row"}
        spacing={2}
        alignItems="center"
        justifyContent="flex-start"
      >
        <ButtonGroup
          variant="outlined"
          size="small"
          color="secondary"
          sx={{
            height: 36,
          }}
        >
          {availableTabs.map((tab) => {
            if (!tab.show) {
              return null;
            }

            return (
              <Button
                key={tab.value}
                value={tab.value}
                startIcon={tab.icon}
                onClick={() => handleTabChange(null, tab.value)}
                variant={currentTab === tab.value ? "contained" : "outlined"}
              >
                {tab.label}
              </Button>
            );
          })}
        </ButtonGroup>

        <Box sx={{ flexGrow: 1 }} />

        {!isCard && (
          <Button
            variant={currentTab === "user" ? "contained" : "outlined"}
            size="small"
            color="secondary"
            sx={{
              px: 0,
              height: 36,
              textTransform: "none",
            }}
            onClick={() => handleTabChange(null, "user")}
          >
            <UserButton
              report={report}
              onTabChange={handleTabChange}
              showOthers={false}
            />
          </Button>
        )}
      </Stack>

      {currentTab === "discussion" && company && user && !readOnly && (
        <Box mb={2}>
          <PriorityWizard report={report} setReport={setReport} />
        </Box>
      )}

      <Box
        style={{
          width: "100%",
          height: "100%",
          overflow: "auto",
        }}
        sx={{
          maxWidth: "lg",
          pt: 1,
        }}
      >
        {currentTab === "session" && (
          <>
            <Stack
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 1,
                overflow: "hidden",
              }}
              ref={containerRef}
            >
              <Box sx={{ flexGrow: 1, flexShrink: 1 }}>
                <PlayerContainer
                  isReportLoading={isReportLoading || loading}
                  report={report}
                  reportContents={reportContents}
                />
              </Box>

              <Box
                sx={{
                  flexGrow: 0,
                  flexShrink: 0,
                  flexBasis: `${actionsTableHeight}px`,
                }}
              >
                <ActionsTable
                  type="logs"
                  report={report}
                  isReportLoading={isReportLoading}
                  reportContents={reportContents}
                  isLive={false}
                  liveActivities={[]}
                  initialState={{
                    showErrors: false,
                  }}
                  // containerRef={containerRef}
                  // compact
                  syncScroll
                  setActionsTableHeight={setActionsTableHeight}
                />
              </Box>
            </Stack>
          </>
        )}

        {/* {currentTab === "logs" && (
          <ActionsTable
            type="logs"
            report={report}
            isReportLoading={isReportLoading}
            reportContents={reportContents}
            isLive={false}
            liveActivities={[]}
            initialState={{
              showErrors: actionsTableDefaultShowErrors,
            }}
            containerRef={containerRef}
            compact={false}
            syncScroll={false}
          />
        )} */}

        {/* {currentTab === "resources" && (
          <ActionsTable
            type="resources"
            report={report}
            isReportLoading={isReportLoading}
            reportContents={reportContents}
            // TODO: restore
            isLive={false}
            liveActivities={[]}
            // isLive={isLive}
            // liveActivities={activities}
            initialState={{
              showErrors: actionsTableDefaultShowErrors,
            }}
            containerRef={containerRef}
            compact={false}
          />
        )} */}

        {currentTab === "user" && <UserEnvGroup report={report} />}

        {currentTab === "notes" && (
          <Paper>
            <NotesPanel key={report.id} report={report} setReport={setReport} />
          </Paper>
        )}

        {currentTab === "discussion" && company && user && !readOnly && (
          <Box
            sx={{
              height: "100%",
            }}
          >
            <BugTranscriptProvider report={report}>
              <Activities
                report={report}
                company={company}
                setReport={setReport}
              />
            </BugTranscriptProvider>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default ActionsPanel;

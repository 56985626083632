import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
  List,
  ListSubheader,
  ListItemButton,
} from "@mui/material";
import { fetchRelatedReports as fetchRelatedReportsApi } from "../../api";
import { filter } from "lodash";
import { UserChip } from "../../pages/ReportsListCells/renderUserId";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIosOutlined as ArrowForwardIosIcon } from "@mui/icons-material";
import { withErrorBoundary } from "react-error-boundary";
import useLoading from "../../hooks/useLoading";
import DateTime from "../DateTime";

const RelatedReportsComponent = ({ report, variant }) => {
  const navigate = useNavigate();

  const [relatedReports, setRelatedReports] = useState([]);

  const {
    isLoading,
    error,
    handler: fetchRelatedReports,
  } = useLoading(fetchRelatedReportsApi);

  useEffect(() => {
    if (!report?.id || !report?.companyId) {
      return;
    }

    const originalReportId =
      report.metadata?.errorInfo?.originalReportId || // for additional report instances
      report.id; // for original report

    fetchRelatedReports(
      {
        reportId: originalReportId,
        companyId: report.companyId,
      },
      { withAuth: variant === "full" }
    ).then((data) => {
      const relatedReports = filter(
        data?.reports || [],
        (r) => r.id !== report.id
      ).reverse();
      setRelatedReports(relatedReports);
    });
  }, [variant, report?.id, report?.companyId]);

  const handleRelatedReportClick = useCallback(
    (relatedReport) => (e) => {
      // if cmd/ctrl key is pressed, open in new tab
      if (e.metaKey || e.ctrlKey) {
        window.open(`/report/${relatedReport.id}?via=related`, "_blank");
        return;
      }

      navigate(`/report/${relatedReport.id}`);
    },
    [navigate]
  );

  if (!report) {
    return null;
  }

  if (!isLoading && !relatedReports.length && !error) {
    return null;
  }

  return (
    <Box
      sx={{
        maxHeight: 190,
        overflowY: "scroll",
      }}
    >
      <Stack spacing={1}>
        {isLoading && (
          <Tooltip
            arrow
            title="Looking for similar reports..."
            placement="bottom"
          >
            <CircularProgress size={20} color="muted" />
          </Tooltip>
        )}

        {error && <p>Error: {error.message}</p>}

        {relatedReports.length > 0 && !isLoading && !error && (
          <>
            <List
              subheader={
                <ListSubheader
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: 600,
                    fontSize: 11,
                    lineHeight: "11px",
                    paddingBottom: 1,
                    paddingLeft: 0,
                  }}
                >
                  {relatedReports.length === 1000
                    ? "1000+"
                    : relatedReports.length}{" "}
                  Related Reports
                </ListSubheader>
              }
            >
              {relatedReports.map((relatedReport) => (
                <ListItemButton
                  key={relatedReport.id}
                  variant="text"
                  color="secondary"
                  size="small"
                  onClick={handleRelatedReportClick(relatedReport)}
                  fullWidth
                  disableGutters
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box sx={{ minWidth: 0 }}>
                      <UserChip row={relatedReport} />
                      <Typography
                        variant="caption"
                        color="tertiary.main"
                        sx={{ ml: 3 }}
                      >
                        <DateTime>{relatedReport.timestamp}</DateTime>
                      </Typography>
                    </Box>

                    <Box flexGrow={1} />

                    <Typography>#{relatedReport.serialNumber}</Typography>
                    <ArrowForwardIosIcon
                      fontSize="small"
                      sx={{
                        height: "12px",
                      }}
                    />
                  </Stack>
                </ListItemButton>
              ))}
            </List>
          </>
        )}
      </Stack>
    </Box>
  );
};

export const RelatedReports = withErrorBoundary(RelatedReportsComponent, {
  fallback: <p>Cannot show related reports</p>,
  onError(error, componentStack) {
    console.error("RelatedReports error", error, componentStack);
  },
});

import "polyfill-intl-enumeration/dist/polifyll-intl-enumeration.min";

import React from "react";
import { createRoot } from "react-dom/client";
import { LicenseInfo } from "@mui/x-license-pro";
import { inject as injectVercelAnalytics } from "@vercel/analytics";

import App from "./App";

injectVercelAnalytics();

// Mui DataGrid Pro License
// https://mui.com/x/introduction/licensing/#license-key-installation
LicenseInfo.setLicenseKey(
  "3f92e5b8bc32fd21ac6de15d1347a249Tz01NDg0NixFPTE3MDA3NDk3NzQ3MTIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

// Fixes an amplify issue that swallows "code" OAUTH2 param
// from our integrations' setup flow
// Workaround from: https://github.com/aws-amplify/amplify-js/issues/9208
if (
  window.location.pathname.startsWith("/settings/integrations") &&
  window.location.search.includes("code=")
) {
  const searchParams = new URLSearchParams(window.location.search);
  window.localStorage.setItem(
    "oauthflow",
    JSON.stringify(Object.fromEntries(searchParams.entries()))
  );

  // remove the code= param, before Amplify
  // attempts to redirect us to /
  console.warn(
    "[OAUTH2] Handling Integrations OAUTH2 flow (removing querystring)"
  );
  window.history.pushState(
    {},
    document.title,
    window.location.pathname + window.location.hash
  );
}

const container = document.getElementById("root");
const root = createRoot(container);

const renderApp = async () => {
  root.render(<App />);
};

const registerServiceWorker = async () => {
  try {
    if (!("serviceWorker" in navigator)) {
      return;
    }

    await navigator.serviceWorker.register("/service-worker.js");
  } catch (e) {
    console.error("Register Service Worker error =>", e);
  }
};

registerServiceWorker();

renderApp();

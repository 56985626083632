import { Backdrop, Box } from "@mui/material";
import { useCallback } from "react";

const Modal = ({ onClose, onSubmit, children }) => {
  const stopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Backdrop
      open
      sx={{
        background:
          "linear-gradient(317.41deg, #F0F5FE 21.54%, rgba(240, 245, 254, 0.8) 103.27%)",
        backdropFilter: "blur(2px)",
        zIndex: 1200,
      }}
      onClick={onClose}
    >
      <Box
        display="flex"
        flex-direction="column"
        p={4}
        width={484}
        boxSizing="border-box"
        maxHeight="100vh"
        height="100%"
      >
        <form
          onClick={stopPropagation}
          onSubmit={onSubmit}
          style={{ width: "100%" }}
        >
          {children}
        </form>
      </Box>
    </Backdrop>
  );
};

export default Modal;

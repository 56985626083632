import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAppContext } from "../contexts/AppContext";
import { SelectWorkspaceDropdown } from "../components/SelectWorkspaceDropdown";
import { Alert, AlertTitle, Divider } from "@mui/material";
import { OnboardingFrame } from "../components/OnboardingFrame";

export const SubscriptionEnded = () => {
  const { companies, companyMembers, selectedCompanyId } = useAppContext();

  const workspace = useMemo(() => {
    return companies?.find((company) => company.id === selectedCompanyId);
  }, [companies, selectedCompanyId]);

  const workspacesCount = companies.length;
  const owner = companyMembers?.find((member) => member.sub === workspace?.id);

  if (!workspace) {
    return (
      <Box maxWidth="sm" m={4}>
        <Alert severity="error">
          <AlertTitle>Workspace not found</AlertTitle>
          The workspace you are trying to access does not exist.
        </Alert>
      </Box>
    );
  }

  return (
    <OnboardingFrame>
      <Typography fontWeight="bold" variant="h6">
        Sorry to see you go ;(
      </Typography>

      <Typography variant="body1">
        Your Bugpilot subscription for <strong>{workspace.name}</strong> has
        ended.
        <br />
        Please contact the workspace owner to renew your subscription.
      </Typography>

      {owner && (
        <Typography variant="body1">
          The owner of this workspace is {owner.given_name} {owner.family_name}{" "}
          (
          <a
            href={`mailto:${owner.email}?subject=Please+renew+our+Bugpilot+subscription`}
          >
            {owner.email}
          </a>
          ).
        </Typography>
      )}

      {workspacesCount > 1 && (
        <>
          <Divider />
          <Box sx={{ pt: 2 }}>
            <SelectWorkspaceDropdown
              label="Select a different workspace"
              sx={{
                ml: -1.75,
              }}
            />
          </Box>
        </>
      )}
    </OnboardingFrame>
  );
};

import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MailOutlineIcon from "@mui/icons-material/MailOutlineOutlined";

import Link from "../components/Link";
import Input from "../components/Input";
import AsyncButton from "../components/AsyncButton";
import CodeIcon from "../icons/CodeIcon";
import { getFieldProps } from "../utils/getFieldProps";
import { handleSubmit } from "../utils/formik";
import FormHelperText from "@mui/material/FormHelperText";
import ErrorText from "../components/ErrorText";
import PasswordField, {
  getPasswordValidationRule,
} from "../components/PasswordField";
import OnboardingBackdrop from "../components/OnboardingBackdrop";

const validationSchema = Yup.object({
  password: getPasswordValidationRule(),
  confirmPassword: getPasswordValidationRule().oneOf(
    [Yup.ref("password")],
    "Passwords are not the same"
  ),
});

const ConfirmResetPassword = ({ onConfirm, onSignInClick, email }) => {
  const [error, setError] = useState(null);
  const formik = useFormik({
    initialValues: {
      email,
      code: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async ({ email, code, password }) => {
      try {
        setError(null);
        await onConfirm({ email, code, password });
      } catch (e) {
        console.error(e);
        setError(e.message);
        throw e;
      }
    },
  });

  return (
    <OnboardingBackdrop>
      <Box maxWidth={700} width="100vw" boxSizing="border-box">
        <Paper>
          <Box p={5}>
            <Grid container spacing={4} flexDirection="column">
              <Grid item>
                <Typography fontWeight="bold" variant="h6">
                  Reset your password
                </Typography>
                <Typography variant="body">
                  Enter the code you received via e-mail and your new password.
                </Typography>
              </Grid>
              <Grid item>
                <Input
                  label="Email address"
                  required
                  placeholder="Email address"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                  {...getFieldProps(formik, {
                    name: "email",
                    errorWhenTouchedOrSubmitted: true,
                  })}
                />
              </Grid>
              <Grid item>
                <Input
                  label="Confirmation code"
                  required
                  placeholder="Confirmation code"
                  type="text"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CodeIcon />
                      </InputAdornment>
                    ),
                  }}
                  {...getFieldProps(formik, {
                    name: "code",
                    errorWhenTouchedOrSubmitted: true,
                  })}
                />
              </Grid>
              <Grid item>
                <PasswordField
                  label="New password"
                  {...getFieldProps(formik, {
                    name: "password",
                    errorWhenTouchedOrSubmitted: true,
                  })}
                />
              </Grid>
              <Grid item>
                <PasswordField
                  label="Repeat new password"
                  placeholder="Repeat your new password"
                  {...getFieldProps(formik, {
                    name: "confirmPassword",
                    errorWhenTouchedOrSubmitted: true,
                  })}
                />
              </Grid>
              <Grid item>
                <AsyncButton
                  isLoading={formik.isSubmitting}
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleSubmit(formik)}
                >
                  Set new password
                </AsyncButton>
              </Grid>

              {error && (
                <Grid item>
                  <FormHelperText error>
                    <ErrorText>{error}</ErrorText>
                  </FormHelperText>
                </Grid>
              )}

              <Grid item>
                <Typography variant="body2">
                  <Link to="/signin">Back to Login</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </OnboardingBackdrop>
  );
};

export default ConfirmResetPassword;

import { useCallback, useEffect, useState } from "react";
import { noop } from "lodash";

const CopyToClipboardBase = ({ text, onCopy = noop, children }) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!isCopied) {
      return;
    }

    const timeoutId = setTimeout(() => {
      clearTimeout(timeoutId);
      setIsCopied(false);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isCopied]);

  const copy = useCallback(() => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    onCopy();
  }, [text, onCopy]);

  return typeof children === "function"
    ? children({ isCopied, copy })
    : children;
};

export default CopyToClipboardBase;

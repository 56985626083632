import React, { useCallback, useMemo, useState } from "react";
import {
  Paper,
  Typography,
  Box,
  Button,
  CircularProgress,
  Stack,
  Chip,
  CardMedia,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/AddOutlined";
import {
  CheckCircleOutlined as CheckCircleIcon,
  OpenInNewOutlined as OpenInNewIcon,
} from "@mui/icons-material";
import { useAnalytics } from "../../../analytics/AnalyticsContext";
import { useIntegrations } from "../../../contexts/IntegrationsContext";
import useHasRole from "../../../hooks/useHasRole";
import { IntegrationCardDialog } from "./IntegrationCardDialog";

export const IntegrationCard = ({
  type,
  name,
  text,
  descriptionComponent: DescriptionComponent,
  icon,
  component: Component,
  onAdd: _onAdd,
  documentationLink,

  isShowCount = true,
  isShowName = true,
  coverImage = false,
  dialogContentComponent: DialogContentComponent,

  // new props:
  company,
  onCreateIntegration,
  onDeleteIntegration,
  onUpdateIntegration,
  onCompanyChange,

  isDeletable = true,
}) => {
  const { trackEvent } = useAnalytics();
  const { integrationsByType } = useIntegrations();
  const { hasRole: isAdminOrOwner } = useHasRole("admin");
  const { hasRole: isOwner } = useHasRole("owner");

  const [isAdding, setIsAdding] = useState(false);
  const [open, setOpen] = useState(false);

  const closeDetails = () => setOpen(false);
  const openDetails = () => setOpen(true);

  const onAdd = () => {
    if (typeof _onAdd === "function") {
      trackEvent("Connect Integration Click", { type, name });
      _onAdd();
    }
  };

  const handleCreateIntegration = useCallback(
    async (data) => {
      setIsAdding(true);
      await onCreateIntegration(company.id, {
        type,
        ...data,
      });
      setIsAdding(false);
    },
    [company, onCreateIntegration, type]
  );

  const configuredIntegrations = useMemo(
    () => integrationsByType[type] || [],
    [integrationsByType, type]
  );

  const canAdd = useMemo(() => {
    return typeof _onAdd === "function";
  }, [_onAdd]);

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      name={type}
      sx={{
        width: "100%",
        minHeight: coverImage ? 530 : 280,
      }}
    >
      <Box
        name={type}
        style={{
          minWidth: 300,
          height: "100%",
          width: "100%",
        }}
      >
        <Paper sx={{ height: "100%" }}>
          {coverImage && (
            <CardMedia component="img" image={coverImage} height={250} />
          )}

          <Box p={4}>
            <Grid container spacing={2} flexDirection="column">
              {isShowName ? (
                <Grid item>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Stack direction="row" spacing={2}>
                        {icon}

                        <Typography variant="body1" fontWeight="bold">
                          {name}
                        </Typography>
                      </Stack>
                    </Grid>

                    {canAdd && (
                      <Grid item>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={onAdd}
                          disabled={!isAdminOrOwner || isAdding}
                          startIcon={
                            isAdding ? (
                              <CircularProgress size={15} />
                            ) : (
                              <AddIcon />
                            )
                          }
                        >
                          {isAdding ? "Connecting..." : "Connect"}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ) : null}

              {text && (
                <Grid item>
                  <Typography variant="body1">{text}</Typography>
                </Grid>
              )}

              {isShowCount &&
                (configuredIntegrations?.length > 0 ? (
                  <Grid item>
                    <Chip
                      size="small"
                      color="success"
                      icon={<CheckCircleIcon />}
                      label={`${
                        configuredIntegrations.length > 1
                          ? `${configuredIntegrations.length} e`
                          : "E"
                      }nabled`} // Enabled
                    />
                  </Grid>
                ) : null)}

              <IntegrationCardDialog
                name={name}
                Component={Component}
                DialogContentComponent={DialogContentComponent}
                company={company}
                onCompanyChange={onCompanyChange}
                isAdminOrOwner={isAdminOrOwner}
                isAdding={isAdding}
                configuredIntegrations={configuredIntegrations}
                onDeleteIntegration={onDeleteIntegration}
                handleCreateIntegration={handleCreateIntegration}
                onUpdateIntegration={onUpdateIntegration}
                open={open}
                openDetails={openDetails}
                closeDetails={closeDetails}
                isDeletable={isDeletable}
              />

              {isAdding && (
                <Grid item>
                  <Box display="flex" justifyContent="center">
                    <CircularProgress size={25} />
                  </Box>
                </Grid>
              )}

              {/* Show text below the title, can be used to add buttons,
                  installations count, etc... */}
              {DescriptionComponent && (
                <Grid item>
                  <DescriptionComponent
                    handleOpenToggle={openDetails}
                    company={company}
                    isOwner={isOwner}
                    onCompanyChange={onCompanyChange}
                    isDisabled={!isAdminOrOwner}
                    integration={configuredIntegrations?.[0]}
                    integrations={configuredIntegrations}
                    onDeleteIntegration={onDeleteIntegration}
                    onUpdateIntegration={onUpdateIntegration}
                    onCreateIntegration={handleCreateIntegration}
                  />
                </Grid>
              )}

              {documentationLink && (
                <Box ml={2}>
                  <Stack alignItems="flex-start" spacing={1}>
                    <Button
                      variant="text"
                      href={documentationLink}
                      target="_blank"
                      startIcon={<OpenInNewIcon fontSize="small" />}
                      size="small"
                      color="secondary"
                    >
                      How does it work?
                    </Button>
                  </Stack>
                </Box>
              )}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Grid>
  );
};

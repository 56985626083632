import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Link, Stack } from "@mui/material";
import { TopAppBar } from "../router/MenuSidebar/TopAppBar.tsx";
import MenuSidebar from "../router/MenuSidebar/MenuSidebar";

export const OnboardingFrame = ({
  children,
  LeftComponent,
  LeftComponentProps,
  hideTos,
}) => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        position: "relative",
      }}
    >
      <MenuSidebar />
      <TopAppBar />

      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1200,
          // backgroundColor: "bg.paper",
          backdropFilter: "blur(3px)",
        }}
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Box component="form" maxWidth={350} width="100%">
            <Stack spacing={2}>{children}</Stack>
          </Box>

          {!hideTos && (
            <Box
              sx={{
                position: "absolute",
                bottom: 32,
              }}
            >
              <Stack direction="row" spacing={1}>
                <Typography variant="caption">
                  <Link
                    href="mailto:support@bugpilot.io?subject=Support%20Request"
                    target="_blank"
                    color="text.tertiary"
                  >
                    Support
                  </Link>
                </Typography>

                <Typography variant="caption" color="text.tertiary">
                  |
                </Typography>
                <Typography variant="caption">
                  <Link
                    href="https://www.bugpilot.io/terms"
                    target="_blank"
                    color="text.tertiary"
                  >
                    Terms and Conditions
                  </Link>
                </Typography>

                <Typography variant="caption" color="text.tertiary">
                  |
                </Typography>

                <Typography variant="caption">
                  <Link
                    href="https://www.bugpilot.io/privacy"
                    target="_blank"
                    color="text.tertiary"
                  >
                    Privacy Policy
                  </Link>
                </Typography>
              </Stack>
            </Box>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

import { createTheme } from "@mui/material/styles";

export let theme = createTheme({
  palette: {
    primary: {
      main: "#0d0f19",
      dark: "#0d0f19",
      accent: "#4736B5",
      accentDark: "#271695",
      contrastText: "#FEFEFE",
    },
    secondary: {
      light: "#FAFAFA",
      main: "#404040",
      dark: "#0d0f19",
      contrastText: "#F2F2F5",
    },
    tertiary: {
      light: "#FAFAFA",
      main: "#707070",
      dark: "#5d5f59",
      contrastText: "#FFFFFF",
    },
    muted: {
      light: "#FAFAFA",
      lightish: "#EAEAEA",
      main: "#D0D0D0",
      dark: "#B0B0B0",
      contrastText: "#0d0f19",
    },
    light: {
      main: "#606060",
      dark: "#404040",
      contrastText: "#FFFFFF",
    },
    white: {
      main: "#FEFEFE",
      dark: "#FAFAFA",
      contrastText: "#0d0f19",
      border: "#E5E5E5",
    },
    text: {
      primary: "#0d0f19",
      secondary: "#404040",
      tertiary: "#606060",
      muted: "#707070",
      accent: "#4736B5",
    },

    // page frames
    bg: {
      sidebar: "", // see below for colors
      page: "#FFFFFF",
      paper: "#FFFFFF",
      board: "#FAFAFA",
    },

    // // sidebar
    // sidebar: {
    //   avatarBg: "#FEFEFE",
    //   avatarFg: "#404040",
    //   gradient1: "#EDEBFF",
    //   gradient2: "#E6E3FF",
    //   title: "#404040",
    //   item: "#0d0f19",
    //   border: "#E5E5E5",
    // },

    // sidebar
    sidebar: {
      avatarBg: "#404040",
      avatarFg: "#FEFEFE",
      gradient1: "#FFFFFF",
      gradient2: "#FFFFFF",
      title: "#303030",
      item: "#303030",
      border: "#303030",
      calloutBg: "#404040",
      calloutText: "#8ADB88",
    },

    // utils:
    success: {
      main: "#1ABC8B",
      contrastText: "#FEFEFE",
    },
    error: {
      main: "#EC515A",
      contrastText: "#FEFEFE",
    },
    warning: {
      main: "#F4B46D",
      contrastText: "#FEFEFE",
    },
    info: {
      main: "#6B47FB",
      contrastText: "#FEFEFE",
    },
  },
  typography: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
    fontSize: 14,
    code: {
      fontFamily: "monospace",
      fontSize: 12,
    },
    h2: {
      fontSize: 16,
      fontWeight: 500,
    },
    h3: {
      fontSize: 16,
      fontWeight: 400,
    },
    h4: {
      fontSize: 19,
      fontWeight: 400,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
    },
    body: {
      fontSize: 14,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
    },
    caption: {
      fontSize: 12,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "error.main",
          margin: "auto",
          padding: 0,
        },
      },
    },

    // all strong tags have fontWeight: 600
    MuiTypography: {
      styleOverrides: {
        root: {
          "& strong": {
            fontWeight: 600,
          },
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: {},
      },
    },

    // disable accordion elevation
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    // drawer paper
    MuiDrawer: {
      styleOverrides: {
        root: {
          "&.AppDrawer": {
            // menu list item color white
            "& .MuiListItem-root": {
              borderRadius: 4,
              // icon
              "& .MuiListItemIcon-root": {
                marginRight: 12,
              },
              // primary text white
              "& .MuiListItemText-primary": {},
              // secondary text white
              "& .MuiListItemText-secondary": {},
            },

            // button
            "& .MuiButtonBase-root": {
              borderRadius: 4,

              // color: "#FEFEFE",

              "&:hover": {
                // change also in MenuSectionTitle
                backgroundColor: "unset",
              },
              "&:active": {
                // change also in MenuSectionTitle
                backgroundColor: "unset",
              },
            },
          },
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          // no shadow
          boxShadow: "none",
          // no shadow on hover
          "&:hover": {
            boxShadow: "none",
          },

          // icon should have primary color
          "& .MuiSvgIcon-root": {
            color: "#404040",
          },

          border: "1px solid",
          borderColor: "rgba(64, 64, 64, 0.5)",

          height: 36.5,

          "&.MuiToggleButton-sizeSmall": {
            fontSize: 12,
            padding: "4px 10px",
            height: "unset",
            lineHeight: 1.25,
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 14,

          // no box shadow
          boxShadow: "none",

          // no box shadow on hover
          "&:hover": {
            boxShadow: "none",
          },

          // small size, font size 12
          "&.MuiButton-sizeSmall": {
            fontSize: 12,
          },

          "&.MuiButton-sizeLarge": {
            height: 53,
          },
        },
      },
    },

    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          border: 0,

          // no box shadow
          boxShadow: "none",

          // no box shadow on hover
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "bg.paper",

          // no elevation
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          // border: "1px solid #E5E5E5",

          // unless in accordion
          "&.MuiAccordion-root": {
            border: "none",
          },

          "&.MuiPaper-elevation1": {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          },

          "&.MuiPaper-elevation2": {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          },

          "&.MuiPaper-elevation3": {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          },
        },
      },
    },
    // menu disable elevation
    MuiMenu: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "primary.main",
          textDecoration: "none",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: 1,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 40,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "bg.paper",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-cell:first-child": {
            paddingLeft: 16,
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            width: "100%",
          },
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            width: "100%",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            width: "100%",
            marginRight: 8,
          },
          ".MuiDataGrid-cell": {
            borderBottom: "thin solid #F3F3F3",
          },
          ".MuiDataGrid-columnHeaders": {
            display: "none",
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: "#404040",
        },
      },
    },

    // .css-mf7823-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-completed": {
            color: "#1ABC8B",
          },
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          marginRight: 8,
        },
      },
    },
  },
});

theme = createTheme(theme, {
  overrides: {
    MUIRichTextEditor: {
      container: {
        minHeight: 200,
        display: "flex",
        flexDirection: "column",
        "&.initial": {
          minHeight: "auto",
        },

        "&.no-min-height": {
          minHeight: "auto",
        },
      },
      editorContainer: {
        padding: "6px 16px",
        flex: 1,
        cursor: "text !important",
        "&:hover": {
          borderColor: theme.palette.secondary.main,
        },
        "&:focus": {
          borderColor: theme.palette.primary.main,
          borderWidth: 2,
        },
        "&.initial": {
          margin: "0 16px 0 16px",
          padding: "16px 0 16px 0",
        },
      },
      placeHolder: {
        color: theme.palette.text.disabled,
      },
      toolbar: {
        "&.initial": {
          padding: "0 8px 8px 12px",
        },
      },
      formattingToolbar: {
        backgroundColor: theme.palette.muted.light,
        borderBottom: "1px solid",
        borderBottomColor: theme.palette.white.border,
        padding: "8px 8px 8px 8px",
      },
    },
  },
});

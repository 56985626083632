import * as Yup from "yup";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import AddIcon from "@mui/icons-material/AddOutlined";

import Button from "../../components/Button";
import ConfirmableInput from "../../components/ConfirmableInput";
import { useGlobalHotKeys } from "../../router/GlobalHotKeysContext.tsx";

const AddNewCard = ({ onCreate, status }, ref) => {
  const [isAdding, setIsAdding] = useState(false);
  const hotkeys = useGlobalHotKeys();

  const inputRef = useRef();

  const handleCardCreate = useCallback(
    (title) => {
      setIsAdding(false);
      return onCreate({ title });
    },
    [onCreate]
  );

  useImperativeHandle(
    ref,
    () => ({
      add: () => {
        setIsAdding(true);
        inputRef.current?.focus?.();
      },
    }),
    []
  );

  const handleAdd = useCallback(() => {
    setIsAdding(true);
  }, []);

  useEffect(() => {
    if (status !== "confirmed") {
      return;
    }

    hotkeys.register("n", handleAdd);

    return () => {
      hotkeys.unregister("n");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAdding ? (
    <ConfirmableInput
      inputRef={inputRef}
      autoFocus
      onCancel={() => setIsAdding(false)}
      placeholder="Type a title and press ⏎"
      validate={Yup.string().min(5, "Title should be at least 5 characters")}
      onChange={handleCardCreate}
    />
  ) : (
    <Button
      size="large"
      variant="text"
      startIcon={<AddIcon />}
      color="tertiary"
      onClick={handleAdd}
    >
      Add card
    </Button>
  );
};

export default forwardRef(AddNewCard);

import * as Yup from "yup";
import { forwardRef, useState } from "react";

import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import { IconButton, InputAdornment, TextField } from "@mui/material";

const PasswordField = (props, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      inputRef={ref}
      label="Password"
      name="password"
      required
      fullWidth
      placeholder="(min. 8 chars with uppercase, number and symbol)"
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowPasswordClick}>
              {showPassword ? (
                <VisibilityOffIcon fontSize="small" />
              ) : (
                <VisibilityIcon fontSize="small" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export const getPasswordValidationRule = ({ strict = true } = {}) =>
  strict
    ? Yup.string()
        .min(8, "Password must be at least 8 characters")
        .minUppercase(1, "Password must contain an uppercase letter")
        .minLowercase(1, "Password must contain a lowercase letter")
        .minNumbers(1, "Password must contain a digit")
        .minSymbols(1, "Password must contain a special character")
        .required("Please type a password")
    : Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Please type a password");

export default forwardRef(PasswordField);

import { SvgIcon } from "@mui/material";

const FrontIcon = (props) => (
  <SvgIcon {...props} viewBox="-55 0 366 366" preserveAspectRatio="xMidYMid">
    <defs>
      <linearGradient
        id="a"
        x1="12.519%"
        x2="88.228%"
        y1="85.213%"
        y2="10.023%"
      >
        <stop offset="0%" stopColor="#FF0057" stopOpacity=".16" />
        <stop offset="86.135%" stopColor="#FF0057" />
      </linearGradient>
    </defs>
    <path
      fill="#001B38"
      d="M0 60.854C0 27.245 27.245 0 60.854 0h195.143v86.6c0 16.804-13.623 30.427-30.427 30.427h-79.762c-15.805.25-28.565 13.033-28.781 28.846v189.41c0 16.804-13.622 30.427-30.427 30.427H0V60.854Z"
    />
    <circle
      cx="147.013"
      cy="147.015"
      r="78.993"
      fill="url(#a)"
      transform="rotate(90 147.013 147.015)"
    />
    <circle
      cx="147.013"
      cy="147.015"
      r="78.993"
      fill="url(#a)"
      opacity=".5"
      transform="rotate(90 147.013 147.015)"
    />
  </SvgIcon>
);

export default FrontIcon;

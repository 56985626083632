import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Tooltip, Button } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SyncIcon from "@mui/icons-material/SyncOutlined";

import Input from "../components/Input";
import CopyIcon from "../icons/CopyIcon";
import { useAppContext } from "../contexts/AppContext";
import { useModals } from "../hooks/useModals";
import { useAnalytics } from "../analytics/AnalyticsContext";
import { useSnackbar } from "notistack";

const generateId = () => crypto.randomUUID();

const InviteLinkBox = ({ onCompanyChange, company, noregenerate }) => {
  const [clicked, setClicked] = useState(false);
  const modals = useModals();
  const { trackEvent } = useAnalytics();
  const { enqueueSnackbar } = useSnackbar();

  const invitationLink = useMemo(
    () =>
      `${window.location.protocol}//${window.location.host}/invitation/${company.invitationCode}`,
    [company]
  );

  const handleCopyInvitationLink = useCallback(async () => {
    setClicked(true);
    await navigator.clipboard.writeText(invitationLink);

    trackEvent("Copy invitation link", {});

    setTimeout(() => {
      setClicked(false);
    }, 2000);
  }, [invitationLink]);

  const hasInvitationCode = useMemo(() => !!company.invitationCode, [company]);

  const handleRefreshInvitationLink = useCallback(async () => {
    trackEvent("Refresh invitation code", {});

    if (!hasInvitationCode) {
      await onCompanyChange({ invitationCode: generateId() });
      enqueueSnackbar("Invitation link generated", { variant: "success" });
      return;
    }

    await modals.openConfirmation({
      text: `You are about to generate a new invitation link, this will invalidate the previous one. Do you want to continue?`,
      confirmCTAHeader: "Regenerate invitation link",
      confirmCTATitle: "Regenerate",
      closeCTATitle: "No",
      confirmCTAColor: "error",
      onConfirm: async () => {
        await onCompanyChange({ invitationCode: generateId() });
        enqueueSnackbar("Invitation link refreshed", { variant: "success" });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInvitationCode]);

  useEffect(() => {
    if (!company.invitationCode && noregenerate) {
      // if the regenerate button is hidden via props,
      // and the company does not have an invitation code already generated
      // then we generate one here
      onCompanyChange({ invitationCode: generateId() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noregenerate, company, company?.invitationCode]);

  return (
    <Input
      fullWidth
      InputLabelProps={{ shrink: true }}
      value={
        hasInvitationCode
          ? invitationLink
          : "Press the button to generate an invite link ➡"
      }
      onClick={(e) => e.target?.select?.()}
      sx={{ cursor: "pointer" }}
      readOnly
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {!noregenerate && (
              <Tooltip
                title={
                  hasInvitationCode
                    ? "Invalidates the previous link and generates a new link."
                    : "Generate an invitation link"
                }
                arrow
              >
                <Button
                  color={hasInvitationCode ? "error" : "primary"}
                  onClick={handleRefreshInvitationLink}
                  startIcon={<SyncIcon fontSize="small" />}
                >
                  {hasInvitationCode ? "Regenerate" : "Generate"}
                </Button>
              </Tooltip>
            )}
            {hasInvitationCode && (
              <Tooltip
                title={
                  clicked
                    ? "Invitation link has been copied to the clipboard"
                    : "Copy link to clipboard"
                }
                arrow
              >
                <Button
                  onClick={handleCopyInvitationLink}
                  startIcon={clicked ? null : <CopyIcon fontSize="small" />}
                  disabled={clicked}
                >
                  {clicked ? "Copied!" : "Copy link"}
                </Button>
              </Tooltip>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

const InviteLinkBoxContainer = ({ noregenerate }) => {
  const { updateCompany, selectedCompanyId, companies } = useAppContext();

  const handleCompanyChange = useCallback(
    (changes) => {
      return updateCompany(selectedCompanyId, changes);
    },
    [updateCompany, selectedCompanyId]
  );

  return (
    <InviteLinkBox
      company={companies.find(({ id }) => id === selectedCompanyId)}
      onCompanyChange={handleCompanyChange}
      noregenerate={noregenerate}
    />
  );
};

export default InviteLinkBoxContainer;

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import noop from "lodash/noop";
import { useLocation, useNavigate } from "react-router-dom";

import useLoading from "../hooks/useLoading";
import {
  deleteReport as deleteReportApi,
  fetchPages as fetchPagesApi,
  getMyCompanies as getMyCompaniesApi,
  updateCompany as updateCompanyApi,
  createCompany as createCompanyApi,
  createConsent as createConsentApi,
  fetchCompanyMembers as fetchCompanyMembersApi,
  createCompanyMember as createCompanyMemberApi,
  updateCompanyMember as updateCompanyMemberApi,
  deleteCompanyMember as deleteCompanyMemberApi,
  listWorkspaceSourcemaps as listWorkspaceSourcemapsApi,
  confirmCompanyMembershipByInvitationCode as confirmCompanyMembershipByInvitationCodeApi,
  getSubscriptions as getSubscriptionsApi,
  createCheckout as createCheckoutApi,
  deleteMe as deleteMeApi,
  getReportMessagePreview as fetchReportMessagePreviewApi,
  notifyReporter as notifyReporterApi,
  // getWorkspaceMutedErrors as getWorkspaceMutedErrorsApi,
  muteError as muteErrorApi,
  unmuteError as unmuteErrorApi,
  createDemoReport as createDemoReportApi,
  getWorkspaceNotifyReporterTemplates as getWorkspaceNotifyReporterTemplatesApi,
  createWorkspaceNotifyReporterTemplate as createWorkspaceNotifyReporterTemplateApi,
  updateWorkspaceNotifyReporterTemplate as updateWorkspaceNotifyReporterTemplateApi,
  deleteWorkspaceNotifyReporterTemplate as deleteWorkspaceNotifyReporterTemplateApi,
  updateSubscriptionPlan as updateSubscriptionPlanApi,
  createCompanyCustomDomain as createCompanyCustomDomainApi,
  updateCompanyCustomDomainStatus as updateCompanyCustomDomainStatusApi,
  deleteCompanyCustomDomain as deleteCompanyCustomDomainApi,
  createReportComment as createReportCommentApi,
  deleteComment as deleteCommentApi,
  updateComment as updateCommentApi,
  getReportComments as getReportCommentsApi,
  getReportChangeLogs as getReportChangeLogsApi,
  getMyNotifications as getMyNotificationsApi,
  markNotificationAsRead as markNotificationAsReadApi,
} from "../api/index";
import { useSnackbar } from "notistack";
import { useAuth } from "./AuthContext";
import { updateReport as updateReportAPI } from "../api/index";
import { keyBy, uniqBy, orderBy } from "lodash";

import { useAnalytics } from "../analytics/AnalyticsContext";
import { withOptimisticUpdates } from "../hooks/withOptimisticUpdates.tsx";
import useWebsockets from "../hooks/useWebsockets";
import withOperation from "../hooks/withOperation";
import { pubsub } from "./pubsub";
import { usePreferences } from "./UserPreferencesContext";
import useLocalStorage from "../hooks/useLocalStorage";

const AppContext = createContext({
  signOut: noop,
  companies: [],
  selectedCompanyId: null,
  setSelectedCompanyId: noop,
  fetchCompany: noop,
  createCompany: noop,
  updateCompany: noop,

  fetchMyCompanies: noop,
  isCompaniesLoading: noop,

  fetchPages: noop,
  isPagesLoading: true,
  setPages: noop,

  mutedErrors: [],
  isMutedErrorsLoading: true,
  setMutedErrors: noop,
  muteError: noop,
  unmuteError: noop,

  deleteReport: noop,
  lastEvaluatedKey: null,
  updateReport: noop,

  createConsent: noop,

  companyMembers: [],
  isCompanyMembersLoading: false,
  createCompanyMember: noop,
  updateCompanyMember: noop,
  deleteCompanyMember: noop,

  workspaceSourcemaps: [],
  fetchWorkspaceSourcemaps: noop,

  scriptIsSetup: false,
  scriptIsSetupChecking: true,

  confirmCompanyMembershipByInvitationCode: noop,

  isSubscriptionsLoading: false,
  fetchSubscriptions: noop,
  subscriptions: [],

  createCheckout: noop,
  updateSubscriptionPlan: noop,

  applicationError: null,

  fetchReportMessagePreview: noop,
  reportMessagePreview: "",
  isReportMessagePreviewLoading: false,

  notifyReporter: noop,

  showLiveView: false,
  setShowLiveView: noop,

  currentWorkspace: null,

  updateScriptStatus: noop,
  createDemoReport: noop,

  createNotifyReporterTemplate: noop,
  deleteNotifyReporterTemplate: noop,
  fetchNotifyReporterTemplates: noop,
  isNotifyReporterTemplatesLoading: false,
  notifyReporterTemplates: [],

  createCompanyCustomDomain: noop,
  updateCompanyCustomDomainStatus: noop,
  deleteCompanyCustomDomain: noop,

  fetchReportComments: noop,
  createReportComment: noop,
  deleteComment: noop,
  updateComment: noop,

  isCommentsLoading: false,
  comments: [],
  setComments: noop,

  fetchReportChangeLogs: noop,
  isChangeLogsLoading: false,
  changeLogs: [],
  setChangeLogs: noop,

  isNotificationsLoading: false,
  notifications: [],
  markNotificationAsRead: noop,

  topBarBreadcrumbs: [],
});

export const AppContextProvider = ({ children }) => {
  // @TODO:
  const setReports = useCallback(() => {
    console.warn(
      "REFACTORING WARNING: THIS CALL TO SETREPORTS, IN A LIKELY ATTEMPT TO IMPLEMENT OPTIMISTIC UPDATES, HAS CURRENTLY NO EFFECT",
      new Error().stack
    );
  }, []);

  const { preferences, updatePreferences, preferencesLoading } =
    usePreferences();

  const { user, signOut } = useAuth();
  const { trackEvent } = useAnalytics();
  const location = useLocation();
  const navigate = useNavigate();

  const [pages, setPages] = useState([]);

  const [companyMembers, setCompanyMembers] = useState([]);

  const [companies, setCompanies] = useLocalStorage("bugpilot.workspaces", []);
  const [selectedCompanyId, _setSelectedCompanyId] = useLocalStorage(
    "bugpilot.workspaceId",
    null
  );

  useEffect(() => {
    const userUpdatedListener = (user) => {
      if (!user) {
        return;
      }

      setCompanyMembers((companyMembers) => {
        const index = companyMembers.findIndex(({ id }) => id === user.sub);
        if (!~index) {
          return companyMembers;
        }

        const newCompanyMembers = [...companyMembers];

        newCompanyMembers.splice(index, 1, {
          ...companyMembers[index],
          ...user,
        });

        return newCompanyMembers;
      });
    };

    pubsub.subscribe("user:updated", userUpdatedListener);

    return () => {
      pubsub.unsubscribe("user:updated", userUpdatedListener);
    };
  }, []);

  useEffect(() => {
    if (preferencesLoading) {
      return;
    }

    if (preferences.selectedCompanyId) {
      _setSelectedCompanyId(preferences.selectedCompanyId);
      return;
    }

    const ownCompanies = companies.filter(
      ({ isExternalCompany }) => !isExternalCompany
    );

    if (!ownCompanies.length) {
      return;
    }

    _setSelectedCompanyId(ownCompanies[0]?.id);
  }, [preferencesLoading, preferences.selectedCompanyId, companies]);

  const [scriptIsSetup, setScriptIsSetup] = useState(false);
  const [scriptIsSetupChecking, setScriptIsSetupChecking] = useState(true);

  const [reportMessagePreview, setReportMessagePreview] = useState("");

  const setSelectedCompanyId = useCallback(
    (companyId) => {
      _setSelectedCompanyId(companyId);
      updatePreferences({ selectedCompanyId: companyId });
    },
    [_setSelectedCompanyId, updatePreferences]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryParamsCompanyId =
      queryParams.get("workspaceId") || queryParams.get("companyId");

    if (!queryParamsCompanyId) {
      return;
    }

    setSelectedCompanyId(queryParamsCompanyId);
  }, [location.search]);

  const [subscriptions, setSubscriptions] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const withHandler = useCallback(
    (fn) =>
      async (...args) => {
        try {
          return await fn(...args);
        } catch (e) {
          if (e.name !== "ApiError" || e.silent) {
            console.error(e.message);
            return null;
          }
          enqueueSnackbar(e.message, { variant: "error" });
        }
      },
    [enqueueSnackbar]
  );

  const { isLoading: isPagesLoading, handler: fetchPages } = useLoading(
    async ({ earliestTimestamp }) => {
      try {
        const result = await fetchPagesApi(selectedCompanyId, {
          earliestTimestamp,
        });

        if (!result) {
          console.error("Reports response is empty", result);
          setPages([]);
          return;
        }

        const nextPages = result.pages || [];
        setPages(nextPages);
      } catch (e) {
        console.error("Cannot fetch pages:", e);
        setPages([]);
        enqueueSnackbar("Sorry, we had trouble loading pages", {
          variant: "error",
        });
      }
    }
  );

  const {
    isLoading: isReportMessagePreviewLoading,
    handler: fetchReportMessagePreview,
  } = useLoading(
    withHandler(async ({ messageTemplate, report }) => {
      const { preview } = await fetchReportMessagePreviewApi({
        messageTemplate,
        report,
      });
      setReportMessagePreview(preview);
    })
  );

  const { isLoading: isCompanyMembersLoading, handler: fetchCompanyMembers } =
    useLoading(async (companyId) => {
      const companyMembers = await fetchCompanyMembersApi(companyId);
      setCompanyMembers(companyMembers);
    });

  const [applicationError, setApplicationError] = useState(null);

  const { isLoading: isCompaniesLoading, handler: fetchMyCompanies } =
    useLoading(async () => {
      try {
        const companies = await getMyCompaniesApi();
        setCompanies(companies);

        return companies;
      } catch (e) {
        console.error("Bugpilot application error", e);
        setApplicationError(
          "Sorry, we could not load your workspaces due to a server error. Contact support if the problem persists"
        );
        setCompanies([]);
        return [];
      }
    }, true /* defaultLoading */);

  const { isLoading: isSubscriptionsLoading, handler: fetchSubscriptions } =
    useLoading(async () => {
      const subscriptions = await getSubscriptionsApi();
      setSubscriptions(
        orderBy(
          subscriptions,
          [({ recurringPrice }) => +recurringPrice.usd],
          ["asc"]
        ).filter(({ name }) => !name.toLowerCase().includes("legacy"))
      );
    });

  const [comments, setComments] = useState([]);

  const { isLoading: isCommentsLoading, handler: fetchReportComments } =
    useLoading(async (reportId) => {
      const { items: comments } = await getReportCommentsApi(reportId);
      setComments(comments);
    }, true);

  const [changeLogs, setChangeLogs] = useState([]);

  const { isLoading: isChangeLogsLoading, handler: fetchReportChangeLogs } =
    useLoading(async (reportId) => {
      const { items: changeLogs } = await getReportChangeLogsApi(reportId);
      setChangeLogs(changeLogs);
    }, true);

  // reset comments, changelogs when reportId changes
  useEffect(() => {
    setComments([]);
    setChangeLogs([]);
  }, [location.pathname, location.search]);

  const [notifications, setNotifications] = useState([]);
  const { isLoading: isNotificationsLoading, handler: fetchMyNotifications } =
    useLoading(async (companyId) => {
      try {
        const { items: notifications } = await getMyNotificationsApi(companyId);
        setNotifications(notifications);
        return notifications;
      } catch (e) {
        console.error("Bugpilot fetch notifications error", e);
        setNotifications([]);
        return [];
      }
    }, true);

  const createReportComment = useCallback(
    withHandler((reportId, comment) => {
      const newComment = {
        id: `comment-${crypto.randomUUID()}`,
        ...comment,
      };

      const optimisticComment = {
        ...newComment,
        authorId: user.sub,
        createdAt: Math.floor(Date.now() / 1000),
        type: "comment",
        reactions: [],
      };

      return withOptimisticUpdates(
        () => createReportCommentApi(reportId, newComment),
        comments,
        setComments
      )(optimisticComment);
    }),
    [user, comments]
  );

  const updateComment = useCallback(
    withHandler((commentId, updates) =>
      withOptimisticUpdates(
        updateCommentApi.bind(null, commentId),
        comments,
        setComments,
        {
          withMerge: true,
        }
      )({
        id: commentId,
        ...updates,
        reactions: uniqBy(
          (updates.reactions ?? []).map((reaction) => ({
            ...reaction,
            authorId: reaction.authorId ?? user.sub,
          })),
          ({ authorId, emoji }) => `${authorId}-${emoji}}`
        ),
      })
    ),
    [user, comments]
  );

  const deleteComment = useCallback(
    withHandler(async (commentId) => {
      await deleteCommentApi(commentId);
      setComments((comments) => comments.filter(({ id }) => id !== commentId));
    }),
    []
  );

  const [mutedErrors, setMutedErrors] = useState([]);

  // const { isLoading: isMutedErrorsLoading, handler: fetchMutedErrors } =
  //   useLoading(async ({ companyId }) => {
  //     const mutedErrors = await getWorkspaceMutedErrorsApi({ companyId });
  //     setMutedErrors(mutedErrors);
  //   });

  const muteError = useCallback(
    withHandler(async ({ companyId, data, type }) => {
      const mutedError = await muteErrorApi({ companyId, data, type });
      setMutedErrors((errors) => [...errors, mutedError]);
    }),
    []
  );

  const unmuteError = useCallback(
    withHandler(async ({ companyId, errorId }) => {
      await unmuteErrorApi({ companyId, errorId });
      setMutedErrors((errors) => errors.filter(({ id }) => id !== errorId));
    }),
    []
  );

  const [notifyReporterTemplates, setNotifyReporterTemplates] = useState([]);

  const {
    isLoading: isNotifyReporterTemplatesLoading,
    handler: fetchNotifyReporterTemplates,
  } = useLoading(async ({ companyId }) => {
    const notifyReporterTemplates =
      await getWorkspaceNotifyReporterTemplatesApi({ companyId });
    setNotifyReporterTemplates(notifyReporterTemplates);
  });

  const createNotifyReporterTemplate = useCallback(
    withHandler(async ({ companyId, isNew, ...template }) => {
      const newTemplate = await (isNew
        ? createWorkspaceNotifyReporterTemplateApi
        : updateWorkspaceNotifyReporterTemplateApi)({ companyId, ...template });

      setNotifyReporterTemplates((notifyReporterTemplates) => {
        const index = notifyReporterTemplates.findIndex(
          (template) => template.id === newTemplate.id
        );

        if (!~index) {
          return [...notifyReporterTemplates, newTemplate];
        }

        const newNotifyReporterTemplates = [...notifyReporterTemplates];
        newNotifyReporterTemplates[index] = newTemplate;

        return newNotifyReporterTemplates;
      });

      return newTemplate;
    }),
    []
  );

  const deleteNotifyReporterTemplate = useCallback(
    withHandler(async ({ companyId, id }) => {
      await deleteWorkspaceNotifyReporterTemplateApi({ companyId, id });
      setNotifyReporterTemplates((notifyReporterTemplates) =>
        notifyReporterTemplates.filter((template) => template.id !== id)
      );
    }),
    []
  );

  const notifyReporter = useCallback(
    withHandler(async (reportId, data) => {
      await notifyReporterApi(reportId, data);

      enqueueSnackbar("An email has been sent to the user", {
        variant: "success",
      });
    }),
    []
  );

  const confirmCompanyMembershipByInvitationCode = useCallback(
    async (code) => {
      const result = await confirmCompanyMembershipByInvitationCodeApi(code);
      trackEvent("Accept Workspace Invitation");
      return result;
    },
    [trackEvent]
  );

  const deleteReport = useCallback(
    async (reportId) => {
      await deleteReportApi(reportId);
      setReports((reports) => reports.filter(({ id }) => id !== reportId));
      pubsub.publish("report:deleted", { id: reportId });
    },
    [setReports]
  );

  const updateCompany = useCallback(
    withHandler(async (companyId, company) =>
      withOptimisticUpdates(
        async () => {
          try {
            const result = await updateCompanyApi(companyId, company);

            if (location.pathname.startsWith("/settings")) {
              enqueueSnackbar(
                "Settings saved. It might take up to 5 minutes before they become effective.",
                {
                  variant: "success",
                  autoHideDuration: 5 * 1000,
                }
              );
            }

            return result;
          } catch (e) {
            enqueueSnackbar(
              "Oops, an error occurred while saving settings. If the problem persists, one customer support agent will be in touch with you.",
              {
                variant: "error",
                autoHideDuration: 5 * 1000,
              }
            );
            return companies.find(({ id }) => id === companyId);
          }
        },
        companies,
        setCompanies,
        { withMerge: true }
      )({
        id: companyId,
        ...company,
      })
    ),
    [companies, location]
  );

  const createCompany = useCallback(
    withHandler(async (company) => {
      const { company: newCompany, companyMember: newCompanyMember } =
        await createCompanyApi(company);
      setCompanies((companies) => [newCompany, ...companies]);
      setCompanyMembers((companyMembers) => [
        newCompanyMember,
        ...companyMembers,
      ]);
      setSelectedCompanyId(newCompany.id);
    }),
    []
  );

  const createCompanyCustomDomain = useCallback(
    withHandler(async ({ companyId, type, domainName }) => {
      const updates = await createCompanyCustomDomainApi({
        companyId,
        type,
        domainName,
      });
      setCompanies((companies) =>
        companies.map((company) =>
          company.id === companyId ? { ...company, ...updates } : company
        )
      );
    }),
    []
  );

  const updateCompanyCustomDomainStatus = useCallback(
    withHandler(async ({ companyId, type }) => {
      const updates = await updateCompanyCustomDomainStatusApi({
        companyId,
        type,
      });
      setCompanies((companies) =>
        companies.map((company) =>
          company.id === companyId ? { ...company, ...updates } : company
        )
      );
    }),
    []
  );

  const deleteCompanyCustomDomain = useCallback(
    withHandler(async ({ companyId, type }) => {
      const updates = await deleteCompanyCustomDomainApi({
        companyId,
        type,
      });
      setCompanies((companies) =>
        companies.map((company) =>
          company.id === companyId ? { ...company, ...updates } : company
        )
      );
    }),
    []
  );

  const createConsent = useCallback(
    ({ type }) =>
      createConsentApi({
        ...user,
        type,
      }),
    [user]
  );

  const createCompanyMember = useCallback(
    withHandler(async (companyId, { email, role }) => {
      const companyMember = await createCompanyMemberApi(companyId, {
        email,
        role,
      });
      setCompanyMembers((companyMembers) => [companyMember, ...companyMembers]);
      enqueueSnackbar(
        "User has been invited, they will need to accept by clicking the activation link they received via email",
        {
          variant: "success",
        }
      );
    }),
    [withHandler]
  );

  const updateCompanyMember = useCallback(
    withHandler(async (companyId, { email, role }) =>
      withOptimisticUpdates(
        updateCompanyMemberApi.bind(null, companyId),
        companyMembers,
        setCompanyMembers,
        { primaryColumns: ["email"] }
      )({ email, role })
    ),
    [withHandler]
  );

  const deleteCompanyMember = useCallback(
    withHandler(async (companyId, { email, id }) => {
      await deleteCompanyMemberApi(companyId, { email, id });
      setCompanyMembers((companyMembers) =>
        companyMembers.filter(
          (companyMember) =>
            companyMember.id !== id && companyMember.email !== email
        )
      );
    }),
    []
  );

  const updateReport = useCallback(
    async (report, updates, setReport, { triggerSource } = {}) => {
      try {
        const updatedReport = {
          ...report,
          ...updates,
        };

        // update the current report
        setReport?.(updatedReport);

        pubsub.publish("report:updating", updatedReport);

        const result = await updateReportAPI(report, updates, {
          triggerSource,
        });

        pubsub.publish("report:updated", result);

        // replace report in reports list (e.g., basic optimistic update
        // of the report title in the reports page)
        // TODO: Move to a separate context
        // setReports((reports) =>
        // reports.map((report) =>
        //   report.id === updatedReport.id ? updatedReport : report
        // )
        // );

        return result;
      } catch (e) {
        console.error("Cannot update report", e);
        setReport?.(report);
        throw e;
      }
    },
    []
  );

  const getScriptSetupStatus = useCallback(async ({ companyId, cacheKey }) => {
    if (!companyId) {
      return false;
    }

    const result = await fetch(
      `https://script.bugpilot.io/${companyId}/adopto.js?status=1&_=${cacheKey}`
    );
    const json = await result.json();
    return Boolean(json.status);
  }, []);

  const createCheckout = useCallback(
    withHandler(({ companyId, planId }) =>
      createCheckoutApi({ companyId, planId })
    ),
    [withHandler]
  );

  const deleteMe = useCallback(async () => {
    try {
      await deleteMeApi();
      signOut();
    } catch (e) {
      enqueueSnackbar(
        "Sorry, account cannot be deleted. Please contact support.",
        {
          variant: "error",
        }
      );
    }
  }, [enqueueSnackbar, signOut]);

  const [workspaceSourcemaps, setWorkspaceSourcemaps] = useState([]);

  const fetchWorkspaceSourcemaps = useCallback(async (companyId) => {
    const response = await listWorkspaceSourcemapsApi(companyId);
    const sourceMaps = response.sourceMaps || [];
    // sort by uploadDate reversed
    sourceMaps.sort((a, b) => {
      if (a.uploadDate > b.uploadDate) {
        return -1;
      }
      if (a.uploadDate < b.uploadDate) {
        return 1;
      }
      return 0;
    });

    setWorkspaceSourcemaps(sourceMaps);
  }, []);

  const [showLiveView, setShowLiveView] = useState(false);

  const updateScriptStatus = useCallback(async () => {
    setScriptIsSetupChecking(true);

    const status = await getScriptSetupStatus({
      companyId: selectedCompanyId,
      cacheKey: Date.now(),
    });
    setScriptIsSetup(status);

    setTimeout(() => {
      // avoid flickering
      setScriptIsSetupChecking(false);
    }, 1000);
  }, [getScriptSetupStatus, selectedCompanyId]);

  const currentWorkspace = useMemo(() => {
    if (!selectedCompanyId) {
      return null;
    }

    return companies.find((company) => company.id === selectedCompanyId);
  }, [companies, selectedCompanyId]);

  const updateSubscriptionPlan = useCallback(
    withHandler(({ planId, planName }) =>
      withOptimisticUpdates(
        () =>
          updateSubscriptionPlanApi({
            companyId: currentWorkspace.id,
            planId,
          }),
        companies,
        setCompanies,
        {
          withMerge: true,
        }
      )({
        planId: planName,
        id: currentWorkspace.id,
        planName,
      })
    ),
    [withHandler, currentWorkspace]
  );

  const markNotificationAsRead = useCallback(
    withHandler(({ notificationId }) =>
      withOptimisticUpdates(
        () =>
          markNotificationAsReadApi({
            companyId: selectedCompanyId,
            notificationId,
          }),
        notifications,
        setNotifications,
        {
          withMerge: true,
          primaryColumns: ["userIdWorkspaceId", "type"],
          notFailOnNotFound: true,
          shouldCreateIfNotFound: false,
        }
      )({
        userIdWorkspaceId: `${user.sub}:${selectedCompanyId}`,
        type: `notification-${notificationId}`,
        readAt: Date.now(),
      })
    ),
    [withHandler, selectedCompanyId, user?.sub, notifications]
  );

  const createDemoReport = useCallback(async () => {
    const result = await createDemoReportApi({ companyId: selectedCompanyId });
    const report = result?.report;

    if (!report?.id) {
      console.error("Create demo report failed.", report);
      return;
    }

    setReports((reports) => [...reports, report]);
  }, [selectedCompanyId, setReports]);

  useEffect(() => {
    if (!user) {
      return;
    }

    fetchMyCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.sub]);

  const companyMembersById = useMemo(
    () => keyBy(companyMembers, "id"),
    [companyMembers]
  );

  useWebsockets({
    isEnabled: Boolean(user) && Boolean(selectedCompanyId),
    sessionId: btoa(
      JSON.stringify({
        ...(user && { userId: user.sub }),
        ...(selectedCompanyId && { companyId: selectedCompanyId }),
      })
    ),
    onMessage: async ({ type, data, operation }) => {
      if (type !== "notification") {
        return;
      }

      withOperation({
        operation,
        data,
        setState: setNotifications,
        primaryColumns: ["id", "type"],
      });

      const title = `[#${data.data.reportSerialNumber}] ${data.data.reportTitle}`;

      const snackbarKey = enqueueSnackbar(title, {
        persist: true,
        variant: "new-notification",
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
        data: {
          ...data.data,
          ...(data.data.type === "comment" && {
            author: companyMembersById[data.data.authorId],
          }),
          ...(data.data.type === "change-log" && {
            author: data.data.createdBy
              ? companyMembersById[data.data.createdBy.sub]
              : null,
          }),
        },
        user,
        onClick: () => {
          navigate(`/report/${data.data.reportId}`);
        },
        onNotificationClose: (e) => {
          e?.stopPropagation?.();
          markNotificationAsRead({ notificationId: data.data.id });
          closeSnackbar(snackbarKey);
        },
      });

      const permission = await Notification.requestPermission();
      if (permission !== "granted") {
        return;
      }

      const notification = new Notification(title, {
        silent: true,
        icon: "/logo512.png",
      });

      notification.addEventListener("click", () => {
        navigate(`/report/${data.data.reportId}`);
        window.open(`/report/${data.data.reportId}`, "_blank");
      });
    },
  });

  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }

    fetchCompanyMembers(selectedCompanyId);
    fetchWorkspaceSourcemaps(selectedCompanyId);
    // fetchMutedErrors({ companyId: selectedCompanyId });
    fetchMyNotifications(selectedCompanyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    updateScriptStatus();
  }, [selectedCompanyId]);

  return (
    <AppContext.Provider
      value={{
        companies,
        selectedCompanyId,
        setSelectedCompanyId,
        fetchMyCompanies,
        isCompaniesLoading,
        updateCompany,
        createCompany,

        deleteReport,
        updateReport,

        pages,
        fetchPages,
        isPagesLoading,
        setPages,

        createConsent,

        fetchCompanyMembers,
        isCompanyMembersLoading,
        createCompanyMember,
        updateCompanyMember,
        deleteCompanyMember,
        companyMembers,
        workspaceSourcemaps,
        fetchWorkspaceSourcemaps,

        scriptIsSetup,
        setScriptIsSetup,
        scriptIsSetupChecking,
        getScriptSetupStatus,

        confirmCompanyMembershipByInvitationCode,

        fetchSubscriptions,
        subscriptions,
        isSubscriptionsLoading,

        createCheckout,
        updateSubscriptionPlan,

        applicationError,

        deleteMe,

        fetchReportMessagePreview,
        reportMessagePreview,
        isReportMessagePreviewLoading,

        notifyReporter,

        showLiveView,
        setShowLiveView,

        currentWorkspace,
        updateScriptStatus,

        mutedErrors,
        fetchMutedErrors: noop,
        isMutedErrorsLoading: false,
        muteError,
        unmuteError,
        createDemoReport,

        notifyReporterTemplates,
        isNotifyReporterTemplatesLoading,
        fetchNotifyReporterTemplates,
        createNotifyReporterTemplate,
        deleteNotifyReporterTemplate,

        createCompanyCustomDomain,
        updateCompanyCustomDomainStatus,
        deleteCompanyCustomDomain,

        comments,
        setComments,
        isCommentsLoading,
        fetchReportComments,
        createReportComment,
        updateComment,
        deleteComment,

        fetchReportChangeLogs,
        isChangeLogsLoading,
        changeLogs,
        setChangeLogs,

        isNotificationsLoading,
        notifications,
        markNotificationAsRead,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

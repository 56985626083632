import differenceInDays from "date-fns/differenceInDays";
import startOfDay from "date-fns/startOfDay";

export const getDaysCount = (workspace) => {
  if (!workspace || !workspace.subscriptionFinishedAt) {
    return [0, 0];
  }

  const remainDays = differenceInDays(
    startOfDay(new Date(workspace.subscriptionFinishedAt)),
    startOfDay(new Date())
  );

  let billingPeriodStartedAt = new Date(workspace.subscriptionFinishedAt);
  billingPeriodStartedAt.setMonth(billingPeriodStartedAt.getMonth() - 1);

  const totalDays = differenceInDays(
    startOfDay(new Date(workspace.subscriptionFinishedAt)),
    startOfDay(billingPeriodStartedAt)
  );

  return [remainDays, totalDays];
};

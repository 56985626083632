import React, { useMemo } from "react";
import { Button, Stack, Typography } from "@mui/material";

import UpgradeIcon from "@mui/icons-material/UpgradeOutlined";

import { useAppContext } from "../contexts/AppContext";

const UpgradeButton = ({ text, spacing = 2 }) => {
  const { companies, selectedCompanyId } = useAppContext();
  const company = useMemo(
    () => companies.find(({ id }) => id === selectedCompanyId),
    [companies, selectedCompanyId]
  );

  return (
    <Stack spacing={spacing}>
      <Typography variant="body1">{text}</Typography>
      <div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => window.open(company.subscriptionUpdateUrl, "_blank")}
          startIcon={<UpgradeIcon />}
        >
          Upgrade plan
        </Button>
      </div>
    </Stack>
  );
};

export default UpgradeButton;

import React from "react";
import { Typography } from "@mui/material";

import MakeIcon from "../../../icons/logos/MakeIcon";
import ZapierIcon from "../../../icons/logos/ZapierIcon";
import SlackIcon from "../../../icons/logos/SlackIcon";
import JiraIcon from "../../../icons/logos/JiraIcon";
import WebhooksIcon from "../../../icons/logos/WebhooksIcon";
import NotionIcon from "../../../icons/logos/NotionIcon";
import ClickUpIcon from "../../../icons/logos/ClickUpIcon";
import VercelIcon from "../../../icons/logos/VercelIcon";
import IntercomIcon from "../../../icons/logos/IntercomIcon";
import HelpScoutIcon from "../../../icons/logos/HelpScoutIcon";
import CrispIcon from "../../../icons/logos/CrispIcon";
import ClickUpIntegration from "../ClickUpIntegration";
import AtlassianIntegration from "../AtlassianIntegration";
import SlackIntegration from "../SlackIntegration";
import NotionIntegration from "../NotionIntegration";
import EmailIntegration from "../EmailIntegration";
import PushNotificationsIntegration from "../PushNotificationsIntegration";
import {
  WebhookIntegration,
  WebhookIntegrationDescription,
} from "../WebhookIntegration";
import GitHubIntegration from "../GithubIntegration";
import GitHubIcon from "../../../icons/logos/GitHubIcon";
import LiveChatIcon from "../../../icons/logos/LiveChatIcon";
import FrontIcon from "../../../icons/logos/FrontIcon";
import WidgetConfig from "../../../components/WidgetConfig";
import ApiConfig from "../../../components/ApiConfig";
import SourceMapsConfig from "../../../components/SourceMapsConfig";

// Integration types
import { WidgetIntegration } from "../WidgetIntegration";
import { IntercomDescription } from "../IntercomDescription";
import { CrispDescription } from "../CrispDescription";
import { LiveChatDescription } from "../LiveChatDescription";
import { ZendeskDescription } from "../ZendeskDescription";
import { FrontDescription } from "../FrontDescription";
import { IntercomBotDescription } from "../IntercomBotDescription";
import { IntercomHomeDescription } from "../IntercomHomeDescription";
import { SourceMapsDescription } from "../SourceMapsDescription";
import { APIDescription } from "../APIDescription";
import MakeIntegration from "../MakeIntegration";
import ZapierIntegration from "../ZapierIntegration";

export const getAvailableIntegrations = ({ flags, onAdd }) => {
  return [
    {
      type: "email",
      name: "Email",
      order: 1,
      category: "notifications",
      descriptionComponent: EmailIntegration,
      isShowCount: false,
      isShowName: false,
    },
    {
      type: "push-notification",
      name: "Push notifications",
      category: "notifications",
      descriptionComponent: PushNotificationsIntegration,
      isShowCount: false,
      isShowName: false,
    },
    {
      type: "widget",
      name: "Bug Reporting widget",
      category: "reporting",
      isShowName: false,
      order: -20,
      // coverImage:
      //   "https://imagedelivery.net/55PXnfNaLA7qnpEnq-80ug/a30fb6c1-2903-43e7-8937-615a99ab2400/w600",
      dialogContentComponent: WidgetConfig,
      descriptionComponent: WidgetIntegration,
    },
    {
      type: "api",
      order: -9,
      name: "Server-side API",
      category: "reporting",
      // coverImage:
      //   "https://imagedelivery.net/55PXnfNaLA7qnpEnq-80ug/ee12c88a-9bd8-4622-fae4-cf4f7a92ad00/1000w",
      text: (
        <>
          You can use our server-side HTTP API to integrate Bugpilot with your
          application, start the bug reporting flow from your backend code,
          request screen recordings, and generate automated bug reports, just by
          making an HTTP request.
        </>
      ),
      descriptionComponent: APIDescription,
      dialogContentComponent: ({ company, onChange }) => (
        <ApiConfig company={company} onCompanyChange={onChange} />
      ),
    },
    {
      type: "sdk",
      order: 10,
      name: "Javascript SDK",
      // coverImage:
      //   "https://imagedelivery.net/55PXnfNaLA7qnpEnq-80ug/4390f3e2-fac0-4f7e-195e-2cae26376000/1000w",
      category: "custom",
      text: (
        <>
          You can use our Javascript SDK with any front-end framework to
          generate bug reports, submit session recordings, and integrate
          Bugpilot with your UI.
        </>
      ),
      // descriptionComponent: SDKDescription,
      documentationLink: "https://api-docs.bugpilot.io/sdk-js/getting-started",
    },
    {
      type: "sourcemaps",
      order: 99,
      name: "Source Maps",
      category: "custom",
      text: (
        <>
          Add source-maps to your Bugpilot workspace to make stack traces more
          readable and understand where the error occurred.
        </>
      ),
      descriptionComponent: SourceMapsDescription,
      dialogContentComponent: ({ company, onChange }) => (
        <SourceMapsConfig company={company} onCompanyChange={onChange} />
      ),
    },
    {
      type: "slack",
      name: "Slack",
      order: 2,
      category: "notifications",
      text: "Send a direct or channel message when a new bug report is generated.",
      icon: <SlackIcon />,
      component: SlackIntegration,
      onAdd: onAdd("slack"),
      documentationLink:
        "https://bugpilot.notion.site/Slack-81a619b4206b4ff6943f41d5b8d518bc",
    },
    {
      type: "atlassian",
      name: "Jira Software",
      category: "project-management",
      text: "Receive bug reports as tickets in your Jira Software boards.",
      icon: <JiraIcon />,
      component: AtlassianIntegration,
      onAdd: onAdd("atlassian"),
    },
    {
      type: "notion",
      name: "Notion",
      category: "project-management",
      text: "Receive bug reports as Notion database items or pages.",
      icon: <NotionIcon />,
      component: NotionIntegration,
      onAdd: onAdd("notion"),
    },
    {
      type: "clickup",
      name: "ClickUp",
      category: "project-management",
      text: "Receive bug reports in ClickUp.",
      icon: <ClickUpIcon sx={{ width: "unset" }} />,
      component: ClickUpIntegration,
      onAdd: onAdd("clickup"),
    },
    {
      type: "github",
      name: "Github Issues",
      category: "project-management",
      text: "Receive bug reports as Github Issues.",
      icon: <GitHubIcon />,
      component: GitHubIntegration,
      onAdd: onAdd("github"),
    },
    {
      type: "vercel",
      name: "Vercel",
      category: "custom",
      text: "Access server logs and deploy information right next to each error.",
      icon: <VercelIcon />,
      component: null,
      onAdd: onAdd("vercel"),
    },
    {
      type: "intercom",
      name: "Intercom",
      category: "helpdesk",
      text: "See bug reports right next to each user conversation, and easily get screen recordings and screenshots on-demand with one click, without leaving Intercom.",
      icon: <IntercomIcon />,
      onAdd: onAdd("intercom"),
      descriptionComponent: IntercomDescription,
      documentationLink:
        "https://bugpilot.notion.site/Intercom-Overview-2376e5278c2c460c9d7b10cdfea892da",
    },
    {
      type: "intercom-bot",
      name: "Intercom Custom Bot",
      // coverImage:
      //   "https://imagedelivery.net/55PXnfNaLA7qnpEnq-80ug/3a2bc20b-9b0a-41f4-0b27-eeee95384900/w600",
      category: "reporting",
      order: -19,
      isShowName: false,
      descriptionComponent: IntercomBotDescription,
    },
    {
      type: "intercom-home",
      name: "Intercom Home App",
      // coverImage:
      //   "https://imagedelivery.net/55PXnfNaLA7qnpEnq-80ug/6f07bd1c-924c-466a-ada4-0e6314e49a00/w600",
      category: "reporting",
      order: -18,
      isShowName: false,
      descriptionComponent: IntercomHomeDescription,
    },
    {
      type: "crisp",
      name: "Crisp",
      category: "helpdesk",
      text: "See bug reports right next to each user conversation, and easily get screen recordings and screenshots on-demand with one click, without leaving Crisp.",
      icon: <CrispIcon />,
      onAdd: onAdd("crisp"),
      descriptionComponent: CrispDescription,
      documentationLink:
        "https://bugpilot.notion.site/Crisp-0adc1c0a61304912862a5f590153bba5",
    },
    {
      type: "livechat",
      name: "LiveChat",
      category: "helpdesk",
      text: "See bug reports right next to each user conversation, and easily get screen recordings and screenshots on-demand with one click, without leaving LiveChat.",
      icon: <LiveChatIcon />,
      onAdd: onAdd("livechat"),
      isEnabled: false, // TODO:: isLiveChatIntegrationEnabled,
      descriptionComponent: LiveChatDescription,
      documentationLink:
        "https://bugpilot.notion.site/LiveChat-b63d054db500447faf04bf6f41641dd5",
    },
    {
      type: "helpscout",
      name: "HelpScout",
      category: "helpdesk",
      text: "See bug reports right next to each user conversation, and easily get screen recordings and screenshots on-demand with one click, without leaving HelpScout.",
      icon: <HelpScoutIcon />,
      onAdd: onAdd("helpscout"),
      isShowCount: false,
      documentationLink:
        "https://bugpilot.notion.site/HelpScout-3e9f4cf6222745d187c0899509d5e5b6",
    },
    {
      type: "zendesk",
      name: "Zendesk",
      category: "helpdesk",
      text: "See bug reports right next to each user conversation, and easily get screen recordings and screenshots on-demand with one click, without leaving Zendesk.",
      icon: (
        <img
          src="https://imagedelivery.net/55PXnfNaLA7qnpEnq-80ug/33e66429-f5c3-4abd-89bb-5e5c4ac36100/original"
          alt="zendesk support icon"
          width={24}
          height={24}
        />
      ),
      onAdd: onAdd("zendesk"),
      descriptionComponent: ZendeskDescription,
      documentationLink:
        "https://bugpilot.notion.site/Zendesk-85258e40db6640cd93e6f931bdf6495e",
    },
    {
      type: "front",
      name: "Front",
      category: "helpdesk",
      text: "See bug reports right next to each user conversation, and easily get screen recordings and screenshots on-demand with one click, without leaving Front.",
      icon: <FrontIcon />,
      onAdd: onAdd("front"),
      descriptionComponent: FrontDescription,
      documentationLink:
        "https://bugpilot.notion.site/Front-862e7217c9044877b04394e7045f1973",
    },
    {
      type: "webhook",
      order: 200,
      name: "Webhooks",
      category: "notifications",
      featured: true,
      text: "You can register one or more URLs that will receive a JSON payload with the bug report data, every time a bug report is generated. This is an easy way to integrate Bugpilot with your existing tools.",
      icon: <WebhooksIcon />,
      component: WebhookIntegration,
      onAdd: onAdd("webhook"),
      dialogContentComponent: ({ ...props }) => (
        <WebhookIntegrationDescription {...props} />
      ),
    },
    {
      type: "zapier",
      name: "Zapier",
      order: 3,
      category: "notifications",
      featured: true,
      text: (
        <Typography variant="body1">
          You can integrate with more than 1,000 software and services, with the
          Bugpilot app for Zapier. You can find the Bugpilot app in the Zapier
          Catalog.
        </Typography>
      ),
      icon: <ZapierIcon />,
      onAdd: onAdd("zapier"),
      component: ZapierIntegration,
      isDeletable: false,
    },
    {
      type: "make",
      name: "Make",
      order: 4,
      category: "notifications",
      featured: true,
      text: (
        <Typography variant="body1">
          You can integrate with more than 1,000 software and services, with the
          Bugpilot app for Make. You can find the Bugpilot app in the Make
          Catalog.
        </Typography>
      ),
      icon: <MakeIcon />,
      onAdd: onAdd("make"),
      component: MakeIntegration,
      isDeletable: false,
    },
  ];
};

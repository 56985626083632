import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useMemo, useEffect, useCallback } from "react";

import Stack from "@mui/material/Stack";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";

import SimpleDialog from "../SimpleDialog";
import { getFieldProps } from "../../utils/getFieldProps";
import Input from "../Input";
import Button from "../Button";
import AsyncButton from "../AsyncButton";
import MarkdownEditor from "../MarkdownEditor";
import { useAppContext } from "../../contexts/AppContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import DeleteIcon from "../../icons/DeleteIcon";
import { useModals } from "../../hooks/useModals";
import { Alert, LoadingButton } from "@mui/lab";
import {
  SaveOutlined as SaveIcon,
  SaveAsOutlined as SaveAsIcon,
  SendOutlined as SendIcon,
} from "@mui/icons-material";

const validationSchema = Yup.object({
  subject: Yup.string().required("Subject is required").min(5),
  text: Yup.string().required("Text is required").min(5),
  name: Yup.string().required("Template name is required").min(5),
});

const DEFAULT_VALUES = {
  name: "New template",
  subject: "We fixed the bug you reported!",
  text: "We’re excited to share that we just fixed a bug that you recently reported! 🎉",
};

const ReporterNotificationModal = ({ userEmail, onClose, onConfirm }) => {
  const {
    currentWorkspace,
    fetchNotifyReporterTemplates,
    notifyReporterTemplates,
    isNotifyReporterTemplatesLoading,
    createNotifyReporterTemplate,
    deleteNotifyReporterTemplate,
  } = useAppContext();

  useEffect(() => {
    fetchNotifyReporterTemplates({ companyId: currentWorkspace.id });
  }, [currentWorkspace?.id]);

  const [selectedTemplateId, setSelectedTemplateId] = useLocalStorage(
    `bugpilot.${currentWorkspace?.id}.notifyReporter.templateId`,
    "new"
  );

  const selectedTemplate = useMemo(
    () => notifyReporterTemplates.find(({ id }) => id === selectedTemplateId),
    [notifyReporterTemplates, selectedTemplateId]
  );

  const formik = useFormik({
    initialValues: {
      subject: selectedTemplate?.subject ?? DEFAULT_VALUES.subject,
      text: selectedTemplate?.text ?? DEFAULT_VALUES.text,
      name: selectedTemplate?.name ?? DEFAULT_VALUES.name,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await onConfirm(values);
      onClose();
    },
  });

  const textFieldProps = getFieldProps(formik, {
    name: "text",
    errorWhenTouchedOrSubmitted: false,
    disabled: isNotifyReporterTemplatesLoading,
  });

  const saveTemplate = useCallback(
    async ({ isNew }) => {
      const isValid = await formik.validateForm();

      if (!isValid) {
        return;
      }

      const newTemplate = await createNotifyReporterTemplate({
        companyId: currentWorkspace.id,
        ...formik.values,
        isNew,
        id: selectedTemplateId,
      });

      setSelectedTemplateId(newTemplate.id);
    },
    [
      createNotifyReporterTemplate,
      currentWorkspace.id,
      formik,
      selectedTemplateId,
      setSelectedTemplateId,
    ]
  );

  const handleSelectedTemplateChange = useCallback(
    (e) => setSelectedTemplateId(e.target.value),
    [setSelectedTemplateId]
  );

  const handleSaveTemplate = useCallback(
    () => saveTemplate({ isNew: selectedTemplateId === "new" }),
    [saveTemplate, selectedTemplateId]
  );

  const handleSaveAsNewTemplate = useCallback(
    () => saveTemplate({ isNew: true }),
    [saveTemplate]
  );

  const modals = useModals();

  const handleDeleteTemplate = useCallback(
    ({ name, id }) =>
      (e) => {
        e.stopPropagation();
        modals.openConfirmation({
          text: `You are about to remove template "${name}" from this workspace. Do you want to continue?`,
          confirmCTAHeader: "Remove template from Workspace",
          confirmCTATitle: "Yes, remove",
          closeCTATitle: "No",
          confirmCTAColor: "error",
          onConfirm: async () => {
            return deleteNotifyReporterTemplate({
              companyId: currentWorkspace.id,
              id,
            });
          },
        });
      },
    []
  );

  return (
    <SimpleDialog
      onClose={onClose}
      title="Notify user"
      maxWidth="md"
      actions={
        <>
          <Button onClick={onClose} disabled={formik.isSubmitting}>
            Cancel
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={formik.isSubmitting}
            onClick={formik.handleSubmit}
            startIcon={<SendIcon />}
          >
            Send now
          </LoadingButton>
        </>
      }
    >
      <Stack spacing={2}>
        <Alert severity="info">
          You can use this form to send an email to the user notifying them the
          bug is being fixed or that a fix has been released. You can customize
          the email subject and the email content using this form. An email will
          be sent to <strong>{userEmail}</strong> and you will be BCCd. The user
          will see your email address so they can reply to you.
        </Alert>

        <Input
          label="Template"
          disabled={isNotifyReporterTemplatesLoading}
          select
          value={selectedTemplateId}
          onChange={handleSelectedTemplateChange}
          renderValue={(value) => (
            <Stack direction="row" spacing={2} alignItems="center">
              {isNotifyReporterTemplatesLoading ? (
                <CircularProgress size={20} />
              ) : (
                <span>
                  {value === "new"
                    ? "Create new template"
                    : notifyReporterTemplates.find(({ id }) => id === value)
                        ?.name}
                </span>
              )}
            </Stack>
          )}
        >
          {notifyReporterTemplates.map(({ id, name, subject, text }) => (
            <MenuItem key={id} value={id}>
              <ListItemText primary={name} secondary={subject} />
              <ListItemSecondaryAction>
                <AsyncButton
                  iconButton
                  onClick={handleDeleteTemplate({ id, name })}
                >
                  <DeleteIcon fontSize="small" />
                </AsyncButton>
              </ListItemSecondaryAction>
            </MenuItem>
          ))}
          <Divider />
          <MenuItem key="new" value="new">
            <ListItemText primary="Create new template" />
          </MenuItem>
        </Input>

        <Input
          label="Email Subject"
          required
          placeholder="We fixed the bug you reported!"
          type="text"
          {...getFieldProps(formik, {
            name: "subject",
            disabled: isNotifyReporterTemplatesLoading,
          })}
        />

        <MarkdownEditor
          label="Email Content"
          {...textFieldProps}
          value={selectedTemplate?.text ?? DEFAULT_VALUES.text}
          placeholder="Type a description and add your notes here..."
          paper
        />

        {textFieldProps.helperText && (
          <FormHelperText error={textFieldProps.error}>
            {textFieldProps.helperText}
          </FormHelperText>
        )}

        {selectedTemplateId === "new" ? (
          <Input
            label="Template name"
            required
            placeholder="Template name"
            type="text"
            {...getFieldProps(formik, {
              name: "name",
              disabled: isNotifyReporterTemplatesLoading,
            })}
          />
        ) : (
          <input
            type="hidden"
            {...getFieldProps(formik, {
              name: "name",
              disabled: isNotifyReporterTemplatesLoading,
            })}
          />
        )}

        {(selectedTemplateId === "new" || formik.dirty) && (
          <Stack direction="row" spacing={2}>
            <AsyncButton
              variant="outlined"
              onClick={handleSaveTemplate}
              startIcon={<SaveIcon />}
              color="tertiary"
            >
              Update template
            </AsyncButton>

            {selectedTemplateId !== "new" && (
              <AsyncButton
                onClick={handleSaveAsNewTemplate}
                variant="outlined"
                startIcon={<SaveAsIcon />}
                color="tertiary"
              >
                Save as new template
              </AsyncButton>
            )}
          </Stack>
        )}
      </Stack>
    </SimpleDialog>
  );
};

export default ReporterNotificationModal;

import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { useAppContext } from "../contexts/AppContext";
import { useAuth } from "../contexts/AuthContext";
import { Alert, AlertTitle, CircularProgress } from "@mui/material";
import { useAnalytics } from "../analytics/AnalyticsContext";
import { Container, SelectWorkspaceHeader } from "./RequireSubscription";
import Loader from "../components/Loader";

// Paddle billing settings
// (See plans here: https://vendors.paddle.com/subscriptions/plans)
//
// 840141: Bugpilot Standard Seat (Monthly); 14 Days trial; bill every 1 month
// 840142: Bugpilot Standard Seat (Yearly); 14 Days trial; bill every 1 year
//
// Both plans have quantity enabled, so we can set the number of seats
// from the members page

const PADDLE_VENDOR_ID = 148620;
export const PADDLE_PRODUCT_ID_SEAT_MONTHLY = 840141;
export const PADDLE_PRODUCT_ID_SEAT_YEARLY = 840142;

export const SubscriptionStart = () => {
  const { user } = useAuth();
  const {
    isSubscriptionsLoading,
    subscriptions,
    fetchSubscriptions,
    isCompaniesLoading,
    selectedCompanyId,
    companies,
    companyMembers,
  } = useAppContext();

  const { trackEvent } = useAnalytics();
  const location = useLocation();
  const navigate = useNavigate();

  const checkoutContainer = useRef(null);
  const [success, setSuccess] = useState(false);

  const [selectedPlanId, setSelectedPlanId] = useState(
    PADDLE_PRODUCT_ID_SEAT_MONTHLY
  );

  const toggleSelectedPlan = useCallback(() => {
    setSelectedPlanId(
      selectedPlanId === PADDLE_PRODUCT_ID_SEAT_MONTHLY
        ? PADDLE_PRODUCT_ID_SEAT_YEARLY
        : PADDLE_PRODUCT_ID_SEAT_MONTHLY
    );
  }, [selectedPlanId]);

  const selectedSubscription = useMemo(
    () =>
      subscriptions.find((subscription) => subscription.id === selectedPlanId),
    [selectedPlanId, subscriptions]
  );

  useEffect(() => {
    if (!user || isCompaniesLoading) {
      return;
    }

    fetchSubscriptions();

    window.Paddle.Setup({
      vendor: PADDLE_VENDOR_ID,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isCompaniesLoading]);

  useLayoutEffect(() => {
    trackEvent("Signup Checkout Loaded", {});
  }, [trackEvent]);

  useEffect(() => {
    if (checkoutContainer.current === null) {
      return;
    }

    if (checkoutContainer.current.innerHTML !== "") {
      checkoutContainer.current.innerHTML = "";
    }

    if (!companyMembers.length) {
      return;
    }

    window.Paddle.Checkout.open({
      // https://developer.paddle.com/reference/5e0171ec215eb-checkout-parameters
      product: selectedPlanId,
      quantity: companyMembers.length,
      email: user.email,
      method: "inline",
      frameTarget: "checkout-container",
      frameStyle: "position: inherit; min-height: 400px; width: 100%",
      frameInitialHeight: 400,
      customData: {
        userId: user.sub,
        companyId: selectedCompanyId,
      },
      successCallback: (data) => {
        window.Paddle.Order.details(data.checkout.id, (details) => {
          setSuccess(true);
        });
      },
      closeCallback: (data) => console.log("Paddle close callback => ", data),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutContainer.current, user, selectedCompanyId, selectedPlanId]);

  useEffect(() => {
    if (!success) {
      return;
    }

    navigate("/subscription/success" + location.search);
  }, [success, navigate, location.search]);

  if (isSubscriptionsLoading || subscriptions.length === 0) {
    return (
      <Container
        LeftComponentProps={{
          selectedSubscription,
          selectedPlanId,
          toggleSelectedPlan,
        }}
      >
        <Stack alignItems="center">
          <Loader title="Loading subscriptions" />
        </Stack>
      </Container>
    );
  }

  if (!selectedSubscription) {
    return (
      <Container>
        <SelectWorkspaceHeader companies={companies} />

        <Alert severity="error">
          <AlertTitle>Something is wrong</AlertTitle>
          We could not load the subscription details.
        </Alert>
      </Container>
    );
  }
  return (
    <Container
      LeftComponentProps={{
        selectedSubscription,
        selectedPlanId,
        toggleSelectedPlan,
      }}
    >
      {success ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          <SelectWorkspaceHeader companies={companies} />

          <Box
            ref={checkoutContainer}
            className="checkout-container"
            style={{
              width: "100%",
              maxHeight: 600,
              overflow: "auto",
              marginTop: 20,
            }}
          />
        </>
      )}
    </Container>
  );
};

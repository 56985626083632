import React, { useCallback, useMemo, useRef, useState } from "react";
import { Typography, Box, Paper, Stack } from "@mui/material";
import { orderBy } from "lodash";

import DateTime from "../../DateTime";
import CommentText from "./CommentText";
import MemberAvatar from "../../MemberAvatar.tsx";
import { ShowReactions } from "./ShowReactions.tsx";
import { AddReactions } from "./AddReactions.tsx";
import { CommentProps, EditorRef } from "./types.tsx";
import { OwnerActionsMenu } from "./OwnerActionsMenu.tsx";
import { CommentInputComponent } from "./index.tsx";
import { usePreferences } from "../../../contexts/UserPreferencesContext.js";

export const CommentComponent = ({
  comment,
  user,
  onDelete,
  onUpdate,
  onReply,
  companyMembers,
  withUser,
}: CommentProps) => {
  const { preferences, updatePreferences } = usePreferences();

  const emojiHotkeys = useMemo(
    () =>
      [
        // @ts-ignore
        ...new Set([
          ...orderBy(
            Object.entries(preferences?.emojisByCount ?? {}),
            ["1"],
            ["desc"]
          ).map(([emoji]) => emoji),
          ...["👍", "👎", "❤️", "✅", "👀"],
        ]),
      ].slice(0, 5),
    [preferences?.emojisByCount]
  );

  const ref = useRef<EditorRef>();
  const [isEditing, setIsEditing] = useState(false);

  const [mouseOver, setMouseOver] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setMouseOver(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setMouseOver(false);
  }, []);

  const handleEdit = useCallback(() => {
    setIsEditing(true);

    const interval = setInterval(() => {
      if (!ref.current) {
        return;
      }

      clearInterval(interval);
      ref.current.focus();
    }, 50);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const handleUpdate = useCallback(
    async ({ contentState, markdown }) => {
      setIsEditing(false);
      await onUpdate(comment.id, { contentState, markdown });
    },
    [comment.id, onUpdate]
  );

  const handleDelete = useCallback(
    () => onDelete(comment.id),
    [onDelete, comment.id]
  );

  const handleReactionUpdate = useCallback(
    (updates) => {
      const { emoji, operation } = updates.reaction;
      const prevCount = preferences.emojisByCount?.[emoji] ?? 0;
      const emojisByCount = {
        ...preferences.emojisByCount,
        [emoji]: Math.max(prevCount + (operation === "add" ? 1 : -1), 0),
      };

      return Promise.all([
        onUpdate(comment.id, updates),
        updatePreferences({ emojisByCount }),
      ]);
    },
    [comment.id, onUpdate, preferences?.emojisByCount, updatePreferences]
  );

  const commentComponent = useMemo(() => {
    return (
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        {isEditing ? (
          <CommentInputComponent
            rawContentState={comment.contentState}
            onChange={handleUpdate}
            onCancel={handleCancelEdit}
            onReply={() => {}}
            ref={ref}
            companyMembers={companyMembers}
            reportId={comment.reportId}
          />
        ) : (
          <CommentText>{comment.markdown}</CommentText>
        )}
      </Box>
    );
  }, [
    comment.contentState,
    comment.markdown,
    companyMembers,
    comment.reportId,
    handleUpdate,
    handleCancelEdit,
    isEditing,
  ]);

  const authorLabel = useMemo(() => {
    if (comment.author?.id === user.sub) {
      return "You";
    }

    return <>{comment.author?.name || comment.author?.email}</>;
  }, [comment.author, user.sub]);

  const actionsComponent = useMemo(() => {
    if (isEditing || comment.isCreating) {
      return null;
    }

    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          opacity: mouseOver ? 1 : 0,
          transition: "opacity 0.2s ease-in-out",
        }}
      >
        <Paper>
          <Stack spacing={1} direction="row" alignItems="center">
            <AddReactions
              reactions={comment.reactions}
              user={user}
              onUpdate={handleReactionUpdate}
              emojiHotkeys={emojiHotkeys}
            />

            {user.sub === comment.authorId && (
              <OwnerActionsMenu
                onDelete={handleDelete}
                onEdit={handleEdit}
                onReply={onReply}
                mouseOver={mouseOver}
              />
            )}
          </Stack>
        </Paper>
      </Box>
    );
  }, [
    comment.authorId,
    comment.isCreating,
    comment.reactions,
    handleDelete,
    handleEdit,
    handleReactionUpdate,
    isEditing,
    mouseOver,
    onReply,
    user,
  ]);

  if (!withUser) {
    return (
      <Box
        sx={{ position: "relative" }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Stack spacing={0} flex={1}>
          <Box pl={3.5}>{commentComponent}</Box>

          {comment.reactions && comment.reactions.length > 0 && (
            <Box pl={3.5}>
              <ShowReactions
                reactions={comment.reactions}
                user={user}
                onUpdate={handleReactionUpdate}
              />
            </Box>
          )}
        </Stack>

        {actionsComponent}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "relative",
        pt: 1,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Stack spacing={0.5} flex={1}>
        <Stack spacing={1} direction="row" alignItems="center">
          <MemberAvatar
            member={comment.author}
            size={18}
            typographyProps={{
              sx: {
                fontSize: 10,
              },
            }}
          />

          <Typography
            variant="body2"
            fontWeight={500}
            flexGrow={1}
            sx={{
              fontSize: "0.8rem",
            }}
          >
            {authorLabel}
          </Typography>

          <Typography variant="body2" fontSize={12}>
            {comment.updatedAt ? (
              <Typography
                ml={1}
                sx={{
                  fontStyle: "italic",
                  color: "muted.dark",
                  opacity: 0.7,
                }}
              >
                (edited)
              </Typography>
            ) : (
              ""
            )}{" "}
            <DateTime
              typographyProps={{
                sx: { color: "muted.main" },
              }}
              onlyTime
              absolute
            >
              {comment.createdAt}
            </DateTime>
          </Typography>
        </Stack>

        <Box pl={3.5}>{commentComponent}</Box>

        {comment.reactions && comment.reactions.length > 0 && (
          <Box pl={3.5}>
            <ShowReactions
              reactions={comment.reactions}
              user={user}
              onUpdate={handleReactionUpdate}
            />
          </Box>
        )}
      </Stack>

      {actionsComponent}
    </Box>
  );
};

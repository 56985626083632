import * as Yup from "yup";
import React, { useCallback } from "react";
import { useFormik } from "formik";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";

import { getFieldProps } from "../../utils/getFieldProps";
import CopyIcon from "../../icons/CopyIcon";
import CodeContainer from "../../components/CodeContainer.tsx";

const webhookValidationSchema = Yup.object({
  webhookUrl: Yup.string().url(),
});

const WebhookIntegration = ({
  isDisabled,
  onUpdateIntegration,
  integration,
}) => {
  const formik = useFormik({
    initialValues: {
      webhookUrl: integration.hookUrl,
      secret: integration.secret,
    },
    enableReinitialize: true,
    validationSchema: webhookValidationSchema,
    onSubmit: async (values) => {
      await onUpdateIntegration(values);
    },
  });

  const handleCopySecret = useCallback(() => {
    navigator.clipboard.writeText(formik.values.secret);
  }, [formik]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Webhook URL"
          placeholder="Webhook URL"
          InputLabelProps={{ shrink: true }}
          {...getFieldProps(formik, {
            name: "webhookUrl",
            disabled: isDisabled,
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Secret"
          InputLabelProps={{ shrink: true }}
          {...getFieldProps(formik, {
            name: "secret",
            disabled: true,
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleCopySecret}>
                  <CopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {formik.dirty && (
        <Grid item>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={formik.handleSubmit}
              endIcon={formik.isSubmitting && <CircularProgress size={25} />}
              disabled={isDisabled || formik.isSubmitting}
            >
              Save
            </Button>
            <Button
              bold
              color="primary"
              disabled={formik.isSubmitting}
              onClick={() => formik.resetForm()}
            >
              Cancel
            </Button>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

const WebhookIntegrationDescription = () => (
  <>
    <Typography variant="body1">
      {`We will call your endpoint using POST method. Use this secret to generate SHA512 hash based on the request raw body
          and compare it to the signature in the "Bugpilot-Signature" header to
          make sure no one faked the request. Example for Node.js:`}
    </Typography>
    <Typography variant="body1">
      <CodeContainer
        language="javascript"
        code={`const crypto = require("crypto");
const signature = crypto
  .createHmac("sha512", secret)
  .update(body)
  .digest("hex");`}
      />
    </Typography>
  </>
);

export { WebhookIntegration, WebhookIntegrationDescription };

import { useCallback } from "react";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";

import useHasRole from "../../hooks/useHasRole";
import { useAuth } from "../../contexts/AuthContext";
import { useAppContext } from "../../contexts/AppContext";

import SecurityAndPrivacy from "./PrivacySection";
import Compliance from "./ComplianceSection";
import OnlyOwnerAlert from "../../components/OnlyOwnerAlert";

const SecurityPrivacyPage = () => {
  const { updateCompany, selectedCompanyId, currentWorkspace } =
    useAppContext();
  const { user } = useAuth();

  const handleChange = useCallback(
    (changes) => updateCompany(selectedCompanyId, changes),
    [updateCompany, selectedCompanyId]
  );
  const { hasRole: isAdminOrOwner } = useHasRole("admin");
  const isDisabled = !isAdminOrOwner;

  return (
    <Stack mt={3} spacing={3}>
      <OnlyOwnerAlert />

      <Compliance
        company={currentWorkspace}
        user={user}
        isDisabled={isDisabled}
      />

      <Box mt={3} />

      <SecurityAndPrivacy
        company={currentWorkspace}
        onChange={handleChange}
        isDisabled={isDisabled}
      />

      <Box mt={3} />
    </Stack>
  );
};

export default SecurityPrivacyPage;

import { UserDescriptionBox } from "./UserDescriptionBox";
import React from "react";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { SelectBugStatus } from "./SelectBugStatus";
import { ExplainPreviewKeyboardShortcuts } from "./ExplainPreviewKeyboardShortcuts";
import { AssigneeSelect } from "./AssigneeSelect.tsx";
import { ActionsMenu } from "./ReportTitleActionsMenu";
import { AutopilotSummary } from "./AutopilotGroup/AutopilotSummary.tsx";

import { RelatedReports } from "./RelatedReports";

import { Link } from "react-router-dom";

export function ReportSidebar({
  variant,
  report,
  reportContents,
  setReport,
  handleTriage,
  stackProps = {},
  switchTab,
  readOnly,
}) {
  return (
    <Stack
      direction="column"
      spacing={2}
      justifyContent="stretch"
      {...stackProps}
      sx={{
        ...(stackProps.sx || {}),
        overflowY: "auto",
        height: "calc(100vh - 90px)",
      }}
    >
      {variant === "full" && !readOnly && (
        <Stack spacing={2} alignItems="center" sx={{ pb: 1 }}>
          <Box sx={{ width: "100%" }}>
            <SelectBugStatus
              key={report.id}
              report={report}
              setReport={setReport}
              onTriage={handleTriage}
              ButtonProps={{
                border: 0,
              }}
              addons={[]}
            />
          </Box>

          <Box flexGrow={1} sx={{ width: "100%" }}>
            <AssigneeSelect
              report={report}
              setReport={setReport}
              addons={[
                <ActionsMenu
                  key="actions-menu"
                  report={report}
                  setReport={setReport}
                  ButtonProps={{}}
                  handleNextReport={() => {}}
                />,
              ]}
            />
          </Box>
        </Stack>
      )}

      {readOnly ? (
        <Box mb={2}>
          <Alert
            severity="info"
            variant="filled"
            icon={false}
            sx={{
              "& .MuiAlert-message": {
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                flexDirection: "column",
                gap: 1,
              },
            }}
          >
            <Typography variant="body2">
              This Report belongs to an Issue and is read-only.
            </Typography>

            <Box flexGrow={1} />

            <Link
              to={`/report/${report.metadata?.errorInfo?.originalReportId}`}
              style={{
                textDecoration: "underline",
                color: "inherit",
                fontWeight: "bold",
              }}
            >
              <Button variant="outlined" color="white" size="small">
                go to the Issue
              </Button>
            </Link>
          </Alert>
        </Box>
      ) : null}

      {/*
      {variant === "full" &&
        report.id?.startsWith?.("card") &&
        report.createdBy && (
          <Stack gap={1} direction="row">
            Created by
            <AssigneeBadge
              report={{
                ...report,
                assigneeSub: report.createdBy.sub,
              }}
              tooltip={false}
              size={24}
              withName
            />
          </Stack>
        )} */}

      {!report.id || !report.id.startsWith("card-") ? (
        <>
          {/* {report.audio && (
            <Paper>
              <Box p={2}>
                <audio controls style={{ width: "100%" }}>
                  <source src={report.audio} type="audio/webm" />
                  <a href={report.audio}>Download</a>
                </audio>
              </Box>
            </Paper>
          )} */}

          <Stack spacing={2}>
            <UserDescriptionBox report={report} />

            <AutopilotSummary
              report={report}
              switchTab={switchTab}
              reportContents={reportContents}
            />
          </Stack>

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              flexGrow: 1,
            }}
          >
            <RelatedReports report={report} variant={variant} />
          </Box>
        </>
      ) : (
        <Box sx={{ flexGrow: 1 }} />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
        gap={1}
      >
        <Typography variant="caption" color="muted.dark" mb={1}>
          Report expires on{" "}
          {new Date(report.deleteAt * 1000).toLocaleDateString()}
        </Typography>

        <ExplainPreviewKeyboardShortcuts />
      </Box>
    </Stack>
  );
}

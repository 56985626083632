import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import { useAppContext } from "../contexts/AppContext";

const ScriptNotDetectedBanner = () => {
  const { scriptIsSetup } = useAppContext();
  const location = useLocation();

  const showScriptAlert = useMemo(() => {
    return (
      !scriptIsSetup &&
      !location.pathname.startsWith("/getting-started") &&
      !location.pathname.startsWith("/settings") &&
      !location.pathname.startsWith("/connect")
    );
  }, [location.pathname, scriptIsSetup]);

  if (!showScriptAlert) {
    return null;
  }

  return null;

  // @FIXME temporarily disabled because of false
  // negatives on the script detection

  // return (
  //   <Box mt={-1} mb={2}>
  //     <Alert severity="warning">
  //       <AlertTitle>Setup not completed</AlertTitle>

  //       <Typography variant="body2">
  //         We could not detect the tracking code on your app. Bugpilot will start
  //         working as soon as you add the tracking code to your web app. It takes
  //         less than 5 minutes. Check out the{" "}
  //         <Link to="/getting-started/code" style={{ color: "inherit" }}>
  //           Setup Page
  //         </Link>{" "}
  //         to get started.
  //       </Typography>
  //     </Alert>
  //   </Box>
  // );
};

export const RequireWorkspace = ({ children, excludeRoutes = [] }) => {
  const location = useLocation();

  const { companies, selectedCompanyId, currentWorkspace } = useAppContext();

  const hasAccessToSelectedWorkspace = useMemo(
    () => companies.some((c) => c.id === selectedCompanyId),
    [companies, selectedCompanyId]
  );

  const accessibleWithoutWorkspace = useMemo(() => {
    return (
      excludeRoutes.includes(location.pathname) ||
      excludeRoutes.some((route) => location.pathname.startsWith(route))
    );
  }, [excludeRoutes, location.pathname]);

  useEffect(() => {
    if (!currentWorkspace) {
      return;
    }

    window.Bugpilot?.identify?.({
      workspaceId: currentWorkspace?.id,
      workspaceName: currentWorkspace?.name,
      workspaceBilling: currentWorkspace?.appSumoId ? "appsumo" : "paddle",
    });
  }, [currentWorkspace]);

  if (accessibleWithoutWorkspace) {
    return children;
  }

  if (!hasAccessToSelectedWorkspace) {
    return <Loader title="Loading workspace..." subtitle={selectedCompanyId} />;
  }

  return (
    <>
      <ScriptNotDetectedBanner />
      {children}
    </>
  );
};

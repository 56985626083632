import React from "react";
import { Typography } from "@mui/material";
import { useIntegrations } from "../../contexts/IntegrationsContext";

// TODO: move to a separate file
//
export const IntercomDescription = () => {
  const { intercomAppId } = useIntegrations();

  if (!intercomAppId) {
    return null;
  }

  return (
    <Typography variant="caption" component="p">
      workspace <code>{intercomAppId}</code>
    </Typography>
  );
};

import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Stack from "@mui/material/Stack";

import capitalize from "lodash/capitalize";

const TableCellTitle = ({ children }) => (
  <TableCell sx={{ width: 100, padding: 1, border: 0 }}>
    <Typography variant="body1" fontWeight="bold">
      {children}
    </Typography>
  </TableCell>
);

const TableCellContent = ({ children }) => (
  <TableCell sx={{ padding: 0, border: 0 }}>
    <Typography variant="body1">{children}</Typography>
  </TableCell>
);

const SystemBrowserInfo = ({ report }) => {
  return (
    <Stack spacing={2}>
      <TableContainer data-test-id="browser-info-container">
        <Table>
          <TableBody>
            <TableRow>
              <TableCellTitle>Browser</TableCellTitle>
              <TableCellContent data-test-id="browser-info-name">
                {report.userAgent?.browser?.name}{" "}
                {report.userAgent?.browser?.version}
                {" ("}
                {report.userAgent?.engine?.name}{" "}
                {report.userAgent?.engine?.version}
                {")"}
              </TableCellContent>
            </TableRow>

            <TableRow>
              <TableCellTitle>OS</TableCellTitle>
              <TableCellContent data-test-id="system-platform">
                {report.userAgent?.os?.name} {report.userAgent?.os?.version}
                {" ("}
                {capitalize(report.userAgent?.device?.type || "desktop")}
                {")"}
              </TableCellContent>
            </TableRow>

            <TableRow>
              <TableCellTitle>Screen size</TableCellTitle>
              <TableCellContent data-test-id="browser-info-screen-size">
                {report.screen?.width || "?"}x{report.screen?.height || "?"}
                {" ("}
                {report.window?.width || "?"}x{report.window?.height || "?"}
                {")"}
              </TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellTitle>Timezone</TableCellTitle>
              <TableCellContent data-test-id="system-device-timezone">
                {report.timeZone}
              </TableCellContent>
            </TableRow>

            <TableRow>
              <TableCellTitle>Language</TableCellTitle>
              <TableCellContent data-test-id="system-device-language">
                {report.language}
              </TableCellContent>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default SystemBrowserInfo;

import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ms from "ms";
import { debounce } from "lodash";
import {
  Button,
  Chip,
  CircularProgress,
  Stack,
  TextField,
  InputAdornment,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  AlertTitle,
  Box,
} from "@mui/material";

import { useAppContext } from "../../contexts/AppContext";
import useHasRole from "../../hooks/useHasRole";
import TimeZoneSelect from "../../components/shared/TimeZoneSelect";
import { SettingsSection } from "./SettingsSection.tsx";
import { NewLabel } from "../../components/NewLabel";
import CustomDomains from "./CustomDomains";
import { useModals } from "../../hooks/useModals";
import OnlyOwnerAlert from "../../components/OnlyOwnerAlert";

const domainNameRegExp = /^([a-zA-Z0-9-]{2,63}\.)+[a-zA-Z0-9-]{2,}$/;

const customDomainNamesValidationSchema = Yup.object({
  apiCustomDomainName: Yup.string().matches(domainNameRegExp, "Invalid domain"),
  scriptCustomDomainName: Yup.string().matches(
    domainNameRegExp,
    "Invalid domain"
  ),
});

const GeneralSettingsPage = ({
  company,
  onChange,
  isDisabled,
  onCreateCompanyCustomDomain,
  onRemoveCompanyCustomDomain,
  onUpdateCompanyCustomDomainStatus,
}) => {
  const debouncedSave = useCallback(
    debounce((changes) => onChange(changes), 3 * 1000),
    [onChange]
  );

  const [companyState, setCompanyState] = useState(company);

  useEffect(() => {
    setCompanyState(company);
  }, [company]);

  const handleChange = useCallback(
    (name) => (e) => {
      const value = e.target.value;

      setCompanyState((prev) => ({
        ...prev,
        [name]: value,
      }));

      debouncedSave({ [name]: value });
    },
    [debouncedSave]
  );

  const customDomainNamesFormik = useFormik({
    initialValues: {
      apiCustomDomainName: company.apiCustomDomainName || "",
      scriptCustomDomainName: company.scriptCustomDomainName || "",
    },
    validationSchema: customDomainNamesValidationSchema,
    enableReinitialize: true,
    onSubmit: async ({ apiCustomDomainName, scriptCustomDomainName }) => {
      if (
        apiCustomDomainName &&
        apiCustomDomainName !== company.apiCustomDomainName
      ) {
        await onCreateCompanyCustomDomain({
          type: "api",
          domainName: apiCustomDomainName,
          companyId: company.id,
        });
      }

      if (
        scriptCustomDomainName &&
        scriptCustomDomainName !== company.scriptCustomDomainName
      ) {
        await onCreateCompanyCustomDomain({
          type: "script",
          domainName: scriptCustomDomainName,
          companyId: company.id,
        });
      }
    },
  });

  const handleAutoResolveChange = useCallback(
    (options) => {
      return handleChange("reportAutoresolveDelay")(options);
    },
    [handleChange]
  );

  return (
    <Stack spacing={3} mt={3}>
      <OnlyOwnerAlert />

      <SettingsSection
        title="Workspace Name"
        description="You can specify a name for your workspace here."
        leftButtons={
          <>
            <span>
              Workspace ID:{" "}
              <span
                style={{
                  fontFamily: "monospace",
                  cursor: "pointer",
                }}
                onClick={() => navigator.clipboard.writeText(company?.id)}
              >
                {company?.id}
              </span>
            </span>
          </>
        }
      >
        <TextField
          fullWidth
          placeholder="My Workspace"
          value={companyState.name || ""}
          onChange={handleChange("name")}
        />
      </SettingsSection>

      <SettingsSection
        title="Workspace Time Zone"
        description="Choose a time zone that will be used for all dates and times in your workspace. This currently applies to all team members regardless of their location."
      >
        <TimeZoneSelect
          required={false}
          disabled={isDisabled}
          value={company?.timeZone ?? "UTC"}
          onChange={(e) => onChange({ timeZone: e.target.value })}
        />
      </SettingsSection>
      {companyState.reportAutoresolveDelay && (
        <SettingsSection
          id="autoresolve"
          title="Auto-resolve stale issues"
          description="To help you keep a clean to-do list, and to avoid stale issues being around for weeks, we recommend setting an auto-resolve timer. Issue will be auto-resolved if there is no new occurrence."
        >
          <TextField
            placeholder="Auto-resolve delay in days"
            value={ms(companyState.reportAutoresolveDelay) / ms("1 day")}
            onChange={(e) => {
              const nextValue = Math.min(
                60,
                Math.max(5, Number(e.target.value) || 5)
              );

              handleAutoResolveChange({
                target: { value: `${nextValue} days` },
              });
            }}
            onClick={(e) => e.target.select()}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">days</InputAdornment>
              ),
              inputProps: {
                min: 1,
              },
            }}
          />
        </SettingsSection>
      )}

      <SettingsSection
        title={
          <>
            Custom Domains
            <NewLabel />
            <Chip label="Enterprise only" size="small" sx={{ ml: 1 }} />
          </>
        }
        id="custom-domains"
        description="You can specify a custom domains for your workspace here."
        buttons={[
          ...(customDomainNamesFormik.dirty
            ? [
                <Button
                  key="cancel"
                  onClick={() => customDomainNamesFormik.resetForm()}
                >
                  Cancel
                </Button>,
              ]
            : []),
          !company.apiCustomDomainName || !company.scriptCustomDomainName
            ? [
                <Button
                  key="save"
                  onClick={customDomainNamesFormik.handleSubmit}
                  disabled={
                    !customDomainNamesFormik.dirty ||
                    customDomainNamesFormik.isSubmitting ||
                    isDisabled
                  }
                  variant="contained"
                  endIcon={
                    customDomainNamesFormik.isSubmitting && (
                      <CircularProgress size={15} />
                    )
                  }
                >
                  Save
                </Button>,
              ]
            : [],
        ]}
      >
        <CustomDomains
          onDelete={onRemoveCompanyCustomDomain}
          onUpdateStatus={onUpdateCompanyCustomDomainStatus}
          company={company}
          formik={customDomainNamesFormik}
          isDisabled={isDisabled}
        />
      </SettingsSection>

      <SettingsSection
        title="Proactive Support"
        description="If enabled, Bugpilot saves a sessions when users click on an help desk widget. This is typically a signal that the user is confused and is seeking for help. You must have this option enabled if you are using one of our integrations with a help desk tool, like Intercom or Zendesk."
      >
        {company?.helpdeskRecordingMode === "manual" && (
          <Typography variant="body2" color="text.secondary" gutterBottom>
            This setting must be enabled if you are using one of our
            integrations with a help desk tool, like Intercom, Crisp or Zendesk.
          </Typography>
        )}

        {company?.helpdeskRecordingMode === "auto" && (
          // Deprecated
          <Alert severity="warning">
            <AlertTitle>Deprecated</AlertTitle>
            This setting is deprecated as we are phasing out support for help
            desk integrations. Please contact us if you are using this feature.
          </Alert>
        )}

        <Box mt={2}>
          <RadioGroup
            row
            value={company?.helpdeskRecordingMode}
            onChange={(e) =>
              onChange({ helpdeskRecordingMode: e.target.value })
            }
          >
            <FormControlLabel
              value="auto"
              control={
                <Radio
                  checked={
                    company?.helpdeskRecordingMode === "auto" ||
                    !company?.helpdeskRecordingMode
                  }
                />
              }
              label="Yes (recommended)"
            />
            <FormControlLabel
              value="manual"
              control={
                <Radio checked={company?.helpdeskRecordingMode === "manual"} />
              }
              label="No"
            />
          </RadioGroup>
        </Box>
      </SettingsSection>
    </Stack>
  );
};

const GeneralSettingsPageContainer = () => {
  const {
    updateCompany,
    selectedCompanyId,
    companies,
    createCompanyCustomDomain,
    updateCompanyCustomDomainStatus,
    deleteCompanyCustomDomain,
  } = useAppContext();

  const handleChange = useCallback(
    (changes) => updateCompany(selectedCompanyId, changes),
    [updateCompany, selectedCompanyId]
  );
  const { hasRole: isAdminOrOwner } = useHasRole("admin");

  const handleUpdateCompanyCustomDomainStatus = useCallback(
    ({ type }) =>
      updateCompanyCustomDomainStatus({
        type,
        companyId: selectedCompanyId,
      }),
    [selectedCompanyId, updateCompanyCustomDomainStatus]
  );

  const modals = useModals();

  const handleRemoveCompanyCustomDomain = useCallback(
    ({ type }) =>
      modals.openConfirmation({
        text: `You are about to delete ${type} custom domain name. Do you want to continue?`,
        confirmCTAHeader: `Delete ${type} custom domain name`,
        confirmCTATitle: "Delete",
        closeCTATitle: "Cancel",
        confirmCTAColor: "error",
        onConfirm: () =>
          deleteCompanyCustomDomain({
            type,
            companyId: selectedCompanyId,
          }),
      }),
    [deleteCompanyCustomDomain, modals, selectedCompanyId]
  );

  return (
    <GeneralSettingsPage
      company={companies.find(({ id }) => id === selectedCompanyId)}
      onChange={handleChange}
      isDisabled={!isAdminOrOwner}
      onCreateCompanyCustomDomain={createCompanyCustomDomain}
      onUpdateCompanyCustomDomainStatus={handleUpdateCompanyCustomDomainStatus}
      onRemoveCompanyCustomDomain={handleRemoveCompanyCustomDomain}
    />
  );
};

export default GeneralSettingsPageContainer;

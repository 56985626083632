import { useCallback, useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";

export const BuildOutdatedAlert = () => {
  const [isBuildOutdated, setIsBuildOutdated] = useState(false);

  const checkVersion = useCallback(async () => {
    const currentVersion = process.env.REACT_APP_COMMIT_SHA;

    try {
      const response = await fetch("/api/version");
      const { version } = await response.json();

      if (!currentVersion || !version) {
        return;
      }

      setIsBuildOutdated(version !== currentVersion);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, []);

  useEffect(() => {
    checkVersion();
    const interval = setInterval(checkVersion, 5 * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [checkVersion]);

  // if the build is outdated and the user goeas away, then come back
  // to the page, we want to force a refresh (like usemotion.com does)
  useEffect(() => {
    let lastVisible = Date.now();

    const reloadOnVisible = () => {
      if (document.visibilityState === "visible") {
        // only if the page was hidden for more than 30 minutes
        if (Date.now() - lastVisible < 1000 * 60 * 30) {
          return;
        }

        window.location.reload(true);
      } else {
        lastVisible = Date.now();
      }
    };

    if (!isBuildOutdated) {
      document.removeEventListener("visibilitychange", reloadOnVisible);
      return;
    }

    document.addEventListener("visibilitychange", reloadOnVisible);

    return () => {
      document.removeEventListener("visibilitychange", reloadOnVisible);
    };
  }, [isBuildOutdated]);

  return (
    <Snackbar
      open={isBuildOutdated}
      size="small"
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Paper
        sx={{
          backgroundColor: "smallcard.main",
          paddingX: 2,
          paddingY: 1,
        }}
      >
        <Stack spacing={3} direction="row" alignItems="center">
          <Typography variant="body1">New version available</Typography>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => window.location.reload(true)}
          >
            Refresh
          </Button>
        </Stack>
      </Paper>
    </Snackbar>
  );
};

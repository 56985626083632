import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import AccordionSummary from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";

import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";

import Input from "../../components/Input";
import CopyIcon from "../../icons/CopyIcon";
import CopyToClipboardBase from "../../components/shared/CopyToClipboardBase";
import { getFieldProps } from "../../utils/getFieldProps";
import Button from "../../components/Button";
import AsyncButton from "../../components/AsyncButton";

const DNSRecord = ({
  name,
  value,
  nameLabel = "Name",
  valueLabel = "Target",
}) => {
  return (
    <Stack spacing={2} direction="row">
      {[
        {
          label: nameLabel,
          value: name,
        },
        {
          label: valueLabel,
          value: value,
        },
      ].map(({ label, value }, i) => (
        <CopyToClipboardBase text={value} key={i}>
          {({ copy, isCopied }) => (
            <Input
              label={label}
              value={value}
              disabled
              sx={{ mt: 0 }}
              InputProps={{
                endAdornment: isCopied ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <InputAdornment position="end">
                    <IconButton onClick={copy}>
                      <CopyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </CopyToClipboardBase>
      ))}
    </Stack>
  );
};

const CustomDomain = ({
  label,
  placeholder,
  helperText,
  dnsRecords,
  isDisabled,
  status,
  error,
  name,
  formik,
  onUpdateStatus,
  onDelete,
  isCreated,
}) => {
  return (
    <>
      <Typography variant="subtitle2">{label}</Typography>
      <TextField
        fullWidth
        placeholder={placeholder}
        {...getFieldProps(formik, {
          name,
          disabled: isDisabled,
          helperText,
        })}
      />
      {status && (
        <Accordion
          sx={{
            "::before": { opacity: 0 },
          }}
          expanded={status === "active" ? false : undefined}
        >
          <AccordionSummary
            expandIcon={status === "active" ? undefined : <ExpandMoreIcon />}
            sx={{
              p: 0,
              cursor: status === "active" ? "default !important" : "pointer",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{ width: "100%", alignItems: "center" }}
            >
              <Chip
                sx={{ textTransform: "capitalize" }}
                label={status}
                size="small"
                color={
                  status === "active"
                    ? "success"
                    : status !== "pending"
                    ? "error"
                    : undefined
                }
              />
              {error && (
                <Typography variant="body2" color="error">
                  {error}
                </Typography>
              )}
              <Box sx={{ flex: 1 }} />
              {status !== "active" && (
                <Typography variant="body2">Complete DNS setup</Typography>
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            {status !== "active" &&
              dnsRecords.map(({ name, value, nameLabel, valueLabel }, i) => (
                <DNSRecord
                  value={value}
                  name={name}
                  key={i}
                  nameLabel={nameLabel}
                  valueLabel={valueLabel}
                />
              ))}
          </AccordionDetails>
        </Accordion>
      )}
      {isCreated && (
        <>
          <Stack spacing={2} direction="row">
            {status !== "active" && (
              <AsyncButton
                variant="contained"
                onClick={onUpdateStatus}
                size="small"
              >
                Recheck
              </AsyncButton>
            )}
            <Button
              color="error"
              variant="contained"
              onClick={onDelete}
              size="small"
            >
              Remove
            </Button>
          </Stack>
          {status !== "active" && (
            <Typography variant="caption" color="text.secondary">
              Validation is based on your DNS provider and may take up to 48
              hours
            </Typography>
          )}
        </>
      )}
    </>
  );
};

const CustomDomains = ({
  company,
  formik,
  isDisabled,
  onUpdateStatus,
  onDelete,
}) => {
  const ownershipVerification =
    company.customDomainSettings?.api?.ownershipVerification;
  const validationRecords =
    company.customDomainSettings?.api?.validationRecords;

  return (
    <Stack spacing={2}>
      {[
        {
          type: "script",
          label: "Script custom domain",
          placeholder: "Script custom domain name",
          helperText:
            "Is used to get a tracking script. For example, bugpilot-script.yourcustomdomain.com",
          dnsRecords: [
            {
              name: formik.values.scriptCustomDomainName,
              value: "script.bugpilot.io",
              nameLabel: "CNAME name",
              valueLabel: "CNAME target",
            },
          ],
        },
        {
          type: "api",
          label: "API custom domain",
          placeholder: "API custom domain name",
          helperText: "For example, bugpilot-api.yourcustomdomain.com",
          dnsRecords: [
            {
              name: formik.values.apiCustomDomainName,
              value: "api.bugpilot.io",
            },
            ...(ownershipVerification
              ? [
                  {
                    name: ownershipVerification.name,
                    value: ownershipVerification.name,
                    nameLabel: "Hostname pre-validation TXT name",
                    valueLabel: "Hostname pre-validation TXT value",
                  },
                ]
              : []),
            ...(validationRecords?.[0]
              ? [
                  {
                    name: validationRecords[0].txt_name,
                    value: validationRecords[0].txt_value,
                    nameLabel: "Certificate validation TXT name",
                    valueLabel: "Certificate validation TXT value",
                  },
                ]
              : []),
          ],
        },
      ].map((props) => (
        <CustomDomain
          {...props}
          key={props.type}
          status={company.customDomainSettings?.[props.type]?.status}
          error={company.customDomainSettings?.[props.type]?.error}
          formik={formik}
          name={`${props.type}CustomDomainName`}
          isDisabled={
            company[`${props.type}CustomDomainName`] ||
            isDisabled ||
            company.planId !== "Enterprise"
          }
          onDelete={() => onDelete({ type: props.type })}
          onUpdateStatus={() => onUpdateStatus({ type: props.type })}
          isCreated={Boolean(company[`${props.type}CustomDomainName`])}
        />
      ))}
    </Stack>
  );
};

export default CustomDomains;

import React, { useMemo } from "react";
import { Alert, Box } from "@mui/material";

export const isInviteSignIn = () => {
  const redirectPath = localStorage.getItem("Bugpilot::redirectPath");
  if (redirectPath && redirectPath.includes("invitation")) {
    return redirectPath;
  }

  return false;
};

export const InviteSignInAddon = () => {
  const isInvite = useMemo(isInviteSignIn, []);

  if (!isInvite) {
    return null;
  }

  return (
    <Box sx={{ width: "100%", paddingX: 4 }} maxWidth={400}>
      <Alert variant="filled" color="info">
        To accept this invite, log in to your Bugpilot account, or create an
        account.
      </Alert>
    </Box>
  );
};

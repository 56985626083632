import React, { useCallback, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Button, CardMedia } from "@mui/material";
import * as Fathom from "fathom-client";

import { useAnalytics } from "../analytics/AnalyticsContext";
import { FavoriteOutlined as FavoriteIcon } from "@mui/icons-material";
import { OnboardingFrame } from "../components/OnboardingFrame";

export const SubscriptionTrialSuccess = () => {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent("Signup Trial Started", {});
  }, [trackEvent]);

  const goToDashboard = useCallback(async () => {
    // TODO: This is a hack to force a reload of the app
    // since the subscription data has been updated
    window.location.href = "/";
  }, []);

  useEffect(() => {
    Fathom?.trackGoal("X9KQ7LPJ", 1500);
  }, []);

  return (
    <OnboardingFrame
      LeftComponent={() => (
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <CardMedia
            component="img"
            image="https://imagedelivery.net/55PXnfNaLA7qnpEnq-80ug/01148ea3-4fca-4694-b986-b21177213700/w500"
            sx={{ width: 300 }}
          />
        </Stack>
      )}
      LeftComponentProps={{ sx: { width: "100%" } }}
    >
      <Stack
        direction="column"
        spacing={2}
        alignItems="flex-start"
        justifyContent="center"
      >
        <FavoriteIcon fontSize="large" color="error" />

        <Typography variant="h6" fontWeight="bold">
          You made our day!
        </Typography>

        <Typography variant="body1">
          Thank you for choosing Bugpilot, you can now proceed to your
          workspace.
        </Typography>

        <Button variant="contained" color="secondary" onClick={goToDashboard}>
          Go to workspace
        </Button>
      </Stack>
    </OnboardingFrame>
  );
};

import { CircularProgress, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getFieldProps } from "../../utils/getFieldProps";
import Input from "../Input";
import Button from "../Button";
import Modal from "../Modal";

const createValidationSchema = ({ entityName, names }) =>
  Yup.object().shape({
    name: Yup.string()
      .min(6, "Minimum 6 characters")
      .max(50, "Maximum 50 characters")
      .required("Title is a required field")
      .test(
        "exists",
        `${entityName} with this name is already exist`,
        (value) =>
          !(names || [])
            .map((name) => name.toLowerCase().trim())
            .includes(value?.trim()?.toLowerCase())
      ),
  });

const EditModal = ({
  onClose,
  onConfirm,
  entityName,
  names,
  entity,
  title,
  dirty,
  ctaText,
}) => {
  const validationSchema = useMemo(
    () => createValidationSchema({ names, entityName }),
    [names, entityName]
  );

  const formik = useFormik({
    initialValues: entity || {
      name: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      await onConfirm(values);
      onClose();
    },
  });

  return (
    <Modal onClose={onClose} onSubmit={formik.handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center">
            {title || (entity ? "Edit this" : "Create new")} {entityName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Title"
            required
            placeholder={`Name your ${entityName}`}
            {...getFieldProps(formik, {
              name: "name",
            })}
            autoFocus
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                bold
                color="primary"
                type="submit"
                variant="contained"
                endIcon={formik.isSubmitting && <CircularProgress size={25} />}
                disabled={formik.isSubmitting || (!dirty && !formik.dirty)}
              >
                {ctaText || (entity ? "Save" : "Create")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {entity && !formik.isSubmitting && (
          <Grid item xs={12}>
            <Button bold onClick={onClose}>
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};

export default EditModal;

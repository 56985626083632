import {
  Alert,
  Box,
  BoxProps,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";

import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Report } from "../../pages/BugTrackerPage/types";
import {
  CloseOutlined as CloseIcon,
  ExpandLessOutlined as ExpandLessIcon,
  ExpandMoreOutlined as ExpandMoreIcon,
} from "@mui/icons-material";
import HelpButton from "../Help";
import { noop, orderBy } from "lodash";
import { useAppContext } from "../../contexts/AppContext";
import { LoadingButton } from "@mui/lab";

type PriorityWizardProps = {
  report: Report;
  setReport: (report: Report) => void;
};

type WizardStepProps = {
  tooltip?: string;
  title?: string;
  text: ReactNode;
  boxProps?: BoxProps;
  typographyProps?: TypographyProps;
};

const WizardStep = ({
  tooltip = null,
  title,
  text,
  boxProps,
  typographyProps,
}: WizardStepProps) => {
  return (
    <Tooltip title={tooltip} placement="bottom" arrow>
      <Box
        {...boxProps}
        sx={{
          flex: 1,
          flexBasis: 120,
          // width: 120,
          height: 80,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 2,
          cursor: tooltip ? "pointer" : "default",
          ...boxProps?.sx,
        }}
      >
        {title && (
          <Typography
            variant="body2"
            {...typographyProps}
            sx={{
              fontWeight: 600,
              fontSize: "0.8em",
              textTransform: "uppercase",
              ...typographyProps?.sx,
            }}
          >
            {title}
          </Typography>
        )}

        <Typography
          variant="body2"
          {...typographyProps}
          sx={{
            fontWeight: 600,
            fontSize: "2em",
            width: "100%",
            textAlign: "center",
            paddingX: 2,
            ...typographyProps?.sx,
          }}
        >
          {text}
        </Typography>
      </Box>
    </Tooltip>
  );
};

type DropdownItemProps = {
  value: string;
  title: string;
  description: string;
  selected: boolean;
  onClick: (e: any) => void;
};

const DropdownItem = ({
  value,
  title,
  description,
  selected,
  onClick,
}: DropdownItemProps) => {
  return (
    <MenuItem
      value={value}
      selected={selected}
      sx={{
        gap: 0.5,
      }}
      onClick={onClick}
    >
      <strong>{title}</strong>
      <span> &ndash; </span>
      <span>{description}</span>
    </MenuItem>
  );
};

type ImpactType = "signup" | "billing" | "core" | "non-core" | "minor";
type ImpactDropdownProps = {
  impact: ImpactType | null;
  setImpact: (impact: ImpactType) => void;
};

type ListType<X> = {
  value: X;
  title: string;
  description: string;
}[];

// Impact
//

const IMPACT_LIST: ListType<ImpactType> = [
  {
    value: "core",
    title: "Core",
    description: "A main functionality is not usable",
  },
  {
    value: "non-core",
    title: "Non-core",
    description: "A minor functionality is not usable",
  },
  {
    value: "signup",
    title: "Signup",
    description: "New customers cannot sign up",
  },
  {
    value: "billing",
    title: "Billing",
    description: "Customers are not being billed correctly",
  },
  {
    value: "minor",
    title: "Visual",
    description: "Some UI parts are not shown as they should",
  },
];

const ImpactDropdown = ({ impact, setImpact }: ImpactDropdownProps) => {
  return (
    <Select
      value={impact}
      onChange={(e) => setImpact(e.target.value as ImpactType)}
      fullWidth
      sx={{
        height: 40,
        fontSize: "0.75em",
      }}
      renderValue={(value) => {
        const item = IMPACT_LIST.find((i) => i.value === value);

        if (!item) {
          return null;
        }

        return <span>{item.title}</span>;
      }}
    >
      {IMPACT_LIST.map((item) => (
        <DropdownItem
          key={item.value}
          value={item.value}
          title={item.title}
          description={item.description}
          selected={item.value === impact}
          onClick={() => setImpact(item.value)}
        />
      ))}
    </Select>
  );
};

// Urgency
//

type UrgencyType = "ui" | "partial" | "unusable";
type UrgencyDropdownProps = {
  urgency: UrgencyType | null;
  setUrgency: (urgency: UrgencyType) => void;
};

const URGENCY_LIST: ListType<UrgencyType> = [
  {
    value: "unusable",
    title: "Unusable",
    description: "The functionality is not usable",
  },
  {
    value: "partial",
    title: "Disrupted",
    description: "The functionality is partially usable",
  },
  {
    value: "ui",
    title: "Visual problem",
    description: "Some UI parts are not shown as they should",
  },
];

const UrgencyDropdown = ({ urgency, setUrgency }: UrgencyDropdownProps) => {
  return (
    <Select
      value={urgency}
      onChange={(e) => setUrgency(e.target.value as UrgencyType)}
      fullWidth
      sx={{
        height: 40,
        fontSize: "0.75em",
      }}
      renderValue={(value) => {
        const item = URGENCY_LIST.find((i) => i.value === value);

        if (!item) {
          return null;
        }

        return <span>{item.title}</span>;
      }}
    >
      {URGENCY_LIST.map((item) => (
        <DropdownItem
          key={item.value}
          value={item.value}
          title={item.title}
          description={item.description}
          selected={item.value === urgency}
          onClick={() => setUrgency(item.value)}
        />
      ))}
    </Select>
  );
};

// Priority dropdown
//

export const PRIORITY_LIST = orderBy(
  [
    // ui, minor, major, critical
    {
      value: "P4",
      icon: <span>P4</span>,
      // description:
      //   "Visual: some UI is not rendering correctly or some errors are thrown with no end-userimpact. The app is still usable and users might not notice the problem",
      // impact: "none",
      description: "Fixing this bug is not a priority",
      color: "#EC515A10",
      contrastColor: "#000000",
    },
    {
      value: "P3",
      icon: <span>P3</span>,
      // description:
      //   "Minor: some non-core features are not working properly but the app is still usable",
      // impact: "minor",
      description: "It is not critical to fix this bug",
      color: "#EC515A40",
      contrastColor: "#000000",
    },
    {
      value: "P2",
      icon: <span>P2</span>,
      // description:
      //   "Major: some core features are not working properly and the app is not usable",
      // impact: "major",
      description: "It is critical to fix this bug",
      color: "#EC515AAF",
      contrastColor: "#FFFFFF",
    },
    {
      value: "P1",
      icon: <span>P1</span>,
      // description:
      //   "Critical: the app is not working at all and users cannot use it or access their data",
      // impact: "critical",
      description: "This bug must be fixed as soon as possible",
      color: "#EC515AFF",
      contrastColor: "#FFFFFF",
    },
  ],
  ["value"],
  ["asc"]
);

type PriorityType = "P1" | "P2" | "P3" | "P4";
type PriorityDropdownProps = {
  priority: PriorityType | null;
  setPriority: (priority: PriorityType) => void;
};

const PriorityDropdown = ({ priority, setPriority }: PriorityDropdownProps) => {
  return (
    <Select
      value={priority}
      onChange={(e) => setPriority(e.target.value as PriorityType)}
      fullWidth
      sx={{
        height: 40,
        fontSize: "0.75em",
        // no border
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: 1,
        },
      }}
      renderValue={(value) => {
        const item = PRIORITY_LIST.find((i) => i.value === value);

        if (!item) {
          return null;
        }

        return <span>{item.value}</span>;
      }}
    >
      {PRIORITY_LIST.map((item) => (
        <DropdownItem
          key={item.value}
          value={item.value}
          title={item.value}
          description={item.description}
          selected={item.value === priority}
          // @ts-ignore
          onClick={() => setPriority(item.value)}
        />
      ))}
    </Select>
  );
};

// Main component
//

export const PriorityWizard = ({ report, setReport }: PriorityWizardProps) => {
  const { updateReport } = useAppContext();

  const affectedUsers = useMemo(
    () => report?.metadata?.errorInfo?.numAffectedUsers,
    [report?.metadata]
  );
  const numOccurences = useMemo(
    () => report?.metadata?.errorInfo?.numOccurences,
    [report?.metadata]
  );
  const [impact, setImpact] = useState<ImpactType | null>(
    report?.metadata?.impact || null
  );
  const [urgency, setUrgency] = useState<UrgencyType | null>(
    report?.metadata?.urgency || null
  );
  const [priority, setPriority] = useState<PriorityType | null>(
    report?.priority || null
  );

  useEffect(() => {
    // Set recommended priority
    //

    if (affectedUsers <= 1 || impact === "minor" || urgency === "ui") {
      // some issues are always not important
      setPriority("P4");
      return;
    }

    if (impact === "core" || impact === "signup") {
      if (urgency === "unusable") {
        setPriority("P1");
        return;
      }

      if (urgency === "partial") {
        setPriority("P2");
        return;
      }
    }

    if (impact === "billing") {
      if (urgency === "unusable") {
        setPriority("P2");
        return;
      }

      if (urgency === "partial") {
        setPriority("P3");
        return;
      }
    }

    if (impact === "non-core") {
      if (urgency === "unusable") {
        setPriority("P3");
        return;
      }

      if (urgency === "partial") {
        setPriority("P4");
        return;
      }
    }
  }, [impact, urgency, affectedUsers]);

  const priorityObj = useMemo(() => {
    if (!priority) {
      return PRIORITY_LIST[PRIORITY_LIST.length - 1];
    }

    return PRIORITY_LIST.find((p) => p.value === priority);
  }, [priority]);

  const handleUpdateReport = useCallback(
    (field: string, value: any): Promise<any> => {
      let diff: { [key: string]: any };

      if (field === "impact" || field === "urgency") {
        diff = {
          metadata: {
            ...report.metadata,
            [field]: value,
          },
        };
      } else {
        diff = {
          [field]: value,
        };
      }

      // @ts-ignore
      return updateReport(
        report,
        {
          ...diff,
        },
        setReport
      );
    },
    [report, updateReport, setReport]
  );

  const save = useCallback(
    (
      field: string,
      value: string,
      setFn: (arg0: any) => void
    ): Promise<any> => {
      setFn(value);
      return handleUpdateReport(field, value);
    },
    [handleUpdateReport]
  );

  const [saving, setSaving] = useState(false);
  const [expanded, setExpanded] = useState(!report?.priority);

  if (!report?.metadata?.errorInfo) {
    return null;
  }

  if (!report.metadata.errorInfo.isErrorNew && !report?.metadata?.demo) {
    return (
      <Alert severity="info">
        Navigate to the related issue to set the priority.
      </Alert>
    );
  }

  return (
    <Paper
      sx={{
        mt: 1,
      }}
    >
      <Box p={1} px={2}>
        <Stack gap={2}>
          <Stack
            direction="row"
            gap={1}
            justifyContent="space-between"
            onClick={() => setExpanded(!expanded)}
            sx={{
              cursor: "pointer",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <span>
                {report.priority ? (
                  <>
                    <b>Current Priority:</b> {report.priority}
                  </>
                ) : (
                  <>
                    <b>Action Required</b> &ndash; assign a priority
                  </>
                )}
              </span>
              <HelpButton title="We suggest you a priority based on the urgency, impact and frequency of the error. Your business benefits from resolving high-impact errors first. P1 = highest priority, P4 = lowest priority." />
            </Typography>

            <IconButton size="small" color="primary">
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Stack>

          {expanded ? (
            <>
              <Stack direction="row" gap={1}>
                <WizardStep
                  tooltip={null}
                  title={"Recommended"}
                  text={
                    <PriorityDropdown
                      priority={priority}
                      setPriority={setPriority}
                    />
                  }
                  boxProps={{
                    sx: {
                      flexBasis: 140,
                      backgroundColor: priorityObj.color,
                      flex: 0,
                      ".MuiSelect-select, .MuiSelect-icon": {
                        color: priorityObj.contrastColor,
                      },
                    },
                  }}
                  typographyProps={{
                    sx: {
                      color: priorityObj.contrastColor,
                    },
                  }}
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    alignSelf: "center",
                    fontSize: "2em",
                    width: 40,
                  }}
                >
                  =
                </Typography>
                {/* How many times the error occurred */}
                {/* <WizardStep
              tooltip={`${numOccurences} times`}
              title={numOccurences === 1 ? "Session" : "Sessions"}
              text={numOccurences}
              boxProps={{
                sx: {
                  backgroundColor: "white.dark",
                  flex: 0,
                  flexBasis: 100,
                },
              }}
            /> */}
                {/* <Close sx={{ alignSelf: "center", fontSize: "2em", width: 40 }} /> */}
                {/* How many times the error occurred */}
                <WizardStep
                  // tooltip={`High-impact functionality`}
                  title={"Impact"}
                  text={
                    <ImpactDropdown
                      impact={impact}
                      setImpact={(value) => save("impact", value, setImpact)}
                    />
                  }
                  boxProps={{
                    sx: {
                      backgroundColor: "white.dark",
                      width: 200,
                    },
                  }}
                />
                <CloseIcon
                  sx={{ alignSelf: "center", fontSize: "2em", width: 40 }}
                />
                {/* How many times the error occurred */}
                <WizardStep
                  title={"Urgency"}
                  text={
                    <UrgencyDropdown
                      urgency={urgency}
                      setUrgency={(value) => save("urgency", value, setUrgency)}
                    />
                  }
                  boxProps={{
                    sx: {
                      backgroundColor: "white.dark",
                      width: 200,
                    },
                  }}
                />
                <CloseIcon
                  sx={{ alignSelf: "center", fontSize: "2em", width: 40 }}
                />
                {/* How many users are affected */}
                <WizardStep
                  tooltip={`${affectedUsers} users are affected (${numOccurences} times)`}
                  title={affectedUsers === 1 ? "User" : "Users"}
                  text={report.metadata.errorInfo.numAffectedUsers}
                  boxProps={{
                    sx: {
                      backgroundColor: "white.dark",
                      flex: 0,
                      flexBasis: 100,
                    },
                  }}
                />
              </Stack>

              {!report?.priority || report?.priority !== priority ? (
                <Box>
                  <LoadingButton
                    loading={saving}
                    variant="outlined"
                    color="primary"
                    size="small"
                    sx={{
                      width: 140,
                      color: PRIORITY_LIST[0].color,
                      borderColor: PRIORITY_LIST[0].color,
                      "&:hover": {
                        borderColor: PRIORITY_LIST[0].color,
                      },
                    }}
                    onClick={async () => {
                      setSaving(true);
                      await save("priority", priority, noop);
                      setTimeout(() => {
                        setSaving(false);
                      }, 1000);
                    }}
                  >
                    {report?.priority ? "Update" : "Set"} Priority
                  </LoadingButton>
                </Box>
              ) : null}
            </>
          ) : null}
        </Stack>
      </Box>
    </Paper>
  );
};

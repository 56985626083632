import React, { useEffect, useMemo } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import { useLocation, useNavigate } from "react-router-dom";
import startOfDay from "date-fns/startOfDay";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";

import { useAppContext } from "../contexts/AppContext";
import { useAuth } from "../contexts/AuthContext";
import { SelectWorkspaceDropdown } from "../components/SelectWorkspaceDropdown";
import {
  Button,
  CircularProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import { OnboardingFrame } from "../components/OnboardingFrame";
import { useSnackbar } from "notistack";
import { PADDLE_PRODUCT_ID_SEAT_MONTHLY } from "./SubscriptionStart";
import Loader from "../components/Loader";

export const CURRENCY_CODE = "usd";
const BENEFITS = [
  "AI-powered Automated bug detection",
  "Cheaper than writing one bug ticket",
  "Cheaper than losing a customer",
  "Cheaper than getting on a call with a customer",
];

const LeftComponent = ({
  selectedSubscription,
  selectedPlanId,
  toggleSelectedPlan,
}) => {
  const { companyMembers } = useAppContext();

  if (!companyMembers.length) {
    return (
      <Stack alignItems="center">
        <Loader title="Loading team members" color="primary" />
      </Stack>
    );
  }

  return (
    <Stack
      spacing={2}
      justifyContent="center"
      alignItems="flex-start"
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: 350,
        margin: "auto",
        position: "relative",
      }}
      color="white"
    >
      {selectedSubscription && (
        <>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: "white",
            }}
          >
            Start fixing hidden bugs
          </Typography>

          <Typography
            variant="body1"
            style={{
              color: "white",
            }}
          >
            Subscribe to Bugpilot for{" "}
            <strong>
              {getSymbolFromCurrency(CURRENCY_CODE)}
              {selectedSubscription.recurringPrice[CURRENCY_CODE] *
                companyMembers.length}
              /{selectedSubscription.billingType}
              {companyMembers.length > 1 && (
                <> ({companyMembers.length} seats)</>
              )}
            </strong>{" "}
            (
            <Link
              href="#"
              sx={{
                textDecoration: "underline",
                textUnderlineOffset: 2,
              }}
              style={{
                color: "white",
              }}
              onClick={(e) => {
                e.preventDefault();
                toggleSelectedPlan();
              }}
            >
              {selectedPlanId === PADDLE_PRODUCT_ID_SEAT_MONTHLY ? (
                <>switch to yearly and save 30%</> // 14 - x*14 = 10, x = 4 / 14 = 0.2857 = 28.57%
              ) : (
                <>switch to monthly</>
              )}
            </Link>
            )<br />
            <br />
            60-day money-back guarantee, no questions asked. Cancel anytime.
          </Typography>

          <List dense>
            {BENEFITS.map((benefit, i) => (
              <ListItem key={i} dense disablePadding>
                <ListItemIcon
                  sx={{
                    minWidth: 36,
                    color: "white.main",
                  }}
                >
                  <CheckCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: "white.main" }}>
                  {benefit}
                </ListItemText>
              </ListItem>
            ))}
          </List>

          <Link
            variant="outlined"
            color="inherit"
            href="/"
            style={{
              color: "white",
              fontSize: "0.75rem",
            }}
          >
            &larr; Not now
          </Link>
        </>
      )}
    </Stack>
  );
};

export const Container = ({ children, LeftComponentProps }) => {
  return (
    <OnboardingFrame
      LeftComponent={LeftComponent}
      LeftComponentProps={LeftComponentProps}
    >
      {children}
    </OnboardingFrame>
  );
};

export const SelectWorkspaceHeader = ({ companies }) => {
  return (
    companies?.length > 1 && (
      <SelectWorkspaceDropdown label="Selected workspace" sx={{ ml: -1.75 }} />
    )
  );
};

const RequireSubscription = ({
  children,
  excludeRoutes,
  subscriptionNotNecessary = false,
}) => {
  const { isCompaniesLoading, currentWorkspace } = useAppContext();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const navigate = useNavigate();

  const pathWithoutSubscription = useMemo(() => {
    return excludeRoutes?.some((pathStart) =>
      location.pathname.startsWith(pathStart)
    );
  }, [location.pathname, excludeRoutes]);

  const shouldRedirectToSubscribe = useMemo(() => {
    if (subscriptionNotNecessary) {
      return false;
    }

    if (isCompaniesLoading) {
      return false;
    }

    if (!currentWorkspace) {
      return false;
    }

    if (user == null) {
      return false;
    }

    if (pathWithoutSubscription) {
      return false;
    }

    if (!currentWorkspace?.subscriptionStatus) {
      // From now on, all users must have a subscription
      return true;
    }

    const subscriptionActive =
      startOfDay(new Date()) <=
      startOfDay(new Date(currentWorkspace?.subscriptionFinishedAt)); // FUTURE: currentWorkspace.subscriptionActive

    if (subscriptionActive) {
      return false;
    }

    enqueueSnackbar(
      `Your subscription ended on ${new Date(
        currentWorkspace?.subscriptionFinishedAt
      ).toLocaleDateString()}. Please renew your subscription to continue using Bugpilot.`,
      {
        id: "subscription-ended",
        preventDuplicate: true, // Prevent duplicate snackbar with the same message, since this memo can be called multiple times
        variant: "error",
        persist: true,
      }
    );

    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCompaniesLoading,
    user,
    pathWithoutSubscription,
    currentWorkspace,
    currentWorkspace?.subscriptionFinishedAt,
  ]);

  useEffect(() => {
    if (!shouldRedirectToSubscribe) {
      return;
    }

    if (user == null) {
      return;
    }

    return navigate("/subscription/start" + window.location.search);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRedirectToSubscribe, currentWorkspace, children, user]);

  if (subscriptionNotNecessary || pathWithoutSubscription) {
    return children;
  }

  if (isCompaniesLoading) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  if (shouldRedirectToSubscribe) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      {currentWorkspace?.subscriptionStatus === "past_due" && (
        <Snackbar open={true} color="error">
          <SnackbarContent>
            Your subscription is past due
            <Button
              color="inherit"
              onClick={() => {
                navigate("/settings/billing");
              }}
            >
              Update payment info
            </Button>
          </SnackbarContent>
        </Snackbar>
      )}

      {children}
    </>
  );
};

export default RequireSubscription;

import { TextField, MenuItem } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useCallback, useMemo } from "react";
import { useAppContext } from "../contexts/AppContext";
import { useNavigate } from "react-router-dom";

const SelectTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    width: "100%",
  },
})(TextField);

export const SelectWorkspaceDropdown = ({ noPreselect, ...props }) => {
  const navigate = useNavigate();
  const {
    companies: _companies,
    selectedCompanyId,
    setSelectedCompanyId,
  } = useAppContext();

  const companies = useMemo(
    () => _companies.filter(({ isExternalCompany }) => !isExternalCompany),
    [_companies]
  );

  const handleCompanyChange = useCallback(
    (e) => {
      setSelectedCompanyId(e.target.value);

      const url = new URL(window.location.href);
      if (url.searchParams.has("companyId")) {
        url.searchParams.delete("companyId");
        navigate(url.toString());
        return;
      }

      navigate(`/`);
    },
    [navigate, setSelectedCompanyId]
  );

  return (
    <SelectTextField
      select
      size="small"
      value={noPreselect ? "--select" : selectedCompanyId}
      onChange={handleCompanyChange}
      {...props}
    >
      {noPreselect && (
        <MenuItem value="--select">
          <>Change workspace...</>
        </MenuItem>
      )}

      <MenuItem value="" disabled>
        <>Switch workspace:</>
      </MenuItem>

      {companies.map(({ id, name }) => (
        <MenuItem value={id} key={id}>
          {name}
        </MenuItem>
      ))}
    </SelectTextField>
  );
};

import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { Auth } from "aws-amplify";
import QRCode from "qrcode.react";

import CloseIcon from "@mui/icons-material/CloseOutlined";
import Button from "../Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input";
import { getFieldProps } from "../../utils/getFieldProps";
import InputAdornment from "@mui/material/InputAdornment";
import KeyIcon from "../../icons/KeyIcon";

const DialogActions = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(4)}`,
  },
}))(MuiDialogActions);

const DialogContent = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(4)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogTitle);

const validationSchema = Yup.object({
  code: Yup.string()
    .min(6, "The confirmation code must be exactly 6 digits")
    .max(6, "The confirmation code must be exactly 6 digits")
    .required("The confirmation code is mandatory"),
});

const EnableMFAModal = ({ onClose, onConfirm }) => {
  const [qrCodeUri, setQrCodeUri] = useState("");
  const [error, setError] = useState(false);

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setError(null);

      try {
        await onConfirm(values);
        onClose();
      } catch (e) {
        console.error("Error verifying TOTP", e);
        setError(e.message);
      }
    },
  });

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      const secret = await Auth.setupTOTP(user);

      const uri = new URL(`otpauth://totp/${user.attributes.email}`);
      uri.searchParams.append("secret", secret);
      uri.searchParams.append("issuer", "Bugpilot.io");

      setQrCodeUri(uri.toString());
    })();
  }, []);

  /*// Then you will have your TOTP account in your TOTP-generating app (like Google Authenticator)
  // Use the generated one-time password to verify the setup

*/

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold">
            Two-Factor Authentication
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="body1">
              Scan the QR Code with Google Authenticator (or any similar app
              such as Authy or Microsoft Authenticator), then enter the
              verification code.
            </Typography>
            <Typography variant="body1" mt={1} fontWeight="bold">
              You will be asked for a verification code every time you login.
            </Typography>
          </Grid>
          <Grid item align="center">
            {qrCodeUri ? (
              <QRCode value={qrCodeUri} size={200} />
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Grid item>
            <Input
              fullWidth
              label="Verification code"
              size="small"
              required
              type="text"
              {...getFieldProps(formik, {
                name: "code",
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>{error && <Alert severity="error">{error}</Alert>}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          bold
          disabled={!formik.dirty || formik.isSubmitting}
          onClick={formik.handleSubmit}
          endIcon={formik.isSubmitting && <CircularProgress size={25} />}
          fullWidth
          variant="contained"
        >
          Enable
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EnableMFAModal;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useParams, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { sortBy, capitalize } from "lodash";

import {
  CircularProgress,
  Stack,
  Typography,
  Grid,
  Box,
  Alert,
  AlertTitle,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";

import useHasRole from "../../../hooks/useHasRole";
import { useIntegrations } from "../../../contexts/IntegrationsContext";
import { INTEGRATION_REDIRECT_URIS } from "../../../contexts/IntegrationsContext";

import { CATEGORIES } from "./CATEGORIES";
import { IntegrationsHeader } from "./IntegrationsHeader";
import { getAvailableIntegrations } from "./getAvailableIntegrations";
import { IntegrationsCategory } from "./IntegrationsCategory";
import { IntegrationCard } from "./IntegrationCard";
import { useModals } from "../../../hooks/useModals";
import { useAppContext } from "../../../contexts/AppContext";
import DateTime from "../../../components/DateTime";
import OnlyOwnerAlert from "../../../components/OnlyOwnerAlert";
import {
  ContactSupportOutlined as ContactSupportIcon,
  RefreshOutlined as RefreshIcon,
} from "@mui/icons-material";

export const IntegrationsPage = ({
  company,
  onCreateIntegration,
  onDeleteIntegration,
  onUpdateIntegration,
  onCompanyChange,
}) => {
  const { currentWorkspace } = useAppContext();
  const flags = useMemo(
    () => currentWorkspace?.flags || {},
    [currentWorkspace]
  );

  const { type } = useParams();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal, modalTypes } = useModals();

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(null);
  const [isAdding, _setIsAdding] = useState(false);

  const setIsAdding = useCallback(
    (value) => {
      if (value) {
        _setIsAdding(true);
      } else {
        // wait a bit so user has a chance to see the loading
        setTimeout(() => _setIsAdding(false), 3 * 1000);
      }
    },
    [_setIsAdding]
  );

  const { hasRole: isAdminOrOwner } = useHasRole("admin");

  const {
    addToFront,
    addToLivechat,
    addToIntercom,
    addToZendesk,
    addToCrisp,
    addNotion,
    addSlack,
    addClickUp,
    addGitHub,
    addVercel,
    addZapier,
    addMake,
    addJira,

    helpScoutCallbackUrl,
    helpScoutSecret,
    createHelpScoutData,

    integrationsByType,
  } = useIntegrations();

  const addToHelpscout = useCallback(async () => {
    let data = {
      callbackUrl: helpScoutCallbackUrl,
      secret: helpScoutSecret,
    };

    if (!helpScoutCallbackUrl || !helpScoutSecret) {
      try {
        data = await createHelpScoutData({ companyId: company.id });
      } catch (err) {
        enqueueSnackbar("Error while creating HelpScout data", {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    }

    openModal(modalTypes.HelpScout, {
      callbackUrl: data.callbackUrl,
      secret: data.secret,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createHelpScoutData, company.id, enqueueSnackbar]);

  const handleAddWebhookIntegration = useCallback(async () => {
    setIsAdding(true);

    await onCreateIntegration(company.id, {
      type: "webhook",
      secret: `sk-${crypto.randomUUID()}`,
      webhookUrl: `https://webhookurl-${crypto.randomUUID()}`,
    });

    setIsAdding(false);
  }, [company.id, onCreateIntegration, setIsAdding]);

  useEffect(() => {
    if (type) {
      if (CATEGORIES.map((c) => c.type).includes(type)) {
        setFilter(type);
      } else {
        setFilter(null);
        setSearch(type);
      }
    }

    if (!type || new URLSearchParams(location.search).get("start") === null) {
      return;
    }

    availableIntegrations
      .find((config) => config.type === type.toLowerCase())
      ?.onAdd?.();

    // do not add 'type' below, otherwise you cannot change the value of the
    // filter, if it is set from the URL
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, filter]);

  useEffect(() => {
    const data = window.localStorage.getItem("oauthflow");

    console.warn("[OAUTH2] Handling integration OAUTH2 flow", {
      type,
      location,
      filter,
      search,
      data,
    });

    if (!data) {
      // we expect to have oauthflow data saved in session-storage.
      // see /index.js for an explanation
      return;
    }

    // next is used for Vercel integration
    const oAuthData = JSON.parse(data);
    const { code, state, next } = oAuthData;
    window.localStorage.removeItem("oauthflow");

    const expectedState = window.localStorage.getItem("oauth-expected-state");
    window.localStorage.removeItem("oauth-expected-state");

    if (
      type !== "vercel" &&
      state !== expectedState &&
      process.env.NODE_ENV === "production"
    ) {
      console.error("[OAUTH2] State mismatch", { state, expectedState });
      enqueueSnackbar("Integration flow error (auth state mismatch)", {
        variant: "error",
      });
      throw new Error("OAUTH2 state mismatch");
    }

    const completeFlow = async () => {
      try {
        setIsAdding(true);
        enqueueSnackbar("Adding integration...", { variant: "info" });

        const redirectUri = INTEGRATION_REDIRECT_URIS[type || "slack"];
        console.warn("[OAUTH2] Creating integration", {
          redirectUri,
          code,
          type,
          company,
        });

        await onCreateIntegration(company.id, {
          redirectUri,
          code,
          source: oAuthData.source,
          type: type || "slack",
        });

        enqueueSnackbar("Integration added", { variant: "success" });

        if (type === "vercel" && oAuthData.next) {
          window.open(oAuthData.next, "_self");
        }
      } catch (e) {
        console.error("[OAUTH2] Integration flow error", e);
        enqueueSnackbar("Integration flow error", { variant: "error" });
      } finally {
        setIsAdding(false);
        console.log("[OAUTH2] Integration flow done");
      }
    };

    completeFlow()
      .then(() => {
        console.log("[OAUTH2] Integration flow completed");
      })
      .catch((e) => {
        console.error("[OAUTH2] Integration flow error", e);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAdd = useCallback(
    (type) => (params) => {
      const onAdd = {
        slack: addSlack,
        clickup: addClickUp,
        github: addGitHub,
        vercel: addVercel,
        zapier: addZapier,
        atlassian: addJira,
        jira: addJira,
        notion: addNotion,
        intercom: addToIntercom,
        livechat: addToLivechat,
        crisp: addToCrisp,
        zendesk: addToZendesk,
        front: addToFront,
        helpscout: addToHelpscout,
        make: addMake,
        webhook: handleAddWebhookIntegration,
      }[type];

      if (!onAdd) {
        throw new Error(`Unknown integration type: ${type}`);
      }

      onAdd(params);
    },
    [
      addSlack,
      addClickUp,
      addGitHub,
      addVercel,
      addZapier,
      addJira,
      addNotion,
      addToIntercom,
      addToLivechat,
      addToCrisp,
      addToZendesk,
      addToFront,
      addToHelpscout,
      handleAddWebhookIntegration,
    ]
  );

  const availableIntegrations = useMemo(
    () => getAvailableIntegrations({ flags, onAdd }),
    [flags, onAdd]
  );

  // load search from query
  useEffect(() => {
    const search = new URLSearchParams(window.location.search).get("search");
    if (!search) {
      return;
    }
    setSearch(search);
  }, []);

  const filterEnabled = useCallback((integ) => integ.isEnabled || true, []);
  const filterSearch = useCallback(
    ({ name, type, category }) => {
      const searchString = search?.trim?.().toLowerCase();

      if (!searchString) {
        if (!filter) {
          return true;
        }

        return type === "request" || category === filter;
      }

      const matchesNameOrCategory =
        type.toLowerCase?.().includes(searchString) ||
        name.toLowerCase?.().includes(searchString) ||
        category.toLowerCase?.() === searchString;

      if (!filter) {
        return matchesNameOrCategory;
      }

      return (
        type === "request" || (category === filter && matchesNameOrCategory)
      );
    },
    [search, filter]
  );

  const sortedIntegrations = useMemo(() => {
    return sortBy(availableIntegrations, ["order", "name"])
      .filter(filterEnabled)
      .filter(filterSearch);
  }, [availableIntegrations, filterEnabled, filterSearch]);

  const integrationCardProps = {
    company,
    onCreateIntegration,
    onDeleteIntegration,
    onUpdateIntegration,
    onCompanyChange,
  };

  const topBarBreadcrumbsElement = document.getElementById(
    "top-bar-breadcrumbs"
  );

  const integrationsWithError = useMemo(
    () =>
      sortBy(availableIntegrations, ["order", "name"])
        .filter(filterEnabled)
        .map(({ type }) => integrationsByType[type])
        .filter(Boolean)
        .flat(1)
        .filter(
          ({ lastSendingStatus }) =>
            lastSendingStatus && lastSendingStatus !== 200
        ),
    [integrationsByType, sortedIntegrations]
  );

  return (
    <Grid container spacing={4}>
      {topBarBreadcrumbsElement &&
        createPortal(
          <IntegrationsHeader
            company={company}
            onCompanyChange={onCompanyChange}
            isAdminOrOwner={isAdminOrOwner}
            filter={filter}
            setFilter={setFilter}
            search={search}
            setSearch={setSearch}
          />,
          topBarBreadcrumbsElement
        )}

      <OnlyOwnerAlert>
        {(alert) =>
          alert ? (
            <Grid item xs={12}>
              {alert}
            </Grid>
          ) : null
        }
      </OnlyOwnerAlert>

      {integrationsWithError.length > 0 && (
        <Grid item xs={12} key={type}>
          <Alert severity="error">
            <AlertTitle>
              One or more Integrations need your attention
            </AlertTitle>

            <Typography variant="body2">
              One or more integrations are not working properly as they are
              returing errors. Please check that you have configured the correct
              permissions, and make sure you did not revoke any 3rd-party token.
              We suggest to try deleting and re-adding the integration if the
              error persists.
            </Typography>

            <List sx={{ width: "100%", mt: 2 }} dense disablePadding>
              {integrationsWithError.map((integrationError, ii) => {
                const {
                  type,
                  integrationType,
                  lastSendingAt,
                  lastSendingStatus,
                  lastSendingMessage,
                } = integrationError;

                const title = `${capitalize(integrationType)} error`;

                return (
                  <ListItem key={ii} disableGutters dense>
                    <ListItemText
                      primary={
                        <>
                          <b title={type}>{title}</b>: {lastSendingStatus}{" "}
                          {lastSendingMessage}{" "}
                          <DateTime
                            typographyProps={{
                              variant: "caption",
                            }}
                          >
                            {lastSendingAt}
                          </DateTime>
                        </>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>

            <Stack direction="row" spacing={2} mt={2}>
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={() => {
                  window.open(
                    `mailto:support@bugpilot.io?subject=Help with integration errors (${company?.id})`
                  );
                }}
                icon={<ContactSupportIcon />}
              >
                Contact support
              </Button>

              <Button
                size="small"
                variant="text"
                color="error"
                onClick={() => window.location.reload()}
                icon={<RefreshIcon />}
              >
                Check again
              </Button>
            </Stack>
          </Alert>
        </Grid>
      )}

      <Grid item width="100%">
        {isAdding ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
            p={4}
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "100%",
                height: "100%",
              }}
              spacing={2}
            >
              <CircularProgress />
              <Typography variant="body1">Saving integration...</Typography>
            </Stack>
          </Box>
        ) : (
          <Stack direction="column" spacing={6}>
            {search ? (
              <Grid container spacing={4} width="100%">
                {sortedIntegrations.map((integration) => (
                  <IntegrationCard
                    key={integration.type}
                    {...integration}
                    {...integrationCardProps}
                  />
                ))}
              </Grid>
            ) : (
              CATEGORIES.map(({ category, title, deprecated }) => (
                <IntegrationsCategory
                  key={category}
                  category={category}
                  title={title}
                  deprecated={deprecated}
                  filter={filter}
                  search={search}
                  allIntegrations={sortedIntegrations}
                  IntegrationCardProps={integrationCardProps}
                />
              )).filter(Boolean)
            )}
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

import React from "react";
import { Button } from "@mui/material";
import { BuildOutlined as BuildIcon } from "@mui/icons-material";
import UpgradeButton from "../../components/UpgradeButton";

export const APIDescription = ({ company, handleOpenToggle, isOwner }) =>
  company?.appSumoId && !["Team", "Enterprise"].includes(company.planId) ? (
    <UpgradeButton
      text={
        <>
          Is not available in your <b>{company.planName}</b> plan
        </>
      }
    />
  ) : (
    <Button
      onClick={handleOpenToggle}
      variant="outlined"
      color="secondary"
      size="small"
      startIcon={<BuildIcon />}
    >
      Settings
    </Button>
  );

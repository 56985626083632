import Typography from "@mui/material/Typography";

import { Button, Stack, TableCell, TableRow, Tooltip } from "@mui/material";

import { useAppContext } from "../../contexts/AppContext";
import { useCallback, useState } from "react";
import { useSnackbar } from "notistack";

import { useAnalytics } from "../../analytics/AnalyticsContext";
import CodeContainer from "../CodeContainer.tsx";

const HUMANIZE_ENDPOINT =
  "https://dstxwn5nc8.execute-api.eu-west-1.amazonaws.com/prod/humanize";

const JoinAutofixPreviewButton = () => {
  const { trackEvent } = useAnalytics();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = useCallback(() => {
    enqueueSnackbar(
      "This feature is in technical preview. We'll get back to you as soon we have a spot for you.",
      {
        variant: "info",
      }
    );

    trackEvent("Clicked join autofix preview button");
  }, []);

  return (
    <Tooltip title="Join the Autofix technical preview" arrow>
      <Button
        variant="outlined"
        color="error"
        size="small"
        onClick={handleClick}
      >
        Autofix
      </Button>
    </Tooltip>
  );
};

const RowWithSourceMap = ({ lineIndex, lineString }) => {
  const [positionString, codeAtLine] = lineString.split(" near ");
  const [source, line, column] = positionString.split(":");

  return (
    <TableRow>
      <TableCell
        style={{
          color:
            source.includes("node_modules") ||
            source.includes("webpack") ||
            source.includes("unknown")
              ? "gray"
              : "black",
        }}
      >
        at {source} line {line} col {column}{" "}
        {codeAtLine && (
          <code>(near &#96;{codeAtLine.trim().substr(0, 20)}...&#96;)</code>
        )}
      </TableCell>
    </TableRow>
  );
};

// const StackTrace = ({ stack, useSourceMaps = false }) => {
//   const { workspaceSourcemaps, currentWorkspace } = useAppContext();
//   const { enqueueSnackbar } = useSnackbar();

//   const [humanizedResult, setHumanizedResult] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [showStackTrace, setShowStackTrace] = useState(false);

//   const codeRef = useRef();

//   // scroll the container to data-focus
//   useEffect(() => {
//     if (!codeRef.current) {
//       return;
//     }

//     codeRef.current.querySelector("[data-focus]")?.scrollIntoView?.({
//       block: "center",
//     });
//   }, [codeRef, codeRef?.current]);

//   useEffect(() => {
//     if (!useSourceMaps) {
//       return;
//     }

//     // load from api
//     setLoading(true);

//     fetch(HUMANIZE_ENDPOINT, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         error: stack,
//         sourceMapURLs: workspaceSourcemaps.map((sm) => sm.url),
//       }),
//     })
//       .then(async (res) => {
//         if (res.status === 200) {
//           return res.json();
//         }

//         const body = await res.text();
//         throw new Error(
//           "Failed to humanize stack trace: " + res.statusText + " " + body,
//         );
//       })
//       .then((body) => {
//         if (body.status === 500) {
//           // sorry, the response code is 200 but the body contains status 500
//           throw new Error("Failed to humanize stack trace: " + body.message);
//         }

//         setLoading(false);
//         setHumanizedResult(body);
//       })
//       .catch((err) => {
//         console.error("Autofix error", err);
//         setLoading(false);
//         enqueueSnackbar("Cannot autofix this error", {
//           variant: "error",
//         });
//         setHumanizedResult(null);
//       });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [useSourceMaps, stack, workspaceSourcemaps]);

//   if (!useSourceMaps) {
//     return (
//       <TableRow>
//         <TableCell>
//           <CodeContainer code={stack} />
//         </TableCell>
//       </TableRow>
//     );
//   }

//   if (useSourceMaps && loading) {
//     return (
//       <TableRow>
//         <TableCell>
//           <Box mt={2} mb={2}>
//             <Stack direction="row" spacing={1}>
//               <CircularProgress size={20} />
//               <Typography variant="caption">Analyzing code...</Typography>
//             </Stack>
//           </Box>
//         </TableCell>
//       </TableRow>
//     );
//   }

//   if (!humanizedResult) {
//     return (
//       <TableRow>
//         <TableCell>
//           <Box mt={2} mb={2}>
//             <Stack direction="row" spacing={1}>
//               <Alert severity="error">
//                 Sorry, we couldn&apos;t autofix this error. We&apos;ll look into
//                 this as soon as possible (E_AF_001).
//               </Alert>
//             </Stack>
//           </Box>
//         </TableCell>
//       </TableRow>
//     );
//   }

//   const errorLineIndex = humanizedResult.errorLineIndex;
//   const lines = humanizedResult.mappedStack?.split("\n") ?? [];

//   if (!errorLineIndex || !lines.length) {
//     return (
//       <TableRow>
//         <TableCell>
//           <Box mt={2} mb={2}>
//             <Stack direction="row" spacing={1}>
//               <Alert severity="error">
//                 Sorry, we couldn&apos;t autofix this error. We&apos;ll look into
//                 this as soon as possible (E_AF_002).
//               </Alert>
//             </Stack>
//           </Box>
//         </TableCell>
//       </TableRow>
//     );
//   }

//   const sourceView = (
//     <CodeContainer language="javascript" code={humanizedResult.source} />
//   );

//   const frameItems = lines.map((lineString, i) => {
//     // get the file name, row and line number
//     // then call smc.originalPositionFor and replace the stack trace item with
//     // the original position

//     if (useSourceMaps) {
//       // only the first line is source mapped for now
//       return <RowWithSourceMap key={i} lineIndex={i} lineString={lineString} />;
//     }

//     return (
//       <TableRow key={i}>
//         <TableCell
//           sx={{
//             opacity: useSourceMaps ? 0.5 : 1,
//           }}
//         >
//           {lineString}
//         </TableCell>
//       </TableRow>
//     );
//   });

//   if (useSourceMaps) {
//     return (
//       <>
//         <TableRow key={`sv`}>{sourceView}</TableRow>

//         <TableRow>
//           <TableCell>
//             <Button
//               variant="outlined"
//               size="small"
//               startIcon={
//                 showStackTrace ? (
//                   <ExpandLess fontSize="small" />
//                 ) : (
//                   <ExpandMore fontSize="small" />
//                 )
//               }
//               onClick={() => {
//                 setShowStackTrace(!showStackTrace);
//               }}
//             >
//               {showStackTrace ? "Hide" : "Show"} full stack trace
//             </Button>
//           </TableCell>
//         </TableRow>

//         {showStackTrace ? frameItems : false}

//         {currentWorkspace?.flags?.autofix &&
//           [
//             {
//               title: "What's this error?",
//               field: humanizedResult.humanCauses,
//             },
//             {
//               title: "How can I improve the UX?",
//               field: humanizedResult.humanHotfixes,
//             },
//             {
//               title: "How can you fix it?",
//               field: humanizedResult.humanSolutions,
//             },
//           ].map((item) => (
//             <TableRow key={item.title}>
//               <TableCell>
//                 <Box mt={2}>
//                   <Typography variant="body1" fontWeight="bold" mb={0}>
//                     {item.title}
//                   </Typography>

//                   <List dense>
//                     {item.field?.map((cause, i) => {
//                       return (
//                         <ListItem dense disablePadding key={i}>
//                           <ListItemText
//                             sx={{
//                               // remove margin from all paragraphs
//                               "& p": {
//                                 margin: 0,
//                               },
//                             }}
//                           >
//                             <ReactMarkdown
//                               remarkPlugins={remarkGfm}
//                               components={{
//                                 code({
//                                   node,
//                                   inline,

//                                   children,
//                                   ...props
//                                 }) {
//                                   return !inline ? (
//                                     <CodeContainer
//                                       style={{
//                                         maxHeight: 300,
//                                         overflow: "scroll",
//                                         fontSize: 11,
//                                       }}
//                                       {...props}
//                                     />
//                                   ) : (
//                                     <code {...props}>{children}</code>
//                                   );
//                                 },
//                               }}
//                             >
//                               {cause}
//                             </ReactMarkdown>
//                           </ListItemText>
//                         </ListItem>
//                       );
//                     })}
//                   </List>
//                 </Box>
//               </TableCell>
//             </TableRow>
//           ))}

//         <TableRow>
//           <Tooltip title="Coming soon" arrow>
//             <Button
//               variant="outlined"
//               startIcon={<Healing fontSize="small" />}
//               onClick={() => {
//                 alert("Coming soon");
//               }}
//             >
//               Open Github PR
//             </Button>
//           </Tooltip>
//         </TableRow>
//       </>
//     );
//   }

//   return frameItems;
// };

export const RenderError = ({ value, wrapLines, compact }) => {
  // Example value:
  // {
  //   "isErrorEvent": false,
  //   "errorEventError": "[null]",
  //   "name": "TypeError",
  //   "message": "Cannot read properties of undefined (reading 'timestamp')",
  //   "stack": "TypeError: Cannot read properties of undefined (reading 'timestamp')\n    at Object.U9 [as comparator] (https://dash.bugpilot.io/static/js/main.5cea19f4.js:2:3361576)\n    at https://dash.bugpilot.io/static/js/main.5cea19f4.js:2:2423694\nl...",
  //   "value": "TypeError: Cannot read properties of undefined (reading 'timestamp')"
  // }

  const { currentWorkspace } = useAppContext();
  const { trackEvent } = useAnalytics();

  const [loading, setLoading] = useState(false);
  const [showSourceMaps, setShowSourceMaps] = useState(false);

  const onAutofixClick = useCallback(() => {
    trackEvent("Clicked Autofix button");

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1.5 * 1000);

    setShowSourceMaps(true);
  }, []);

  let stack;

  if (value.isErrorEvent) {
    stack = value.errorEventError.stack;
  } else {
    stack = value.stack;
  }

  if (!stack) {
    // error does not have a stack trace
    return (
      <Typography
        variant="code"
        className="error-without-stack"
        noWrap={!wrapLines}
      >
        {value?.value || JSON.stringify(value)}
      </Typography>
    );
  }

  if (compact) {
    return (
      <Typography variant="code" color="error" noWrap={!wrapLines}>
        {value?.value}
      </Typography>
    );
  }

  return (
    <Stack direction="column" alignItems="flex-start" spacing={1}>
      {/* <StackTrace stack={stack} useSourceMaps={showSourceMaps} /> */}

      <CodeContainer code={stack} />

      {/* {!showSourceMaps && currentWorkspace?.flags?.autofix && (
        <Tooltip
          title="Explain possible root causes of the bug and recommend a code fix. This is a feature in technical preview."
          arrow
        >
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={onAutofixClick}
            disabled={loading}
            startIcon={<AutoFixHigh fontSize="small" />}
          >
            Autofix
          </Button>
        </Tooltip>
      )} */}

      {/* {!currentWorkspace?.flags?.autofix && <JoinAutofixPreviewButton />} */}
    </Stack>
  );
};

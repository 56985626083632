import React from "react";
import { useCallback, useRef, useState } from "react";
import { convertToRaw } from "draft-js";
import stateToMarkdown from "../../../lib/draft-js-export-markdown/index.ts";
import { Button, IconButton, Paper, Stack, Box } from "@mui/material";
import { SendOutlined as SendIcon } from "@mui/icons-material";
import Editor from "./Editor";
import { useAnalytics } from "../../../analytics/AnalyticsContext";
// import { CannedBotRequests } from "./CannedBotRequests.tsx";
import { CommentInput, EditorRef } from "./types";

type Props = {
  onChange: (comment: CommentInput) => void;
  rawContentState?: string;
  onCancel: () => void;
  companyMembers: any[];
  reportId: string;
  onReply: (comment: CommentInput) => void;
};

export const CommentInputComponentWithoutRef = (
  {
    onChange,
    rawContentState,
    onCancel,
    companyMembers,
    reportId,
    onReply,
  }: Props,
  ref: React.ForwardedRef<EditorRef>
) => {
  const defaultRef = useRef<EditorRef>();

  const [editorState, setEditorState] = useState();

  const handleUpdate = useCallback(() => {
    const contentState = (
      ref || defaultRef
    ).current.editorState.getCurrentContent();

    let markdown = stateToMarkdown(contentState, {
      gfm: true,
    });

    // eslint-disable-next-line no-irregular-whitespace
    const parts = markdown.trim().split(/[\r\n​]/g);
    const firstIndex = parts.findIndex((p) => p.trim().length);
    const lastIndex = parts.findLastIndex((p) => p.trim().length);
    markdown = parts.slice(firstIndex, lastIndex + 1).join("\n");

    return onChange({
      markdown,
      contentState: JSON.stringify(convertToRaw(contentState)),
    });
  }, [defaultRef, ref, onChange]);

  const handleEditorStateChange = useCallback((editorState) => {
    setEditorState(editorState);
  }, []);

  const { trackEvent } = useAnalytics();
  const handleFormattingClick = useCallback(() => {
    trackEvent("Comment editor formatting click");
  }, [trackEvent]);

  const buttonsDisabled = (() => {
    if (!editorState) {
      return true;
    }

    const hasText = editorState?.getCurrentContent?.()?.hasText?.();
    return !hasText;
  })();

  return (
    <Stack
      spacing={1}
      sx={{
        display: "flex",
        width: "100%",
        flexGrow: 1,
      }}
    >
      {/* <Box>
        <CannedBotRequests reportId={reportId} />
      </Box> */}

      <Paper
        sx={{
          position: "relative",
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        <Editor
          rawContentState={rawContentState}
          companyMembers={companyMembers}
          reportId={reportId}
          ref={ref || defaultRef}
          onChange={handleEditorStateChange}
          onSave={handleUpdate}
          onFormattingClick={handleFormattingClick}
          placeholder="Type your message and send with ⌘/Ctrl + ⏎"
        />

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            pr: 1,
            pb: 1,
          }}
        >
          {rawContentState ? (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={handleUpdate}
              disabled={buttonsDisabled}
            >
              Update
            </Button>
          ) : (
            <IconButton
              size="small"
              color="secondary"
              onClick={handleUpdate}
              disabled={buttonsDisabled}
              sx={{ mb: 0.5 }}
            >
              <SendIcon fontSize="small" />
            </IconButton>
          )}

          {rawContentState && (
            <Button size="small" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Box>
      </Paper>
    </Stack>
  );
};

import {
  Alert,
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAppContext } from "../../contexts/AppContext";
import CopyToClipboardBase from "../../components/shared/CopyToClipboardBase";
import {
  ChangeCircleOutlined as ChangeCircleIcon,
  CheckOutlined as CheckIcon,
  CircleOutlined as CircleIcon,
  ContentCopyOutlined as ContentCopyIcon,
  OpenInNewOutlined as OpenInNewIcon,
  RemoveRedEyeOutlined as RemoveRedEyeIcon,
} from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import CodeContainer from "../../components/CodeContainer.tsx";
import { useFormik } from "formik";
import { SettingsSection } from "../Settings/SettingsSection.tsx";
import { useSnackbar } from "notistack";
import { createPortal } from "react-dom";

export const StatusPageSetupPage = () => {
  const { currentWorkspace, scriptIsSetup } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const pageLink = useMemo(
    () => `https://statuspage.fyi/status/${currentWorkspace.id}`,
    [currentWorkspace?.id]
  );

  const pageEmbed = useMemo(
    () =>
      `<iframe src="https://statuspage.fyi/status/${currentWorkspace.id}/badge?align=left" width="100px" height="40px" frameborder="0" style="background-color: transparent;"></iframe>`,
    [currentWorkspace?.id]
  );

  const updateStatus = useCallback(
    async ({ status, statusText, bannerText }) => {
      const workspaceId = currentWorkspace?.id;
      const secret = currentWorkspace?.statusPageSecret;

      if (!workspaceId) {
        return;
      }

      if (!secret) {
        alert("Sorry, your workspace is not configured for this feature yet.");
        return;
      }

      const url = "https://www.statuspage.fyi/edit";
      const body = {
        workspaceId: workspaceId,
        status: status,
        statusText: statusText || "",
        secret: secret,
        bannerText: bannerText || null,
      };

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const data = await res.json();
      if (!data.ok) {
        console.error("Error updating status", data);
        throw new Error("Error " + data.err);
      }
    },
    [currentWorkspace?.id, currentWorkspace?.statusPageSecret]
  );

  const [saving, setSaving] = useState(false);
  const formik = useFormik({
    initialValues: {
      status: currentWorkspace?.status || "operational",
      statusText: currentWorkspace?.statusText || "",
      bannerText: currentWorkspace?.bannerText || "",
    },
    onSubmit: async (values) => {
      setSaving(true);

      try {
        await updateStatus(values);

        enqueueSnackbar("Status Page updated", {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar("Cannot update Status Page: " + e.message, {
          variant: "error",
        });
      } finally {
        setSaving(false);
      }
    },
  });

  useEffect(() => {
    const getData = async () => {
      // Fetch data from the GET endpoint, /edit?workspaceId=2fa,
      // and populate the form

      const workspaceId = currentWorkspace?.id;

      if (!workspaceId) {
        return;
      }

      const url = `https://www.statuspage.fyi/edit?workspaceId=${workspaceId}`;

      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await res.json();
      if (!data.ok) {
        return;
      }

      formik.setValues({
        status: data.status.status,
        statusText: data.status.statusText,
        bannerText: data.status.bannerText,
      });
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkspace?.id]);

  const topBarBreadcrumbsElement = document.getElementById(
    "top-bar-breadcrumbs"
  );

  const handleBannerPreviewClick = useCallback(() => {
    const bannerText = formik.values.bannerText;

    // @ts-ignore
    window.Bugpilot?.showBanner({
      text: bannerText,
      type: "error",
    });
  }, [formik.values.bannerText]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {topBarBreadcrumbsElement &&
        createPortal(
          <Stack direction="row" alignItems="center">
            <Button
              startIcon={
                <OpenInNewIcon
                  sx={{
                    fontSize: 10,
                  }}
                />
              }
              variant="outlined"
              color="tertiary"
              onClick={() => {
                window.open(pageLink, "_blank");
              }}
              sx={{
                fontSize: "0.857em",
                height: 37,
              }}
            >
              Open Status Page
            </Button>
          </Stack>,
          topBarBreadcrumbsElement
        )}

      <Stack gap={4} maxWidth="md">
        {/* Setup form */}
        <SettingsSection
          id="status-page-setup"
          title="Publish your app status"
          description={
            <>
              <p style={{ margin: 0 }}>
                A status page is a great way to keep your users updated about
                the status of your app. You can use this page to share updates
                about any issues you are experiencing, and let your users know
                when you expect to resolve them. Status pages can help reduce
                the volume of support requests you receive, and improve your
                overall users&apos; experience.
              </p>
              <p>Select the status of your app from the dropdown below.</p>
            </>
          }
          buttons={[
            <Button
              key="publish"
              disabled={saving}
              startIcon={<ChangeCircleIcon />}
              color="error"
              variant={formik.dirty ? "contained" : "outlined"}
              onClick={() => formik.submitForm()}
            >
              Update Status
            </Button>,
          ]}
          leftButtons={[
            <Typography key="icon" variant="body1" color="muted.dark">
              Included in all plans.
            </Typography>,
          ]}
        >
          <Select {...formik.getFieldProps("status")} size="small" fullWidth>
            <MenuItem value="good">
              <Stack direction="row" alignItems="center">
                <CircleIcon
                  sx={{ fontSize: 10, mr: 1, color: "success.main" }}
                />{" "}
                <Typography fontWeight="bold">Operational </Typography>
                <Typography pl={0.5}>
                  &ndash; Your app is working as expected.
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem value="bad">
              <Stack direction="row" alignItems="center">
                <CircleIcon sx={{ fontSize: 10, mr: 1, color: "error.main" }} />
                <Typography fontWeight="bold">Degraded </Typography>
                <Typography pl={0.5}>
                  &ndash; Some functionality is not working as expected.
                </Typography>
              </Stack>
            </MenuItem>
          </Select>
        </SettingsSection>

        <SettingsSection
          id="status-page-custom-text"
          title="Custom Page Text"
          description="Optionally set a custom text to show on your status page. This is a great place to share more details about the status of your app, the issues you are experiencing, or the steps you are taking to resolve them. It's good practice to include an ETA for when you expect to share further updates. You can use markdown to format your message."
          buttons={[
            <Button
              key="publish"
              disabled={saving}
              startIcon={<CheckIcon />}
              color="secondary"
              variant={formik.dirty ? "contained" : "outlined"}
              onClick={() => formik.submitForm()}
            >
              Save
            </Button>,
          ]}
        >
          <TextField
            {...formik.getFieldProps("statusText")}
            size="small"
            multiline
            sx={{
              width: "100%",
            }}
          />
        </SettingsSection>

        <SettingsSection
          id="status-page-banner"
          title="Alert Banner Text"
          description="For critical bugs, you might want to show a prominent banner on your app. Set the banner text here. Once the text is set, the banner will start showing on your app. The Bugpilot tracking code must be installed for the banner to be displayed."
          leftButtons={[
            <Typography key="icon" variant="body1" color="muted.dark">
              Changes will take up to 5 minutes to take effect.
            </Typography>,
          ]}
          buttons={[
            // Preview Button
            <Button
              key="preview"
              disabled={saving}
              startIcon={<RemoveRedEyeIcon />}
              color="secondary"
              variant="outlined"
              onClick={handleBannerPreviewClick}
            >
              Preview
            </Button>,

            // Save Button
            <Tooltip title="Coming soon!" arrow key="publish">
              <Button
                disabled={saving}
                startIcon={<ChangeCircleIcon />}
                color="error"
                variant={formik.dirty ? "contained" : "outlined"}
                onClick={() => formik.submitForm()}
              >
                <span>Update banner</span>
              </Button>
            </Tooltip>,
          ]}
        >
          <TextField
            {...formik.getFieldProps("bannerText")}
            size="small"
            multiline
            sx={{
              width: "100%",
            }}
          />

          <Box mt={2}>
            {scriptIsSetup ? (
              <Typography variant="body1">
                The banner will be displayed on every page where you added the
                Bugpilot tracking code.
              </Typography>
            ) : (
              <Alert severity="error">
                The alert banner requires the Bugpilot tracking code to be
                installed on your app. Please install the tracking code to
                enable this feature.
              </Alert>
            )}
          </Box>
        </SettingsSection>

        {/* Page URL */}
        <SettingsSection
          id="status-page-url"
          title="Link to your status page"
          description="Share this link with your users to keep them updated. If you want a vanity URL, you can set up a redirect from your app, or include this address inside an iframe."
          buttons={[
            <CopyToClipboardBase key="copy" text={pageLink}>
              {({ copy }) => (
                <Button
                  onClick={copy}
                  startIcon={<ContentCopyIcon />}
                  variant="outlined"
                  color="secondary"
                >
                  Copy link
                </Button>
              )}
            </CopyToClipboardBase>,
            // Open
            <Button
              variant="outlined"
              color="secondary"
              key="open"
              startIcon={<OpenInNewIcon />}
              onClick={() => {
                window.open(pageLink, "_blank");
              }}
            >
              Open
            </Button>,
          ]}
        >
          <Typography
            variant="body1"
            sx={{
              color: "primary.accent",
              textDecoration: "underline",
              textUnderlineOffset: "2px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(pageLink, "_blank");
            }}
          >
            {pageLink}
          </Typography>
        </SettingsSection>

        {/* Widget HTML Embed */}
        <SettingsSection
          id="status-page-embed"
          title="Embed a status widget"
          description="Embed a status widget on your website to keep your users updated without leaving your app. Users can click on the widget to open your status page. The widget has a transparent background, so you can place it anywhere on your page."
          leftButtons={[]}
        >
          <Stack direction="row" spacing={1} alignItems="center" mb={2}>
            <Typography fontWeight="bold" pb={1}>
              Preview:
            </Typography>
            <div
              key="preview"
              dangerouslySetInnerHTML={{ __html: pageEmbed }}
            />
          </Stack>

          <Paper sx={{ p: 2 }}>
            <CodeContainer code={pageEmbed}>{pageEmbed}</CodeContainer>
          </Paper>
        </SettingsSection>
      </Stack>
    </form>
  );
};

import { useNavigate } from "react-router-dom";

import { Box, Typography, Stack, Button, Paper } from "@mui/material";
import {
  ArrowRightAltOutlined,
  CodeOutlined as CodeOutlinedIcon,
  FavoriteOutlined as FavoriteIcon,
  PendingOutlined,
  ReportProblemOutlined as ReportProblemOutlinedIcon,
} from "@mui/icons-material";

const steps = [
  {
    title: "Identify your users",
    description:
      "Bugs are easier to fix when you know who is affected. Learn how to identify your users.",
    link: "/settings/code#identify-users",
  },
  {
    title: "Enable notifications",
    description:
      "Receive email or push notifications when critical issues are detected.",
    link: "/settings/integrations/email",
  },
  {
    title: "Customize the Widget",
    description:
      "Customize the look and feel of the Bugpilot widget to match your app.",
    link: "/widget",
  },
  {
    title: "Configure Source Maps",
    description:
      "Learn how to get readable JS stack traces in your Bugpilot reports.",
    link: "/settings/integrations/custom",
  },
  {
    title: "Check out our Integrations",
    description:
      "Receive Bugpilot reports in your favourite tools, configure notifications, and more.",
    link: "/settings/integrations",
  },
  {
    title: "Invite your team",
    description: "Invite your team members to your Bugpilot account.",
    link: "/settings/team",
  },
];

export const StepDone = () => {
  const navigate = useNavigate();

  return (
    <Stack spacing={3}>
      <Paper>
        <Box
          p={4}
          sx={{
            width: "100%",
          }}
        >
          <Stack spacing={3}>
            <FavoriteIcon
              sx={{
                fontSize: "5em",
                color: "error.main",
              }}
            />

            <Typography variant="h5">Welcome to Bugpilot!</Typography>

            <Typography variant="body1" color="secondary">
              Once you add the tracking code to your app, Bugpilot will start
              working.
              <br />
              Sit back, relax, and wait for your first automated bug report. You
              will soon start receiving bug reports in the New Issues tab.
            </Typography>

            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/issues");
                }}
                href="/issues"
                startIcon={<ReportProblemOutlinedIcon />}
              >
                Go to New Issues
              </Button>
              <Button
                variant="text"
                color="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/settings/code");
                }}
                href="/settings/code"
                startIcon={<CodeOutlinedIcon />}
              >
                Get the tracking code
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Paper>

      <Paper>
        <Box
          p={4}
          sx={{
            width: "100%",
          }}
        >
          <Typography variant="h4" mb={4}>
            Next steps
          </Typography>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: 4,
            }}
          >
            {steps.map(({ title, description, link }, i) => (
              <Box
                key={title}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  flexBasis: "30%",
                  flexGrow: 0,
                  flexShrink: 1,
                  gap: 1,
                  cursor: "pointer",
                  height: "100%",
                  justifyContent: "flex-start",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(link);
                }}
              >
                <Typography
                  variant="body1"
                  color="primary"
                  fontWeight="medium"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PendingOutlined sx={{ mr: 1, color: "muted.main" }} />
                  <span>{title}</span>
                </Typography>

                <Typography variant="body1" color="secondary">
                  {description}
                </Typography>

                <Box flexGrow={1} />

                <Button
                  href={link}
                  size="small"
                  variant="text"
                  color="secondary"
                  endIcon={<ArrowRightAltOutlined />}
                  sx={{
                    alignSelf: "flex-end",
                  }}
                >
                  Complete step {i + 1}
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      </Paper>
    </Stack>
  );
};

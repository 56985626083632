import { Button, Typography } from "@mui/material";
import {
  OpenInNewOutlined as OpenInNewIcon,
  VerifiedOutlined as VerifiedIcon,
} from "@mui/icons-material";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import CrispIcon from "../icons/logos/CrispIcon";
import IntercomLogo from "../icons/logos/IntercomIcon";
import LiveChatIcon from "../icons/logos/LiveChatIcon";
import HelpScoutIcon from "../icons/logos/HelpScoutIcon";
import FrontIcon from "../icons/logos/FrontIcon";
import { OnboardingFrame } from "../components/OnboardingFrame";
import { TestimonialContent } from "../components/TestimonialContent";
import { LoadingButton } from "@mui/lab";
import { useAnalytics } from "../analytics/AnalyticsContext";

const providers = {
  intercom: {
    name: "Intercom",
    icon: IntercomLogo,
  },
  livechat: {
    name: "LiveChat",
    icon: LiveChatIcon,
  },
  zendesk: {
    name: "Zendesk",
    icon: (props) => (
      <img
        src="https://imagedelivery.net/55PXnfNaLA7qnpEnq-80ug/33e66429-f5c3-4abd-89bb-5e5c4ac36100/original"
        alt="zendesk support icon"
        {...props}
      />
    ),
  },
  helpscout: {
    name: "HelpScout",
    icon: HelpScoutIcon,
  },
  front: {
    name: "Front",
    icon: FrontIcon,
  },
  crisp: {
    name: "Crisp",
    icon: CrispIcon,
  },
};

const OfferAuthenticationPage = () => {
  const location = useLocation();
  const { trackEvent } = useAnalytics();

  const [loading, setLoading] = useState(false);

  const [provider, connectedWorkspace] = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const _provider = searchParams.get("provider") || "generic";
    const _connectedWorkspace = searchParams.get("connectedWorkspace") || null;
    return [_provider, _connectedWorkspace];
  }, [location.search]);

  const goToSignUp = useCallback(() => {
    setLoading(true);
    window.open(`/signup${location.search}`, "_blank");

    setTimeout(() => {
      setLoading(false);
    }, 3 * 1000);
  }, []);

  const goToDashboard = useCallback(() => {
    setLoading(true);
    window.open("https://dash.bugpilot.io/?from=interstitial", "_blank");
  }, []);

  const goToWebsite = useCallback(() => {
    window.open(
      "https://www.bugpilot.io/?utm_source=interstitial&utm_campaign=whatsbp-button",
      "_blank"
    );
  }, []);

  const providerName = useMemo(
    () => providers[provider]?.name || "",
    [provider]
  );

  const cmsContentId = useMemo(() => {
    if (connectedWorkspace) {
      return `interstitial.${provider}.connected.1`;
    }

    return `interstitial.${provider}.1`;
  }, [connectedWorkspace, provider]);

  useLayoutEffect(() => {
    trackEvent("Signup Interstitial", {
      provider,
      connectedWorkspace: !!connectedWorkspace,
    });
  }, [connectedWorkspace, provider, trackEvent]);

  return (
    <OnboardingFrame
      LeftComponent={() => <TestimonialContent contentId={cmsContentId} />}
    >
      {connectedWorkspace ? (
        <>
          <VerifiedIcon color="info" fontSize="large" />

          <Typography variant="h2">
            {providerName ? `${providerName} is` : `You're`} connected to
            Bugpilot
          </Typography>

          <Typography variant="body1">
            Congrats! You have successfully connected {providerName} with
            Bugpilot. Go to your Bugpilot Workspace for next steps:
          </Typography>

          <LoadingButton
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            loading={loading}
            onClick={goToDashboard}
            startIcon={<OpenInNewIcon fontSize="small" />}
          >
            Open Workspace
          </LoadingButton>
        </>
      ) : (
        <>
          <Typography variant="h2">
            You did it &mdash; just one last step
          </Typography>

          <Typography variant="body1">
            To connect {providerName} with your Bugpilot workspace, you need to
            create an account.
          </Typography>

          <LoadingButton
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            loading={loading}
            onClick={goToSignUp}
            startIcon={<OpenInNewIcon fontSize="small" />}
          >
            Create an account
          </LoadingButton>

          <Button
            size="large"
            color="secondary"
            variant="text"
            onClick={goToWebsite}
          >
            What&apos;s Bugpilot?
          </Button>
        </>
      )}
    </OnboardingFrame>
  );
};

export default OfferAuthenticationPage;

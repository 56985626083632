import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  DialogActions as MuiDialogActions,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/CloseOutlined";
import { withStyles } from "@mui/styles";

const DialogActions = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(4)}`,
  },
}))(MuiDialogActions);

const SimpleDialog = ({ title, actions, children, ...props }) => (
  <Dialog open maxWidth="lg" fullWidth {...props}>
    <DialogTitle>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {title}
        <div>
          <IconButton size="small" onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </Stack>
    </DialogTitle>
    <DialogContent>{children}</DialogContent>
    {actions && <DialogActions sx={{ pl: 2, pr: 2 }}>{actions}</DialogActions>}
  </Dialog>
);

export default SimpleDialog;

import React, { useState, useContext, createContext, useEffect } from "react";
import {
  AutopilotContextType,
  AutopilotData,
  AutopilotFailureResponse,
  AutopilotProviderProps,
} from "./types";
import { getReportAutopilot } from "../../../api";

const AutopilotContext = createContext<AutopilotContextType>({
  data: null,
  error: null,
  loading: true,
});

export const AutopilotProvider: React.FC<AutopilotProviderProps> = ({
  report,
  children,
  variant,
}) => {
  const [data, setData] = useState<AutopilotData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);

    if (!report?.id) {
      return;
    }

    const loadData = async () => {
      // fetch data from AUTOPILOT_API_URL
      // set data to state

      const _data = await getReportAutopilot(
        { reportId: report.id },
        { withAuth: variant === "full" }
      ).catch((e) => ({
        ok: false,
        message: e.message,
      }));

      if (_data.ok === false) {
        const errorResponse = _data as AutopilotFailureResponse;
        setData(null);
        setError(errorResponse.message);
        setLoading(false);

        return;
      }

      setData(_data as AutopilotData);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    };

    loadData();
  }, [report.id]);

  return (
    <AutopilotContext.Provider value={{ data, error, loading }}>
      {children}
    </AutopilotContext.Provider>
  );
};

export const useAutopilot = () => useContext(AutopilotContext);

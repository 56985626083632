// Create a React context for analytics

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { debounce, noop } from "lodash";
import { useSnackbar } from "notistack";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { AnalyticsBrowser } from "@june-so/analytics-next";

const juneMock = {
  page() {},
  identify() {},
  group() {},
  track() {},
};

const AnalyticsContext = createContext({
  identifyUser: noop,
  identifyCompany: noop,
  updateCompany: noop,
  updateUser: noop,
  trackPage: noop,
  trackEvent: noop,
  june: juneMock,
});

let oldHref = document.location.href;

export const AnalyticsProvider = ({ children, ...args }) => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [currentCompanyId, setCurrentCompanyId] = useState(null);
  const june = useRef(juneMock);

  useEffect(() => {
    june.current = AnalyticsBrowser.load({
      writeKey: "K4xLeqVpoJxGS5tf",
    });
  }, []);

  const observeURL = useCallback((cb) => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        const nextHref = document.location.href;
        if (oldHref !== nextHref) {
          oldHref = nextHref;
          cb(nextHref);
        }
      });
    });
    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, []);

  const debugEnabled = useMemo(() => {
    // TEST: window.localStorage.setItem("Bugpilot::debugAnalytics", "true");
    return window.localStorage.getItem("Bugpilot::debugAnalytics") === "true";
  }, []);

  const notify = useCallback(
    (message) => {
      if (!debugEnabled) {
        return;
      }

      enqueueSnackbar(message, { variant: "info", autoHideDuration: 750 });
    },
    [debugEnabled, enqueueSnackbar]
  );

  useEffect(() => {
    if (!debugEnabled) {
      return;
    }

    enqueueSnackbar(`Navigate: ${location.pathname}`, {
      variant: "warning",
      autoHideDuration: 1000,
    });
    console.warn("Location change:", location);
  }, [location, debugEnabled, enqueueSnackbar]);

  const identifyUser = useCallback(
    (user, extra = {}) => {
      if (!user) {
        return;
      }

      notify("Identifying user");

      window.Bugpilot?.identify?.({
        id: user.sub,
        email: user.email,
        firstName: user.attributes?.given_name,
        lastName: user.attributes?.family_name,
      });
    },
    [notify]
  );

  const updateUser = useCallback(
    (userId, traits) => {
      if (!userId) {
        return;
      }

      notify("Updating user");
    },
    [notify]
  );

  const identifyCompany = useCallback(
    (workspace) => {
      const { id, name, planId, createdAt } = workspace || {};

      if (!id) {
        return;
      }

      notify("Identifying company");

      setCurrentCompanyId(id);
    },
    [notify]
  );

  const updateCompany = useCallback(
    (companyId, traits) => {
      if (!companyId) {
        return;
      }

      notify("Updating company");
    },
    [notify]
  );

  const trackEvent = useCallback(
    (name, properties) => {
      if (!name) {
        throw new Error("trackEvent requires a name");
      }

      notify(`Tracking event: ${name}`);
    },
    [notify, currentCompanyId]
  );

  const trackPage = useCallback(
    (name) => {
      if (!name) {
        throw new Error("trackPage requires a name");
      }

      june.current?.page("name");

      notify(`Tracking page: ${name}`);
    },
    [notify]
  );

  // automatically track page views
  useEffect(() => {
    const onLocationChange = () => {
      const path = window.location.pathname;

      if (path.startsWith("/report")) {
        trackPage("/report");
        return;
      }

      trackPage(path);
    };

    const debouncedHandler = debounce(onLocationChange, 1000);
    const unListen = observeURL(debouncedHandler);

    setTimeout(() => {
      // wait for the page to load
      debouncedHandler();
    }, 100);

    return () => {
      unListen();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnalyticsContext.Provider
      value={{
        identifyUser,
        updateUser,
        identifyCompany,
        updateCompany,
        trackEvent,
        trackPage,
        june: june.current,
      }}
    >
      {children}

      {debugEnabled && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            width: 600,
            minHeight: 25,
            border: "1px solid red",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            m: 2,
            p: 2,
            pointerEvents: "none",
          }}
        >
          <Typography variant="code">{JSON.stringify(location)}</Typography>
        </Box>
      )}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => useContext(AnalyticsContext);

import React, { Fragment, useMemo } from "react";
import format from "date-fns/format";
import {
  Avatar,
  IconButton,
  Tooltip,
  Divider,
  Typography,
  Chip,
  Stack,
  TextField,
  MenuItem,
  Grid,
} from "@mui/material";

import {
  DeleteOutlined as DeleteIcon,
  PendingOutlined as PendingIcon,
} from "@mui/icons-material";

import getAvatar from "../utils/getAvatar";
import Help from "../components/Help";

// Sorry, don't want to show the social logins for now
// import GoogleIcon from "@mui/icons-material/Google";
// import GitHubIcon from "../icons/logos/GitHubIcon";
// import GitLabIcon from "../icons/logos/GitLabIcon";
// import BitbucketIcon from "../icons/logos/BitbucketIcon";

// const providersConfig = {
//   google: {
//     name: "Google",
//     icon: GoogleIcon,
//   },
//   github: {
//     name: "GitHub",
//     icon: GitHubIcon,
//   },
//   gitlab: {
//     name: "GitLab",
//     icon: GitLabIcon,
//   },
//   bitbucket: {
//     name: "Bibucket",
//     icon: BitbucketIcon,
//   },
// };

export const TeamMemberRow = ({
  user,
  member,
  handleMemberRoleChange,
  isOwner,
  onDeleteCompanyMember,
  divider,
}) => {
  const {
    id,
    invitedAt,
    invitedBy,
    email,
    acceptedOn,
    role,
    roleAssignedBy,
    roleAssignedAt,
    name,
  } = member;

  // const providers = useMemo(
  //   () =>
  //     (member?.providers ?? []).filter((provider) => provider !== "cognito"),
  //   [member?.providers]
  // );

  const userAvatar = useMemo(() => getAvatar(member), [member]);

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="stretch"
    >
      <Avatar src={userAvatar ?? "/avatar.svg"} />

      <Stack spacing={0} sx={{ flexGrow: 1 }}>
        <Typography variant="body1">
          {name} {email === user?.email && <> (you)</>}
        </Typography>

        {/* <Stack spacing={1} direction="row" display="inline-flex"> */}
        <Typography variant="body2" sx={{ color: "text.tertiary" }}>
          {email}
        </Typography>

        {/* {providers.map((provider) => {
                const Icon = providersConfig[provider]?.icon;
                return (
                  <Chip
                    key={provider}
                    size="small"
                    label={providersConfig[provider]?.name}
                    color="secondary"
                    variant="outlined"
                    icon={Icon ? <Icon fontSize="small" /> : null}
                    sx={{ ml: 1 }}
                  />
                );
              })} */}
        {/* </Stack> */}
      </Stack>

      {acceptedOn ? (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography variant="body1" color="text.secondary">
            Active
          </Typography>

          <Help
            title={`This member is active and counts towards your billable seats. Team member joined on ${format(
              new Date(acceptedOn * 1000),
              "PPPppp"
            )}`}
          />
        </Stack>
      ) : (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography variant="body1" color="text.secondary">
            Email invite sent
          </Typography>

          <Help
            title={
              invitedAt &&
              `Invited on ${format(new Date(invitedAt * 1000), "PPPppp")} by ${
                invitedBy.firstName
              } ${invitedBy.lastName} (${invitedBy.email})`
            }
          />
        </Stack>
      )}

      <Stack direction="row" spacing={2} alignItems="center">
        {/* <Tooltip
          open={roleAssignedBy ? undefined : false}
          placement="top"
          title={
            roleAssignedBy ? (
              <>
                Role last updated by {roleAssignedBy.firstName}{" "}
                {roleAssignedBy.lastName} ({roleAssignedBy.email}) on{" "}
                {format(new Date(roleAssignedAt * 1000), "PPPppp")}{" "}
              </>
            ) : (
              "Role assigned"
            )
          }
          arrow
        > */}
        <TextField
          select
          size="small"
          value={role || "user"}
          onChange={handleMemberRoleChange({ email })}
          disabled={!isOwner || email === user?.email}
          sx={{ width: 120, height: 37 }}
        >
          <MenuItem value="admin">Full Access</MenuItem>
          <MenuItem value="owner">Owner</MenuItem>
          <MenuItem value="user" disabled>
            View Only{" "}
            {role && role !== "user" && (
              <Chip size="small" label="enterprise" />
            )}
          </MenuItem>
        </TextField>
        {/* </Tooltip> */}
      </Stack>

      <Tooltip
        title={
          role === "owner"
            ? "You cannot remove the owner of the workspace"
            : "Remove member"
        }
        arrow
      >
        <IconButton
          size="small"
          edge="end"
          onClick={onDeleteCompanyMember({ email, id })}
          disabled={!isOwner || role === "owner"}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

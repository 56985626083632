import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Value from "./shared/Value";

const StoragePanel = ({ report }) => {
  return (
    <Grid container spacing={2} padding={2} direction="column">
      {report.localStorage && (
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="body1">Local Storage Data</Typography>
            </Grid>
            <Grid item data-test-id="localstorage-container">
              <Value value={report.localStorage} />
            </Grid>
          </Grid>
        </Grid>
      )}

      {report.sessionStorage && (
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="body1">Session Storage Data</Typography>
            </Grid>
            <Grid item data-test-id="sessionstorage-container">
              <Value value={report.sessionStorage} />
            </Grid>
          </Grid>
        </Grid>
      )}

      {report.cookies && (
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="body1">Cookies Data</Typography>
            </Grid>
            <Grid item data-test-id="cookies-container">
              <Value value={report.cookies} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default StoragePanel;

import { Box, Stack, Typography, Paper } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { BedtimeOutlined as BedtimeIcon } from "@mui/icons-material";

import { useAppContext } from "../../contexts/AppContext";
import { useReportsListContext } from "../../contexts/ReportsListContext.tsx";
import useLoading from "../../hooks/useLoading";

export const NoRows_Sessions = ({ offerCreateDemoReport }) => {
  const { createDemoReport } = useAppContext();
  const { requestRefreshReports } = useReportsListContext();

  const { handler: createSampleReportHandler, isLoading } = useLoading(
    async () => {
      await createDemoReport();
      await requestRefreshReports();
    }
  );

  const NoSessionReplays = () => (
    <Stack spacing={2}>
      <BedtimeIcon />

      <Typography variant="h6" gutterBottom>
        It&apos;s a bit quiet here
      </Typography>

      <Typography variant="body1" gutterBottom>
        Make sure you have completed the setup and configured which pages to
        monitor from Settings. You will start seeing Session Replays here as
        some users start using your app.
      </Typography>

      <Stack direction="row" spacing={2}>
        {offerCreateDemoReport && (
          <LoadingButton
            color="secondary"
            variant="outlined"
            onClick={createSampleReportHandler}
            loading={isLoading}
            disabled={isLoading}
            size="small"
          >
            Create sample report
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );

  return (
    <Box
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      height="100%"
      width="100%"
      position="relative"
      zIndex={10}
    >
      <Paper sx={{ padding: 4, maxWidth: "sm" }}>
        <NoSessionReplays />
      </Paper>
    </Box>
  );
};

import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";

import AsyncButton from "../AsyncButton";
import Button from "../Button";

const DialogActions = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(4)}`,
  },
}))(MuiDialogActions);

const DialogContent = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(4)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogTitle);

const StyledBackdrop = withStyles({
  root: {
    background:
      "linear-gradient(317.41deg, #F0F5FE 21.54%, rgba(240, 245, 254, 0.8) 103.27%)",
    backdropFilter: "blur(2px)",
  },
})(Backdrop);

const DataProcessingAddendumModal = ({ onClose, onConfirm, user }) => {
  return (
    <Dialog BackdropComponent={StyledBackdrop} maxWidth="md" open fullWidth>
      <DialogTitle>
        <Typography variant="h5" fontWeight="bold">
          Data Processing Addendum
        </Typography>
      </DialogTitle>
      <DialogContent>
        <iframe
          title="Data Processing Addendum"
          src="https://docs.google.com/document/d/e/2PACX-1vT7o5Up9P__mgxk6YQmioSgZq6Y42k7EgNxz_FMURsbnEY2llwEXN0Jz7zOUJHeShxZDD9aGu3aUVU0/pub?embedded=true"
          frameBorder="0"
          width="100%"
          height="500px"
        />
      </DialogContent>
      <DialogActions>
        <Typography variant="body1" flex={1}>
          You are agreeing as <b>{user.email}</b>
        </Typography>
        <AsyncButton bold variant="contained" onClick={onConfirm}>
          I Accept
        </AsyncButton>
        <Button bold onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DataProcessingAddendumModal;

import { useCallback, useState } from "react";

const useLoading = (action, defaultLoading = false) => {
  const [isLoading, setIsLoading] = useState(defaultLoading);
  const [isSuccess, setIsSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handler = useCallback(
    async (...params) => {
      const isSilent = params?.[params.length - 1]?.isSilent;
      if (!isSilent) {
        setIsLoading(true);
      }
      setError(null);

      try {
        let result = null;
        if (typeof action == "function") {
          result = await action(...params);
        }

        setTimeout(() => {
          setIsLoading(false);
          setIsSuccess(true);
        }, 300);
        return result;
      } catch (e) {
        setIsLoading(false);
        setIsSuccess(false);
        setError(e);
      } finally {
        setTimeout(() => {
          setIsSuccess(null);
        }, 3000);
      }
    },
    [action]
  );

  return {
    handler,
    isLoading,
    isSuccess,
    error,
  };
};

export default useLoading;

import React, { useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import CloseIcon from "@mui/icons-material/CloseOutlined";
import Button from "../Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../Input";
import { getFieldProps } from "../../utils/getFieldProps";
import InputAdornment from "@mui/material/InputAdornment";
import KeyIcon from "../../icons/KeyIcon";

const DialogActions = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(4)}`,
  },
}))(MuiDialogActions);

const DialogContent = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(4)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogTitle);

const validationSchema = Yup.object({
  code: Yup.string()
    .min(6, "The confirmation code must be exactly 6 digits")
    .max(6, "The confirmation code must be exactly 6 digits")
    .required("The confirmation code is mandatory"),
});

const DisableMFAModal = ({ onClose, onConfirm }) => {
  const [error, setError] = useState(false);

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await onConfirm(values);
        setError(null);
        onClose();
      } catch (e) {
        console.error("Error verifying TOTP", e);
        setError(e.message);
      }
    },
  });

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold" color="error">
            Disable Two-Factor Authentication
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="body1">
              Disabling Two-Factor Authentication may expose your account to
              security risks. If you understand the implications and really want
              to proceed, please enter a verification code below.
            </Typography>
            <Typography variant="body1" color="error" mt={2} fontWeight="bold">
              We recommend that you do not disable two-factor authentication.
            </Typography>
          </Grid>
          <Grid item>
            <Input
              fullWidth
              label="Verification code"
              size="small"
              required
              type="text"
              {...getFieldProps(formik, {
                name: "code",
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>{error && <Alert severity="error">{error}</Alert>}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          bold
          disabled={!formik.dirty || formik.isSubmitting}
          onClick={formik.handleSubmit}
          endIcon={formik.isSubmitting && <CircularProgress size={25} />}
          fullWidth
          variant="contained"
          color="error"
        >
          Disable
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisableMFAModal;

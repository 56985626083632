import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { keyBy } from "lodash";
import VisibilitySensor from "react-visibility-sensor";
import {
  Popper,
  Typography,
  Paper,
  ClickAwayListener,
  List,
  ListItemText,
  Box,
  Stack,
  ListItem,
  IconButton,
  Tooltip,
} from "@mui/material";

import CommentText from "../../components/report/Activities/CommentText";
import ChangeLog from "../../components/report/Activities/ChangeLog";
import DateTime from "../../components/DateTime";
import { CompanyMember } from "../../global";
import {
  Comment as CommentType,
  ChangeLog as ChangeLogType,
} from "../../components/report/Activities/types.ts";
import {
  CircleOutlined as CircleIcon,
  DoneOutlined as DoneIcon,
} from "@mui/icons-material";

type Props = {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
  notifications: any[];
  user: any;
  companyMembers: CompanyMember[];
  onRead: (data: { notificationId: string }) => void;
};

type NotificationCardProps = {
  reportSerialNumber: number;
  reportTitle: string;
  item: CommentType | ChangeLogType;
  type: "change-log" | "comment";
  user: CompanyMember;
  onClose?: () => void;
  onClick?: () => void;
  notification: any;
};

export const NotificationCard = ({
  reportSerialNumber,
  reportTitle,
  item,
  user,
  type,
  onClose,
  onClick,
  notification,
}: NotificationCardProps) => {
  const userLabel = useMemo(
    () =>
      [item.author?.firstName, item.author?.lastName]
        .filter((name) => name && name !== "-")
        .join(" ") ||
      item.author?.email ||
      "Autopilot",
    [item.author]
  );

  const isRead = useMemo(
    () => notification.readAt !== null,
    [notification.readAt]
  );

  return (
    <Paper
      sx={{
        width: 500,
        overflow: "hidden",
        cursor: "pointer",
        userSelect: "none",
        background: "rgba(255, 255, 255, 0.65)",
        backdropFilter: "blur(10px)",
      }}
      onClick={onClick}
      role="button"
    >
      <Box
        // bgcolor="muted.lightish"
        color="muted.contrastText"
        sx={{
          px: 2,
          py: 2,
          pb: 0,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
            height: 30,
            width: "100%",
          }}
        >
          {/* {item?.author ? (
                <MemberAvatar member={item?.author} size={24} />
              ) : null} */}

          {isRead ? null : (
            <CircleIcon
              sx={{
                color: "primary.accent",
                fontSize: 9,
              }}
            />
          )}

          <Typography
            variant="body2"
            sx={{
              color: "text.tertiary",
              fontSize: 12,
              flexGrow: 1,
            }}
          >
            {user ? userLabel : null}
          </Typography>

          <Typography
            variant="body2"
            sx={{
              color: "text.tertiary",
              fontSize: 12,
            }}
          >
            <DateTime
              absolute
              onlyTime
              typographyProps={{
                variant: "body2",
                sx: {
                  color: "text.tertiary",
                  fontSize: 12,
                },
              }}
            >
              {item?.createdAt}
            </DateTime>
          </Typography>

          {onClose && (
            <IconButton size="small" onClick={onClose}>
              <Tooltip title="Mark as read" arrow placement="top-end">
                <DoneIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          )}
        </Stack>
      </Box>

      <Stack
        spacing={1}
        sx={{
          p: 2,
          pt: 1,
          pb: 3,
        }}
      >
        <Typography
          variant="body2"
          noWrap
          sx={{
            // on hover, make underlined
            "&:hover": {
              textDecoration: "underline",
              textUnderlineOffset: 2,
              // underline is dotted
              textDecorationStyle: "dotted",
            },
          }}
        >
          [#{reportSerialNumber}] {reportTitle}
        </Typography>

        {type === "change-log" && (
          <Typography variant="body2" color="text.primary">
            <ChangeLog user={user} changeLog={item} textOnly />
          </Typography>
        )}

        {type === "comment" && (
          <Typography variant="body2" color="text.primary" component="div">
            <CommentText>{item.markdown}</CommentText>
          </Typography>
        )}
      </Stack>
    </Paper>
  );
};

const ChangeLogNotification = ({ notification, user, onClose, navigate }) => {
  const changeLog = notification.data;

  const handleClick = useCallback(() => {
    onClose();
    navigate(`/report/${notification.data.reportId}`);
  }, [onClose, navigate, notification.data.reportId]);

  return (
    <ListItem>
      <NotificationCard
        reportSerialNumber={changeLog.reportSerialNumber}
        reportTitle={changeLog.reportTitle}
        item={changeLog}
        user={user}
        type="change-log"
        onClick={handleClick}
        notification={notification}
      />
    </ListItem>
  );
};

const CommentNotification = ({ notification, user, onClose, navigate }) => {
  const comment = notification.data;

  const handleClick = useCallback(() => {
    onClose();
    navigate(`/report/${notification.data.reportId}`);
  }, [onClose, navigate, notification.data.reportId]);

  return (
    <ListItem>
      <NotificationCard
        reportSerialNumber={comment.reportSerialNumber}
        reportTitle={comment.reportTitle}
        item={comment}
        user={comment.author}
        type="comment"
        onClick={handleClick}
        notification={notification}
      />
    </ListItem>
  );
};

const NotificationsComponentMap = {
  "change-log": ChangeLogNotification,
  comment: CommentNotification,
};

export const NotificationsDrawer = ({
  anchorEl,
  open,
  onClose,
  notifications = [],
  companyMembers = [],
  onRead,
  user,
}: Props) => {
  const companyMembersById = useMemo(
    () => keyBy(companyMembers, "id"),
    [companyMembers]
  );

  const [isShowAll, setIsShowAll] = useState(true);

  const openTimestamp = useMemo(() => Date.now(), [open]);

  const notificationsWithUser = useMemo(
    () =>
      notifications
        .filter(({ readAt }) => isShowAll || !readAt || readAt > openTimestamp)
        .map((notification) => ({
          ...notification,
          data: {
            ...notification.data,
            ...(notification.data.type === "comment" && {
              author: companyMembersById[notification.data.authorId],
            }),
            ...(notification.data.type === "change-log" && {
              author: notification.data.createdBy
                ? companyMembersById[notification.data.createdBy.sub]
                : null,
            }),
          },
        })),
    [notifications, isShowAll, openTimestamp, companyMembersById]
  );

  const navigate = useNavigate();

  return (
    <Popper
      anchorEl={anchorEl}
      open={open}
      sx={{ zIndex: 1000 }}
      popperOptions={{
        placement: "bottom-end",
      }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper
          elevation={4}
          sx={({ breakpoints }) => ({
            width: breakpoints.values.sm - 100,
            mt: 2,
            mr: 2,
          })}
        >
          <List
            sx={{
              maxHeight: document.body.clientHeight - 150,
              overflowY: "scroll",
            }}
            // subheader={
            //   <ListSubheader sx={{ pt: 2, pb: 1, width: "100%" }}>
            //     <Stack
            //       spacing={2}
            //       direction="row"
            //       alignItems="center"
            //       justifyContent="flex-start"
            //       sx={{
            //         width: "100%",
            //       }}
            //       id="workspace-notifications"
            //     >
            //       <ToggleButtonGroup
            //         size="small"
            //         value={isShowAll}
            //         onChange={(_, newValue) =>
            //           newValue !== null && setIsShowAll(newValue)
            //         }
            //         exclusive
            //       >
            //         <ToggleButton value={false}>Unread</ToggleButton>
            //         <ToggleButton value={true}>All</ToggleButton>
            //       </ToggleButtonGroup>
            //     </Stack>
            //   </ListSubheader>
            // }
          >
            {notificationsWithUser.length === 0 && (
              <ListItem>
                <ListItemText
                  primary="You're all caught up!"
                  secondary="There's no new activity that requires your attention."
                />
              </ListItem>
            )}

            {notificationsWithUser.map((notification) => {
              const Component =
                NotificationsComponentMap[notification.data.type];

              if (!Component) {
                return null;
              }

              return (
                <VisibilitySensor
                  active={!notification.readAt}
                  intervalDelay={2000}
                  scrollDelay={2000}
                  key={notification.data.id}
                  onChange={(isVisible) => {
                    if (!isVisible || notification.readAt !== null) {
                      return;
                    }

                    setTimeout(() => {
                      onRead({ notificationId: notification.data.id });
                    }, 2000);
                  }}
                >
                  <Box>
                    <Component
                      onClose={onClose}
                      notification={notification}
                      user={user}
                      navigate={navigate}
                    />
                  </Box>
                </VisibilitySensor>
              );
            })}
          </List>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

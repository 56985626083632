import { SvgIcon } from "@mui/material";

const LiveChatIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
      <g>
        <path fill="#ff5100" d="M0 0h80v80H0z" />
        <path
          d="M59.75 46.85a9.44 9.44 0 0 1-9.5 8.65H45L35 62v-6.5L45 49h5.25a3 3 0 0 0 3-2.65 107.28 107.28 0 0 0-.1-15 2.79 2.79 0 0 0-2.6-2.55c-3.4-.15-7-.3-10.6-.3s-7.2.1-10.6.35a2.75 2.75 0 0 0-2.6 2.55 115.51 115.51 0 0 0-.1 15 2.92 2.92 0 0 0 3.1 2.6H35v6.5h-5.25a9.41 9.41 0 0 1-9.5-8.65 113.93 113.93 0 0 1 .1-16 9.28 9.28 0 0 1 8.65-8.5c3.5-.25 7.25-.35 11-.35s7.5.1 11 .4a9.28 9.28 0 0 1 8.65 8.5 121.28 121.28 0 0 1 .1 15.95Z"
          fill="#fff"
        />
      </g>
    </SvgIcon>
  );
};

export default LiveChatIcon;

import React from "react";
import { Typography, Button, Stack } from "@mui/material";
import { OpenInNewOutlined as OpenInNewIcon } from "@mui/icons-material";
import IntercomIcon from "../../icons/logos/IntercomIcon";

export const IntercomBotDescription = ({
  company,
  onCompanyChange,
  isDisabled,
}) => (
  <Stack spacing={2} alignItems="flex-start">
    <Stack direction="row" spacing={2}>
      <IntercomIcon />
      <Typography variant="body1" fontWeight="bold">
        Intercom Custom Bot
      </Typography>
    </Stack>

    <Typography variant="body1">
      You can add Bugpilot to an Intercom Custom bot that answers user
      conversations and guides them to report a bug or submit a screen
      recording, without leaving Intercom.
    </Typography>

    <Button
      href="https://bugpilot.notion.site/Intercom-Add-a-Report-Bug-button-to-your-Custom-Bot-c68d7e2353294168a87527a90b838af1"
      target="_blank"
      variant="outlined"
      size="small"
      color="secondary"
      startIcon={<OpenInNewIcon />}
    >
      Documentation
    </Button>
  </Stack>
);

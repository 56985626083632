import React, { useCallback, useState, useEffect } from "react";
import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";

const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const getRegistrationPushSubscription = async () => {
  if (!("serviceWorker" in navigator)) {
    return;
  }

  if (Notification.permission !== "granted") {
    return;
  }

  const registration = await navigator.serviceWorker.getRegistration();

  if (!registration) {
    return null;
  }

  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(
      process.env.REACT_APP_WEB_PUSH_PUBLIC_KEY
    ),
  };

  const pushSubscription = await registration.pushManager.subscribe(
    subscribeOptions
  );

  return pushSubscription;
};

const PushNotificationsIntegration = ({
  isDisabled,
  integrations,
  onDeleteIntegration,
  onCreateIntegration,
}) => {
  const [notificationPermission, setNotificationPermission] = useState(
    Notification.permission
  );
  const [pushSubscription, setPushSubscription] = useState(null);

  const { user } = useAuth();

  useEffect(() => {
    if (!("permissions" in navigator)) {
      return;
    }
    navigator.permissions.query({ name: "notifications" }).then(
      (notificationPermission) =>
        (notificationPermission.onchange = () => {
          setNotificationPermission(notificationPermission.state);
        })
    );
  }, []);

  useEffect(() => {
    getRegistrationPushSubscription().then((pushSubscription) =>
      setPushSubscription(pushSubscription)
    );
  }, []);

  const integration = integrations.find(
    (integration) =>
      integration.sub === user?.sub &&
      integration.pushSubscription?.endpoint === pushSubscription?.endpoint
  );

  const handleCreateIntegration = useCallback(async () => {
    if (Notification.permission !== "granted") {
      await Notification.requestPermission();
    }

    try {
      const pushSubscription = await getRegistrationPushSubscription();

      if (!pushSubscription) {
        return;
      }

      await onCreateIntegration({
        type: "push-notification",
        pushSubscription,
      });
    } catch (e) {
      console.error("Error while subscribing to push notifications =>", e);
    }
  }, [onCreateIntegration]);

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" fontWeight="bold">
          Push notifications
        </Typography>

        <FormControlLabel
          control={
            <Switch
              disabled={isDisabled}
              checked={Boolean(integration)}
              onChange={async (e, enabled) => {
                if (enabled) {
                  await handleCreateIntegration();
                  return;
                }

                onDeleteIntegration(integration);
              }}
              color="success"
            />
          }
          label={integration ? "Enabled" : "Disabled"}
        />
      </Stack>

      {notificationPermission && notificationPermission === "denied" && (
        <>
          <Typography variant="body1" color="error">
            You denied push notifications. You need to allow push notifications
            in your browser settings.
          </Typography>
          <img
            src="https://imagedelivery.net/55PXnfNaLA7qnpEnq-80ug/fc6f055a-4053-472b-80be-9006406aae00/w500"
            alt=""
            style={{
              maxWidth: "100%",
            }}
          />
        </>
      )}

      <Typography variant="body1">
        Receive a browser push notification every time a bug report is
        generated. Will be enabled just for your user on current device and
        browser.
      </Typography>
    </Stack>
  );
};

export default PushNotificationsIntegration;

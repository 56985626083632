import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { noop, orderBy } from "lodash";
import { useSnackbar } from "notistack";

import { fetchReportsAPI as fetchReportsApi } from "../api/index";
import { useAppContext } from "./AppContext.js";
import { INBOX_SETTINGS } from "../pages/ReportsListPage/config.tsx";
import useReportsListeners from "../hooks/useReportsListeners.js";

type Report = {
  // TODO: add types
  [key: string]: any;
};

type ReportsListContextType = {
  reportsLoading: boolean;
  reports: any[];
  setQueryParams: (opts: FetchQueryParams) => void;
  optimisticReportUpdate: (
    reportId: string,
    updateFn: (report: Report) => Report
  ) => void;
  requestRefreshReports: () => void;
  lastEvaluatedKey: any;
};

export type FetchQueryParams = {
  clear?: boolean;
  more?: any | false; // Last evaluated key
  withErrors?: boolean;
  archived?: boolean;
  bugStatuses?: string[];
  bugStatusesNot?: string[];
  userReported?: string;
  limitAfterSorting?: number;
  target?: "inbox-new" | "inbox";
  filterFn?: (report: Report) => Promise<boolean>;
  processReports?: (reports: Report[]) => Promise<Report[]>;
};

const ReportsListContext = createContext<ReportsListContextType>({
  reportsLoading: true,
  reports: [],
  setQueryParams: noop,
  requestRefreshReports: noop,
  lastEvaluatedKey: null,
  optimisticReportUpdate: noop,
});

const DEFAULT_FETCH_OPTIONS = INBOX_SETTINGS.PENDING_REVIEW_FILTER;

export const ReportsListProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { selectedCompanyId: workspaceId } = useAppContext();

  const [_reports, setReports] = useState<Report[]>([]);
  const [reportsLoading, _setReportsLoading] = useState<boolean>(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>(null);
  const [queryParams, _setQueryParams] = useState<FetchQueryParams>(
    DEFAULT_FETCH_OPTIONS
  );

  const reports = useMemo(
    () =>
      _reports
        .filter(
          (r) =>
            r.metadata?.triggerType !== "autopilot" ||
            !r.metadata?.errorInfo?.originalReportId ||
            r.id === r.metadata?.errorInfo?.originalReportId
        )
        .map((r) => ({
          ...r,
          sortIndex: `${
            r.metadata?.errorInfo?.originalReportId === r.id
              ? r.metadata.errorInfo.numAffectedUsers * 2 +
                r.metadata.errorInfo.numOccurences
              : ["widget", "sdk", "wss"].includes(r.metadata?.triggerType)
              ? Number.MAX_SAFE_INTEGER
              : 1
          }-${r.timestamp}`,
        })),
    [_reports]
  );

  useReportsListeners({ setReports, label: "reportsList" });

  const setReportsLoading = useCallback(
    (loading: boolean) => _setReportsLoading(loading),
    [_setReportsLoading]
  );

  const setQueryParams = useCallback(
    (opts: FetchQueryParams) => {
      if (Object.keys(opts).length !== 1 && opts.more) {
        // if any setting is changed except more (i.e., the dynamo starting key for pagination)
        // we need to reset the lastEvaluatedKey
        setLastEvaluatedKey(null);
        setReports([]);
      }

      return _setQueryParams(opts);
    },
    [_setQueryParams]
  );

  const optimisticReportUpdate = useCallback(
    (reportId: string, updateFn) => {
      setReports((reports) => {
        const reportIndex = reports.findIndex(
          (report) => report.reportId === reportId
        );

        if (reportIndex === -1) {
          return reports;
        }

        const report = reports[reportIndex];
        const updatedReport = updateFn(report);

        if (!updatedReport) {
          return reports;
        }

        const nextReports = [...reports];
        nextReports[reportIndex] = updatedReport;

        return nextReports;
      });
    },
    [setReports]
  );

  const loadReports = useCallback(async () => {
    setReportsLoading(true);

    if (!workspaceId) {
      return;
    }

    try {
      const result = await fetchReportsApi(workspaceId, {
        startKey: queryParams.more || null,
        search: undefined,
        withErrors: queryParams.withErrors,
        archived: queryParams.archived,
        bugStatuses: queryParams.bugStatuses,
        target: queryParams.target,
      });

      if (!result) {
        console.error("Reports response is empty", result);
        setReports([]);
        setReportsLoading(false);
        return;
      }

      let { reports: nextReports, lastEvaluatedKey: nextLastEvaluatedKey } =
        result;

      if (queryParams.bugStatusesNot) {
        // removes reports with status null or listed in bugStatusesNot
        nextReports = nextReports.filter((report) => {
          return !queryParams.bugStatusesNot.includes(report.bugStatus || "");
        });
      }

      nextReports = orderBy(nextReports, ["timestamp"], ["desc"]);

      if (queryParams.limitAfterSorting) {
        nextReports = nextReports.slice(0, queryParams.limitAfterSorting);
      }

      if (typeof queryParams.filterFn === "function") {
        const asyncFilter = async (arr) => {
          const results = await Promise.all(arr.map(queryParams.filterFn));
          return arr.filter((_v, index) => results[index]);
        };

        nextReports = await asyncFilter(nextReports);
      }

      if (typeof queryParams.processReports === "function") {
        nextReports = await queryParams.processReports(nextReports);
      }

      setReports(
        queryParams.more ? (r) => [...r, ...nextReports] : nextReports
      );
      setReportsLoading(false);

      setLastEvaluatedKey(nextLastEvaluatedKey);
    } catch (e) {
      console.error("Cannot fetch reports:", e);
      setReports([]);
      setReportsLoading(false);

      // enqueueSnackbar("Sorry, we had trouble loading reports", {
      //   variant: "error",
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, queryParams, enqueueSnackbar]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const loadReports = useCallback(throttle(_fetchReportsHandler, 2 * 1000), [
  //   _fetchReportsHandler,
  // ]);

  const requestRefreshReports = useCallback(() => {
    setLastEvaluatedKey(null);
    loadReports();
  }, [loadReports]);

  useEffect(() => {
    setLastEvaluatedKey(null);
    loadReports();

    return () => {
      setReports([]);
    };
  }, [workspaceId, queryParams, loadReports]);

  return (
    <ReportsListContext.Provider
      value={{
        reportsLoading,
        reports,
        setQueryParams,
        optimisticReportUpdate,
        requestRefreshReports,
        lastEvaluatedKey,
      }}
    >
      {children}
    </ReportsListContext.Provider>
  );
};

export const useReportsListContext = () => {
  return useContext(ReportsListContext);
};

import { SvgIcon } from "@mui/material";

const CrispIcon = ({ ...props }) => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 450 366"
    >
      <g fill="#1972F5" fillRule="evenodd">
        <path d="M685.68 270.32c34.2 0 54.36-14.76 66.6-30.96l-24.84-23.4c-9.72 13.68-23.04 20.88-39.96 20.88-31.32 0-52.56-24.12-52.56-57.96s21.24-57.6 52.56-57.6c16.92 0 30.24 6.48 39.96 20.16l24.84-22.68c-12.24-16.56-32.4-30.96-66.6-30.96-52.56 0-89.64 38.52-89.64 91.08 0 52.56 37.08 91.44 89.64 91.44ZM818.56 266V147.56c7.56-12.6 28.8-23.4 45-23.4 4.68 0 8.28.36 11.52 1.08V87.8c-22.32 0-43.2 12.96-56.52 29.52v-25.2h-37.8V266h37.8Zm101.2-195.12c12.96 0 23.4-10.44 23.4-23.4 0-12.96-10.44-23.4-23.4-23.4-12.96 0-23.4 10.44-23.4 23.4 0 12.96 10.44 23.4 23.4 23.4ZM938.48 266V92.12h-37.8V266h37.8Zm103.72 4.32c46.8 0 72.36-23.4 72.36-54.36 0-69.12-105.12-46.08-105.12-76.68 0-12.24 12.24-21.6 32.04-21.6 21.96 0 41.04 9.36 51.48 20.88l15.84-26.64c-15.48-13.68-37.8-24.12-67.32-24.12-43.92 0-68.4 24.12-68.4 52.56 0 66.96 105.12 42.48 105.12 76.68 0 13.68-11.88 23.04-34.56 23.04-22.32 0-46.44-11.52-58.68-24.12l-17.28 27c18 17.64 44.64 27.36 74.52 27.36Zm198.76 0c-21.96 0-41.4-10.08-55.8-28.44v90.36h-37.8V92.12h37.8v23.76c12.96-17.28 33.12-28.08 55.8-28.08 45 0 77.04 33.84 77.04 91.08 0 57.24-32.04 91.44-77.04 91.44Zm-11.16-33.48c29.88 0 49.32-24.12 49.32-57.96s-19.44-57.6-49.32-57.6c-17.64 0-36 10.08-44.64 23.04v68.76c8.28 12.96 27 23.76 44.64 23.76ZM175.837 289.907 23.989 306.664.182 47.46 426.453.417l23.808 259.206-147.52 16.28-55.595 89.718-71.31-75.714Z" />
      </g>
    </SvgIcon>
  );
};

export default CrispIcon;

import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { useEffect, useMemo } from "react";
import { Hub } from "aws-amplify";
import { Helmet } from "react-helmet";

import Box from "@mui/material/Box";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import MenuSidebar from "./MenuSidebar/MenuSidebar";
import UnhandledIssuesPage from "../pages/ReportsListPage/UnhandledIssuesPage";
import SettingsPageFrame from "../pages/Settings/SettingsPageFrame";
import AccountPage from "../pages/AccountPage";
import SecurityPrivacyPage from "../pages/Settings/SecurityPrivacyPage";
import GeneralSettingsPage from "../pages/Settings/GeneralSettingsPage";
import SessionCaptureSettingsPage from "../pages/Settings/SessionCaptureSettingsPage";
import BillingPage from "../pages/Settings/BillingPage";
import TrackingCodePage from "../pages/TrackingCodeSettingsPage";
import TeamPage from "../pages/TeamPage";
import IntegrationsPage from "../pages/Integrations/IntegrationsPage/IntegrationsPageContainer";
import SignUp from "../pages/SignUp";
import ConfirmSignUp from "../pages/ConfirmSignUp";
import ResetPassword from "../pages/ResetPassword";
import ConfirmResetPassword from "../pages/ConfirmResetPassword";
import ConfirmMFA from "../pages/ConfirmMFA";
import ReportPage from "../components/report/ReportPage";
import GettingStartedPage from "../pages/GettingStarted/GettingStartedPage";

import { AppContextProvider } from "../contexts/AppContext";
import { RequireWorkspace } from "./RequireWorkspace";
import { RequireAccountSetup } from "./RequireAccountSetup";
import NotFoundPage from "../pages/NotFoundPage";
import { AuthProvider, useAuth } from "../contexts/AuthContext";
import SignIn from "../pages/SignIn";
import { AcceptWorkspaceInvitationPage } from "../pages/AcceptWorkspaceInvitationPage";
import { AnalyticsIdentifyUser } from "../analytics/AnalyticsIdentifyUser";
import { RequireSignIn } from "./RequireSignIn";
import { ModalsProvider } from "../hooks/useModals";
import NewPassword from "../pages/NewPassword";
import { useMediaQuery } from "@mui/material";
import AccountSetupFlow from "./AccountSetupFlow";
import { UserPreferencesProvider } from "../contexts/UserPreferencesContext";
import { AnalyticsProvider } from "../analytics/AnalyticsContext";
import OfferAuthenticationPage from "../pages/OfferAuthenticationPage";
import ProviderConnectionPage from "../pages/ProviderConnectionPage";
import { SubscriptionStart } from "../subscription/SubscriptionStart";
import { SubscriptionEnded } from "../subscription/SubscriptionEnded";
import { SubscriptionTrialSuccess } from "../subscription/SubscriptionTrialSuccess";
import { LogoutRoute } from "../pages/LogoutRoute";
import {
  CaptureProviderConnectionCallback,
  RedirectProviderConnectionCallback,
} from "./CaptureProviderConnectionCallback";
import { GlobalHotKeysProvider } from "./GlobalHotKeysContext.tsx";

import { capitalize } from "lodash";

import * as Fathom from "fathom-client";

import { DRAWER_WIDTH } from "./MenuSidebar/MenuSidebar";
import { IntegrationsProvider } from "../contexts/IntegrationsContext";
import BugTrackerPage from "../pages/BugTrackerPage/BugTrackerPage";
import { ReportsListProvider } from "../contexts/ReportsListContext.tsx";
import { TOP_APPBAR_HEIGHT } from "./MenuSidebar/TopAppBar.tsx";
import { ReportsListCacheProvider } from "../contexts/ReportsListCacheContext";
import { RequireScriptSetup } from "./RequireScriptSetup";
import { BuildOutdatedAlert } from "./BuildOutdatedAlert";
import MonitoringPage from "../pages/Monitoring/MonitoringPage.tsx";
import { StatusPageSetupPage } from "../pages/StatusPageSetupPage/StatusPageSetupPage.tsx";
import { WidgetSetupPage } from "../pages/WidgetSetupPage/WidgetSetupPage.tsx";

const PageContainer = () => {
  const location = useLocation();

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    // Initialize Fathom when the app loads
    // Example: yourdomain.com
    //  - Do not include https://
    //  - This must be an exact match of your domain.
    //  - If you're using www. for your domain, make sure you include that here.
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
    ) {
      return;
    }

    if (
      window.localStorage
        .getItem("bugpilot.lastemail")
        ?.includes("@bugpilot.io")
    ) {
      return;
    }

    Fathom.load("ECBYFOJQ", {
      includedDomains: [
        "bugpilot.io",
        "www.bugpilot.io",
        "dash.bugpilot.io",
        "www.bugpilot.com",
        "dash.bugpilot.com",
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // track fathom page view on location change
  useEffect(() => {
    Fathom.trackPageview();
  }, [location]);

  const isWithoutLayout = useMemo(() => {
    return (
      location.pathname.startsWith("/onboarding") ||
      location.pathname.startsWith("/invitation") ||
      location.pathname.startsWith("/subscription") ||
      location.pathname.startsWith("/lean-report")
    );
  }, [location.pathname]);

  if (isWithoutLayout) {
    return <Outlet />;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        bgcolor: "bg.page",
      }}
    >
      <BuildOutdatedAlert />

      <MenuSidebar />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          p: 4,
          margin: 0,
          marginLeft: smallScreen ? 0 : `${DRAWER_WIDTH}px`,
          marginTop: smallScreen ? 7 : 0,
          position: "relative",
          pt: TOP_APPBAR_HEIGHT / 8 + 3, // 50px = appbar height, 8 = mui spacing multiplier, 2 = standard stack spacing
        }}
      >
        <RequireWorkspace excludeRoutes={["/account", "/invitation"]}>
          <RequireScriptSetup>
            <Outlet />
          </RequireScriptSetup>
        </RequireWorkspace>
      </Box>
    </Box>
  );
};

const RedirectIfLoggedIn = ({ children }) => {
  const { user } = useAuth();

  if (user !== null && user !== false) {
    return <Navigate to="/" />;
  }

  return children;
};

let error;

Hub.listen("auth", ({ payload }) => {
  // https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/
  if (payload?.event !== "signIn_failure") {
    return;
  }

  error = payload?.data?.message
    .replace(/\+/g, " ")
    .replace("PreSignUp failed with error ", "");
});

const DocumentTitle = ({ title, children, brand = "Bugpilot" }) => {
  const params = useParams();

  const suffix = params.id ? ` - ${params.id}` : "";

  useEffect(() => {
    if (typeof title === "function") {
      document.title = `${title(params)}${suffix} - ${brand}`;
      return;
    }

    document.title = `${title}${suffix} - ${brand}`;
  }, [title, suffix, brand, params]);

  return children;
};

const AppRoutesList = () => {
  const {
    authStateData,
    toSignIn,
    signIn,
    signUp,
    confirmSignUp,
    confirmMFA,
    resendSignUp,
    forgotPassword,
    forgotPasswordSubmit,
    completeNewPassword,
    federatedSignIn,
  } = useAuth();

  return (
    <Routes>
      {/* Public routes for signin and signup */}
      {/*  */}

      <Route
        path="/signin"
        element={
          <RedirectIfLoggedIn>
            <DocumentTitle title="Sign in">
              <Helmet>
                <link rel="canonical" href="https://dash.bugpilot.io/signin" />
              </Helmet>
              <SignIn
                onSignIn={signIn}
                federatedSignIn={federatedSignIn}
                error={error}
                email={new URLSearchParams(window.location.search).get("email")}
              />
            </DocumentTitle>
          </RedirectIfLoggedIn>
        }
      />

      <Route
        path="/logout"
        element={
          <DocumentTitle title="Logout">
            <LogoutRoute />
          </DocumentTitle>
        }
      />

      <Route
        path="/frame/welcome"
        element={
          <DocumentTitle title="Connect Workspace">
            <OfferAuthenticationPage />
          </DocumentTitle>
        }
      />

      <Route
        path="/signup"
        element={
          <DocumentTitle title="Sign up">
            <Helmet>
              <link rel="canonical" href="https://dash.bugpilot.io/signup" />
            </Helmet>
            <SignUp
              onSignUp={signUp}
              federatedSignIn={federatedSignIn}
              error={error}
            />
          </DocumentTitle>
        }
      />

      <Route
        path="/signup/confirm"
        element={
          <DocumentTitle title="Confirm sign up">
            <ConfirmSignUp
              email={authStateData.email}
              onConfirm={confirmSignUp}
              onSignInClick={toSignIn}
              onResendCode={resendSignUp}
            />
          </DocumentTitle>
        }
      />

      <Route
        path="/signin/mfa"
        element={
          <DocumentTitle title="Confirm MFA">
            <ConfirmMFA
              user={authStateData.user}
              email={authStateData.email}
              onConfirm={confirmMFA}
              onSignInClick={toSignIn}
            />
          </DocumentTitle>
        }
      />

      <Route
        path="/signin/reset-password"
        element={
          <DocumentTitle title="Reset password">
            <ResetPassword onReset={forgotPassword} />
          </DocumentTitle>
        }
      />

      <Route
        path="/signin/reset-password/confirm"
        element={
          <DocumentTitle title="Confirm reset password">
            <ConfirmResetPassword
              onConfirm={forgotPasswordSubmit}
              email={authStateData.email}
            />
          </DocumentTitle>
        }
      />

      <Route
        path="/signin/new-password"
        element={
          <DocumentTitle title="New password">
            <NewPassword
              onConfirm={({ email, password }) =>
                completeNewPassword({
                  user: authStateData.user,
                  email,
                  password,
                })
              }
              email={authStateData.email}
            />
          </DocumentTitle>
        }
      />

      {/* Public Route for ReportBug.ai */}

      <Route
        path="lean-report/:id"
        element={
          <DocumentTitle title="Report" brand="ReportBug.ai">
            <Box p={3}>
              <ReportPage variant="lean" />
            </Box>
          </DocumentTitle>
        }
      />

      {/* Routes behind login */}
      {/*  */}

      <Route
        path="/"
        element={
          <CaptureProviderConnectionCallback>
            <RequireSignIn>
              <UserPreferencesProvider>
                <AppContextProvider>
                  <ModalsProvider>
                    <IntegrationsProvider>
                      <AnalyticsIdentifyUser />
                      <RequireAccountSetup>
                        <DndProvider backend={HTML5Backend}>
                          <ReportsListProvider>
                            <ReportsListCacheProvider>
                              <GlobalHotKeysProvider>
                                <PageContainer />
                              </GlobalHotKeysProvider>
                            </ReportsListCacheProvider>
                          </ReportsListProvider>
                        </DndProvider>
                      </RequireAccountSetup>
                    </IntegrationsProvider>
                  </ModalsProvider>
                </AppContextProvider>
              </UserPreferencesProvider>
            </RequireSignIn>
          </CaptureProviderConnectionCallback>
        }
      >
        {/* Dashboard homepage */}

        <Route
          path="/"
          element={
            <RedirectProviderConnectionCallback>
              <DocumentTitle title="Dashboard">
                <MonitoringPage />
              </DocumentTitle>
            </RedirectProviderConnectionCallback>
          }
        />

        <Route
          path="subscription"
          element={<Navigate to="/subscription/start" />}
        />

        <Route
          path="subscription/start"
          element={
            <DocumentTitle title="Subscribe">
              <SubscriptionStart />
            </DocumentTitle>
          }
        />

        <Route
          path="subscription/success"
          element={
            <DocumentTitle title="Subscribed">
              <SubscriptionTrialSuccess />
            </DocumentTitle>
          }
        />

        <Route
          path="subscription/ended"
          element={
            <DocumentTitle title="Subscription Ended">
              <SubscriptionEnded />
            </DocumentTitle>
          }
        />

        <Route path="getting-started">
          <Route path="" element={<GettingStartedPage />} />

          <Route
            path=":step"
            element={
              <DocumentTitle
                title={({ step }) => `Getting Started - ${capitalize(step)}`}
              >
                <GettingStartedPage />
              </DocumentTitle>
            }
          />
        </Route>

        {/* Onboarding routes (handled by AccountSetupFlow) */}
        <Route path="onboarding">
          <Route path="" element={<Navigate to="/onboarding/tos" />} />
          <Route
            path=":step"
            element={
              <DocumentTitle
                title={({ step }) =>
                  `Onboarding ${
                    step === "tos"
                      ? "Terms of Service"
                      : step === "role"
                      ? "Role"
                      : step === "company"
                      ? "Company"
                      : ""
                  }`
                }
              >
                <AccountSetupFlow />
              </DocumentTitle>
            }
          />
        </Route>

        {/* Provider Connection Callback */}
        <Route path="connect">
          <Route path=":provider">
            <Route
              path="callback"
              element={
                <DocumentTitle
                  title={({ provider }) => `Connect to ${provider}`}
                >
                  <ProviderConnectionPage />
                </DocumentTitle>
              }
            />
          </Route>
        </Route>

        <Route
          path="/inbox/:activeTab?"
          Component={(props) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { activeTab } = useParams();
            return (
              <Navigate
                replace
                to={`/issues${activeTab ? `/${activeTab}` : ""}`}
              />
            );
          }}
        />

        <Route path="/issues">
          <Route
            path=""
            element={
              <DocumentTitle title="New issues">
                <UnhandledIssuesPage />
              </DocumentTitle>
            }
          />
          <Route
            path=":activeTab"
            element={
              <DocumentTitle title="New issues">
                <UnhandledIssuesPage />
              </DocumentTitle>
            }
          />
        </Route>

        <Route
          path="tracker"
          element={
            <DocumentTitle title="Bug Tracker">
              <BugTrackerPage />
            </DocumentTitle>
          }
        />

        <Route path="monitoring" element={<Navigate to="/" />} />

        <Route
          path="search"
          element={
            <DocumentTitle title="Search">
              <Navigate to={`/${window.location.search}`} />
            </DocumentTitle>
          }
        />

        <Route
          path="statuspage"
          element={
            <DocumentTitle title="Status Page Setup">
              <StatusPageSetupPage />
            </DocumentTitle>
          }
        />

        <Route
          path="widget"
          element={
            <DocumentTitle title="Status Page Setup">
              <WidgetSetupPage />
            </DocumentTitle>
          }
        />

        {/* Dashboard homepage */}
        <Route path="reports">
          <Route path="" element={<Navigate to="/reports/all" />} />

          <Route
            path="all"
            element={
              <DocumentTitle title="Session Replays">
                <UnhandledIssuesPage />
              </DocumentTitle>
            }
          />

          <Route
            path=":id/:slug"
            element={
              <DocumentTitle title="Report">
                <ReportPage />
              </DocumentTitle>
            }
          />

          <Route
            path=":id"
            element={
              <DocumentTitle title="Report">
                <ReportPage />
              </DocumentTitle>
            }
          />
        </Route>

        {/* Report pages */}
        <Route
          path="report/:id/:slug"
          element={
            <DocumentTitle title="Report">
              <ReportPage />
            </DocumentTitle>
          }
        />
        <Route
          path="report/:id"
          element={
            <DocumentTitle title="Report">
              <ReportPage />
            </DocumentTitle>
          }
        />
        <Route
          path="report/:serialNumber/:slug/:id"
          element={
            <DocumentTitle title="Report">
              <ReportPage />
            </DocumentTitle>
          }
        />

        {/* Account page */}
        <Route
          path="account"
          element={
            <DocumentTitle title="My Profile">
              <AccountPage />
            </DocumentTitle>
          }
        />
        <Route
          path="invitation/:code"
          element={
            <DocumentTitle title="Join Workspace">
              <AcceptWorkspaceInvitationPage />
            </DocumentTitle>
          }
        />

        <Route
          path="settings/team"
          element={
            <DocumentTitle title="Team">
              <TeamPage />
            </DocumentTitle>
          }
        />

        {/* Integrations page */}
        <Route
          path="settings/integrations"
          element={
            <DocumentTitle title="Integrations">
              <IntegrationsPage />
            </DocumentTitle>
          }
        >
          <Route path=":type" element={<IntegrationsPage />} />
        </Route>

        {/* Settings page */}
        <Route
          path="settings"
          element={
            <SettingsPageFrame>
              <Outlet />
            </SettingsPageFrame>
          }
        >
          <Route index element={<Navigate to="customize" replace />} />

          <Route
            path="customize"
            element={
              <DocumentTitle title="Settings">
                <GeneralSettingsPage />
              </DocumentTitle>
            }
          />
          <Route
            path="session"
            element={
              <DocumentTitle title="Session Replays Settings">
                <SessionCaptureSettingsPage />
              </DocumentTitle>
            }
          />
          <Route
            path="data-and-security"
            element={
              <DocumentTitle title="Security and Privacy">
                <SecurityPrivacyPage />
              </DocumentTitle>
            }
          />
          <Route
            path="billing"
            element={
              <DocumentTitle title="Billing">
                <BillingPage />
              </DocumentTitle>
            }
          />
          <Route
            path="code"
            element={
              <DocumentTitle title="Tracking code">
                <TrackingCodePage />
              </DocumentTitle>
            }
          />
        </Route>

        {/* 404 */}
        <Route
          path="*"
          element={
            <DocumentTitle title="Not found">
              <NotFoundPage />
            </DocumentTitle>
          }
        />
      </Route>
    </Routes>
  );
};

const AppContainer = () => {
  return (
    <BrowserRouter>
      <AnalyticsProvider>
        <AuthProvider>
          <AppRoutesList />
        </AuthProvider>
      </AnalyticsProvider>
    </BrowserRouter>
  );
};

export default AppContainer;

import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";

import { getFieldProps } from "../utils/getFieldProps";
import { OnboardingFrame } from "../components/OnboardingFrame";

import { isInviteSignIn } from "./InviteSignInAddon";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email is a required field"),
  code: Yup.string().required(
    "Please enter the confirmation code you received via email"
  ),
});

const ConfirmSignUp = ({ onConfirm, onSignInClick, onResendCode, email }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const formik = useFormik({
    initialValues: {
      email,
      code: "",
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async ({ email, code }) => {
      try {
        setError(null);
        await onConfirm(email, code, {
          ...(isInviteSignIn() && {
            clientMetadata: {
              source: "invitation",
            },
          }),
        });
        setSubmitted(true);
      } catch (e) {
        console.error("Form submit error", e);
        setError(e.message);
        setSubmitted(false);
      }
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  const handleResendCode = useCallback(async () => {
    await onResendCode(email);
    enqueueSnackbar(`Confirmation code sent to ${email}`, {
      variant: "success",
    });
  }, [email, onResendCode]);

  useEffect(() => {
    if (submitted) {
      return;
    }

    if (!email) {
      navigate("/signup");
    }
  }, [email, navigate, submitted]);

  return (
    <OnboardingFrame>
      <Typography variant="h2">Activate your account</Typography>
      <Typography variant="body1">
        To keep things secure, you should have received an email at{" "}
        <strong>{email}</strong> with a one-time confirmation code.
      </Typography>

      <TextField
        label="Confirmation code"
        required
        placeholder="Enter the code you received via email"
        type="text"
        autoComplete="off"
        onPaste={(e) => {
          // remove everything except numbers when something is pasted
          e.preventDefault();
          const text = e.clipboardData.getData("text/plain");
          const numbers = text.replace(/[^0-9]/g, "");
          document.execCommand("insertText", false, numbers);
        }}
        {...getFieldProps(formik, {
          name: "code",
        })}
      />

      {error && (
        <FormHelperText error>
          <Typography variant="body1">{error}</Typography>
        </FormHelperText>
      )}

      <LoadingButton
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        loading={submitted || formik.isSubmitting}
        onClick={handleSubmit}
      >
        Next
      </LoadingButton>

      <Typography variant="caption">
        Didn&apos;t receive your code?{" "}
        <Link href="#" onClick={handleResendCode}>
          Send again
        </Link>
      </Typography>
    </OnboardingFrame>
  );
};

export default ConfirmSignUp;

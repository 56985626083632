import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputAdornment from "@mui/material/InputAdornment";

import CloseIcon from "@mui/icons-material/CloseOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import Button from "../Button";

import Input from "../Input";
import { getFieldProps } from "../../utils/getFieldProps";
import { changeUserEmail, confirmChangeUserEmail } from "../../api";
import CodeIcon from "../../icons/CodeIcon";
import FormHelperText from "@mui/material/FormHelperText";
import ErrorText from "../ErrorText";

const DialogActions = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(4)}`,
  },
}))(MuiDialogActions);

const DialogContent = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(4)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogTitle);

const validationSchema = Yup.object({
  oldEmail: Yup.string().email(),
  newEmail: Yup.string()
    .email("New Email is required")
    .required("New Email is required")
    .notOneOf(
      [Yup.ref("oldEmail")],
      "New Email should be different from old Email"
    ),
});

const confirmValidationSchema = Yup.object({
  code: Yup.string()
    .required("Confirmation code is required. Check your new email.")
    .length(6),
});

const ChangeEmailModal = ({ email, onClose, onConfirm }) => {
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [error, setError] = useState(null);

  const formik = useFormik({
    initialValues: isConfirmation
      ? { code: "" }
      : {
          oldEmail: email,
          newEmail: "",
        },
    enableReinitialize: true,
    validationSchema: isConfirmation
      ? confirmValidationSchema
      : validationSchema,
    onSubmit: async (values) => {
      try {
        if (values.code) {
          await confirmChangeUserEmail({ code: values.code });

          onConfirm({ email: newEmail });
          return;
        }

        await changeUserEmail({ email: values.newEmail });
        setNewEmail(values.newEmail);
        setIsConfirmation(true);
      } catch (e) {
        // message equals "null" when trying to change to email which is already in use in another account
        setError(e.message !== "null" ? e.message : e.code);
      }
    },
  });

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold">
            Change email
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} direction="column">
          {isConfirmation && (
            <>
              <Grid item>
                <Typography variant="body1">
                  Enter the confirmation code you received at your new email
                  address. This change will not be effective until you confirm
                  it.
                </Typography>
              </Grid>
              <Grid item>
                <Input
                  fullWidth
                  label="Confirmation code"
                  size="small"
                  required
                  type="text"
                  autoFocus
                  autoComplete="off"
                  {...getFieldProps(formik, {
                    name: "code",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CodeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          )}

          {!isConfirmation && (
            <>
              <Grid item>
                <Input
                  fullWidth
                  label="Old email"
                  size="small"
                  required
                  type="email"
                  {...getFieldProps(formik, {
                    name: "oldEmail",
                    disabled: true,
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <Input
                  fullWidth
                  label="New email"
                  size="small"
                  required
                  type="email"
                  {...getFieldProps(formik, {
                    name: "newEmail",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          )}

          {error && (
            <Grid item>
              <FormHelperText error>
                <ErrorText>{error}</ErrorText>
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          bold
          disabled={!formik.dirty || formik.isSubmitting}
          onClick={formik.handleSubmit}
          endIcon={formik.isSubmitting && <CircularProgress size={25} />}
          fullWidth
          variant="contained"
        >
          {isConfirmation ? "Confirm" : "Change"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeEmailModal;

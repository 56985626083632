import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MailOutlineIcon from "@mui/icons-material/MailOutlineOutlined";

import Link from "../components/Link";
import Input from "../components/Input";
import AsyncButton from "../components/AsyncButton";
import { getFieldProps } from "../utils/getFieldProps";
import { handleSubmit } from "../utils/formik";
import FormHelperText from "@mui/material/FormHelperText";
import ErrorText from "../components/ErrorText";
import OnboardingBackdrop from "../components/OnboardingBackdrop";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email is a required field"),
});

const ResetPassword = ({ onReset, onSignInClick }) => {
  const [error, setError] = useState(null);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async ({ email, code }) => {
      try {
        setError(null);
        await onReset({ email, code });
      } catch (e) {
        console.error(e);
        setError(e.message);
        throw e;
      }
    },
  });

  return (
    <OnboardingBackdrop>
      <Box maxWidth={700} width="100vw" boxSizing="border-box">
        <Paper>
          <Box p={5}>
            <Grid container spacing={2} flexDirection="column">
              <Grid item>
                <Typography fontWeight="bold" variant="h6">
                  Reset your password
                </Typography>
                <Typography variant="body">
                  Enter your e-mail address, you will receive an e-mail with a
                  code that you can enter in this page to set a new password.
                </Typography>
              </Grid>
              <Grid item>
                <Input
                  label="Email address"
                  required
                  placeholder="Email address"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                  {...getFieldProps(formik, {
                    name: "email",
                    errorWhenTouchedOrSubmitted: true,
                  })}
                />
              </Grid>
              <Grid item>
                <AsyncButton
                  isLoading={formik.isSubmitting}
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleSubmit(formik)}
                >
                  Reset your password
                </AsyncButton>
              </Grid>

              {error && (
                <Grid item>
                  <FormHelperText error>
                    <ErrorText>{error}</ErrorText>
                  </FormHelperText>
                </Grid>
              )}

              <Grid item>
                <Typography variant="body1">
                  <Link to="/signin">Back to Login</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </OnboardingBackdrop>
  );
};

export default ResetPassword;

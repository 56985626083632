import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorIcon from "@mui/icons-material/ErrorOutlined";

import useLoading from "../hooks/useLoading";
import Button from "./Button";

const AsyncButton = ({ onClick, disabled, iconButton = false, ...props }) => {
  const { isLoading, isSuccess, handler } = useLoading(
    onClick,
    props.isLoading
  );

  const Component = iconButton ? IconButton : Button;

  return (
    <Component
      {...props}
      onClick={handler}
      disabled={disabled || isLoading || props.isLoading}
      endIcon={
        isLoading || props.isLoading ? (
          <CircularProgress size={15} />
        ) : typeof isSuccess === "boolean" ? (
          isSuccess ? (
            <CheckCircleIcon size={15} />
          ) : (
            <ErrorIcon color="error" size={15} />
          )
        ) : (
          props.endIcon
        )
      }
    />
  );
};

export default AsyncButton;

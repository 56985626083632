import {
  type FC,
  useCallback,
  useEffect,
  useState,
  lazy,
  Suspense,
  useMemo,
} from "react";
import ms from "ms";
import { createPortal } from "react-dom";
import { orderBy, times } from "lodash";
import { orderBy as fpOrderBy, compose, filter as fpFilter } from "lodash/fp";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Link as MuiLink,
  Paper,
  Skeleton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import useLoading from "../../hooks/useLoading.js";
import { getWorkspaceStats } from "../../api/index.js";
import { useAppContext } from "../../contexts/AppContext.js";
import { usePreferences } from "../../contexts/UserPreferencesContext.js";
import HelpButton from "../../components/Help.js";
import {
  AdsClickOutlined as AdsClickIcon,
  ArrowDropDownOutlined as ArrowDropDownIcon,
  CheckCircleOutlined as CheckCircleIcon,
  ChevronRightOutlined as ChevronRightIcon,
  DescriptionOutlined as DescriptionIcon,
  ExpandLessOutlined as ExpandLessIcon,
  ExpandMoreOutlined as ExpandMoreIcon,
  OpenInNewOutlined as OpenInNewIcon,
  Person2Outlined as Person2Icon,
  LocalFireDepartmentOutlined,
  ReportProblemOutlined,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useReportsListContext } from "../../contexts/ReportsListContext.tsx";
import { INBOX_SETTINGS } from "../ReportsListPage/config.tsx";
import { isRecommended } from "../ReportsListPage/ReportsTable.js";

const Chart = lazy(() => import("./Chart.tsx"));

declare global {
  type Stats = {
    statsByDate: Array<{
      date: string;
      reportsCount: number;
      usersCount: number;
    }>;
    usersCount: number;
    usersCountDiff: number;
    issuesCount: number;
    issuesCountDiff: number;
    sessionsCount: number;
    sessionsCountDiff: number;
    topUsersByReportsCount: Array<{
      user: Object;
      reportsCount: number;
    }>;
    urlsCount: Record<string, number>;
    urlsCountDiff: number;
  };
}

type MonitoringProps = {
  isLoading: boolean;
  stats: Stats;
  selectedCompanyId: string;
  period: string;
};

const ChartLoader = () => <Skeleton variant="rounded" height="100%" />;

const MonitoringCard = ({
  title,
  description,
  number,
  isLoading,
  diffWithPreviousPeriod,
}) => {
  return (
    <Paper sx={{ flex: 1 }} key={title}>
      <Stack gap={2.5} p={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          spacing={1}
        >
          <Typography variant="subtitle2">{title}</Typography>

          <HelpButton
            title={description}
            sx={{
              fontSize: "1rem",
              color: "muted.dark",
            }}
          />
        </Stack>

        <Typography variant="h5">
          {isLoading || typeof number !== "number" ? (
            <Skeleton variant="text" />
          ) : (
            <>
              <span>{number}</span>

              {diffWithPreviousPeriod ? (
                <span
                  style={{
                    color: diffWithPreviousPeriod < 0 ? "#00C853" : "#FF1744",
                    fontSize: "0.75rem",
                    marginLeft: 4,
                  }}
                >
                  <ArrowDropDownIcon
                    sx={{
                      verticalAlign: "bottom",
                      transform:
                        diffWithPreviousPeriod > 0 ? "rotate(180deg)" : "",
                    }}
                  />
                  {Math.abs(diffWithPreviousPeriod)}
                </span>
              ) : (
                <span
                  style={{
                    color: "#D0D0D0",
                    fontSize: "0.75rem",
                    marginLeft: 4,
                  }}
                >
                  <ArrowDropDownIcon
                    sx={{
                      verticalAlign: "bottom",
                      transform:
                        diffWithPreviousPeriod > 0 ? "rotate(180deg)" : "",
                    }}
                  />{" "}
                  0
                </span>
              )}
            </>
          )}
        </Typography>
      </Stack>
    </Paper>
  );
};

const periods = {
  "1day": "24h",
  "7days": "7d",
  "14days": "14d",
  "30days": "30d",
};

const SkeletonLoader = () => (
  <>
    {times(3, (i) => (
      <Typography variant="body1" key={i}>
        <Skeleton variant="text" />
      </Typography>
    ))}
  </>
);

const HumanizedPeriod = ({ period }) => periods[period] ?? period;

const useViewAll = ({ list = [], limit = 3 } = {}) => {
  const [isViewAll, setIsViewAll] = useState(false);

  const handleToggle = useCallback(() => {
    setIsViewAll((isViewAll) => !isViewAll);
  }, []);

  return {
    isViewAll,
    isViewAllActive: list?.length > limit,
    list: isViewAll ? list : list?.slice?.(0, limit),
    onChange: handleToggle,
  };
};

const CriticalPages = ({ isLoading, urlsCount, period }) => {
  const urlsByCount = useMemo(
    () => orderBy(Object.entries(urlsCount ?? {}), ["1"], ["desc"]),
    [urlsCount]
  );

  const {
    list: urls,
    onChange: handleViewAll,
    isViewAllActive,
    isViewAll,
  } = useViewAll({
    list: urlsByCount,
  });

  const humanizeUrl = useCallback((url) => {
    try {
      return new URL(url).pathname;
    } catch (e) {
      return url;
    }
  }, []);

  return (
    <Paper sx={{ flex: 1, height: "100%" }}>
      <Stack gap={2} p={2} sx={{ height: "100%" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          spacing={1}
        >
          <Typography variant="subtitle2">Critical Pages</Typography>
          <Typography variant="subtitle2">
            <CountText /> (<HumanizedPeriod period={period} />)
          </Typography>
        </Stack>

        <Stack gap={1}>
          {isLoading ? (
            <SkeletonLoader />
          ) : urls.length > 0 ? (
            urls.map(([url, count]) => (
              <Box key={url} sx={{ display: "flex" }}>
                <Typography
                  variant="body2"
                  sx={{ flex: 1, color: "#5d5f59" }}
                  noWrap
                >
                  {!url.includes("********") ? (
                    <MuiLink
                      href={url}
                      target="_blank"
                      rel="noopener noreferer"
                      sx={{
                        textDecoration: "underline",
                        color: "#5d5f59",
                      }}
                    >
                      {humanizeUrl(url)}

                      <OpenInNewIcon
                        sx={{
                          ml: 0.5,
                          verticalAlign: "middle",
                          fontSize: 12,
                        }}
                      />
                    </MuiLink>
                  ) : (
                    humanizeUrl(url)
                  )}
                </Typography>

                <Typography variant="body2">{"" + count}</Typography>
              </Box>
            ))
          ) : (
            <Typography
              variant="body2"
              color="text.tertiary"
              sx={{
                mb: 2,

                // align icon and text
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <CheckCircleIcon color="success" />{" "}
              <span>All pages are good!</span>
            </Typography>
          )}
        </Stack>

        {isViewAllActive && (
          <>
            <Box flexGrow={1} />
            <Divider />

            <Button
              fullWidth
              size="small"
              variant="text"
              color="tertiary"
              endIcon={isViewAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              onClick={handleViewAll}
            >
              View {isViewAll ? "less" : "more"}
            </Button>
          </>
        )}
      </Stack>
    </Paper>
  );
};

const CriticalUsers = ({ isLoading, topUsersByReportsCount, period }) => {
  const {
    list: users,
    onChange: handleViewAll,
    isViewAllActive,
    isViewAll,
  } = useViewAll({
    list: topUsersByReportsCount,
  });

  return (
    <Paper sx={{ flex: 1, height: "100%" }}>
      <Stack gap={2} p={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          spacing={1}
        >
          <Typography variant="subtitle2">Critical Users</Typography>
          <Typography variant="subtitle2">
            <CountText /> (<HumanizedPeriod period={period} />)
          </Typography>
        </Stack>

        <Stack gap={1}>
          {isLoading ? (
            <SkeletonLoader />
          ) : users.length > 0 ? (
            users.map(({ user, reportsCount }) => (
              <Box
                key={user.id ?? user.userId ?? user.userId ?? user.email}
                sx={{ display: "flex" }}
              >
                <Typography variant="body2" sx={{ flex: 1 }} noWrap>
                  <Link
                    to={`/search?email=${encodeURIComponent(
                      user.email ?? user.id ?? user.userId
                    )}`}
                    style={{
                      color: "#5d5f59",
                    }}
                  >
                    {user.email ?? user.id ?? user.userId}
                  </Link>
                </Typography>

                <Typography variant="body2">{reportsCount}</Typography>
              </Box>
            ))
          ) : (
            <Typography
              variant="body2"
              color="text.tertiary"
              sx={{
                mb: 2,

                // align icon and text
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <CheckCircleIcon color="success" />{" "}
              <span>No user experienced issues</span>
            </Typography>
          )}
        </Stack>

        {isViewAllActive && (
          <>
            <Box flexGrow={1} />
            <Divider />

            <Button
              fullWidth
              size="small"
              variant="text"
              color="tertiary"
              endIcon={isViewAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              onClick={handleViewAll}
            >
              View {isViewAll ? "less" : "more"}
            </Button>
          </>
        )}
      </Stack>
    </Paper>
  );
};

const RecentReports = ({ isLoading, reports: _reports, period }) => {
  const navigate = useNavigate();

  const { list: reports } = useViewAll({
    list: _reports,
  });

  return (
    <Paper sx={{ flex: 1, height: "100%" }}>
      <Stack gap={2} p={2} sx={{ height: "100%" }}>
        <Typography variant="subtitle2">New issues</Typography>

        <Stack gap={1}>
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            reports.map(({ id, serialNumber, title }) => (
              <Typography
                key={id}
                variant="body2"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography
                  variant="body2"
                  component="span"
                  color="text.tertiary"
                  fontWeight="bold"
                >
                  #{serialNumber}
                </Typography>{" "}
                <Tooltip arrow title={title}>
                  <Link
                    to={`/reports/${id}`}
                    style={{
                      color: "#5d5f59",
                    }}
                  >
                    {title}
                  </Link>
                </Tooltip>
              </Typography>
            ))
          )}
        </Stack>

        {!isLoading &&
          (reports?.length > 0 ? (
            <>
              <Box flexGrow={1} />
              <Divider />
              <Button
                fullWidth
                size="small"
                variant="text"
                color="tertiary"
                endIcon={<ChevronRightIcon />}
                onClick={() => navigate("/issues/priority")}
              >
                View new issues
              </Button>
            </>
          ) : (
            <Typography
              variant="body2"
              color="text.tertiary"
              sx={{
                mt: -2,
                mb: 2,

                // align icon and text
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <CheckCircleIcon color="success" />{" "}
              <span>Great job, you have triaged all incoming issues!</span>
            </Typography>
          ))}
      </Stack>
    </Paper>
  );
};

const FireAlertCard = ({ isLoading, reports }) => {
  const navigate = useNavigate();
  const onFire = useMemo(() => reports?.length > 0, [reports]);

  if (isLoading) {
    return (
      <Alert
        severity="info"
        sx={{
          height: "100%",
          width: 200,
          p: 2,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="standard"
        icon={false}
      >
        <Stack direction="column" alignItems="flex-start" spacing={2}>
          <CircularProgress size={24} color="info" />

          <Typography variant="body1" sx={{ flex: 1 }}>
            <b>Checking...</b>
          </Typography>
        </Stack>
      </Alert>
    );
  }

  if (!onFire) {
    return (
      <Alert
        severity="success"
        sx={{
          height: "100%",
          width: 200,
          p: 2,
          color: "white.main",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        variant="filled"
        icon={false}
      >
        <Stack direction="column" alignItems="flex-start" spacing={2}>
          <CheckCircleIcon
            sx={{
              fontSize: "1.5rem",
              verticalAlign: "middle",
            }}
          />

          <Typography variant="body1" sx={{ flex: 1 }}>
            <b>All good!</b>
          </Typography>
        </Stack>
      </Alert>
    );
  }

  return (
    <Alert
      severity="error"
      sx={{
        height: "100%",
        width: 200,
        p: 2,
        cursor: "pointer",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}
      variant="filled"
      icon={false}
      onClick={() => navigate("/issues/priority")}
    >
      <Stack direction="column" alignItems="flex-start" spacing={2}>
        <LocalFireDepartmentOutlined
          sx={{
            fontSize: "1.5rem",
            verticalAlign: "middle",
          }}
        />

        <Typography variant="body1" sx={{ flex: 1 }}>
          <Link
            to="/issues/priority"
            style={{
              fontWeight: "bold",
              color: "inherit",
            }}
          >
            <span>New issues found</span>
            <ChevronRightIcon
              sx={{ pl: 0.5, fontSize: "1rem", verticalAlign: "middle" }}
            />
          </Link>
        </Typography>
      </Stack>
    </Alert>
  );
};

const Monitoring: FC<MonitoringProps> = ({ isLoading, stats, period }) => {
  const { reports, reportsLoading } = useReportsListContext();

  // Took from UnhandledIssuesPage.tsx
  const filters = INBOX_SETTINGS.PENDING_REVIEW_FILTER;

  const reportsToShow = useMemo(
    () =>
      compose(
        fpOrderBy(["sortIndex"], ["desc"]),
        fpFilter(isRecommended),
        fpFilter((r) => !r.id?.startsWith?.("card")),
        fpFilter(
          (r) =>
            !filters.bugStatusesNot?.includes?.(r.bugStatus) &&
            (filters.archived === undefined || r.archived === filters.archived)
        )
      )(reports),
    [filters.archived, filters.bugStatusesNot, reports]
  );

  const tiles = [
    {
      title: (
        <>
          <ReportProblemOutlined
            sx={{
              fontSize: "0.9rem",
              verticalAlign: "middle",
            }}
          />{" "}
          <span>Issues Detected</span>
        </>
      ),
      description:
        "Displays the total number of issues detected by Bugpilot during the selected period.",
      number: stats?.issuesCount,
      diff: stats?.issuesCountDiff,
    },
    {
      title: (
        <>
          <AdsClickIcon
            sx={{
              fontSize: "0.9rem",
              verticalAlign: "middle",
            }}
          />{" "}
          <span>Sessions Affected</span>
        </>
      ),
      description:
        "Displays the total number of sessions affected by issues in the selected time period. One session can have multiple issues.",
      number: stats?.sessionsCount,
      diff: stats?.sessionsCountDiff,
    },
    {
      title: (
        <>
          <Person2Icon
            sx={{
              fontSize: "0.9rem",
              verticalAlign: "middle",
            }}
          />{" "}
          <span>Users Affected</span>
        </>
      ),
      description:
        "Displays the total number of users affected by issues in the selected time period.",
      number: stats?.usersCount,
      diff: stats?.usersCountDiff,
    },
    {
      title: (
        <>
          <DescriptionIcon
            sx={{
              fontSize: "0.9rem",
              verticalAlign: "middle",
            }}
          />{" "}
          <span>Pages Affected</span>
        </>
      ),
      description:
        "Displays the total number of pages affected by issues in the selected time period.",
      number: Object.keys(stats?.urlsCount ?? {}).length,
      diff: stats?.urlsCountDiff,
    },
  ];

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2}>
        <FireAlertCard reports={reportsToShow} isLoading={reportsLoading} />

        {tiles.map(({ title, description, number, diff }) => (
          <MonitoringCard
            key={description}
            title={title}
            description={description}
            number={number}
            isLoading={isLoading}
            diffWithPreviousPeriod={period === "30days" ? 0 : diff} // +=bad,-=good
          />
        ))}
      </Stack>

      <Box style={{ height: 200 }}>
        {isLoading ? (
          <ChartLoader />
        ) : (
          <Suspense fallback={<ChartLoader />}>
            <Chart stats={stats} />
          </Suspense>
        )}
      </Box>

      <Stack direction="row" spacing={2}>
        <Box flex={1} overflow="auto">
          <RecentReports
            isLoading={reportsLoading}
            reports={reportsToShow}
            period={period}
          />
        </Box>

        <Box flex={1} overflow="auto">
          <CriticalPages
            isLoading={isLoading}
            urlsCount={stats?.urlsCount}
            period={period}
          />
        </Box>

        <Box flex={1} overflow="auto">
          <CriticalUsers
            isLoading={isLoading}
            topUsersByReportsCount={stats?.topUsersByReportsCount}
            period={period}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

const MonitoringPage = () => {
  const { preferences, updatePreferences, preferencesLoading } =
    usePreferences();
  const { selectedCompanyId } = useAppContext();
  const { isLoading, handler: fetchWorkspaceStatsApi } = useLoading(
    getWorkspaceStats,
    true
  );

  const [stats, setStats] = useState<Stats>(null);

  const topBarBreadcrumbsElement = document.getElementById(
    "top-bar-breadcrumbs"
  );

  const fetchWorkspaceStats = useCallback(
    async ({ period, isSilent = false }) =>
      fetchWorkspaceStatsApi({
        companyId: selectedCompanyId,
        period,
        isSilent,
      }).then((stats) => {
        setStats({
          ...stats,
          statsByDate: stats?.statsByDate?.map((stat) => ({
            ...stat,
            date:
              period === "1day"
                ? new Date(new Date().setHours(stat.date)).toLocaleString(
                    "en-US",
                    {
                      hour: "numeric",
                      hour12: true,
                    }
                  )
                : new Date(stat.date).toLocaleDateString(
                    "en-US" || navigator.languages[0],
                    {
                      month: "short",
                      day: "numeric",
                    }
                  ),
          })),
        });
      }),
    [selectedCompanyId]
  );

  useEffect(() => {
    if (!selectedCompanyId || preferencesLoading) {
      return;
    }

    fetchWorkspaceStats({ period: preferences.monitoringPeriod ?? "1day" });

    const interval = setInterval(() => {
      fetchWorkspaceStats({
        period: preferences.monitoringPeriod ?? "1day",
        isSilent: true,
      });
    }, ms("10m"));

    return () => {
      clearInterval(interval);
    };
  }, [selectedCompanyId, preferencesLoading, preferences?.monitoringPeriod]);

  const handleUpdateMonitoringPeriod = useCallback(
    (_, value) => {
      if (!value) {
        return;
      }

      updatePreferences({
        monitoringPeriod: value,
      });
    },
    [updatePreferences]
  );

  return (
    <>
      <Monitoring
        isLoading={isLoading}
        stats={stats}
        period={preferences.monitoringPeriod ?? "1day"}
      />

      {topBarBreadcrumbsElement &&
        createPortal(
          <Stack direction="row" spacing={2}>
            {preferencesLoading ? (
              <Box />
            ) : (
              <ToggleButtonGroup
                color="secondary"
                exclusive
                value={preferences.monitoringPeriod ?? "1day"}
                onChange={handleUpdateMonitoringPeriod}
              >
                <ToggleButton
                  value="1day"
                  sx={{ fontSize: "0.857em", height: 36 }}
                >
                  24h
                </ToggleButton>
                <ToggleButton
                  value="7days"
                  sx={{ fontSize: "0.857em", height: 36 }}
                >
                  7d
                </ToggleButton>
                <ToggleButton
                  value="14days"
                  sx={{ fontSize: "0.857em", height: 36 }}
                >
                  14d
                </ToggleButton>
                <ToggleButton
                  value="30days"
                  sx={{ fontSize: "0.857em", height: 36 }}
                >
                  30d
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          </Stack>,
          topBarBreadcrumbsElement
        )}
    </>
  );
};

export default MonitoringPage;

function CountText() {
  return (
    <Tooltip
      arrow
      title="Number of sessions where one or more issues were detected, in the selected time period."
      placement="top-end"
    >
      <Typography
        component="span"
        sx={{
          fontSize: "inherit",
          fontWeight: "inherit",

          cursor: "help",

          // "&:hover": {
          textDecoration: "underline",
          textUnderlineOffset: "2px",
          textDecorationStyle: "dotted",
          // },
        }}
      >
        Count
      </Typography>
    </Tooltip>
  );
}

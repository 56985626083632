export const CATEGORIES = [
  // {
  //   category: "featured",
  //   title: "Featured integrations",
  // },
  {
    category: "notifications",
    title: "Get notified when a bug is detected",
    filterTitle: "Notifications",
  },
  {
    category: "custom",
    title: "Advanced integrations",
    filterTitle: "Advanced",
  },
  {
    category: "helpdesk",
    title: "Integrate with your help desk",
    filterTitle: "Help desk",
    deprecated:
      "We're deprecating Help desk integrations. Existing integrations will continue to work.",
  },
  {
    category: "reporting",
    title: "Get bug reports from users",
    filterTitle: "Bug reporting",
    deprecated:
      "We're deprecating Intercom integrations. We recommend you switch to the JS SDK instead.",
  },
  {
    category: "project-management",
    title: "Sync bugs with your project management tool",
    filterTitle: "Project management",
    deprecated:
      "We recommend using our Zapier, Make and Webhook integrations instead: it's easier to configure them and you will be able to map all bug report fields. Integrations you already set up will continue to work.",
  },
];

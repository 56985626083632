import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import {
  UserChip,
  getFullName,
} from "../../pages/ReportsListCells/renderUserId";
import { UserPosture } from "./AutopilotGroup/UserPosture.tsx";
import { useCallback, useMemo, useState } from "react";
import {
  CheckOutlined as CheckIcon,
  ChevronRightOutlined as ChevronRightIcon,
  ContentCopyOutlined as ContentCopyIcon,
} from "@mui/icons-material";
import CopyToClipboardBase from "../shared/CopyToClipboardBase.js";

export const UserButton = ({ report, onTabChange, showOthers = true }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const user = useMemo(() => report.user, [report]);
  const userFullName = useMemo(() => getFullName(user), [user]);

  const onViewAllDetails = useCallback(() => {
    onTabChange(null, "user");
    handleClose();
  }, [handleClose, onTabChange]);

  return (
    <Box
      aria-describedby="user-posture"
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      onClick={onViewAllDetails}
      sx={{
        cursor: "pointer",
      }}
      p={1}
    >
      <UserChip row={report} showOthers={showOthers} />

      <Popper
        id={"user-posture"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        placement="bottom-end"
      >
        <Paper
          sx={{
            mt: 2,
          }}
        >
          <Box sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Box sx={{ px: 0.5 }}>
                <Stack spacing={0.5}>
                  <Typography variant="body2" fontWeight={"bold"}>
                    {userFullName || "Unknown User"}
                  </Typography>

                  {user?.email && (
                    <CopyToClipboardBase text={user.email}>
                      {({ copy, isCopied }) => (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          justifyContent="flex-start"
                        >
                          <Typography variant="body2">{user.email}</Typography>

                          <Box>
                            <IconButton
                              color="tertiary.main"
                              variant="text"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                copy();
                              }}
                            >
                              {isCopied ? (
                                <CheckIcon sx={{ fontSize: 14 }} />
                              ) : (
                                <ContentCopyIcon sx={{ fontSize: 14 }} />
                              )}
                            </IconButton>
                          </Box>
                        </Stack>
                      )}
                    </CopyToClipboardBase>
                  )}
                </Stack>
              </Box>

              <Divider />

              <Box sx={{ px: 0.5, pb: 1 }}>
                <UserPosture />
              </Box>

              <Divider />

              <Box>
                <Button
                  variant="text"
                  size="small"
                  endIcon={<ChevronRightIcon />}
                  color="tertiary"
                  onClick={onViewAllDetails}
                >
                  View All Details
                </Button>
              </Box>
            </Stack>
          </Box>
        </Paper>
      </Popper>
    </Box>
  );
};

// This page is used to search for sessions
// At the top, there is a form to enter search parameters. For now we only support user email address
// At the bottom, we render ReportsTable component to display the search results

import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  ClearOutlined as ClearIcon,
  SearchOutlined as SearchIcon,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { noop } from "lodash";
import { useSnackbar } from "notistack";

import { ReportsTable } from "../ReportsListPage/ReportsTable.js";
import { useAppContext } from "../../contexts/AppContext.js";
import { fetchReportsAPI } from "../../api";
import useLocalStorage from "../../hooks/useLocalStorage.js";

type Props = {
  onResultClick: ({ id }: { id: string }) => void;
};

const SearchPageComponent = ({ onResultClick }: Props) => {
  const navigate = useNavigate();
  const { selectedCompanyId, currentWorkspace } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);

  const [savedSearch, setSavedSearch] = useLocalStorage(
    "search.string",
    "",
    window.sessionStorage
  );

  const [email, setEmail] = useState(searchParams.get("email") || savedSearch);
  useEffect(() => {
    setSavedSearch(email);
  }, [email, setSavedSearch]);

  const fetchReports = useCallback(async () => {
    setLoading(true);

    if (!email || !selectedCompanyId) {
      setReports([]);
      setLoading(false);
      return;
    }

    try {
      const { reports } = await fetchReportsAPI(selectedCompanyId, {
        search: email,
      });

      setReports(reports);
      setLoading(false);
    } catch (e) {
      setReports([]);
      setLoading(false);

      enqueueSnackbar("Sorry, we had trouble running your search", {
        variant: "error",
        autoHideDuration: 5 * 1000,
      });
    }
  }, [email, selectedCompanyId, enqueueSnackbar]);

  const onSearch = useCallback(() => {
    // when search is pressed, set the search query string parameter
    // This will trigger the useEffect above
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  // Event Listeners
  //

  const onEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const searchOnEnter = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onSearch();
      }
    },
    [onSearch]
  );

  // Trigger search if email comes from searchParams
  useEffect(() => {
    if (searchParams.get("email") || savedSearch) {
      onSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Report Actions
  //

  const handleOpenReport = useCallback(
    ({ id }) => {
      onResultClick({ id });
      navigate(`/report/${id}`);
    },
    [navigate, onResultClick]
  );

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Stack spacing={2} sx={{ height: "100%" }}>
        <Stack spacing={2} sx={{}}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              pt: 1,
            }}
          >
            <Box flexGrow={1}>
              <TextField
                variant="outlined"
                label="User Email"
                value={email}
                onChange={onEmailChange}
                onKeyDown={searchOnEnter}
                size="small"
                type="email"
                autoFocus
                fullWidth
                name="search-email"
                id="search-email"
                inputMode="email"
              />
            </Box>

            <Box>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={onSearch}
                startIcon={<SearchIcon />}
                loading={loading}
              >
                Search
              </LoadingButton>
            </Box>

            <Box>
              <Button
                type="reset"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setEmail("");
                  setReports([]);
                  setSearchParams({});
                }}
                startIcon={<ClearIcon />}
              >
                Clear
              </Button>
            </Box>
          </Stack>
        </Stack>

        {reports.length > 0 && (
          <Box
            sx={{
              height: "100%",
              overflow: "hidden",
            }}
          >
            <ReportsTable
              currentWorkspace={currentWorkspace}
              onOpenReport={handleOpenReport}
              openReportPreview={noop} // @TODO Restore
              reports={reports}
              // Unused
              activeTab="all"
              deletingList={[]}
              filter="search"
              reportsLoading={loading}
              latestOnly={false}
              onCreateSampleReport={() => {}}
              onDeleteReport={() => {}}
              onLoadMore={() => {}}
              setLatestOnly={false}
            />
          </Box>
        )}

        {reports.length === 0 && email && !loading && (
          <Box mt={2}>
            <Paper sx={{ p: 2 }}>
              <Typography>Your search did not return any result.</Typography>
            </Paper>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export const SearchPage = (props) => {
  return <SearchPageComponent {...props} />;
};

// TODO: Remove
export default SearchPage;

import React, { useCallback, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";

import Link from "../components/Link";
import { getFieldProps } from "../utils/getFieldProps";
import PasswordField, {
  getPasswordValidationRule,
} from "../components/PasswordField";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import { InviteSignInAddon } from "./InviteSignInAddon";
import { LoadingButton } from "@mui/lab";
import options from "../authOptions";
import { EmailOutlined } from "@mui/icons-material";

const validationSchema = Yup.object({
  email: Yup.string()
    .required("Please enter your work email address")
    .email("Please enter your work email address"),
  password: getPasswordValidationRule({ strict: false }),
});

const SignIn = ({
  email = "",
  error: defaultError = null,
  onSignIn,
  federatedSignIn,
}) => {
  const [error, setError] = useState(defaultError);
  const [showEmailLogin, setShowEmailLogin] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onAuthClick = useCallback(
    (providerName) => () => {
      federatedSignIn(providerName);
      setSubmitting(true);

      setTimeout(() => {
        setSubmitting(false);
      }, 5 * 1000);
    },
    [federatedSignIn]
  );

  const formik = useFormik({
    initialValues: {
      email,
      password: "",
    },
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async ({ email, password }) => {
      try {
        setError(null);
        await onSignIn(email, password);
      } catch (e) {
        console.error(e);
        setError(e.message);
        throw e;
      }
    },
  });

  const handleSignInClick = useCallback(() => {
    formik.submitForm();
  }, [formik]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100dvh",
        overflow: "auto",
        width: "100%",
      }}
    >
      <AppBar
        position="absolute"
        sx={{
          top: 0,
          left: 0,
          right: 0,
          height: 62,
          padding: "0 1rem",
          borderRight: "none",
          borderLeft: "none",
          boxShadow: "none",
        }}
        variant="outlined"
        color="transparent"
        elevation={0}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Box>
            <img src="/logo.svg" alt="Bugpilot" width={120} />
          </Box>
          <Box>
            <Button
              size="small"
              variant="outlined"
              color="tertiary"
              href="/signup"
            >
              Create an account
            </Button>
          </Box>
        </Stack>
      </AppBar>

      <Stack
        sx={{
          maxWidth: 400,
          width: "100%",
          paddingX: 4,
          position: "relative",
        }}
        spacing={2}
      >
        <Typography
          variant="h2"
          sx={{
            mb: 4,
            fontSize: "3em",
            textAlign: "center",
            fontWeight: "medium",
            userSelect: "none",
          }}
        >
          Log in to Bugpilot
        </Typography>

        <InviteSignInAddon />

        {showEmailLogin ? null : (
          <>
            {options.map(({ name, providerName, icon: Icon }) => (
              <Button
                key={name}
                size="large"
                fullWidth
                variant="outlined"
                color="primary"
                onClick={onAuthClick(providerName ?? name)}
                startIcon={<Icon height={25} width={25} />}
              >
                Login with {name}
              </Button>
            ))}

            <Divider sx={{ my: 2 }}>or</Divider>

            <Button
              key={"email"}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => setShowEmailLogin(true)}
              startIcon={<EmailOutlined height={25} width={25} />}
            >
              Login with email
            </Button>
          </>
        )}

        {error && (
          <FormHelperText error>
            <Typography variant="body1">{error}</Typography>
          </FormHelperText>
        )}

        {showEmailLogin ? (
          <>
            <TextField
              label="Email"
              type="email"
              required
              fullWidth
              {...getFieldProps(formik, {
                name: "email",
              })}
            />

            <PasswordField
              {...getFieldProps(formik, {
                name: "password",
              })}
            />
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSignInClick}
              loading={formik.isSubmitting}
            >
              Login
            </LoadingButton>

            <Typography variant="body1">
              <Link to="/signin/reset-password">Forgot password</Link> &middot;{" "}
              <Link onClick={() => setShowEmailLogin(false)}>
                Social logins
              </Link>
            </Typography>
          </>
        ) : null}

        {submitting ? (
          <Box
            sx={{
              position: "absolute",
              top: -20,
              left: -20,
              right: -20,
              bottom: -20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255,255,255,0.5)",
              backdropFilter: "blur(3px)",
              userSelect: "none",
            }}
          >
            <CircularProgress />
            <Typography variant="body1" sx={{ ml: 2 }}>
              Redirecting to provider&hellip;
            </Typography>
          </Box>
        ) : null}
      </Stack>
    </Box>
  );
};

export default SignIn;

import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Typography,
  Chip,
  Tooltip,
  ButtonGroup,
  Button,
  Box,
  Stack,
} from "@mui/material";

import AddIcon from "@mui/icons-material/AddOutlined";

import { STATUS_LIST } from "../../components/report/SelectBugStatus";
import { ReportCard } from "./ReportCard";
import AddNewCard from "./AddNewCard";
import { useBugTrackerContext } from "./BugTrackerContext.tsx";
import { useAuth } from "../../contexts/AuthContext";
import { HelpButton } from "../../components/Help";

export const ReportsColumn = ({
  reports,
  status,
  label,
  onClick,
  titleProps = {},
  stackProps = {},
  helpText,
  handleMoveCard,
}) => {
  const { user } = useAuth();
  const addNewRef = useRef();
  const { createCard } = useBugTrackerContext();

  const statusDef = useMemo(() => {
    return STATUS_LIST.find((s) => s.bugStatus === status);
  }, [status]);

  const colStack = useRef(null);
  const [mouseOver, setMouseOver] = useState(false);

  useEffect(() => {
    if (!colStack.current) {
      return;
    }

    colStack.current.addEventListener("mouseenter", () => setMouseOver(true));
    colStack.current.addEventListener("mouseleave", () => setMouseOver(false));

    const cur = colStack.current;
    return () => {
      cur.removeEventListener("mouseenter", () => setMouseOver(true));
      cur.removeEventListener("mouseleave", () => setMouseOver(false));
    };
  }, [colStack]);

  const handleMoveUp = useCallback(
    (id) => {
      const moveBeforeIdIndex = reports.findIndex((r) => r.id === id) - 1;
      const moveBeforeId = reports[moveBeforeIdIndex]?.id;

      handleMoveCard({
        id,
        toStatus: status,
        fromStatus: status,
        moveBeforeId,
      });
    },
    [handleMoveCard, reports, status]
  );

  const handleMoveDown = useCallback(
    (id) => {
      const moveBeforeIdIndex = reports.findIndex((r) => r.id === id) + 2;
      const moveBeforeId = reports[moveBeforeIdIndex]?.id;

      handleMoveCard({
        id,
        toStatus: status,
        fromStatus: status,
        moveBeforeId,
      });
    },
    [handleMoveCard, reports, status]
  );

  const handleMoveBefore = useCallback(
    ({ id, status, oldStatus, moveBeforeId }) =>
      handleMoveCard({
        id,
        toStatus: status,
        fromStatus: oldStatus,
        moveBeforeId,
      }),
    [handleMoveCard]
  );

  const handleMoveToEnd = useCallback(
    ({ id, status, oldStatus }) =>
      handleMoveCard({
        id,
        toStatus: status,
        fromStatus: oldStatus,
        moveBeforeId: null, // append
      }),
    [handleMoveCard]
  );

  const handleCardCreate = useCallback(
    (report) => {
      return createCard({
        ...report,
        bugStatus: status,
        assigneeSub: user.sub,
      });
    },
    [createCard]
  );

  return (
    <Box
      p={2}
      sx={{
        boxSizing: "border-box",
        borderRadius: 2,
        border: (theme) => `thin solid ${theme.palette.bg.page}`,
        bgcolor: (theme) => theme.palette.bg.board,
        height: "100%",
      }}
    >
      <Stack
        spacing={0.5}
        style={{
          height: "100%",
        }}
        ref={colStack}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          mb={1.5}
        >
          <Box
            sx={{
              color: statusDef.color,
              mt: 0.5,
            }}
          >
            {statusDef.icon}
          </Box>

          <Typography variant="h6">{label}</Typography>

          <Chip
            size="small"
            label={reports.length}
            variant={reports.length > 0 ? "default" : "outlined"}
          />

          <Box flexGrow={1} />

          {helpText && (
            <HelpButton
              title={helpText}
              placement="bottom-end"
              sx={{
                color: "muted.main",
                pr: 1,
                fontSize: 18,
                width: 24,
              }}
            />
          )}
        </Stack>

        <Box
          {...stackProps}
          sx={{
            ...stackProps.sx,
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            rowGap: 16,
            overflowX: "hidden",
            overflowY: "auto",
            paddingRight: 4,
          }}
        >
          {reports.map((report, index) => (
            <Fragment key={report.id}>
              <Box
                className="report-card-container"
                data-report-id={report.id}
                // This box is necessary to prevent the card from being
                // shrinked to fit in the column height
              >
                <ReportCard
                  previousId={reports[index - 1]?.id}
                  key={`card-${report.id}`}
                  report={report}
                  onClick={onClick}
                  status={status}
                  onMoveUp={handleMoveUp}
                  onMoveDown={handleMoveDown}
                  onMoveBefore={handleMoveBefore}
                  onMoveToEnd={handleMoveToEnd}
                  isFirst={index === 0}
                  isLast={index === reports.length - 1}
                />
              </Box>
            </Fragment>
          ))}

          <ReportCard
            key="card-placeholder"
            isDropTarget
            status={status}
            onMoveUp={handleMoveUp}
            onMoveDown={handleMoveDown}
            onMoveBefore={handleMoveBefore}
            onMoveToEnd={handleMoveToEnd}
            isFirst // no arrows
            isLast // no arrows
          />
        </Box>

        {status === "confirmed" && (
          <>
            <Box
              sx={{
                flexGrow: 1,
              }}
            />

            <AddNewCard
              ref={addNewRef}
              onCreate={handleCardCreate}
              status={status}
            />
          </>
        )}
      </Stack>
    </Box>
  );
};

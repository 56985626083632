import { useCallback, useState } from "react";
import data from "@emoji-mart/data";
import EmojiPicker from "@emoji-mart/react";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import AddReactionIcon from "@mui/icons-material/AddReactionOutlined";
import { Popper } from "@mui/material";

const EmojiSelect = ({ onChange, color, size, id, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback((event) => {
    setIsOpen((isOpen) => !isOpen);

    setAnchorEl(event.currentTarget);
  }, []);

  const handleChange = useCallback(
    (emojiObject, event) => {
      setIsOpen(false);
      return onChange?.(emojiObject, event);
    },
    [onChange]
  );

  const handleClickAway = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box display="inline-flex" sx={{ verticalAlign: "middle" }}>
        <IconButton
          color={color}
          onClick={handleOpen}
          size={size}
          id={id}
          disabled={disabled}
        >
          <AddReactionIcon
            fontSize={size}
            sx={{ ...(size === "small" ? { width: 16, height: 16 } : {}) }}
          />
        </IconButton>

        <Popper
          sx={{ zIndex: Number.MAX_SAFE_INTEGER }}
          open={isOpen}
          anchorEl={anchorEl}
          placement="left-end"
          id="helloworld"
        >
          <EmojiPicker data={data} onEmojiSelect={handleChange} />
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default EmojiSelect;

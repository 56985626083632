import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";

import CloseIcon from "@mui/icons-material/CloseOutlined";
import BusinessIcon from "@mui/icons-material/BusinessOutlined";

import Button from "../Button";

import Input from "../Input";
import { getFieldProps } from "../../utils/getFieldProps";
import ErrorText from "../ErrorText";

const DialogActions = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(4)}`,
  },
}))(MuiDialogActions);

const DialogContent = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(4)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogTitle);

const validationSchema = Yup.object({
  name: Yup.string().required().min(5),
});

const CreateCompanyModal = ({ onClose, onConfirm }) => {
  const [error, setError] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "New workspace",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await onConfirm(values);
        onClose();
      } catch (e) {
        setError(e.message !== "null" ? e.message : e.code);
      }
    },
  });

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold">
            Create new workspace
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Input
            fullWidth
            label="New workspace name"
            size="small"
            required
            {...getFieldProps(formik, {
              name: "name",
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <BusinessIcon />
                </InputAdornment>
              ),
            }}
          />

          {error && (
            <FormHelperText error>
              <ErrorText>{error}</ErrorText>
            </FormHelperText>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          bold
          disabled={formik.isSubmitting}
          onClick={formik.handleSubmit}
          endIcon={formik.isSubmitting && <CircularProgress size={25} />}
          fullWidth
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCompanyModal;

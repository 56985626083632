import React from "react";

// const DEFAULT_COVER_IMAGE =
// "https://imagedelivery.net/55PXnfNaLA7qnpEnq-80ug/97ee325d-8c77-4daf-1e63-0d58d5929100/public";
export const Logo2023 = (props) => (
  <img
    alt="bugpilot"
    src="https://imagedelivery.net/55PXnfNaLA7qnpEnq-80ug/8f47e47e-f867-458c-b8e3-aceb93404a00/original"
    srcSet="https://imagedelivery.net/55PXnfNaLA7qnpEnq-80ug/8f47e47e-f867-458c-b8e3-aceb93404a00/original 1x, https://imagedelivery.net/55PXnfNaLA7qnpEnq-80ug/76a5a548-0a9d-4a71-873a-15bd4430a200/original 2x, https://imagedelivery.net/55PXnfNaLA7qnpEnq-80ug/103dfbc7-193a-48f2-cc06-76eccec08900/original 3x"
    height={40}
    {...props}
  />
);

export const Icon2023 = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.9774 7.90003C33.9738 3.78667 29.3352 1.51166 26.222 4.09633L7.48704 19.6504C5.19762 21.5511 5.62175 25.2565 8.27783 26.5592C8.8467 26.8382 9.46855 26.9739 10.0988 26.9814C12.0528 27.0048 16.5598 27.2047 19.3957 28.4864C22.6607 29.9621 26.2033 33.998 27.5224 35.5958C27.8807 36.0298 28.3163 36.3871 28.8162 36.6324C31.2221 37.8124 34.0024 36.0078 34 33.2679L33.9774 7.90003ZM30.3915 25.25C30.6913 25.2524 30.9297 24.9968 30.9237 24.6886C30.9178 24.3804 30.6699 24.1362 30.3701 24.1334C27.6987 24.1076 25.0615 23.4931 22.6375 22.3288C20.2134 21.1645 18.0629 19.4795 16.3299 17.3898C16.1354 17.1552 15.7941 17.1163 15.5603 17.3094C15.3265 17.5025 15.2886 17.8544 15.4828 18.0894C17.3201 20.312 19.603 22.1036 22.1776 23.3402C24.7522 24.5768 27.5539 25.2273 30.3915 25.25Z"
      fill="#0D0F19"
    />
  </svg>
);

import { Typography, Card, CardContent } from "@mui/material";
import {
  AddOutlined as AddIcon,
  PersonOutlined as PersonIcon,
  JavascriptOutlined as JavascriptIcon,
} from "@mui/icons-material";
import { Box, Stack } from "@mui/system";
import { useCallback, useMemo, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

import { truncate } from "lodash";
import AssigneeBadge from "./AssigneeBadge.js";
import { PRIORITY_LIST } from "../../components/report/PriorityWizard.tsx";
import { ReportSource } from "../ReportsListCells/getColumns.js";

const CARD_STYLES = {
  cursor: "pointer",
  border: "thin solid rgba(0,0,0,0.1)",
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",

  "&:hover": {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  // maxWidth: "320px",
};
const CARDCONTENT_STYLES = {
  // no extra padding on last child
  "&:last-child": {
    paddingBottom: "16px",
  },
};

export const ReportCard = ({
  report = {},
  isDropTarget, // true if the card is not an actual report, but an invisible drop target
  onClick,
  onMoveUp,
  onMoveDown,
  isFirst,
  isLast,
  status, // the column status this card is in
  onMoveBefore,
  onMoveToEnd,
  previousId,
}) => {
  const [hover, setHover] = useState(false);

  // Drag and Drop
  //

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      canDrag: !isDropTarget,
      type: "report",
      item: { report },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [report]
  );

  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: "report",
      drop: async ({ report: draggingReport }) => {
        if (isDropTarget) {
          onMoveToEnd({
            id: draggingReport.id,
            status: status,
            oldStatus: draggingReport.bugStatus,
          });
        } else {
          onMoveBefore({
            id: draggingReport.id,
            status: status,
            oldStatus: draggingReport.bugStatus,
            moveBeforeId: report.id,
          });
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver() && monitor.canDrop(),
      }),
      canDrop: ({ report: draggingReport }) =>
        isDropTarget ||
        (report.id !== draggingReport.id && previousId !== draggingReport.id),
    }),
    [status, report, onMoveBefore, isDropTarget]
  );

  //
  // End Drag and Drop

  const handleCardClick = useCallback(
    (e) => {
      if (isDropTarget) {
        return;
      }

      onClick(report, e.shiftKey);
    },
    [report, onClick, isDropTarget]
  );

  const isNew = useMemo(() => {
    if (status === "fixed") {
      // if the report is fixed, it's not new
      return false;
    }

    const lastSeenOn = report.lastSeenOn || {};
    const hasSomeoneSeenThisReport = Object.keys(lastSeenOn).length > 0;

    return !hasSomeoneSeenThisReport;
  }, [report.lastSeenOn, status]);

  const showCompactCard = useMemo(() => {
    return status === "fixed";
  }, [status]);

  return (
    <Box
      ref={dropRef}
      style={{
        minHeight: 50,
        display: "flex",
        flexDirection: "column",
        rowGap: 16,
        userSelect: "none",
      }}
    >
      {isOver && (
        <Card
          onClick={handleCardClick}
          sx={CARD_STYLES}
          ref={dragRef}
          style={{ height: 100 }}
        >
          <CardContent sx={{ height: 100 }}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                height: 100,
              }}
            >
              <AddIcon sx={{ color: "white.border" }} />
            </Stack>
          </CardContent>
        </Card>
      )}

      {isDropTarget ? (
        <Box
          sx={{
            minHeight: 150,
          }}
        >
          {" "}
          {/* // invisible content to make the drop target work */}
        </Box>
      ) : (
        <Card
          onClick={handleCardClick}
          sx={{
            ...CARD_STYLES,

            ...(isNew
              ? {
                  borderLeft: (theme) =>
                    `3px solid ${theme.palette.secondary.dark}`,
                }
              : {
                  paddingLeft: "3px",
                }),
          }}
          ref={dragRef}
          style={{
            opacity: isDragging ? 0.4 : 1,
            transition: "opacity 0.3s",
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <CardContent sx={CARDCONTENT_STYLES}>
            <Stack spacing={0.5}>
              <Typography
                variant="caption"
                color="text.tertiary"
                fontWeight="bold"
                sx={{
                  letterSpacing: "0.5px",
                  fontSize: "0.7rem",
                }}
              >
                #{report.serialNumber}
              </Typography>

              <Typography
                flexGrow={1}
                variant="body1"
                color="text.primary"
                fontWeight={400}
                sx={{
                  lineHeight: "1.3em",
                }}
              >
                {truncate(report.title || report.id, { length: 130 })}
              </Typography>

              <Box sx={{ mb: 2 }} />

              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <PriorityBadge report={report} />

                {["widget", "sdk", "wss"].includes(
                  report.metadata?.triggerType
                ) && (
                  <Typography
                    variant="caption"
                    color="muted.dark"
                    sx={{
                      fontWeight: 400,
                    }}
                  >
                    <ReportSource row={report} />
                  </Typography>
                )}

                {report.metadata?.errorInfo ? (
                  <Typography
                    variant="caption"
                    color="muted.dark"
                    sx={{
                      fontWeight: 400,
                    }}
                  >
                    {report.metadata?.errorInfo?.numAffectedUsers ?? 1} user
                    {report.metadata?.errorInfo?.numAffectedUsers === 1
                      ? ""
                      : "s"}{" "}
                    • {report.metadata?.errorInfo?.numOccurences ?? 1} session
                    {report.metadata?.errorInfo?.numOccurences === 1
                      ? ""
                      : "s"}{" "}
                  </Typography>
                ) : (
                  <Box />
                )}

                <Box sx={{ flexGrow: 1 }} />

                <Box>
                  <AssigneeBadge report={report} />
                </Box>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

function PriorityBadge({ report }) {
  const priority = useMemo(() => {
    if (!report?.priority) {
      return null;
    }

    return PRIORITY_LIST.find((i) => i.value === report.priority);
  }, [report?.priority]);

  if (
    !report?.priority &&
    ["sdk", "widget"].includes(report?.metadata?.triggerType)
  ) {
    return (
      <Box
        sx={{
          backgroundColor: "white.dark",
          color: "text.primary",
          borderRadius: "4px",
          padding: "2px 4px",
          width: 24,
          height: 24,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="body2" component="span" display="flex">
          {report?.metadata?.triggerType === "widget" && (
            <PersonIcon sx={{ fontSize: 12 }} />
          )}
          {report?.metadata?.triggerType === "sdk" && <JavascriptIcon />}
        </Typography>
      </Box>
    );
  }

  if (!priority) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: priority.color,
        color: priority.contrastColor,
        borderRadius: "4px",
        padding: "2px 4px",
        width: 24,
        height: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="body2"
        component="span"
        sx={{
          fontSize: 12,
          textTransform: "uppercase",
          fontWeight: "bold",
        }}
      >
        {priority.icon}
      </Typography>
    </Box>
  );
}

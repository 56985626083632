import {
  Typography,
  Link,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/system";
import CodeContainer from "../../components/CodeContainer.tsx";
import { useAnalytics } from "../../analytics/AnalyticsContext";
import { getScriptEmbedCode } from "./getEmbedCode";
import { useAppContext } from "../../contexts/AppContext";
import { SettingsSection } from "../Settings/SettingsSection.tsx";

import { VerifyInstallation } from "./VerifyInstallation";

const IDENTIFY_CODE = `window.Bugpilot.identify({
  id: "foo@example.com", // Required. Replace with actual user id or user email.
  email: "foo@example.com", // Required. Replace with actual user email.
  // Optional:
  firstName: "Jane",
  lastName: "Doe",
  // ... and any other user properties you want to pass (must be json-serializable)
});`;

const FRAMEWORKS = {
  nextjs: {
    name: "Next.js",
    description: (
      <span>
        1. Install the
        <Link
          href="https://www.npmjs.com/package/@bugpilot/next"
          target="_blank"
          sx={{
            color: "inherit",
            textDecoration: "underline",
            textUnderlineOffset: 2,
            cursor: "pointer",
            pl: 0.5,
          }}
        >
          <code>@bugpilot/next</code>
        </Link>{" "}
        package with npm, pnpm, or yarn (<code>$ pnpm i @bugpilot/next</code>).
        <br />
        2. Get familiar with the package by reading the
        <Link
          href="https://www.npmjs.com/package/@bugpilot/next"
          target="_blank"
          sx={{
            color: "inherit",
            textDecoration: "underline",
            textUnderlineOffset: 2,
            cursor: "pointer",
            pl: 0.5,
          }}
        >
          README.md
        </Link>{" "}
        file.
        <br />
        3. Add the <code>&lt;Bugpilot&gt;</code> context provider in your root
        layout file as shown below.
      </span>
    ),
    code: (workspaceId) => (
      <CodeContainer
        language="tsx"
        code={`// @/app/layout.tsx
import { Bugpilot } from "@bugpilot/next";

// ...

<Bugpilot
  workspaceId="${workspaceId}"
  
  // Optional: you can provide user info here, if available,
  // or you can identify() anywhere in your down your component tree.
  user={user}

  // Optional: pass false to disable Bugpilot (e.g., in staging environments);
  // Bugpilot is always disabled when running on localhost.
  enabled={true}
>
  {/* Your app code here; Bugpilot will record any critical UX issue, */}
  {/* unhandled exception, and will capture errors printed to the console */}
  {/* Bug reports will be made available in your Bugpilot Dashboard under New Issues > Recommended */}

  {children}
</Bugpilot>`}
      />
    ),
    identifyCode: () => (
      <CodeContainer
        language="tsx"
        code={`const LoginComponent = () => {
  const { identify } = useBugpilot();

  useEffect(() => {
    // example call to identify, this can be called anywhere in your app
    // to update the user's info as soon as it's available or it changes.

    const user = {
      // Required: user id (or email if you don't have a user id)
      id: "123",

      // Recommended: user email (or id if you don't have a user email)
      email: "jane@bugpilot.io",

      // Optional: any other user properties you want to pass (must be json-serializable)
      // firstName, lastName will be used to display the user's name in the dashboard
      firstName: "Jane"
      lastName: "Doe"
    };

    identify(user);
  }, []);

  // ...
};`}
      />
    ),
  },

  js: {
    name: "Other",
    description: (
      <span>
        Copy and paste the following code snippet into the <strong>head</strong>{" "}
        section of your website. If you have installed Google Analyics, Hotjar,
        or any other analytics tool, you can add the Bugpilot script in the same
        place.
      </span>
    ),
  },
};

export const GetTrackingCode = ({ open, onClose }) => {
  const { trackEvent } = useAnalytics();
  const { selectedCompanyId, currentWorkspace } = useAppContext();
  const [framework, setFramework] = useState("nextjs");

  useEffect(() => {
    trackEvent("GettingStarted::showCodeDialog");
  }, [trackEvent]);

  const [embedCode] = useMemo(() => {
    return [
      getScriptEmbedCode({
        workspaceId: selectedCompanyId,
        scriptCustomDomainName:
          currentWorkspace.customDomainSettings?.script?.status === "active"
            ? currentWorkspace.scriptCustomDomainName
            : undefined,
      }),
    ];
  }, [
    selectedCompanyId,
    currentWorkspace.scriptCustomDomainName,
    currentWorkspace.customDomainSettings?.script?.status,
  ]);

  return (
    <Stack spacing={4}>
      <SettingsSection
        title="Add the tracking code"
        description={
          <FrameworkToggleButton
            setFramework={setFramework}
            framework={framework}
          />
        }
        leftButtons={
          <Typography variant="caption" color={"tertiary.main"}>
            This step is required to enable any of the features in Bugpilot.
          </Typography>
        }
      >
        <Typography variant="body1" color={"secondary"}>
          {FRAMEWORKS[framework].description}
        </Typography>

        <Box
          mt={2}
          sx={{
            width: "100%",
          }}
        >
          {FRAMEWORKS[framework].code ? (
            FRAMEWORKS[framework].code(selectedCompanyId)
          ) : (
            <CodeContainer language="html" code={embedCode} />
          )}
        </Box>
      </SettingsSection>

      <SettingsSection
        title="Identify users"
        description={
          <FrameworkToggleButton
            setFramework={setFramework}
            framework={framework}
          />
        }
        leftButtons={
          <Typography variant="caption" color={"tertiary.main"}>
            Recommended to get the most out of Bugpilot.{" "}
            <Link
              href="https://bugpilot.notion.site/Install-the-Bugpilot-Tracking-code-9c52426af6834d218a025165bc4552d2"
              target="_blank"
              sx={{
                color: "inherit",
                textDecoration: "underline",
                textUnderlineOffset: 2,
              }}
            >
              Read more
            </Link>{" "}
            in our help center.
          </Typography>
        }
      >
        <span>
          Bugs are easier to prioritize and fix when you know who is affected.
          Pass an user id and an email address to Bugpilot to identify your
          users. You can call <code>identify()</code> multiple times, and all
          the properties you pass will be merged.
        </span>

        {FRAMEWORKS[framework].identifyCode ? (
          FRAMEWORKS[framework].identifyCode(selectedCompanyId)
        ) : (
          <CodeContainer language="tsx" code={IDENTIFY_CODE} />
        )}
      </SettingsSection>

      <SettingsSection
        title="Verify installation"
        description={
          <span>
            Once you have added the tracking code, follow the instructions to
            verify the installation and get your first dummy bug report.
          </span>
        }
        leftButtons={
          <Typography variant="caption" color={"tertiary.main"}>
            This step is optional.
          </Typography>
        }
      >
        <VerifyInstallation />
      </SettingsSection>

      <Box my={4}>
        <Typography variant="h2" gutterBottom>
          Need help?
        </Typography>
        <Typography variant="body1" color={"secondary"}>
          Take a look at our{" "}
          <Link
            href="https://bugpilot.notion.site/Install-the-Bugpilot-Tracking-code-9c52426af6834d218a025165bc4552d2"
            target={"_blank"}
            style={{
              color: "inherit",
              textDecoration: "underline",
            }}
          >
            Installing the Bugpilot script
          </Link>{" "}
          article. If you get stuck, please contact us{" "}
          <Link
            href="mailto:support@bugpilot.io?subject=Help with script installation"
            target={"_blank"}
            style={{
              color: "inherit",
              textDecoration: "underline",
            }}
          >
            support@bugpilot.io
          </Link>
          .
        </Typography>
      </Box>
    </Stack>
  );
};

function FrameworkToggleButton({ setFramework, framework }) {
  return (
    <ToggleButtonGroup
      variant="outlined"
      color="primary"
      onChange={(e) => {
        if (e.target.value) {
          setFramework(e.target.value);
        }
      }}
      value={framework}
      exclusive
    >
      {Object.entries(FRAMEWORKS).map(([key, { name }]) => (
        <ToggleButton
          variant="outlined"
          color="secondary"
          key={key}
          size="small"
          selected={framework === key}
          value={key}
        >
          {name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

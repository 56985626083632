import { useCallback, useMemo, useState } from "react";

import { Alert, LinearProgress, Stack, Typography } from "@mui/material";
import {
  CreditCardOutlined as CreditCardIcon,
  OpenInNewOutlined as OpenInNewIcon,
  SyncOutlined as SyncIcon,
} from "@mui/icons-material";

import { useAppContext } from "../../contexts/AppContext";
import useHasRole from "../../hooks/useHasRole";
import Button from "../../components/Button";
import { CancelReasonDialog } from "../../components/CancelReasonDialog";
import { getDaysCount } from "./getDaysCount";
import { SettingsSection } from "./SettingsSection.tsx";
import { StatusChip } from "./StatusChip";
import { useModals } from "../../hooks/useModals";
import { PADDLE_PRODUCT_ID_SEAT_MONTHLY } from "../../subscription/SubscriptionStart";
import currencySymbolMap from "./currencySymbolMap";
import OnlyOwnerAlert from "../../components/OnlyOwnerAlert";

const BillingPage = ({ company, isDisabled, updateSubscriptionPlan }) => {
  const [remainDays, totalDays] = useMemo(
    () => getDaysCount(company),
    [company]
  );

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  // const isSubscriptionExpired = useMemo(
  //   () =>
  //     company?.subscriptionFinishedAt
  //       ? new Date(company.subscriptionFinishedAt * 1000) < new Date()
  //       : true,
  //   [company]
  // );

  const modals = useModals();

  const handleChangePlan = useCallback(() => {
    modals.openModal(modals.modalTypes.ChangePlan, {
      onConfirm: async ({ planId, planName }) => {
        await updateSubscriptionPlan({ planId, planName });
      },
    });
  }, [modals, updateSubscriptionPlan]);

  const isSubscriptionPastDue = useMemo(
    () => company?.subscriptionStatus === "past_due",
    [company]
  );

  const buttons = useMemo(() => {
    if (!company) {
      return null;
    }

    const _buttons = [];

    if (company.subscriptionStatus === "deleted") {
      return _buttons;
    }

    if (company.appSumoId) {
      _buttons.push(
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => window.open(company.subscriptionUpdateUrl, "_blank")}
          disabled={isDisabled}
        >
          Manage plan in AppSumo
        </Button>
      );

      return _buttons;
    }

    if (company.subscriptionCancelUrl) {
      _buttons.push(
        <Button
          variant="outlined"
          color="error"
          onClick={() => setShowCancelConfirmation(true)}
          disabled={isDisabled}
        >
          Cancel subscription
        </Button>
      );

      if (
        company.availablePlans?.length &&
        company?.subscriptionStatus === "active"
      ) {
        _buttons.push(
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleChangePlan}
            disabled={isDisabled}
            startIcon={<SyncIcon />}
          >
            Change plan
          </Button>
        );
      }
    }

    if (company.subscriptionUpdateUrl) {
      _buttons.push(
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.open(company.subscriptionUpdateUrl, "_blank")}
          disabled={isDisabled}
          startIcon={<CreditCardIcon />}
        >
          Update payment info
        </Button>
      );
    }

    return _buttons;
  }, [company, isDisabled, handleChangePlan]);

  if (!company?.subscriptionStatus) {
    return null;
  }

  return (
    <Stack spacing={3} mt={4}>
      <OnlyOwnerAlert />

      {isSubscriptionPastDue && (
        <Alert variant="filled" severity="error">
          Your payment method is past due. Please update your payment method to
          avoid service interruption.
        </Alert>
      )}

      <SettingsSection
        title="Billing"
        description="Manage your billing information and subscription."
        buttons={buttons}
        leftButtons={[
          <Button
            key="1"
            variant="text"
            color="secondary"
            startIcon={<OpenInNewIcon fontSize="small" />}
            href="https://www.bugpilot.io/pricing"
            target="_blank"
          >
            View plans
          </Button>,
        ]}
      >
        <Stack spacing={2} alignItems="flex-start">
          <Stack direction="row" spacing={1}>
            <Typography variant="body1" component="p" fontWeight="bold">
              Plan:
            </Typography>
            <StatusChip workspace={company} />
          </Stack>

          {company.paddleData?.price && company.paddleData.currency && (
            <Stack direction="row" spacing={1}>
              <Typography variant="body1" component="p" fontWeight="bold">
                Price:
              </Typography>
              <Typography variant="body1" component="p">
                {company.paddleData.price}
                {currencySymbolMap[company.paddleData.currency] ??
                  company.paddleData.currency}{" "}
                {+company.paddleData.subscriptionPlanId ===
                PADDLE_PRODUCT_ID_SEAT_MONTHLY
                  ? "monthly"
                  : "yearly"}{" "}
                ({company.paddleData.quantity} seats)
              </Typography>
            </Stack>
          )}

          {company?.appSumoId ? (
            <Typography variant="body1">
              You are on a Lifetime plan. You can manage your subscription in
              your AppSumo account.
            </Typography>
          ) : remainDays && totalDays ? (
            <Stack spacing={0.5} sx={{ width: "100%" }}>
              {remainDays > 0 ? (
                <Typography variant="body1">
                  {company?.subscriptionStatus === "trialing"
                    ? "Trial ends"
                    : "Plan renews"}{" "}
                  in {remainDays} day{remainDays !== 1 ? "s" : ""}
                </Typography>
              ) : (
                <Typography variant="body1">
                  Trial started{" "}
                  {new Intl.DateTimeFormat().format(
                    new Date(company.subscriptionStartedAt)
                  )}{" "}
                  and is now over.
                </Typography>
              )}

              <LinearProgress
                variant="determinate"
                value={(remainDays * 100) / 14}
                sx={{
                  height: 12,
                }}
              />
            </Stack>
          ) : null}

          {company?.subscriptionStatus === "trialing" && (
            <Alert severity="info">
              Billing will start on{" "}
              {new Intl.DateTimeFormat({
                date: "long",
              }).format(new Date(company.subscriptionFinishedAt))}
            </Alert>
          )}
        </Stack>
      </SettingsSection>

      {showCancelConfirmation && (
        <CancelReasonDialog
          onDontCancel={() => setShowCancelConfirmation(false)}
          onCancel={() => {
            window.open(company.subscriptionCancelUrl, "_blank");
            setShowCancelConfirmation(false);
          }}
        />
      )}
    </Stack>
  );
};

const BillingPageContainer = () => {
  const { selectedCompanyId, companies, updateSubscriptionPlan } =
    useAppContext();

  const { hasRole: isAdminOrOwner } = useHasRole("admin");

  return (
    <BillingPage
      company={companies.find(({ id }) => id === selectedCompanyId)}
      isDisabled={!isAdminOrOwner}
      updateSubscriptionPlan={updateSubscriptionPlan}
    />
  );
};

export default BillingPageContainer;

import React, { useMemo } from "react";
import emailValidator from "email-validator";
import uniq from "lodash/uniq";
import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";

import TeamMemberSelect from "../../components/TeamMembersSelect";
import EmailIcon from "../../icons/logos/EmailIcon";
import ButtonDialog from "../../components/ButtonDialog";
import FieldsSelect from "../Settings/FieldsSelect";
import { useAppContext } from "../../contexts/AppContext";

const EmailIntegration = ({
  isDisabled,
  integration,
  onDeleteIntegration,
  onCreateIntegration,
  onUpdateIntegration,
  integrations,
  company,
}) => {
  const { companyMembers } = useAppContext();

  const companyMembersEmails = useMemo(
    () => [...companyMembers.map(({ email }) => email), "all"],
    [companyMembers]
  );

  const selectedCompanyMembersEmails = useMemo(
    () =>
      (integration?.emails ?? []).filter((email) =>
        companyMembersEmails.includes(email)
      ),
    [companyMembersEmails, integration?.emails]
  );

  const additionalEmails = useMemo(
    () =>
      (integration?.emails ?? []).filter(
        (email) => !companyMembersEmails.includes(email)
      ),
    [companyMembersEmails, integration?.emails]
  );

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={2}>
          <EmailIcon />
          <Typography variant="body1" fontWeight="bold">
            Email
          </Typography>
        </Stack>

        <FormControlLabel
          control={
            <Switch
              disabled={isDisabled}
              checked={Boolean(integration)}
              onChange={(e, enabled) => {
                if (enabled) {
                  onCreateIntegration({
                    emails: ["all"],
                  });
                  return;
                }

                onDeleteIntegration(integration);
              }}
              color="success"
            />
          }
          label={integration ? "Enabled" : "Disabled"}
        />
      </Stack>

      <Typography variant="body1">
        Receive an email notification when new critical bugs are detected.
      </Typography>

      <Stack direction="row" justifyContent="flex-start">
        {integration && (
          <ButtonDialog
            title="Customize email settings"
            buttonLabel="Customize recipients"
            buttonProps={{
              color: "secondary",
              variant: "outlined",
              size: "small",
            }}
          >
            <Stack spacing={2}>
              <Typography variant="body1" gutterBottom>
                Select the team members that will receive an email notification
                when new bug reports are generated. You can customize the rules
                to send an email notification from the Integrations Settings
                button in the top right corner.
              </Typography>

              <TeamMemberSelect
                InputLabelProps={{ shrink: true }}
                fullWidth
                values={selectedCompanyMembersEmails}
                label="Send an email notification to"
                disabled={isDisabled}
                onChange={(emails) =>
                  onUpdateIntegration({
                    companyId: company.id,
                    type: integrations[0].type,
                    integration: {
                      emails: uniq([...emails, ...additionalEmails]),
                    },
                  })
                }
              />

              <FieldsSelect
                hideSelect
                hideToggle
                enabled="custom"
                inputProps={{
                  label: "Additional emails",
                }}
                placeholder="Type an aditional email"
                fields={additionalEmails}
                onChange={({ fields }) =>
                  onUpdateIntegration({
                    companyId: company.id,
                    type: integrations[0].type,
                    integration: {
                      emails: uniq([
                        ...selectedCompanyMembersEmails,
                        ...fields,
                      ]),
                    },
                  })
                }
                disabled={isDisabled}
                sx={{ marginLeft: "-16px !important" }}
                validator={(email) => {
                  const isValid = emailValidator.validate(email);

                  return {
                    isValid,
                    message: isValid ? null : "Email is not valid",
                  };
                }}
              />
            </Stack>
          </ButtonDialog>
        )}
      </Stack>
    </Stack>
  );
};

export default EmailIntegration;

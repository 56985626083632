import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";

import Link from "../components/Link";
import AsyncButton from "../components/AsyncButton";
import { getFieldProps } from "../utils/getFieldProps";
import { handleSubmit } from "../utils/formik";
import ErrorText from "../components/ErrorText";
import PasswordField, {
  getPasswordValidationRule,
} from "../components/PasswordField";
import OnboardingBackdrop from "../components/OnboardingBackdrop";

const validationSchema = Yup.object({
  password: getPasswordValidationRule(),
  confirmPassword: getPasswordValidationRule().oneOf(
    [Yup.ref("password")],
    "Passwords are not the same"
  ),
});

const NewPassword = ({ onConfirm, email }) => {
  const [error, setError] = useState(null);
  const formik = useFormik({
    initialValues: {
      email,
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async ({ email, password }) => {
      try {
        setError(null);
        await onConfirm({ email, password });
      } catch (e) {
        console.error(e);
        setError(e.message);
        throw e;
      }
    },
  });

  return (
    <OnboardingBackdrop>
      <Box maxWidth={700} width="100vw" boxSizing="border-box">
        <Paper>
          <Box p={5}>
            <Stack spacing={4} flexDirection="column">
              <Typography fontWeight="bold" variant="h6">
                New password
              </Typography>
              <PasswordField
                label="New password"
                {...getFieldProps(formik, {
                  name: "password",
                  errorWhenTouchedOrSubmitted: true,
                })}
              />
              <PasswordField
                label="Repeat new password"
                placeholder="Repeat your new password"
                {...getFieldProps(formik, {
                  name: "confirmPassword",
                  errorWhenTouchedOrSubmitted: true,
                })}
              />
              <AsyncButton
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                onClick={handleSubmit(formik)}
              >
                Set new password
              </AsyncButton>

              {error && (
                <FormHelperText error>
                  <ErrorText>{error}</ErrorText>
                </FormHelperText>
              )}

              <Typography variant="body1">
                <Link to="/signin">Back to Login</Link>
              </Typography>
            </Stack>
          </Box>
        </Paper>
      </Box>
    </OnboardingBackdrop>
  );
};

export default NewPassword;

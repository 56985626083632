/* eslint-disable react/jsx-pascal-case */
import { useCallback, useEffect, useMemo, useState } from "react";
import { get, noop } from "lodash";
import { compose, filter as fpFilter, orderBy as fpOrderBy } from "lodash/fp";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import { useAppContext } from "../../contexts/AppContext";
import { useAuth } from "../../contexts/AuthContext";

import { createDemoReport } from "../../api";
import { useReportsListContext } from "../../contexts/ReportsListContext.tsx";

import { INBOX_SETTINGS } from "./config.tsx";
import { ReportsTable } from "./ReportsTable";
import { makeReportUrl } from "../../components/report/makeReportUrl";
import { useReportsListCache } from "../../contexts/ReportsListCacheContext";
import { createPortal } from "react-dom";
import { RefreshOutlined as RefreshIcon } from "@mui/icons-material";
import { usePreferences } from "../../contexts/UserPreferencesContext";
import UnreadsCounter from "../../components/UnreadsCounter";

export const VIEW_OPTIONS = [
  {
    label: "Recommended",
    value: "priority",
  },
  {
    label: "User-reported",
    value: "user-reported",
  },
  {
    label: "All",
    value: "all",
  },
];

const viewOptionsValues = VIEW_OPTIONS.map(({ value }) => value);

const SORT_MODES = [
  {
    label: "Recommended",
    value: "priority",
    orderByParams: [["sortIndex"], ["desc"]],
  },
  {
    label: "Users affected",
    value: "users-affected",
    orderByParams: [
      (r) => get(r, "metadata.errorInfo.numAffectedUsers", 0),
      ["desc"],
    ],
  },
  {
    label: "Sessions count",
    value: "sessions-count",
    orderByParams: [
      (r) => get(r, "metadata.errorInfo.numOccurences", 0),
      ["desc"],
    ],
  },
  {
    label: "Date (newest first)",
    value: "date-newest-first",
    orderByParams: [["timestamp"], ["desc"]],
  },
  {
    label: "Date (oldest first)",
    value: "date-oldest-first",
    orderByParams: [["timestamp"], ["asc"]],
  },
];

const UnhandledIssuesPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { activeTab } = useParams();

  const { deleteReport, selectedCompanyId, companies, currentWorkspace } =
    useAppContext();
  const { setCachedReportsList, setPreviousReportsView } =
    useReportsListCache();

  const { updatePreferences, preferences, preferencesLoading } =
    usePreferences();

  const {
    reports,
    lastEvaluatedKey,
    setQueryParams,
    requestRefreshReports,
    reportsLoading,
  } = useReportsListContext();

  const { user } = useAuth();

  const filters = useMemo(() => {
    if (location.pathname.includes("issues")) {
      return INBOX_SETTINGS.PENDING_REVIEW_FILTER;
    }

    return {};
  }, [location.pathname]);

  useEffect(() => {
    // save the reports and current view to the cache, so it's ready
    // when we navigate back:
    setCachedReportsList(reports);
    setPreviousReportsView(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, reports]);

  useEffect(() => {
    setQueryParams(filters);
  }, [filters, setQueryParams]);

  const handleOpenReport = useCallback(
    (report) => navigate(makeReportUrl(report.row)),
    [navigate]
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [deletingList, setDeletingList] = useState([]);

  const sortMode = preferences.issues?.sortMode ?? SORT_MODES[0].value;

  const handleDeleteReport = useCallback(
    ({ id }) => {
      const DELETE_COMMIT_TIMEOUT = 5 * 1000;
      setDeletingList((list) => [...list, id]);

      let canceled = false;

      setTimeout(() => {
        if (canceled) {
          return;
        }
        doDelete();
      }, DELETE_COMMIT_TIMEOUT + 300);

      const doDelete = () => {
        return deleteReport(id);
      };

      const action = (snackbarId) => (
        <Button
          color="secondary"
          size="small"
          onClick={() => {
            closeSnackbar(snackbarId);
            setDeletingList((list) => list.filter((i) => i !== id));
            canceled = true;
          }}
        >
          Undo
        </Button>
      );

      enqueueSnackbar("Report deleted", {
        autoHideDuration: DELETE_COMMIT_TIMEOUT,
        disableWindowBlurListener: true,
        variant: "default",
        action,
      });
    },
    [closeSnackbar, deleteReport, enqueueSnackbar]
  );

  const reportsToShow = useMemo(
    () =>
      compose(
        fpOrderBy(
          ...SORT_MODES.find((m) => m.value === sortMode).orderByParams
        ),
        fpFilter((r) => !r.id?.startsWith?.("card")),
        fpFilter(["companyId", selectedCompanyId]),
        fpFilter(
          (r) =>
            !filters.bugStatusesNot?.includes?.(r.bugStatus) &&
            (filters.archived === undefined || r.archived === filters.archived)
        )
      )(reports),
    [
      filters.archived,
      filters.bugStatusesNot,
      reports,
      selectedCompanyId,
      sortMode,
    ]
  );

  const handleCreateSampleReport = useCallback(async () => {
    try {
      await createDemoReport({ companyId: selectedCompanyId });
    } catch (e) {
      enqueueSnackbar(`Failed to create sample report`, {
        variant: "error",
      });
    }

    requestRefreshReports();
  }, [selectedCompanyId, enqueueSnackbar, requestRefreshReports]);

  const handleLoadMore = useCallback(() => {
    if (!lastEvaluatedKey) {
      enqueueSnackbar(`No more reports to load`, {
        variant: "info",
      });
      return;
    }

    setQueryParams({ more: lastEvaluatedKey });
  }, [enqueueSnackbar, lastEvaluatedKey, setQueryParams]);

  const handleRefreshReports = useCallback(() => {
    requestRefreshReports();
  }, [requestRefreshReports]);

  const currentView = viewOptionsValues.includes(activeTab)
    ? activeTab
    : preferences.issues?.view || "priority";

  useEffect(() => {
    if (viewOptionsValues.includes(activeTab)) {
      return;
    }

    navigate(`/issues/${currentView}`);
  }, [activeTab, currentView, navigate]);

  if (!user || !companies) {
    return null;
  }

  const topBarBreadcrumbsElement = document.getElementById(
    "top-bar-breadcrumbs"
  );

  return (
    <>
      {topBarBreadcrumbsElement &&
        createPortal(
          <Stack direction="row" spacing={2} alignItems="center">
            <ToggleButtonGroup
              key="set-current-view-toggle"
              value={preferencesLoading ? undefined : currentView}
              onChange={(e, value) => {
                if (!value) {
                  return;
                }

                updatePreferences({ issues: { view: value } });
                navigate(`/issues/${value}`);
              }}
              exclusive
              disabled={reportsLoading}
            >
              {VIEW_OPTIONS.map((option) => (
                <ToggleButton
                  sx={{ fontSize: "0.857em" }}
                  key={option.value}
                  value={option.value}
                >
                  <Stack spacing={1} alignItems="center" direction="row">
                    <span>{option.label}</span>

                    {option.value === "priority" && <UnreadsCounter />}
                  </Stack>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            <Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <span style={{ color: "black" }}>Sort by:</span>

                <Select
                  size="small"
                  value={preferencesLoading ? null : sortMode}
                  onChange={(e) =>
                    updatePreferences({
                      issues: {
                        sortMode: e.target.value,
                      },
                    })
                  }
                  disabled={preferencesLoading}
                >
                  {SORT_MODES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <span>{option.label}</span>
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Box>

            <Box flexGrow={1} />

            <Box justifyContent="flex-end">
              <Button
                onClick={reportsLoading ? noop : handleRefreshReports}
                variant="outlined"
                color="tertiary"
                style={{
                  height: 36,
                }}
              >
                {reportsLoading ? (
                  <CircularProgress size={12} />
                ) : (
                  <RefreshIcon fontSize="small" />
                )}
              </Button>
            </Box>
          </Stack>,
          topBarBreadcrumbsElement
        )}

      <ReportsTable
        activeTab="possible_bugs"
        currentWorkspace={currentWorkspace}
        deletingList={deletingList}
        onCreateSampleReport={handleCreateSampleReport}
        onLoadMore={handleLoadMore}
        onOpenReport={handleOpenReport}
        reports={reportsToShow}
        reportsLoading={reportsLoading || preferencesLoading}
        setLatestOnly={noop}
        currentView={currentView}
        setCurrentView={(view) => {
          if (!view) {
            return;
          }

          updatePreferences({ issues: { view } });
          navigate(`/issues/${view}`);
        }}
      />
    </>
  );
};

const UnhandledIssuesContainer = () => {
  return <UnhandledIssuesPage />;
};

export default UnhandledIssuesContainer;

import React, { useCallback, useMemo, useState } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  Typography,
  Stack,
  Alert,
  Link,
  Button,
  Dialog,
  Chip,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";

import { useAppContext } from "../contexts/AppContext";
import Loader from "../components/Loader";
import { useModals } from "../hooks/useModals";
import useHasRole from "../hooks/useHasRole";
import { useAuth } from "../contexts/AuthContext";
import InviteLinkBox from "../components/InviteLinkBox";
import UpgradeButton from "../components/UpgradeButton";
import { useAnalytics } from "../analytics/AnalyticsContext";
import { TeamMemberRow } from "./TeamMemberRow";
import Input from "../components/Input";
import { LoadingButton } from "@mui/lab";
import InputAdornment from "@mui/material/InputAdornment";
import AddIcon from "@mui/icons-material/AddOutlined";
import { handleSubmit } from "../utils/formik";
import { getFieldProps } from "../utils/getFieldProps";
import { createPortal } from "react-dom";
import {
  OpenInNewOutlined as OpenInNewIcon,
  PersonAddOutlined as PersonAddIcon,
} from "@mui/icons-material";

const seatsLimit = {
  Small: 5,
  Team: 20,
  Enterprise: 60,
};

const InviteTeamDialog = ({
  company,
  companyMembers,
  formik,
  handleSubmit,
  handleKeyDown,
  isOwner,
}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShow(true)}
          startIcon={<PersonAddIcon />}
          disabled={!isOwner}
          sx={{
            fontSize: "0.857em",
            height: 37,
          }}
        >
          Add team member
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          href="https://www.bugpilot.io/pricing"
          target="_blank"
          startIcon={<OpenInNewIcon />}
          sx={{
            fontSize: "0.857em",
            height: 37,
          }}
        >
          View pricing
        </Button>
      </Stack>

      <Dialog open={show} maxWidth="sm" fullWidth>
        <DialogTitle>Add team member</DialogTitle>

        <DialogContent>
          {company?.appSumoId &&
          companyMembers.length >= seatsLimit[company.planId] ? (
            <UpgradeButton
              spacing={1}
              text={
                <>
                  Your limit of <b>{seatsLimit[company.planId]}</b> seats in{" "}
                  <b>{company.planName}</b> plan exceeded.
                </>
              }
            />
          ) : (
            <>
              <Typography variant="body1" mb={1}>
                Share this link with your team members to invite them to this
                Workspace; they will have access to all the reports and the
                workspace settings. After you invite them, you can assign a role
                to each member to control their access.
              </Typography>

              <Typography variant="body1" mt={2} mb={1} fontWeight="medium">
                Copy this link:
              </Typography>

              <InviteLinkBox />

              <Typography variant="body1" mt={2} mb={1} fontWeight="medium">
                Or invite them via email:
              </Typography>

              <Input
                margin="none"
                placeholder="Type an email address"
                fullWidth
                value={formik.values.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LoadingButton
                        variant="outlined"
                        color="secondary"
                        size="small"
                        loading={formik.isSubmitting}
                        onClick={handleSubmit(formik)}
                        startIcon={<AddIcon />}
                      >
                        Send email
                      </LoadingButton>
                    </InputAdornment>
                  ),
                }}
                {...getFieldProps(formik, { name: "email" })}
                onKeyDown={handleKeyDown}
              />
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setShow(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const TeamPage = ({
  companyMembers,
  user,
  onDeleteCompanyMember,
  onUpdateCompanyMember,
  company,
  formik,
}) => {
  const { hasRole: isOwner } = useHasRole();

  const handleMemberRoleChange = useCallback(
    ({ email }) =>
      (e) =>
        onUpdateCompanyMember({ email, role: e.target.value }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key !== "Enter") {
        return;
      }

      handleSubmit(formik)(e);
    },
    [formik]
  );

  const topBarBreadcrumbsElement = document.getElementById(
    "top-bar-breadcrumbs"
  );

  return (
    <Box
      maxWidth="md"
      sx={{
        m: 4,
      }}
    >
      {topBarBreadcrumbsElement &&
        createPortal(
          <InviteTeamDialog
            company={company}
            companyMembers={companyMembers}
            formik={formik}
            handleSubmit={handleSubmit}
            handleKeyDown={handleKeyDown}
            isOwner={isOwner}
          />,
          topBarBreadcrumbsElement
        )}

      <Stack>
        <Grid container direction="column" spacing={4}>
          {!isOwner && (
            <Grid item xs={12}>
              <Alert severity="info">
                Only the Workspace owner can invite team members and change
                their roles.
              </Alert>
            </Grid>
          )}

          <Paper>
            <Box p={4}>
              <Stack direction="row" alignItems="center" spacing={1} mb={4}>
                <Typography
                  sx={{
                    fontSize: "1.1rem",
                    fontWeight: 500,
                  }}
                >
                  {company.name} members
                </Typography>

                <Chip
                  size="small"
                  label={companyMembers.length || 0}
                  sx={{ my: 1 }}
                />
                <span>
                  {company.appSumoId && (
                    <>
                      /<Box component="span">{seatsLimit[company.planId]}</Box>
                    </>
                  )}
                </span>
              </Stack>

              <Box flexGrow={1} />

              <Stack
                direction="column"
                spacing={4}
                sx={{
                  width: "100%",
                }}
              >
                {companyMembers.map((member, index) => (
                  <TeamMemberRow
                    divider={index !== companyMembers.length - 1}
                    handleMemberRoleChange={handleMemberRoleChange}
                    isOwner={isOwner}
                    key={index}
                    member={member}
                    onDeleteCompanyMember={onDeleteCompanyMember}
                    user={user}
                  />
                ))}
              </Stack>
            </Box>
          </Paper>
        </Grid>
      </Stack>
    </Box>
  );
};

const getValidationSchema = ({ companyMembers, user }) =>
  Yup.object({
    email: Yup.string()
      .email("Please enter a valid email address")
      .test(
        "shared",
        "This user is already in your team",
        (value) =>
          !(companyMembers || [])
            .map(({ email }) => email.toLowerCase().trim())
            .includes(value?.trim()?.toLowerCase())
      ),
  });

const TeamPageContainer = () => {
  const {
    updateCompany,
    selectedCompanyId,
    companies,
    isCompanyMembersLoading,
    companyMembers,
    deleteCompanyMember,
    createCompanyMember,
    updateCompanyMember,
  } = useAppContext();
  const { user } = useAuth();
  const modals = useModals();
  const { trackEvent } = useAnalytics();

  const validationSchema = useMemo(
    () => getValidationSchema({ companyMembers, user }),
    [user, companyMembers]
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      role: "user",
    },
    validationSchema,
    onSubmit: async (values) => {
      await createCompanyMember(selectedCompanyId, values);
      formik.resetForm();
    },
  });

  const handleDeleteCompanyMember = useCallback(
    ({ email, id }) =>
      async () => {
        await modals.openConfirmation({
          text: `You are about to remove <${email}> from this workspace. Do you want to continue?`,
          confirmCTAHeader: "Remove user from Workspace",
          confirmCTATitle: "Yes, remove",
          closeCTATitle: "No",
          confirmCTAColor: "error",
          onConfirm: async () => {
            return deleteCompanyMember(selectedCompanyId, { email, id });
          },
        });
      },
    [deleteCompanyMember, modals, selectedCompanyId]
  );

  const handleUpdateCompanyMember = useCallback(
    async ({ email, role }) => {
      trackEvent("Update Member", {
        selectedCompanyId,
        memberEmail: email,
        memberRole: role,
      });

      await updateCompanyMember(selectedCompanyId, { email, role });
    },
    [selectedCompanyId, trackEvent, updateCompanyMember]
  );

  const handleCompanyChange = useCallback(
    (changes) => {
      return updateCompany(selectedCompanyId, changes);
    },
    [updateCompany, selectedCompanyId]
  );

  if (isCompanyMembersLoading) {
    return <Loader />;
  }

  return (
    <TeamPage
      company={companies.find(({ id }) => id === selectedCompanyId)}
      user={user}
      onCompanyChange={handleCompanyChange}
      onDeleteCompanyMember={handleDeleteCompanyMember}
      onUpdateCompanyMember={handleUpdateCompanyMember}
      companyMembers={companyMembers}
      formik={formik}
    />
  );
};

export default TeamPageContainer;

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import WarningIcon from "@mui/icons-material/WarningOutlined";
import CopyIcon from "@mui/icons-material/CopyAllOutlined";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Input,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useRef } from "react";

export const ReportShareDialog = ({ report, open, onClose }) => {
  const inputRef = useRef(null);

  const copyLink = useCallback(() => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.select();
    document.execCommand("copy");
  }, [inputRef]);

  return (
    <Dialog open={open} maxWidth="md" onBackdropClick={onClose}>
      <DialogTitle>Share report</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Stack>
            <Typography variant="body1" mb={2}>
              You can share this report with anyone by copying the following
              link
            </Typography>

            <Input
              fullWidth
              value={`https://dash.bugpilot.io/report/${report.id}?utm_source=share`}
              readOnly
              onClick={copyLink}
              sx={{
                fontSize: "0.8rem",
                fontFamily: "monospace",
                "& .MuiInputBase-input": {
                  padding: 1,
                  paddingLeft: 0,
                },
              }}
              inputProps={{
                ref: inputRef,
              }}
              endAdornment={
                <Tooltip title="Copy to clipboard" arrow>
                  <IconButton size="small" onClick={copyLink}>
                    <CopyIcon />
                  </IconButton>
                </Tooltip>
              }
            />

            <Typography variant="caption" mt={1 / 2}>
              <VisibilityIcon fontSize="inherit" /> Bugpilot users with this
              link can view
            </Typography>

            {(!report.title || report.title === "Untitled report") && (
              <Typography variant="caption" color="error" mt={2}>
                <WarningIcon fontSize="inherit" /> This report has no title, we
                recommend adding one
              </Typography>
            )}
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

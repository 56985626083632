import {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from "react";
import { noop } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import { matchSorter } from "match-sorter";

import { RefreshOutlined as RefreshIcon } from "@mui/icons-material";

import {
  Button,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Stack,
} from "@mui/material";

import { Columns } from "./Columns";
import { NoRows } from "./NoRows";
import {
  BugTrackerProvider,
  useBugTrackerContext,
} from "./BugTrackerContext.tsx";
import { useAuth } from "../../contexts/AuthContext";
import { usePreferences } from "../../contexts/UserPreferencesContext";
import { makeReportUrl } from "../../components/report/makeReportUrl";
import { useReportsListCache } from "../../contexts/ReportsListCacheContext";
import { SearchBoxSmall } from "../../components/SearchBox/SearchBox.tsx";

const BugTrackerPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const { reports, loading, refreshReports } = useBugTrackerContext();
  const { preferences, updatePreferences, preferencesLoading } =
    usePreferences();
  const { setCachedReportsList, setPreviousReportsView } =
    useReportsListCache();

  useEffect(() => {
    // save the reports and current view to the cache, so it's ready
    // when we navigate back:
    setCachedReportsList(reports);
    setPreviousReportsView(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, reports]);

  const handleClick = useCallback(
    (report, newWindow) => {
      if (newWindow) {
        window.open(makeReportUrl(report), "_blank");
        return;
      }

      navigate(makeReportUrl(report));
    },
    [navigate]
  );

  const [isShowOnlyAssignedToMe, setIsShowOnlyAssignedToMe] = useState(
    preferences.trackerPrefersMine ?? false
  );

  useEffect(() => {
    if (preferencesLoading || preferences.trackerPrefersMine === undefined) {
      return;
    }
    setIsShowOnlyAssignedToMe(preferences.trackerPrefersMine);
  }, [preferences.trackerPrefersMine, preferencesLoading]);

  const [search, setSearch] = useState("");

  const deferredSearch = useDeferredValue(search);

  const handleSearchChange = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const visibleReports = useMemo(() => {
    const result = preferencesLoading
      ? []
      : reports
          .filter((report) => !report.archived)
          .filter(
            (report) =>
              !isShowOnlyAssignedToMe || report.assigneeSub === user?.sub
          );

    return deferredSearch
      ? matchSorter(result, deferredSearch, { keys: ["title"] })
      : result;
  }, [
    isShowOnlyAssignedToMe,
    preferencesLoading,
    reports,
    deferredSearch,
    user?.sub,
  ]);

  useEffect(() => {
    refreshReports();

    const i = setInterval(() => {
      if (document.hidden) {
        return;
      }

      refreshReports();
    }, 1000 * 60 * 2);

    return () => clearInterval(i);
  }, []);

  const handleShowOnlyAssignedToMeChange = useCallback(
    (event, newValue) => {
      if (newValue === null) {
        return;
      }
      setIsShowOnlyAssignedToMe(newValue);
      updatePreferences({ trackerPrefersMine: newValue });
    },
    [updatePreferences]
  );

  const topBarBreadcrumbsElement = document.getElementById(
    "top-bar-breadcrumbs"
  );

  return (
    <Stack
      sx={{
        height: "calc(100vh - 120px)",
        overflow: "hidden",
      }}
    >
      {topBarBreadcrumbsElement &&
        createPortal(
          <Stack direction="row" spacing={2} alignItems="center">
            <ToggleButtonGroup
              key="show-only-assigned-to-me"
              value={isShowOnlyAssignedToMe}
              onChange={handleShowOnlyAssignedToMeChange}
              exclusive
              disabled={preferencesLoading}
            >
              <ToggleButton
                value={preferencesLoading ? undefined : false}
                sx={{ fontSize: "0.857em" }}
              >
                All
              </ToggleButton>
              <ToggleButton
                value={preferencesLoading ? undefined : true}
                sx={{ fontSize: "0.857em" }}
              >
                Assigned to me
              </ToggleButton>
            </ToggleButtonGroup>

            <SearchBoxSmall
              sx={{ width: 200 }}
              placeholder="Filter by title..."
              onChange={handleSearchChange}
              value={search}
              disabled={loading}
              showIcon={false}
            />

            <Box flex={1} />

            {refreshReports ? (
              <Button
                onClick={loading ? noop : refreshReports}
                variant="outlined"
                color="tertiary"
                style={{
                  height: 36,
                }}
              >
                {loading ? (
                  <CircularProgress size={12} />
                ) : (
                  <RefreshIcon fontSize="small" />
                )}
              </Button>
            ) : null}
          </Stack>,
          topBarBreadcrumbsElement
        )}

      <Columns reports={visibleReports} onClick={handleClick} />

      {!loading && !preferencesLoading && visibleReports?.length === 0 && (
        <NoRows reports={reports} onRefresh={refreshReports} />
      )}
    </Stack>
  );
};

const BugTrackerPageContainer = () => {
  return (
    <BugTrackerProvider>
      <BugTrackerPage />
    </BugTrackerProvider>
  );
};

export default BugTrackerPageContainer;

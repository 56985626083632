import { FetchQueryParams } from "../../contexts/ReportsListContext";

export const DATA_GRID_STYLES = {
  // no data grid borders
  border: "none",

  ".deleting": {
    opacity: 0.5,
    pointerEvents: "none",
  },

  ".pending": {
    opacity: 0.5,
    cursor: "not-allowed",
  },

  " .MuiDataGrid-cell": {
    backgroundColor: "#E6E3FF30",
  },
  ".new > .MuiDataGrid-cell": {
    backgroundColor: "#FFFF",
  },
  ".new > .MuiDataGrid-cell .MuiTypography-body1": {
    fontWeight: "600",
  },
  ".MuiDataGrid-row": {
    cursor: "pointer",
  },
  ".MuiDataGrid-row.Mui-selected": {
    backgroundColor: "transparent",
  },
  ".MuiDataGrid-row:hover": {
    backgroundColor: "transparent",
  },
};

export const DATA_GRID_INITIAL_PAGE_SIZE = 500;

export const DATA_GRID_PAGE_SIZES = [500];

export const DATA_GRID_INITIAL_STATE = {
  pagination: {
    pageSize: 500,
  },
  sorting: {
    sortModel: [
      {
        field: "sortIndex",
        sort: "desc",
      },
    ],
  },
};

export const FIXED_ROW_HEIGHT = 80;

export const INBOX_SETTINGS: { [key: string]: FetchQueryParams } = {
  PENDING_REVIEW_FILTER: {
    bugStatusesNot: ["not_a_bug", "confirmed", "in_progress", "fixed", ""],
    archived: false, // LEGACY
    target: "inbox",
  },
};

import { useCallback, useMemo, useState } from "react";
import * as Yup from "yup";

import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";

import { useAppContext } from "../../contexts/AppContext";
import ConfirmableInput from "../ConfirmableInput";
import { useAuth } from "../../contexts/AuthContext";

const ReportTitleBar = ({
  report,
  setReport,
  addons = [],
  handleNextReport,
  showActions,
}) => {
  const { updateReport } = useAppContext();
  const { user } = useAuth();

  const isGuest = useMemo(() => user === false, [user]);
  const [titleLoading] = useState(false);

  const title = report?.title || "";
  const handleTitleChange = useCallback(
    (value) =>
      updateReport(
        report,
        {
          title: value,
          forceTitleUpdate: true,
        },
        setReport
      ),
    [report, setReport, updateReport]
  );

  if (!report) {
    return null;
  }

  return (
    <Box
      sx={{
        overflow: "hidden",
        width: "100%",
      }}
    >
      <Stack direction="row" spacing={0.5} alignItems="center">
        {report?.serialNumber && (
          <Typography
            variant="subtitle1"
            color="text.muted"
            style={{
              fontSize: 14,
            }}
          >
            [#{report.serialNumber}]
          </Typography>
        )}

        <ConfirmableInput
          disabled={isGuest || titleLoading}
          initialValue={title}
          onChange={handleTitleChange}
          validate={Yup.string().min(1)}
          inputProps={{
            sx: {
              padding: 1,
              fontSize: 14,
              fontWeight: 500,
            },
          }}
          iconAddons={[]}
        />
      </Stack>
    </Box>
  );
};

export default ReportTitleBar;

import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
  Button,
  Avatar,
  FormControlLabel,
} from "@mui/material";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import InputAdornment from "@mui/material/InputAdornment";
import MUIButton from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";

import LockIcon from "@mui/icons-material/LockOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpenOutlined";
import FileUploadIcon from "@mui/icons-material/FileUploadOutlined";

import Input from "../components/Input";
import { getFieldProps } from "../utils/getFieldProps";
import { changeUserPassword, uploadFile } from "../api";
import KeyIcon from "../icons/KeyIcon";
import { useModals } from "../hooks/useModals";
import { useSnackbar } from "notistack";
import { Auth } from "aws-amplify";
import { useAuth } from "../contexts/AuthContext";
import { AccountPageFooter } from "../components/AccountPageFooter";
import { useAppContext } from "../contexts/AppContext";
import { CancelReasonDialog } from "../components/CancelReasonDialog";
import { DoneOutlined as DoneIcon } from "@mui/icons-material";
import Switch from "@mui/material/Switch";
import { usePreferences } from "../contexts/UserPreferencesContext";
import useLoading from "../hooks/useLoading";
import getAvatar from "../utils/getAvatar";

const validationSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  password: Yup.string(),
  email: Yup.string().email(),
});

const formControlLabelComponentProps = {
  typography: {
    variant: "body1",
  },
};

const FileUpload = ({ children, onChange }) => {
  const ref = useRef();

  const handleUpload = useCallback(() => {
    ref.current.click();
  }, []);

  const { handler: handleChange, isLoading } = useLoading(async (e) => {
    const [file] = e.target.files;

    const uploadUrl = await uploadFile();

    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(uploadUrl, {
      method: "POST",
      body: formData,
    });

    const {
      result: {
        variants: [imageUrl],
      },
    } = await response.json();

    const url = imageUrl.split("/").slice(0, -1).concat("w500").join("/");

    onChange(url);
  });

  return (
    <>
      {children({ upload: handleUpload, isLoading })}
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        ref={ref}
        onChange={handleChange}
      />
    </>
  );
};

const MFAToggleButton = ({ onEnable, onDisable, preferredMFAState }) => {
  if (preferredMFAState === "SOFTWARE_TOKEN_MFA") {
    return (
      <MUIButton
        variant="outlined"
        onClick={onDisable}
        startIcon={<LockOpenIcon />}
        color="error"
      >
        Disable
      </MUIButton>
    );
  }

  if (preferredMFAState === "") {
    <CircularProgress />;
  }

  return (
    <MUIButton variant="outlined" onClick={onEnable} startIcon={<LockIcon />}>
      Enable
    </MUIButton>
  );
};

const AccountPage = () => {
  const { user, enableMFA, disableMFA, updateUser, setUser } = useAuth();
  const { deleteMe } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const modals = useModals();

  const { preferences, updatePreferences, preferencesLoading } =
    usePreferences();

  const userAvatar = useMemo(() => getAvatar(user), [user]);

  const formik = useFormik({
    initialValues: {
      email: user?.email,
      firstName: user?.given_name !== "-" ? user?.given_name : "",
      lastName: user?.family_name !== "-" ? user?.family_name : "",
      picture: userAvatar,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      await updateUser({
        firstName: values.firstName,
        lastName: values.lastName,
        picture: values.picture,
      });
      enqueueSnackbar("Profile updated", {
        variant: "success",
      });
    },
  });

  const handleChangePassword = useCallback(() => {
    modals.openModal(modals.modalTypes.ChangePassword, {
      onConfirm: async ({ oldPassword, newPassword }) => {
        await changeUserPassword(oldPassword, newPassword);
        enqueueSnackbar("Password changed", {
          variant: "success",
        });
      },
    });
  }, [enqueueSnackbar, modals]);

  const handleEnableMFA = useCallback(() => {
    modals.openModal(modals.modalTypes.EnableMFA, {
      onConfirm: async (values) => {
        await enableMFA(values);
        setPreferredMFState("SOFTWARE_TOKEN_MFA");

        enqueueSnackbar("MFA Enabled", {
          variant: "success",
        });
      },
    });
  }, [enableMFA, enqueueSnackbar, modals]);

  const handleDisableMFA = useCallback(() => {
    modals.openModal(modals.modalTypes.DisableMFA, {
      onConfirm: async (values) => {
        await disableMFA(values);
        setPreferredMFState("");

        enqueueSnackbar("MFA Disabled", {
          variant: "success",
        });
      },
    });
  }, [disableMFA, enqueueSnackbar, modals]);

  const [preferredMFAState, setPreferredMFState] = useState("");
  useEffect(() => {
    async function loadMFAState() {
      const authUser = await Auth.currentAuthenticatedUser();
      const preferredMFA = await Auth.getPreferredMFA(authUser, {
        bypassCache: true,
      });

      setPreferredMFState(preferredMFA);
    }
    loadMFAState();
  }, []);

  const isOpenIdLogin = Boolean(user?.identities?.[0]?.providerType);
  const isOldOpenIdLogin = isOpenIdLogin && user?.username?.includes("_");

  const [showDeleteReason, setShowDeleteReason] = useState(false);

  const handleDeleteAccount = useCallback(() => {
    modals.openConfirmation({
      text: "Are you sure you want to delete your account? This action cannot be undone.",
      confirmCTAHeader: "Delete account",
      confirmCTAColor: "error",
      onConfirm: () => deleteMe(),
    });
  }, [deleteMe, modals]);

  const handleEmailChange = useCallback(() => {
    modals.openModal(modals.modalTypes.ChangeEmail, {
      email: user.email,
      onConfirm: async ({ email }) => {
        enqueueSnackbar("Email updated", {
          variant: "success",
        });
        setUser((user) => ({ ...user, email }));
        modals.closeModal();
      },
    });
  }, [modals, user?.email]);

  const emailSubscriptionPreferences =
    preferences?.emailSubscriptionPreferences;
  const reportUpdatesEmailPreference =
    emailSubscriptionPreferences?.reportUpdates;
  const weeklySummaryEmailPreference =
    emailSubscriptionPreferences?.weeklySummary;

  const handleAvatarChange = useCallback(
    (url) => {
      formik.setFieldTouched("picture", true);
      formik.setFieldValue("picture", url);
    },
    [formik]
  );

  return (
    <Box maxWidth="md">
      <Grid container spacing={4} direction="column">
        <Grid item sx={{ width: "100%" }}>
          <Typography variant="h5">My profile</Typography>
        </Grid>

        {isOpenIdLogin && (
          <Grid item>
            <Alert severity="info">
              Since you are using Sign-In with Google, some settings on this
              page are not available.
            </Alert>
          </Grid>
        )}

        <Grid item>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={4}>
              <Paper>
                <Box p={4}>
                  <Grid container spacing={4} direction="column">
                    <Grid item>
                      <Grid container spacing={4}>
                        <Grid item>
                          <Stack spacing={2}>
                            <FileUpload onChange={handleAvatarChange}>
                              {({ upload, isLoading }) => (
                                <>
                                  <Avatar
                                    alt="User Avatar"
                                    src={formik.values.picture}
                                    sx={{
                                      width: 100,
                                      height: 100,
                                      cursor: "pointer",
                                    }}
                                    onClick={upload}
                                    disabled={isLoading}
                                  />
                                  <Button
                                    size="small"
                                    startIcon={<FileUploadIcon />}
                                    onClick={upload}
                                    disabled={isLoading}
                                    endIcon={
                                      isLoading && (
                                        <CircularProgress size={20} />
                                      )
                                    }
                                  >
                                    Change
                                  </Button>
                                </>
                              )}
                            </FileUpload>
                          </Stack>
                        </Grid>

                        <Grid item flex={1}>
                          <Grid container spacing={3} direction="column">
                            <Grid item>
                              <Input
                                fullWidth
                                label="Email"
                                size="small"
                                required
                                {...getFieldProps(formik, {
                                  name: "email",
                                  disabled: true,
                                })}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {!isOpenIdLogin && (
                                        <Button
                                          color="primary"
                                          size="small"
                                          onClick={handleEmailChange}
                                        >
                                          Change
                                        </Button>
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>

                            {!isOldOpenIdLogin && (
                              <Grid item>
                                <Stack direction="row" spacing={1}>
                                  <Input
                                    fullWidth
                                    label="Password"
                                    size="small"
                                    required
                                    type="password"
                                    {...getFieldProps(formik, {
                                      name: "password",
                                      disabled: true,
                                    })}
                                    placeholder="•••••••••••"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Stack
                                            direction="row"
                                            spacing={2}
                                            display="flex"
                                            alignItems="center"
                                          >
                                            <Button
                                              color="primary"
                                              size="small"
                                              onClick={handleChangePassword}
                                            >
                                              Change
                                            </Button>
                                            <KeyIcon />
                                          </Stack>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Stack>
                              </Grid>
                            )}

                            <Grid item>
                              <Input
                                fullWidth
                                label="First name"
                                size="small"
                                required
                                {...getFieldProps(formik, {
                                  name: "firstName",
                                  disabled: isOldOpenIdLogin,
                                })}
                              />
                            </Grid>

                            <Grid item>
                              <Input
                                fullWidth
                                label="Last name"
                                size="small"
                                required
                                {...getFieldProps(formik, {
                                  name: "lastName",
                                  disabled: isOldOpenIdLogin,
                                })}
                              />
                            </Grid>

                            {formik.dirty && (
                              <Grid item>
                                <Grid container justifyContent="flex-end">
                                  <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    loading={formik.isSubmitting}
                                    startIcon={<DoneIcon />}
                                  >
                                    Save
                                  </LoadingButton>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>

              <Paper>
                <Box p={4}>
                  <Stack spacing={2} alignItems="flex-start">
                    <Typography variant="h2" gutterBottom>
                      Two-factor authentication
                    </Typography>
                    <MFAToggleButton
                      preferredMFAState={preferredMFAState}
                      onEnable={handleEnableMFA}
                      onDisable={handleDisableMFA}
                    />
                  </Stack>
                </Box>
              </Paper>

              <Paper>
                <Box p={4}>
                  <Stack spacing={2} alignItems="flex-start">
                    <Typography variant="h2" gutterBottom>
                      Email preferences
                    </Typography>
                    <FormControlLabel
                      disabled={preferencesLoading}
                      onChange={(e) =>
                        updatePreferences({
                          emailSubscriptionPreferences: {
                            reportUpdates: e.target.checked,
                          },
                        })
                      }
                      control={
                        <Switch
                          size="small"
                          checked={
                            reportUpdatesEmailPreference === undefined
                              ? true
                              : reportUpdatesEmailPreference
                          }
                        />
                      }
                      label={
                        <>
                          <Typography variant="h6">Report updates</Typography>
                          <Typography variant="body2">
                            Receive updates on your assigned reports, including
                            new comments and any changes to the report status or
                            assignee user. You will also be notified if you are
                            mentioned in a report.
                          </Typography>
                        </>
                      }
                      componentsProps={formControlLabelComponentProps}
                    />
                    <FormControlLabel
                      disabled={preferencesLoading}
                      onChange={(e) =>
                        updatePreferences({
                          emailSubscriptionPreferences: {
                            weeklySummary: e.target.checked,
                          },
                        })
                      }
                      control={
                        <Switch
                          size="small"
                          checked={
                            weeklySummaryEmailPreference === undefined
                              ? true
                              : weeklySummaryEmailPreference
                          }
                        />
                      }
                      label={
                        <>
                          <Typography variant="h6">Weekly summary</Typography>
                          <Typography variant="body2">
                            Receive weekly workspace statistics such as the
                            number of detected and fixed bugs, and more.
                          </Typography>
                        </>
                      }
                      componentsProps={formControlLabelComponentProps}
                    />
                  </Stack>
                </Box>
              </Paper>

              <Paper>
                <Box p={4}>
                  <Stack spacing={2} alignItems="flex-start">
                    <Typography variant="h2" gutterBottom>
                      Delete your account
                    </Typography>
                    <Typography variant="body1">
                      You can delete your account using the button below. Please
                      note that this will not delete any workspaces you have
                      created, unless you are the only user of such workspace.
                      The cancellation will be processed immediately. If you
                      wish to delete your workspaces, please remove all the
                      members and then proceed with the deletion of your
                      account.
                    </Typography>
                    <Button
                      variant="text"
                      color="error"
                      size="small"
                      onClick={() => setShowDeleteReason(true)}
                    >
                      Delete account
                    </Button>
                  </Stack>

                  {showDeleteReason && (
                    <CancelReasonDialog
                      open={showDeleteReason}
                      onCancel={() => handleDeleteAccount()}
                      onDontCancel={() => setShowDeleteReason(false)}
                    />
                  )}
                </Box>
              </Paper>
            </Stack>
          </form>
        </Grid>

        <Grid item>
          <AccountPageFooter />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountPage;

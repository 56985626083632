import Amplify, { Auth } from "aws-amplify";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN_NAME;
const API_PREFIX = `https://${API_DOMAIN}/v1`;

class ApiError extends Error {
  name = "ApiError";

  constructor(message, statusCode, silent = false) {
    super(message);
    this.statusCode = statusCode;
    this.silent = silent;
  }
}

const callApi = async (
  path,
  {
    body,
    method = "GET",
    withAuth = true,
    headers = {},
    authTokenType = "idToken",
    apiPrefix = API_PREFIX,
  } = {}
) => {
  let currentSession;

  if (withAuth) {
    try {
      currentSession = await Auth.currentSession();
    } catch (e) {
      if (e === "No current user") {
        await Auth.signOut();
        window.localStorage.clear();
        window.localStorage.setItem(
          "hasPreviouslySignedIn",
          Date.now().toString()
        );
        window.location = "/signin";
      }
    }
  }

  const reqUrl = path.match(/https?:\/\//) ? path : `${apiPrefix}/${path}`;
  const reqHeaders = {
    ...(["POST", "PUT", "PATCH"].includes(method.toUpperCase()) && {
      "Content-Type": "application/json",
    }),
    ...headers,
    ...(withAuth && {
      Authorization: `Bearer ${currentSession[authTokenType].jwtToken}`,
    }),
  };

  if (process.env.NODE_ENV === "development") {
    console.warn("--> API call", method, reqUrl, body || "");
  }

  const response = await fetch(reqUrl, {
    method,
    ...(body && { body: JSON.stringify(body) }),
    headers: reqHeaders,
  });

  if (!response.ok) {
    const data = await response.text();
    let message = data;
    let silent = false;
    try {
      const parsedData = JSON.parse(data);
      message = parsedData.message;
      silent = parsedData.silent;

      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (message) {
      throw new ApiError(message, response.status, silent);
    }

    const err = new Error(data);
    err.statusCode = response.status;
    throw err;
  }

  return response.json();
};

// Projects
export const fetchReportsAPI = (
  companyId,
  {
    startKey = null,
    search = "",
    withErrors = false,
    archived = false,
    bugStatuses = [],
    userReported = false,
    target = "",
  } = {}
) => {
  const searchParams = new URLSearchParams();

  searchParams.append("companyId", companyId);

  if (startKey) {
    searchParams.append("startKey", JSON.stringify(startKey));
  }

  if (search) {
    let url = "";

    try {
      url = new URL(search);
      searchParams.append("url", url?.href);
    } catch (e) {
      searchParams.append("chatUserId", search);
    }
  }

  if (withErrors) {
    searchParams.append("withErrors", "true");
  }

  if (bugStatuses?.length > 0) {
    searchParams.append("bugStatuses", bugStatuses.join(","));
  }

  if (typeof archived === "boolean") {
    searchParams.append("archived", archived.toString());
  }

  if (typeof userReported === "boolean") {
    searchParams.append("userReported", userReported.toString());
  }

  if (typeof target === "string" && target.length > 0) {
    searchParams.append("target", target);
  }

  return callApi(`reports?${searchParams}`);
};

export const fetchRelatedReports = (
  { reportId, companyId },
  { withAuth = true } = {}
) => {
  if (!reportId || !companyId) {
    return [];
  }

  const searchParams = new URLSearchParams();
  searchParams.append("target", "group");
  searchParams.append("reportGroupId", reportId);
  searchParams.append("companyId", companyId);
  return callApi(`reports?${searchParams}`, { withAuth });
};

export const deleteReport = (reportId) =>
  callApi(`reports/${reportId}`, { method: "DELETE" });

export const fetchPages = (companyId, { earliestTimestamp }) => {
  const searchParams = new URLSearchParams();
  searchParams.append("workspaceId", companyId);
  searchParams.append("earliestTimestamp", earliestTimestamp);
  searchParams.append(
    "timeZone",
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  return callApi(`pages?${searchParams}`);
};

export const getMyCompanies = async () =>
  callApi(`companies/my`, { method: "GET" });

export const updateCompany = async (companyId, body) =>
  callApi(`companies/${companyId}`, { method: "PATCH", body });

export const updateUser = async ({
  firstName,
  lastName,
  timeZone,
  howDidYouHearAboutUs,
  picture,
  locale,
}) => {
  const user = await Auth.currentAuthenticatedUser();

  const updates = omitBy(
    {
      given_name: firstName,
      family_name: lastName,
      zoneinfo: timeZone,
      "custom:howDidYouHearAboutUs": howDidYouHearAboutUs,
      picture,
      "custom:picture": picture,
      locale,
    },
    isNil
  );

  if (Object.keys(updates).length === 0) {
    return user;
  }

  await Auth.updateUserAttributes(user, updates);

  return Auth.currentAuthenticatedUser();
};

export const changeUserPassword = async (oldPassword, newPassword) => {
  const currentUser = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(currentUser, oldPassword, newPassword);
};

export const changeUserEmail = async ({ email }) => {
  const user = await Auth.currentAuthenticatedUser();
  return Auth.updateUserAttributes(user, {
    email,
  });
};

export const confirmChangeUserEmail = async ({ code }) =>
  callApi(`users/me/verify-email-change`, {
    method: "POST",
    body: { code },
    authTokenType: "accessToken",
  });

export const createConsent = async ({
  type,
  sub,
  email,
  firstName,
  lastName,
}) =>
  fetch("https://consent.iubenda.com/public/consent", {
    method: "POST",
    body: JSON.stringify({
      subject: {
        id: sub,
        email,
        first_name: firstName,
        last_name: lastName,
        verified: true,
      },
      legal_notices: [
        {
          identifier: type,
        },
      ],
    }),
    headers: {
      "Content-Type": "application/json",
      ApiKey: "RqhVnNcZKnU7wMzSAZ35eU3a9qv4enbt",
    },
  });

export const createCompanyMember = (companyId, data) =>
  callApi(`companies/${companyId}/members`, { body: data, method: "POST" });

export const updateCompanyMember = (companyId, data) =>
  callApi(`companies/${companyId}/members`, { body: data, method: "PATCH" });

export const fetchCompanyMembers = (companyId) =>
  callApi(`companies/${companyId}/members`);

export const deleteCompanyMember = (companyId, data) =>
  callApi(`companies/${companyId}/members`, { body: data, method: "DELETE" });

export const createIntegration = (companyId, data) =>
  callApi(`companies/${companyId}/integrations`, {
    body: data,
    method: "POST",
  });

export const updateIntegration = ({ companyId, type, integration }) =>
  callApi(`companies/${companyId}/integrations/${type}`, {
    body: integration,
    method: "PATCH",
  });

export const deleteIntegration = ({ companyId, type }) =>
  callApi(`companies/${companyId}/integrations/${type}`, {
    method: "DELETE",
  });

export const listWorkspaceIntegrations = (companyId) =>
  callApi(`companies/${companyId}/integrations/list`);

export const listWorkspaceSourcemaps = (companyId) =>
  callApi(`companies/${companyId}/sourcemaps/list`);

export const getAtlassianIntegrationProjects = (companyId) =>
  callApi(`companies/${companyId}/integrations/atlassian/projects`);

export const getAtlassianIntegrationIssueTypes = (companyId) =>
  callApi(`companies/${companyId}/integrations/atlassian/issue-types`);

export const getNotionIntegrationPages = (companyId) =>
  callApi(`companies/${companyId}/integrations/notion/pages`);

export const confirmCompanyMembershipByInvitationCode = (code) =>
  callApi(`confirm-membership-by-invitation/${code}`, { method: "POST" });

export const updateReport = (report, updates, { triggerSource } = {}) =>
  callApi(
    `reports/${report.id}?${
      triggerSource ? `triggerSource=${triggerSource}&` : ""
    }v=dash`,
    {
      method: "PATCH",
      body: updates,
      apiPrefix: API_PREFIX,
    }
  );

export const fetchReportContents = async (signedContentsUrl) => {
  if (!signedContentsUrl) {
    return [];
  }

  const response = await fetch(signedContentsUrl, {})
    .then((res) => res.text())
    .catch((e) => {
      console.error("fetchReportContents error", e);
      return "";
    });

  return response
    .split("\n")
    .map((line) => {
      try {
        const parsed = JSON.parse(line);
        if (parsed.timestamp && !Number.isFinite(parsed.timestamp)) {
          parsed.timestamp = new Date(parsed.timestamp).getTime();
        }

        if (parsed._pushTimestamp && !Number.isFinite(parsed._pushTimestamp)) {
          parsed._pushTimestamp = new Date(parsed._pushTimestamp).getTime();
        }

        return parsed;
      } catch (e) {
        console.log("Cannot parse contents line", line);
        return null;
      }
    })
    .filter(Boolean);
};

export const createDemoReport = ({ companyId }) =>
  callApi(`demo-report?companyId=${companyId}`, {
    method: "POST",
  });

export const getReport = ({ reportId, userId }, { withAuth = true } = {}) => {
  // keep utm params for server-side analytics
  const query = new URLSearchParams(window.location.search);
  query.set("userId", userId);

  return callApi(`reports/${reportId}?${query.toString()}`, {
    apiPrefix: API_PREFIX,
    withAuth,
  });
};

export const getReportAudio = ({ audio, v }) =>
  v === "20220625" && audio
    ? Promise.all(
        audio.map(async (url) => {
          const blob = await fetch(url).then((b) => b.blob());
          const audioUrl = URL.createObjectURL(blob);
          return audioUrl;
        })
      )
    : [];

export const getReportActivities = async ({ activities, v }) =>
  (
    await Promise.all(
      activities.map(async (url) => {
        if (v !== "20230315") {
          return fetch(url).then((b) => b.json());
        }

        const response = await fetch(url);
        const body = await response.text();
        if (!body) {
          console.error("Attention: empty activity file", url);
          return [];
        }

        try {
          return body.split("\n").map((record) => JSON.parse(record));
        } catch (e) {
          console.error("Error parsing activities", e);
          return [];
        }
      })
    )
  ).flat(1);

export const callAIApi = async ({ path, body }) => {
  const AI_ENDPOINT = `https://api.bugpilot.io/ai/autocomplete`;

  const amplifyToken = await Amplify.Auth.currentSession().then((session) =>
    session.getIdToken().getJwtToken()
  );

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${amplifyToken}`,
  };

  const result = await fetch(`${AI_ENDPOINT}${path}`, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  })
    .then((b) => b.json())
    .catch((e) => ({
      ok: false,
      error: e,
      text: `Sorry, an error occurred: ${e.message || e}`,
    }));

  return result;
};

export const getLiveChatOrganizationId = async ({ companyId }) => {
  const data = await callApi(
    `https://api.bugpilot.io/livechat/status?workspaceId=${companyId}`,
    {
      withAuth: false,
      headers: {
        "x-api-key": "4d0aeece-7d73-43d2-a1fd-d7b2cb339b04",
      },
    }
  );

  return data?.organizationId;
};

export const getIntercomAppId = async ({ companyId }) => {
  const data = await callApi(
    `https://bugpilot-intercom.adoptoapp.workers.dev/status?workspaceId=${companyId}&_=${Date.now()}`,
    {
      withAuth: false,
      headers: {
        "x-api-key": "66ab739d-8c36-4468-8d68-81ac88b5eb52",
      },
    }
  );

  return data?.intercomAppId;
};

export const getCrispWebsiteId = async ({ companyId }) => {
  const data = await callApi(
    `https://api.bugpilot.io/crisp/status?workspaceId=${companyId}`,
    {
      withAuth: false,
      headers: {
        "x-api-key": "ab3d9812-3108-407f-92f9-0bb882ca4e88",
      },
    }
  );

  return data?.websiteId;
};

export const getHelpScoutData = async ({ companyId }) =>
  callApi(`https://api.bugpilot.io/helpscout/status?workspaceId=${companyId}`, {
    withAuth: false,
    headers: {
      "x-api-key": "ed7b4574-adb9-431e-b1cf-eb4f01efa838",
    },
  });

export const createHelpScoutData = async ({ companyId }) =>
  callApi("https://api.bugpilot.io/helpscout/create", {
    method: "POST",
    withAuth: false,
    headers: {
      "x-api-key": "ed7b4574-adb9-431e-b1cf-eb4f01efa838",
    },
    body: { workspaceId: companyId },
  });

export const getZendeskSubdomain = async ({ companyId }) => {
  const data = await callApi(
    `https://api.bugpilot.io/zendesk/status?workspaceId=${companyId}`,
    {
      withAuth: false,
      headers: {
        "x-api-key": "bc60cb6c-4730-440d-a67e-e9fc8169ac77",
      },
    }
  );

  return data?.subdomain;
};

export const getFrontTeamId = async ({ companyId }) => {
  const data = await callApi(
    `https://api.bugpilot.io/front/status?workspaceId=${companyId}`,
    {
      withAuth: false,
      headers: {
        "x-api-key": "3e2ca236-e617-4706-94c7-17da6ab47cfc",
      },
    }
  );

  return data?.teamId;
};

export const getSubscriptions = () => callApi("subscriptions");

export const createCheckout = async ({ companyId, planId }) =>
  callApi("paddle/checkout", {
    method: "POST",
    body: { companyId, planId },
  });

export const updateSubscriptionPlan = async ({ companyId, planId }) =>
  callApi(`companies/${companyId}/subscription/plan`, {
    method: "PATCH",
    body: { planId },
  });

export const getClickUpIntegrationWorkspaces = ({ companyId, integrationId }) =>
  callApi(
    `companies/${companyId}/integrations/${integrationId}/clickup/workspaces`
  );

export const getClickUpIntegrationLists = ({
  companyId,
  integrationId,
  workspaceId,
}) =>
  callApi(
    `companies/${companyId}/integrations/${integrationId}/clickup/workspaces/${workspaceId}/lists`
  );

export const getGitHubIntegrationRepos = ({ companyId, integrationId }) =>
  callApi(`companies/${companyId}/integrations/${integrationId}/github/repos`);

export const getGitHubIntegrationProjects = ({ companyId, integrationId }) =>
  callApi(
    `companies/${companyId}/integrations/${integrationId}/github/projects`
  );

export const deleteMe = () =>
  callApi("users/me", {
    method: "DELETE",
  });

export const getReportMessagePreview = ({ report, messageTemplate }) =>
  callApi("report-message-preview", {
    method: "POST",
    body: { report, messageTemplate },
  });

export const notifyReporter = (reportId, { subject, text }) =>
  callApi(`reports/${reportId}/notify-reporter`, {
    method: "POST",
    body: { subject, text },
  });

export const muteError = ({ companyId, data, type }) =>
  callApi(`companies/${companyId}/errors/mute`, {
    method: "POST",
    body: { data, type },
  });

export const unmuteError = ({ companyId, errorId }) =>
  callApi(`companies/${companyId}/errors/${errorId}/unmute`, {
    method: "POST",
  });

export const getWorkspaceMutedErrors = ({ companyId }) =>
  callApi(`companies/${companyId}/muted-errors`, {
    method: "GET",
  });

export const getWorkspaceNotifyReporterTemplates = ({ companyId }) =>
  callApi(`companies/${companyId}/notify-reporter-templates`, {
    method: "GET",
  });

export const createWorkspaceNotifyReporterTemplate = ({
  companyId,
  ...template
}) =>
  callApi(`companies/${companyId}/notify-reporter-templates`, {
    method: "POST",
    body: template,
  });

export const updateWorkspaceNotifyReporterTemplate = ({
  companyId,
  id,
  ...template
}) =>
  callApi(`companies/${companyId}/notify-reporter-templates/${id}`, {
    method: "PATCH",
    body: template,
  });

export const deleteWorkspaceNotifyReporterTemplate = ({ companyId, id }) =>
  callApi(`companies/${companyId}/notify-reporter-templates/${id}`, {
    method: "DELETE",
  });

export const createCompany = (company) =>
  callApi("companies", {
    method: "POST",
    body: company,
  });

export const createCompanyCustomDomain = ({ companyId, type, domainName }) =>
  callApi(`companies/${companyId}/custom-domain`, {
    method: "POST",
    body: {
      type,
      domainName,
    },
  });

export const updateCompanyCustomDomainStatus = ({ companyId, type }) =>
  callApi(`companies/${companyId}/custom-domain/${type}`, {
    method: "PATCH",
  });

export const deleteCompanyCustomDomain = ({ companyId, type }) =>
  callApi(`companies/${companyId}/custom-domain/${type}`, {
    method: "DELETE",
  });

export const getIsUserOnline = (sessionId) =>
  callApi(`https://wss.bugpilot.io/status/${sessionId}`, {
    method: "GET",
  });

export const getReportComments = (reportId) =>
  callApi(`reports/${reportId}/comments`, {
    method: "GET",
  });

export const createReportComment = (reportId, body) =>
  callApi(`reports/${reportId}/comments`, {
    method: "POST",
    body,
  });

export const updateComment = (commentId, body) =>
  callApi(`comments/${commentId}`, {
    method: "PATCH",
    body,
  });

export const deleteComment = (commentId) =>
  callApi(`comments/${commentId}`, {
    method: "DELETE",
  });

export const getCommentAttachmentUploadUrl = (reportId, { contentType }) =>
  callApi(`reports/${reportId}/comments/attachments`, {
    method: "POST",
    body: { contentType },
  });

export const getReportChangeLogs = (reportId) =>
  callApi(`reports/${reportId}/change-logs`, {
    method: "GET",
  });

export const getMyNotifications = (companyId) =>
  callApi(`notifications/${companyId}`, {
    method: "GET",
  });

export const markNotificationAsRead = ({ companyId, notificationId }) =>
  callApi(`notifications/${companyId}/${notificationId}`, {
    method: "PATCH",
  });

export const createWorkspaceCard = (report) =>
  callApi(`reports`, {
    apiPrefix: API_PREFIX,
    method: "POST",
    body: report,
  });

export const askAI = (workspaceId, body) =>
  callApi(`companies/${workspaceId}/ai`, {
    method: "POST",
    body,
  });

export const getReportAutopilot = ({ reportId }, { withAuth = true } = {}) =>
  callApi(`reports/${reportId}/autopilot`, {
    apiPrefix: API_PREFIX,
    method: "GET",
    withAuth,
  });

export const getWorkspaceStats = ({ companyId, period }) =>
  callApi(`companies/${companyId}/stats?period=${period}`, {
    method: "GET",
  });

export const uploadFile = () =>
  callApi(`images/upload`, {
    method: "POST",
    body: {},
  });

import GoogleIcon from "./icons/logos/GoogleIcon";
import GitHubIcon from "./icons/logos/GitHubIcon";
import GitLabIcon from "./icons/logos/GitLabIcon";
import BitbucketIcon from "./icons/logos/BitbucketIcon";

const options = [
  {
    providerName: "google",
    name: "Google",
    icon: GoogleIcon,
  },
  {
    name: "GitHub",
    icon: GitHubIcon,
  },
  // https://about.gitlab.com/press/press-kit/
  {
    name: "GitLab",
    icon: GitLabIcon,
  },
  {
    name: "Bitbucket",
    icon: BitbucketIcon,
  },
];

export default options;

import React from "react";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { EditorState, Modifier } from "draft-js";
import { keyBy, orderBy, times } from "lodash";

import { Skeleton, Stack, Box } from "@mui/material";

import { useAppContext } from "../../../contexts/AppContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useModals } from "../../../hooks/useModals";
import { useAnalytics } from "../../../analytics/AnalyticsContext";
import { CommentInputComponentWithoutRef } from "./CommentInputComponentWithoutRef.tsx";
import { Activities } from "./Activities.tsx";
import { CommentInput, EditorRef } from "./types.ts";

export const CommentInputComponent = forwardRef(
  CommentInputComponentWithoutRef
);

const Loader = ({ report }) => {
  const skeletonsCount = Math.min(10, Math.max(3, report?.commentsCount || 3));

  return (
    <Stack spacing={1}>
      {times(skeletonsCount, (i) => (
        <Stack direction="row" spacing={1} key={i}>
          <Skeleton
            width={32}
            height={26}
            sx={{
              backgroundColor: "white.dark",
            }}
          />

          <Box flex={1}>
            <Skeleton
              height={26}
              sx={{
                backgroundColor: "white.dark",
              }}
            />
          </Box>

          <Skeleton
            width={32}
            height={26}
            sx={{
              backgroundColor: "white.dark",
            }}
          />
        </Stack>
      ))}
    </Stack>
  );
};

const ActivitiesContainer = ({ report, company, setReport }) => {
  const {
    comments,
    fetchReportComments,
    createReportComment,
    updateComment,
    deleteComment,
    companyMembers,
    fetchReportChangeLogs,
    changeLogs,
    isCommentsLoading,
    isChangeLogsLoading,
  } = useAppContext();
  const { user } = useAuth();
  const { trackEvent } = useAnalytics();

  const inputRef = useRef<EditorRef>();

  useEffect(() => {
    if (!report.id) {
      return;
    }

    fetchReportChangeLogs(report.id);
    fetchReportComments(report.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.id]);

  const handleUpdateComment = useCallback(
    (commentId: string, comment: CommentInput) => {
      trackEvent("Edit comment");
      return updateComment(commentId, comment);
    },
    [updateComment, trackEvent]
  );

  const [replyToId, setReplyToId] = useState(null);

  const handleCreateComment = useCallback(
    (comment: CommentInput) => {
      if (!inputRef.current) {
        return;
      }

      inputRef.current.editorState = EditorState.createEmpty(
        inputRef.current.editorState.getDecorator()
      );
      trackEvent("Create comment");
      setReplyToId(null);

      return createReportComment(report.id, {
        ...comment,
        replyToId,
      });
    },
    [createReportComment, replyToId, report.id, trackEvent]
  );

  const modals = useModals();

  const handleDelete = useCallback(
    (commentId: string) => {
      trackEvent("Delete comment clicked");
      modals.openConfirmation({
        text: "Are you sure you want to delete your comment? This action cannot be undone.",
        confirmCTAHeader: "Delete comment",
        confirmCTAColor: "error",
        onConfirm: () => {
          trackEvent("Delete comment confirmed");
          return deleteComment(commentId);
        },
      });
    },
    [trackEvent, deleteComment, modals]
  );

  const companyMembersById = useMemo(
    () => keyBy(companyMembers, "id"),
    [companyMembers]
  );

  const activitiesWithAuthor = useMemo(
    () =>
      orderBy(
        [...comments, ...changeLogs].map((item) => ({
          ...item,
          ...(item.type === "comment" && {
            author: companyMembersById[item.authorId],
            reactions: item.reactions.map((reaction) => ({
              ...reaction,
              author: companyMembersById[reaction.authorId],
            })),
          }),
          ...(item.type === "change-log" && {
            author: item.createdBy
              ? companyMembersById[item.createdBy.sub]
              : null,
          }),
        })),
        ["createdAt"],
        ["asc"]
      ),
    [comments, changeLogs, companyMembersById]
  );

  const handleReplyToComment = useCallback(
    ({ id, author }) => {
      if (!inputRef.current) {
        return;
      }

      trackEvent("Reply to comment clicked");

      const mention = `<${author.name || author.email}|${author.id}>`;
      const editorState = inputRef.current.editorState;
      const contentState = editorState.getCurrentContent();
      const entityKey = contentState
        .createEntity("AC_ITEM", "IMMUTABLE", {
          id: author.id,
        })
        .getLastCreatedEntityKey();

      let newContentState = Modifier.insertText(
        contentState,
        editorState.getSelection(),
        mention,
        editorState.getCurrentInlineStyle(),
        entityKey
      );

      let newEditorState = EditorState.push(
        editorState,
        newContentState,
        "insert-characters"
      );

      newContentState = Modifier.insertText(
        newEditorState.getCurrentContent(),
        newEditorState.getSelection(),
        " ",
        newEditorState.getCurrentInlineStyle()
      );

      newEditorState = EditorState.push(
        newEditorState,
        newContentState,
        "insert-characters"
      );

      inputRef.current.editorState = newEditorState;
      inputRef.current.focus();

      setReplyToId(id);
    },
    [trackEvent, inputRef]
  );

  if (!report.companyId || !company.id || report.companyId !== company.id) {
    return null;
  }

  if (isCommentsLoading || isChangeLogsLoading) {
    return <Loader report={report} />;
  }

  return (
    <Activities
      key={report.id}
      ref={inputRef}
      items={activitiesWithAuthor}
      onCreate={handleCreateComment}
      onReply={handleReplyToComment}
      user={user}
      onUpdate={handleUpdateComment}
      onDelete={handleDelete}
      companyMembers={companyMembers}
      reportId={report.id}
      report={report}
      setReport={setReport}
    />
  );
};

export default ActivitiesContainer;

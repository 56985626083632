import * as renderUserId from "./renderUserId";
import * as renderTimestamp from "./renderTimestamp";
import * as renderReportId from "./renderReportId";

import { Box } from "@mui/system";
import { Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { CircleOutlined as CircleIcon } from "@mui/icons-material";

const loadingRenderer = () => (
  <Skeleton variant="rounded" animation="wave" width={300} height={20} />
);
const emptyFormatter = () => "";
const voidComparator = () => 0;

export const ReportSource = ({ row }) => {
  const { metadata } = row;
  let label;

  if (metadata?.triggerType === "widget") {
    label = "User report";
  }

  if (metadata?.triggerType === "autopilot") {
    label = "Autopilot";
  }

  if (metadata?.triggerType === "sdk") {
    label = "SDK";
  }

  if (
    metadata?.triggerType === "wss" &&
    metadata?.triggerSource?.includes?.("helpdesk")
  ) {
    label = "Helpdesk";
  }

  if (metadata?.triggerType === "helpdesk-listener") {
    label = "Helpdesk chat bubble";
  }

  return (
    <Typography variant="caption" color="tertiary.main">
      {label}
    </Typography>
  );
};

const getDomainName = (url) => {
  if (!url) {
    return "";
  }

  const urlObj = new URL(url);
  return urlObj.hostname;
};

export const getColumns = ({ currentWorkspace }) => [
  {
    field: "report_id",
    headerName: "Report ID",
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        {/* Box with number of occurrences */}
        <Tooltip
          arrow
          title={`${row.metadata?.errorInfo?.numOccurences || 1} session${
            (row.metadata?.errorInfo?.numOccurences || 1) === 1 ? "" : "s"
          } affected`}
        >
          <Box
            sx={{
              width: 40,
              height: 40,
              textAlign: "center",
              backgroundColor: "text.primary",
              borderRadius: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: (row.metadata?.errorInfo?.numOccurences || 1) / 10,
            }}
          >
            <Typography
              variant="body2"
              color="white.main"
              fontWeight="normal"
              sx={{
                fontSize:
                  row.metadata?.errorInfo?.numOccurences > 10000
                    ? 12
                    : row.metadata?.errorInfo?.numOccurences > 1000
                    ? 14
                    : row.metadata?.errorInfo?.numOccurences > 100
                    ? 18
                    : 20,
              }}
            >
              {row.metadata?.errorInfo?.numOccurences || 1}
            </Typography>
          </Box>
        </Tooltip>

        <Stack
          gap={0.5}
          sx={{
            width: "100%",
            overflow: "hidden",
          }}
        >
          <Stack direction="row" gap={0.5} alignItems="center">
            <Typography
              variant="caption"
              color="tertiary.main"
              fontWeight="bold"
            >
              #{typeof row.serialNumber === "number" ? row.serialNumber : ""}
            </Typography>

            <CircleIcon sx={{ fontSize: 4, color: "tertiary.main" }} />

            <Typography variant="caption" color="tertiary.main">
              {getDomainName(row.url)}
            </Typography>

            <CircleIcon sx={{ fontSize: 4, color: "tertiary.main" }} />

            <ReportSource row={row} />
          </Stack>

          <Typography variant="body1" color="secondary.main" noWrap>
            {row.title
              ? row.title
              : row.metadata?.userProvidedDescription || "No title"}
          </Typography>
        </Stack>
      </Stack>
    ),
    valueFormatter: renderReportId.valueFormatter,
    sortComparator: renderReportId.sortComparator,
  },
  {
    field: "user_id",
    headerName: "User",
    sortable: false,
    width: 150,
    align: "left",
    renderCell: renderUserId.render,
    valueFormatter: renderUserId.valueFormatter,
    sortComparator: renderUserId.sortComparator,
  },
  {
    field: "timestamp",
    headerName: (
      <Box style={{ width: "100%", textAlign: "right", paddingRight: 6 }}>
        Date
      </Box>
    ),
    width: 100,
    type: "dateTime",
    sortable: true,
    align: "right",
    renderCell: renderTimestamp.getRenderer({ currentWorkspace }),
    valueFormatter: renderTimestamp.valueFormatter,
    sortComparator: renderTimestamp.sortComparator,
  },
];

export const getLoadingColumns = () =>
  getColumns({ currentWorkspace: null }).map((col) => ({
    ...col,
    renderCell: loadingRenderer,
    valueFormatter: emptyFormatter,
    sortComparator: voidComparator,
  }));

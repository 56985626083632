const getAvatar = (member) => {
  if (!member) {
    return null;
  }

  if (member["custom:picture"]) {
    return member["custom:picture"];
  }

  if (member.picture) {
    return member.picture;
  }

  if (!member.email) {
    return null;
  }

  // multiavatar.com
  const hash = member.email.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

  return `https://api.multiavatar.com/${hash}.svg?apikey=obgW13ZVXt2JDz`;
};

export default getAvatar;

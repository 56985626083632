import React from "react";
import { useCallback, useMemo } from "react";
import groupBy from "lodash/groupBy";
import { Tooltip, Chip, Avatar, Typography, Box, Stack } from "@mui/material";

export const ShowReactions = ({ reactions, onUpdate, user }) => {
  const reactionsByEmoji = useMemo(
    () =>
      Object.entries(groupBy(reactions, "emoji")).map(([emoji, reactions]) => ({
        emoji,
        reactions,
      })),
    [reactions]
  );

  const handleReactionClick = useCallback(
    (emoji) => () => {
      const isReacted = reactions.some(
        ({ authorId, ...reaction }) =>
          authorId === user.sub && emoji === reaction.emoji
      );

      return onUpdate({
        reaction: {
          emoji,
          operation: isReacted ? "remove" : "add",
        },
        reactions: isReacted
          ? reactions.filter(
              (reaction) =>
                reaction.emoji !== emoji || reaction.authorId !== user.sub
            )
          : [
              ...reactions,
              {
                emoji,
              },
            ],
      });
    },
    [reactions, onUpdate, user.sub]
  );

  return (
    <Box mt={0.5}>
      <Stack direction="row" spacing={0.5}>
        {reactionsByEmoji.map(({ emoji, reactions }) => (
          <Tooltip
            arrow
            key={emoji}
            title={
              <Typography variant="body2">
                {reactions
                  .map(({ authorId, author }) =>
                    authorId === user.sub ? "You" : author?.name
                  )
                  .join(", ")}
              </Typography>
            }
          >
            <Chip
              clickable
              sx={{
                backgroundColor: "tertiary.light",

                borderColor: reactions.some(
                  ({ authorId }) => authorId === user.sub
                )
                  ? "primary.main"
                  : "tertiary.light",
              }}
              size="small"
              variant="outlined"
              label={reactions.length}
              avatar={
                <Avatar
                  sx={{ backgroundColor: "transparent", fontSize: "1rem" }}
                >
                  {emoji}
                </Avatar>
              }
              onClick={handleReactionClick(emoji)}
            />
          </Tooltip>
        ))}
      </Stack>
    </Box>
  );
};

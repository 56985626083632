import { useCallback, useEffect, useMemo } from "react";
import format from "date-fns/format";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmailOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ScreenShareIcon from "@mui/icons-material/ScreenShareOutlined";

import { getFieldProps } from "../../utils/getFieldProps";
import Input from "../Input";
import Button from "../Button";
import Modal from "../Modal";
import { useAppContext } from "../../contexts/AppContext";
import Loader from "../Loader";
import { useModals } from "../../hooks/useModals";
import { useAuth } from "../../contexts/AuthContext";

const getValidationSchema = ({ shares, user }) =>
  Yup.object({
    email: Yup.string()
      .email()
      .test(
        "shared",
        "Project is already shared with this email",
        (value) =>
          !(shares || [])
            .map(({ email }) => email.toLowerCase().trim())
            .includes(value?.trim()?.toLowerCase())
      ),
    // .test(
    //   "shareWithYourself",
    //   "Can't share project with yourself",
    //   (value) => value?.trim()?.toLowerCase() !== user.email
    // ),
  });

const ShareModal = ({ onClose, onConfirm, project }) => {
  const {
    projectShares,
    fetchProjectShares,
    deleteProjectShare,
    isProjectSharesLoading,
  } = useAppContext();
  const { user } = useAuth();

  const validationSchema = useMemo(
    () => getValidationSchema({ shares: projectShares, user }),
    [projectShares, user]
  );

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      await onConfirm(values);
      onClose();
    },
  });

  const modals = useModals();
  useEffect(() => {
    fetchProjectShares(project.id);
  }, [project.id]);

  const handleDeleteProjectShare = useCallback(
    (email) => async () => {
      await modals.openConfirmation({
        onConfirm: async () => deleteProjectShare(project.id, { email }),
      });
    },
    [deleteProjectShare, modals, project.id]
  );

  return (
    <Modal onClose={onClose} onSubmit={formik.handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center">
            Share project
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Email"
            required
            placeholder="Email"
            {...getFieldProps(formik, {
              name: "email",
            })}
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                bold
                color="primary"
                type="submit"
                variant="contained"
                startIcon={<ScreenShareIcon />}
                endIcon={formik.isSubmitting && <CircularProgress size={25} />}
                disabled={formik.isSubmitting}
              >
                Share
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {!formik.isSubmitting && (
          <Grid item xs={12}>
            <Button bold onClick={onClose}>
              Cancel
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box sx={{ position: "relative" }}>
            {/* eslint-disable-next-line no-constant-condition */}
            {isProjectSharesLoading ? (
              <Loader inline />
            ) : (
              <List
                subheader={<ListSubheader>Shared with</ListSubheader>}
                sx={{ bgcolor: "background.paper" }}
              >
                {projectShares.length === 0 && (
                  <ListItem>
                    <ListItemText primary="Project is not shared with anyone yet" />
                  </ListItem>
                )}

                {projectShares.map(
                  ({ sharedAt, sharedBy, projectId, email }) => (
                    <ListItem
                      key={`${projectId}-${email}`}
                      secondaryAction={
                        (sharedBy.sub === user.sub ||
                          project.companyId === user.sub) && (
                          <Tooltip title="Remove share" arrow>
                            <IconButton
                              size="small"
                              edge="end"
                              onClick={handleDeleteProjectShare(email)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: "success.main" }}>
                          <AlternateEmailIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={email}
                        secondary={`${format(
                          new Date(sharedAt * 1000),
                          "dd.MM.yyyy HH:mm:ss"
                        )} by ${sharedBy.firstName} ${sharedBy.lastName} (${
                          sharedBy.email
                        })`}
                      />
                    </ListItem>
                  )
                )}
              </List>
            )}
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ShareModal;

import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useAnalytics } from "../analytics/AnalyticsContext";

const HelpDialog = ({
  open,
  onClose,
  dialogTitle,
  buttonLabel = "Close",
  children,
  ...props
}) => {
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onBackdropClick={onClose}
      {...props}
      sx={{
        "& .MuiDialog-paper": {
          padding: 0,
        },
      }}
    >
      {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
      {children}
      <DialogActions
        sx={{
          padding: 2,
        }}
      >
        <Button variant="contained" color="secondary" onClick={onClose}>
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const HelpButton = ({
  initialOpen = false,
  dialog = false,
  dialogTitle = null,
  title,
  buttonLabel = "Close",
  children = null,
  icon = null,
  dialogProps = {},
  ...props
}) => {
  const [dialogOpen, setDialogOpen] = useState(initialOpen);
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    setDialogOpen(initialOpen);
  }, [initialOpen]);

  useLayoutEffect(() => {
    if (dialogOpen) {
      trackEvent("Help Dialog Opened", {
        dialogTitle,
      });
    }
  }, [dialogOpen, trackEvent, dialogTitle]);

  if (dialog) {
    return (
      <>
        <IconButton size="small" onClick={() => setDialogOpen(true)}>
          {icon ? icon : <InfoOutlinedIcon fontSize="small" />}
        </IconButton>

        <HelpDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          dialogTitle={dialogTitle}
          buttonLabel={buttonLabel}
          {...dialogProps}
        >
          {children}
        </HelpDialog>
      </>
    );
  }

  return (
    <Tooltip
      title={title || children}
      arrow
      {...props}
      sx={{
        cursor: "pointer",
        ...props.sx,
      }}
      componentsProps={{
        ...props.componentsProps,
        tooltip: {
          ...props.componentsProps?.tooltip,
          sx: {
            ...props.componentsProps?.tooltip?.sx,
            padding: 3,
            opacity: 0.9,
          },
        },
      }}
    >
      <InfoOutlinedIcon fontSize="inherit" />
    </Tooltip>
  );
};

export default HelpButton;

import {
  Box,
  Grid,
  Select,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import { inputProps } from "../utils/getFieldProps";

const Input = ({
  label,
  required,
  InputProps,
  backgroundColor = "white",
  select,
  children,
  ...props
}) => (
  <Grid container rowSpacing={1} sx={props.sx}>
    {label && (
      <Grid item xs={12}>
        <Typography variant="body1">
          {label}
          {required && (
            <>
              {" "}
              <Box
                component="span"
                sx={{ color: ({ palette }) => palette.error.main }}
              >
                *
              </Box>
            </>
          )}
        </Typography>
      </Grid>
    )}
    <Grid item xs={12}>
      {select ? (
        <>
          <Select
            displayEmpty
            required={required}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
            margin="none"
            hiddenLabel
            FormHelperTextProps={{
              style: {
                marginLeft: 0,
              },
            }}
            InputProps={{
              ...InputProps,
              ...(!props.select && {
                inputProps: {
                  ...inputProps,
                  ...InputProps?.inputProps,
                  ...props.inputProps,
                },
              }),
            }}
            sx={{
              ...props?.sx,
            }}
            {...props}
          >
            {children}
          </Select>
          {props.helperText && (
            <FormHelperText error={props.error}>
              {props.helperText}
            </FormHelperText>
          )}
        </>
      ) : (
        <TextField
          required={required}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          fullWidth
          margin="none"
          hiddenLabel
          FormHelperTextProps={{
            style: {
              marginLeft: 0,
            },
          }}
          InputProps={{
            ...InputProps,
            ...(!props.select && {
              inputProps: {
                ...inputProps,
                ...InputProps?.inputProps,
                ...props.inputProps,
              },
            }),
            sx: {
              ...InputProps?.sx,
            },
          }}
          {...props}
        />
      )}
    </Grid>
  </Grid>
);

export default Input;

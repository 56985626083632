import React, { forwardRef, FunctionComponent } from "react";
import { Paper, MenuItem, MenuList } from "@mui/material";
import { createStyles, withStyles, WithStyles } from "@mui/styles";
import { lineHeight } from "@mui/system";

export type TAutocompleteItem = {
  keys: string[];
  value: any;
  content: string | JSX.Element;
};

interface TAutocompleteProps extends WithStyles<typeof styles> {
  items: TAutocompleteItem[];
  top: number;
  left: number;
  selectedIndex: number;
  onClick: (selectedIndex: number) => void;
}

const styles = () =>
  createStyles({
    container: {
      minWidth: "200px",
      position: "absolute",
      zIndex: 10,
    },
    item: {
      cursor: "pointer",
    },
  });

const Autocomplete: FunctionComponent<TAutocompleteProps> = (props, ref) => {
  if (!props.items.length) {
    return null;
  }

  const { classes } = props;
  return (
    <Paper
      className={classes.container}
      style={{
        top: props.top - lineHeight,
        left: props.left,
        transform: "translateY(-100%)",
      }}
    >
      <MenuList
        dense={true}
        sx={{ "&:focus-visible": { outline: "none" } }}
        ref={ref}
      >
        {props.items.map((item, index) => (
          <MenuItem
            value={index}
            key={index}
            className={classes.item}
            selected={index === props.selectedIndex}
            onClick={() => props.onClick(index)}
          >
            {item.content}
          </MenuItem>
        ))}
      </MenuList>
    </Paper>
  );
};

export default withStyles(styles, { withTheme: true })(
  forwardRef(Autocomplete)
);

import * as Yup from "yup";
import { useAppContext } from "../../contexts/AppContext";
import { useFormik } from "formik";
import React, { useCallback } from "react";
import { getFieldProps } from "../../utils/getFieldProps";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import Select from "./IntegrationsPage/Select";
import Stack from "@mui/material/Stack";
import { useIntegrations } from "../../contexts/IntegrationsContext";

const notionValidationSchema = Yup.object({
  parentId: Yup.string().required(),
  parentType: Yup.string().oneOf(["page", "database-item"]).required(),
  column: Yup.string().when("parentType", {
    is: (parentType) => parentType === "database-item",
    then: () => Yup.string().required(),
    otherwise: () => Yup.string().nullable(true),
  }),
});

const NotionIntegration = ({
  integration,
  isDisabled,
  onUpdateIntegration,
}) => {
  const { selectedCompanyId } = useAppContext();
  const { fetchNotionIntegrationPages } = useIntegrations();

  const formik = useFormik({
    initialValues: {
      parentId: integration.parentId,
      parentName: integration.parentName,
      parentType: integration.parentType || "database-item",
      column: integration.column,
    },
    enableReinitialize: true,
    validationSchema: notionValidationSchema,
    onSubmit: async (values) => onUpdateIntegration(values),
  });

  const handleParentChange = useCallback(
    ({ id, name }) => {
      formik.setFieldValue("parentId", id, true);
      formik.setFieldValue("parentName", name);
      formik.setFieldValue("column", undefined);
      formik.setFieldTouched("parentId", true);
      formik.setFieldTouched("column", true);
    },
    [formik]
  );

  const handleColumnChange = useCallback(
    ({ id }) => {
      formik.setFieldValue("column", id, true);
      formik.setFieldTouched("column", true);
    },
    [formik]
  );

  const handleParentTypeChange = useCallback(
    (e) => {
      formik.setFieldValue("parentType", e.target.value, true);
      formik.setFieldValue("parentId", undefined, true);
      formik.setFieldValue("parentName", undefined, true);
      formik.setFieldValue(
        "column",
        e.target.value === "page" ? null : undefined,
        true
      );
      formik.setFieldTouched("parentType", true);
      formik.setFieldTouched("parentName", true);
      formik.setFieldTouched("column", true);
    },
    [formik]
  );

  const parentFieldProps = getFieldProps(formik, {
    name: "parentId",
    errorWhenTouchedOrSubmitted: true,
  });

  const columnFieldProps = getFieldProps(formik, {
    name: "column",
    errorWhenTouchedOrSubmitted: true,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <RadioGroup
            value={formik.values.parentType}
            onChange={handleParentTypeChange}
          >
            <FormControlLabel
              value="page"
              control={<Radio size="small" />}
              label={
                <>
                  Create a page inside <b>{integration.parentName}</b> page
                </>
              }
            />
            <FormControlLabel
              value="database-item"
              control={<Radio size="small" />}
              label={
                <>
                  Create a database item inside <b>{integration.parentName}</b>{" "}
                  database
                </>
              }
            />
          </RadioGroup>
        </FormControl>
        <Typography variant="body1" component="p">
          in <b>{integration.workspaceName}</b> workspace every time a new
          report is received in Bugpilot
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Select
          margin="none"
          size="small"
          label={
            formik.values.parentType === "page" ? "Parent Page" : "Database"
          }
          fetch={() =>
            fetchNotionIntegrationPages(selectedCompanyId, {
              type: formik.values.parentType,
            })
          }
          getOptionLabel={(option) => option.name}
          required
          onChange={handleParentChange}
          error={parentFieldProps.error}
          helperText={parentFieldProps.helperText}
          value={
            formik.values.parentId
              ? {
                  name: formik.values.parentName,
                  id: formik.values.parentId,
                }
              : null
          }
        />
      </Grid>
      {formik.values.parentType === "database-item" && (
        <Grid item xs={12}>
          <Select
            disabled={!formik.values.parentId}
            margin="none"
            size="small"
            label="Database column for report title"
            fetch={() =>
              fetchNotionIntegrationPages(selectedCompanyId, {
                type: "database-item",
              }).then((pages) =>
                (
                  pages.find(({ id }) => formik.values.parentId === id)
                    ?.columns ?? []
                ).map((column) => ({ id: column, name: column }))
              )
            }
            getOptionLabel={(option) => option.name}
            required
            onChange={handleColumnChange}
            error={columnFieldProps.error}
            helperText={columnFieldProps.helperText}
            value={
              formik.values.column
                ? {
                    name: formik.values.column,
                    id: formik.values.column,
                  }
                : null
            }
          />
        </Grid>
      )}
      {formik.dirty && (
        <Grid item>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={formik.handleSubmit}
              endIcon={formik.isSubmitting && <CircularProgress size={25} />}
              disabled={isDisabled || formik.isSubmitting}
            >
              Save
            </Button>
            <Button
              bold
              color="primary"
              disabled={formik.isSubmitting}
              onClick={() => formik.resetForm()}
            >
              Cancel
            </Button>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default NotionIntegration;

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  TextField,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as Fathom from "fathom-client";

import { getFieldProps } from "../utils/getFieldProps";
import ErrorText from "./ErrorText";
import { useAppContext } from "../contexts/AppContext";
import { Box } from "@mui/system";
import { useAnalytics } from "../analytics/AnalyticsContext";
import { useAuth } from "../contexts/AuthContext";
import { ChevronRightOutlined as ChevronRightOutlinedIcon } from "@mui/icons-material";

const validationSchema = Yup.object({
  companyName: Yup.string().required("This field is required"),
  ownerRole: Yup.string().required("This field is required"),
  howDidYouHearAboutUs: Yup.string().required("This field is required"),
});

const roles = [
  {
    name: "Frontend Developer",
    value: "dev",
  },
  {
    name: "Backend Developer",
    value: "backend-dev",
  },
  {
    name: "Full-stack Developer",
    value: "fs-dev",
  },
  {
    name: "Product Manager",
    value: "pm",
  },
  {
    name: "Other",
    value: "other",
  },
];

const AccountSetupStep1 = ({ handleSave }) => {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const { user } = useAuth();
  const { currentWorkspace } = useAppContext();
  const { trackEvent } = useAnalytics();

  const formik = useFormik({
    initialValues: {
      companyName: "",
      ownerRole: "",
      howDidYouHearAboutUs: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    onSubmit: async ({ ownerRole, howDidYouHearAboutUs, companyName }) => {
      try {
        const workspaceId = currentWorkspace?.id;
        const workspaceName = currentWorkspace?.name || "My Workspace";
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        try {
          await handleSave({
            companyName: companyName || workspaceName || "My Workspace",
            ownerRole,
            howDidYouHearAboutUs,
            timeZone,
            termsOfService: true,
            termsOfServiceAcceptedAt: new Date().toISOString(),
          });
        } catch (e) {
          console.log("Cannot save onboarding info to workspace", e);
        }

        try {
          await fetch(
            "https://hook.eu1.make.com/4aln0266ax2q3b8hm5gjvy470rmiu238",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                WorkspaceId: workspaceId,
                WorkspaceName: companyName,
                UserSub: user.sub,
                UserEmail: user.email,
                TimeZone: timeZone,
                OwnerRole: ownerRole,
                HowDidYouHearAboutUs: howDidYouHearAboutUs,
                CompanyName: companyName,
              }),
            }
          );
        } catch (e) {
          console.log("Cannot save onboarding info to workspace", e);
        }

        trackEvent("Account Setup Complete", {
          ownerRole,
          howDidYouHearAboutUs,
        });

        setSubmitted(true);
      } catch (e) {
        setSubmitted(false);
        setError("Something went wrong. Please try again.");

        console.error("Error submitting privacy modal", e);
        throw e;
      }
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  useEffect(() => {
    Fathom?.trackGoal?.("RKRPZ7EH", 0);
  }, []);

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Typography variant="h5">Welcome to Bugpilot!</Typography>
        <Typography variant="body1">
          Our marketing team is forcing us to ask you these three questions.
          Thanks for helping us out &lt;3
        </Typography>
      </Stack>

      <Box pt={0.5} pb={0.5}>
        <FormControl fullWidth>
          <TextField
            mt={1}
            size="medium"
            label="Your Company"
            required
            fullWidth
            {...getFieldProps(formik, {
              name: "companyName",
            })}
          />
        </FormControl>
      </Box>

      <Box pt={0.5} pb={0.5}>
        <FormControl fullWidth>
          <InputLabel>Your Role</InputLabel>
          <Select
            fullWidth
            size="medium"
            label="Your Role"
            required
            {...getFieldProps(formik, {
              name: "ownerRole",
            })}
          >
            {roles.map(({ name, value }) => (
              <MenuItem value={value} key={value}>
                {name}
              </MenuItem>
            ))}
          </Select>

          {formik.errors.ownerRole && (
            <FormHelperText error>{formik.errors.ownerRole}</FormHelperText>
          )}
        </FormControl>
      </Box>

      <Box pt={0.5} pb={0.5}>
        <FormControl fullWidth>
          <TextField
            mt={1}
            size="medium"
            label="How did find us?"
            required
            fullWidth
            {...getFieldProps(formik, {
              name: "howDidYouHearAboutUs",
            })}
          />
        </FormControl>
      </Box>

      {error && (
        <Box>
          <ErrorText>{error}</ErrorText>
        </Box>
      )}

      <LoadingButton
        size="large"
        fullWidth
        variant="contained"
        color="primary"
        loading={submitted || formik.isSubmitting}
        onClick={handleSubmit}
        endIcon={<ChevronRightOutlinedIcon />}
      >
        Go to Bugpilot
      </LoadingButton>
    </Stack>
  );
};

export default AccountSetupStep1;

import { SvgIcon } from "@mui/material";

const MakeIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 512 512">
    <path
      fill="#724ebf"
      d="M229.5 1.5C143.1 11 67.1 63.2 28.1 140c-44.6 87.7-35.3 191.5 24.2 270.9 8.2 10.9 33.7 37.1 44.3 45.3 35.7 28.1 77.3 46 122.9 53 19.9 3 53.1 3 73 0 39.2-6 72.5-18.8 105.1-40.3 15.8-10.5 23.3-16.6 39-32.3 21-21 33.6-38.1 46.3-62.8 38.6-74.7 38.6-160.9 0-235.6-24.8-48.2-65-87.4-114.4-111.7-42.5-21-92.8-30-139-25zm49.4 154c15.1 3 28 6 28.8 6.7.8.7 1.9 2.2 2.3 3.4 1.3 3.2-36.1 191.7-38.5 194.4-1 1.1-3 2-4.4 2-4.7 0-53.6-10.1-55.8-11.6-1.3-.8-2.5-2.4-2.8-3.7-.3-1.2 8-45.1 18.3-97.6 17-85.7 19.1-95.6 21.2-97.3 1.2-1 2.5-1.8 2.9-1.8.4 0 13 2.5 28 5.5zm-72.7 8.6c22.4 11.2 26.8 14.1 26.8 17.8 0 2.4-87 175.5-89.2 177.3-1.1 1-3 1.8-4.1 1.8-2.3 0-49-23.2-51.9-25.8-1-.8-1.8-2.9-1.8-4.6 0-2.1 14.6-32.1 43.9-90.1 45.3-90 45.5-90.4 50.6-88.8 1.1.3 12.7 5.9 25.7 12.4zm175.9-9.2 2.4 1.9.3 98.5.2 98.6-2.4 2.8-2.4 2.8-26.4.3c-28.7.4-32.7-.2-34.6-4.3-.9-1.9-1.2-28-1.2-100.1v-97.5l2.5-2.4 2.4-2.5h28.4c26.5 0 28.6.1 30.8 1.9z"
    />
  </SvgIcon>
);

export default MakeIcon;

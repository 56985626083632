import { Box, Paper, Stack, Typography } from "@mui/material";
import React from "react";

export function UserDescriptionBox({ report }) {
  return (
    report.metadata?.userProvidedDescription && (
      <Box>
        <Stack spacing={1}>
          <Typography
            sx={{
              textTransform: "uppercase",
              fontWeight: 600,
              fontSize: 11,
              padding: 0,
              margin: 0,
            }}
            color="secondary.text"
          >
            User-reported Problem
          </Typography>

          <Box>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                lineHeight: "1.5em",
                maxHeight: 110,
                overflowY: "auto",

                "::-webkit-scrollbar": {
                  "-webkit-appearance": "none",
                  width: 3,
                },

                "::-webkit-scrollbar-thumb": {
                  borderRadius: 3,
                  backgroundColor: `rgba(0,0,0,.3)`,
                },
              }}
            >
              {report.metadata.userProvidedDescription}
            </Typography>
          </Box>

          {report.transcription && (
            <>
              <Typography variant="body2" color="text.tertiary">
                Audio transcription
                {report.transcriptionLanguage
                  ? ` ${report.transcriptionLanguage} `
                  : ""}
                👇
              </Typography>
              <Typography
                variant="body2"
                color="text.tertiary"
                sx={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                  lineHeight: "1.5em",
                  maxHeight: 100,
                  overflowY: "auto",
                }}
              >
                {report.transcription}
              </Typography>
            </>
          )}

          {report.transcriptionTranslation && (
            <>
              <Typography variant="body2" color="text.tertiary">
                Translation 👇
              </Typography>
              <Typography
                variant="body2"
                color="text.tertiary"
                sx={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                  lineHeight: "1.5em",
                  maxHeight: 100,
                  overflowY: "auto",
                }}
              >
                {report.transcriptionTranslation}
              </Typography>
            </>
          )}
        </Stack>
      </Box>
    )
  );
}

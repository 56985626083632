import { SvgIcon } from "@mui/material";

const EmailIcon = (props) => (
  <SvgIcon {...props} viewBox="-13.2 -16.504 114.4 99.024">
    <path fill="#4285f4" d="M6 66.016h14v-34l-20-15v43c0 3.315 2.685 6 6 6z" />
    <path fill="#34a853" d="M68 66.016h14c3.315 0 6-2.685 6-6v-43l-20 15z" />
    <path
      fill="#fbbc04"
      d="M68 6.016v26l20-15v-8c0-7.415-8.465-11.65-14.4-7.2z"
    />
    <path fill="#ea4335" d="M20 32.016v-26l24 18 24-18v26l-24 18z" />
    <path
      fill="#c5221f"
      d="M0 9.016v8l20 15v-26l-5.6-4.2C8.465-2.634 0 1.601 0 9.016z"
    />
  </SvgIcon>
);

export default EmailIcon;

import React, { useMemo, useState } from "react";
import {
  Stack,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Paper,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { replace } from "lodash";

import { Report } from "../../../pages/BugTrackerPage/types";
import { useAppContext } from "../../../contexts/AppContext.js";
import { useAuth } from "../../../contexts/AuthContext.js";
import { askAI } from "../../../api/index.js";
import { useSnackbar } from "notistack";
import {
  AutoAwesomeOutlined as AutoAwesomeIcon,
  EmailOutlined as EmailIcon,
} from "@mui/icons-material";

type NotifyUserModalProps = {
  report: Report;
  open: boolean;
  handleClose: () => void;
};

export const NotifyUserModal = ({
  report,
  open,
  handleClose,
}: NotifyUserModalProps) => {
  const { user: authUser } = useAuth();
  const { currentWorkspace } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const reportInfoForPrompt = useMemo(() => {
    // extract the report info we want to send to the GPT-4 prompt
    // to generate the message to send to the user to notify them
    // that their bug report is in progress or the bug is fixed

    const {
      bugStatus,
      timestamp,
      metadata,
      title,
      description,
      url,
      pageTitle,
      user: reportUser,
    } = report;
    const { userProvidedDescription } = metadata;

    const cleanString = (str: string) => {
      return replace(str, /[(\n|\r|"|<)]+/g, " ");
    };

    return `[Begin bug report info]
Application name: "${cleanString(currentWorkspace.name)}"
Reporter user name: "${cleanString(reportUser.firstName)}"
Bug report timestamp: "${cleanString(new Date(timestamp).toISOString())}"
Bug report status: "${cleanString(bugStatus)}"
Bug report title: "${cleanString(title)}"
Bug report user provided description: "${cleanString(userProvidedDescription)}"
Bug report internal description: "${cleanString(description)}"
Bug report initial page url: "${cleanString(url)}"
Bug report initial page title: "${cleanString(pageTitle)}"
Assignee name for signature: "${cleanString(authUser.given_name)} ${cleanString(
      authUser.family_name
    )}"
[End bug report info]`;
  }, [authUser, currentWorkspace.name, report]);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleGenerateMessage = async () => {
    setLoading(true);
    setMessage("");

    try {
      const result = await askAI(currentWorkspace.id, {
        op: "notifyUser",
        reportInfoText: reportInfoForPrompt,
      });

      if (result?.text) {
        setMessage(result.text);
      }
    } catch (e) {
      enqueueSnackbar("Error generating message", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
      <DialogContent>
        <Box p={2}>
          <Stack spacing={2} alignItems="flex-start">
            {/* <pre>{reportInfoForPrompt}</pre> */}

            <Typography variant="h2">
              Send a notification to the user
            </Typography>

            <Typography variant="body1">
              You can use this dialog to generate the text for an email to
              notify the user the bug is being fixed or that a fix has been
              released. You can customize the email subject and the email
              content using this form.
            </Typography>
            <Typography variant="body1" color="text.tertiary">
              Disclaimer: This functionality is in preview and can generate
              incorrect or misleading results. This functionality is powered by
              AI and we&apos;ll send some info to OpenAI.
            </Typography>

            <LoadingButton
              variant={message ? "outlined" : "contained"}
              color="primary"
              onClick={handleGenerateMessage}
              loading={loading}
              startIcon={<AutoAwesomeIcon />}
            >
              {message ? "Generate variation" : "Generate message"}
            </LoadingButton>

            {loading && (
              <Typography variant="caption" color="text.tertiary">
                (Please be patient, generating the message can take up to 30
                seconds.)
              </Typography>
            )}

            {message && (
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" color="text.secondary" gutterBottom>
                    {message.split("\n")[0]}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {message.split("\n").slice(1).join("\n")}
                  </Typography>
                </Box>
              </Paper>
            )}

            {message && (
              <Alert severity="info">
                The send button opens your email client so you can send an email
                to <strong>{report.user.email}</strong>. By default you will be
                BCCd. You can edit the email subject and body before sending.
              </Alert>
            )}
          </Stack>
        </Box>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Close
          </Button>

          <Button
            disabled={!message}
            variant="contained"
            color="primary"
            href={`mailto:${report.user.email}?bcc=${authUser.email}&subject=${
              message.split("\n")[0]
            }&body=${encodeURIComponent(
              message.split("\n").slice(1).join("\n")
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<EmailIcon />}
          >
            Compose email
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

import React, { useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { useAppContext } from "../../contexts/AppContext";
import { getFieldProps } from "../../utils/getFieldProps";
import Select from "./IntegrationsPage/Select";
import Stack from "@mui/material/Stack";
import { useIntegrations } from "../../contexts/IntegrationsContext";

const atlassianValidationSchema = Yup.object({
  projectId: Yup.string().required(),
  issueTypeId: Yup.string().required(),
});

const AtlassianIntegration = ({
  integration,
  isDisabled,
  onUpdateIntegration,
}) => {
  const { selectedCompanyId } = useAppContext();
  const {
    fetchAtlassianIntegrationProjects,
    fetchAtlassianIntegrationIssueTypes,
  } = useIntegrations();

  const formik = useFormik({
    initialValues: {
      projectId: integration.projectId,
      projectName: integration.projectName,
      issueTypeId: integration.issueTypeId,
      issueTypeName: integration.issueTypeName,
    },
    enableReinitialize: true,
    validationSchema: atlassianValidationSchema,
    onSubmit: async (values) => onUpdateIntegration(values),
  });

  const handleProjectChange = useCallback(
    ({ id, name }) => {
      formik.setFieldValue("projectId", id, true);
      formik.setFieldValue("projectName", name);
      formik.setFieldTouched("projectId", true);
    },
    [formik]
  );

  const handleIssueTypeChange = useCallback(
    ({ id, name }) => {
      formik.setFieldValue("issueTypeId", id, true);
      formik.setFieldValue("issueTypeName", name);
      formik.setFieldTouched("issueTypeId", true);
    },
    [formik]
  );

  const projectFieldProps = getFieldProps(formik, {
    name: "projectId",
    errorWhenTouchedOrSubmitted: true,
    disabled: isDisabled,
  });
  const issueTypeFieldProps = getFieldProps(formik, {
    name: "issueTypeId",
    errorWhenTouchedOrSubmitted: true,
    disabled: isDisabled,
  });

  return (
    <Grid
      item
      style={{
        flex: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="body1" component="p">
            Create an issue <b>{integration.issueTypeName}</b> in{" "}
            <b>{integration.projectName}</b> project every time a new report is
            received in Bugpilot
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Select
            margin="none"
            size="small"
            label="Project"
            fetch={() => fetchAtlassianIntegrationProjects(selectedCompanyId)}
            getOptionLabel={(option) =>
              option.description
                ? `${option.name} (${option.key})`
                : option.name
            }
            required
            onChange={handleProjectChange}
            error={projectFieldProps.error}
            helperText={projectFieldProps.helperText}
            value={
              formik.values.projectId
                ? {
                    name: formik.values.projectName,
                    id: formik.values.projectId,
                  }
                : null
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            margin="none"
            size="small"
            label="Issue type"
            fetch={async () => {
              const issueTypes = await fetchAtlassianIntegrationIssueTypes(
                selectedCompanyId
              );
              return issueTypes.filter(
                ({ scope }) =>
                  !scope || scope.project.id === formik.values.projectId
              );
            }}
            getOptionLabel={(option) =>
              option.description
                ? `${option.name} (${option.description})`
                : option.name
            }
            required
            onChange={handleIssueTypeChange}
            error={issueTypeFieldProps.error}
            helperText={issueTypeFieldProps.helperText}
            disabled={!formik.values.projectId}
            value={
              formik.values.issueTypeId
                ? {
                    name: formik.values.issueTypeName,
                    id: formik.values.issueTypeId,
                  }
                : null
            }
          />
        </Grid>
        {formik.dirty && (
          <Grid item>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={formik.handleSubmit}
                endIcon={formik.isSubmitting && <CircularProgress size={25} />}
                disabled={isDisabled || formik.isSubmitting}
              >
                Save
              </Button>
              <Button
                bold
                color="primary"
                disabled={formik.isSubmitting}
                onClick={() => formik.resetForm()}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AtlassianIntegration;

import React, { createContext, useCallback, useContext, useMemo } from "react";

import { useReportsListCache } from "./ReportsListCacheContext";
import { useNavigate } from "react-router-dom";
import { makeReportUrl } from "../components/report/makeReportUrl";
import { useSnackbar } from "notistack";
import useWebsockets from "../hooks/useWebsockets";
import { useAppContext } from "./AppContext";
import withOperation from "../hooks/withOperation";

type ReportContextProps = {
  // report: Report | null;
  // setReport: (report: Report | null) => void;
  closeReport: () => void;
  nextReport: () => void;
  prevReport: () => void;
  hasHistory: boolean;
};

const ReportContext = createContext<ReportContextProps>({
  // report: null,
  // setReport: () => {},
  closeReport: () => {},
  nextReport: () => {},
  prevReport: () => {},
  hasHistory: false,
});

const DEFAULT_BUG_VIEW = "/issues";

export const ReportProvider: React.FC = ({
  reportId,
  children,
}: {
  children: React.ReactElement;
  reportId: string;
}) => {
  // const [report, setReport] = useState<Report | null>(null);
  const navigate = useNavigate();
  const { setComments, setChangeLogs, selectedCompanyId } = useAppContext();
  const { cachedReportsList, previousReportsView } = useReportsListCache();
  const { enqueueSnackbar } = useSnackbar();

  const hasHistory = useMemo(() => {
    return cachedReportsList && cachedReportsList.length > 1;
  }, [cachedReportsList]);

  const closeReport = useCallback(() => {
    navigate(previousReportsView || DEFAULT_BUG_VIEW);
  }, [navigate, previousReportsView]);

  const nextReport = useCallback(() => {
    const currentIndex = cachedReportsList.findIndex(
      (report) => report.id === reportId
    );

    const nextIndex = currentIndex + 1;
    if (nextIndex < cachedReportsList.length) {
      navigate(makeReportUrl(cachedReportsList[nextIndex]));
    } else {
      enqueueSnackbar("No more reports", {
        variant: "info",
        autoHideDuration: 2 * 1000,
      });
    }
  }, [cachedReportsList, enqueueSnackbar, navigate, reportId]);

  const prevReport = useCallback(() => {
    const currentIndex = cachedReportsList.findIndex(
      (report) => report.id === reportId
    );

    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      navigate(makeReportUrl(cachedReportsList[prevIndex]));
    } else {
      enqueueSnackbar("No more reports", {
        variant: "info",
        autoHideDuration: 2 * 1000,
      });
    }
  }, [cachedReportsList, enqueueSnackbar, navigate, reportId]);

  useWebsockets({
    isEnabled: Boolean(reportId) && Boolean(selectedCompanyId),
    sessionId: btoa(
      JSON.stringify({
        ...(selectedCompanyId && { companyId: selectedCompanyId }),
        ...(reportId && { reportId: reportId }),
      })
    ),
    onMessage: ({ operation, data, type }) => {
      if (!["comment", "change-log"].includes(type)) {
        return;
      }
      withOperation({
        operation,
        data,
        setState: type === "comment" ? setComments : setChangeLogs,
      });
    },
  });

  return (
    <ReportContext.Provider
      value={{
        // report,
        // setReport,
        closeReport,
        nextReport,
        prevReport,
        hasHistory,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export const useReport = () => useContext(ReportContext);

import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import * as Yup from "yup";
import YupPassword from "yup-password"; // extend yup
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Fathom from "fathom-client";

import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  Tooltip,
} from "@mui/material";

import { getPasswordValidationRule } from "../components/PasswordField";
import { useLocation, useNavigate } from "react-router-dom";

import Link from "../components/Link";
import { InviteSignInAddon } from "./InviteSignInAddon";
import { useAnalytics } from "../analytics/AnalyticsContext";
import options from "../authOptions";
import { uniqueId } from "lodash";

YupPassword(Yup);
const validationSchema = Yup.object({
  email: Yup.string()
    .required("Please enter your work email address")
    .email("Please enter a valid email address")
    .nullable(),
  password: getPasswordValidationRule(),
  termsOfService: Yup.boolean().oneOf(
    [true],
    "Please accept the T&C and Privacy Policy"
  ),
});

const SignUp = ({ onSignInClick, onSignUp: signupAPI, federatedSignIn }) => {
  const [serverError, setServerError] = useState("");
  const [googlePrivacyError, setGooglePrivacyError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const tosRef = useRef(null);
  const navigate = useNavigate();

  const location = useLocation();
  const { trackEvent } = useAnalytics();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onEmailAuthClick = useCallback(
    (e) => {
      setSubmitting(true);

      const submit = async ({ email, password }) =>
        await signupAPI({
          username: email,
          password,
          attributes: {
            given_name: "-",
            family_name: "-",
          },
        });

      handleSubmit(submit)(e)
        .then(() => {
          trackEvent("Signup click");
          trackEvent("Signup with email");
        })
        .catch((e) => {
          console.error("Signup error", e);

          if (e.message.includes("Incorrect username or password")) {
            setServerError(
              "You already have an account with this email address, but you used the wrong password."
            );
            return;
          }

          setServerError(e.message.replace("PreSignUp failed with error ", ""));
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [handleSubmit, signupAPI, trackEvent]
  );

  const onAuthClick = useCallback(
    (providerName) => () => {
      if (!tosRef?.current?.checked) {
        setGooglePrivacyError("Please accept the T&C and Privacy Policy");
        return;
      }

      setSubmitting(true);
      federatedSignIn(providerName);

      trackEvent("Signup click");
      trackEvent("Signup with Google");

      setTimeout(() => {
        setSubmitting(false);
      }, 5 * 1000);
    },
    [federatedSignIn, trackEvent]
  );

  const provider = useMemo(() => {
    const providerQs = new URLSearchParams(location.search).get("provider");
    return providerQs;
  }, [location]);

  useLayoutEffect(() => {
    if (provider) {
      trackEvent("Signup start from " + provider);
    }
  }, [provider, trackEvent]);

  useEffect(() => {
    window.Bugpilot?.saveReport?.(
      {
        triggerType: "sdk",
        triggerSource: "signup-flow",
      },
      {
        title: "Signup flow started",
      }
    );

    Fathom?.trackGoal("RKRPZ7EH", 0);

    // Twitter Pixel
    //

    const conversionId =
      window.localStorage.getItem("bugpilot.signup.conversionid") ||
      uniqueId("signup-") + Date.now();

    window.localStorage.setItem("bugpilot.signup.conversionid", conversionId);

    window?.twq?.("event", "tw-ocfj9-ogu08", {
      // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
      conversion_id: conversionId,
    });
  }, []);

  const handleTabChange = (e, newValue) => {
    if (newValue === "signin") {
      navigate(`/signin${location.search}`);
      return;
    }

    navigate(`/signup${location.search}`);
  };

  return (
    <Stack
      spacing={6}
      sx={{
        width: "100dvw",
        height: "100dvh",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "none",
        overflow: "scroll",
      }}
    >
      <AppBar
        position="absolute"
        sx={{
          top: 0,
          left: 0,
          right: 0,
          height: 62,
          padding: "0 1rem",
          borderRight: "none",
          borderLeft: "none",
          boxShadow: "none",
        }}
        variant="outlined"
        color="transparent"
        elevation={0}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Box>
            <img src="/logo.svg" alt="Bugpilot" width={120} />
          </Box>
          <Box>
            <Button
              size="small"
              variant="outlined"
              color="tertiary"
              onClick={() => navigate("/signin")}
            >
              Log in
            </Button>
          </Box>
        </Stack>
      </AppBar>

      <InviteSignInAddon />

      <Box sx={{ width: "100%", paddingX: 4 }} maxWidth={400}>
        <Typography
          variant="h2"
          sx={{
            fontSize: "3em",
            textAlign: "center",
            fontWeight: "medium",
            display: {
              xs: "none",
              sm: "block",
            },
            userSelect: "none",
          }}
        >
          Create your <br />
          free account
        </Typography>

        {/* <Tabs
          value={"signup"}
          aria-label="signup and login tabs"
          onChange={handleTabChange}
        >
          <Tab value={"signup"} label="Create account" sx={{ flex: 1 }} />

          <Tab
            value={"signin"}
            label="Log in"
            sx={{
              flex: 1,
            }}
          />
        </Tabs> */}
      </Box>

      <Stack
        spacing={6}
        sx={{
          width: "100%",
          paddingX: 4,
          paddingTop: {
            xs: 8,
            md: 0,
          },
        }}
        maxWidth={400}
      >
        <Tooltip
          open={googlePrivacyError}
          title={googlePrivacyError}
          placement="bottom"
          arrow
          style={{
            "& .MuiTooltip-arrow": {
              backgroundColor: "red",
            },

            "& .MuiTooltip-tooltip": {
              backgroundColor: "red",
            },
          }}
        >
          {/* {googlePrivacyError && (
          <Typography
            variant="body1"
            sx={{
              color: (theme) => theme.palette.error.main,
              display: "flex",
              alignItems: "center",
            }}
          >
            <ErrorIcon sx={{ mr: 1 }} fontSize="small" />
            {googlePrivacyError}
          </Typography>
        )} */}

          <FormControlLabel
            control={
              <Checkbox
                inputRef={(el) => {
                  tosRef.current = el;
                }}
                sx={{
                  color: (theme) =>
                    googlePrivacyError
                      ? theme.palette.error.main
                      : theme.palette.secondary.dark,
                }}
                onClick={() => setGooglePrivacyError(false)}
                {...register("termsOfService", {
                  required: true,
                })}
              />
            }
            label={
              <Typography
                variant="inherit"
                sx={{
                  color: (theme) =>
                    googlePrivacyError
                      ? theme.palette.error.main
                      : theme.palette.secondary.dark,
                }}
              >
                I have read and agree to the{" "}
                <Link
                  href="https://www.bugpilot.io/terms"
                  target="_blank"
                  sx={{
                    color: "inherit",
                    textDecoration: "underline",
                    textUnderlineOffset: "2px",
                  }}
                >
                  Terms and Conditions
                </Link>{" "}
                and{" "}
                <Link
                  href="https://www.bugpilot.io/privacy"
                  target="_blank"
                  sx={{
                    color: "inherit",
                    textDecoration: "underline",
                    textUnderlineOffset: "2px",
                  }}
                >
                  Privacy Policy
                </Link>
                <Typography component="span" variant="body1" color="error">
                  *
                </Typography>
              </Typography>
            }
          />
        </Tooltip>

        <Stack spacing={2} sx={{ position: "relative", overflow: "visible" }}>
          {options.map(({ name, providerName, icon: Icon }) => (
            <Button
              key={name}
              size="large"
              fullWidth
              variant="outlined"
              color="primary"
              onClick={onAuthClick(providerName ?? name)}
              startIcon={<Icon height={25} width={25} />}
              disabled={submitting}
            >
              Sign up with {name}
            </Button>
          ))}

          {submitting ? (
            <Box
              sx={{
                position: "absolute",
                top: -20,
                left: -20,
                right: -20,
                bottom: -20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255,255,255,0.5)",
                backdropFilter: "blur(3px)",
                userSelect: "none",
              }}
            >
              <CircularProgress />
              <Typography variant="body1" sx={{ ml: 2 }}>
                Redirecting to provider&hellip;
              </Typography>
            </Box>
          ) : null}
        </Stack>
      </Stack>

      {/*
        <Divider sx={{ my: 2 }}>or</Divider>

        <Stack spacing={2}>
          <TextField
            name="email"
            type="email"
            label="Email"
            placeholder="susanne@acmecorp.com"
            required
            fullWidth
            {...register("email", {
              required: true,
            })}
          />

          <PasswordField
            {...register("password", {
              required: true,
            })}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onEmailAuthClick(e);
              }
            }}
          />

          {errors.email && (
            <FormHelperText error>
              <Typography variant="body1">{errors.email.message}</Typography>
            </FormHelperText>
          )}

          {errors.password && (
            <FormHelperText error>
              <Typography variant="body1">{errors.password.message}</Typography>
            </FormHelperText>
          )}

          {errors.termsOfService && (
            <FormHelperText error>
              <Typography variant="body1">
                {errors.termsOfService.message}
              </Typography>
            </FormHelperText>
          )}
          {serverError && (
            <FormHelperText error>
              <Typography variant="body1">{serverError}</Typography>
            </FormHelperText>
          )}

          <LoadingButton
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            id="signup-submit"
            onClick={onEmailAuthClick}
            loading={submitting}
          >
            Continue with email
          </LoadingButton>
        </Stack> */}
    </Stack>
  );
};

export default SignUp;

import { useNavigate } from "react-router-dom";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Stack,
} from "@mui/material";
import { ChevronRightOutlined as ChevronRightIcon } from "@mui/icons-material";

export const MenuButtonItem = ({
  text = "Menu item text",
  icon = null,
  to = "#invalid_url",
  selected = false,
  sx = {},
  component = null,
  badge = null,
  ...props
}) => {
  const navigate = useNavigate();
  const child = (
    <ListItemButton
      // selected={selected}
      sx={{
        // backgroundColor: selected ? "#00000040 !important" : "transparent",
        p: 0,
        pr: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: 3,
          justifyContent: "center",
          color: "sidebar.item",
          width: 18,
          height: 18,
          margin: "auto",
          display: "flex",
          flex: "0 0 18px",
        }}
      >
        {icon}
      </ListItemIcon>

      {component ? (
        component()
      ) : (
        <ListItemText
          primary={
            <Stack spacing={1} alignItems="center" direction="row">
              <span>{text}</span>
              {badge ? (
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  sx={{
                    height: 18,
                    ml: 3,
                    color: (theme) => theme.palette.sidebar.avatarBg,
                  }}
                >
                  {badge}
                </Typography>
              ) : null}
            </Stack>
          }
          sx={{
            color: "sidebar.item",
          }}
        />
      )}

      <Box
        sx={{
          visibility: selected ? "visible" : "hidden",
          width: 18,
          height: 18,
          color: "sidebar.item",
        }}
      >
        <ChevronRightIcon fontSize="small" />
      </Box>
    </ListItemButton>
  );

  return (
    <ListItem
      disablePadding
      sx={{ paddingTop: 1, display: "block", ...sx }}
      {...props}
      onClick={(e) => {
        if (to.startsWith("http")) {
          window.open(to, "_blank");
          return;
        }

        navigate(to);
      }}
    >
      {child}
    </ListItem>
  );
};

export const MenuSectionTitle = ({ text, sx = {} }) => {
  return (
    <ListItem disablePadding>
      <ListItemText
        primary={text}
        sx={{
          // span child
          "& > span": {
            color: "sidebar.title",
            fontWeight: "bold",
            textTransform: "uppercase",
            fontSize: "0.75rem",
            letterSpacing: "80%",
          },
          mt: 3,
          mb: 0,
          ml: 0,
          ...sx,
        }}
      />
    </ListItem>
  );
};

import JSONView from "react-json-view";

import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";

const numberStyles = {
  color: "rgb(38, 139, 210)",
  display: "inline-block",
  padding: "0 5px",
};

const isJSONObjectString = (str) => {
  if (typeof str !== "string") {
    return false;
  }

  try {
    const parsed = JSON.parse(str);

    // "false", "true", "1" will be parsed without error, so we need to check
    return typeof parsed === "object" && parsed !== null;
  } catch (e) {
    return false;
  }
};

const enableClipboard = (copy) => {
  return navigator.clipboard.writeText(JSON.stringify(copy.src, null, 2));
};

const Value = ({ value, rootName, collapsed, contentTypeHint, wrapLines }) => {
  if (typeof value === "number") {
    return (
      <Typography
        variant="code"
        component="span"
        sx={{
          display: "inline-block",
          padding: "0 5px",
        }}
      >
        {JSON.stringify(value)}
      </Typography>
    );
  }

  if (
    typeof value === "boolean" ||
    typeof value === "undefined" ||
    value === null
  ) {
    return (
      <Typography variant="code" sx={numberStyles}>
        {JSON.stringify(value)}
      </Typography>
    );
  }

  if (typeof value !== "object" && !isJSONObjectString(value)) {
    let stringText = typeof value === "string" ? value : JSON.stringify(value);

    if (contentTypeHint === "application/x-www-form-urlencoded") {
      stringText = decodeURIComponent(stringText);

      try {
        const params = new URLSearchParams(stringText);
        const paramsObject = {};

        for (const [key, value] of params) {
          paramsObject[key] = value;
        }

        return (
          <JSONView
            src={paramsObject}
            name={rootName}
            style={{
              fontFamily: "monospace",
              fontSize: 11,
              wordBreak: "break-all",
              paddingTop: 4,
              paddingBottom: 4,
            }}
            collapsed={collapsed}
            enableClipboard={enableClipboard}
          />
        );
      } catch (e) {
        // ignore
      }
    }

    return (
      <Stack>
        {rootName && <Typography variant="caption">{rootName}</Typography>}
        <Typography
          variant="code"
          sx={{
            ...(wrapLines
              ? {
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-all",
                  overflowWrap: "break-word",
                }
              : {
                  whiteSpace: "pre",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }),
          }}
          component="code"
        >
          {stringText}
        </Typography>
      </Stack>
    );
  }

  let objectValue = value;

  if (isJSONObjectString(value)) {
    objectValue = JSON.parse(value);
  }

  if (objectValue.__nativeClass === "Error") {
    return (
      <>
        <Typography variant="code">
          <br />
          <strong>{objectValue.message}</strong>
          <br />
        </Typography>

        <Typography variant="code">{objectValue.stack}</Typography>
      </>
    );
  }

  if (typeof objectValue.isErrorEvent === "boolean") {
    return (
      <Typography variant="code" sx={{ color: "error.main", fontWeight: 600 }}>
        &#123; {objectValue.value} &#125;
      </Typography>
    );
  }

  const forceCollapsed = JSON.stringify(value).length > 1000 ? true : false;

  return (
    <JSONView
      src={objectValue}
      name={rootName || false}
      displayDataTypes={false}
      style={{
        fontFamily: "monospace",
        fontSize: 11,
        wordBreak: "break-all",
        paddingTop: 4,
        paddingBottom: 4,
      }}
      collapsed={forceCollapsed || collapsed}
      enableClipboard={enableClipboard}
    />
  );
};

export default Value;

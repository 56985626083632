import { useCallback, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Box, Dialog } from "@mui/material";

const mentionRegExp = /<([a-zA-Z0-9\s@_.+-]+)\|([a-z0-9-]+)>/gi;

const Image = ({ node, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleImageClick = useCallback(
    () => setIsOpen((isOpen) => !isOpen),
    []
  );

  return isOpen ? (
    <Dialog open fullWidth maxWidth={false} onClose={handleImageClick}>
      <Box
        width="100%"
        height="100%"
        alignItems="center"
        display="flex"
        justifyContent="center"
      >
        <img alt="" {...props} onClick={handleImageClick} />
      </Box>
    </Dialog>
  ) : (
    <img
      alt=""
      {...props}
      style={{
        maxWidth: "100%",
        maxHeight: "200px",
        cursor: "pointer",
        ...props.style,
      }}
      onClick={handleImageClick}
    />
  );
};

const CommentText = ({ children }) => {
  return (
    <Box>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        linkTarget="_blank"
        components={{
          p: ({ node, ...props }) => (
            <p
              {...props}
              style={{
                ...props.style,
                margin: 0,
                marginBottom: "1em",
              }}
            />
          ),
          img: Image,
        }}
      >
        {children.replaceAll(mentionRegExp, "**@$1**")}
      </ReactMarkdown>
    </Box>
  );
};

export default CommentText;

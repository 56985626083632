import { EditorState, Modifier, SelectionState } from "draft-js";
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  forwardRef,
} from "react";
import { useSnackbar } from "notistack";

import {
  ClickAwayListener,
  IconButton,
  Box,
  useTheme,
  Popper,
  Typography,
  Chip,
  Stack,
  Paper,
} from "@mui/material";

import AttachFileIcon from "@mui/icons-material/AttachFileOutlined";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import FormatColorTextIcon from "@mui/icons-material/FormatColorTextOutlined";
import GoogleIcon from "@mui/icons-material/Google";

import { getCommentAttachmentUploadUrl } from "../../../api";
import EmojiSelect from "./EmojiSelect";
import MUIRichTextEditor from "../../../lib/MUIRichTextEditor/index.tsx";
import { useAppContext } from "../../../contexts/AppContext";
import { useAuth } from "../../../contexts/AuthContext";
import MemberAvatar from "../../MemberAvatar.tsx";

const mentionRegex = /<(.+)\|(.+)>/g;
const Mention = (props) => {
  const theme = useTheme();
  const { user } = useAuth();
  const { companyMembers } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback((e) => {
    console.log("Enter");
    setIsOpen(true);
  }, []);

  const member = useMemo(() => {
    const id = props.decoratedText.replace(mentionRegex, "$2");
    return companyMembers.find((member) => member.id === id);
  }, [props.decoratedText, companyMembers]);

  return (
    <>
      <span
        ref={ref}
        style={{
          color: theme.palette.primary.accent,
          cursor: "pointer",
        }}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        {props.decoratedText.replace(mentionRegex, "@$1")}
      </span>

      {member && (
        <Popper
          open={isOpen}
          anchorEl={ref.current}
          onClose={handleClose}
          placement="top"
        >
          <Paper>
            <Box p={2}>
              <Stack spacing={1}>
                <Stack spacing={1} direction="row">
                  <MemberAvatar member={member} />
                  <Typography variant="body1">
                    {member.name} {member.email === user?.email && <> (you)</>}
                  </Typography>
                </Stack>
                <Typography variant="body2" sx={{ color: "text.tertiary" }}>
                  {member.email}
                  {member.provider !== "cognito" && (
                    <Chip
                      size="small"
                      label="Google"
                      color="secondary"
                      variant="outlined"
                      icon={<GoogleIcon fontSize="small" />}
                      sx={{ ml: 1 }}
                    />
                  )}
                </Typography>
              </Stack>
            </Box>
          </Paper>
        </Popper>
      )}
    </>
  );
};

const Editor = (
  {
    rawContentState,
    companyMembers = [],
    reportId,
    onChange,
    onSave,
    onFormattingClick,
    placeholder = "Write something...",
    onBlur,
    enableFileUpload = true,
    classes = {},
  },
  ref
) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const inputRef = useRef();
  const [isToolbarShown, setIsToolbarShown] = useState(false);

  const handleChange = useCallback(
    (editorState) => {
      onChange?.(editorState);
    },
    [onChange]
  );

  const handleFocus = useCallback(() => {
    setIsToolbarShown(true);
  }, []);

  const companyMembersAutocompleteItems = useMemo(
    () =>
      companyMembers
        .map((member) => ({
          keys: [
            member.firstName,
            member.lastName,
            member.name,
            member.email,
          ].filter((item) => item && item !== "-"),
          value: `<${member.name || member.email}|${member.id}>`,
          content: `${member.name || member.email}`,
          data: { id: member.id },
        }))
        .filter(({ keys }) => keys.length > 0),
    [companyMembers]
  );

  const handleFileUpload = useCallback(
    (files) => {
      if (files.some(({ size }) => size > 10 * 1000 * 1000)) {
        enqueueSnackbar("Each file size should be less than 10MB", {
          variant: "error",
        });
        return;
      }

      const uploadFile = async (file) => {
        const { snackbarId } = enqueueSnackbar(
          `Uploading ${file.name || "file"}...`,
          {
            variant: "info",
            persist: true,
            hideIconVariant: true,
          }
        );

        const { putUrl, getUrl } = await getCommentAttachmentUploadUrl(
          reportId,
          {
            contentType: file.type,
          }
        );

        try {
          await fetch(putUrl, {
            method: "PUT",
            body: file,
            headers: {
              "Content-Type": file.type,
            },
          });
        } catch (e) {
          enqueueSnackbar("Failed to upload file", {
            variant: "error",
          });
        } finally {
          closeSnackbar(snackbarId);
        }

        return { getUrl };
      };

      [...files].forEach(async (file) => {
        const blockType = file.type.startsWith("image/") ? "image" : "link";

        if (blockType === "link") {
          const editorState = (ref || inputRef).current.editorState;

          const currentSelection = editorState.getSelection();
          const contentState = editorState.getCurrentContent();

          const text = file.name || "Attachment";

          const textWithInsert = Modifier.insertText(
            contentState,
            currentSelection,
            text
          );

          (ref || inputRef).current.editorState = EditorState.push(
            editorState,
            textWithInsert,
            "insert-characters"
          );

          const { getUrl } = await uploadFile(file);

          const insertedTextSelection = SelectionState.createEmpty(
            currentSelection.getAnchorKey()
          )
            .set("anchorOffset", currentSelection.getAnchorOffset())
            .set(
              "focusOffset",
              currentSelection.getAnchorOffset() + text.length
            );

          const linkEntity = contentState.createEntity("LINK", "IMMUTABLE", {
            url: getUrl,
            href: getUrl,
            title: file.name,
            target: "_blank",
          });
          const linkEntityKey = linkEntity.getLastCreatedEntityKey();

          const newContentState = Modifier.applyEntity(
            textWithInsert,
            insertedTextSelection,
            linkEntityKey
          );
          (ref || inputRef).current.editorState = EditorState.push(
            editorState,
            newContentState,
            "apply-entity"
          );

          return;
        }

        ref.current?.insertAtomicBlockAsync?.(
          blockType.toUpperCase(),
          // eslint-disable-next-line no-async-promise-executor
          new Promise(async (resolve, reject) => {
            try {
              const { getUrl } = await uploadFile(file);
              resolve({
                data: {
                  ...(blockType === "image"
                    ? {
                        url: getUrl,
                        src: getUrl,
                        alt: file.name,
                        width: "200px",
                        alignment: "left",
                      }
                    : {
                        href: getUrl,
                        name: file.name,
                      }),
                },
              });
            } catch (e) {
              reject(e);
            }
          }),
          " "
        );
      });
    },
    [enqueueSnackbar, ref, reportId, closeSnackbar]
  );

  const handleFileChange = useCallback(
    (e) => {
      const files = [...e.target.files];

      handleFileUpload(files);
      inputRef.current.value = "";
    },
    [handleFileUpload, inputRef]
  );

  const [isTextFormattingShown, setIsTextFormattingShown] = useState(false);

  const handleClickAway = useCallback(() => {
    setIsToolbarShown(false);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <MUIRichTextEditor
          keyCommands={[
            {
              key: 13,
              name: "send",
              callback: (editorState) => {
                if (!editorState.getCurrentContent().hasText()) {
                  return editorState;
                }

                onSave(editorState);
                return EditorState.createEmpty(editorState.getDecorator());
              },
            },
          ]}
          toolbarButtonSize="small"
          decorators={[
            {
              component: Mention,
              regex: /<([a-zA-Z0-9\s@_.+-]+)\|([a-z0-9-]+)>/gi,
            },
          ]}
          formattingControls={
            isTextFormattingShown && isToolbarShown
              ? [
                  "bold",
                  "italic",
                  "underline",
                  "strikethrough",
                  "numberList",
                  "bulletList",
                  "quote",
                  "code",
                  "clear",
                ]
              : []
          }
          customControls={[
            {
              name: "emoji",
              type: "callback",
              component: ({ onMouseDown, id, disabled }) => {
                return (
                  <EmojiSelect
                    id={id}
                    disabled={disabled}
                    size="small"
                    onChange={({ native: emoji }, e) => {
                      document.getElementById(id).value = emoji;
                      onMouseDown(e);
                    }}
                  />
                );
              },
              onClick: (editorState, name, element) => {
                const selection = editorState.getSelection();
                const newContentState = Modifier.insertText(
                  editorState.getCurrentContent(),
                  selection,
                  element.value
                );

                return EditorState.push(
                  editorState,
                  newContentState,
                  "insert-characters"
                );
              },
            },
            ...(enableFileUpload
              ? [
                  {
                    name: "files",
                    type: "callback",
                    icon: <AttachFileIcon fontSize="small" />,
                    onClick: (editorState, name, element) => {
                      inputRef.current.setAttribute("accept", "*");
                      inputRef.current.click();
                    },
                  },
                  {
                    name: "images",
                    type: "callback",
                    icon: <AddPhotoAlternateIcon fontSize="small" />,
                    onClick: (editorState, name, element) => {
                      inputRef.current.setAttribute("accept", "image/*");
                      inputRef.current.click();
                    },
                  },
                ]
              : []),
            {
              name: "format",
              type: "callback",
              component: ({ onMouseDown, id, disabled }) => (
                <IconButton
                  size="small"
                  onClick={onMouseDown}
                  disabled={disabled}
                >
                  <FormatColorTextIcon
                    fontSize="small"
                    color={isTextFormattingShown ? "primary" : undefined}
                  />
                </IconButton>
              ),
              onClick() {
                onFormattingClick?.();
                setIsTextFormattingShown((prev) => !prev);
              },
            },
          ]}
          autocomplete={{
            suggestLimit: Number.MAX_SAFE_INTEGER,
            strategies: companyMembers?.length
              ? [
                  {
                    items: companyMembersAutocompleteItems,
                    triggerChar: "@",
                  },
                ]
              : [],
          }}
          classes={{
            container: "initial",
            editorContainer: "initial",
            toolbar: isToolbarShown ? "initial" : "hidden",
            ...classes,
          }}
          ref={ref}
          controls={
            isToolbarShown ? ["format", "emoji", "files", "images"] : []
          }
          defaultValue={rawContentState}
          onChange={handleChange}
          label={placeholder}
          onFocus={handleFocus}
          onBlur={onBlur}
          draftEditorProps={
            enableFileUpload
              ? {
                  handleDroppedFiles: (selectionState, files) => {
                    if (!files.length) {
                      return "not-handled";
                    }

                    handleFileUpload(files);
                    return "handled";
                  },
                  handlePastedFiles: (files) => {
                    handleFileUpload(files);
                    return "handled";
                  },
                }
              : {}
          }
        />
        <input
          multiple
          type="file"
          accept="*"
          ref={inputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </Box>
    </ClickAwayListener>
  );
};

const EditorWithRef = forwardRef(Editor);

export default EditorWithRef;

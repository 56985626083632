import React from "react";

import { Button, Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ShowSupportInfoButton } from "../pages/ShowSupportInfoButton";
import { Box } from "@mui/system";

export const AccountPageFooter = () => {
  const navigate = useNavigate();

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1}>
        <Button
          size="small"
          color="secondary"
          variant="text"
          onClick={() => window.open("https://www.bugpilot.io/privacy")}
        >
          Privacy Policy
        </Button>

        <Button
          size="small"
          color="secondary"
          variant="text"
          onClick={() => window.open("https://www.bugpilot.io/terms")}
        >
          Terms and Conditions
        </Button>

        <Button
          size="small"
          color="secondary"
          variant="text"
          onClick={() => {
            navigate("/onboarding");
          }}
        >
          Retake onboarding
        </Button>

        <Button
          size="small"
          color="secondary"
          variant="text"
          onClick={() => {
            navigate(window.user.name);
          }}
        >
          Generate Error
        </Button>

        <ShowSupportInfoButton
          buttonProps={{
            size: "small",
            color: "secondary",
            variant: "text",
          }}
        />
      </Stack>

      <Box pl={0.5}>
        <Typography variant="caption" color="text.secondary">
          Application Version:{" "}
          {process.env.REACT_APP_COMMIT_SHA
            ? process.env.REACT_APP_COMMIT_SHA.substring(0, 8)
            : "0.0.0"}
        </Typography>
      </Box>
    </Stack>
  );
};

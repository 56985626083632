import React, { useEffect } from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  IconButton,
  AppBar,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Badge,
  Link as MuiLink,
  Tooltip,
} from "@mui/material";
import {
  PersonOutlined as PersonIcon,
  LogoutOutlined as LogoutIcon,
  NotificationsOutlined as NotificationsIcon,
  NotificationsActiveOutlined as NotificationsActiveIcon,
  SupportAgentOutlined as SupportAgentIcon,
} from "@mui/icons-material";
import { useAuth } from "../../contexts/AuthContext.js";
import { DRAWER_WIDTH } from "./MenuSidebar.js";
import { NotificationsDrawer } from "./NotificationsDrawer.tsx";
import { useAppContext } from "../../contexts/AppContext.js";
import { Badger } from "./Badger.tsx";
import { SearchBox } from "../../components/SearchBox/SearchBox.tsx";

export const TOP_APPBAR_HEIGHT = 60;

export const TopAppBar = () => {
  const { signOut, user } = useAuth();
  const {
    notifications,
    companyMembers,
    isCompanyMembersLoading,
    isNotificationsLoading,
    markNotificationAsRead,
  } = useAppContext();
  const navigate = useNavigate();

  const profileMenuRef = useRef(null);
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const notificationsMenuRef = useRef(null);
  const [showNotificationsMenu, setShowNotificationsMenu] = useState(false);
  const handleNotificationsMenuToggle = useCallback(() => {
    setShowNotificationsMenu((showNotificationsMenu) => !showNotificationsMenu);
  }, []);

  const unreadNotifications = useMemo(
    () => notifications.filter(({ readAt }) => readAt === null),
    [notifications]
  );

  useEffect(() => {
    if (isNotificationsLoading) {
      return;
    }

    const interval = setInterval(() => {
      if (!unreadNotifications.length) {
        document.title = document.title.replace(/^\(\d+\)\s/g, "");
        return;
      }

      const match = document.title.match(/^\(\d+\)/g);
      document.title = match
        ? document.title.replace(match[0], `(${unreadNotifications.length})`)
        : `(${unreadNotifications.length}) ${document.title}`;
    }, 100);

    return () => clearInterval(interval);
  }, [isNotificationsLoading, unreadNotifications.length]);

  const badger = useMemo(() => {
    try {
      return new Badger({ size: 0.7, position: "se" });
    } catch (_) {
      return { value: 0 };
    }
  }, []);

  useEffect(() => {
    badger.value = unreadNotifications.length;
  }, [badger, unreadNotifications.length]);

  return (
    <AppBar
      position="absolute"
      sx={{
        height: TOP_APPBAR_HEIGHT,
        backgroundColor: "#ffffffff",
        margin: 0,
        marginLeft: DRAWER_WIDTH / 8,
        paddingY: 0,
        paddingX: 1,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        // borderBottom: "thin solid",
        borderColor: "white.border",
        boxShadow: "none",
        backdropFilter: "blur(10px)",
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box
          flexGrow={1}
          sx={{
            paddingLeft: DRAWER_WIDTH / 8 + 3,
            width: "100%",
          }}
          id="top-bar-breadcrumbs"
        />

        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="flex-end"
          sx={{
            height: TOP_APPBAR_HEIGHT,
            width: 450,
          }}
        >
          {/* Search box */}
          <Box mr={1}>
            <SearchBox placeholder="Search" />
          </Box>

          <MuiLink
            href="mailto:support@bugpilot.io?subject=Help with my account"
            target="_blank"
          >
            <Tooltip arrow title="Contact support">
              <IconButton>
                <SupportAgentIcon
                  fontSize="small"
                  sx={{ color: "tertiary.main" }}
                />
              </IconButton>
            </Tooltip>
          </MuiLink>

          {!isCompanyMembersLoading && !isNotificationsLoading && user && (
            <>
              <IconButton
                onClick={handleNotificationsMenuToggle}
                ref={notificationsMenuRef}
              >
                {notifications.length ? (
                  <Badge
                    sx={{ "& .MuiBadge-badge": { height: 15, width: 15 } }}
                    badgeContent={unreadNotifications.length}
                    max={100}
                    color="error"
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <NotificationsActiveIcon
                      fontSize="small"
                      sx={{ color: "tertiary.main" }}
                    />
                  </Badge>
                ) : (
                  <NotificationsIcon
                    fontSize="small"
                    sx={{ color: "tertiary.main" }}
                  />
                )}
              </IconButton>

              <NotificationsDrawer
                anchorEl={notificationsMenuRef.current}
                onClose={handleNotificationsMenuToggle}
                open={showNotificationsMenu}
                notifications={notifications}
                companyMembers={companyMembers}
                user={user}
                onRead={markNotificationAsRead}
              />
            </>
          )}

          <IconButton
            onClick={() => {
              setShowProfileMenu(true);
            }}
            ref={profileMenuRef}
          >
            <PersonIcon fontSize="small" sx={{ color: "tertiary.main" }} />
          </IconButton>

          <Menu
            anchorEl={profileMenuRef.current}
            open={showProfileMenu}
            onClose={() => setShowProfileMenu(false)}
            onClick={() => setShowProfileMenu(false)}
          >
            <MenuItem
              dense
              value="account"
              onClick={() => navigate("/account")}
            >
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="My profile" />
            </MenuItem>

            <MenuItem dense value="signout" onClick={() => signOut()}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
    </AppBar>
  );
};

import { useCallback } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Stack } from "@mui/system";

import format from "date-fns/format";

import Button from "../../components/Button";
import { useModals } from "../../hooks/useModals";
import { useAppContext } from "../../contexts/AppContext";
import { SettingsSection } from "./SettingsSection.tsx";
import { Divider, MenuItem, Select } from "@mui/material";

const Compliance = ({ user, company, isDisabled }) => {
  const modals = useModals();
  const { updateCompany, createConsent, selectedCompanyId } = useAppContext();

  const handleAcceptDataProcessingAddendum = useCallback(() => {
    modals.openModal(modals.modalTypes.DataProcessingAddendum, {
      user,
      onConfirm: async () => {
        await createConsent({ type: "dpa" });
        await updateCompany(selectedCompanyId, {
          dataProcessingAddendumAcceptedOn: Math.round(new Date() / 1000),
          dataProcessingAddendumAcceptedBy: {
            sub: user.sub,
            firstName: user.given_name,
            lastName: user.family_name,
            email: user.email,
          },
        });
        modals.closeModal();
      },
    });
  }, [selectedCompanyId, createConsent, modals, updateCompany, user]);

  return (
    <Box>
      <Stack spacing={3}>
        <SettingsSection
          title="Expire reports"
          description={
            <span>
              Bug reports are deleted after{" "}
              <span
                style={{
                  fontFamily: "monospace",
                }}
              >
                30 days
              </span>{" "}
              of inactivity. If you make any changes to a report, the countdown
              will restart. This is to ensure that we don&apos;t store data for
              longer than necessary.
            </span>
          }
        ></SettingsSection>

        <SettingsSection
          title="Data Location"
          description="All your data is encrypted in-transit using TLSv1.3 and at rest
            using AES-256. Enterprise customers can choose a data region below."
        >
          <Select size="small" value="eu1" onChange={() => {}}>
            <MenuItem selected value="eu1">
              European Union
            </MenuItem>
            <Divider
              sx={{
                color: "tertiary.main",
              }}
            >
              Enterprise
            </Divider>
            <MenuItem disabled value="us">
              United States
            </MenuItem>
            <MenuItem disabled value="us">
              Brazil
            </MenuItem>
            <MenuItem disabled value="us">
              Germany
            </MenuItem>
          </Select>
        </SettingsSection>

        <SettingsSection
          title="Data processing addendum"
          description="We offer a data processing addendum to our customers who need it. This is generally required if you are subject to the GDPR."
        >
          {company.dataProcessingAddendumAcceptedOn ? (
            <Typography variant="caption">
              Accepted on{" "}
              {format(
                new Date(company.dataProcessingAddendumAcceptedOn * 1000),
                "LLL d, yyyy"
              )}{" "}
              by {company.dataProcessingAddendumAcceptedBy.email} (
              <Link
                href="https://docs.google.com/document/d/e/2PACX-1vT7o5Up9P__mgxk6YQmioSgZq6Y42k7EgNxz_FMURsbnEY2llwEXN0Jz7zOUJHeShxZDD9aGu3aUVU0/pub?embedded=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                link
              </Link>
              )
            </Typography>
          ) : (
            <Button
              variant="contained"
              onClick={handleAcceptDataProcessingAddendum}
            >
              Read &amp; Accept
            </Button>
          )}
        </SettingsSection>
      </Stack>
    </Box>
  );
};

export default Compliance;

import React from "react";
import { Typography } from "@mui/material";
import { useIntegrations } from "../../contexts/IntegrationsContext";

export const ZendeskDescription = () => {
  const { zendeskSubdomain } = useIntegrations();

  if (!zendeskSubdomain) {
    return null;
  }

  return (
    <Typography variant="caption" component="p">
      subdomain <code>{zendeskSubdomain}</code>
    </Typography>
  );
};

/* eslint-disable react/jsx-pascal-case */
import { CircularProgress } from "@mui/material";
import { NoRows_Sessions } from "./NoRows_Sessions";
import { NoRows_Inbox } from "./NoRows_Inbox";

export function NoRows({
  activeTab,
  isInbox,
  isReportsLoading,
  onCreateSampleReport,
  isAll,
  reports,
  currentView,
  setCurrentView,
}) {
  if (reports && reports.length > 0) {
    return;
  }

  return isReportsLoading ? (
    <CircularProgress />
  ) : isInbox ? (
    <NoRows_Inbox
      activeTab={activeTab}
      onCreateSampleReport={onCreateSampleReport}
      currentView={currentView}
      setCurrentView={setCurrentView}
    />
  ) : (
    <NoRows_Sessions offerCreateDemoReport={isAll} />
  );
}

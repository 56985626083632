import {
  ListAltOutlined as ListAltIcon,
  SettingsOutlined as SettingsIcon,
} from "@mui/icons-material";
import { NoRowsWrapper } from "./NoRowsWrapper.js";
import { Box, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const NoRows_EmptyPendingReview_GoodJob = ({
  currentView,
  setCurrentView,
}) => {
  const navigate = useNavigate();

  const src =
    "https://imagedelivery.net/55PXnfNaLA7qnpEnq-80ug/ee6c338d-0e09-44b8-8fcc-8cdac752c300/w=200,h=200,c=fit";

  return (
    <NoRowsWrapper
      icon={
        <Box
          sx={{
            cursor: "pointer",
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            key="image"
            src={src}
            alt="Woohoo"
            style={{
              borderRadius: 10,
              aspectRatio: "1/1",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </Box>
      }
      title="Woohoo!"
      description={
        <>
          {`You've cleared all ${
            currentView === "priority"
              ? "recommended"
              : currentView === "user-reported"
              ? "user-reported"
              : "new"
          } issues by assigning a status to every single one.`}
          <br />
          Keep up the fantastic work in keeping those bugs in check!
        </>
      }
      stackProps={{
        alignItems: "center",
      }}
      paragraphProps={{
        textAlign: "center",
      }}
    >
      {currentView !== "all" && (
        <Stack spacing={1}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => setCurrentView("all")}
            startIcon={<ListAltIcon />}
          >
            View low-priority issues
          </Button>

          <Button
            variant="text"
            size="small"
            color="tertiary"
            onClick={() => {
              navigate("/settings/customize#autoresolve");
            }}
            startIcon={<SettingsIcon />}
            sx={{
              fontSize: "0.875rem",
            }}
          >
            auto-resolve settings
          </Button>
        </Stack>
      )}
    </NoRowsWrapper>
  );
};

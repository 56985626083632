import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import FormHelperText from "@mui/material/FormHelperText";

import CloseIcon from "@mui/icons-material/CloseOutlined";

import Button from "../Button";

import Input from "../Input";
import { getFieldProps } from "../../utils/getFieldProps";
import ErrorText from "../ErrorText";
import { useAppContext } from "../../contexts/AppContext";
import Loader from "../Loader";
import MenuItem from "@mui/material/MenuItem";

const DialogActions = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(4)}`,
  },
}))(MuiDialogActions);

const DialogContent = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(4)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogTitle);

const validationSchema = Yup.object({
  planId: Yup.number().integer().required(),
});

const ChangePlanModal = ({ onClose, onConfirm }) => {
  const {
    isSubscriptionsLoading,
    fetchSubscriptions,
    currentWorkspace,
    subscriptions,
  } = useAppContext();
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const formik = useFormik({
    initialValues: {
      planId: currentWorkspace.paddleData.subscriptionPlanId,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async ({ planId }) => {
      try {
        console.log(
          subscriptions.find((subscription) => subscription.id === planId).name
        );
        await onConfirm({
          planId,
          planName: subscriptions.find(
            (subscription) => subscription.id === planId
          ).name,
        });
        onClose();
      } catch (e) {
        setError(e.message !== "null" ? e.message : e.code);
      }
    },
  });

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" fontWeight="bold">
            Change plan
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isSubscriptionsLoading && <Loader sx={{ height: "100%" }} />}
        {!isSubscriptionsLoading && (
          <Stack spacing={3}>
            <Input
              select
              fullWidth
              label="Plan"
              size="small"
              required
              {...getFieldProps(formik, {
                name: "planId",
              })}
            >
              {subscriptions
                .filter(({ name, id }) =>
                  (currentWorkspace.availablePlans ?? []).some((plan) =>
                    name.toLowerCase().includes(plan.toLowerCase())
                  )
                )
                .map(
                  ({
                    name,
                    id,
                    recurringPrice,
                    billingPeriod,
                    billingType,
                  }) => (
                    <MenuItem value={id} key={id}>
                      {name} ${recurringPrice.usd} /{" "}
                      {billingPeriod > 1 ? `${billingPeriod} ` : ""}
                      {billingType}
                    </MenuItem>
                  )
                )}
            </Input>

            <Typography variant="bofy1">
              You will be charged immediately the difference in price between
              the old and the new plan, pro-rated for the remaining time in the
              current billing period.
            </Typography>

            {error && (
              <FormHelperText error>
                <ErrorText>{error}</ErrorText>
              </FormHelperText>
            )}
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          bold
          disabled={formik.isSubmitting}
          onClick={formik.handleSubmit}
          endIcon={formik.isSubmitting && <CircularProgress size={25} />}
          fullWidth
          variant="contained"
        >
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePlanModal;

import { useCallback, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";

import {
  CheckCircleOutlined as CheckCircleIcon,
  VisibilityOutlined as VisibilityIcon,
  VisibilityOffOutlined as VisibilityOffIcon,
} from "@mui/icons-material";

const ApiConfig = ({ isDisabled, company, onCompanyChange }) => {
  const [showValue, setShowValue] = useState(false);
  const toggleShowValue = useCallback(() => setShowValue((v) => !v), []);

  return (
    <Stack spacing={4}>
      {isDisabled && (
        <Alert severity="warning">
          At this time, only the owner of a workspace can get the API Key
        </Alert>
      )}

      <List dense disablePadding sx={{ mt: 0 }}>
        <ListItem disableGutters>
          <ListItemText>Currently supported operations:</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <CheckCircleIcon fontSize="small" />
          </ListItemAvatar>
          <ListItemText>
            Get an automated session recording from a user
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <CheckCircleIcon fontSize="small" />
          </ListItemAvatar>
          <ListItemText>Get a screenshot from a user</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <CheckCircleIcon fontSize="small" />
          </ListItemAvatar>
          <ListItemText>Request a 60-second screen recording</ListItemText>
        </ListItem>
      </List>

      <Typography variant="body1" fontWeight="bold">
        API Credentials
      </Typography>

      <TextField
        variant="outlined"
        label="Workspace Id"
        onFocus={(e) => e.target.select()}
        value={company.id}
        readonly
      />

      <TextField
        label="API Key"
        value={company.workspaceSecret || ""}
        readonly
        type={showValue ? "text" : "password"}
        onFocus={(e) => {
          setShowValue(true);
          e.target.select();
        }}
        onBlur={() => setShowValue(false)}
        helperText={
          company.workspaceSecret ? (
            "The API Key is for server-side use only. Keep it private."
          ) : isDisabled ? (
            <Typography variant="body1" color="error">
              You&apos;re not the owner of this workspace. Only the owner can
              get the API Key.
            </Typography>
          ) : (
            "No API Key? Try refreshing this page."
          )
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={toggleShowValue}
                edge="end"
              >
                {showValue ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Typography variant="body1" fontWeight="bold">
        API Settings
      </Typography>

      <FormControlLabel
        style={{ marginTop: 8 }}
        control={
          <Checkbox
            checked={company.apiWebsocketMode === "always-on"}
            onChange={(e) =>
              onCompanyChange({
                apiWebsocketMode: e.target.checked ? "always-on" : "manual",
              })
            }
          />
        }
        label="Always connect Action WebSockets"
      />

      <Typography variant="subtitle2">
        <Link href="https://api-docs.bugpilot.io/" target="_blank">
          Read the documentation
        </Link>
      </Typography>
    </Stack>
  );
};

export default ApiConfig;

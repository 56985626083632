import { useCallback, useState, useEffect } from "react";

const useLocalStorage = (key, initialValue, storage = window.localStorage) => {
  const getValue = useCallback(() => {
    {
      try {
        const item = storage.getItem(key);

        if (item) {
          return JSON.parse(item);
        }

        storage.setItem(key, JSON.stringify(initialValue));
        return initialValue;
      } catch (e) {
        storage.setItem(key, JSON.stringify(initialValue));
        return initialValue;
      }
    }
  }, [storage, initialValue, key]);

  const [storedValue, setStoredValue] = useState(getValue);

  const setValueAndFireEvent = useCallback(
    (value) => {
      const newValue = typeof value === "function" ? value(storedValue) : value;

      setStoredValue(newValue);

      storage.setItem(key, JSON.stringify(newValue));
      window.dispatchEvent(
        new CustomEvent("custom-storage-change", {
          detail: { key, newValue, oldValue: storedValue },
        })
      );
    },
    [key, storage, storedValue]
  );

  const onStorageCustomEvent = useCallback(
    (event) => {
      // Syncs the storage value with
      // this instance:
      let newValue;

      if (event.key === key) {
        newValue = event.newValue;
      }

      if (event.detail?.key === key) {
        newValue = event.detail.newValue;
      }

      if (newValue) {
        try {
          setStoredValue(JSON.parse(newValue));
        } catch (e) {
          setStoredValue(newValue);
        }
      }
    },
    [key, setStoredValue]
  );

  useEffect(() => {
    window.addEventListener("storage", onStorageCustomEvent);
    window.addEventListener("custom-storage-change", onStorageCustomEvent);

    return () => {
      window.addEventListener("storage", onStorageCustomEvent);
      window.removeEventListener("custom-storage-change", onStorageCustomEvent);
    };
  });

  useEffect(() => {
    setStoredValue(getValue);
  }, [JSON.stringify(initialValue), key]);

  return [storedValue, setValueAndFireEvent];
};

export default useLocalStorage;

import React, { useCallback, useMemo } from "react";
import { useAppContext } from "../../../contexts/AppContext";
import Loader from "../../../components/Loader";
import { useModals } from "../../../hooks/useModals";
import { useAuth } from "../../../contexts/AuthContext";
import { useIntegrations } from "../../../contexts/IntegrationsContext";
import { IntegrationsPage } from "./IntegrationsPage";

const IntegrationsPageContainer = () => {
  const { selectedCompanyId, companies, updateCompany } = useAppContext();
  const { user } = useAuth();
  const {
    isWorkspaceIntegrationsLoading,
    createWorkspaceIntegration,
    deleteCompanyIntegration,
    updateCompanyIntegration,
  } = useIntegrations();

  const modals = useModals();

  const handleIntegrationDelete = useCallback(
    async ({ type, id }) => {
      if (type === "integration-email") {
        await deleteCompanyIntegration({ type, companyId: id });
        return;
      }
      modals.openConfirmation({
        onConfirm: async () =>
          deleteCompanyIntegration({ type, companyId: id }),
        text: "This integration will be permanently deleted and it won’t be possible to recover it. ",
      });
    },
    [modals, deleteCompanyIntegration]
  );

  const handleCompanyChange = useCallback(
    (changes) => updateCompany(selectedCompanyId, changes),
    [updateCompany, selectedCompanyId]
  );

  const company = useMemo(
    () => companies.find(({ id }) => id === selectedCompanyId),
    [companies, selectedCompanyId]
  );

  if (isWorkspaceIntegrationsLoading) {
    return <Loader />;
  }

  return (
    <IntegrationsPage
      company={company}
      user={user}
      onCompanyChange={handleCompanyChange}
      onCreateIntegration={createWorkspaceIntegration}
      onDeleteIntegration={handleIntegrationDelete}
      onUpdateIntegration={updateCompanyIntegration}
    />
  );
};
export default IntegrationsPageContainer;

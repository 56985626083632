import { useMemo, useCallback } from "react";
import { markdownToDraft, draftToMarkdown } from "markdown-draft-js";
import { convertToRaw } from "draft-js";

import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import MUIRichTextEditor from "../lib/MUIRichTextEditor/index.tsx";
import { Paper } from "@mui/material";

const MarkdownEditor = ({
  name,
  required,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  editorProps = {},
  placeholder,
  paper,
}) => {
  const defaultValue = useMemo(
    () => JSON.stringify(markdownToDraft(value)),
    [value]
  );

  const handleChange = useCallback(
    (editorState) => {
      const content = editorState.getCurrentContent();
      const rawObject = convertToRaw(content);
      const markdownString = draftToMarkdown(rawObject);
      onChange?.(
        {
          target: {
            name,
            value: markdownString,
          },
        },
        markdownString
      );
    },
    [name, onChange]
  );

  const Component = useMemo(() => (paper ? Paper : Box), [paper]);

  return (
    <Stack>
      {label && (
        <FormLabel>
          <Typography
            variant="body1"
            sx={{ color: "text.primary" }}
            mb={paper ? 1 : 0}
          >
            {label}{" "}
            {required && (
              <Box
                component="span"
                sx={{ color: ({ palette }) => palette.error.main }}
              >
                *
              </Box>
            )}
          </Typography>
        </FormLabel>
      )}

      <Component flexGrow={1}>
        <MUIRichTextEditor
          controls={["bold", "italic", "underline", "numberList", "bulletList"]}
          inlineToolbar
          inlineToolbarControls={["bold", "italic", "underline"]}
          defaultValue={defaultValue}
          label={placeholder}
          {...editorProps}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </Component>
    </Stack>
  );
};

export default MarkdownEditor;

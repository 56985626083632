import { Link } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";
import { Alert } from "@mui/material";

export function RequireScriptSetup({ children }) {
  const { scriptIsSetup, scriptIsSetupChecking } = useAppContext();

  if (scriptIsSetupChecking) {
    return children;
  }

  if (
    scriptIsSetup === false &&
    window.location.pathname.startsWith("/settings") === false &&
    window.location.pathname.startsWith("/getting-started") === false &&
    window.location.pathname.startsWith("/report") === false &&
    window.location.pathname.startsWith("/statuspage") === false
  ) {
    return (
      <>
        <Alert
          severity="error"
          variant="filled"
          sx={{
            marginBottom: 2,
          }}
        >
          Tracking code not detected. Please follow the instructions to install
          the tracking code so Bugpilot can start finding bugs on your app.
          <Link
            to="/getting-started/code"
            style={{ marginLeft: 8, color: "inherit", fontWeight: "bold" }}
          >
            Setup instructions →
          </Link>
        </Alert>

        {children}
      </>
    );
  }

  return children;
}

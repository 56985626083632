import { useCallback, useMemo, useState } from "react";

import { truncate } from "lodash";
import ms from "ms";

import {
  Alert,
  Grid,
  Input,
  Stack,
  Link,
  Typography,
  Box,
} from "@mui/material";
import {
  ExpandLessOutlined as ExpandLessIcon,
  KeyboardOutlined as KeyboardIcon,
  PublicOutlined as PublicIcon,
  PersonOutlined as PersonIcon,
  VisibilityOutlined as VisibilityIcon,
  VisibilityOffOutlined as VisibilityOffIcon,
  ChevronRightOutlined as ChevronRightIcon,
  OpenInNewOutlined as OpenInNewIcon,
} from "@mui/icons-material";

import { getTargetName } from "./StepsToReproduce/utils.tsx";
import { RenderError } from "./RenderError.js";
import Value from "./shared/Value.js";
import CodeContainer from "../CodeContainer.tsx";
import { withErrorBoundary } from "react-error-boundary";

import DateTime from "../DateTime.js";
import Button from "../Button";

const CodeBlock = ({ html, black }) => {
  if (!html) return null;

  return <CodeContainer code={html} language="html" />;
};

const UserTypedValue = ({ value }) => {
  return (
    <Input
      value={value}
      readOnly
      variant="outlined"
      size="small"
      sx={{
        fontFamily: "monospace",
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.target.select();
      }}
    />
  );
};

const RowContainer = ({
  icon,
  label,
  DetailsComponent,
  variant,
  children,
  timestamp,
  chevron = false,
}) => {
  const [expanded, setExpanded] = useState(false);

  const expandable = useMemo(
    () => children || DetailsComponent,
    [children, DetailsComponent]
  );

  const toggleExpanded = useCallback(() => {
    if (!expandable) {
      return;
    }

    setExpanded(!expanded);
  }, [expandable, expanded]);

  return (
    <Stack
      sx={{
        width: "100%",
        backgroundColor: expanded ? "white.main" : "transparent",
        ml: -3,
        pl: 3,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={toggleExpanded}
        sx={{
          cursor: expandable ? "pointer" : "default",
          minHeight: 30,
        }}
      >
        <Typography
          variant="code"
          component="div"
          flexGrow={1}
          sx={{
            color: "secondary.main",
            fontFamily: "monospace",
            fontSize: 12,
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            maxWidth: "100%",
            mr: 2,
          }}
        >
          <Box
            sx={{
              visibility: expandable ? "visible" : "hidden",
              mr: 1,
              color: "tertiary.main",
              height: 20,
            }}
          >
            {chevron ? (
              expanded ? (
                <ExpandLessIcon fontSize="small" />
              ) : (
                <ChevronRightIcon fontSize="small" />
              )
            ) : null}
          </Box>

          {label}
        </Typography>

        <Typography
          flexShrink={0}
          variant="code"
          sx={{
            fontFamily: "monospace",
            fontSize: 12,
            color: "secondary.main",
            textAlign: "right",
          }}
        >
          <DateTime onlyTime withSeconds>
            {timestamp}
          </DateTime>
        </Typography>
      </Stack>

      {expanded && (
        <Box p={2} pt={3}>
          {children}
          {DetailsComponent}
        </Box>
      )}
    </Stack>
  );
};

const RowNetwork = ({ data, timestamp, isMuted, report }) => {
  const method = useMemo(
    () => data.args[0]?.method?.toUpperCase() || "GET",
    [data.args]
  );

  const statusCode = useMemo(
    () => data.response?.status ?? "-1",
    [data.response]
  );

  const humanUrl = useMemo(() => {
    const urlString = data.url
      ? typeof data.url === "string"
        ? data.url
        : data.url.toString()
      : report.url;
    return truncate(urlString, { length: 100 });
  }, [data, report.url]);

  const vercelRequestId = data.response?.headers?.["x-vercel-id"]
    ?.split("::")
    ?.slice(-1)?.[0];

  return (
    <RowContainer
      timestamp={timestamp}
      chevron
      label={
        <Stack direction="row" alignItems="center" spacing={1}>
          <span>{method}</span>

          <Link
            href={data.url}
            target="_blank"
            onClick={(e) => {
              e.stopPropagation();
            }}
            sx={{
              textDecoration: "underline",
              textUnderlineOffset: "2px",
            }}
            noWrap
          >
            {humanUrl}
          </Link>

          <span>{statusCode}</span>

          {vercelRequestId && report.integrations?.vercel?.inspectorUrl && (
            <Button
              sx={{
                ml: 8,
              }}
              size="small"
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                e.stopPropagation();

                const url = new URL(
                  `${report.integrations.vercel.inspectorUrl
                    .split("/")
                    .slice(0, -1)
                    .join("/")}/logs`
                );

                url.searchParams.set("requestIds", vercelRequestId);
                url.searchParams.set("timeline", "absolute");
                url.searchParams.set(
                  "startDate",
                  (report.timestamp - ms("10m")).toString()
                );
                url.searchParams.set(
                  "endDate",
                  (report.timestamp + ms("10m")).toString()
                );

                window.open(url.toString(), "_blank");
              }}
              startIcon={<OpenInNewIcon />}
            >
              Vercel Logs
            </Button>
          )}
        </Stack>
      }
    >
      <Grid container direction="row" spacing={4} sx={{ width: "100%" }}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom>
            Request
          </Typography>

          {data.args[0]?.headers && (
            <Value rootName="Headers" value={data.args[0].headers} />
          )}
          {data.args[0]?.body ? (
            <Value
              rootName="Body"
              collapsed
              value={data.args[0].body}
              contentTypeHint={
                data.args[0].headers?.["Content-Type"]?.split(";")?.[0] ?? ""
              }
            />
          ) : (
            <Typography variant="caption" mt={1}>
              No request body.
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom>
            Response
          </Typography>

          {data.response?.headers &&
          Object.keys(data.response?.headers ?? {}).length !== 0 ? (
            <Value rootName="Headers" value={data.response.headers}></Value>
          ) : (
            <Box mt={2}>
              <Alert severity="warning">No response headers available.</Alert>
            </Box>
          )}

          {data.response?.body ? (
            <Value rootName="Body" collapsed value={data.response.body}></Value>
          ) : (
            <Box mt={2}>
              <Alert severity="info">
                No response body was returned or the Content-Type header is not
                supported.
              </Alert>
            </Box>
          )}
        </Grid>
      </Grid>
    </RowContainer>
  );
};

const RowClick = ({ data, timestamp }) => {
  const { html, tagName } = data.target;
  const name = getTargetName(data.target);

  const label =
    name?.length > 0 ? (
      <span>
        <BugpilotBrand /> Click on &lt;{tagName?.toLowerCase?.()}&gt; with name{" "}
        <code>{name}</code>
      </span>
    ) : (
      "Click"
    );

  return (
    <RowContainer
      chevron
      icon={PersonIcon}
      label={label}
      DetailsComponent={<CodeBlock html={html} />}
      timestamp={timestamp}
    />
  );
};

const RowTyping = ({ data, timestamp }) => {
  const { value, html, tagName } = data.target;
  const name = getTargetName(data.target);

  const label =
    name?.length > 0 ? (
      <span>
        <BugpilotBrand /> Typing <UserTypedValue value={value} /> in{" "}
        {tagName?.toLowerCase?.()} with name <code>{name}</code>
      </span>
    ) : (
      <span>Type &laquo;{value}&raquo;</span>
    );

  return (
    <RowContainer
      chevron
      icon={KeyboardIcon}
      label={label}
      DetailsComponent={<CodeBlock html={html} black />}
      timestamp={timestamp}
    />
  );
};

const RowNavigate = ({ data, timestamp }) => {
  const label = (
    <span>
      <BugpilotBrand /> Page load{" "}
      <Link
        href={data.url}
        target="_blank"
        rel="noreferrer noopener"
        sx={{
          textDecoration: "underline",
          textUnderlineOffset: "2px",
        }}
      >
        {truncate(data.url, { length: 200 })}
      </Link>
    </span>
  );

  return (
    <RowContainer
      chevron
      icon={PublicIcon}
      label={label}
      timestamp={timestamp}
    />
  );
};

// utils
//

export const isFetchResponseOK = (data) => {
  return data?.ok && data?.status >= 200 && data?.status < 400;
};

export const isBlockedByClient = (data) => {
  return data?.status === 0;
};

export const isConsoleError = (data) => {
  return data?.method === "error";
};

export const isTypeFetchResponse = (type) => {
  return type === "fetchResponse";
};

export const isTypeFetch = (type) => {
  return type === "fetch";
};

export const isTypeConsole = (type) => {
  return type === "console";
};

export const isTypePointer = (type) => {
  return type === "pointer";
};

const RowConsole = ({ data, isMuted, timestamp, wrapLines, compact }) => {
  const errorDetails = data.args.map((arg, i) => {
    if (Object.hasOwnProperty.call(arg || {}, "isErrorEvent")) {
      return (
        <RenderError
          key={i}
          value={arg}
          compact={compact}
          wrapLines={wrapLines}
        />
      );
    }

    return <Value key={i} value={arg} collapsed wrapLines={wrapLines}></Value>;
  });

  return (
    <RowContainer
      chevron
      icon={false}
      label={
        <Box
          py={1}
          sx={{
            maxWidth: "100%",
          }}
        >
          {errorDetails}
        </Box>
      }
      timestamp={timestamp}
    ></RowContainer>
  );
};

const RowResource = ({ data, timestamp }) => (
  <RowContainer
    chevron
    icon={PublicIcon}
    label={
      <span>
        {data.initiatorType}{" "}
        <Link href={data.url} target="_blank" rel="noreferrer noopener">
          {truncate(data.url, { length: 80 })}
        </Link>
      </span>
    }
    timestamp={timestamp}
  />
);

const BugpilotBrand = () => (
  <Typography
    variant="span"
    sx={{
      color: "primary.accent",
    }}
  >
    [Bugpilot]
  </Typography>
);

const RowVisibility = ({ data, timestamp }) => {
  const { state } = data;

  return (
    <RowContainer
      chevron
      icon={state === "hidden" ? VisibilityOffIcon : VisibilityIcon}
      label={
        state === "hidden" ? (
          <span>
            <BugpilotBrand /> User is inactive or switched to another tab
          </span>
        ) : (
          <span>
            <BugpilotBrand /> User is back on the page
          </span>
        )
      }
      timestamp={timestamp}
    />
  );
};

const ActionsTableRow = ({ item, wrapLines, compact, report }) => {
  const { type, timestamp, data, mutedError } = item;

  if (data?.args?.[0]?.includes?.("Error while reading CSS rules from")) {
    return null;
  }

  if (type === "fetch") {
    return (
      <RowNetwork
        data={data}
        timestamp={timestamp}
        isMuted={Boolean(mutedError)}
        report={report}
      />
    );
  }

  if (type === "navigate") {
    return <RowNavigate data={data} timestamp={timestamp} />;
  }

  if (type === "console") {
    return (
      <RowConsole
        data={data}
        isMuted={Boolean(mutedError)}
        timestamp={timestamp}
        wrapLines={wrapLines}
        compact={compact}
      />
    );
  }

  if (type === "pointer") {
    return <RowClick data={data} timestamp={timestamp} />;
  }

  if (type === "pointer-blur") {
    return <RowTyping data={data} timestamp={timestamp} />;
  }

  if (type === "resource" && data.initiatorType !== "fetch") {
    return <RowResource data={data} timestamp={timestamp} />;
  }

  if (type === "visibility") {
    return <RowVisibility data={data} timestamp={timestamp} />;
  }

  return <code>{JSON.stringify(data)}</code>;
};

export default withErrorBoundary(ActionsTableRow, {
  fallback: <p>Failed to render actions table row</p>,
  onError: (error, componentStack) => {
    console.error("ActionsTableRow render error", error, componentStack);
  },
});

import React from "react";
import { Typography, Button, Stack } from "@mui/material";
import { OpenInNewOutlined as OpenInNewIcon } from "@mui/icons-material";
import IntercomIcon from "../../icons/logos/IntercomIcon";

export const IntercomHomeDescription = ({
  company,
  onCompanyChange,
  isDisabled,
}) => (
  <Stack spacing={2} alignItems="flex-start">
    <Stack direction="row" spacing={2}>
      <IntercomIcon />
      <Typography variant="body1" fontWeight="bold">
        Intercom Home App
      </Typography>
    </Stack>

    <Typography variant="body1">
      You can add a Submit a bug report button to your Intercom Widget Home, so
      users can report bugs without even start a conversation with you.
    </Typography>

    <Button
      href="https://bugpilot.notion.site/Intercom-Add-a-Report-Bug-button-to-your-Messenger-c8491c3eacd44224b66b5e469f020c97"
      target="_blank"
      variant="outlined"
      size="small"
      color="secondary"
      startIcon={<OpenInNewIcon />}
    >
      Documentation
    </Button>
  </Stack>
);

import { GetTrackingCode } from "./GetTrackingCode";

import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

export const StepCode = ({ scriptIsSetup }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (scriptIsSetup) {
      navigate("/getting-started/done");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptIsSetup]);

  if (scriptIsSetup) {
    return <Navigate to="/getting-started/done" />;
  }

  return <GetTrackingCode />;
};

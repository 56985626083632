import React from "react";
import { IconButton, Stack } from "@mui/material";
import {
  DeleteOutlined as DeleteIcon,
  EditOutlined as EditIcon,
  ReplyOutlined as ReplyIcon,
} from "@mui/icons-material";

export const OwnerActionsMenu = ({ onDelete, onEdit, mouseOver, onReply }) => {
  return (
    <Stack
      direction={"row"}
      spacing={1}
      sx={{
        opacity: mouseOver ? 1 : 0,
        transition: "opacity 0.2s ease-in-out",
      }}
    >
      <IconButton size="small" onClick={onReply}>
        <ReplyIcon
          fontSize="small"
          sx={{
            height: 16,
            width: 16,
          }}
        />
      </IconButton>
      <IconButton size="small" onClick={onEdit}>
        <EditIcon
          fontSize="small"
          sx={{
            height: 16,
            width: 16,
          }}
        />
      </IconButton>
      <IconButton size="small" onClick={onDelete} sx={{ color: "error.main" }}>
        <DeleteIcon
          fontSize="small"
          sx={{
            height: 16,
            width: 16,
          }}
        />
      </IconButton>
    </Stack>
  );
};

import { truncate } from "lodash";

export const makeReportUrl = (report) => {
  // if (report.metadata?.errorInfo?.originalReportId) {
  //   return `/report/${report.metadata.errorInfo.originalReportId}`;
  // }

  const slug = report.title
    ? truncate(report.title.replace(/\W+/g, "-").toLowerCase(), {
        length: 60,
        omission: "",
      })
    : "untitled-report";

  return `/report/${report.serialNumber}/${slug}/${report.id}`;
};

import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";

export const LogoutRoute = () => {
  const { signOut } = useAuth();

  useEffect(() => {
    signOut();

    const ti = setTimeout(() => {
      window.location.href = "/";
    }, 1000);

    return () => clearTimeout(ti);
  }, [signOut]);
};

import { Button, Chip } from "@mui/material";
import { AutoAwesomeOutlined as AutoAwesomeIcon } from "@mui/icons-material";

export function UpgradeButton() {
  return (
    <Button
      variant="text"
      size="small"
      sx={{
        textTransform: "uppercase",
        justifyContent: "flex-start",
        ml: -1,

        "&:hover": {
          background: "none",
        },

        "&:active": {
          background: "none",
        },
      }}
      href="/subscription/start"
    >
      <Chip
        icon={<AutoAwesomeIcon fontSize="small" color="inherit" />}
        label={"Upgrade"}
        sx={{
          cursor: "pointer",
          px: 0.5,
          color: "sidebar.item",
          // nice pink gradient bg
          background:
            "linear-gradient(245deg, rgba(241,71,251,0.90) 0%, rgba(241,71,251,0.50) 78%);",
          borderRadius: 1,

          "&:hover": {
            background:
              "linear-gradient(245deg, rgba(241,71,251,0.99) 0%, rgba(241,71,251,0.70) 78%);",
          },

          "&:active": {
            background:
              "linear-gradient(245deg, rgba(241,71,251,0.99) 0%, rgba(241,71,251,0.70) 78%);",
          },
        }}
      ></Chip>
    </Button>
  );
}

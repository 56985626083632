import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SystemBrowserInfo from "./SystemBrowserInfo";
import UserInfo from "./UserInfo";
import { Paper, Typography } from "@mui/material";

import ButtonDialog from "../ButtonDialog";
import StoragePanel from "./StoragePanel";

const StorageCookiesInfo = ({ report }) => {
  return (
    <ButtonDialog
      buttonLabel="Show values"
      buttonProps={{ variant: "outlined", color: "secondary", size: "small" }}
    >
      <StoragePanel report={report} />
    </ButtonDialog>
  );
};

const TimeZone = ({ report }) => {
  return (
    <Box pl={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body1" fontWeight="bold">
          Time Zone
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {report.timeZone}
        </Typography>
      </Stack>
    </Box>
  );
};

export const UserEnvGroup = ({ report }) => {
  return (
    <Stack spacing={2}>
      <Paper>
        <Box p={4}>
          <Typography variant="h2" mb={2}>
            User
          </Typography>
          <UserInfo report={report} />
        </Box>
      </Paper>

      <Paper>
        <Box p={4}>
          <Typography variant="h2" mb={2}>
            Location
          </Typography>
          <TimeZone report={report} />
        </Box>
      </Paper>

      <Paper>
        <Box p={4}>
          <Typography variant="h2" mb={2}>
            Environment
          </Typography>
          <SystemBrowserInfo report={report} />
        </Box>
      </Paper>

      <Paper>
        <Box p={4}>
          <Typography variant="h2" mb={2}>
            Storage & Cookies
          </Typography>
          <StorageCookiesInfo report={report} />
        </Box>
      </Paper>
    </Stack>
  );
};

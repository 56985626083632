import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useCallback } from "react";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import LockIcon from "@mui/icons-material/LockOutlined";

import { getFieldProps } from "../../utils/getFieldProps";
import { useAppContext } from "../../contexts/AppContext";
import Select from "./IntegrationsPage/Select";
import {
  getGitHubIntegrationRepos,
  getGitHubIntegrationProjects,
} from "../../api";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

const githubValidationSchema = Yup.object({
  githubType: Yup.string().oneOf(["issue", "project-draft-issue"]).required(),
  repoId: Yup.string().when("githubType", {
    is: (githubType) => githubType === "issue",
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  repoName: Yup.string(),
  projectId: Yup.string().when("githubType", {
    is: (githubType) => githubType === "project-draft-issue",
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  projectName: Yup.string(),
});

const GitHubIntegration = ({
  integration,
  onUpdateIntegration,
  isDisabled,
}) => {
  const { selectedCompanyId } = useAppContext();
  const formik = useFormik({
    initialValues: {
      githubType: integration.githubType ?? "issue",
      repoId: integration.repoName ?? "",
      repoName: integration.repoName ?? "",
      issueTemplate:
        integration.issueTemplate ??
        "Here is a link to the [bug report]({reportUrl})",
      projectId: integration.projectId ?? "",
      projectName: integration.projectName ?? "",
    },
    enableReinitialize: true,
    validationSchema: githubValidationSchema,
    onSubmit: async (values) => onUpdateIntegration(values),
  });

  const handleRepoChange = useCallback(
    ({ id, nameWithOwner }) => {
      formik.setFieldValue("repoId", id);
      formik.setFieldTouched("repoId", true);
      formik.setFieldValue("repoName", nameWithOwner);
      formik.setFieldTouched("repoName", true);
    },
    [formik]
  );

  const handleTypeChange = useCallback(
    (e) => {
      formik.setFieldValue("githubType", e.target.value);
      formik.setFieldTouched("githubType", true);
      formik.setFieldValue("repoName", "");
      formik.setFieldTouched("repoName", true);
    },
    [formik]
  );

  const handleProjectChange = useCallback(
    ({ id, title }) => {
      formik.setFieldValue("projectId", id);
      formik.setFieldTouched("projectId", true);
      formik.setFieldValue("projectName", title);
      formik.setFieldTouched("projectName", true);
    },
    [formik]
  );

  const repoFieldProps = getFieldProps(formik, {
    name: "workspaceId",
    errorWhenTouchedOrSubmitted: true,
    disabled: isDisabled,
  });

  const projectFieldProps = getFieldProps(formik, {
    name: "projectId",
    errorWhenTouchedOrSubmitted: true,
    disabled: isDisabled,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <RadioGroup
            value={formik.values.githubType}
            onChange={handleTypeChange}
          >
            <FormControlLabel
              value="issue"
              control={<Radio size="small" />}
              label={<>Create an issue inside selected repository</>}
            />
            <FormControlLabel
              value="project-draft-issue"
              control={<Radio size="small" />}
              label={<>Create a draft issue inside selected project</>}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {formik.values.githubType === "issue" && (
        <Grid item xs={12}>
          <Select
            label="Repository"
            fetch={() =>
              getGitHubIntegrationRepos({
                companyId: selectedCompanyId,
                integrationId: integration.type,
              }).then((repos) =>
                repos
                  .filter(({ isArchived }) => !isArchived)
                  .filter(({ hasIssuesEnabled, hasProjectsEnabled }) =>
                    formik.values.githubType === "issue"
                      ? hasIssuesEnabled
                      : hasProjectsEnabled
                  )
              )
            }
            getOptionLabel={(option) => option.nameWithOwner}
            required
            onChange={handleRepoChange}
            disabled={repoFieldProps.disabled}
            error={repoFieldProps.error}
            helperText={
              repoFieldProps.helperText ??
              'Make sure that "Issues" are enabled in repository settings'
            }
            value={
              formik.values.repoId
                ? {
                    id: formik.values.repoId,
                    nameWithOwner: formik.values.repoName,
                  }
                : null
            }
            renderOption={(props, option) => (
              <Stack direction="row" spacing={2} alignItems="center" {...props}>
                {option.isPrivate && <LockIcon fontSize="small" />}
                <Typography variant="body1">{option.nameWithOwner}</Typography>
              </Stack>
            )}
          />
        </Grid>
      )}
      {formik.values.githubType === "project-draft-issue" && (
        <Grid item xs={12}>
          <Select
            label="Project"
            fetch={() =>
              getGitHubIntegrationProjects({
                companyId: selectedCompanyId,
                integrationId: integration.type,
              })
            }
            getOptionLabel={(option) => option.title}
            required
            onChange={handleProjectChange}
            disabled={projectFieldProps.disabled}
            error={projectFieldProps.error}
            value={
              formik.values.projectId
                ? {
                    id: formik.values.projectId,
                    title: formik.values.projectName,
                  }
                : null
            }
            groupBy={(project) => `"${project.owner} projects`}
          />
        </Grid>
      )}
      {formik.dirty && (
        <Grid item>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={formik.handleSubmit}
              endIcon={formik.isSubmitting && <CircularProgress size={25} />}
              disabled={isDisabled || formik.isSubmitting}
            >
              Save
            </Button>
            <Button
              bold
              color="primary"
              disabled={formik.isSubmitting}
              onClick={() => formik.resetForm()}
            >
              Cancel
            </Button>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default GitHubIntegration;

import React, { useEffect } from "react";
import { CircularProgress, Paper, Stack, Typography } from "@mui/material";
import { useAppContext } from "../../contexts/AppContext";
import { Box } from "@mui/system";

import { MenuSectionTitle } from "../../router/MenuSidebar/MenuSectionTitle";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { CheckOutlined as CheckIcon } from "@mui/icons-material";

export const SidebarChecklist = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { scriptIsSetup, currentWorkspace } = useAppContext();

  useEffect(() => {
    if (!currentWorkspace?.id) {
      return;
    }

    if (!user?.sub) {
      return;
    }
  }, [currentWorkspace?.id, user?.sub]);

  return (
    <Paper
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: (theme) => theme.palette.white.main,
          color: (theme) => theme.palette.primary.main,
          px: 2,
          py: 1,
          margin: 0,
          borderRadius: 2,
          position: "relative",
          boxShadow: (theme) =>
            `0px 0px 5px 2px ${theme.palette.sidebar.gradient1}`,
        }}
        onClick={() => navigate("/getting-started/")}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            cursor: "pointer",
          }}
        >
          <Stack sx={{ width: "100%" }}>
            <MenuSectionTitle
              text="Quick Start"
              sx={{
                "& > span": {
                  color: (theme) => theme.palette.sidebar.calloutText,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontSize: "0.75rem",
                  letterSpacing: "80%",
                },
                mt: 0,
              }}
            />
            <Typography variant="caption" sx={{ letterHeight: 1 }}>
              1 step left
            </Typography>
          </Stack>

          {scriptIsSetup ? (
            <CheckIcon
              sx={{ color: (theme) => theme.palette.sidebar.calloutText }}
            />
          ) : (
            <Box sx={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress
                variant="determinate"
                value={66}
                size={25}
                sx={{
                  color: (theme) => theme.palette.sidebar.calloutText,
                }}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >
                  {`1`}
                </Typography>
              </Box>
            </Box>
          )}
        </Stack>
      </Box>
    </Paper>
  );
};

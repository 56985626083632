import { useCallback, useMemo } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

import { isAccountSetup } from "./RequireAccountSetup";
// import { NavigateWithSearchParams } from "./AppRoutes";

export const RequireSignIn = ({ exclude = [], children }) => {
  const { user, userAttributes } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  const saveRedirectPath = useCallback(
    (path) => {
      const redirectPath = `${path || location.pathname}${location.search}`;
      localStorage.setItem("Bugpilot::redirectPath", redirectPath);
    },
    [location]
  );

  const isInIframe = useMemo(() => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }, []);

  const canRedirect = useMemo(() => {
    const accountSetup = isAccountSetup(userAttributes);

    if (!accountSetup) {
      // wait for account setup to be completed
      // before redirecting to the original path
      return false;
    }

    return true;
  }, [userAttributes]);

  if (user === null) {
    // Cognito is initializing (see useEffect in AuthContext)
    return children;
  }

  if (user === false) {
    if (exclude.some((path) => location.pathname.startsWith(path))) {
      // some routes might be available for anonymous users as well as
      // for logged in users
      return children;
    }

    // If user is not logged in, redirect to signup
    if (isInIframe) {
      saveRedirectPath();
      return <Navigate to={`/frame/welcome${location.search}`} />;
    }

    saveRedirectPath();
    return <Navigate to={`/signin${location.search}`} />;
  }

  // when everything is OK, render the children
  // or redirect to the original path

  if (canRedirect) {
    if (location.pathname.startsWith("/onboarding")) {
      // onboarding takes care of its own redirects
      return children;
    }

    const redirectPath = localStorage.getItem("Bugpilot::redirectPath");

    if (redirectPath) {
      localStorage.removeItem("Bugpilot::redirectPath");

      setTimeout(() => {
        // for some reason the Navigate component doesn't work
        navigate(redirectPath);
      }, 100);

      return <Navigate to={redirectPath} />;
    }
  }

  return children;
};

import React from "react";

import {
  Typography,
  Button,
  Stack,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";

import {
  OpenInNewOutlined as OpenInNewIcon,
  CheckCircleOutlined as CheckCircleIcon,
} from "@mui/icons-material";

import Link from "../../components/Link";
import CopyToClipboardBase from "../../components/shared/CopyToClipboardBase";
import CopyIcon from "../../icons/CopyIcon";

const MakeIntegration = ({ integration, isDisabled }) => (
  <Stack spacing={1} direction="column">
    <Typography variant="body1" component="p">
      Webhook <b>{integration.name}</b> with id <b>{integration.makeId}</b>
    </Typography>
    <Stack spacing={1} direction="row">
      <Link href={integration.hookUrl}>{integration.hookUrl}</Link>
      <CopyToClipboardBase text={integration.hookUrl}>
        {({ copy, isCopied }) => (
          <Tooltip title="Copy to clipboard" arrow>
            <IconButton size="small" onClick={copy}>
              {isCopied ? (
                <CheckCircleIcon color="success" fontSize="small" />
              ) : (
                <CopyIcon />
              )}
            </IconButton>
          </Tooltip>
        )}
      </CopyToClipboardBase>
    </Stack>
    <Box>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        endIcon={<OpenInNewIcon />}
        onClick={() => {
          const domainName = new URL(integration.hookUrl).hostname.replace(
            "hook.",
            ""
          );
          window.open(
            `https://${domainName}/${integration.teamId}/hooks/${integration.makeId}`,
            "_blank"
          );
        }}
      >
        Manage
      </Button>
    </Box>
  </Stack>
);

export default MakeIntegration;

import { useCallback, useEffect, useMemo } from "react";
import format from "date-fns/format";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmailOutlined";
import PersonRemoveIcon from "@mui/icons-material/PersonRemoveOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAddOutlined";

import { getFieldProps } from "../../utils/getFieldProps";
import Input from "../Input";
import Button from "../Button";
import Modal from "../Modal";
import { useAppContext } from "../../contexts/AppContext";
import Loader from "../Loader";
import { useModals } from "../../hooks/useModals";
import { useAuth } from "../../contexts/AuthContext";

const getValidationSchema = ({ companyMembers, user }) =>
  Yup.object({
    email: Yup.string()
      .email()
      .test(
        "shared",
        "User is already a member of company",
        (value) =>
          !(companyMembers || [])
            .map(({ email }) => email.toLowerCase().trim())
            .includes(value?.trim()?.toLowerCase())
      ),
  });

const CreateCompanyMemberModal = ({ onClose, onConfirm, project }) => {
  const {
    companyMembers,
    fetchCompanyMembers,
    deleteCompanyMember,
    isCompanyMembersLoading,
    selectedCompanyId,
  } = useAppContext();
  const { user } = useAuth();

  const validationSchema = useMemo(
    () => getValidationSchema({ companyMembers, user }),
    [user, companyMembers]
  );

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      await onConfirm(values);
      onClose();
    },
  });

  const modals = useModals();
  useEffect(() => {
    fetchCompanyMembers(selectedCompanyId);
  }, [selectedCompanyId]);

  const handleDeleteCompanyMember = useCallback(
    (email) => async () => {
      await modals.openConfirmation({
        onConfirm: async () =>
          deleteCompanyMember(selectedCompanyId, { email }),
      });
    },
    [deleteCompanyMember, modals, selectedCompanyId]
  );

  return (
    <Modal onClose={onClose} onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={4}
        flexDirection="column"
        flexWrap="nowrap"
        justifyContent="center"
        height="100%"
      >
        <Grid item>
          <Typography variant="h5" textAlign="center">
            Invite team member
          </Typography>
        </Grid>
        <Grid item>
          <Input
            label="Email"
            required
            placeholder="Email"
            {...getFieldProps(formik, {
              name: "email",
            })}
            autoFocus
          />
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                bold
                color="primary"
                type="submit"
                variant="contained"
                startIcon={<PersonAddIcon />}
                endIcon={formik.isSubmitting && <CircularProgress size={25} />}
                disabled={formik.isSubmitting}
              >
                Invite
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {!formik.isSubmitting && (
          <Grid item>
            <Button bold onClick={onClose}>
              Cancel
            </Button>
          </Grid>
        )}
        <Grid
          item
          container
          flexDirection="column"
          overflow="hidden"
          flexBasis={200}
        >
          <Box sx={{ position: "relative" }} overflow="auto" height="100%">
            {/* eslint-disable-next-line no-constant-condition */}
            {isCompanyMembersLoading ? (
              <Loader inline />
            ) : (
              <List
                subheader={<ListSubheader>Team members</ListSubheader>}
                sx={{ bgcolor: "background.paper" }}
              >
                {companyMembers.length === 0 && (
                  <ListItem>
                    <ListItemText primary="You didn't invite any team member yet" />
                  </ListItem>
                )}

                {companyMembers.map(
                  ({ invitedAt, invitedBy, companyId, email }) => (
                    <ListItem
                      key={`${companyId}-${email}`}
                      secondaryAction={
                        (invitedBy.sub === user.sub ||
                          companyId === user.sub) && (
                          <Tooltip title="Remove user from team members" arrow>
                            <IconButton
                              size="small"
                              edge="end"
                              onClick={handleDeleteCompanyMember(email)}
                            >
                              <PersonRemoveIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: "success.main" }}>
                          <AlternateEmailIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={email}
                        secondary={`Invited on ${format(
                          new Date(invitedAt * 1000),
                          "dd.MM.yyyy HH:mm:ss"
                        )} by ${invitedBy.firstName} ${invitedBy.lastName} (${
                          invitedBy.email
                        })`}
                      />
                    </ListItem>
                  )
                )}
              </List>
            )}
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CreateCompanyMemberModal;

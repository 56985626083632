import React from "react";

import { Stack, Tooltip, IconButton } from "@mui/material";

import { CheckCircleOutlined as CheckCircleIcon } from "@mui/icons-material";

import Link from "../../components/Link";
import CopyToClipboardBase from "../../components/shared/CopyToClipboardBase";
import CopyIcon from "../../icons/CopyIcon";

const ZapierIntegration = ({ integration, isDisabled }) => (
  <Stack spacing={1} direction="column">
    <Stack spacing={1} direction="row">
      <Link href={integration.hookUrl}>{integration.hookUrl}</Link>
      <CopyToClipboardBase text={integration.hookUrl}>
        {({ copy, isCopied }) => (
          <Tooltip title="Copy to clipboard" arrow>
            <IconButton size="small" onClick={copy}>
              {isCopied ? (
                <CheckCircleIcon color="success" fontSize="small" />
              ) : (
                <CopyIcon />
              )}
            </IconButton>
          </Tooltip>
        )}
      </CopyToClipboardBase>
    </Stack>
  </Stack>
);

export default ZapierIntegration;

import { useState, useMemo, useCallback, useEffect } from "react";
import debounce from "lodash/debounce";
import keyBy from "lodash/keyBy";

import {
  TextField,
  ListItemButton,
  ListItemText,
  Chip,
  Stack,
  Checkbox,
  Avatar,
} from "@mui/material";

import { useAppContext } from "../contexts/AppContext";
import { useAuth } from "../contexts/AuthContext";
import MemberAvatar from "./MemberAvatar.tsx";
import getAvatar from "../utils/getAvatar";

const TeamMemberSelect = ({ onChange, values, ...props }) => {
  const [members, setMembers] = useState(values);
  const { companyMembers } = useAppContext();
  const { user } = useAuth();

  useEffect(() => {
    setMembers(values);
  }, [values]);

  const companyMembersByEmail = useMemo(
    () => keyBy(companyMembers, "email"),
    [companyMembers]
  );

  const onChangeDebounced = useMemo(() => debounce(onChange, 500), [onChange]);

  const handleChange = useCallback(
    (e, element) => {
      if (element?.props?.value === "all") {
        const newMembers = members.includes("all") ? [] : ["all"];
        setMembers(newMembers);
        onChangeDebounced(newMembers);
        return;
      }

      const newMembers = e.target.value.filter((value) => value !== "all");
      setMembers(newMembers);
      onChangeDebounced(newMembers);
    },
    [members, onChangeDebounced]
  );

  return (
    <TextField
      {...props}
      select
      SelectProps={{
        multiple: true,
        renderValue: (selected) => (
          <Stack spacing={2} direction="row">
            {selected.map((email) => (
              <Chip
                label={email === "all" ? "All team members" : email}
                key={email}
                {...(email !== "all" && {
                  avatar: (
                    <Avatar src={getAvatar(companyMembersByEmail[email])} />
                  ),
                })}
              />
            ))}
          </Stack>
        ),
      }}
      value={members}
      onChange={handleChange}
    >
      {[{ email: "all" }, ...companyMembers].map(({ sub, email }) => (
        <ListItemButton key={email} value={email} button>
          <Checkbox
            checked={members.includes("all") || members.includes(email)}
            sx={{ mr: 0.5 }}
          />
          {email !== "all" && (
            <MemberAvatar
              member={companyMembersByEmail[email]}
              sx={{ mr: 2 }}
            />
          )}
          <ListItemText
            primary={email === "all" ? "All team members" : email}
            secondary={user.sub === sub ? "me" : ""}
          />
        </ListItemButton>
      ))}
    </TextField>
  );
};

export default TeamMemberSelect;

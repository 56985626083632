import { useEffect, useMemo, useRef, useState } from "react";
import ms from "ms";
import { format, formatRelative, formatDistanceToNow, isToday } from "date-fns";
import { withErrorBoundary } from "react-error-boundary";
import { Typography, Tooltip } from "@mui/material";

const formatDate = (
  date,
  { absolute = false, onlyTime = false, withSeconds = false, onlyDate } = {}
) => {
  if (isNaN(+date)) {
    return null;
  }

  const now = Date.now();

  if (onlyTime) {
    if (withSeconds) {
      return format(date, "HH:mm:ss");
    }

    return format(date, "HH:mm");
  }

  if (onlyDate) {
    return format(date, "MMMM do");
  }

  if (absolute) {
    return format(date, "dd MMMM yyyy HH:mm");
  }

  if (+date < now - ms("1w")) {
    return format(date, "dd MMMM yyyy HH:mm");
  }

  if (!isToday(date)) {
    return formatRelative(date, new Date());
  }

  if (now > +date + ms("1h")) {
    return format(date, "HH:mm");
  }

  return formatDistanceToNow(date, { addSuffix: true });
};

const DateTime = ({
  typographyProps,
  children,
  absolute = false,
  onlyTime = false,
  onlyDate = false,
  withSeconds = false,
}) => {
  const timerRef = useRef(null);

  const dateObject = useMemo(
    () => new Date(children / 1e10 > 1 ? children : children * 1000),
    [children]
  );

  const [value, setValue] = useState(
    formatDate(new Date(children / 1e10 > 1 ? children : children * 1000), {
      absolute,
      onlyTime,
      onlyDate,
      withSeconds,
    })
  );

  useEffect(() => {
    if (absolute) {
      return;
    }

    timerRef.current = setInterval(() => {
      setValue(
        formatDate(new Date(children / 1e10 > 1 ? children : children * 1000)),
        { absolute, onlyTime, onlyDate, withSeconds }
      );
    }, 5 * 1000);

    let t = timerRef.current;

    return () => {
      clearInterval(t);
    };
  }, [absolute, children, onlyDate, onlyTime, withSeconds]);

  if (isNaN(+dateObject)) {
    return null;
  }

  if (!children) {
    return null;
  }

  return (
    <Tooltip
      title={dateObject.toLocaleString()}
      arrow
      sx={{
        zIndex: 9999,
      }}
    >
      <Typography
        variant="span"
        color="inherit"
        {...typographyProps}
        sx={{
          ...typographyProps?.sx,
          cursor: "help",
          whiteSpace: "nowrap",

          "&:hover": {
            textDecoration: "underline",
            textDecorationStyle: "dotted",
            textUnderlineOffset: "2px",
          },
        }}
      >
        {value}
      </Typography>
    </Tooltip>
  );
};

const DateTimeWithErrorBoundary = withErrorBoundary(DateTime, {
  fallback: <Typography>Invalid Date</Typography>,
  onError(error, info) {
    // Do something with the error
    // E.g. log to an error logging client here
    console.log("DateTime error boundary", error, info);
  },
});

export default DateTimeWithErrorBoundary;

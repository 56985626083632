import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useCallback } from "react";
import capitalize from "lodash/capitalize";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import FlagIcon from "@mui/icons-material/FlagOutlined";

import { getFieldProps } from "../../utils/getFieldProps";
import { useAppContext } from "../../contexts/AppContext";
import Select from "./IntegrationsPage/Select";
import {
  getClickUpIntegrationLists,
  getClickUpIntegrationWorkspaces,
} from "../../api";

const clickupValidationSchema = Yup.object({
  workspaceId: Yup.string().required(),
  workspaceName: Yup.string().required(),
  listId: Yup.string().required(),
  listName: Yup.string().required(),
  statusId: Yup.string(),
  statusName: Yup.string(),
  priorityId: Yup.string(),
  priorityName: Yup.string(),
});

const ClickUpIntegration = ({
  integration,
  onUpdateIntegration,
  isDisabled,
}) => {
  const { selectedCompanyId } = useAppContext();
  const formik = useFormik({
    initialValues: {
      workspaceId: integration.workspaceId ?? "",
      workspaceName: integration.workspaceName ?? "",
      listId: integration.listId ?? "",
      listName: integration.listName ?? "",
      statusId: integration.statusId ?? "",
      statusName: integration.statusName ?? "",
      priorityId: integration.priorityId ?? "",
      priorityName: integration.priorityName ?? "",
    },
    enableReinitialize: true,
    validationSchema: clickupValidationSchema,
    onSubmit: async (values) => onUpdateIntegration(values),
  });

  const handleWorkspaceChange = useCallback(
    ({ id, name }) => {
      formik.setFieldValue("workspaceId", id, true);
      formik.setFieldValue("workspaceName", name);
      formik.setFieldTouched("workspaceId", true);
    },
    [formik]
  );

  const handleListChange = useCallback(
    ({ id, name }) => {
      formik.setFieldValue("listId", id, true);
      formik.setFieldValue("listName", name);
      formik.setFieldTouched("listId", true);
    },
    [formik]
  );

  const handleStatusChange = useCallback(
    (value) => {
      const { status, name } = value ?? {};
      formik.setFieldValue("statusId", status ?? "", true);
      formik.setFieldValue("statusName", name ?? "");
      formik.setFieldTouched("statusIdId", true);
    },
    [formik]
  );

  const handlePriorityChange = useCallback(
    (value) => {
      const { id, priority } = value ?? {};
      formik.setFieldValue("priorityId", id ?? "", true);
      formik.setFieldValue("priorityName", priority ?? "");
      formik.setFieldTouched("priorityId", true);
    },
    [formik]
  );

  const workspaceFieldProps = getFieldProps(formik, {
    name: "workspaceId",
    errorWhenTouchedOrSubmitted: true,
    disabled: isDisabled,
  });

  const listFieldProps = getFieldProps(formik, {
    name: "listId",
    errorWhenTouchedOrSubmitted: true,
    disabled: isDisabled,
  });

  const statusFieldProps = getFieldProps(formik, {
    name: "statusId",
    errorWhenTouchedOrSubmitted: true,
    disabled: isDisabled,
  });

  const priorityFieldProps = getFieldProps(formik, {
    name: "priorityId",
    errorWhenTouchedOrSubmitted: true,
    disabled: isDisabled,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Select
          label="Workspace"
          fetch={() =>
            getClickUpIntegrationWorkspaces({
              companyId: selectedCompanyId,
              integrationId: integration.type,
            })
          }
          required
          onChange={handleWorkspaceChange}
          error={workspaceFieldProps.error}
          helperText={workspaceFieldProps.helperText}
          value={
            formik.values.workspaceId
              ? {
                  id: formik.values.workspaceId,
                  name: formik.values.workspaceName,
                }
              : null
          }
          renderOption={(props, option) => (
            <Stack direction="row" spacing={2} alignItems="center" {...props}>
              <Avatar
                src={option.avatar}
                sx={{ backgroundColor: option.color }}
              >
                {option.name[0].toUpperCase()}
              </Avatar>
              <Typography variant="body1">{option.name}</Typography>
            </Stack>
          )}
        />
      </Grid>
      {formik.values.workspaceId && (
        <Grid item xs={12}>
          <Select
            label="List"
            fetch={() =>
              getClickUpIntegrationLists({
                companyId: selectedCompanyId,
                integrationId: integration.type,
                workspaceId: formik.values.workspaceId,
              })
            }
            required
            onChange={handleListChange}
            error={listFieldProps.error}
            helperText={listFieldProps.helperText}
            value={
              formik.values.listId
                ? {
                    id: formik.values.listId,
                    name: formik.values.listName,
                  }
                : null
            }
            groupBy={({ space, folder }) =>
              `space "${space.name}"${
                folder?.hidden ? "" : `, folder "${folder.name}"`
              }`
            }
          />
        </Grid>
      )}
      {formik.values.listId && (
        <Grid item xs={12}>
          <Select
            label="Status"
            disableClearable={false}
            fetch={async () => {
              const lists = await getClickUpIntegrationLists({
                companyId: selectedCompanyId,
                integrationId: integration.type,
                workspaceId: formik.values.workspaceId,
              });

              const list = lists.find(({ id }) => id === formik.values.listId);

              return list.space.statuses.map((status) => ({
                ...status,
                id: status.status,
                name: capitalize(status.status),
              }));
            }}
            onChange={handleStatusChange}
            error={statusFieldProps.error}
            helperText={statusFieldProps.helperText}
            value={
              formik.values.statusId
                ? {
                    id: formik.values.statusId,
                    name: formik.values.statusName,
                  }
                : null
            }
            groupBy={({ type }) => type}
            renderOption={(props, { color, status }) => (
              <Stack direction="row" spacing={2} alignItems="center" {...props}>
                <div
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: 2,
                    backgroundColor: color,
                  }}
                />
                <Typography variant="body1">{status}</Typography>
              </Stack>
            )}
          />
        </Grid>
      )}
      {formik.values.listId && (
        <Grid item xs={12}>
          <Select
            label="Priority"
            disableClearable={false}
            fetch={async () => {
              const lists = await getClickUpIntegrationLists({
                companyId: selectedCompanyId,
                integrationId: integration.type,
                workspaceId: formik.values.workspaceId,
              });

              const list = lists.find(({ id }) => id === formik.values.listId);

              return (
                list.space.features?.priorities?.priorities?.map(
                  (priority) => ({
                    ...priority,
                    priority: capitalize(priority.priority),
                  })
                ) ?? []
              );
            }}
            onChange={handlePriorityChange}
            error={priorityFieldProps.error}
            helperText={priorityFieldProps.helperText}
            value={
              formik.values.priorityId
                ? {
                    id: formik.values.priorityId,
                    name: formik.values.priorityName,
                  }
                : null
            }
            groupBy={({ type }) => type}
            renderOption={(props, { color, priority }) => (
              <Stack direction="row" spacing={2} alignItems="center" {...props}>
                <FlagIcon fontSize="small" sx={{ color }} />
                <Typography variant="body1">{priority}</Typography>
              </Stack>
            )}
          />
        </Grid>
      )}
      {formik.dirty && (
        <Grid item>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={formik.handleSubmit}
              endIcon={formik.isSubmitting && <CircularProgress size={25} />}
              disabled={isDisabled || formik.isSubmitting}
            >
              Save
            </Button>
            <Button
              bold
              color="primary"
              disabled={formik.isSubmitting}
              onClick={() => formik.resetForm()}
            >
              Cancel
            </Button>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default ClickUpIntegration;

import { useEffect, useState } from "react";

import { Chip } from "@mui/material";

import { INBOX_SETTINGS } from "../pages/ReportsListPage/config.tsx";
import { isRecommended } from "../pages/ReportsListPage/ReportsTable";
import { useReportsListContext } from "../contexts/ReportsListContext.tsx";
import { useAppContext } from "../contexts/AppContext";

const UnreadsCounter = () => {
  const [unreadsCount, setUnreadsCount] = useState(0);
  const { isCompaniesLoading } = useAppContext();
  const { reports, reportsLoading } = useReportsListContext();

  useEffect(() => {
    if (reportsLoading) {
      return;
    }

    setUnreadsCount(
      reports?.filter(
        (report) =>
          report.bugStatus &&
          !INBOX_SETTINGS.PENDING_REVIEW_FILTER.bugStatusesNot.includes(
            report.bugStatus
          ) &&
          !report.id?.startsWith?.("card") &&
          isRecommended(report)
      ).length
    );
  }, [reports, reportsLoading]);

  return unreadsCount && !isCompaniesLoading ? (
    <Chip
      label={unreadsCount}
      color="error"
      size="small"
      sx={{
        fontSize: "0.75rem",
        height: 20,
        fontWeight: "normal",
        "& .MuiChip-label": {
          padding: "0 6px",
        },
      }}
    />
  ) : null;
};

export default UnreadsCounter;

import { Alert, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { IntegrationCard } from "./IntegrationCard";

export const IntegrationsCategory = ({
  category,
  title,
  deprecated,
  allIntegrations,
  mapIntegrationBoxes,
  filter,
  search,
  IntegrationCardProps,
}) => {
  const integrations = allIntegrations.filter(
    ({ category: cat, featured }) =>
      cat === category ||
      (category === "featured" && featured && !filter && !search)
  );

  if (!integrations.length) {
    return null;
  }

  return (
    <Grid item xs={12} key={`cat-${category}`}>
      <Typography variant="h4" mb={2}>
        {title}
      </Typography>

      {deprecated ? (
        <Alert severity="warning" sx={{ mb: 2 }}>
          <span style={{ fontWeight: "bold" }}>Deprecated:</span> {deprecated}
        </Alert>
      ) : null}

      <Box>
        <Grid container spacing={4}>
          {integrations.map((integration) => {
            return (
              <IntegrationCard
                key={integration.type}
                {...integration}
                {...IntegrationCardProps}
              />
            );
          })}
        </Grid>
      </Box>
    </Grid>
  );
};

import ClickUpIcon from "../../icons/logos/ClickUpIcon";
import JiraIcon from "../../icons/logos/JiraIcon";
import NotionIcon from "../../icons/logos/NotionIcon";
import GitHubIcon from "../../icons/logos/GitHubIcon";
import VercelIcon from "../../icons/logos/VercelIcon";

const integrations = {
  clickup: {
    icon: <ClickUpIcon fontSize="small" />,
    text: "View in ClickUp",
  },
  atlassian: {
    icon: <JiraIcon fontSize="small" />,
    text: "View in Jira",
  },
  notion: {
    icon: <NotionIcon fontSize="small" />,
    text: "View in Notion",
  },
  github: {
    icon: <GitHubIcon fontSize="small" />,
    text: "View in GitHub",
  },
  vercel: {
    icon: <VercelIcon fontSize="small" />,
    text: "View deployment in Vercel",
  },
};

const getIntegrationsLinks = ({ report }) => {
  if (!report.integrations) {
    return [];
  }

  return Object.entries(report.integrations)
    .filter(
      ([integrationType, { url }]) => url && integrations[integrationType]
    )
    .map(([integrationType, { url }]) => ({
      icon: integrations[integrationType].icon,
      label: integrations[integrationType].text,
      onClick: () => window.open(url, "_blank"),
    }));
};

export default getIntegrationsLinks;

import {
  Alert,
  AlertTitle,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import Editor from "./Activities/Editor";
import stateToMarkdown from "draft-js-export-markdown/lib/stateToMarkdown";
import { markdownToDraft } from "markdown-draft-js";
import { CheckOutlined as CheckIcon } from "@mui/icons-material";

const NotesPanel = ({ report, setReport }) => {
  const { updateReport } = useAppContext();

  const initialDescription = useMemo(() => report.description || "", [report]);

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);

  const handleSave = useCallback(
    async (description) => {
      // If the description is the same as the initial one, do not save
      if (description.trim() === initialDescription.trim()) {
        return;
      }

      setSaving(true);
      setError(false);

      try {
        await updateReport(
          report,
          {
            description,
          },
          setReport
        );

        setError(false);
        setSaving(false);
        setSaved(true);
      } catch (e) {
        console.error("Cannot save description", e);
        setError("Could not save description: " + e.message);
        setSaving(false);
        setSaved(false);
      }
    },
    [initialDescription, updateReport, report, setReport]
  );

  const notesRef = useRef(null);

  const handleNotesSave = useCallback(
    () =>
      handleSave(
        stateToMarkdown(notesRef.current.editorState.getCurrentContent(), {
          gfm: true,
        })
      ),
    [handleSave]
  );

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Stack spacing={1}>
        <Box>
          <Alert severity="error" sx={{ m: 2 }}>
            <AlertTitle>Deprecation notice</AlertTitle>
            This feature is deprecated and will be removed on October, 1st.
            Please use the Discussion tab instead.
          </Alert>

          {/*<MarkdownEditor*/}
          {/*  key={report.id}*/}
          {/*  value={initialDescription}*/}
          {/*  onChange={(e) => setDescription(e.target.value)}*/}
          {/*  onBlur={handleSave}*/}
          {/*  placeholder="Add your notes here..."*/}
          {/*/>*/}
          <Editor
            placeholder="Add your notes here..."
            ref={notesRef}
            rawContentState={JSON.stringify(
              markdownToDraft(initialDescription)
            )}
            onSave={handleNotesSave}
            onBlur={handleNotesSave}
            reportId={report.id}
            enableFileUpload={false}
            classes={{
              container: "no-min-height",
            }}
          />
        </Box>

        {error && (
          <Typography color="error" variant="body1">
            {error}
          </Typography>
        )}
      </Stack>

      {(saving || saved) && (
        <Box
          sx={{
            position: "absolute",
            bottom: 16,
            right: 24,
          }}
        >
          {saving ? (
            <CircularProgress size={16} color="primary" />
          ) : (
            <Typography
              color="success.main"
              variant="body1"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <CheckIcon color="inherit" fontSize="small" />
              <span>Saved</span>
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default NotesPanel;

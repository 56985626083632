import React from "react";
import { Typography } from "@mui/material";
import { useIntegrations } from "../../contexts/IntegrationsContext";

export const CrispDescription = () => {
  const { crispWebsiteId } = useIntegrations();

  if (!crispWebsiteId) {
    return null;
  }

  return (
    <Typography variant="caption" component="p">
      website <code>{crispWebsiteId}</code>
    </Typography>
  );
};

import { useState } from "react";
import Button from "@mui/material/Button";
import {
  ArrowBackOutlined as ArrowBackIcon,
  ArrowCircleLeftOutlined as ArrowCircleLeftIcon,
  ArrowCircleRightOutlined as ArrowCircleRightIcon,
  ArrowForwardOutlined as ArrowForwardIcon,
  KeyboardAltOutlined as KeyboardAltOutlinedIcon,
} from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useReport } from "../../contexts/ReportContext.tsx";

export const ExplainPreviewKeyboardShortcuts = () => {
  const [open, setOpen] = useState(false);

  const { hasHistory, closeReport, nextReport, prevReport } = useReport();

  return (
    <>
      <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
        <Button
          variant="outlined"
          size="small"
          color="muted"
          sx={{ textTransform: "none", color: "muted.dark" }}
          onClick={closeReport}
        >
          ESC
        </Button>

        {hasHistory && (
          <>
            <Button
              variant="text"
              size="small"
              color="muted"
              sx={{ textTransform: "none", color: "muted.dark" }}
              onClick={prevReport}
              startIcon={<ArrowBackIcon fontSize="small" />}
            >
              Newer
            </Button>

            <Button
              variant="text"
              size="small"
              color="muted"
              sx={{ textTransform: "none", color: "muted.dark" }}
              onClick={nextReport}
              endIcon={<ArrowForwardIcon fontSize="small" />}
            >
              Older
            </Button>
          </>
        )}

        <IconButton
          onClick={() => setOpen(true)}
          variant="text"
          size="small"
          color="muted"
          sx={{
            textTransform: "none",
            color: "muted.dark",
          }}
        >
          <KeyboardAltOutlinedIcon fontSize="small" />
        </IconButton>
      </Stack>

      <Dialog
        open={open}
        onBackdropClick={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Keyboard shortcuts</DialogTitle>

        <DialogContent>
          <DialogContentText>
            <TableContainer
              sx={{
                // align center the first cell of each row
                "& td:first-child, & th:first-child, ": {
                  textAlign: "center",
                },
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Key</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <ArrowCircleRightIcon />
                    </TableCell>
                    <TableCell>Go to the next report</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>d</TableCell>
                    <TableCell>Delete report</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <ArrowCircleLeftIcon />
                    </TableCell>
                    <TableCell>Go to the previous report</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Esc</TableCell>
                    <TableCell>Close report and return to list</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Shift + o</TableCell>
                    <TableCell>Open a report in a new tab</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>1</TableCell>
                    <TableCell>Mark as correct behavior</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>2</TableCell>
                    <TableCell>Mark as confirmed bug</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>3</TableCell>
                    <TableCell>Mark as in progress</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>4</TableCell>
                    <TableCell>Mark as fixed</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

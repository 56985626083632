import React, { useCallback, useContext, useEffect, useState } from "react";
import noop from "lodash/noop";

import EditModal from "../components/modals/EditModal";
import ConfirmModal from "../components/modals/ConfirmModal";
import ShareModal from "../components/modals/ShareModal";
import PaymentFailedModal from "../components/modals/PaymentFailedModal";
import PaymentSuccessModal from "../components/modals/PaymentSuccessModal";
import ChangePasswordModal from "../components/modals/ChangePasswordModal";
import DataProcessingAddendumModal from "../components/modals/DataProcessingAddendumModal";
import TermsAndConditionsModal from "../components/modals/TermsAndConditionsModal";
import PrivacyPolicyModal from "../components/modals/PrivacyPolicyModal";
import CreateCompanyMemberModal from "../components/modals/CreateCompanyMemberModal";
import EnableMFAModal from "../components/modals/EnableMFAModal";
import DisableMFAModal from "../components/modals/DisableMFAModal";
import HelpScoutModal from "../components/modals/HelpScoutModal";
import ChangeEmailModal from "../components/modals/ChangeEmailModal";
import ReporterNotificationModal from "../components/modals/ReporterNotificationModal";
import CreateCompanyModal from "../components/modals/CreateCompanyModal";
import ChangePlanModal from "../components/modals/ChangePlanModal";

const modalTypes = {
  Confirm: "Confirm",
  Edit: "Edit",
  Share: "Share",
  Subscription: "Subscription",
  PaymentFailed: "PaymentFailed",
  PaymentSuccess: "PaymentSuccess",
  ChangePassword: "ChangePassword",
  DataProcessingAddendum: "DataProcessingAddendum",
  TermsAndConditions: "TermsAndConditions",
  PrivacyPolicy: "PrivacyPolicy",
  CreateCompanyMember: "CreateCompanyMember",
  EnableMFA: "EnableMFA",
  DisableMFA: "DisableMFA",
  HelpScout: "HelpScout",
  ChangeEmail: "ChangeEmail",
  ReporterNotification: "ReporterNotification",
  CreateCompany: "CreateCompany",
  ChangePlan: "ChangePlan",
};

const modals = {
  [modalTypes.Confirm]: ConfirmModal,
  [modalTypes.Edit]: EditModal,
  [modalTypes.Share]: ShareModal,
  [modalTypes.PaymentFailed]: PaymentFailedModal,
  [modalTypes.PaymentSuccess]: PaymentSuccessModal,
  [modalTypes.ChangePassword]: ChangePasswordModal,
  [modalTypes.DataProcessingAddendum]: DataProcessingAddendumModal,
  [modalTypes.TermsAndConditions]: TermsAndConditionsModal,
  [modalTypes.PrivacyPolicy]: PrivacyPolicyModal,
  [modalTypes.CreateCompanyMember]: CreateCompanyMemberModal,
  [modalTypes.EnableMFA]: EnableMFAModal,
  [modalTypes.DisableMFA]: DisableMFAModal,
  [modalTypes.HelpScout]: HelpScoutModal,
  [modalTypes.ChangeEmail]: ChangeEmailModal,
  [modalTypes.ReporterNotification]: ReporterNotificationModal,
  [modalTypes.CreateCompany]: CreateCompanyModal,
  [modalTypes.ChangePlan]: ChangePlanModal,
};

const ModalsContext = React.createContext({
  closeModal: noop,
  modalTypes: modalTypes,
  openModal: noop,
  openModal2: noop,
  openConfirmation: noop,
});

const useModals = () => useContext(ModalsContext);

const ModalsProvider = ({ children }) => {
  const [modal, setModal] = useState(null);
  const [modalProps, setModalProps] = useState(null);
  const [modal2, setModal2] = useState(null);
  const [modal2Props, setModal2Props] = useState(null);

  const handleOpen = useCallback((modalToOpen, props) => {
    if (!modals[modalToOpen]) {
      console.error(`Modal ${modalToOpen} is not found`);
      return;
    }

    setModal(modalToOpen);
    setModalProps(props);
  }, []);

  const handleOpen2 = useCallback((modalToOpen, props) => {
    if (!modals[modalToOpen]) {
      console.error(`Modal ${modalToOpen} is not found`);
      return;
    }

    setModal2(modalToOpen);
    setModal2Props(props);
  }, []);

  const handleClose = useCallback(() => {
    if (typeof modalProps?.onClose === "function") {
      modalProps.onClose();
    }

    setModal(null);
  }, [modalProps]);

  const handleClose2 = useCallback(() => {
    if (typeof modal2Props?.onClose === "function") {
      modal2Props.onClose();
    }

    setModal2(null);
  }, [modal2Props]);

  const handleOpenConfirmation = useCallback(
    ({ title, confirmCTATitle, closeCTATitle, ...props } = {}) => {
      setModal2(modalTypes.Confirm);
      setModal2Props({
        closeCTATitle,
        confirmCTATitle,
        title,
        ...props,
      });
    },
    []
  );

  const Modal = modals[modal];
  const Modal2 = modals[modal2];

  useEffect(() => {
    const listener = () => {
      setModal(null);
      setModalProps(null);
      setModal2(null);
      setModal2Props(null);
    };

    window.addEventListener("popstate", listener);

    return () => {
      window.removeEventListener("popstate", listener);
    };
  }, []);

  return (
    <ModalsContext.Provider
      value={{
        closeModal: handleClose,
        modalTypes,
        openModal: handleOpen,
        openModal2: handleOpen2,
        openConfirmation: handleOpenConfirmation,
      }}
    >
      {Modal && <Modal {...modalProps} onClose={handleClose} />}
      {Modal2 && <Modal2 {...modal2Props} onClose={handleClose2} />}

      {children}
    </ModalsContext.Provider>
  );
};

export { useModals, ModalsProvider };

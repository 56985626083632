import React from "react";
import { Button } from "@mui/material";
import { BuildOutlined as BuildIcon } from "@mui/icons-material";

export const SourceMapsDescription = ({ handleOpenToggle }) => (
  <Button
    onClick={handleOpenToggle}
    variant="outlined"
    color="secondary"
    size="small"
    startIcon={<BuildIcon />}
  >
    Settings
  </Button>
);

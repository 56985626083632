import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Box,
  Dialog,
  InputAdornment,
  TextField,
  IconButton,
} from "@mui/material";
import {
  SearchOutlined as SearchIcon,
  ClearOutlined as ClearIcon,
} from "@mui/icons-material";

import { SearchPage } from "../../pages/SearchPage/SearchPage.tsx";
import { useGlobalHotKeys } from "../../router/GlobalHotKeysContext.tsx";

type SearchBoxSmallProps = {
  onClick: () => void;
  showIcon?: boolean;
} & Omit<React.ComponentProps<typeof TextField>, "onChange"> & {
    onChange?: (e: { target: { value: string } }) => void;
  };

export const SearchBoxSmall = ({
  onClick,
  showIcon = true,
  ...props
}: SearchBoxSmallProps): JSX.Element => {
  return (
    <TextField
      placeholder="Search"
      variant="outlined"
      size="small"
      fullWidth
      // search icon
      InputProps={{
        endAdornment: showIcon ? (
          <InputAdornment position="end">
            {props.value && (
              <IconButton
                size="small"
                onClick={() => props.onChange?.({ target: { value: "" } })}
              >
                <ClearIcon />
              </IconButton>
            )}
            <SearchIcon />
          </InputAdornment>
        ) : undefined,
      }}
      value=""
      onClick={onClick}
      {...props}
      onKeyDown={(e) => {
        if (e.key !== "Escape") {
          return;
        }

        props.onChange?.({ target: { value: "" } });
      }}
    />
  );
};

type SearchDialogProps = {
  onClose: () => void;
};

const SearchDialog = ({ onClose }: SearchDialogProps): JSX.Element => {
  return (
    <Dialog open={true} fullWidth maxWidth="md" onClose={onClose}>
      <Box
        p={4}
        sx={{
          height: 600,
          overflow: "hidden",
        }}
      >
        <SearchPage onResultClick={onClose} />
      </Box>
    </Dialog>
  );
};

export const SearchBox = (props): JSX.Element => {
  const [searchParams] = useSearchParams();
  const [expanded, setExpanded] = useState(false);
  const { register, unregister } = useGlobalHotKeys();

  const handleBoxClick = useCallback(() => {
    setExpanded(true);
  }, []);

  const handleClose = useCallback(() => {
    setExpanded(false);
  }, []);

  useEffect(() => {
    register("f", handleBoxClick);

    return () => {
      unregister("f");
    };
  }, [handleBoxClick, register, unregister]);

  // if there is an email param in the url, open search
  useEffect(() => {
    const email = searchParams.get("email");
    if (email) {
      setExpanded(true);
    }
  }, [searchParams]);

  return (
    <>
      {expanded && <SearchDialog onClose={handleClose} />}
      <SearchBoxSmall onClick={handleBoxClick} {...props} />
    </>
  );
};

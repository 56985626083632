import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Link,
  Divider,
} from "@mui/material";
import { useAppContext } from "../../contexts/AppContext";
import { useCallback, useMemo, useRef } from "react";
import { SettingsSection } from "../Settings/SettingsSection.tsx";
import { useSnackbar } from "notistack";
import { createPortal } from "react-dom";
import useHasRole from "../../hooks/useHasRole.js";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import ButtonDialog from "../../components/ButtonDialog.js";

export const WidgetSetupPage = () => {
  const { updateCompany, selectedCompanyId, currentWorkspace } =
    useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = useCallback(
    (changes: any) => {
      updateCompany(selectedCompanyId, changes)
        //@ts-ignore-next-line
        .then(() => {
          enqueueSnackbar(
            "Settings saved. Changes might take up to 5 minutes to become visible.",
            { variant: "success" }
          );
        })
        .catch((e) => {
          console.error("Cannot save widget settings", e);
          enqueueSnackbar("Failed to save settings, please retry", {
            variant: "error",
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateCompany, selectedCompanyId]
  );
  const { hasRole: isAdminOrOwner } = useHasRole("admin");
  const isDisabled = useMemo(() => !isAdminOrOwner, [isAdminOrOwner]);

  const topBarBreadcrumbsElement = document.getElementById(
    "top-bar-breadcrumbs"
  );

  const userWidgetPosition =
    currentWorkspace?.userWidgetPosition || "center-right";
  // const showWidgetBranding = currentWorkspace?.showWidgetBranding ?? true;
  const idleText = currentWorkspace?.widgetSettings?.idleText || "Report issue";
  const customCSS = currentWorkspace?.widgetSettings?.customCSS || "";
  const elementSelector =
    currentWorkspace?.widgetSettings?.elementSelector || null;
  const primaryColor =
    currentWorkspace?.widgetSettings?.colors?.primary || "#1F1E1F";
  const secondaryColor =
    currentWorkspace?.widgetSettings?.colors?.secondary || "#F2F2F5";

  // create a ref for each of these
  const widgetPositionRef = useRef(null);
  // const showWidgetBrandingRef = useRef(showWidgetBranding);
  const idleTextRef = useRef(null);
  const customCSSRef = useRef(null);
  const elementSelectorRef = useRef(null);
  const primaryColorRef = useRef(null);
  const secondaryColorRef = useRef(null);

  return (
    <Box
      maxWidth="md"
      sx={{
        width: "100%",
      }}
    >
      <Stack spacing={4}>
        {topBarBreadcrumbsElement &&
          createPortal(
            <Stack direction="row" alignItems="center">
              <ButtonDialog
                buttonLabel="See an example"
                buttonProps={{
                  variant: "outlined",
                  color: "tertiary",
                  startIcon: <RemoveRedEyeOutlined />,
                  sx: {
                    height: 37,
                    fontSize: "0.875em",
                  },
                }}
                title="Widget example"
                dialogProps={{
                  sx: { width: "100%" },
                  maxWidth: "lg",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    aspectRatio: "16/9",
                  }}
                >
                  <iframe
                    title="Video example of the bug reporting widget"
                    src="https://customer-owrhb44srun41lbh.cloudflarestream.com/d2fcf575a2c307e15a8c0fdda0619dfa/iframe?letterboxColor=transparent&muted=true&preload=true&poster=https%3A%2F%2Fcustomer-owrhb44srun41lbh.cloudflarestream.com%2Fd2fcf575a2c307e15a8c0fdda0619dfa%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D1200"
                    style={{
                      border: "none",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100%",
                      width: "100%",
                    }}
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowFullScreen
                  ></iframe>
                </div>
              </ButtonDialog>
            </Stack>,
            topBarBreadcrumbsElement
          )}

        {/* Section 1: Widget Enable / Disable */}

        <SettingsSection
          id="widget-setup"
          title="Bug reporting widget"
          description={
            <span>
              Show a bug reporting widget on all pages where the tracking code
              is embeded. The Bug reporting widget allows your users to report
              bugs, submit screen recordings, console logs, and more, in just
              one click. The reports generated with this button will be
              delivered in New Issues &gt; Recommended.
            </span>
          }
          leftButtons={
            <span>
              Choose disabled to disable the widget globally, regardless of the{" "}
              <code>enabled</code> context prop.
            </span>
          }
        >
          <FormControlLabel
            control={
              <Switch
                disabled={isDisabled}
                defaultChecked
                checked={currentWorkspace.enableUserWidgetV2 ?? false}
                color="success"
              />
            }
            label={
              <span style={{ fontWeight: 600 }}>
                {currentWorkspace.enableUserWidgetV2 ? "Enabled" : "Disabled"}
              </span>
            }
            onChange={(e, enabled) =>
              handleChange({ enableUserWidgetV2: enabled })
            }
          />
        </SettingsSection>

        {/* Section 1b: Require Description */}
        <SettingsSection
          id="widget-require-description"
          title="Require bug description"
          description={
            <span>
              Require users to provide a description when reporting a bug. This
              will help your team understand some details that are not captured
              by the screen recording, or console logs.
            </span>
          }
          leftButtons={
            <span>
              If optional, the user can submit bug reports without filling the
              description field.
            </span>
          }
        >
          <FormControlLabel
            control={
              <Switch
                disabled={isDisabled}
                defaultChecked
                checked={
                  currentWorkspace.widgetSettings?.requireDescription ?? true
                }
                color="primary"
              />
            }
            label={
              <span>
                {currentWorkspace.widgetSettings?.requireDescription ?? true
                  ? "Required"
                  : "Optional"}
              </span>
            }
            onChange={(e, enabled) =>
              handleChange({
                widgetSettings: {
                  ...(currentWorkspace.widgetSettings || {}),
                  requireDescription: enabled,
                },
              })
            }
          />
        </SettingsSection>

        {/* Section 1c: Require Recording */}
        <SettingsSection
          id="widget-require-recording"
          title="Require screen recording"
          description={
            <span>
              Require users to record the page when reporting a bug. We
              recommend enabling this option, as it will help your team
              understand the context of the bug, and will also reduce the number
              of false positives.
            </span>
          }
          leftButtons={
            <span>
              If optional, the user can skip the screen recording step.
            </span>
          }
        >
          <FormControlLabel
            control={
              <Switch
                disabled={isDisabled}
                defaultChecked
                checked={
                  currentWorkspace.widgetSettings?.requireRecording ?? true
                }
                color="primary"
              />
            }
            label={
              <span>
                {currentWorkspace.widgetSettings?.requireRecording ?? true
                  ? "Required"
                  : "Optional"}
              </span>
            }
            onChange={(e, enabled) =>
              handleChange({
                widgetSettings: {
                  ...(currentWorkspace.widgetSettings || {}),
                  requireRecording: enabled,
                },
              })
            }
          />
        </SettingsSection>

        {/* Section 2: Anchor Element  */}

        <SettingsSection
          id="widget-setup"
          title="Anchor element"
          description={
            <span>
              You can chose your users will be able to start the bug reporting
              flow. By default, the Bugpilot &laquo;Report an issue&raquo;
              ribbon is shown on the right side of the screen. If you prefer
              full control over styling and appearance of the widget, you can
              specify a CSS selector of any HTML element. We will start the bug
              reporting reporting flow when users click on it.
            </span>
          }
          leftButtons={null}
          buttons={
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleChange({
                    widgetSettings: {
                      elementSelector:
                        elementSelectorRef.current?.value === "no"
                          ? null
                          : "#bug-report-button",
                    },
                  });
                }}
              >
                Save
              </Button>
            </>
          }
        >
          <Stack direction="row" spacing={2}>
            <Select
              defaultValue={elementSelector === null ? "no" : "yes"}
              disabled={isDisabled}
              inputProps={{
                ref: elementSelectorRef,
              }}
              onChange={(e) => {
                handleChange({
                  widgetSettings: {
                    elementSelector:
                      e.target.value === "no" ? null : "#bug-report-button",
                  },
                });
              }}
            >
              <MenuItem value={"no"}>Default ribbon (customize below)</MenuItem>
              <MenuItem value={"yes"}>Custom CSS selector...</MenuItem>
            </Select>

            {elementSelector ? (
              <FormControl>
                <TextField
                  label="CSS Selector"
                  defaultValue={elementSelector}
                  error={elementSelector === ""}
                  disabled={isDisabled}
                  placeholder="e.g., #bug-report-button"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            ) : null}
          </Stack>
        </SettingsSection>

        {/* Section 3: Default Ribbon Customization */}

        <SettingsSection
          id="widget-setup"
          title="Default Ribbon Appearance"
          description={
            <span>
              You can customize how the default bugpilot widget will be
              displayed on your app. By default, the Bugpilot &laquo;Report an
              issue&raquo; ribbon is shown on the right side of the screen.
            </span>
          }
          leftButtons={null}
          buttons={
            <>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  isDisabled ||
                  elementSelector ||
                  !widgetPositionRef.current?.value ||
                  !idleTextRef.current?.value
                }
                onClick={() => {
                  handleChange({
                    userWidgetPosition:
                      widgetPositionRef.current?.value || "center",
                    widgetSettings: {
                      idleText: idleTextRef.current?.value || "Report issue",
                      colors: {
                        primary: primaryColorRef.current?.value || primaryColor,
                        secondary:
                          secondaryColorRef.current?.value || secondaryColor,
                      },
                    },
                  });
                }}
              >
                Save
              </Button>
            </>
          }
        >
          <Box component="form">
            <Stack spacing={2}>
              {/* Position */}
              <FormControl>
                <InputLabel id="widget-position-position">
                  Widget position
                </InputLabel>

                <Select
                  label="Ribbon placement"
                  labelId="widget-position-position"
                  defaultValue={userWidgetPosition || "center-right"}
                  disabled={isDisabled || elementSelector}
                  inputRef={widgetPositionRef}
                >
                  <MenuItem value="right">Default (Right)</MenuItem>
                  <Divider />

                  <MenuItem value="center-right">Right</MenuItem>
                  <MenuItem value="bottom">Bottom</MenuItem>
                  <MenuItem value="left">Left</MenuItem>
                  <MenuItem value="top">Top</MenuItem>
                </Select>
              </FormControl>

              {/* Label */}
              <FormControl>
                <TextField
                  label="Ribbon text"
                  defaultValue={idleText || "Report issue"}
                  disabled={isDisabled || elementSelector}
                  inputRef={idleTextRef}
                />
              </FormControl>
            </Stack>
          </Box>
        </SettingsSection>

        <SettingsSection
          id="widget-setup"
          title="Brand Colors"
          description={
            <span>
              You can customize the appearance of the widget by changing the
              primary and secondary colors. This is useful if you want to
              integrate the widget with your brand.
            </span>
          }
          leftButtons={null}
          buttons={
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleChange({
                    widgetSettings: {
                      idleText: idleTextRef.current?.value || idleText,
                      colors: {
                        primary: primaryColorRef.current?.value || "#1F1E1F",
                        secondary:
                          secondaryColorRef.current?.value || "#F2F2F5",
                      },
                    },
                  });
                }}
              >
                Save
              </Button>
            </>
          }
        >
          <Box component="form">
            {/* Color */}
            <Stack spacing={2} direction="row">
              <TextField
                type="color"
                label="Primary color"
                sx={{ width: 120 }}
                disabled={isDisabled}
                defaultValue={primaryColor}
                inputRef={primaryColorRef}
              />

              <TextField
                type="color"
                label="Secondary color"
                sx={{ width: 120 }}
                disabled={isDisabled}
                defaultValue={secondaryColor}
                inputRef={secondaryColorRef}
              />
            </Stack>
          </Box>
        </SettingsSection>

        <SettingsSection
          id="widget-setup"
          title="Custom CSS Rules"
          description={
            <span>
              You can customize the appearance of the widget by adding custom
              CSS rules. This is useful if you want to change the color of the
              ribbon or the font of the text.
            </span>
          }
          leftButtons={
            <>
              <span>
                Read more and find CSS snippets in our{" "}
                <Link
                  href="https://bugpilot.notion.site/Customize-the-Bug-Reporting-Widget-ca822375fc7641ad8db971ccb1b4f9b9"
                  target="_blank"
                  sx={{
                    textDecoration: "underline",
                    textUnderlineOffset: "2px",
                  }}
                >
                  documentation
                </Link>
                .
              </span>
            </>
          }
          buttons={
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleChange({
                    widgetSettings: {
                      customCSS: customCSSRef.current?.value,
                    },
                  });
                }}
              >
                Save
              </Button>
            </>
          }
        >
          <Box component="form">
            <TextField
              fullWidth
              label="Custom CSS"
              multiline
              defaultValue={customCSS}
              disabled={isDisabled}
              minRows={4}
              inputRef={customCSSRef}
              sx={{
                "& .MuiOutlinedInput-input": {
                  fontFamily: "monospace",
                  whiteSpace: "pre",
                },
              }}
            />
          </Box>
        </SettingsSection>
      </Stack>
    </Box>
  );
};

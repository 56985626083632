import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, Tabs, Box } from "@mui/material";

import { useAppContext } from "../../contexts/AppContext";
import useHasRole from "../../hooks/useHasRole";
import { useAuth } from "../../contexts/AuthContext";
import { createPortal } from "react-dom";

const getTabs = (company) => [
  {
    label: "General",
    path: "/settings/customize",
  },
  {
    label: "Tracking Code",
    path: "/settings/code",
  },
  {
    label: "Privacy",
    path: "/settings/data-and-security",
  },
  ...(company.subscriptionStatus
    ? [
        {
          label: "Billing",
          path: "/settings/billing",
        },
      ]
    : []),
];

const SettingsPageFrame = ({ company, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = useMemo(() => getTabs(company), [company]);

  const [tabIndex, _setTabIndex] = useState(
    // find tab index based on location
    tabs.findIndex(({ path }) => path === location.pathname)
  );
  const setTabIndex = (e, nextValue) => {
    _setTabIndex(nextValue);
  };

  useEffect(() => {
    if (location.pathname !== "/settings/billing") {
      return;
    }

    if (company && !company.subscriptionStatus) {
      navigate("/settings");
    }
  }, [location.pathname]);

  const topBarBreadcrumbsElement = document.getElementById(
    "top-bar-breadcrumbs"
  );

  return (
    <Box maxWidth="md">
      {topBarBreadcrumbsElement &&
        createPortal(
          <Box maxWidth="md" sx={{ width: "100%" }}>
            <Tabs
              value={tabIndex}
              onChange={setTabIndex}
              allowScrollButtonsMobile
              variant="standard"
            >
              {tabs.map(({ label, path }, index) => (
                <Tab key={index} label={label} onClick={() => navigate(path)} />
              ))}
            </Tabs>
          </Box>,
          topBarBreadcrumbsElement
        )}

      <Box>{children}</Box>
    </Box>
  );
};

const SettingsPageFrameContainer = ({ children }) => {
  const { companies, updateCompany, selectedCompanyId } = useAppContext();
  const { user } = useAuth();
  const location = useLocation();
  const { hasRole: isAdminOrOwner } = useHasRole("admin");

  const [activeTab, setActiveTab] = useState(
    location.pathname.split("/")[2]?.toLowerCase()
  );

  const handleCompanyChange = useCallback(
    (changes) => {
      return updateCompany(selectedCompanyId, changes);
    },
    [updateCompany, selectedCompanyId]
  );

  useEffect(() => {
    setActiveTab(location.pathname.split("/")[2]?.toLowerCase());
  }, [location.pathname]);

  return (
    <SettingsPageFrame
      company={companies.find(({ id }) => id === selectedCompanyId)}
      user={user}
      onCompanyChange={handleCompanyChange}
      activeTab={activeTab}
      isAdminOrOwner={isAdminOrOwner}
    >
      {children}
    </SettingsPageFrame>
  );
};

export default SettingsPageFrameContainer;

import { compose, orderBy as fpOrderBy, uniqBy as fpUniqBy } from "lodash/fp";

const withOperation = ({
  setState,
  primaryColumns = ["id"],
  sortColumns = ["createdAt"],
  sortDirections = ["desc"],
  operation,
  data,
}) => {
  if (operation === "create") {
    setState((state) =>
      compose(
        fpOrderBy(sortColumns, sortDirections),
        fpUniqBy((item) =>
          primaryColumns.map((column) => item[column]).join("-")
        )
      )([...state, data])
    );
    return;
  }

  if (operation === "update") {
    setState((state) => {
      const index = state.findIndex((item) =>
        primaryColumns.every((column) => item[column] === data[column])
      );

      if (!~index) {
        return compose(
          fpOrderBy(sortColumns, sortDirections),
          fpUniqBy((item) =>
            primaryColumns.map((column) => item[column]).join("-")
          )
        )([...state, data]);
      }

      const nextState = [...state];
      nextState.splice(index, 1, data);
      return nextState;
    });
    return;
  }

  if (operation === "delete") {
    setState((state) =>
      state.filter((item) =>
        primaryColumns.some((column) => item[column] !== data[column])
      )
    );
  }
};

export default withOperation;

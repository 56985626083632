const useHelpdesk = () => {
  // Connection methods
  //

  const connectIntercom = async ({ appId, workspaceId }) => {
    const response = await fetch(
      `https://bugpilot-intercom.adoptoapp.workers.dev/connect`,
      {
        method: "POST",
        body: JSON.stringify({
          intercomAppId: appId,
          workspaceId,
        }),
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "66ab739d-8c36-4468-8d68-81ac88b5eb52",
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => ({ ok: false, error: err.message }));

    if (!response.ok) {
      console.warn("Intercom connect response", response);

      if (typeof response.error === "string") {
        throw new Error("Failed to connect to Intercom: " + response.error);
      }
      throw new Error("Failed to connect to Intercom");
    }

    return { appId };
  };

  const connectLiveChat = async ({ appId, workspaceId }) => {
    const response = await fetch(`https://api.bugpilot.io/livechat/connect`, {
      method: "POST",
      body: JSON.stringify({
        organizationId: appId,
        workspaceId,
      }),
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "4d0aeece-7d73-43d2-a1fd-d7b2cb339b04",
      },
    })
      .then((res) => res.json())
      .catch((err) => ({ ok: false, error: err }));

    if (!response.ok || response.error) {
      console.warn("LiveChat connect response", response);

      if (typeof response.error === "string") {
        throw new Error("Failed to connect to LiveChat: " + response.error);
      }
      throw new Error("Failed to connect to LiveChat");
    }

    return {
      organizationId: response.organizationId,
    };
  };

  const connectCrisp = async ({ appId, workspaceId }) => {
    const response = await fetch(`https://api.bugpilot.io/crisp/connect`, {
      method: "POST",
      body: JSON.stringify({
        websiteId: appId,
        workspaceId,
      }),
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "ab3d9812-3108-407f-92f9-0bb882ca4e88",
      },
    })
      .then((res) => res.json())
      .catch((err) => ({ ok: false, error: err }));

    if (response.error) {
      console.warn("Crisp connect response", response);

      if (typeof response.error === "string") {
        throw new Error(`Failed to connect to Crisp: ` + response.error);
      }
      throw new Error("Failed to connect to Crisp");
    }

    return {
      websiteId: response.websiteId,
    };
  };

  const connectZendesk = async ({ subdomain, workspaceId }) => {
    const response = await fetch(`https://api.bugpilot.io/zendesk/connect`, {
      method: "POST",
      body: JSON.stringify({
        subdomain,
        workspaceId,
      }),
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "bc60cb6c-4730-440d-a67e-e9fc8169ac77",
      },
    })
      .then((res) => res.json())
      .catch((err) => ({ ok: false, error: err }));

    if (response.error) {
      console.warn("Zendesk connect response", response);

      if (typeof response.error === "string") {
        throw new Error(`Failed to connect to Zendesk: ${response.error}`);
      }
      throw new Error("Failed to connect to Zendesk");
    }

    return {
      subdomain: response.subdomain,
    };
  };

  const connectFront = async ({ teamId, workspaceId }) => {
    const response = await fetch(`https://api.bugpilot.io/front/connect`, {
      method: "POST",
      body: JSON.stringify({
        teamId,
        workspaceId,
      }),
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "3e2ca236-e617-4706-94c7-17da6ab47cfc",
      },
    })
      .then((res) => res.json())
      .catch((err) => ({ ok: false, error: err }));

    if (response.error) {
      console.warn("Front connect response", response);

      if (typeof response.error === "string") {
        throw new Error(`Failed to connect to Front: ${response.error}`);
      }
      throw new Error("Failed to connect to Front");
    }

    return {
      teamId: response.teamId,
    };
  };

  return {
    connectIntercom,
    connectLiveChat,
    connectCrisp,
    connectZendesk,
    connectFront,
  };
};

export default useHelpdesk;

import { intlFormat, isAfter, isToday, subMinutes } from "date-fns";

const getLocale = () => {
  const browserLocale = navigator.language;
  return browserLocale;
};

export const safeIntlFormat = (...args) => {
  try {
    return intlFormat(...args);
  } catch (e) {
    try {
      return new Date(args[0]).toLocaleString();
    } catch (e) {
      return "Invalid date";
    }
  }
};

export const customFormatDate = (timestamp, tz) => {
  if (isAfter(timestamp, subMinutes(new Date(), 3))) {
    return "Just now";
  }

  if (isToday(timestamp)) {
    return safeIntlFormat(
      timestamp,
      {
        timeStyle: "short",
        timeZone: tz,
      },
      {
        locale: getLocale(),
      }
    );
  }

  return safeIntlFormat(
    timestamp,
    {
      month: "short",
      day: "numeric",
      timeZone: tz,
    },
    {
      locale: getLocale(),
    }
  );
};

export const formatAbsoluteDate = (timestamp, tz) => {
  return safeIntlFormat(
    timestamp,
    {
      timeStyle: "long",
      dateStyle: "short",
      timeZone: tz,
    },
    {
      locale: getLocale(),
    }
  );
};

export default customFormatDate;

// Create react context and hook for usePreferences
// it should query the dynamodb table userpreferences (using amplify credentials)
// and return the preferences object

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Auth } from "aws-amplify";
import DynamoDB from "aws-sdk/clients/dynamodb";
import { merge } from "lodash";

import { useAuth } from "./AuthContext";

const UserPreferencesContext = createContext({
  preferences: {},
  preferencesLoading: true,
  updatePreferences: async (nextPreferences) => {},
});

const getClient = async () => {
  const credentials = await Auth.currentUserCredentials();

  if (credentials instanceof Error) {
    console.error("Failed to get credentials", credentials);
    throw credentials;
  }

  const doc = new DynamoDB.DocumentClient({
    region: "eu-west-1",
    credentials: Auth.essentialCredentials(credentials),
  });

  return { doc, sub: credentials.identityId };
};

const getInitialPreferences = () => {
  return {
    itemCreatedAt: new Date().toISOString(),
  };
};

const getPreferences = async () => {
  const { doc, sub } = await getClient();
  const result = await doc
    .get({
      TableName: "userpreferences",
      Key: {
        id: sub,
        type: "prefs-1",
      },
    })
    .promise();

  if (result.Item) {
    return result.Item.preferences;
  }

  const item = {
    id: sub,
    type: "prefs-1",
    preferences: { ...getInitialPreferences() },
  };

  await doc
    .put({
      TableName: "userpreferences",
      Item: item,
    })
    .promise();

  return item.preferences;
};

export const UserPreferencesProvider = ({ children }) => {
  const [preferences, setPreferences] = useState({});
  const [preferencesLoading, setPreferencesLoading] = useState(true);
  const { user } = useAuth();

  const fetchPreferences = useCallback(() => {
    getPreferences()
      .then(setPreferences)
      .then(() => setPreferencesLoading(false))
      .catch((e) => {
        console.error("Failed loading preferences", e);
        setPreferencesLoading(false);
      });
  }, [setPreferences, setPreferencesLoading]);

  useEffect(() => {
    if (!user) {
      return;
    }

    fetchPreferences();

    const interval = setInterval(() => {
      if (document.visibilityState !== "visible") {
        return;
      }

      fetchPreferences();
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, [user, fetchPreferences]);

  const updatePreferences = useCallback(
    async (newPreferences) => {
      if (typeof newPreferences !== "object") {
        throw new Error("newPreferences must be an object");
      }

      const { doc, sub } = await getClient();
      const item = merge({}, preferences, newPreferences);

      // remove keys which value is null
      Object.keys(item).forEach((key) => {
        if (item[key] === null) {
          delete item[key];
        }
      });

      // optimistic update
      setPreferences(item);

      const updateResult = await doc
        .update({
          TableName: "userpreferences",
          Key: {
            id: sub,
            type: "prefs-1",
          },
          UpdateExpression: "set #preferences = :preferences",
          ExpressionAttributeNames: {
            "#preferences": "preferences",
          },
          ExpressionAttributeValues: {
            ":preferences": item,
          },
          ReturnValues: "ALL_NEW",
        })
        .promise();

      setPreferences(updateResult.Attributes.preferences);
    },
    [user, preferences, setPreferences]
  );

  return (
    <UserPreferencesContext.Provider
      value={{
        preferences,
        preferencesLoading,
        updatePreferences,
      }}
    >
      {children}
    </UserPreferencesContext.Provider>
  );
};

export const usePreferences = () => useContext(UserPreferencesContext);

import React from "react";
import orderBy from "lodash/orderBy";
import Autocomplete from "@mui/material/Autocomplete";
import Input from "../Input";

const date = new Date();

const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));

const formatTimezone = (timeZone) => {
  const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));
  const offset = tzDate.getTime() - utcDate.getTime();
  const offsetString = (Math.abs(offset) / (60 * 60 * 1000))
    .toString()
    .padStart(2, "0");

  return {
    label: `${timeZone} (GMT${offset >= 0 ? "+" : "-"}${offsetString}:00)`,
    offset: offset,
    value: timeZone,
  };
};

const timeZoneOptions = orderBy(
  [...Intl.supportedValuesOf("timeZone"), "UTC"].map((value) =>
    formatTimezone(value)
  ),
  ["offset"],
  ["asc"]
);

const TimeZoneSelect = ({ value, name, label, onChange, ...props }) => (
  <Autocomplete
    autoHighlight
    disableClearable
    options={timeZoneOptions}
    getOptionLabel={(option) => option.label}
    renderInput={(params) => (
      <Input {...params} required label={label} {...props} />
    )}
    value={timeZoneOptions.find((option) => option.value === value)}
    onChange={(event, newValue, reason) => {
      if (reason !== "selectOption") {
        return;
      }

      onChange?.({
        target: {
          name,
          value: newValue.value,
        },
      });
    }}
  />
);

export default TimeZoneSelect;

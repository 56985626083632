export const defaultMessageTemplate = JSON.stringify({
  blocks: [
    {
      key: "6venp",
      text: "New Bugpilot report",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "4v6u1",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "3f1n0",
      text: " ",
      type: "atomic",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: 1,
          key: 0,
        },
      ],
      data: {},
    },
    {
      key: "1l2h9",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "3bmk8",
      text: " ",
      type: "atomic",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: 1,
          key: 1,
        },
      ],
      data: {},
    },
    {
      key: "unis",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "4330n",
      text: " ",
      type: "atomic",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: 1,
          key: 2,
        },
      ],
      data: {},
    },
    {
      key: "7qvur",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "5lu0t",
      text: " ",
      type: "atomic",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: 1,
          key: 3,
        },
      ],
      data: {},
    },
    {
      key: "6lqcf",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: "82jj4",
      text: " ",
      type: "atomic",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 0,
          length: 1,
          key: 4,
        },
      ],
      data: {},
    },
    {
      key: "1msre",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {
    0: {
      type: "MY-FIELD",
      mutability: "IMMUTABLE",
      data: {
        value: "{user.email}",
      },
    },
    1: {
      type: "MY-FIELD",
      mutability: "IMMUTABLE",
      data: {
        value: "{url}",
      },
    },
    2: {
      type: "MY-FIELD",
      mutability: "IMMUTABLE",
      data: {
        value: "{timestamp}",
      },
    },
    3: {
      type: "MY-FIELD",
      mutability: "IMMUTABLE",
      data: {
        value: "{description}",
      },
    },
    4: {
      type: "MY-FIELD",
      mutability: "IMMUTABLE",
      data: {
        value: "{reportUrl}",
      },
    },
  },
});

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { convertToRaw } from "draft-js";
import debounce from "lodash/debounce";

import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

import TitleIcon from "@mui/icons-material/TitleOutlined";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import PersonIcon from "@mui/icons-material/PersonOutlined";
import WebIcon from "@mui/icons-material/WebOutlined";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActiveOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTimeOutlined";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmailOutlined";
import LinkIcon from "@mui/icons-material/LinkOutlined";

import MUIRichTextEditor from "../lib/MUIRichTextEditor/index.tsx";
import { getReport } from "../api";
import { useAppContext } from "../contexts/AppContext";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { Paper } from "@mui/material";

const Content = ({ children }) => (
  <Typography variant="body2" color="text.secondary">
    {children}
  </Typography>
);

const REPORT_FIELDS = [
  {
    keys: ["Report type"],
    value: "{type}",
    content: <Content>Report type</Content>,
  },
  {
    keys: ["Title"],
    value: "{title}",
    content: <Content>Title</Content>,
    icon: <TitleIcon fontSize="small" />,
  },
  {
    keys: ["Description"],
    value: "{description}",
    content: <Content>Description</Content>,
    icon: <DescriptionIcon fontSize="small" />,
  },
  {
    keys: ["Timestamp"],
    value: "{timestamp}",
    content: <Content>Timestamp</Content>,
    icon: <AccessTimeIcon fontSize="small" />,
  },
  {
    keys: ["URL"],
    value: "{url}",
    content: <Content>URL</Content>,
    icon: <LinkIcon fontSize="small" />,
  },
  {
    keys: ["Report URL"],
    value: "{reportUrl}",
    content: <Content>Report URL</Content>,
    icon: <LinkIcon fontSize="small" />,
  },
  {
    keys: ["Email"],
    value: "{user.email}",
    content: <Content>Email</Content>,
    icon: <AlternateEmailIcon fontSize="small" />,
  },
  {
    keys: ["User info"],
    value: "{user}",
    content: <Content>User info</Content>,
    icon: <PersonIcon fontSize="small" />,
  },
  {
    keys: ["Browser info"],
    value: "{browser}",
    content: <Content>Browser info</Content>,
    icon: <WebIcon fontSize="small" />,
  },
  {
    keys: ["Browser type"],
    value: "{browser.type}",
    content: <Content>Browser type (desktop, mobile)</Content>,
    icon: <WebIcon fontSize="small" />,
  },
  {
    keys: ["Autopilot info"],
    value: "{autopilot}",
    content: <Content>Autopilot info</Content>,
    icon: <AirplanemodeActiveIcon fontSize="small" />,
  },
];

const FieldComponent = (props) => {
  const { blockProps } = props;
  const { value } = blockProps;

  const item = REPORT_FIELDS.find((item) => item.value === value);

  return (
    <Chip
      sx={{
        display: "inline-flex",
      }}
      color="secondary"
      variant="outlined"
      label={item?.keys[0]}
      icon={item?.icon}
    />
  );
};

const exampleReport = {
  userAgent: {
    cpu: {
      architecture: "amd64",
    },
    ua: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36",
    os: {
      name: "Windows",
      version: "10",
    },
    engine: {
      name: "Blink",
      version: "108.0.0.0",
    },
    device: {},
    browser: {
      name: "Chrome",
      major: "108",
      version: "108.0.0.0",
    },
  },
  metadata: {
    triggerSource: "intercom",
    triggerType: "auto-intercom-bubble-open",
  },
  stats: {
    activitiesCount: 252,
    eventsCount: 1559,
    duration: 58014,
  },
  window: {
    width: 1536,
    height: 743,
  },
  user: {
    firstName: "Henrietta",
    lastName: "Muller",
    id: "fb112bfc-6e88-4540-8add-bb1888710528",
    email: "Henrietta.muller@bugpilot.io",
    workspaceId: "b1256879-5df7-4ab8-9000-329964fb56a2",
  },
  sourceInfo: {},
  localStorage: {},
  cookies: {},
  url: "https://dash.bugpilot.io/reports",
  language: "en-US",
  activities: [],
  timing: {
    redirectCount: 0,
    encodedBodySize: 2642,
    unloadEventEnd: 0,
    responseEnd: 325.19999980926514,
    domainLookupEnd: 192.90000009536743,
    unloadEventStart: 0,
    domContentLoadedEventStart: 424.40000009536743,
    type: "navigate",
    duration: 429.90000009536743,
    decodedBodySize: 6303,
    redirectStart: 0,
    connectEnd: 211,
    requestStart: 211.19999980926514,
    startTime: 0,
    fetchStart: 38.19999980926514,
    serverTiming: [],
    domContentLoadedEventEnd: 426.40000009536743,
    entryType: "navigation",
    renderBlockingStatus: "blocking",
    workerStart: 0,
    responseStart: 321.59999990463257,
    domInteractive: 414.90000009536743,
    domComplete: 426.69999980926514,
    domainLookupStart: 45.59999990463257,
    redirectEnd: 0,
    transferSize: 2942,
    connectStart: 192.90000009536743,
    loadEventStart: 426.69999980926514,
    secureConnectionStart: 193.19999980926514,
    name: "https://dash.bugpilot.io/report/r1-vcdmzw_hrMXnYKD4Ku6TE?utm_medium=intercom&utm_source=intercom-integration&utm_campaign=reports-list",
    nextHopProtocol: "h3",
    activationStart: 0,
    initiatorType: "navigation",
    loadEventEnd: 429.90000009536743,
  },
  recordings: [],
  id: "r1-example-id",
  firstContentfulPaint: {
    name: "first-contentful-paint",
    duration: 0,
    entryType: "paint",
    startTime: 1181.5999999046326,
  },
  reportId: "r1-fIyg6hV7dJcVahFrmTzQU",
  mode: "session",
  screen: {
    width: 1920,
    height: 1080,
  },
  scriptVersion: "f0cd819daa8c6819f156ed82938027f989baa1b3",
  timestamp: 1671640496291,
  source: "script",
  sessionStorage: {},
  timeOrigin: 1671640612888.2,
  timeZone: "Europe/Sofia",
  autopilot: {
    rageClicks: 0,
    frontendErrors: 6,
    blocked: 0,
    requestsWithoutResponses: 0,
    errors: 7,
    networkErrors: 1,
  },
  connection: {
    effectiveType: "4g",
    downlink: 10,
    rtt: 100,
  },
  firstPaint: {
    name: "first-paint",
    duration: 0,
    entryType: "paint",
    startTime: 1181.5999999046326,
  },
  description:
    'As a user, I get an error message that says "something went wrong"',
  userAgentString:
    "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36",
  title: "b5a395a8-bf0e-4f4d-a73a-25b3f4eac1ee | reports",
};

const IntegrationsMessageField = ({ company, onChange, value }) => {
  const ref = useRef();

  const {
    reports,
    fetchReportMessagePreview,
    reportMessagePreview,
    isReportMessagePreviewLoading,
  } = useAppContext();

  const [report, setReport] = useState(exampleReport);
  const [messageTemplate, setMessageTemplate] = useState(value);

  const fetchPreview = useMemo(
    () =>
      debounce(
        async ({ messageTemplate, report }) =>
          fetchReportMessagePreview({ messageTemplate, report }),
        500
      ),
    [fetchReportMessagePreview]
  );

  useEffect(() => {
    fetchPreview({ messageTemplate, report });
  }, [report, messageTemplate]);

  const handleChange = useCallback(
    (editorState) => {
      const content = editorState.getCurrentContent();
      const rawObject = convertToRaw(content);
      const messageTemplate = JSON.stringify(rawObject);
      setMessageTemplate(messageTemplate);
      onChange?.(messageTemplate);
    },
    [onChange]
  );

  const handleReportChange = useCallback(async (e) => {
    const reportId = e.target.value;

    if (reportId === exampleReport.id) {
      setReport(exampleReport);
      return;
    }

    try {
      const report = await getReport({ reportId });
      setReport(report);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, []);

  return (
    <Stack>
      <Typography variant="h6" gutterBottom>
        Template
      </Typography>

      <Paper>
        <MUIRichTextEditor
          defaultValue={value}
          ref={ref}
          onChange={handleChange}
          controls={[
            "bold",
            "italic",
            "strikethrough",
            "bulletList",
            "numberList",
            "clear",
          ]}
          inlineToolbar
          inlineToolbarControls={["bold", "italic", "strikethrough", "clear"]}
          customControls={[
            {
              name: "my-field",
              type: "atomic",
              atomicComponent: FieldComponent,
            },
          ]}
          autocomplete={{
            suggestLimit: 100,
            strategies: [
              {
                atomicBlockName: "my-field",
                items: REPORT_FIELDS,
                triggerChar: "@",
                insertSpaceAfter: false,
              },
            ],
          }}
        />
      </Paper>

      <FormHelperText
        sx={{
          marginTop: 1,
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <InfoIcon fontSize="small" />
          Type @ to insert report properties
        </Stack>
      </FormHelperText>
    </Stack>
  );
};

export default IntegrationsMessageField;

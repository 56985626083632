import { useMemo } from "react";
import { Alert } from "@mui/material";

import useHasRole from "../hooks/useHasRole";

const OnlyOwnerAlert = ({ isDisabled }) => {
  return isDisabled ? (
    <Alert severity="info">
      Only the Workspace owner or admin can change settings in this section
    </Alert>
  ) : null;
};

const OnlyOwnerAlertContainer = ({ role = "admin", children }) => {
  const { hasRole: isAdminOrOwner, isLoading } = useHasRole(role);
  const isDisabled = !isLoading && !isAdminOrOwner;

  const element = useMemo(
    () => (isDisabled ? <OnlyOwnerAlert isDisabled={isDisabled} /> : null),
    [isDisabled]
  );

  return typeof children === "function" ? children(element) : element;
};

export default OnlyOwnerAlertContainer;

import React, { useCallback, useMemo } from "react";
import debounce from "lodash/debounce";

import {
  Stack,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
  Alert,
  Typography,
} from "@mui/material";

import { useAppContext } from "../../contexts/AppContext";
import useHasRole from "../../hooks/useHasRole";
import { SettingsSection } from "./SettingsSection.tsx";
import OnlyOwnerAlert from "../../components/OnlyOwnerAlert";

const SessionCaptureSettingsPage = ({
  company,
  onChange: _onChange,
  isDisabled,
}) => {
  const onChange = useMemo(
    () => debounce((...args) => _onChange(...args), 300),
    [_onChange]
  );

  return (
    <Stack spacing={3} mt={3}>
      <OnlyOwnerAlert />

      <Typography variant="h2">This settings section is deprecated.</Typography>

      <Box />
    </Stack>
  );
};

const CustomizePageContainer = () => {
  const { updateCompany, selectedCompanyId, companies } = useAppContext();

  const handleChange = useCallback(
    (changes) => updateCompany(selectedCompanyId, changes),
    [updateCompany, selectedCompanyId]
  );
  const { hasRole: isAdminOrOwner } = useHasRole("admin");

  return (
    <SessionCaptureSettingsPage
      company={companies.find(({ id }) => id === selectedCompanyId)}
      onChange={handleChange}
      isDisabled={!isAdminOrOwner}
    />
  );
};

export default CustomizePageContainer;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import JSONView from "react-json-view";
import * as emailValidator from "email-validator";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSnackbar } from "notistack";
import { Stack } from "@mui/system";

import { useAppContext } from "../../contexts/AppContext";
import { useModals } from "../../hooks/useModals";
import {
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  Input,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import {
  CodeOutlined as CodeIcon,
  MoreVertOutlined as MoreVertIcon,
  EmailOutlined as EmailOutlinedIcon,
} from "@mui/icons-material";
import getIntegrationsLinks from "./IntegrationsLinks";
import { ReportShareDialog } from "./ReportShareDialog";
import { NotifyUserModal } from "./NotifyUserModal/NotifyUserModal.tsx";
import { useReport } from "../../contexts/ReportContext.tsx";
import { makeReportUrl } from "./makeReportUrl";

export const ActionsMenu = ({ report, setReport }) => {
  const modals = useModals();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { deleteReport, updateReport, notifyReporter } = useAppContext();
  const { closeReport, nextReport, prevReport } = useReport();

  const [showSourceModal, setShowSourceModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showShareDialog, setShowShareDialog] = useState(false);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleDeleteReport = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      modals.openConfirmation({
        onConfirm: async () => {
          await deleteReport(report.id);
          nextReport();
        },
        text: "This report will be deleted and you won’t be able to recover it.",
        confirmCTAHeader: "Delete report",
        confirmCTATitle: "Permanently delete",
        confirmCTAColor: "error",
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report]
  );

  const source = useMemo(() => {
    if (!report) {
      return {};
    }

    const val = {
      ...report,
    };
    delete val.recordings;
    delete val.activities;
    delete val.localStorage;
    delete val.sessionStorage;
    delete val.cookies;
    return val;
  }, [report]);

  // register keyboard shortcuts listeners to:
  // o: open report
  // shift + o: open report in new tab
  // right arrow: next report

  // 3: bug status set to confirmed
  // 1: bug status set to correct behavior
  // 2: bug status set to likely_a_bug
  // space: toggle play/pause
  useEffect(() => {
    const handleKeyDown = (e) => {
      // if an input is focused, ignore keyboard shortcuts
      if (
        document.activeElement.tagName === "INPUT" ||
        document.activeElement.tagName === "TEXTAREA" ||
        document.activeElement.tagName === "BUTTON" ||
        // does not have parents with contenteditable
        document.activeElement.closest("[contenteditable]") !== null
      ) {
        return;
      }

      if (e.key === "o" && e.shiftKey) {
        window.open(makeReportUrl(report), "_blank");
        return;
      }

      if (e.key === "d") {
        handleDeleteReport(e);
        return;
      }

      if (
        e.key === // right arrow
        "ArrowRight"
      ) {
        nextReport();
        return;
      }

      // if escape, close
      if (e.key === "Escape") {
        closeReport();
        return;
      }

      if (e.key === "ArrowLeft") {
        prevReport();
        return;
      }

      if (e.key === "1") {
        updateReport(report, { bugStatus: "not_a_bug" }, setReport);
        return;
      }

      if (e.key === "2") {
        updateReport(report, { bugStatus: "confirmed" }, setReport);
        return;
      }

      if (e.key === "3") {
        updateReport(report, { bugStatus: "in_progress" }, setReport);
        return;
      }

      if (e.key === "4") {
        updateReport(report, { bugStatus: "fixed" }, setReport);
        return;
      }

      if (e.key === "/") {
        setShowSourceModal(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate, nextReport, prevReport, report, setReport, updateReport]);

  const userEmail = useMemo(
    () =>
      report?.user
        ? emailValidator.validate(report.user.email)
          ? report.user.email
          : Object.values(report.user).find((value) =>
              emailValidator.validate(value)
            )
        : null,
    [report?.user]
  );

  const [showNotifyUserModal, setShowNotifyUserModal] = useState(false);
  const handleShowNotifyUser = useCallback(
    (val) => setShowNotifyUserModal(!val),
    []
  );

  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
          width: 40,
        }}
      >
        <MoreVertIcon fontSize="small" />
      </Button>

      <Popper
        sx={{
          zIndex: 10000,
        }}
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center bottom" : "center top",
            }}
          >
            <Paper elevation={3}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  sx={{
                    // listItemIcon color primary
                    "& .MuiListItemIcon-root": {
                      color: "secondary.main",
                      width: "18px",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      if (!userEmail) {
                        enqueueSnackbar(
                          "You cannot notify this user because we don't have an email address for them",
                          {
                            variant: "error",
                          }
                        );
                        return;
                      }

                      handleShowNotifyUser();
                    }}
                  >
                    <ListItemIcon>
                      <EmailOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Notify user" />
                  </MenuItem>

                  <Divider />

                  {getIntegrationsLinks({ report }).map((integ) => (
                    <MenuItem key={integ.label} onClick={integ.onClick}>
                      <ListItemIcon>{integ.icon}</ListItemIcon>
                      <ListItemText primary={integ.label} />
                    </MenuItem>
                  ))}

                  <Divider />

                  <MenuItem onClick={() => setShowSourceModal(true)}>
                    <ListItemIcon>
                      <CodeIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="View source" />
                  </MenuItem>

                  {/* <MenuItem onClick={handleDeleteReport}>
                    <ListItemIcon>
                      <DeleteIcon
                        fontSize="small"
                        sx={{ color: "#EC515A !important" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Delete"
                      sx={{ color: "error.main" }}
                    />
                  </MenuItem> */}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {showSourceModal && (
        <Dialog
          open={showSourceModal}
          maxWidth="lg"
          onClose={() => setShowSourceModal(false)}
        >
          <DialogTitle>Report Source View</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Paper>
                <Box p={2}>
                  <Typography variant="body1">
                    Report ID:{" "}
                    <Input
                      value={report.id}
                      readOnly
                      inputProps={{
                        size: 30,
                      }}
                      onClick={(e) => {
                        e.target.select();
                        document.execCommand("copy");
                      }}
                    />{" "}
                    <br />
                    Report Link:{" "}
                    <a
                      href={`${window.location.origin}/report/${report.id}`}
                    >{`${window.location.origin}/report/${report.id}`}</a>
                  </Typography>
                </Box>
              </Paper>

              <Paper>
                <Box p={2}>
                  <JSONView
                    src={source}
                    enableClipboard
                    collapsed={false}
                    displayDataTypes={false}
                    style={{
                      fontFamily: "monospace",
                      fontSize: 11,
                      wordBreak: "break-all",
                    }}
                  />
                </Box>
              </Paper>

              <Divider />

              <Paper>
                <Box p={2}>
                  <Typography variant="body2">
                    Session Replay data ({report.recordings?.length} items):
                  </Typography>

                  <List>
                    {report.recordings?.map((recording, i) => (
                      <ListItem key={i}>
                        <Typography variant="code" fontSize={11}>
                          {JSON.stringify(recording)}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Paper>

              <Divider />

              <Paper>
                <Box p={2}>
                  <Typography variant="body2">
                    Console data ({report.activities?.length} items):
                  </Typography>

                  <List>
                    {report.activities?.map((activity, i) => (
                      <ListItem key={i}>
                        <Typography
                          variant="code"
                          fontSize={11}
                          sx={{
                            wordBreak: "break-all",
                          }}
                        >
                          {typeof activity === "string" ? (
                            activity.startsWith("https://") ? (
                              <Link href={activity} target="_blank">
                                {activity}
                              </Link>
                            ) : (
                              activity
                            )
                          ) : (
                            JSON.stringify(activity)
                          )}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Paper>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowSourceModal(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      {showShareDialog && (
        <ReportShareDialog
          report={report}
          open
          onClose={() => setShowShareDialog(false)}
        />
      )}

      {showNotifyUserModal && (
        <NotifyUserModal
          report={report}
          open
          handleClose={handleShowNotifyUser}
        />
      )}
    </>
  );
};

import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";

export const HOTKEYS_EVENTS = {
  f: "Opens the omnisearch box (same as clicking on Search in top app bar)",
  i: "Moves to issues",
  b: "Moves to bug tracker",
  n: ["In Bug Tracker, creates a new card"],
};

type HotkeyType = keyof typeof HOTKEYS_EVENTS;

const GlobalHotKeysContext = createContext({
  register: (key: HotkeyType, fn: () => void) => {},
  unregister: (key: HotkeyType) => {},
});

type Handler = (e: KeyboardEvent) => void;

export const GlobalHotKeysProvider = ({ children }): ReactElement => {
  const handlers = useRef<{
    [key in HotkeyType]?: Handler;
  }>({});

  const register = useCallback((key: HotkeyType, fn: Handler) => {
    if (handlers.current[key]) {
      console.error(`GlobalHotKeys: Overwriting handler for ${key}`);
    }

    handlers.current[key] = fn;
  }, []);

  const unregister = useCallback((key: HotkeyType) => {
    delete handlers.current[key];
  }, []);

  useEffect(() => {
    const action = (e: KeyboardEvent, fn: Handler) => {
      e.preventDefault();
      e.stopPropagation();
      fn(e);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (!e.ctrlKey) {
        // our shortcuts are all ctrl based
        return;
      }

      if (e.ctrlKey && e.shiftKey) {
        // ...except for ctrl+shift+something, this allows
        // windows users to search with ctrl+shift+f
        return;
      }

      // call action with the callback, for each HOTKEYS_EVENTS

      const key = e.key.toLowerCase() as HotkeyType;
      const fn = handlers.current[key];
      if (fn) {
        action(e, fn);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <GlobalHotKeysContext.Provider
      value={{
        register,
        unregister,
      }}
    >
      {children}
    </GlobalHotKeysContext.Provider>
  );
};

export const useGlobalHotKeys = () => useContext(GlobalHotKeysContext);

import {
  CircularProgress,
  Paper,
  Stack,
  Typography,
  Link as MuiLink,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import { CheckCircleOutlined as CheckCircleIcon } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SimpleList } from "./SimpleList";
import { Link } from "react-router-dom";
import { useReportsListContext } from "../../contexts/ReportsListContext.tsx";

export const VerifyInstallation = () => {
  const { reports, requestRefreshReports, setQueryParams } =
    useReportsListContext();

  const [websiteUrl, setWebsiteUrl] = useState("");
  const [openClicked, setOpenClicked] = useState(false);
  const [verifying, setVerifying] = useState(true);

  const handleOpenClick = useCallback(() => {
    if (!websiteUrl) {
      return;
    }

    let urlString = websiteUrl;

    if (!urlString.startsWith("http")) {
      urlString = `https://${urlString}`;
    }

    const url = new URL(urlString);
    const result = window.open(url.toString(), "_blank");

    if (!result) {
      alert("Please allow popups for this website.");
      return;
    }

    setOpenClicked(true);

    setTimeout(() => {
      setOpenClicked(false);
    }, 2000);
  }, [websiteUrl]);

  // fetch reports every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setQueryParams((params) => []);
      requestRefreshReports();
    }, 10 * 1000);

    return () => clearInterval(interval);
  }, [requestRefreshReports, setQueryParams]);

  const reportVerified = useMemo(() => {
    // check if a report with metadata.triggerType = verify-script exists
    // and it's timestamp is less than 2 minutes ago
    const report = reports.find((report) => {
      const recent =
        !report.metadata?.demo &&
        report.timestamp > Date.now() - 10 * 60 * 1000;
      return recent;
    });

    return report;
  }, [reports]);

  useEffect(() => {
    if (reportVerified) {
      setVerifying(false);
    }
  }, [reportVerified]);

  return (
    <Stack spacing={1}>
      <Stack spacing={1}>
        {/* Manual reporting via the widget */}
        <Stack>
          <Typography variant="body1" fontWeight={"bold"} color={"secondary"}>
            Report a bug using your new Bug Reporting widget:
          </Typography>
          <SimpleList
            dense
            disablePadding
            items={[
              "Open your production app (localhost is not supported)",
              <>
                Click on the <strong>&ldquo;REPORT ISSUE&rdquo;</strong>{" "}
                Bugpilot widget you&apos;ll find in the right side of your
                screen.
              </>,
              <>
                Click on <strong>&ldquo;RECORD A PROBLEM&rdquo;</strong> button
                and follow the instructions to record your screen.
              </>,
              <>
                After you finish recording, write a description of the issue and
                click on the <strong>&ldquo;SUBMIT&rdquo;</strong> button.
              </>,
              "Wait a few seconds and check the list of issues below.",
            ]}
            sx={{ m: 1, mb: 0, px: 2 }}
          />
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Divider sx={{ mt: 2 }} />

        <Box p={4}>
          <Stack
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: 130 }}
          >
            {verifying && (
              <>
                <CircularProgress size={24} />
                <Typography variant="body2" textAlign="center">
                  Follow the steps above to verify your installation.{" "}
                  <Link
                    onClick={requestRefreshReports}
                    style={{
                      cursor: "pointer",
                      color: "inherit",
                    }}
                  >
                    Check again
                  </Link>
                </Typography>
              </>
            )}

            {!verifying && reportVerified && (
              <>
                <CheckCircleIcon color="success" fontSize="large" />
                <Typography variant="body2" textAlign="center">
                  Success!{" "}
                  <MuiLink
                    href={`/getting-started/done`}
                    style={{
                      color: "inherit",
                      textDecoration: "underline",
                      textUnderlineOffset: "2px",
                      cursor: "pointer",
                    }}
                  >
                    Continue to the next step &rarr;
                  </MuiLink>
                </Typography>
              </>
            )}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

import { Box, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";

const OnboardingBackdrop = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: "sidebar.gradient1",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack spacing={2}>
        {children}

        <Typography variant="body1">
          <Link
            href="/logout"
            sx={{
              color: "muted.light",
            }}
          >
            Logout
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
};

export default OnboardingBackdrop;

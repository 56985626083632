import { Box, Stack } from "@mui/material";
import { CMSContent } from "./CMSContent";

export const TestimonialContent = ({ contentId, ...props }) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "100%",
        width: "100%",
        ...(props.sx || {}),
        pointerEvents: "none",
      }}
    >
      {/* Testimonial embed */}
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
          width: "100%",
        }}
      >
        <CMSContent
          type="image"
          width={500}
          database="testimonials"
          contentId={contentId}
          sx={{
            border: "1px solid #ccc",
            borderRadius: 2,
          }}
        />
      </Box>
    </Stack>
  );
};

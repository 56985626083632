import { Typography } from "@mui/material";

export const ReportId = ({ row }) => {
  return (
    <Typography variant="body1" color="tertiary.main" noWrap>
      #{typeof row.serialNumber === "number" ? row.serialNumber : ""}
    </Typography>
  );
};

export const render = (props) => <ReportId {...props} />;

export const valueFormatter = ({ user, chatUserId }) =>
  (user && (user.email || user.id || chatUserId)) || "Anonymous";

export const sortComparator = (v1, v2, param1, param2) => {
  const value1 = param1.user?.email || param1.user?.id || param1.chatUserId;
  const value2 = param2.user?.email || param2.user?.id || param2.chatUserId;
  return value1?.localeCompare?.(value2);
};

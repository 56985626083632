import { useState } from "react";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { WorkspaceSelectDropdownMenu } from "./WorkspaceSelectDropdownMenu";
import { UnfoldMoreOutlined as UnfoldMoreIcon } from "@mui/icons-material";

export const SelectWorkspaceMenu = ({ selectedWorkspace }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const workspaceName = selectedWorkspace?.name ?? "";

  return (
    <ListItem
      disablePadding
      sx={{
        cursor: "pointer",
        width: "100%",
        overflow: "hidden",
      }}
      onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
    >
      <ListItemAvatar
        sx={{
          minWidth: 0,
          mr: 2,
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            backgroundColor: "sidebar.avatarBg",
            color: "sidebar.avatarFg",
            height: 22,
            width: 22,
            fontSize: 12,
          }}
        >
          {workspaceName.toUpperCase().slice(0, 2)}
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={workspaceName || "My Workspace"}
        primaryTypographyProps={{
          variant: "body1",
          fontWeight: 600,
          color: (theme) => theme.palette.sidebar.title.replace("C0", "FF"),
        }}
        color="success"
        sx={{
          // all children
          "& > *": {
            overflow: "hidden",
            width: "100%",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
          visibility: workspaceName ? "visible" : "hidden",
        }}
      />

      <UnfoldMoreIcon fontSize="small" sx={{ color: "sidebar.item" }} />

      <WorkspaceSelectDropdownMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
      />
    </ListItem>
  );
};

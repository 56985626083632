import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  Box,
  Alert,
  AlertTitle,
  Typography,
  Button,
  Stack,
} from "@mui/material";

import ActionsPanel from "./ActionsPanel.tsx";
import ReportTitleBar from "./ReportTitleBar";

import { AutopilotProvider } from "./AutopilotGroup/AutopilotContext.tsx";
import { useNavigate } from "react-router-dom";
import { ReportSidebar } from "./ReportSidebar";
import { createPortal } from "react-dom";
import RequireSubscription from "../../subscription/RequireSubscription.js";

export const FullReportPage = ({
  user,
  company,
  report,
  reportContents,
  setReport,
  isReportLoading,
  mutedErrors,
  handleNextReport,
  isBuffering,
  refreshReport,
  error,
  variant = "full", // full ||  lean
}) => {
  // the activities (previously named recordings) and
  // the recordings (previously named events, from rrweb) are now embedded
  // in the report object (see api.getReport)
  const navigate = useNavigate();

  const [showTitleAlert, setShowTitleAlert] = useState(false);

  const handleTriage = useCallback(
    (status) => {
      if (!report.title || report.title === "Untitled report") {
        setShowTitleAlert(true);
      }
    },
    [setShowTitleAlert, report]
  );

  const topBarBreadcrumbsElement = document.getElementById(
    "top-bar-breadcrumbs"
  );

  const topBarBreadcrumbs = useMemo(
    () => (
      <ReportHeaderBar
        report={report}
        isBuffering={isBuffering}
        refreshReport={refreshReport}
        company={company}
        handleNextReport={handleNextReport}
        onTourEnd={() => {}}
        setReport={setReport}
        setShowTitleAlert={setShowTitleAlert}
        showTitleAlert={showTitleAlert}
        variant={variant}
      />
    ),
    [
      report,
      isBuffering,
      refreshReport,
      company,
      handleNextReport,
      setReport,
      setShowTitleAlert,
      showTitleAlert,
      variant,
    ]
  );

  const readOnly = useMemo(
    () =>
      report.metadata?.triggerType === "autopilot" &&
      report.metadata?.errorInfo?.originalReportId &&
      report.metadata?.errorInfo?.originalReportId !== report.id,
    [report]
  );

  const [defaultTab, setDefaultTab] = useState(
    (report?.commentsCount && !readOnly && variant === "full") ||
      report?.id?.startsWith("card-")
      ? "discussion"
      : "session"
  );

  useEffect(() => {
    setDefaultTab(
      (report?.commentsCount && !readOnly && variant === "full") ||
        report?.id?.startsWith("card-")
        ? "discussion"
        : "session"
    );
  }, [readOnly, report?.commentsCount, report?.id, variant]);

  if (!report) {
    return (
      <Box p={4}>
        {topBarBreadcrumbsElement &&
          createPortal(topBarBreadcrumbs, topBarBreadcrumbsElement)}
        <Alert severity="error">
          <AlertTitle>Report not available</AlertTitle>
          <Typography gutterBottom>
            This report does not exist, has been deleted, or you do not have
            access to it.
          </Typography>
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={() => navigate("/")}
          >
            Go to Bugpilot
          </Button>
        </Alert>
      </Box>
    );
  }

  if (error) {
    return (
      <Grid item xs={12} order={{ xs: 2 }}>
        {topBarBreadcrumbsElement &&
          createPortal(topBarBreadcrumbs, topBarBreadcrumbsElement)}
        <Alert severity="error" sx={{ mb: 2 }}>
          An error occurred while loading the report. Some information may be
          missing. Our engineers have been notified, please try again later (
          {JSON.stringify(error.message)})
        </Alert>
      </Grid>
    );
  }

  return (
    <RequireSubscription
      // Exclude demo reports from the subscription requirement
      excludeRoutes={["/report/0", "/reports/0"]}
      subscriptionNotNecessary={
        report?.title?.includes("Example") ||
        report?.metadata?.demo ||
        // widget reports are included in the free plan
        report?.metadata?.triggerType === "widget" ||
        report?.companyId?.startsWith("reportbug-")
      }
    >
      <AutopilotProvider report={report} variant={variant}>
        {topBarBreadcrumbsElement &&
          createPortal(topBarBreadcrumbs, topBarBreadcrumbsElement)}

        <Stack
          direction="row"
          spacing={4}
          sx={{ height: "100%", overflow: "hidden" }}
        >
          <ReportSidebar
            variant={variant}
            report={report}
            reportContents={reportContents}
            setReport={setReport}
            handleTriage={handleTriage}
            stackProps={{
              flexBasis: 250,
              flexGrow: 0,
              flexShrink: 0,
              style: {
                width: "100%",
                height: "100%",
              },
            }}
            switchTab={setDefaultTab}
            readOnly={readOnly}
          />

          <Box
            style={{
              width: "100%",
              height: "100%",
            }}
            maxWidth="lg"
          >
            <ActionsPanel
              key={report.id}
              user={user}
              company={company}
              report={report}
              reportContents={reportContents}
              isReportLoading={isReportLoading}
              activities={report.activities}
              currentWorkspace={company}
              setReport={setReport}
              isBuffering={isBuffering}
              refreshReport={refreshReport}
              defaultTab={defaultTab}
              switchTab={setDefaultTab}
              readOnly={readOnly}
              variant={variant}
            />
          </Box>
        </Stack>
      </AutopilotProvider>
    </RequireSubscription>
  );
};

function ReportHeaderBar({
  showTitleAlert,
  setShowTitleAlert,
  onTourEnd,
  report,
  company,
  setReport,
  handleNextReport,
  variant,
}) {
  return (
    <ReportTitleBar
      key={report?.id}
      currentWorkspace={company}
      report={report}
      setReport={setReport}
      handleNextReport={handleNextReport}
      showActions={variant === "full"}
    />
  );
}

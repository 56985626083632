import { useMemo } from "react";
import { Chip } from "@mui/material";

export const StatusChip = ({ workspace }) => {
  const chip = useMemo(() => {
    if (!workspace) {
      return null;
    }

    const { subscriptionStatus, planName } = workspace;

    const humanPlanName = planName?.toLowerCase?.().includes?.("small")
      ? "Small"
      : planName?.toLowerCase?.().includes?.("team")
      ? "Team"
      : planName || "";

    switch (subscriptionStatus) {
      case "trialing":
        return (
          <Chip
            label={`${humanPlanName} Trial`}
            color="warning"
            size="small"
            sx={{
              color: "secondary.main",
            }}
          />
        );
      case "active":
        return (
          <Chip
            label={humanPlanName}
            size="small"
            sx={{
              bgcolor: (theme) => theme.palette.sidebar.calloutBg,
              color: (theme) => theme.palette.sidebar.calloutText,
            }}
          />
        );
      case "past_due":
        return <Chip label="Unpaid" color="error" size="small" />;
      case "deleted":
        return <Chip label="Cancelled" color="error" size="small" />;
      case "paused":
        return <Chip label="Paused" color="error" size="small" />;
      default:
        return null;
    }
  }, [workspace]);

  if (!workspace) {
    return null;
  }

  return chip;
};

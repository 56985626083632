import React from "react";
import { useCallback } from "react";
import { Box, IconButton } from "@mui/material";
import EmojiSelect from "./EmojiSelect";

export const AddReactions = ({ reactions, onUpdate, user, emojiHotkeys }) => {
  const handleReactionSelect = useCallback(
    ({ native: emoji }) => {
      const isReacted = reactions.some(
        ({ authorId, ...reaction }) =>
          authorId === user.sub && emoji === reaction.emoji
      );

      if (isReacted) {
        return;
      }

      return onUpdate({
        reaction: {
          emoji,
          operation: "add",
        },
        reactions: [
          ...reactions,
          {
            emoji,
          },
        ],
      });
    },
    [reactions, onUpdate, user.sub]
  );

  return (
    <>
      {emojiHotkeys.map((emoji) => (
        <IconButton
          size="small"
          key={emoji}
          onClick={() => handleReactionSelect({ native: emoji })}
        >
          <span
            style={{
              fontSize: 11,
              height: 16,
              width: 16,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {emoji}
          </span>
        </IconButton>
      ))}
      <Box component="span">
        <EmojiSelect
          onChange={handleReactionSelect}
          color="tertiary"
          size="small"
          variant="outlined"
        />
      </Box>
    </>
  );
};

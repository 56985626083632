import { useCallback, useEffect, useState } from "react";
import Amplify from "aws-amplify";
import ms from "ms";

const INITIAL_WS_RECONNECT_DELAY = ms("1s");
const MAX_WS_RECONNECT_DELAY = ms("10m");

const useWebsockets = ({ sessionId, onMessage, isEnabled }) => {
  const [ws, setWS] = useState(null);
  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    let webSocket;
    let reconnectDelay = INITIAL_WS_RECONNECT_DELAY;

    const connect = async () => {
      const session = await Amplify.Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();

      const url = new URL(`wss://wss.bugpilot.io`);
      url.searchParams.set("sessionId", sessionId);
      url.searchParams.set("authorization", `Bearer ${idToken}`);
      webSocket = new WebSocket(url.toString());
      setWS(webSocket);

      webSocket.onopen = () => {
        reconnectDelay = INITIAL_WS_RECONNECT_DELAY;
      };

      webSocket.onerror = () => {
        setTimeout(() => {
          connect();

          // Exponential backoff
          if (reconnectDelay >= MAX_WS_RECONNECT_DELAY) {
            return;
          }

          reconnectDelay = reconnectDelay * 2;
        }, reconnectDelay);
      };
    };

    connect();

    return () => {
      webSocket?.close?.();
    };
  }, [isEnabled, sessionId]);

  useEffect(() => {
    if (!ws) {
      return;
    }

    ws.onmessage = (e) => {
      let parsedMessage = e.data;

      try {
        parsedMessage = JSON.parse(e.data);
      } catch (e) {
        // Ignore
      }

      onMessage(parsedMessage);
    };
  }, [onMessage, ws]);

  const sendMessage = useCallback(
    (message) => {
      if (!ws) {
        return;
      }
      ws.send(JSON.stringify(message));
    },
    [ws]
  );

  return {
    sendMessage,
  };
};

export default useWebsockets;

import { useCallback, useState } from "react";
import { InputAdornment, IconButton, TextField } from "@mui/material";
import {
  CloseOutlined as CloseIcon,
  SearchOutlined as SearchIcon,
} from "@mui/icons-material";

const SearchBar = ({ onChange, ...props }) => {
  const [search, setSearch] = useState("");

  const handleChange = useCallback(
    (e) => {
      setSearch(e.target.value);
      onChange?.(e.target.value);
    },
    [onChange]
  );

  const handleClear = useCallback(() => {
    setSearch("");
    onChange?.("");
  }, [onChange]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Escape") {
        handleClear();
      }
    },
    [handleClear]
  );

  return (
    <TextField
      onKeyDown={handleKeyDown}
      size="small"
      {...props}
      value={search}
      onChange={handleChange}
      InputProps={{
        autoComplete: "off",
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" sx={{ fontSize: 16, ml: -0.5 }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={{
                visibility: search?.length > 0 ? "visible" : "hidden",
              }}
              size="small"
              onClick={handleClear}
            >
              <CloseIcon fontSize="small" sx={{ fontSize: 16 }} />
            </IconButton>
          </InputAdornment>
        ),
        sx: {
          height: 25,
        },
      }}
      sx={{
        // disable the X button in the input (Chrome)
        "& input::-webkit-search-cancel-button": {
          display: "none",
        },
        ...(props.sx || {}),
      }}
    />
  );
};

export default SearchBar;

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Link from "../Link";
import { Alert, IconButton, Tooltip } from "@mui/material";
import { CopyAllOutlined as CopyAllIcon } from "@mui/icons-material";
import { useState } from "react";
import { Box } from "@mui/system";

const TableCellTitle = ({ children }) => {
  return (
    <TableCell sx={{ width: 100, padding: 1, border: 0 }}>
      <Typography variant="body1" fontWeight="bold">
        {children}
      </Typography>
    </TableCell>
  );
};

const TableCellContent = ({ children }) => {
  const [showCopy, setShowCopy] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(children);
  };

  return (
    <TableCell
      sx={{ padding: 0, border: 0, wordBreak: "break-all" }}
      onMouseEnter={() => setShowCopy(true)}
      onMouseLeave={() => setShowCopy(false)}
    >
      <Stack direction="row" alignItems="center">
        <Typography variant="body1">{children}</Typography>

        <Box>
          <Tooltip
            title={showCopy ? "Copy to clipboard" : null}
            arrow
            placement="right"
          >
            <IconButton
              size="small"
              sx={{ ml: 1, visibility: showCopy ? "visible" : "hidden" }}
              onClick={handleCopy}
            >
              <CopyAllIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
    </TableCell>
  );
};

const renderValue = (value) => {
  // strings are rendered as is
  // all other values are stringified (objects, booleans, arrays,...)
  if (typeof value === "string") {
    return value;
  }
  return JSON.stringify(value);
};

const UserInfo = ({ report }) => {
  const { user } = report;

  return (
    <Stack spacing={2}>
      {!user || typeof user !== "object" || Object.keys(user).length === 0 ? (
        <Alert severity="warning" variant="outlined">
          There is no user information available for this report. Make sure you
          are identifying your users. Grab the identify code and read more under
          Settings &gt; Tracking Code &gt;{" "}
          <Link to="/settings/code#identify-users">Identify Users</Link>.
        </Alert>
      ) : (
        <TableContainer data-test-id="browser-info-container">
          <Table>
            <TableBody>
              {Object.entries(user).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCellTitle align="right">{key}</TableCellTitle>
                  <TableCellContent>{renderValue(value)}</TableCellContent>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
};

export default UserInfo;

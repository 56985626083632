import { useCallback, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { usePreferences } from "../../contexts/UserPreferencesContext";
import {
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  BugReportOutlined as BugReportIcon,
  BuildOutlined as BuildIcon,
  CheckCircleOutline as CheckCircleIcon,
  DoNotDisturbOutlined as DoNotDisturbIcon,
} from "@mui/icons-material";
import { useAnalytics } from "../../analytics/AnalyticsContext";
import { Box } from "@mui/system";
import Confetti from "react-confetti";

export const STATUS_LIST = [
  {
    icon: <DoNotDisturbIcon fontSize="small" color="inherit" />,
    tooltip: "Flag as not a bug and ignore all future occurrences",
    description: "Not a bug",
    bugStatus: "not_a_bug",
    color: "success.main",
  },
  {
    icon: <BugReportIcon fontSize="small" color="inherit" />,
    tooltip: "Flag as a bug and move it to the Bug Tracker",
    description: "To Fix",
    bugStatus: "confirmed",
    color: "error.main",
  },
  {
    icon: <BuildIcon fontSize="small" color="inherit" />,
    description: "Fix in progress",
    tooltip: "Move it to the Bug Tracker's In Progress column",
    bugStatus: "in_progress",
    color: "primary.accent",
  },
  {
    icon: <CheckCircleIcon fontSize="small" color="inherit" />,
    description: "Resolved",
    tooltip: "Mark the bug as resolved",
    bugStatus: "fixed",
    color: "success.main",
  },
];

// const SelectPriority = ({ report, setReport }) => {
//   const { updateReport } = useAppContext();
//   const { enqueueSnackbar } = useSnackbar();
//   const { trackEvent } = useAnalytics();

//   const [selectedPriority, setSelectedPriority] = useState(null);

//   const handleUpdate = useCallback(
//     (e, priority) => {
//       setSelectedPriority(priority);

//       updateReport(
//         report,
//         {
//           priority,
//         },
//         setReport
//       )
//         .then(() => {
//           trackEvent("Update Bug Priority", {
//             priority,
//           });
//         })
//         .catch((err) => {
//           enqueueSnackbar("Error updating bug priority, please try again.", {
//             variant: "error",
//           });
//           throw err;
//         });
//     },
//     [report, setReport, updateReport, trackEvent, enqueueSnackbar]
//   );

//   const initialPriority = useMemo(
//     () =>
//       PRIORITY_LIST.map((i) => i.priority).includes(report.priority)
//         ? report.priority
//         : "",
//     [report]
//   );

//   return (
//     <Stack direction="row" spacing={1} alignItems="center">
//       <ToggleButtonGroup
//         value={selectedPriority || initialPriority}
//         exclusive
//         onChange={handleUpdate}
//         fullWidth
//         sx={{
//           boxShadow: "none",
//         }}
//       >
//         {PRIORITY_LIST.map((p, i) => (
//           <ToggleButton
//             key={i}
//             value={p.value}
//             sx={{
//               borderColor: "muted.border",

//               // selected bgcolor red
//               "&.Mui-selected": {
//                 backgroundColor: p.color,

//                 // icon
//                 "& span": {
//                   color: p.contrastColor,
//                 },

//                 // on hover do not change color
//                 "&:hover": {
//                   backgroundColor: p.color,
//                 },
//                 "&:active": {
//                   backgroundColor: p.color,
//                 },
//               },
//             }}
//           >
//             <Tooltip arrow title={p.description}>
//               {p.icon}
//             </Tooltip>
//           </ToggleButton>
//         ))}
//       </ToggleButtonGroup>
//     </Stack>
//   );
// };

export const SelectBugStatus = ({
  report,
  setReport,
  onTriage,
  ButtonProps = {},
  addons = [],
}) => {
  const { updateReport } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = useAnalytics();
  const { updatePreferences } = usePreferences();

  const [bugStatus, setBugStatus] = useState(null);
  const [confetti, setConfetti] = useState(false);

  const initialStatus = useMemo(
    () =>
      STATUS_LIST.map((i) => i.bugStatus).includes(report.bugStatus)
        ? report.bugStatus
        : "likely_a_bug",
    [report]
  );

  const onFixCelebrate = useCallback(() => {
    setConfetti(true);
  }, []);

  const handleUpdate = useCallback(
    async (e, newBugStatus) => {
      if (!newBugStatus) {
        newBugStatus = "likely_a_bug";
      }
      setBugStatus(newBugStatus);

      if (
        newBugStatus === "likely_a_bug" ||
        newBugStatus === "confirmed" ||
        newBugStatus === "in_progress"
      ) {
        onTriage?.(newBugStatus);
      }

      updateReport(
        report,
        {
          bugStatus: newBugStatus,
        },
        setReport
      )
        .then(() => {
          trackEvent("Update Bug Status", {
            bugStatus: newBugStatus,
          });

          if (newBugStatus === "fixed") {
            onFixCelebrate();
          }
        })
        .catch((err) => {
          enqueueSnackbar("Error updating bug status, please try again.", {
            variant: "error",
          });
          throw err;
        });

      updatePreferences({
        lastBugStatusAction: Date.now(),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report]
  );

  const confettiRef = useRef(null);

  const currentValue = useMemo(
    () => bugStatus || initialStatus,
    [bugStatus, initialStatus]
  );

  if (report.archived) {
    return (
      <Box>
        <Paper>
          <Box p={2}>
            <Typography variant="body2">
              This report has been archived. Archiving reports is a legacy
              feature and is no longer supported. Please use the bug status
              feature instead. If you wish to unarchive this report, please
              contact us.
            </Typography>
          </Box>
        </Paper>
      </Box>
    );
  }

  return (
    <Box>
      <Stack spacing={2}>
        <ToggleButtonGroup
          value={currentValue}
          exclusive
          onChange={handleUpdate}
          fullWidth
          sx={{
            boxShadow: "none",
          }}
        >
          {STATUS_LIST.map((status, i) => (
            <ToggleButton
              key={status.bugStatus}
              value={status.bugStatus}
              color="secondary"
              size="small"
              sx={{
                borderColor: "muted.main",

                // selected bgcolor red
                "&.Mui-selected": {
                  backgroundColor: status.color,

                  // icon
                  "& .MuiSvgIcon-root": {
                    color: "error.contrastText",
                  },

                  // on hover do not change color
                  "&:hover": {
                    backgroundColor: status.color,
                  },
                  "&:active": {
                    backgroundColor: status.color,
                    color: status.color,
                  },

                  // no border right except last one
                  borderRight: "none",
                },
              }}
              style={{ height: 36 }}
            >
              <Tooltip title={`${status.tooltip} [${i + 1}]`} arrow>
                {status.icon}
              </Tooltip>
            </ToggleButton>
          ))}

          {addons}
        </ToggleButtonGroup>

        {/* {currentValue &&
          currentValue !== "not_a_bug" &&
          currentValue !== "likely_a_bug" && (
            <SelectPriority report={report} setReport={setReport} />
          )} */}
      </Stack>

      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: "none",
        }}
      >
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            width: "100%",
            height: "100%",
            zIndex: 5000,
          }}
        >
          {confetti && (
            <Confetti
              height={window.innerHeight}
              ref={confettiRef}
              numberOfPieces={500}
              recycle={false}
              confettiSource={{
                x: 0,
                y: 0,
                w: window.innerWidth,
                h: 0,
              }}
              onConfettiComplete={() => {
                setConfetti(false);
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

import { ErrorOutlined as ErrorIcon } from "@mui/icons-material";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAnalytics } from "../analytics/AnalyticsContext";
import { useAppContext } from "../contexts/AppContext";
import { useIntegrations } from "../contexts/IntegrationsContext";
import useHelpdesk from "../hooks/useHelpdesk";

const CONNECT_STATUS = {
  OK: "OK",
  ERROR: "ERROR",
  IN_PROGRESS: "IN_PROGRESS",
};

const ConnectionInProgress = ({ connectMessage }) => {
  return (
    <Dialog open maxWidth="xs" fullWidth>
      <DialogContent>
        <Box p={4}>
          <Stack spacing={2} alignItems="center">
            <CircularProgress size={26} />
            <Typography
              variant="body1"
              color="text.secondary"
              textAlign="center"
            >
              {connectMessage}
            </Typography>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const ConnectionError = ({
  connectMessage,
  selectedCompanyId,
  appId,
  provider,
}) => {
  const { trackEvent } = useAnalytics();

  useLayoutEffect(() => {
    trackEvent("Integration Connect Error", {
      provider,
    });
  }, [trackEvent, provider]);

  return (
    <Stack spacing={2} alignItems="flex-start">
      <Box>
        <ErrorIcon fontSize="large" sx={{ color: "error.main" }} />
        <Typography variant="h6" mt={1} color="error">
          Integration install error
        </Typography>
      </Box>

      <Typography variant="body1">{connectMessage}</Typography>

      <Typography variant="code">
        Workspace ID: {selectedCompanyId}
        <br />
        App ID: {appId}
      </Typography>
    </Stack>
  );
};

const ConnectionSuccess = ({ connectMessage, provider }) => {
  const { trackEvent } = useAnalytics();
  const { enqueueSnackbar } = useSnackbar();

  useLayoutEffect(() => {
    trackEvent("Integration Connect Success", {
      provider,
    });

    enqueueSnackbar("Integration connected successfully", {
      variant: "success",
      autoHideDuration: 30 * 1000,
    });
  }, [trackEvent, provider, enqueueSnackbar]);

  return <Navigate to="/getting-started/code" />;
};

const ProviderConnectionPage = ({ provider, appId, state }) => {
  const [connectMessage, setConnectMessage] = useState(false);
  const [connectStatus, _setConnectStatus] = useState(
    CONNECT_STATUS.IN_PROGRESS
  );

  const setConnectStatus = useCallback((status) => {
    // delay by 3 seconds to avoid flickering
    setTimeout(() => {
      _setConnectStatus(status);
    }, 3000);
  }, []);

  const { setSelectedCompanyId, selectedCompanyId, companies } =
    useAppContext();

  const {
    intercomAppId,
    setIntercomAppId,
    setLiveChatOrganizationId,
    liveChatOrganizationId,
    crispWebsiteId,
    setCrispWebsiteId,
    zendeskSubdomain,
    setZendeskSubdomain,
    setFrontTeamId,
  } = useIntegrations();

  const {
    connectCrisp,
    connectIntercom,
    connectLiveChat,
    connectZendesk,
    connectFront,
  } = useHelpdesk();

  useEffect(() => {
    let [workspaceIdFromState, source] = state?.split(";") ?? [];
    if (
      workspaceIdFromState &&
      !companies.some(({ id }) => id === workspaceIdFromState)
    ) {
      workspaceIdFromState = null;
    }

    if (workspaceIdFromState && workspaceIdFromState !== selectedCompanyId) {
      setSelectedCompanyId(workspaceIdFromState);
      return;
    }

    // Do not attempt to connect if the user is already connected to a workspace
    //

    if (provider === "intercom" && intercomAppId) {
      console.warn(
        "Intercom integration is already set up for this workspace."
      );

      setConnectStatus(CONNECT_STATUS.OK);
      setConnectMessage("This workspace is already connected to Intercom.");

      if (source === "store") {
        setTimeout(() => {
          window.location.href =
            "https://app.intercom.io/appstore/redirect?install_success=true";
        }, 3000);
      }
      return;
    }

    if (provider === "livechat" && liveChatOrganizationId) {
      console.warn(
        "LiveChat integration is already set up for this workspace."
      );

      setConnectStatus(CONNECT_STATUS.OK);
      setConnectMessage("This workspace is already connected to LiveChat.");

      return;
    }

    if (provider === "crisp" && crispWebsiteId) {
      console.warn("Crisp integration is already set up for this workspace.");

      setConnectStatus(CONNECT_STATUS.OK);
      setConnectMessage("This workspace is already connected to Crisp.");

      return;
    }

    if (provider === "zendesk" && zendeskSubdomain) {
      console.warn("Zendesk integration is already set up for this workspace.");

      setConnectStatus(CONNECT_STATUS.OK);
      setConnectMessage("This workspace is already connected to Zendesk.");

      return;
    }

    const workspaceId = workspaceIdFromState || selectedCompanyId;

    // Connect with the provider
    //

    if (provider === "intercom") {
      setConnectStatus(CONNECT_STATUS.IN_PROGRESS);
      setConnectMessage("Connecting to Intercom...");

      connectIntercom({
        appId,
        workspaceId,
      })
        .then(({ appId }) => {
          setConnectStatus(CONNECT_STATUS.OK);
          setConnectMessage("Connected to Intercom");
          setIntercomAppId(appId);

          if (source === "store") {
            // Intercom: requests coming from the store must be
            // redirected back to the store
            setTimeout(() => {
              window.location.href =
                "https://app.intercom.io/appstore/redirect?install_success=true";
            }, 3000);
          }
        })
        .catch((error) => {
          console.error("Unexpected error in connectLiveChat()", error);
          setConnectStatus(CONNECT_STATUS.ERROR);
          setConnectMessage(`${error.message}`);

          if (source === "store") {
            // Intercom: requests coming from the store must be
            // redirected back to the store
            setTimeout(() => {
              window.location.href = `https://app.intercom.io/appstore/redirect?error_message=${error.message}`;
            }, 3000);
          }
        });
    }

    if (provider === "livechat") {
      setConnectStatus(CONNECT_STATUS.IN_PROGRESS);
      setConnectMessage("Connecting to LiveChat...");

      connectLiveChat({
        appId,
        workspaceId,
      })
        .then(({ organizationId }) => {
          setConnectStatus(CONNECT_STATUS.OK);
          setConnectMessage("Connected to LiveChat");
          setLiveChatOrganizationId(organizationId);
        })
        .catch((error) => {
          console.error("Unexpected error in connectLiveChat()", error);
          setConnectStatus(CONNECT_STATUS.ERROR);
          setConnectMessage(`${error.message}`);
        });
    }

    if (provider === "crisp") {
      setConnectStatus(CONNECT_STATUS.IN_PROGRESS);
      setConnectMessage("Connecting to Crisp...");

      connectCrisp({
        appId,
        workspaceId,
      })
        .then(({ websiteId }) => {
          setConnectStatus(CONNECT_STATUS.OK);
          setConnectMessage(
            "The connection with Crisp has been successfully completed."
          );
          setCrispWebsiteId(websiteId);
        })
        .catch((error) => {
          console.error("Unexpected error in connectCrisp()", error);
          setConnectStatus(CONNECT_STATUS.ERROR);
          setConnectMessage(`${error.message}`);
        });
    }

    if (provider === "zendesk") {
      setConnectStatus(CONNECT_STATUS.IN_PROGRESS);
      setConnectMessage("Connecting to Zendesk...");

      connectZendesk({
        subdomain: appId,
        workspaceId,
      })
        .then(({ subdomain }) => {
          setConnectStatus(CONNECT_STATUS.OK);
          setConnectMessage(
            "The connection with Zendesk has been successfully completed."
          );
          setZendeskSubdomain(subdomain);
        })
        .catch((error) => {
          console.error("Unexpected error in connectZendesk()", error);
          setConnectStatus(CONNECT_STATUS.ERROR);
          setConnectMessage(`${error.message}`);
        });
    }

    if (provider === "front") {
      setConnectStatus(CONNECT_STATUS.IN_PROGRESS);
      setConnectMessage("Connecting to Front...");

      connectFront({
        teamId: appId,
        workspaceId,
      })
        .then(({ teamId }) => {
          setConnectStatus(CONNECT_STATUS.OK);
          setConnectMessage(
            "The connection with Front has been successfully completed."
          );
          setFrontTeamId(teamId);
        })
        .catch((error) => {
          console.error("Unexpected error in connectFront()", error);
          setConnectStatus(CONNECT_STATUS.ERROR);
          setConnectMessage(`${error.message}`);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, provider, state, selectedCompanyId]);

  if (connectStatus === CONNECT_STATUS.IN_PROGRESS) {
    return (
      <ConnectionInProgress
        connectMessage={connectMessage}
        provider={provider}
      />
    );
  }

  if (connectStatus === CONNECT_STATUS.ERROR) {
    return (
      <ConnectionError
        appId={appId}
        connectMessage={connectMessage}
        selectedCompanyId={selectedCompanyId}
        provider={provider}
      />
    );
  }

  if (connectStatus === CONNECT_STATUS.OK) {
    return (
      <ConnectionSuccess connectMessage={connectMessage} provider={provider} />
    );
  }

  return false;
};

const ProviderConnectionPageContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const provider = params.get("provider");
  const state = params.get("state");
  const appId =
    params.get("appId") ??
    params.get("organizationId") ??
    params.get("websiteId") ??
    params.get("helpscoutCompanyId") ??
    params.get("zendeskSubdomain") ??
    params.get("teamId");

  const vercelIntegrationRedirect = params.get("next");
  if (vercelIntegrationRedirect) {
    // redirect to nextRedirect url
    navigate(vercelIntegrationRedirect);
  }

  return (
    <Box maxWidth="md">
      <ProviderConnectionPage provider={provider} appId={appId} state={state} />
    </Box>
  );
};

export default ProviderConnectionPageContainer;

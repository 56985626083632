import {
  Box,
  Typography,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  Button,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  ArrowRightAltOutlined as ArrowRightAltIcon,
  FavoriteOutlined as FavoriteIcon,
  PersonOutlined as PersonIcon,
  SentimentDissatisfiedOutlined as SentimentDissatisfiedIcon,
  SmartToyOutlined as SmartToyIcon,
} from "@mui/icons-material";

import { NoRowsWrapper } from "./NoRowsWrapper.js";
import useLoading from "../../hooks/useLoading";

export const NoRows_EmptyPendingReview_NewUser = ({
  onCreateSampleReport,
  currentView,
  setCurrentView,
}) => {
  const { handler: createSampleReportHandler, isLoading } =
    useLoading(onCreateSampleReport);

  return (
    <NoRowsWrapper
      icon={<FavoriteIcon />}
      title={"Welcome to New Issues!"}
      description={
        "Here you will find issues that need your attention. Use the filters at the top to select priority issues or user-reported bugs. You can review and assign status to each issue. Once a status is assigned, you'll find them in the Bug Tracker."
      }
    >
      <Box mt={-2} mb={2}>
        <List dense>
          <ListItem sx={{ paddingLeft: 0 }}>
            <ListItemText>
              <Typography variant="body1" fontWeight={"bold"}>
                Here you&apos;ll find:
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem sx={{ paddingLeft: 0 }}>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Bugs reported by your users</ListItemText>
          </ListItem>

          {currentView !== "user-reported" && (
            <ListItem sx={{ paddingLeft: 0 }}>
              <ListItemIcon>
                <SmartToyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                Critical bug reports detected by Bugpilot
              </ListItemText>
            </ListItem>
          )}

          {currentView === "all" && (
            <ListItem sx={{ paddingLeft: 0 }}>
              <ListItemIcon>
                <SentimentDissatisfiedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                Low priority bugs that might not be worth your time
              </ListItemText>
            </ListItem>
          )}
        </List>
      </Box>
      <div>
        <Stack direction="row" spacing={1}>
          <LoadingButton
            variant="outlined"
            onClick={createSampleReportHandler}
            loading={isLoading}
            disabled={isLoading}
            size="small"
            color="secondary"
          >
            Create sample report
          </LoadingButton>

          {currentView !== "all" && (
            <Button
              variant="link"
              size="small"
              color="secondary"
              onClick={() => setCurrentView("all")}
              endIcon={<ArrowRightAltIcon />}
            >
              View all issues
            </Button>
          )}
        </Stack>
      </div>
    </NoRowsWrapper>
  );
};

import { Box } from "@mui/material";
import { GetTrackingCode } from "./GettingStarted/GetTrackingCode";

const TrackingCodeSettingPage = () => {
  return (
    <Box mt={3}>
      <GetTrackingCode />
    </Box>
  );
};

export default TrackingCodeSettingPage;

import { Box, Stack, Typography } from "@mui/material";

export const NoRowsWrapper = ({
  children,
  icon,
  title,
  description,
  stackProps = {},
  paragraphProps = {},
}) => (
  <Stack spacing={2} {...stackProps}>
    <Box pb={2}>{icon}</Box>
    <Typography variant="h6" {...paragraphProps}>
      {title}
    </Typography>
    <Typography variant="body1" {...paragraphProps}>
      {description}
    </Typography>
    {children}
  </Stack>
);

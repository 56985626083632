import {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";
import compose from "lodash/fp/compose";
import fpFilter from "lodash/fp/filter";
import fpOrderBy from "lodash/fp/orderBy";
import fpMap from "lodash/fp/map";
import { keyBy, negate, sortBy, times } from "lodash";

import * as colors from "@mui/material/colors";
import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  Skeleton,
} from "@mui/material";

import Link from "@mui/material/Link";

import ActionsTableRow, {
  isTypeFetch,
  isTypeFetchResponse,
} from "./ActionsTableRow";
import Value from "./shared/Value";
import SimpleDialog from "../SimpleDialog";

// import { useAppContext } from "../../contexts/AppContext";
// import {
//   getMutedError,
//   prepareMutedErrorConsoleArgs,
// } from "../../utils/mutedErrors";

import { getReportActivities as getReportActivitiesAPI } from "../../api";

import SearchBar from "../SearchBar";
import { getTargetName } from "./StepsToReproduce/utils.tsx";
import { usePreferences } from "../../contexts/UserPreferencesContext";
import { VALID_PLAYER_ORIGIN } from "./PlayerContainer.tsx";
import {
  CropSquareOutlined as CropSquareIcon,
  MaximizeOutlined as MaximizeIcon,
  MinimizeOutlined as MinimizeIcon,
} from "@mui/icons-material";

const consoleMethodsMap = {
  error: "error",
  warn: "warning",
};

const getResourceStatus = (row) => {
  if (row.data?.responseStatus > 0 && row.data?.duration === 0) {
    return "cached";
  }

  if (
    row.data?.responseStatus === 0 &&
    row.data?.duration === 0 &&
    row.data?.transferSize === 0
  ) {
    return "blocked";
  }

  return "ok";
};

const getRowClassName = (type, data) => {
  if (type === "console") {
    return consoleMethodsMap[data.method] || "ok";
  }

  if (type === "fetch") {
    const responseOk =
      data?.response?.ok &&
      data?.response?.status >= 200 &&
      data?.response?.status < 300;

    return responseOk ? "ok" : "error";
  }

  if (type === "pointer" || type === "navigate") {
    return "ok";
  }

  if (type === "resource") {
    return getResourceStatus({ data });
  }
};

const getLogsColumns = ({ wrapLines, compact, report }) => [
  {
    field: "message",
    headerName: "Message",
    type: "string",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <ActionsTableRow
          item={params.row}
          wrapLines={wrapLines}
          compact={compact}
          report={report}
        />
      );
    },
  },
];

const getResourcesColumns = () => [
  {
    field: "url",
    headerName: "URL",
    type: "url",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      const url = params.row.data?.url ?? "";
      return (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            // truncate text
            width: "100%",
          }}
        >
          <Tooltip title={url} arrow>
            <Link
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              variant="code"
              sx={{
                color: "inherit",
                textDecoration: "underline",
                // truncate text
                display: "block",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {url}
            </Link>
          </Tooltip>
        </Stack>
      );
    },
  },
  {
    field: "initiatorType",
    headerName: "Initiator",
    type: "string",
    sortable: false,
    width: 100,
    renderCell: ({ row }) => {
      return <Typography variant="code">{row.data?.initiatorType}</Typography>;
    },
  },
  {
    field: "duration",
    headerName: "Duration",
    sortable: false,
    width: 100,
    renderCell: ({ row }) => {
      return (
        <Typography
          variant="code"
          sx={{
            color: (theme) =>
              row.data?.duration > 1000 ? theme.palette.error.main : "initial",
          }}
        >
          {row.data?.duration
            ? `${new Intl.NumberFormat().format(
                Math.ceil(row.data?.duration)
              )} ms`
            : "n/a"}
        </Typography>
      );
    },
  },
  {
    field: "size",
    headerName: "Size",
    sortable: false,
    width: 100,
    renderCell: ({ row }) => {
      const status = getResourceStatus(row);

      if (status === "cached") {
        return <Typography variant="code">cached</Typography>;
      }

      if (status === "blocked") {
        return (
          <Typography variant="code" color="error">
            cancelled
          </Typography>
        );
      }

      if (row.data?.transferSize === 0) {
        return (
          <Typography
            variant="code"
            sx={{
              color: colors.grey[500],
            }}
          >
            empty
          </Typography>
        );
      }

      return (
        <Typography variant="code">
          {Number(row.data?.transferSize / 1000).toFixed(2)} KB
        </Typography>
      );
    },
  },
];

const NoRows = ({ type }) => {
  return (
    <Stack
      sx={{
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="body1">
        {type === "logs"
          ? "No logs were recorded"
          : "No network requests were recorded"}
      </Typography>
    </Stack>
  );
};

export const CONSOLE_SIZES = {
  small: 0,
  medium: 200,
  large: 450,
};

const ActionsTable = ({
  report,
  reportContents,
  isReportLoading,
  type = "logs",
  isLive,
  initialState = {},
  containerRef,
  compact = false,
  syncScroll,
  setActionsTableHeight,
}) => {
  // const { muteError, unmuteError, mutedErrors } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const { preferences, updatePreferences } = usePreferences();

  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);

  const [search, setSearch] = useState("");
  const deferredSearch = useDeferredValue(search);
  const handleSearchChange = useCallback((value) => setSearch(value), []);

  const [showErrors, setShowErrors] = useState(
    initialState.showErrors ?? false
  );

  const [showNetwork, setShowNetwork] = useState(
    initialState.showNetwork ?? false
  );

  useEffect(() => {
    setShowErrors(initialState.showErrors ?? false);
    setShowNetwork(initialState.showNetwork ?? false);
  }, [initialState.showErrors, initialState.showNetwork]);

  const handleShowErrorsChange = useCallback((next) => {
    setShowErrors(next);
    setShowNetwork(false);
  }, []);

  const handleShowNetworkChange = useCallback((next) => {
    setShowNetwork(next);
    setShowErrors(false);
  }, []);

  const [showSource, setShowSource] = useState(null);
  const [wrapLines, setWrapLines] = useState(preferences.wrapLines ?? true);
  const [consoleSize, setConsoleSize] = useState(
    preferences.consoleSize ?? CONSOLE_SIZES.medium
  );

  useEffect(() => {
    setActionsTableHeight?.(consoleSize);
  }, [consoleSize, setActionsTableHeight]);

  const handleConsoleSizeChange = useCallback(
    (e, next) => {
      if (!next) {
        return;
      }
      setConsoleSize(next);
      updatePreferences({ consoleSize: next });
    },
    [updatePreferences]
  );

  const handleWrapLinesChange = useCallback(
    (next) => {
      setWrapLines(next);
      updatePreferences({ wrapLines: next });
    },
    [updatePreferences]
  );

  useEffect(() => {
    if (isLive) {
      return;
    }

    if (!report) {
      return;
    }

    setActivities([]);

    if (report.eventsVersion === "20230710") {
      if (!reportContents) {
        setActivities([]);
        setLoading(false);
        return;
      }

      const nextActivities = reportContents.filter(
        (r) => r.eventType === "activities"
      );
      setActivities(nextActivities);
      setLoading(false);
      return;
    }

    const fetchActivities = async () => {
      if (!report.activities) {
        setActivities([]);
        setLoading(false);
        return;
      }

      let activities = await getReportActivitiesAPI(report);

      activities = sortBy(activities, "timestamp");

      setActivities(activities);
      setLoading(false);
    };

    fetchActivities().catch((err) => {
      enqueueSnackbar("Error fetching report activities", {
        variant: "error",
        autoHideDuration: 5 * 1000,
      });
      console.error("Fetch report activities error", err);
      return [];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report, reportContents, report.activities, enqueueSnackbar]);

  const preparedActivities = useMemo(() => {
    if (!activities) return [];

    const fetchResponses = keyBy(
      activities.filter((r) => r.type === "fetchResponse"),
      "sourceRecordingId"
    );

    const injectFetchResponse = (recording) => {
      const data = isTypeFetch(recording.type)
        ? {
            ...recording.data,
            response: fetchResponses[recording.id]?.data || {},
          }
        : false;

      return {
        ...recording,
        ...(data ? { data } : {}),
      };
    };

    return compose(
      fpOrderBy(["timestamp"], ["asc"]),
      fpFilter(({ type, data }) => {
        let searchString = "";

        if (["fetch", "resource", "navigate"].includes(type)) {
          searchString = data?.url;
        }

        if (["pointer", "pointer-blur"].includes(type)) {
          searchString = getTargetName(data?.target);
        }

        if (type === "console") {
          searchString = data.args
            .filter(Boolean)
            .map((arg) => arg.toString())
            .join(" ");
        }

        const matchesSearch =
          !deferredSearch ||
          searchString.toLowerCase().includes(deferredSearch?.toLowerCase());

        if (showErrors) {
          const isConsoleError =
            data?.method === "error" || data?.method === "warn";

          const isNetworkError =
            data?.initiator === "fetch" &&
            (!data?.response || !data?.response.ok);

          return matchesSearch && (isConsoleError || isNetworkError);
        } else if (showNetwork) {
          const isNetwork =
            data?.initiator === "fetch" || data?.initiator === "xmlhttprequest";
          return matchesSearch && isNetwork;
        } else {
          return matchesSearch;
        }
      }),
      fpMap(injectFetchResponse),
      fpFilter(({ type }) => type !== "resource"),
      fpFilter(
        negate((r) => isTypeFetchResponse(r.type) || r.type === "adopto-report")
      )
    )(activities || []);
  }, [activities, deferredSearch, showErrors, showNetwork]);

  const resourcesActivities = useMemo(() => {
    if (!report || !activities) {
      return [];
    }

    return compose(
      fpOrderBy(["timestamp"], ["desc"]),
      fpFilter(
        ({ data }) => !deferredSearch || data?.url?.includes(deferredSearch)
      ),
      fpFilter(
        ({ type, data }) =>
          type === "resource" && data?.initiatorType !== "fetch"
      )
    )(activities ?? []);
  }, [report, activities, deferredSearch]);

  const columns = useMemo(
    () => [
      // logs display the message
      ...(type === "logs"
        ? getLogsColumns({ wrapLines, compact, report })
        : getResourcesColumns({})),
    ],
    [compact, type, wrapLines]
  );

  const [playerSeekTime, setPlayerSeekTime] = useState(0);

  useEffect(() => {
    const onMsg = (msg) => {
      if (msg.origin !== VALID_PLAYER_ORIGIN) {
        return;
      }

      if (!msg.data.type) {
        return;
      }

      const data = msg.data;

      if (data.type === "io.bugpilot.player::tick") {
        setPlayerSeekTime(data.timestamp);
      }
    };

    window.addEventListener("message", onMsg);

    return () => {
      window.removeEventListener("message", onMsg);
    };
  }, [report, report.id, report.recordings]);

  const rows = useMemo(() => {
    return type === "logs" ? preparedActivities : resourcesActivities;
  }, [preparedActivities, resourcesActivities, type]);

  const apiRef = useGridApiRef(null);

  useEffect(() => {
    // scroll to the first row having timestamp > playerSeekTime
    const index = rows.findIndex((r) => r.timestamp > playerSeekTime);
    if (index > 0) {
      apiRef?.current?.scrollToIndexes?.({
        // rowIndex: Math.min(index + 2, rows.length - 1),
        rowIndex: Math.max(0, index),
      });
    }
  }, [apiRef, playerSeekTime, rows]);

  return (
    <Stack
      spacing={1}
      sx={{
        height: "100%",
      }}
    >
      {compact ? null : (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <ToggleButtonGroup
            exclusive
            color="primary"
            size="small"
            variant="outlined"
            value={showErrors ? "errors" : showNetwork ? "network" : "all"}
            disabled={isReportLoading || loading}
          >
            <ToggleButton
              onClick={() => handleShowErrorsChange(false)}
              value="all"
            >
              All
            </ToggleButton>
            <ToggleButton
              onClick={() => handleShowNetworkChange(true)}
              value="network"
            >
              Network
            </ToggleButton>
            <ToggleButton
              onClick={() => handleShowErrorsChange(true)}
              value="errors"
            >
              Errors
            </ToggleButton>
          </ToggleButtonGroup>

          <SearchBar
            onChange={handleSearchChange}
            disabled={isReportLoading || loading}
          />

          <Box sx={{ flexGrow: 1 }} />

          <ToggleButtonGroup
            exclusive
            color="primary"
            size="small"
            variant="outlined"
            value={consoleSize}
            onChange={handleConsoleSizeChange}
          >
            <ToggleButton value="small">
              <MinimizeIcon
                fontSize="small"
                sx={{
                  fontSize: 12,
                }}
              />
            </ToggleButton>
            <ToggleButton value="medium">
              <CropSquareIcon
                fontSize="small"
                sx={{
                  fontSize: 12,
                }}
              />
            </ToggleButton>
            <ToggleButton value="large">
              <MaximizeIcon
                fontSize="small"
                sx={{
                  fontSize: 12,
                }}
              />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      )}

      {consoleSize !== "small" && (
        <Box
          sx={{
            minHeight: compact ? undefined : CONSOLE_SIZES[consoleSize],
            height: "100%",
          }}
        >
          <DataGridPro
            apiRef={apiRef}
            rowBuffer={10}
            rows={rows}
            getRowClassName={({ row }) => {
              const { type, data, timestamp } = row;

              const className = getRowClassName(type, data);

              if (playerSeekTime && playerSeekTime <= timestamp) {
                return `${className} future`;
              }

              return className;
            }}
            hideFooter
            columns={columns}
            isRowSelectable={() => false}
            getRowHeight={() => "auto"}
            headerHeight={0}
            disableMultipleColumnsSorting
            loading={isReportLoading || loading}
            components={{
              NoRowsOverlay: () => <NoRows type={type} />,
              LoadingOverlay: () => (
                <Box pt={1} pb={1} pl={2} pr={2}>
                  {times(6, (i) => (
                    <Skeleton key={i} height={30} width="100%" />
                  ))}
                </Box>
              ),
            }}
            sx={{
              // smooth scroll
              "& .MuiDataGrid-window": {
                scrollBehavior: "smooth",
              },

              "& .MuiDataGrid-cell": {
                padding: "2px 2px",
              },
              "& .MuiDataGrid-cell:first-child": {
                paddingLeft: 1,
              },
              "& .MuiDataGrid-cell:last-child": {},

              // do not highlight row on hover
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "unset",
              },

              // row with invisible
              // border left 4px to fix alignment
              "& .MuiDataGrid-row": {
                borderLeftWidth: 4,
                borderLeftStyle: "solid",
                borderLeftColor: "transparent",
              },

              // min row height 60
              ".ok": {},
              ".warning": {
                borderLeftColor: colors.yellow[500],
                backgroundColor: colors.yellow[50] + "A0",
              },
              ".error": {
                // style for error logs and failed network requests
                borderLeftColor: colors.red[500],
                backgroundColor: colors.red[50] + "A0",
              },

              ".future": {
                opacity: 0.1,
              },
            }}
          />
        </Box>
      )}

      {showSource && (
        <SimpleDialog title="Source" onClose={() => setShowSource(null)}>
          <Value value={showSource} collapsed={false} />
        </SimpleDialog>
      )}
    </Stack>
  );
};

export default ActionsTable;

import { useCallback, useDeferredValue, useMemo, useState } from "react";
import {
  ListItemText,
  Menu,
  MenuItem,
  Divider,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  ExpandLessOutlined as ExpandLessIcon,
  ExpandMoreOutlined as ExpandMoreIcon,
  SearchOutlined as SearchIcon,
} from "@mui/icons-material";

import { useAppContext } from "../../contexts/AppContext";
import { useModals } from "../../hooks/useModals";
import { useAnalytics } from "../../analytics/AnalyticsContext";
import { DRAWER_WIDTH } from "./MenuSidebar";
import { useSnackbar } from "notistack";

export const WorkspaceSelectDropdownMenu = ({
  anchorEl = null,
  noPreselect = null,
}) => {
  const {
    companies: _companies,
    setSelectedCompanyId,
    selectedCompanyId,
    createCompany,
  } = useAppContext();
  const { trackEvent } = useAnalytics();
  const { enqueueSnackbar } = useSnackbar();
  const modals = useModals();

  const [search, setSearch] = useState("");
  const deferredSearch = useDeferredValue(search);

  const [isCustomersCompaniesExpanded, setIsCustomersCompaniesExpanded] =
    useState(false);

  const companies = useMemo(
    () => _companies.filter(({ isExternalCompany }) => !isExternalCompany),
    [_companies]
  );

  const customerCompanies = useMemo(
    () => _companies.filter(({ isExternalCompany }) => isExternalCompany),
    [_companies]
  );

  const filteredCustomerCompanies = useMemo(
    () =>
      customerCompanies.filter(
        ({ name, id }) =>
          !deferredSearch ||
          name.toLowerCase().includes(deferredSearch.toLowerCase()) ||
          id.toLowerCase().includes(deferredSearch.toLowerCase())
      ),
    [customerCompanies, deferredSearch]
  );

  const canCreateNewWorkspaces = useMemo(
    () =>
      companies.some(
        ({ planId, role }) => planId === "Enterprise" && role === "owner"
      ),
    [companies]
  );

  const handleNewCompanyCreate = useCallback(async () => {
    if (!canCreateNewWorkspaces) {
      enqueueSnackbar(
        "Only Enterprise plan customers can create additional workspaces.",
        {
          variant: "error",
        }
      );
      return;
    }

    modals.openModal(modals.modalTypes.CreateCompany, {
      onConfirm: async (values) => {
        await createCompany(values);
      },
    });
  }, [canCreateNewWorkspaces, createCompany, enqueueSnackbar, modals]);

  const handleWorkspaceChange = useCallback(
    (id) => () => {
      const url = new URL(window.location.href);
      if (url.searchParams.has("companyId")) {
        url.searchParams.delete("companyId");
        window.location.replace(url.toString());
      }

      setSelectedCompanyId(id);
      trackEvent("Workspace Change", { workspace_id: id });
    },
    [setSelectedCompanyId, trackEvent]
  );

  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      sx={{
        "& .MuiMenu-paper": {
          width: DRAWER_WIDTH - 32,
          maxWidth: "100%",
        },
      }}
    >
      {noPreselect && (
        <MenuItem dense value="--select">
          <>Change workspace...</>
        </MenuItem>
      )}
      {companies.map(({ id, name }) => (
        <MenuItem
          onClick={handleWorkspaceChange(id)}
          value={id}
          key={id}
          selected={id === selectedCompanyId}
        >
          <ListItemText primary={name} />
        </MenuItem>
      ))}
      <Divider />
      {customerCompanies.length > 0 && (
        <>
          <Typography
            variant="caption"
            color="tertiary.main"
            ml={2}
            mb={2}
            sx={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              setIsCustomersCompaniesExpanded((isExpanded) => !isExpanded);
            }}
          >
            Other Workspaces{" "}
            <IconButton size="small">
              {isCustomersCompaniesExpanded ? (
                <ExpandLessIcon fontSize="small" />
              ) : (
                <ExpandMoreIcon fontSize="small" />
              )}
            </IconButton>
          </Typography>
          {isCustomersCompaniesExpanded && (
            <>
              <TextField
                size="small"
                margin="none"
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                sx={{ ml: 2, mr: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {filteredCustomerCompanies.map(({ id, name }) => (
                <MenuItem
                  onClick={handleWorkspaceChange(id)}
                  value={id}
                  key={id}
                  selected={id === selectedCompanyId}
                >
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </>
          )}
          <Divider />
        </>
      )}

      <MenuItem onClick={handleNewCompanyCreate}>
        <ListItemText primary="New workspace..." />
      </MenuItem>
    </Menu>
  );
};

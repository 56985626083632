import React, { createContext, useContext, useState } from "react";
import useReportsListeners from "../hooks/useReportsListeners";

// README:
// =======
//
// This context is used to cache the reports list data
// so that when the user navigates back to the reports list
// from a report, the list does not have to be fetched again.
//
// Also, this list is used to implement Back, Next, and Previous
// buttons on the report page.
//
// This context is shared between ReportsList, BugTracker, and
// Report pages.
//

const ReportsListCacheContext = createContext({
  cachedReportsList: [],
  setCachedReportsList: () => {},
  previousReportsView: "",
  setPreviousReportsView: () => {},
});

export const ReportsListCacheProvider = ({ children }) => {
  const [cachedReportsList, setCachedReportsList] = useState([]);
  const [previousReportsView, setPreviousReportsView] = useState("");

  useReportsListeners({
    setReports: setCachedReportsList,
    label: "cachedReportsList",
  });

  return (
    <ReportsListCacheContext.Provider
      value={{
        cachedReportsList,
        setCachedReportsList,
        previousReportsView,
        setPreviousReportsView,
      }}
    >
      {children}
    </ReportsListCacheContext.Provider>
  );
};

export const useReportsListCache = () => useContext(ReportsListCacheContext);

import { Stack, TextField, Button } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useSnackbar } from "notistack";
import { useAuth } from "../contexts/AuthContext";
import { usePreferences } from "../contexts/UserPreferencesContext";
import ButtonDialog from "../components/ButtonDialog";

export const ShowSupportInfoButton = ({ ...props }) => {
  const { user, userAttributes } = useAuth();
  const { preferences, preferencesLoading, updatePreferences } =
    usePreferences();

  const { enqueueSnackbar } = useSnackbar();

  return (
    <ButtonDialog
      title="Advanced support info"
      buttonLabel="Advanced support info"
      {...props}
    >
      <Stack spacing={4}>
        <TextField label="User Sub" disabled size="small" value={user?.sub} />

        <TextField
          id="preferences-textarea"
          defaultValue={
            preferencesLoading
              ? "Loading..."
              : JSON.stringify(preferences, null, 2)
          }
          multiline
          label="User Preferences"
          size="small"
          InputProps={{
            endAdornment: !preferencesLoading && (
              <InputAdornment position="end">
                <Button
                  color="primary"
                  size="small"
                  onClick={() => {
                    updatePreferences(
                      JSON.parse(
                        document.getElementById("preferences-textarea").value
                      )
                    )
                      .then(() => {
                        enqueueSnackbar("Preferences updated", {
                          variant: "success",
                        });
                      })
                      .catch((err) => {
                        enqueueSnackbar(err.message, {
                          variant: "error",
                        });
                      });
                  }}
                >
                  Save
                </Button>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          id="preferences-textarea2"
          value={user == null ? "No user" : JSON.stringify(user, null, 2)}
          multiline
          readonly
          label="User Info"
          size="small"
        />

        <TextField
          id="preferences-textarea3"
          value={
            user == null
              ? "No attributes"
              : JSON.stringify(userAttributes, null, 2)
          }
          multiline
          readonly
          label="User Attributes"
          size="small"
        />
      </Stack>
    </ButtonDialog>
  );
};

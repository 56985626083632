import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";

const Loader = ({
  title,
  subtitle,
  size = 24,
  color = "secondary",
  ...props
}) => (
  <Box
    {...props}
    sx={{
      height: "100vh",
      width: "100%",
      ...props.sx,
    }}
  >
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100%" }}
      spacing={2}
    >
      <CircularProgress size={size} color={color} />

      <Box
        sx={{
          textAlign: "center",
        }}
      >
        {title ? (
          <Typography variant="body1" color={`text.${color}`} gutterBottom>
            {title}
          </Typography>
        ) : null}

        {subtitle ? (
          <Typography variant="caption" color="text.tertiary">
            {subtitle}
          </Typography>
        ) : null}
      </Box>
    </Stack>
  </Box>
);

export default Loader;

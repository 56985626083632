import { useEffect, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";

const KEY = "Bugpilot::providerRedirectPath";

export const CaptureProviderConnectionCallback = ({ children }) => {
  // IMPORTANT:
  // This component is rendered at the top of the tree, before
  // any app data is available. You cannot use any context here.

  const location = useLocation();

  const provider = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("provider");
  }, [location.search]);

  useEffect(() => {
    if (!provider) {
      return;
    }

    if (location.pathname.startsWith("/connect/")) {
      return;
    }

    const redirectUrl = `/connect/${provider}/callback${location.search}`;
    localStorage.setItem(KEY, redirectUrl);
  }, [location.search, provider]);

  return children;
};

export const RedirectProviderConnectionCallback = ({ children }) => {
  const redirectUrl = useMemo(() => localStorage.getItem(KEY), []);

  if (redirectUrl) {
    localStorage.removeItem(KEY);
    return <Navigate to={redirectUrl} />;
  }

  return children;
};

import { Button, Stack, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ margin: "auto" }}>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ margin: "auto", height: "100vh" }}
        spacing={2}
      >
        <Typography variant="h5">Sorry, this page does not exist :(</Typography>

        <Button
          variant="contained"
          color="secondary"
          startIcon={<HomeIcon />}
          onClick={() => navigate("/")}
        >
          Go home
        </Button>
      </Stack>
    </Box>
  );
};

export default NotFoundPage;

import { SvgIcon } from "@mui/material";

const BitbucketIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 62.42 62.42">
    <defs>
      <linearGradient
        id="a"
        x1={64.01}
        x2={32.99}
        y1={30.27}
        y2={54.48}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.18} stopColor="#0052cc" />
        <stop offset={1} stopColor="#2684ff" />
      </linearGradient>
    </defs>
    <g data-name="Layer 2">
      <path
        fill="#2684ff"
        d="M2 3.13a2 2 0 0 0-2 2.32l8.49 51.54a2.72 2.72 0 0 0 2.66 2.27h40.73a2 2 0 0 0 2-1.68l8.49-52.12a2 2 0 0 0-2-2.32Zm35.75 37.25h-13l-3.52-18.39H40.9Z"
      />
      <path
        fill="url(#a)"
        d="M59.67 25.12H40.9l-3.15 18.39h-13L9.4 61.73a2.71 2.71 0 0 0 1.75.66h40.74a2 2 0 0 0 2-1.68Z"
        transform="translate(0 -3.13)"
      />
    </g>
  </SvgIcon>
);

export default BitbucketIcon;

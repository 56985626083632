import InputAdornment from "@mui/material/InputAdornment";
import MuiLink from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import CopyToClipboardBase from "../shared/CopyToClipboardBase";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
import CopyIcon from "../../icons/CopyIcon";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

const HelpScoutModal = ({ onClose, callbackUrl, secret }) => {
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>HelpScout integration</DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="body1">
              To set up the HelpScount integration, go to{" "}
              <MuiLink
                target="_blank"
                href="https://secure.helpscout.net/apps/custom/"
                rel="noreferrer noopener"
              >
                https://secure.helpscout.net/apps/custom/
              </MuiLink>{" "}
              {`and create a new Help Scout custom app. Select the "Dynamic Content" as content type, tick the mailbox checkbox, and use the values below to fill the form:`}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <CopyToClipboardBase text="Bugpilot">
              {({ copy, isCopied }) => (
                <TextField
                  fullWidth
                  readonly
                  label="App Name"
                  value="Bugpilot"
                  InputProps={{
                    endAdornment: isCopied ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <InputAdornment position="end">
                        <IconButton onClick={copy}>
                          <CopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </CopyToClipboardBase>
          </Grid>

          <Grid item xs={12}>
            <CopyToClipboardBase text={callbackUrl}>
              {({ copy, isCopied }) => (
                <TextField
                  fullWidth
                  readonly
                  label="Callback Url"
                  value={callbackUrl}
                  InputProps={{
                    endAdornment: isCopied ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <InputAdornment position="end">
                        <IconButton onClick={copy}>
                          <CopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </CopyToClipboardBase>
          </Grid>

          <Grid item xs={12}>
            <CopyToClipboardBase text={secret}>
              {({ copy, isCopied }) => (
                <TextField
                  fullWidth
                  readonly
                  label="Secret key"
                  value={secret}
                  InputProps={{
                    endAdornment: isCopied ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <InputAdornment position="end">
                        <IconButton onClick={copy}>
                          <CopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </CopyToClipboardBase>
          </Grid>

          <Grid item xs={12}>
            <Alert severity="warning">
              Make sure you&apos;re sending users&apos; email addresses to the{" "}
              <code>Bugpilot.identify</code> call . This is required for the
              integration to work.
            </Alert>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          type="submit"
          variant="contained"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HelpScoutModal;

export const getTargetName = (target) => {
  return (
    target.name ||
    target.text ||
    target.value ||
    target.placeholder ||
    target.dataAttrs?.["aria-label"] ||
    target.dataAttrs?.["data-test-id"] ||
    target.dataAttrs?.["data-testid"] ||
    target.id ||
    target.tagName
  );
};

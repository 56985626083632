import React, { useCallback, useEffect, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
} from "@mui/material";

import Button from "../Button";

const ConfirmModal = ({
  onClose,
  onConfirm,
  text,
  confirmCTAHeader = "Confirm",
  confirmCTATitle = "Yes, I’m sure",
  closeCTATitle = "Cancel",
  confirmCTAColor = "primary",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleConfirm = useCallback(
    async (...args) => {
      if (typeof onConfirm !== "function") {
        return;
      }

      setIsLoading(true);
      try {
        await onConfirm(...args);

        if (typeof onClose !== "function") {
          return;
        }
        onClose();
      } finally {
        setIsLoading(false);
      }
    },
    [onClose, onConfirm]
  );

  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        onClose();
      } else if (e.key === "Enter") {
        handleConfirm();
      }
    };

    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs">
      <DialogTitle>
        <Typography color={confirmCTAColor}>{confirmCTAHeader}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          onClick={onClose}
          color="primary"
          style={{ textTransform: "uppercase" }}
        >
          {closeCTATitle}
        </Button>
        <Button
          color={confirmCTAColor}
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={25} />}
          onClick={handleConfirm}
          autoFocus
          style={{ textTransform: "uppercase" }}
        >
          {confirmCTATitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;

import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";

import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText,
  DialogTitle as MuiDialogTitle,
} from "@mui/material";

import { useAuth } from "../../contexts/AuthContext";
import Button from "../Button";

const DialogActions = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(4)}`,
  },
}))(MuiDialogActions);

const DialogContent = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(4)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogTitle);

const StyledBackdrop = withStyles({
  root: {
    background:
      "linear-gradient(317.41deg, #F0F5FE 21.54%, rgba(240, 245, 254, 0.8) 103.27%)",
    backdropFilter: "blur(2px)",
  },
})(Backdrop);

const PaymentSuccessModal = ({ onClose }) => {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const handleStart = useCallback(() => {
    navigate("/?payment-success=1");
    onClose();
  }, [navigate, location]);

  return (
    <Dialog BackdropComponent={StyledBackdrop} maxWidth="sm" open fullWidth>
      <DialogTitle>
        <Typography variant="h5" fontWeight="bold">
          Account is ready, {user.given_name} {user.family_name}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Come on in! Your subscription is active now.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button bold variant="contained" onClick={handleStart} fullWidth>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Let's Go!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentSuccessModal;

import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Stack } from "@mui/system";
import {
  Box,
  List,
  IconButton,
  Typography,
  useMediaQuery,
  AppBar,
  Toolbar,
  SwipeableDrawer,
  Button,
  ListItem,
} from "@mui/material";

import {
  MenuOutlined as MenuIcon,
  MonitorHeartOutlined as MonitorHeartOutlinedIcon,
  ReportProblemOutlined as ReportProblemOutlinedIcon,
  BugReportOutlined as BugReportOutlinedIcon,
  TaskOutlined as TaskOutlinedIcon,
  BuildOutlined as BuildOutlinedIcon,
  ExtensionOutlined as ExtensionOutlinedIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  PeopleOutlined as PeopleOutlinedIcon,
  SupportAgentOutlined as SupportAgentOutlinedIcon,
  HelpCenterOutlined as HelpCenterOutlinedIcon,
  CampaignOutlined as CampaignOutlinedIcon,
  ViewWeekOutlined as ViewWeekOutlinedIcon,
} from "@mui/icons-material";

import { MenuSectionTitle, MenuButtonItem } from "./MenuSectionTitle";
import { SelectWorkspaceMenu } from "./SelectWorkspaceMenu";
import { SidebarChecklist } from "../../pages/GettingStarted/SidebarChecklist";
import { useAppContext } from "../../contexts/AppContext";
import { TopAppBar } from "./TopAppBar.tsx";
import { UpgradeButton } from "./UpgradeButton";
import { useGlobalHotKeys } from "../GlobalHotKeysContext.tsx";
import UnreadsCounter from "../../components/UnreadsCounter";

export const DRAWER_WIDTH = 220;

const MenuSidebar = () => {
  const {
    currentWorkspace,
    scriptIsSetup,
    scriptIsSetupChecking,
    isCompaniesLoading,
  } = useAppContext();
  const hotkeys = useGlobalHotKeys();
  const navigate = useNavigate();

  const location = useLocation();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [showDrawer, setShowDrawer] = useState(!smallScreen);
  const handleDrawerToggle = useCallback(() => {
    setShowDrawer(!showDrawer);
  }, [showDrawer]);

  useEffect(() => {
    hotkeys.register("d", () => navigate("/"));
    hotkeys.register("i", () => navigate("/issues/possible_bugs"));
    hotkeys.register("b", () => navigate("/tracker"));

    return () => {
      hotkeys.unregister("d");
      hotkeys.unregister("i");
      hotkeys.unregister("b");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {smallScreen ? (
        <AppBar position="fixed" onClick={handleDrawerToggle}>
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{ ml: 2, mr: 1 }}
            >
              <MenuIcon color="primary.contrastText" />
            </IconButton>

            <Typography variant="h2" noWrap>
              {currentWorkspace?.name}
            </Typography>
          </Toolbar>
        </AppBar>
      ) : null}

      {!smallScreen && <TopAppBar />}

      <SwipeableDrawer
        className="AppDrawer"
        variant={smallScreen ? "temporary" : "permanent"}
        width={DRAWER_WIDTH}
        onOpen={() => setShowDrawer(true)}
        onClose={() => setShowDrawer(false)}
        open={showDrawer}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            border: 0,
            borderRadius: 0,
            background: (theme) =>
              `linear-gradient(180deg, ${theme.palette.sidebar.gradient1} 0%, ${theme.palette.sidebar.gradient2} 90%);`,
            width: DRAWER_WIDTH,

            boxShadow: smallScreen
              ? "0px 0px 10px 0px rgba(0,0,0,0.75)"
              : "none",
            padding: 1,
            paddingTop: 0,
            borderRight: (theme) => `thin solid #fafafa`,
          },
        }}
        BackdropProps={{
          invisible: true,
        }}
      >
        <Box sx={{ height: "100%" }}>
          <Stack
            sx={{
              height: "100%",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <List
              dense
              disablePadding
              sx={{
                maxWidth: DRAWER_WIDTH,
                overflowX: "hidden",
                overflowY: "auto",
                padding: 2,
              }}
            >
              <SelectWorkspaceMenu selectedWorkspace={currentWorkspace} />

              {!scriptIsSetupChecking && !scriptIsSetup && (
                <ListItem sx={{ mt: 3, p: 0, mb: 0 }}>
                  <SidebarChecklist />
                </ListItem>
              )}

              <MenuSectionTitle text="Bugs" />

              <MenuButtonItem
                to="/"
                icon={<MonitorHeartOutlinedIcon fontSize="small" />}
                text="Dashboard"
                selected={location.pathname === "/"}
              />

              <MenuButtonItem
                to="/issues"
                icon={<ReportProblemOutlinedIcon fontSize="small" />}
                text="New Issues"
                selected={location.pathname.startsWith("/issues")}
                badge={<UnreadsCounter />}
              />

              <MenuButtonItem
                to="/tracker"
                icon={<ViewWeekOutlinedIcon fontSize="small" />}
                text="Bug Tracker"
                selected={location.pathname.startsWith("/tracker")}
              />

              <MenuSectionTitle text="Free Tools" />
              <MenuButtonItem
                to="/widget"
                icon={<BugReportOutlinedIcon fontSize="small" />}
                text="Widget"
                selected={location.pathname.startsWith("/widget")}
              />
              <MenuButtonItem
                to="/statuspage"
                icon={<TaskOutlinedIcon fontSize="small" />}
                text="Status Page"
                selected={location.pathname.startsWith("/statuspage")}
              />

              <MenuSectionTitle text="Workspace" />
              <MenuButtonItem
                to="/getting-started/code"
                icon={<BuildOutlinedIcon sx={{ width: 15, height: 15 }} />}
                text="Getting Started"
                selected={location.pathname.startsWith("/getting-started")}
              />
              <MenuButtonItem
                to="/settings/integrations"
                icon={<ExtensionOutlinedIcon fontSize="small" />}
                text="Integrations"
                selected={location.pathname.startsWith(
                  "/settings/integrations"
                )}
              />
              <MenuButtonItem
                to="/settings/team"
                icon={<PeopleOutlinedIcon fontSize="small" />}
                text="Team"
                selected={location.pathname === "/settings/team"}
              />
              <MenuButtonItem
                to="/settings/customize"
                icon={<SettingsOutlinedIcon fontSize="small" />}
                text="Settings"
                selected={
                  location.pathname.startsWith("/settings") &&
                  location.pathname !== "/settings/team" &&
                  !location.pathname.startsWith("/settings/integrations")
                }
              />
            </List>

            <Box sx={{ flexGrow: 1 }} />

            <Box
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                opacity: 0.8,
              }}
            >
              {!isCompaniesLoading && !currentWorkspace?.planId && (
                <UpgradeButton />
              )}

              <Box>
                <iframe
                  title="Status Page"
                  src="https://statuspage.fyi/status/2fa89212-ad87-4d1b-8a79-fbb965af7f41/badge"
                  width="100px"
                  height="40px"
                  frameBorder="0"
                  style={{ backgroundColor: "transparent" }}
                />
              </Box>

              {/* <StatusIcon /> */}

              <Button
                variant="text"
                startIcon={<SupportAgentOutlinedIcon fontSize="small" />}
                size="small"
                sx={{
                  textTransform: "none",
                  justifyContent: "flex-start",
                }}
                target="_blank"
                href="mailto:support@bugpilot.io?subject=Help with my account"
              >
                Support
              </Button>

              <Button
                variant="text"
                startIcon={<HelpCenterOutlinedIcon fontSize="small" />}
                size="small"
                sx={{
                  textTransform: "none",
                  justifyContent: "flex-start",
                }}
                target="_blank"
                href="https://docs.bugpilot.io/"
              >
                Docs
              </Button>

              <Button
                variant="text"
                startIcon={<CampaignOutlinedIcon fontSize="small" />}
                size="small"
                sx={{
                  textTransform: "none",
                  justifyContent: "flex-start",
                }}
                target="_blank"
                href="https://dash.bugpilot.io/feedback"
              >
                Feedback
              </Button>
            </Box>
          </Stack>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default MenuSidebar;

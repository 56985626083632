import React, { forwardRef } from "react";
import { SnackbarProvider, SnackbarContent } from "notistack";
import { Amplify } from "aws-amplify";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { Box } from "@mui/system";

import { theme } from "./theme";
import AppContainer from "./router/AppRoutes";
import { NotificationCard } from "./router/MenuSidebar/NotificationsDrawer.tsx";

Amplify.configure({
  Auth: {
    region: "eu-west-1",

    userPoolWebClientId: "51rlr8fejke8hisv9b8d2hgm3f",
    userPoolId: "eu-west-1_atjkI9Ixz",

    identityPoolId: "eu-west-1:9ee32c1d-454c-48d9-8f8c-1e7ec733332f",
    identityPoolRegion: "eu-west-1",

    oauth: {
      domain: process.env.REACT_APP_USER_POOL_DOMAIN_NAME,
      redirectSignIn: `${window.location.origin}/`,
      redirectSignOut: `${window.location.origin}/`,
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      responseType: "code",
    },
  },
});

// eslint-disable-next-line react/display-name
const NewNotificationSnackbarComponent = forwardRef(
  ({ data, user, onClick, onNotificationClose, ...props }, ref) => (
    <SnackbarContent ref={ref} {...props} onClick={onClick}>
      <NotificationCard
        reportSerialNumber={data.reportSerialNumber}
        reportTitle={data.reportTitle}
        item={data}
        user={user}
        type={data.type}
        onClose={onNotificationClose}
        notification={data}
      />
    </SnackbarContent>
  )
);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        Components={{
          // eslint-disable-next-line react/display-name
          "new-notification": NewNotificationSnackbarComponent,
        }}
      >
        <CssBaseline />
        <Box
          style={{
            minHeight: "100%",
            overflowY: "overlay",
          }}
        >
          <AppContainer />
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;

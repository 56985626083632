import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  IconButton,
  Grid,
} from "@mui/material";
import {
  ClearOutlined as ClearIcon,
  SearchOutlined as SearchIcon,
  SettingsApplicationsOutlined as SettingsApplicationsIcon,
} from "@mui/icons-material";

import ButtonDialog from "../../../components/ButtonDialog";
import { IntegrationsSettingsDialog } from "./IntegrationsSettingsDialog";
import { CATEGORIES } from "./CATEGORIES";

export const IntegrationsHeader = ({
  company,
  onCompanyChange,
  isAdminOrOwner,
  filter,
  setFilter,
  search,
  setSearch,
}) => {
  const navigate = useNavigate();
  const [messageTemplate, setMessageTemplate] = useState();

  return (
    <>
      <Grid item xs={12}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <ButtonDialog
            buttonLabel="Configure"
            buttonProps={{
              color: "secondary",
              startIcon: <SettingsApplicationsIcon />,
            }}
            dialogProps={{
              maxWidth: "md",
            }}
            title="Configure Integrations"
            hideCloseButton
            buttonPrepend={[
              <Select
                key="category"
                size="small"
                renderValue={(value) => {
                  const category = CATEGORIES.find(
                    (cat) => cat.category === value
                  );

                  if (!category || !category.filterTitle) {
                    return "Filter by type";
                  }

                  return `Filter: ${category?.filterTitle}`;
                }}
                value={filter || "all"}
                onChange={(e) => {
                  setFilter(e.target.value === "all" ? "" : e.target.value);
                  navigate(
                    e.target.value === "all"
                      ? "/settings/integrations"
                      : `/settings/integrations/${e.target.value}`
                  );
                }}
              >
                <MenuItem value="all">All</MenuItem>

                {CATEGORIES.filter((cat) => cat.filterTitle).map((cat) => (
                  <MenuItem key={cat.title} value={cat.category}>
                    {cat.filterTitle}
                  </MenuItem>
                ))}
              </Select>,
              <TextField
                key="search"
                name="integration-search"
                autoComplete="off"
                type="search"
                placeholder="Search integration"
                variant="outlined"
                size="small"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  // end button to clear search
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setSearch("")}
                        sx={{
                          visibility: search ? "visible" : "hidden",
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  // hide browser clear button on chrome
                  "& input::-webkit-search-cancel-button": {
                    display: "none",
                  },
                }}
              />,
            ]}
          >
            <IntegrationsSettingsDialog
              company={company}
              onCompanyChange={onCompanyChange}
              isAdminOrOwner={isAdminOrOwner}
              messageTemplate={messageTemplate}
              setMessageTemplate={setMessageTemplate}
            />
          </ButtonDialog>
        </Stack>
      </Grid>
    </>
  );
};

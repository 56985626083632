import React, { useCallback } from "react";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";

import AsyncButton from "../AsyncButton";
import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText,
  DialogTitle as MuiDialogTitle,
} from "@mui/material";

import LaunchIcon from "@mui/icons-material/LaunchOutlined";

const DialogActions = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(4)}`,
  },
}))(MuiDialogActions);

const DialogContent = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(2)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(4)} ${spacing(4)} ${spacing(2)}`,
  },
}))(MuiDialogTitle);

const StyledBackdrop = withStyles({
  root: {
    background:
      "linear-gradient(317.41deg, #F0F5FE 21.54%, rgba(240, 245, 254, 0.8) 103.27%)",
    backdropFilter: "blur(2px)",
  },
})(Backdrop);

const PaymentFailedModal = () => {
  const handleOpenCustomerPortal = useCallback(async () => {}, []);

  return (
    <Dialog BackdropComponent={StyledBackdrop} maxWidth="sm" open fullWidth>
      <DialogTitle>
        <Typography variant="h5" fontWeight="bold">
          Payment failed
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          The credit card payment couldn't be processed at this time. Update
          your payment information to continue using Adopto.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <AsyncButton
          bold
          variant="contained"
          onClick={handleOpenCustomerPortal}
          fullWidth
          endIcon={<LaunchIcon />}
        >
          Update payment information
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentFailedModal;

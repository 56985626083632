/* eslint-disable react/jsx-pascal-case */
import { Link } from "react-router-dom";
import { Box, Button, CircularProgress } from "@mui/material";
import { ListAltOutlined as ListAltIcon } from "@mui/icons-material";
import { useReportsListContext } from "../../contexts/ReportsListContext.tsx";
import { NoRowsWrapper } from "./NoRowsWrapper.js";
import { usePreferences } from "../../contexts/UserPreferencesContext.js";
import { NoRows_EmptyPendingReview_GoodJob } from "./NoRows_EmptyPendingReview_GoodJob.js";
import { NoRows_EmptyPendingReview_NewUser } from "./NoRows_EmptyPendingReview_NewUser.js";

export const NoRows_Inbox = ({
  activeTab,
  onCreateSampleReport,
  currentView,
  setCurrentView,
}) => {
  const { reportsLoading } = useReportsListContext();
  const { preferences } = usePreferences();

  const EmptyAll = () => (
    <NoRowsWrapper
      icon={<ListAltIcon />}
      title={"Nothing to see here. Did you complete the setup?"}
      description="You will find all your Bugpilot reports here: past and present, archived and active, fixed and to-do."
    >
      <div>
        <Link to="/getting-started/code">
          <Button variant="outlined" size="small" color="secondary">
            Go to Setup
          </Button>
        </Link>
      </div>
    </NoRowsWrapper>
  );

  if (reportsLoading) {
    return (
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        height="100%"
        width="100%"
        position="relative"
        zIndex={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      height="100%"
      width="100%"
      position="relative"
      zIndex={10}
    >
      <Box sx={{ padding: 4, maxWidth: "sm" }}>
        {activeTab === "possible_bugs" &&
          (preferences.lastBugStatusAction ? (
            <NoRows_EmptyPendingReview_GoodJob
              currentView={currentView}
              setCurrentView={setCurrentView}
            />
          ) : (
            <NoRows_EmptyPendingReview_NewUser
              onCreateSampleReport={onCreateSampleReport}
              currentView={currentView}
              setCurrentView={setCurrentView}
            />
          ))}

        {activeTab === "everything" && <EmptyAll />}
      </Box>
    </Box>
  );
};

export function withOptimisticUpdates<T>(
  fn,
  state,
  setState,
  {
    primaryColumns = ["id"],
    withMerge = false,
    shouldCreateIfNotFound = true,
    notFailOnNotFound = false,
  } = {}
): (updates: Partial<T>) => Promise<T> {
  return async (updates) => {
    const snapshot = [...state];

    try {
      setState((records) => {
        const index = records.findIndex((record) =>
          primaryColumns.every((column) => updates[column] === record[column])
        );

        if (!~index) {
          return shouldCreateIfNotFound
            ? [
                {
                  ...updates,
                  isCreating: true,
                },
                ...records,
              ]
            : records;
        }

        const newRecords = [...records];
        newRecords.splice(index, 1, {
          ...records[index],
          ...updates,
        });
        return newRecords;
      });

      const updatedRecord = await fn(updates);

      setState((records) => {
        const index = records.findIndex((record) =>
          primaryColumns.every((column) => record[column] === updates[column])
        );

        if (!~index) {
          return records;
        }

        const newRecords = [...records];
        newRecords.splice(
          index,
          1,
          withMerge ? { ...newRecords[index], ...updatedRecord } : updatedRecord
        );
        return newRecords;
      });

      return updatedRecord;
    } catch (e) {
      if (e.statusCode === 404 && notFailOnNotFound) {
        return;
      }

      setState(snapshot);
      throw e;
    }
  };
}

import React, { SyntheticEvent, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { kebabCase } from "lodash";

import { Divider, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

type SettingsSectionProps = {
  id: string;
  title: string;
  description: string | React.ReactNode;
  children: React.ReactNode | React.ReactFragment;
  buttons?: React.ReactNode[] | React.ReactNode | React.ReactFragment;
  leftButtons?: React.ReactNode[] | React.ReactNode | React.ReactFragment;
};

export function SettingsSection({
  title,
  description,
  children,
  buttons = [],
  leftButtons = [],
  ...props
}: SettingsSectionProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const ref = useRef<HTMLParagraphElement>();
  const id = props.id || (typeof title === "string" ? kebabCase(title) : "");

  useEffect(() => {
    if (location.hash !== `#${id}`) {
      return;
    }

    if (!ref.current) {
      return;
    }
    ref.current.scrollIntoView();
  }, [id, location.hash]);

  return (
    <Paper>
      <Stack spacing={3}>
        <Typography
          ref={ref}
          variant="h5"
          px={3}
          pt={3}
          sx={{
            fontSize: "1.1rem",
            fontWeight: 500,
            textDecoration: "none",
            color: "inherit",
          }}
          {...(id && {
            component: "a",
            href: `${location.pathname}#${id}`,
            onClick: (e: SyntheticEvent) => {
              e.preventDefault();

              navigate({
                ...location,
                hash: `#${id}`,
              });
            },
          })}
        >
          {title}
        </Typography>

        <Typography variant="body1" px={3}>
          {description}
        </Typography>

        {children && <Box px={3}>{children}</Box>}

        <Box>
          <Divider sx={{ color: "white.border" }} />

          <Box
            py={2}
            px={3}
            bgcolor="secondary.light"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              columnGap: 2,
              color: "text.secondary",
              fontSize: "0.8rem",
            }}
          >
            {leftButtons}

            <Box sx={{ flexGrow: 1 }} />

            {buttons}
          </Box>
        </Box>
      </Stack>
    </Paper>
  );
}

import React from "react";

import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  ListItemText,
  Button,
} from "@mui/material";
import {
  BugReportOutlined as BugReportIcon,
  BuildOutlined as BuildIcon,
  CheckCircleOutlined as CheckCircleIcon,
  RocketLaunchOutlined as RocketLaunchIcon,
} from "@mui/icons-material";
import { Box, Stack } from "@mui/system";
import { Link } from "react-router-dom";

export const NoRows = ({ reports, onRefresh }) => {
  if (reports.length > 1) {
    return null;
  }

  if (reports.length === 1 && !reports[0].metadata?.demo) {
    return null;
  }

  return (
    <Stack
      direction="row"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        zIndex: 10,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Paper elevation={3}>
        <Box p={4}>
          <Stack spacing={2} alignItems="flex-start" maxWidth="sm">
            <RocketLaunchIcon fontSize="small" />

            <Typography variant="h6" gutterBottom>
              Oh oh, it&apos;s empty here!
            </Typography>

            <Typography variant="body1">
              The Bug Tracker is a powerful board that helps you manage and keep
              an eye on all bugs. To get started, go to the Issues and assign a
              status to a bug.
            </Typography>

            <List dense mt={0}>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <BugReportIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body1">
                    Confirmed bugs that need to be fixed
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <BuildIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body1">
                    Bugs that are being fixed
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <CheckCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body1">
                    Bugs that you have fixed
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>

            <div>
              <Link to="/issues">
                <Button variant="outlined" size="small" color="secondary">
                  Go to Issues
                </Button>
              </Link>
            </div>
          </Stack>
        </Box>
      </Paper>
    </Stack>
  );
};

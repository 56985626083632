import React, { useCallback } from "react";
import AccountSetupStep1 from "../components/AccountSetupStep1";
import { OnboardingFrame } from "../components/OnboardingFrame";
import { useAppContext } from "../contexts/AppContext";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { usePreferences } from "../contexts/UserPreferencesContext";

const AccountSetupFlow = () => {
  const { createConsent, updateCompany, currentWorkspace } = useAppContext();
  const { fetchUserAttributes, updateUser } = useAuth();
  const { updatePreferences } = usePreferences();

  const navigate = useNavigate();

  const handlePrivacyConfirm = useCallback(async () => {
    // Save the consent to iubenda
    try {
      await Promise.all([
        createConsent({ type: "privacy-policy" }),
        createConsent({ type: "terms-and-conditions" }),
      ]);
    } catch (error) {
      console.error("Cannot save user privacy confirm", error);
    }

    // Save consent to Cognito
    const amplifyUser = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(amplifyUser, {
      "custom:privacyPolicyAgreed": `${new Date().toISOString()}`,
    });
    await fetchUserAttributes();
  }, [createConsent, fetchUserAttributes]);

  const handleSave = useCallback(
    async (fields) => {
      await handlePrivacyConfirm();
      await updatePreferences(fields);

      if (fields.howDidYouHearAboutUs) {
        await updateUser({ howDidYouHearAboutUs: fields.howDidYouHearAboutUs });
      }

      if (fields.companyName && currentWorkspace?.id) {
        await updateCompany(currentWorkspace.id, { name: fields.companyName });
      }

      const redirectPath = localStorage.getItem("Bugpilot::redirectPath");

      if (redirectPath) {
        localStorage.removeItem("Bugpilot::redirectPath");

        navigate(redirectPath);
        return;
      }

      navigate("/?accountsetup=1");
    },
    [
      currentWorkspace?.id,
      handlePrivacyConfirm,
      navigate,
      updateCompany,
      updatePreferences,
      updateUser,
    ]
  );

  return (
    <OnboardingFrame LeftComponent={() => null} hideTos={true}>
      <AccountSetupStep1 handleSave={handleSave} />
    </OnboardingFrame>
  );
};

export default AccountSetupFlow;

import { Avatar, Typography } from "@mui/material";
import { InsertEmoticonOutlined as InsertEmoticonIcon } from "@mui/icons-material";
import { useMemo } from "react";
import { Stack } from "@mui/system";
import { getCountryInfo } from "../../components/report/CountryFlag";
import { uniqueNamesGenerator, colors, animals } from "unique-names-generator";

const generateFunName = (anonymousId) => {
  if (!anonymousId) {
    return "Anonymous";
  }

  const config = {
    dictionaries: [colors, animals],
    separator: " ",
    length: 2,
    seed: anonymousId,
  };
  return uniqueNamesGenerator(config);
};

export const getFullName = (user) => {
  if (!user) return "";

  if (user.surname && user.name) {
    return `${user.surname} ${user.name}`;
  }

  if (user.name || user.fullName || user.full_name || user.displayName) {
    return user.name || user.fullName || user.full_name || user.displayName;
  }

  if (user?.firstName && user?.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  if (user?.first_name && user?.last_name) {
    return `${user.first_name} ${user.last_name}`;
  }

  if (user?.given_name && user?.family_name) {
    return `${user.given_name} ${user.family_name}`;
  }

  return "";
};

export const UserChip = ({ row, showOthers = true }) => {
  const { user, chatUserId } = row;

  const userFullName = useMemo(() => getFullName(user), [user]);

  const userId = useMemo(() => {
    return user?.email || user?.id || chatUserId;
  }, [user, chatUserId]);

  const { englishName: countryName } = useMemo(() => {
    return getCountryInfo({ report: row });
  }, [row]);

  const userLabel = useMemo(() => {
    if (userFullName && userFullName !== "- -") {
      return <span>{userFullName}</span>;
    }

    if (user?.email) {
      return <span>{user.email}</span>;
    }

    if (user?.anonymousId) {
      return (
        <span
          style={{
            textTransform: "capitalize",
          }}
        >
          {generateFunName(user.anonymousId)}
        </span>
      );
    }

    return <span>Visitor from {countryName}</span>;
  }, [user, userFullName, countryName]);

  const userAvatar = useMemo(() => {
    const idForHash = userId || user?.anonymousId || null;

    if (!idForHash) {
      return null;
    }

    // multiavatar.com
    const hash = String(idForHash)
      .split("")
      .reduce((a, b) => {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);
    return `https://api.multiavatar.com/${hash}.svg?apikey=obgW13ZVXt2JDz`;
  }, [user?.anonymousId, userId]);

  return (
    <Stack gap={0.5}>
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        sx={{
          width: "100%", // needed for noWrap
        }}
      >
        {user?.id || user?.email ? (
          <Avatar
            src={userAvatar}
            sx={{
              width: 20,
              height: 20,
            }}
            imgProps={{
              width: 20,
              height: 20,
            }}
          />
        ) : (
          <InsertEmoticonIcon sx={{ height: 20, width: 20 }} />
        )}

        <Typography variant="body2" noWrap>
          {userLabel}
        </Typography>
      </Stack>

      {showOthers &&
        (row.metadata?.errorInfo?.numAffectedUsers > 1 ? (
          <Typography
            variant="body2"
            color="tertiary.main"
            noWrap
            pl={3.5}
            sx={{
              fontSize: "0.85em",
            }}
          >
            (+ {row.metadata?.errorInfo?.numAffectedUsers - 1} others)
          </Typography>
        ) : null)}
    </Stack>
  );
};

export const render = (props) => <UserChip {...props} />;

export const valueFormatter = ({ user, chatUserId }) =>
  (user && (user.email || user.id || chatUserId)) || "Anonymous";

export const sortComparator = (v1, v2, param1, param2) => {
  const value1 = param1.user?.email || param1.user?.id || param1.chatUserId;
  const value2 = param2.user?.email || param2.user?.id || param2.chatUserId;
  return value1?.localeCompare?.(value2);
};

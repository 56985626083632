import { useCallback, useState } from "react";
import {
  IconButton,
  InputAdornment,
  Button,
  Stack,
  TextField,
  Typography,
  Box,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import {
  VisibilityOutlined as VisibilityIcon,
  VisibilityOffOutlined as VisibilityOffIcon,
  DownloadOutlined as DownloadIcon,
} from "@mui/icons-material";
import { useAppContext } from "../contexts/AppContext";

const SourceMapsConfig = ({ isDisabled, company, onCompanyChange }) => {
  const { workspaceSourcemaps } = useAppContext();
  const [showValue, setShowValue] = useState(false);
  const toggleShowValue = useCallback(() => setShowValue((v) => !v), []);

  return (
    <Stack spacing={2}>
      <Typography variant="subtitle2">
        Read the documentation and learn how to Bugpilot to use JavaScript
        source maps.
      </Typography>

      <Box>
        <Button
          variant="contained"
          href="https://bugpilot.notion.site/Advanced-Configure-Source-Maps-b6b0bcc8df6845bbada3eeab3425be20"
          target="_blank"
        >
          Read the docs
        </Button>
      </Box>

      <Typography variant="body1" fontWeight="bold">
        Source Map Upload Credentials
      </Typography>

      <TextField
        variant="outlined"
        label="Workspace Id"
        onFocus={(e) => e.target.select()}
        value={company.id}
        readonly
      />

      <TextField
        label="Source Map Upload Secret"
        value={company.fileUploadSecret || ""}
        readonly
        type={showValue ? "text" : "password"}
        onFocus={(e) => {
          setShowValue(true);
          e.target.select();
        }}
        onBlur={() => setShowValue(false)}
        helperText={
          "The API Key is a write-only key designed for your build scripts. Keep it private."
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={toggleShowValue}
                edge="end"
              >
                {showValue ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Typography variant="body" fontWeight="bold" mt={2}>
        Example code
      </Typography>

      <Tooltip title="Click to copy to clipboard" arrow>
        <Typography
          component="code"
          variant="code"
          sx={{
            backgroundColor: "#f5f5f5",
            padding: 2,
            borderRadius: 1,
            overflow: "auto",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            cursor: "pointer",
          }}
          onMouseEnter={(e) => {
            setShowValue(true);
          }}
          onMouseLeave={() => setShowValue(false)}
          // copy on click
          onClick={(e) => {
            navigator.clipboard.writeText(
              e.target.innerText.replace(/\$\s/g, "")
            );
          }}
        >
          {`$ curl -o bugpilot-cli https://cli-downloads.bugpilot.io/bugpilot_linux_amd64
$ chmod +x ./bugpilot-cli
$ ./bugpilot-cli \\
    -workspaceId ${company.id} \\
    -auth ${showValue ? company.fileUploadSecret : "<SECRET>"} \\
    upload-sourcemaps`}
        </Typography>
      </Tooltip>

      <Box>
        <Typography variant="body" fontWeight="bold">
          Uploaded source maps
        </Typography>

        <Typography variant="body1" mt={2}>
          There are {workspaceSourcemaps.length || "no"} source maps available
        </Typography>

        {workspaceSourcemaps.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>File</TableCell>
                <TableCell>Uploaded On</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workspaceSourcemaps.map((sourcemap, i) => (
                <TableRow key={i}>
                  <TableCell>{sourcemap.fileName}</TableCell>
                  <TableCell>
                    {/* format date with locale */}
                    {new Date(sourcemap.uploadDate || 0).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      size="small"
                      href={sourcemap.url}
                      startIcon={<DownloadIcon />}
                    >
                      Download
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>

      <Box mb={2}></Box>
    </Stack>
  );
};

export default SourceMapsConfig;

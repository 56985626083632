import Tooltip from "@mui/material/Tooltip";
import { findKey } from "lodash";
import timeZones from "../../lib/timeZones.json";
import ReactCountryFlag from "react-country-flag";
import { useMemo } from "react";
import { Box } from "@mui/system";

const getCountryCode = (timeZone) => {
  return findKey(timeZones, (zones) =>
    zones.includes(timeZone)
  )?.toLocaleLowerCase();
};

export const getCountryInfo = ({ report }) => {
  const code = getCountryCode(report.timeZone);
  let englishName = "";

  if (!code) {
    englishName = "Unknown";
    return { code, englishName };
  }

  try {
    englishName = new Intl.DisplayNames(["en"], { type: "region" }).of(
      code.toUpperCase()
    );
  } catch (e) {
    englishName = "Unknown";
  }

  return { code, englishName };
};

export const CountryFlag = ({ report, ...props }) => {
  const { code, englishName } = useMemo(
    () => getCountryInfo({ report }),
    [report]
  );

  const title = useMemo(() => {
    return `${englishName} (${report.timeZone || "Unknown"})`;
  }, [englishName, report.timeZone]);

  if (englishName === "Unknown") {
    return null;
  }

  return (
    <Tooltip title={title} arrow>
      <Box
        {...props}
        sx={{
          ...props.sx,
          cursor: "default",
          backgroundColor: "#fff",
          borderTopLeftRadius: 2,
        }}
      >
        {code ? (
          <ReactCountryFlag
            style={{
              fontSize: "1.2em",
            }}
            countryCode={code}
          />
        ) : (
          report.timeZone || ""
        )}
      </Box>
    </Tooltip>
  );
};

import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";
import { Button, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { OnboardingFrame } from "../components/OnboardingFrame";
import { useAnalytics } from "../analytics/AnalyticsContext";

export const AcceptWorkspaceInvitationPage = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    confirmCompanyMembershipByInvitationCode: confirmAPI,
    fetchMyCompanies,
    setSelectedCompanyId,
  } = useAppContext();

  const handleJoinClick = useCallback(async () => {
    setLoading(true);

    try {
      const result = await confirmAPI(code);
      const { id } = result;

      trackEvent("Joined workspace", { workspaceId: id });

      await fetchMyCompanies();
      setSelectedCompanyId(id);
      navigate("/?acceptWorkspaceInvitation=1");
    } catch (error) {
      console.error("Workspace join error", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, confirmAPI, navigate]);

  const onCancel = useCallback(() => navigate(`/?join-cancel=1`), [navigate]);

  return (
    <OnboardingFrame>
      <Typography variant="h2">You have been invited to a workspace</Typography>

      <Typography variant="body1">
        Oh, now that&apos;s exciting! Click the button below to join the
        workspace and start resolving bugs at the speed of light.
      </Typography>

      {error && (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      )}

      <LoadingButton
        variant="contained"
        color="primary"
        onClick={handleJoinClick}
        loading={loading}
      >
        Join workspace
      </LoadingButton>

      <Button variant="text" color="secondary" onClick={onCancel}>
        Back
      </Button>
    </OnboardingFrame>
  );
};

import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { keyBy, truncate, uniqBy } from "lodash";
import { Box, Stack, Typography } from "@mui/material";

import DateTime from "../../DateTime";
import { STATUS_LIST } from "../SelectBugStatus";
import { useAppContext } from "../../../contexts/AppContext";
import MemberAvatar, { AUTOPILOT } from "../../MemberAvatar.tsx";

const columnNamesMap = {
  bugStatus: "Bug status",
  assigneeSub: "Assignee",
  title: "Title",
  description: "Notes",
  archived: "Archived",
};

const statusById = keyBy(
  uniqBy(
    [
      ...STATUS_LIST,
      {
        description: "Not a bug",
        bugStatus: "not_a_bug",
      },
      {
        description: "Possible bug",
        bugStatus: "likely_a_bug",
      },
      {
        description: "To Fix",
        bugStatus: "confirmed",
      },
      {
        description: "Fix in progress",
        bugStatus: "in_progress",
      },
      {
        description: "Resolved",
        bugStatus: "fixed",
      },
    ],
    "bugStatus"
  ),
  "bugStatus"
);

const Value = ({ value, column, user }) => {
  const { companyMembers } = useAppContext();

  if (column === "assigneeSub") {
    const member = companyMembers.find(({ id }) => id === value);
    return (
      <b>
        {(member?.name || member?.email) ?? "Unassigned"}
        {user.sub === value ? " (you)" : ""}
      </b>
    );
  }

  if (column === "bugStatus") {
    return (
      <Box sx={{ fontWeight: "bold" }} component="span">
        {statusById[value].description || value}
      </Box>
    );
  }

  if (column === "description") {
    return (
      <Typography
        component="span"
        sx={{
          fontSize: 11,
        }}
      >
        {value ? truncate(value, { length: 200 }) : "None"}
      </Typography>
    );
  }

  if (typeof value === "string") {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          p: ({ node, ...props }) => (
            <p
              {...props}
              style={{ ...props.style, margin: 0, display: "inline" }}
            />
          ),
        }}
      >
        {value}
      </ReactMarkdown>
    );
  }

  if (typeof value === "boolean") {
    return <b>{value ? "Yes" : "No"}</b>;
  }
  return <b>{value}</b>;
};

const ChangeLog = ({
  changeLog,
  user,
  showDateTime = false,
  textOnly = false,
}) => {
  const isUpdate =
    changeLog.oldValue !== null && changeLog.oldValue !== undefined;

  const actorLabel = useMemo(() => {
    if (changeLog.author?.id === user.sub) {
      return "You";
    }

    return changeLog.author?.name || changeLog.author?.email || AUTOPILOT.name;
  }, [changeLog?.author, user?.sub]);

  const text = useMemo(() => {
    return (
      <>
        {columnNamesMap[changeLog.column] || changeLog.column}{" "}
        {/*{isUpdate && (*/}
        {/*  <>*/}
        {/*    from{" "}*/}
        {/*    <Value*/}
        {/*      value={changeLog.oldValue}*/}
        {/*      column={changeLog.column}*/}
        {/*      user={user}*/}
        {/*    />*/}
        {/*  </>*/}
        {/*)}{" "}*/}
        {isUpdate ? "updated" : "set"} to{" "}
        <Value
          value={changeLog.newValue}
          column={changeLog.column}
          user={user}
        />
      </>
    );
  }, [changeLog, isUpdate, user]);

  if (textOnly) {
    return text;
  }

  return (
    <Stack spacing={1} direction="row" alignItems="flex-start">
      <MemberAvatar
        member={changeLog.author?.id ? changeLog.author : AUTOPILOT}
        size={18}
        typographyProps={{
          sx: {
            fontSize: 10,
          },
        }}
      />

      <Typography
        variant="body2"
        color="text.tertiary"
        flexGrow={1}
        noWrap
        sx={{
          fontSize: "0.8rem",
          cursor: "default",
        }}
      >
        {text}
      </Typography>

      {showDateTime && (
        <Typography variant="caption" textAlign={"right"}>
          <DateTime
            onlyTime
            absolute
            typographyProps={{
              sx: { color: "muted.main" },
            }}
          >
            {changeLog.createdAt}
          </DateTime>
        </Typography>
      )}
    </Stack>
  );
};

export default ChangeLog;

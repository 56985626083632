import ContentCopy from "@mui/icons-material/ContentCopyOutlined";
import { Box } from "@mui/system";

import {
  CSSProperties,
  ComponentProps,
  useCallback,
  useMemo,
  useState,
} from "react";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { CheckCircleOutlined as CheckCircleIcon } from "@mui/icons-material";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import codeStyleDark from "react-syntax-highlighter/dist/esm/styles/prism/one-dark";
import codeStyleLight from "react-syntax-highlighter/dist/esm/styles/prism/one-light";

const CODE_STYLE_ATTRIBUTE: CSSProperties = {
  fontFamily: "monospace",
  fontSize: 12,
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  display: "block",
  width: "100%",
};

type CodeContainerProps = {
  code?: string;
  children?: string | string[];
  language?: string;
  dark?: boolean;
} & ComponentProps<typeof Box>;

const CodeContainer = ({
  children,
  code,
  language,
  dark = false,
  ...boxProps
}: CodeContainerProps) => {
  const [copying, setCopying] = useState(false);

  const handleCopyCode = useCallback(() => {
    if (!code) {
      return;
    }

    setCopying(true);
    navigator.clipboard.writeText(code);

    setTimeout(() => {
      setCopying(false);
    }, 1000);
  }, [code]);

  const showLineNumbers = useMemo(() => {
    if (Array.isArray(children)) {
      return children.length > 1;
    }

    return code.split("\n").length > 1;
  }, [children, code]);

  return (
    <Box
      onClick={handleCopyCode}
      {...boxProps}
      sx={{
        width: "100%",
        ...(boxProps.sx || {}),
        position: "relative",
      }}
      style={{
        ...(boxProps.style || {}),
      }}
    >
      <Stack
        gap={1}
        alignItems="flex-start"
        sx={{
          width: "100%",
        }}
      >
        {language ? (
          <SyntaxHighlighter
            style={dark ? codeStyleDark : codeStyleLight}
            language={language}
            codeTagProps={{
              style: CODE_STYLE_ATTRIBUTE,
            }}
            customStyle={{
              width: "100%",
            }}
            showLineNumbers={showLineNumbers}
            wrapLines
          >
            {code || children}
          </SyntaxHighlighter>
        ) : (
          <code title={language} style={CODE_STYLE_ATTRIBUTE}>
            {code || children}
          </code>
        )}

        <Box
          sx={{
            alignSelf: "flex-end",
          }}
        >
          <LoadingButton
            loading={copying}
            loadingIndicator={
              <CheckCircleIcon color="success" sx={{ fontSize: 16 }} />
            }
            loadingPosition="start"
            size="small"
            onClick={handleCopyCode}
            variant="contained"
            sx={{
              fontSize: "0.875rem",
              height: 20,
              backgroundColor: "#fff !important",
              color: "#0d0f19",

              opacity: 0.7,
              transition: "opacity 0.2s ease-in-out",

              "&:hover": {
                opacity: 1,
              },

              ml: -1,
            }}
            startIcon={
              <ContentCopy
                sx={{
                  fontSize: 18,
                }}
              />
            }
          >
            Copy to clipboard
          </LoadingButton>
        </Box>
      </Stack>
    </Box>
  );
};
export default CodeContainer;

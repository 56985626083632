import { useAppContext } from "../contexts/AppContext";
import { useAuth } from "../contexts/AuthContext";

const useHasRole = (role) => {
  const { currentWorkspace, companyMembers, isCompanyMembersLoading } =
    useAppContext();
  const { user } = useAuth();

  if (!currentWorkspace) {
    return {
      isLoading: true,
      hasRole: false,
    };
  }

  if (!user) {
    return {
      isLoading: true,
      hasRole: false,
    };
  }

  return {
    hasRole: ["owner", ...(role ? [role] : [])].includes(
      companyMembers.find(({ email }) => email === user.email)?.role
    ),
    isLoading: isCompanyMembersLoading,
  };
};

export default useHasRole;

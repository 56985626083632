// Bugpilot embed code

const code1 = ({
  workspaceId = "YOUR WORKSPACE ID",
  scriptCustomDomainName = "script.bugpilot.io",
} = {}) =>
  `<script src="https://${scriptCustomDomainName}/${workspaceId}/adopto.js" defer async></script>`;

// TODO: add FetchPriority but not for tagmanager

const code2 = ({ variant, serverSideLang }) => {
  const getVariable = (accessor) => {
    if (serverSideLang === "PHP") {
      return `"<?php echo ${accessor}; ?>"`;
    }
    if (serverSideLang === "RUBY") {
      return `"<%= ${accessor} %>"`;
    }
    if (serverSideLang === "PYTHON") {
      return `"{{ ${accessor} }}"`;
    }
    return accessor;
  };

  if (variant === "READYSTATE") {
    return `
<script>
  window.BugpilotConfig = {
    user: {
      id: ${getVariable("user.id")}, // Required
      email: ${getVariable("user.email")}, // Required

      // Optional properties
      firstName: "John", 
      lastName: "Doe",
      // ...any other attributes you want to see in the reports
    }
  };
</script>
`;
  }

  return `
// If you get an error like "Cannot read property identify of undefined", it means that the Bugpilot script has not been loaded yet. You may want to wrap the following call in a setTimeout(), remove the async/defer attributes from the script tag, or call the method later in your application code.

window.Bugpilot.identify({
  id: ${getVariable("user.id")}, // Required
  email: ${getVariable("user.email")}, // Required

  // Optional properties
  firstName: "John",
  lastName: "Doe",
  // ...any other attributes you want to see in the reports
});`;
};

export const getScriptEmbedCode = ({ workspaceId, scriptCustomDomainName }) => {
  return code1({ workspaceId, scriptCustomDomainName });
};

export const getScriptIdentifyCode = ({ variant, serverSideLang }) => {
  const code = code2({ variant, serverSideLang });
  return code.trim();
};

// component that renders a dialog to ask the user for a reason for cancelling
// and calls a callback when the user provides a reason, or closes when the user
// cancels
// the result entered by the user is passed to https://api.com/cancelreason along with
// the user sub and the workspace id

import React, { useCallback, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";

import { useAppContext } from "../contexts/AppContext";
import { useAuth } from "../contexts/AuthContext";
import { useSnackbar } from "notistack";

const ENDPOINT = "https://hook.eu1.make.com/xw3bd92kf23ngk4ipj3vy8jrcavxpm59";

export const CancelReasonDialog = ({ onDontCancel, onCancel }) => {
  const { selectedCompanyId } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();
  const [reason, setReason] = useState("");

  const submitReason = useCallback(async () => {
    await fetch(ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        WorkspaceId: selectedCompanyId,
        UserSub: user.sub,
        UserEmail: user.email,
        CancelReason: reason,
      }),
    });
  }, [reason, selectedCompanyId, user]);

  const handleDontCancel = useCallback(() => {
    onDontCancel?.();
  }, [onDontCancel]);

  const handleCancel = useCallback(() => {
    if (!reason || reason.length < 3) {
      enqueueSnackbar("Please enter a reason for canceling", {
        variant: "error",
      });
      return;
    }

    submitReason().finally(() => onCancel?.());
  }, [onCancel, submitReason, enqueueSnackbar, reason]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={() => {
        onDontCancel();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          Thank you for helping us improve our service.
          <br />
          What can we do better? What didn&apos;t you like? What didn&apos;t
          work?
        </Typography>

        <TextField
          autoFocus
          placeholder="Any feedback is appreciated"
          type="text"
          fullWidth
          multiline
          variant="standard"
          onChange={(e) => setReason(e.target.value)}
          minRows={4}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          onClick={handleDontCancel}
          color="secondary"
          size="small"
          autofocus
        >
          I changed my mind
        </Button>
        <Button
          variant="text"
          onClick={handleCancel}
          color="error"
          size="small"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React, { useState, useRef, useEffect, useMemo } from "react";
import { Skeleton } from "@mui/material";

import { Report, ReportEvent } from "../../pages/BugTrackerPage/types.jsx";

export const FRAME_BASE_URI =
  process.env.REACT_APP_PLAYER_ENDPOINT || "https://player.bugpilot.io/player";

export const VALID_PLAYER_ORIGIN = FRAME_BASE_URI.includes(":3100")
  ? "http://localhost:3100" // local development
  : "https://player.bugpilot.io"; // production

export const PlayerContainer = ({
  report,
  reportContents,
  isReportLoading,
}: {
  report: Report;
  reportContents: ReportEvent[];
  isReportLoading: boolean;
}) => {
  const frameRef = useRef<HTMLIFrameElement>(null);

  const [playerReady, setPlayerReady] = useState<boolean>(false);

  useEffect(() => {
    const cur = frameRef.current;

    if (!cur) {
      return;
    }

    const onMsg = (msg: MessageEvent) => {
      if (msg.origin !== VALID_PLAYER_ORIGIN) {
        return;
      }

      if (!cur) {
        return;
      }

      if (msg.source !== cur.contentWindow) {
        return;
      }

      if (!msg.data.type) {
        return;
      }

      const data = msg.data;

      if (data.type === "io.bugpilot.player::ready") {
        const recordings =
          report.eventsVersion === "20230710"
            ? reportContents.filter((e) => e.eventType === "recordings")
            : report.recordings;

        cur.contentWindow?.postMessage(
          {
            type: "io.bugpilot.player::events",
            events: recordings,
            reportId: report.id,
          },
          VALID_PLAYER_ORIGIN
        );
        setPlayerReady(true);
      }
    };

    window.addEventListener("message", onMsg);

    return () => {
      window.removeEventListener("message", onMsg);
    };
  }, [report, report.id, report.recordings, reportContents, isReportLoading]);

  useEffect(() => {
    if (!frameRef.current) {
      return;
    }

    if (!playerReady) {
      return;
    }

    if (!report || !report?.metadata || isReportLoading) {
      return;
    }

    frameRef.current.contentWindow?.postMessage(
      {
        type: "io.bugpilot.player::autopilot",
        data: report.autopilotErrors,
        loading: false,
        extraAnnotations: [
          ...(report.metadata?.widgetStartTime && report.autopilotErrors
            ? [
                {
                  type: "widget-interaction",
                  widgetStartTime: report.metadata.widgetStartTime,
                  widgetFinishTime: report.metadata.widgetFinishTime,
                },
              ]
            : []),
          ...(Array.isArray(report.metadata?.helpdeskStartTime)
            ? report.metadata.helpdeskStartTime.map((timestamp) => ({
                type: "helpdeskInteraction",
                timestamp: timestamp,
              }))
            : []),
        ],
      },
      VALID_PLAYER_ORIGIN
    );
  }, [report, report.metadata, playerReady, isReportLoading]);

  const frameSrc = useMemo(() => {
    const url = new URL(FRAME_BASE_URI);
    url.searchParams.set("reportId", report.id);
    return url.toString();
  }, [report.id]);

  if (isReportLoading) {
    return (
      <Skeleton sx={{ width: "100%", height: "100%", transform: "none" }} />
    );
  }

  if (!report.id) {
    return null;
  }

  if (!report.activities?.length && !reportContents?.length) {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      id="bugpilot-player-iframe"
      ref={frameRef}
      src={frameSrc}
      style={{
        border: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
      sandbox="allow-scripts allow-same-origin"
      allow="fullscreen"
    />
  );
};

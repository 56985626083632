// Button that opens a dialog when clicked
import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Collapse,
  Typography,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";

export const ButtonDialog = ({
  variant = "dialog", // dialog, slide
  title,
  children,
  buttonLabel,
  dialogProps,
  buttonProps,
  buttonAddons,
  buttonPrepend,
  stackProps,
  ButtonComponent = Button,
  hideCloseButton,
  tooltip,
  tooltipProps = {},
  boxProps = {},
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const btn = (
    <Stack direction="row" alignItems="center" spacing={1} {...stackProps}>
      {buttonPrepend}

      <Tooltip title={tooltip} placement="right" arrow {...tooltipProps}>
        <ButtonComponent
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          {...buttonProps}
        >
          {buttonLabel}
        </ButtonComponent>
      </Tooltip>

      {buttonAddons}
    </Stack>
  );

  if (variant === "slide") {
    return (
      <Stack
        spacing={2}
        alignItems="flex-start"
        sx={{
          width: "100%",
        }}
      >
        {btn}

        <Collapse
          in={open}
          sx={{
            width: "100%",
          }}
          unmountOnExit
          mountOnEnter
        >
          <Box {...dialogProps}>
            <Stack spacing={2}>{children}</Stack>
          </Box>
        </Collapse>
      </Stack>
    );
  }

  return (
    <>
      {btn}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        {...dialogProps}
      >
        <Box p={2} {...boxProps}>
          {title && (
            <DialogTitle>
              <Typography variant="h5">{title}</Typography>
            </DialogTitle>
          )}

          <DialogContent>
            <Box pt={0} pb={hideCloseButton ? 0 : 1}>
              <Stack spacing={2}>{children}</Stack>
            </Box>
          </DialogContent>

          {hideCloseButton ? null : (
            <DialogActions>
              <Box pr={2}>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  variant="outlined"
                >
                  Close
                </Button>
              </Box>
            </DialogActions>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default ButtonDialog;

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React, { useCallback, useState } from "react";
import { Box, Stack, FormControlLabel, Typography, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { DoneOutlined as DoneIcon } from "@mui/icons-material";

import HelpButton from "../../../components/Help";
import IntegrationsMessageField from "../../../components/IntegrationsMessageField";
import { defaultMessageTemplate } from "./defaultMessageTemplate";

export const IntegrationsSettingsDialog = ({
  company,
  onCompanyChange,
  isAdminOrOwner,
  messageTemplate,
  setMessageTemplate,
}) => {
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback(async () => {
    setSaving(true);
    try {
      await onCompanyChange({
        messageTemplate,
      });
    } finally {
      setTimeout(() => setSaving(false), 1000);
    }
  }, [messageTemplate, onCompanyChange]);

  return (
    <Stack spacing={2}>
      <Typography variant="body1">
        Use this page to configure integrations behavior. You can choose which
        reports to send reports to integrations and customize the template of
        the message that will be sent.
      </Typography>

      <Alert severity="info">
        These settings will be applied to all integrations except for Zapier,
        Make, and WebHook.
      </Alert>

      <Stack>
        <Typography
          variant="h6"
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={1}
          gutterBottom
        >
          <span>Send</span>
          <HelpButton>
            Specify which issues should be sent to integrations.
          </HelpButton>
        </Typography>

        <RadioGroup
          row
          onChange={(e) =>
            onCompanyChange({ reportSendingOption: e.target.value })
          }
          value={company.reportSendingOption || "recommended"}
        >
          <FormControlLabel
            value="recommended"
            control={<Radio />}
            label="Recommended"
          />
          <FormControlLabel
            value="user-reported"
            control={<Radio />}
            label="User reported"
          />
          <FormControlLabel value="all" control={<Radio />} label="All" />
          <FormControlLabel value="none" control={<Radio />} label="None" />
        </RadioGroup>

        {company.reportSendingOption === "none" && (
          <Alert severity="error">
            Your integrations will not receive any reports.
          </Alert>
        )}

        {company.reportSendingOption === "all" && (
          <Alert severity="warning">
            Your integrations will receive all issues. We recommend using the
            Recommended option.
          </Alert>
        )}
      </Stack>

      <Box>
        <IntegrationsMessageField
          company={company}
          onChange={(value) => setMessageTemplate(value)}
          value={company.messageTemplate ?? defaultMessageTemplate}
        />
      </Box>

      <Box
        sx={{
          width: "100%",
          textAlign: "right",
        }}
      >
        <LoadingButton
          variant="contained"
          color="secondary"
          disabled={!isAdminOrOwner}
          onClick={handleSave}
          startIcon={<DoneIcon />}
          loading={saving}
        >
          Save
        </LoadingButton>
      </Box>
    </Stack>
  );
};

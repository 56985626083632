import { CircularProgress, Tooltip, Typography } from "@mui/material";
import customFormatDate, { safeIntlFormat } from "./utils/customFormatDate";

export const getRenderer = ({ currentWorkspace }) => {
  const renderTime = ({ row }) => {
    const { timestamp, timeZone } = row;

    const title = (
      <Typography variant="caption">
        User:{" "}
        {safeIntlFormat(timestamp, {
          timeZone: timeZone || "GMT",
          dateStyle: "short",
          timeStyle: "long",
        })}
        <br />
        Workspace:{" "}
        {safeIntlFormat(timestamp, {
          timeZone: currentWorkspace.timeZone || "GMT",
          dateStyle: "short",
          timeStyle: "long",
        })}
        <br />
        UTC:{" "}
        {safeIntlFormat(timestamp, {
          timeZone: "UTC",
          dateStyle: "short",
          timeStyle: "long",
        })}
        <br />
        Unix: {timestamp}
      </Typography>
    );

    // if less than 3 minutes ago, show in progress
    if (timestamp > Date.now() - 1000 * 60 * 2.5) {
      return (
        <CircularProgress
          size={12}
          sx={{
            color: "text.muted",
          }}
        />
      );
    }

    return (
      <Tooltip title={title} arrow>
        <Typography
          variant="body2"
          sx={{
            color: "tertiary.main",
          }}
        >
          {customFormatDate(
            row.metadata?.errorInfo?.lastOccurrenceOn || timestamp,
            currentWorkspace.timeZone || "GMT"
          )}
        </Typography>
      </Tooltip>
    );
  };

  return renderTime;
};

export const valueFormatter = (val) => {
  return val;
};

export const sortComparator = (v1, v2) => {
  return v1 - v2;
};

import { Grid } from "@mui/material";
import { ReportsColumn } from "./ReportsColumn";
import { useMemo } from "react";
import { useAppContext } from "../../contexts/AppContext";
import {
  orderBy as fpOrderBy,
  compose,
  filter as fpFilter,
  map as fpMap,
} from "lodash/fp";
import { round } from "lodash";

const getIndex = (report) =>
  report
    ? report.index === undefined
      ? report.serialNumber
      : report.index
    : 0;

const filterByStatus = (reports, status) => {
  return compose(
    fpOrderBy(["index"], ["desc"]),
    fpMap((report) => ({
      ...report,
      index: getIndex(report),
    })),
    fpFilter(["bugStatus", status])
  )(reports);
};

export const Columns = ({ reports: allReports, onClick }) => {
  const { updateReport } = useAppContext();
  const confirmedReports = useMemo(
    () => filterByStatus(allReports, "confirmed"),
    [allReports]
  );

  const inProgressReports = useMemo(
    () => filterByStatus(allReports, "in_progress"),
    [allReports]
  );

  const fixedReports = useMemo(
    () => filterByStatus(allReports, "fixed"),
    [allReports]
  );

  const handleMoveCard = async ({ id, toStatus, fromStatus, moveBeforeId }) => {
    const reports = filterByStatus(allReports, toStatus);

    const beforeReportIndex = reports.findIndex((r) => r.id === moveBeforeId);
    const beforeReport = reports[beforeReportIndex];
    const afterReport = reports[beforeReportIndex - 1];

    const getNewIndex = () => {
      if (beforeReport && afterReport) {
        return (getIndex(beforeReport) + getIndex(afterReport)) / 2;
      }

      // Move to the end of the list
      if (!beforeReport) {
        return reports[reports.length - 1]
          ? reports[reports.length - 1] - 0.001
          : 0;
      }

      // Move to the beginning of the list
      return getIndex(beforeReport) + 0.001;
    };

    const newIndex = round(getNewIndex(), 10);

    await updateReport(
      { id },
      {
        index: newIndex,
        ...(toStatus !== fromStatus && { bugStatus: toStatus }),
      }
    );
  };

  return (
    <Grid
      container
      spacing={2}
      style={{
        marginLeft: -16,

        height: "100%",
        overflow: "hidden",
      }}
    >
      <Grid
        item
        xs={4}
        style={{
          maxHeight: "100%",
          overflow: "hidden",
        }}
      >
        <ReportsColumn
          reports={confirmedReports}
          status="confirmed"
          label="Confirmed"
          onClick={onClick}
          handleMoveCard={handleMoveCard}
        />
      </Grid>

      <Grid
        item
        xs={4}
        style={{
          maxHeight: "100%",
          overflow: "hidden",
        }}
      >
        <ReportsColumn
          reports={inProgressReports}
          status="in_progress"
          label="In Progress"
          onClick={onClick}
          handleMoveCard={handleMoveCard}
        />
      </Grid>

      <Grid
        item
        xs={4}
        style={{
          maxHeight: "100%",
          overflow: "hidden",
        }}
      >
        <ReportsColumn
          reports={fixedReports}
          status="fixed"
          label="Fixed"
          onClick={onClick}
          handleMoveCard={handleMoveCard}
          helpText="Reports are automatically deleted after 30 days of inactivity."
        />
      </Grid>
    </Grid>
  );
};

import { useCallback, useEffect, useState } from "react";

import { TextField, InputAdornment, IconButton } from "@mui/material";

import { UndoOutlined as UndoIcon } from "@mui/icons-material";

const ConfirmableInput = ({
  onChange,
  validate,
  disabled,
  initialValue,
  autoFocus,
  onCancel,
  iconAddons = [],
  ...props
}) => {
  const [value, setValue] = useState(initialValue || "");
  const [isEdit, setIsEdit] = useState(autoFocus);
  const [error, setError] = useState(false);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = useCallback(
    async (e) => {
      setValue(e.target.value);

      try {
        if (validate) {
          await validate.validate(e.target.value);
          setError(null);
        }
      } catch (e) {
        setError(e.message);
      }
    },
    [validate]
  );

  const handleCancel = useCallback(
    (e) => {
      e.stopPropagation();

      setIsEdit(false);
      setValue(initialValue);

      onCancel?.();
    },
    [initialValue, onCancel]
  );

  const handleSave = useCallback(async () => {
    try {
      if (validate) {
        await validate.validate(value);
        setError(null);
        onChange?.(value);
        setIsEdit(false);
      }
    } catch (e) {
      setError(e.message);
    }
  }, [onChange, validate, value]);

  return (
    <TextField
      {...props}
      autoFocus={autoFocus}
      disabled={disabled}
      value={value}
      fullWidth
      onChange={handleChange}
      size="large"
      variant="outlined"
      data-test-id="confirmable-text-input"
      onClick={() => {
        setIsEdit(true);
      }}
      onBlur={(e) => {
        if (value !== initialValue) {
          handleSave();
        }

        if (!value || value.trim() === "") {
          handleCancel(e);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSave();
          return;
        }

        if (e.key === "Escape") {
          handleCancel();
        }
      }}
      sx={{
        paddingY: 0,
        // disable border colors
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: isEdit ? undefined : "transparent",
          },
        },
      }}
      InputProps={{
        endAdornment: isEdit ? (
          <InputAdornment position="end">
            {value !== initialValue ? (
              <IconButton
                size="small"
                onClick={handleCancel}
                disabled={disabled}
              >
                <UndoIcon fontSize="small" />
              </IconButton>
            ) : null}
          </InputAdornment>
        ) : (
          <InputAdornment position="end">{iconAddons}</InputAdornment>
        ),
      }}
      error={Boolean(error)}
      helperText={error}
    />
  );
};

export default ConfirmableInput;
